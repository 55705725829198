import { useNavigate } from "react-router-dom";
import { RouteUrls } from "../../../constants/routes";
import {
  DeleteDialog,
  Select,
  Sort,
  TextField,
} from "../../../components/basic";
import { DataTable, CustomFilter } from "../../../components/shared";
import { Box, Menu, MenuItem, Typography, debounce } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import {
  DeleteIcon,
  EditIcon,
  KebabMenuIcon,
  PlusIcon,
} from "../../../assets/icons";
import {
  ItemListType,
  getBillingItemsList,
  getDepartments,
  deleteBillItemById,
} from "../../../services/billingService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import { setCurrentPage } from "../../../redux/slices/pagination";
import PermissionUtils from "../../../utils/PermissionUtils";
import {
  setFilterDataValues,
  setHandleClearSearch,
  setSortField,
} from "../../../redux/slices/pagination";
type GridRowData = Record<string, unknown>;

const styles = {
  actionContainerStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "12px",
  },
};
const BillingItems = () => {
  const { can } = PermissionUtils();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { billingItemsList, filterData, count, sortField } = useSelector(
    (state: RootState) => ({
      billingItemsList: state?.pagination?.billingItemsList,
      filterData: state?.pagination?.billingItemsList?.filterData,
      count: state?.pagination?.billingItemsList?.count,
      sortField: state?.pagination?.billingItemsList?.sortedField,
    })
  );

  const [isDeleteButtonLoading, setIsDeleteButtonLoading] =
    useState<any>(false);

  const { billingUrl, billingAddItemUrl, billingItemsMasterUrl } = RouteUrls;

  const handleClick = () => {
    navigate(`${billingUrl}${billingAddItemUrl}`);
  };

  const handleClickMaster = () => {
    navigate(`${billingUrl}${billingItemsMasterUrl}`);
  };

  const [itemsList, setItemsList] = useState<ItemListType>({
    result: [],
    total: 0,
  });
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [activeSort, setActiveSort] = useState({ field: "", order: "" });
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<{
    isOpen: boolean;
    selectedRow: any;
  }>({
    isOpen: false,
    selectedRow: null,
  });
  const [billingDepartmentOptions, setBillingDepartmentOptions] = useState<
    { id: string | number; name: string }[] | []
  >([]);
  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (isFieldSort) {
      setActiveSort({ field: field, order: "ASC" });
      dispatch(
        setSortField({
          key: "billingItemsList",
          value: { field: field, order: "ASC" },
        })
      );
    }
    if (!isFieldSort) {
      setActiveSort({ field: field, order: "DESC" });
      dispatch(
        setSortField({
          key: "billingItemsList",
          value: { field: field, order: "DESC" },
        })
      );
    }
  };

  const handleFilterClick = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleDeleteConfirm = () => {
    if (isDeleteDialogOpen?.selectedRow?.id) {
      setIsDeleteButtonLoading(true);
      deleteBillItemById(isDeleteDialogOpen?.selectedRow?.id)
        .then((res) => {
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          const data = {
            department: filterData?.department,
            name: filterData?.name,
            description: filterData?.description,
            price: filterData?.price,
            search: filterData?.search,
            column: sortField?.field,
            order: sortField?.order,
            page: billingItemsList?.page + 1,
            length: billingItemsList?.pageSize,
          };
          setIsDeleteButtonLoading(false);
          setIsLoading(true);
          debouncedGetBillingItemList(data);
          setIsDeleteDialogOpen({ isOpen: false, selectedRow: null });
        })
        .catch((err) => {
          setIsDeleteButtonLoading(false);
          if (err?.response?.data?.errors) {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.response?.data?.errors,
              })
            );
          }
        });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "Department",
      flex: 1.5,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("department")}
        >
          <Typography variant="h5" fontSize={14}>
            Department
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "department" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "department" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.dept_name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "Name",
      flex: 1.5,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "Description",
      flex: 1.5,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("desc")}
        >
          <Typography variant="h5" fontSize={14}>
            Description
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "desc" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "desc" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.desc}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "Price",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("Price")}
        >
          <Typography variant="h5" fontSize={14}>
            Price
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "Price" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "Price" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.price}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    ...(can("billing_items_edit") || can("billing_items_delete")
      ? [
          {
            field: "actions",
            renderHeader: () => (
              <>
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </>
            ),
            renderCell: ({ row }: any) => <TableActions row={row} />,
            sortable: false,
            // align: "center",
            minWidth: 100,
          },
        ]
      : []),
  ];

  const TableActions = ({ row }: any) => {
    return (
      <Box
        sx={{
          ...styles.actionContainerStyle,
        }}
      >
        {can("billing_items_edit") && (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            onClick={() =>
              navigate(`${billingUrl}${billingAddItemUrl}/${row.id}`)
            }
          >
            <EditIcon
              style={{
                margin: "0 10px",
              }}
            />
          </Box>
        )}
        {can("billing_items_delete") && (
          <Box display="flex" alignItems="center" flexDirection="row">
            <DeleteIcon
              style={{
                width: "15px",
                height: "15px",
                margin: "0 6px 0 0",
              }}
            />
          </Box>
        )}
      </Box>
    );
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    dispatch(
      setFilterDataValues({ key: "billingItemsList", name: name, value: value })
    );

    dispatch(setCurrentPage({ key: "billingItemsList", value: 0 }));
  };

  const handleClearFilter = () => {
    if (
      filterData?.department ||
      filterData?.name ||
      filterData?.description ||
      filterData?.price
    ) {
      dispatch(
        setFilterDataValues({
          key: "billingItemsList",
          name: "clearFilter",
          value: {
            department: "",
            name: "",
            description: "",
            price: "",
            search: "",
          },
        })
      );
    }
  };

  const filterElements = [
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <Select
          value={filterData?.department}
          formControlStyle={{ width: "100%", paddingBottom: "2px" }}
          placeholder="Select Department"
          name="department"
          options={billingDepartmentOptions}
          label={"Department"}
          width=""
          onChange={handleChange}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData?.name}
          label={"Name"}
          name="name"
          formControlStyle={{ width: "100%", paddingBottom: "2px" }}
          onChange={handleChange}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData?.description}
          label={"Description"}
          name="description"
          formControlStyle={{ width: "100%", paddingBottom: "2px" }}
          onChange={handleChange}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData?.price}
          label={"Price"}
          name="price"
          formControlStyle={{ width: "100%", paddingBottom: "2px" }}
          onChange={handleChange}
        />
      ),
    },
  ];

  const debouncedGetBillingItemList = useCallback(
    debounce((data: any) => {
      getBillingItemsList(data)
        .then((res: any) => {
          setIsLoading(false);
          if (res.data) {
            setItemsList(res.data);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    }, 300),
    []
  );

  useEffect(() => {
    setIsLoading(true);
    const data = {
      department: filterData?.department,
      name: filterData?.name,
      description: filterData?.description,
      price: filterData?.price,
      search: filterData?.search,
      column: sortField?.field,
      order: sortField?.order,
      page: billingItemsList?.page + 1,
      length: billingItemsList?.pageSize,
    };

    debouncedGetBillingItemList(data);
  }, [billingItemsList, activeSort, filterData]);

  useEffect(() => {
    getDepartments()
      .then((res) => {
        if (res.data) {
          setBillingDepartmentOptions(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  console.log("filterdata", filterData?.price);

  return (
    <div>
      <>
        <CustomFilter
          addButtonTitle={can("billing_items_add") ? "Add" : null}
          editButtonTitle={can("billing_items_Master") ? "Master" : null}
          onAddButtonClick={handleClick}
          onUpdateButtonClick={handleClickMaster}
          isFilterEnabled
          isSearchEnabled
          data={filterElements}
          searchValue={filterData?.search}
          searchOnChange={handleChange}
          searchName="search"
          clearFilter={handleClearFilter}
          appliedFilterCount={count}
          clearSearch={() => {
            dispatch(
              setHandleClearSearch({
                key: "billingItemsList",
                value: "",
                name: "search",
              })
            );
          }}
          addStartIcon={<PlusIcon width={"12px"} height={"12px"} />}
        />
        <DataTable
          loading={isLoading}
          columns={columns}
          getRowId={(row: GridRowData) => `${String(row.id)}`}
          rows={itemsList?.result}
          pageCount={itemsList?.total}
          currentPage={"billingItemsList"}
          handleFilterClick={handleFilterClick}
        />
        <DeleteDialog
          open={isDeleteDialogOpen.isOpen}
          onClose={() =>
            setIsDeleteDialogOpen({ isOpen: false, selectedRow: null })
          }
          onConfirm={handleDeleteConfirm}
          loading={isDeleteButtonLoading}
        />
      </>
    </div>
  );
};

export default BillingItems;
