import { useState, useEffect, useCallback } from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  Typography,
  CircularProgress,
  Grid,
  debounce,
  Box,
} from "@mui/material";
import {
  Sort,
  CustomButton,
  TextField,
  PageLoader,
} from "../../../../components/basic";
import {
  DialogWrapper,
  ConfirmationDialog,
} from "../../../../components/shared";
import { DataTable } from "../../../../components/shared";
import { ActiveStatusColor, statusColor } from "../../../../utils/GeneralUtils";
import CustomFilter from "../../../../components/shared/customFilter";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import { displayText } from "../../../../constants/displayText";
import { DeleteIcon, EditIcon, PlusIcon } from "../../../../assets/icons";
import AddAndEditProduct from "./AddAndEdit";
import {
  addLabProducts,
  deleteLabProduct,
  getAllLabProductList,
  getLabProductById,
  updateLabProductById,
} from "../../../../services/procurementMasterService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import PermissionUtils from "../../../../utils/PermissionUtils";
import { setCurrentPage } from "../../../../redux/slices/pagination";

type GridRowData = Record<string, unknown>;

function Products() {
  const { can } = PermissionUtils();

  const { ADD, EDIT, ADD_BUTTON_TITLE, CONFIRMATION_DELETE_DIALOG_TITLE } =
    displayText;

  // Products list Data table states and constants
  const dispatch = useDispatch();

  const [isFieldSort, setIsFieldSort] = useState(false);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [loading, setLoading] = useState(false);
  const { laboratoryMasterProducts } = useSelector(
    (state: RootState) => state.pagination
  );
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);
  const [filterData, setFilterData] = useState({
    name: "",
    chemical: "",
    no_of_tests: "",
    search: "",
  });

  const [productList, setProductList] = useState([]);
  const [pageCount, setPageCount] = useState();
  // Add And Edit dialog states and constants
  const [isAddAndEditDialogOpen, setIsAddAndEditDialogOpen] =
    useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
  const [idToBeUpdated, setIdToBeUpdated] = useState<number | null>(null);

  // confirmation dialog states and constants

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);

  // form states and constants

  const initialFormError = {
    name: "",
    chemical_reagent: "",
    no_of_tests: "",
    generic_name: "",
    hsn_code: "",
    prescription: "",
    major_drug: "",
    drug_classification: "",
    dosage_form: "",
    unit: "",
    schedule_type: "",
    manufacturer: "",
    tax: "",
    discount: "",
    pack_type: "",
    rack_position: "",
    department: "",
    re_order_qty: "",
    max_qty: "",
    min_qty: "",
  };

  const [productFormError, setProductFormError] =
    useState<any>(initialFormError);
  type ProductTypes = {
    name: string | number | null;
    chemical_reagent: string | number | null;
    no_of_tests: string | number | null;
    dept_reorder: [
      {
        dept_id: string | number | null;
        re_order_qty: string | number | null;
        max_qty: string | number | null;
        min_qty: string | number | null;
        is_indent: boolean;
      }
    ];
    active: boolean | null;
  };
  const initialData: ProductTypes = {
    name: "",
    chemical_reagent: "",
    no_of_tests: "",
    dept_reorder: [
      {
        dept_id: "",
        re_order_qty: "",
        max_qty: "",
        min_qty: "",
        is_indent: false,
      },
    ],
    active: true,
  };

  const [productFormData, setProductFormData] = useState<any>(initialData);

  const fieldData: any = {
    name: {
      label: "Product Name",
      name: "name",
      value: productFormData.name,
      isError: Boolean(productFormError.name),
      helperText: productFormError.name,
    },
    chemical_reagent: {
      label: "Chemical Reagent",
      name: "chemical_reagent",
      value: productFormData.chemical_reagent,
      isError: Boolean(productFormError.chemical_reagent),
      helperText: productFormError.chemical_reagent,
    },
    no_of_tests: {
      label: "No of Tests",
      name: "no_of_tests",
      value: productFormData.no_of_tests,
      isError: Boolean(productFormError.no_of_tests),
      helperText: productFormError.no_of_tests,
    },
    status: {
      label: "Status",
      name: "active",
      value: productFormData.active,
    },
  };

  const [productFieldData, setProductFieldData] = useState(fieldData);
  const [saveBtnLoader, setSaveBtnLoader] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState<boolean>(false);
  const [productToBeDeleted, setProductToBeDeleted] = useState<any>(null);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);
  const [vacationId, setVacationId] = useState<number | any>("");

  const deleteVacation = (row: any) => {
    setIsConfirmationDialogOpen(true);
    setProductToBeDeleted(row.id);
  };

  // Products list Data table functions

  const handleFilterInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;

      setFilterData((prevState: any) => {
        const newFilter = { ...prevState, [name]: value };
        const appliedFilterCount = Object.entries(newFilter).filter(
          ([key, val]) => key !== "search" && Boolean(val)
        ).length;

        setAppliedFilterCount(appliedFilterCount);
        return newFilter;
      });
    }
    dispatch(setCurrentPage({ key: "laboratoryMasterProducts", value: 0 }));
  };

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        {can("lab_products_edit") && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIdToBeUpdated(row.id);
              setIsAddAndEditDialogOpen(true);
            }}
          >
            <EditIcon />
          </div>
        )}
        {can("lab_products_delete") && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => deleteVacation(row)}
          >
            <DeleteIcon />
          </div>
        )}
      </div>
    );
  };

  const clearFilters = () => {
    if (filterData.name || filterData.chemical || filterData.no_of_tests) {
      setFilterData({
        ...filterData,
        name: "",
        chemical: "",
        no_of_tests: "",
      });
      setAppliedFilterCount(0);
    }
  };

  const deleteProductById = async () => {
    if (productToBeDeleted === null) {
      return false;
    }
    setDeleteBtnLoader(true);
    await deleteLabProduct(productToBeDeleted as number)
      .then((res: any) => {
        setProductToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        debouncedGetAllAppointment({
          page: Number(laboratoryMasterProducts.page) + 1,
          pageSize: laboratoryMasterProducts.pageSize,
          column: sortedField.field,
          order: sortedField.order,
          search: filterData.search,
          name: filterData.name,
          chemical: filterData.chemical,
          no_of_tests: filterData.no_of_tests,
        });
        dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
        setDeleteBtnLoader(false);
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.errors
          ? err?.response?.data?.errors
          : "Unknown error occurred";
        dispatch(
          setSnackBarFailed({
            snackBarMessage: `${errorMessage}`,
          })
        );
        setProductToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        setDeleteBtnLoader(false);
        console.log(err);
      });
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "chemical_reagent",
      flex: 1.5,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("chemical_reagent")}
        >
          <Typography variant="h5" fontSize={14}>
            Chemical Reagent
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "chemical_reagent" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "chemical_reagent" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.chemical_reagent || ""}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "no_of_tests",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("no_of_tests")}
        >
          <Typography variant="h5" fontSize={14}>
            No of Test
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "no_of_tests" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "no_of_tests" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.no_of_tests}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "active",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("active")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "active" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "active" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => {
        const statusValue = row ? row.active : null;
        const { buttonText, backgroundColor, textColor } =
          ActiveStatusColor[statusValue] || {};

        return (
          <Typography variant="h5" fontSize={14}>
            <CustomButton
              buttonText={buttonText}
              backgroundColor={backgroundColor}
              textColor={textColor}
              fontSize={"10px"}
              fontWeight={500}
              textTransform={"capitalize"}
            />
          </Typography>
        );
      },
      minWidth: 120,
      sortable: false,
    },
    ...(can("lab_products_edit") || can("lab_products_delete")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const productFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData.name}
          placeholder={"Enter Name"}
          label={"Name"}
          formControlStyle={{ width: "100%" }}
          onChange={handleFilterInputChange}
          name={"name"}
          width="100%"
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData.chemical}
          placeholder={"Enter Chemical"}
          label={"Chemical"}
          formControlStyle={{ width: "100%" }}
          onChange={handleFilterInputChange}
          name={"chemical"}
          width="100%"
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData.no_of_tests}
          label={"No of Test"}
          placeholder={"Enter No of Test"}
          formControlStyle={{ width: "100%" }}
          onChange={handleFilterInputChange}
          name={"no_of_tests"}
          width="100%"
        />
      ),
    },
  ];

  const handleClearSearch = () => {
    setFilterData((prevState: any) => {
      const newFilter = { ...prevState, search: "" };
      return newFilter;
    });
    dispatch(setCurrentPage({ key: "laboratoryMasterProducts", value: 0 }));
  };

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "name":
      case "no_of_tests":
      case "chemical_reagent":
      case "generic_name":
      case "pack_type": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setProductFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setProductFormError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateProductsFieldData = () => {
    setProductFieldData((prev: any) => {
      return Object?.keys(prev)?.map((field: any) => {
        return {
          ...field,
          helperText: productFormError[field.name],
          value: productFormData[field.name],
          isError: productFormError[field.name] === "" ? false : true,
        };
      });
    });
  };

  useEffect(() => {
    updateProductsFieldData();
  }, [productFormError, productFormData]);

  // form fields onchange functions

  const handleInputChange = (e: any, index?: number | string) => {
    const { name, value } = e.target;
    if (
      name === "re_order_qty" ||
      name === "max_qty" ||
      name === "min_qty" ||
      name === "dept_id"
    ) {
      setProductFormData((prev: any) => ({
        ...prev,
        dept_reorder: prev?.dept_reorder?.map((department: any, i: number) => {
          if (i === index) {
            return {
              ...department,
              [name]: value,
            };
          }
          return department;
        }),
      }));
      handleValidation(e);
    } else {
      setProductFormData((prev: any) => ({
        ...prev,
        [name]: value,
      }));
      handleValidation(e);
    }
  };

  const handleSelectChange = (e: any, id: number) => {
    const { name, value } = e.target;

    if (name === "dept_id") {
      setProductFormData((prev: any) => ({
        ...prev,
        dept_reorder: prev?.dept_reorder?.map(
          (item: any, index: number | string) => {
            if (index === id) {
              return {
                ...item,
                [name]: value,
              };
            }
            return item;
          }
        ),
      }));
    } else {
      setProductFormData((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    }

    handleValidation(e);
  };

  useEffect(() => {
    if (idToBeUpdated) {
      setIsDialogLoading(true);
      getLabProductById(idToBeUpdated)
        .then((res: any) => {
          const productDetail: any = res?.data;
          setProductFormData((prevState: ProductTypes) => ({
            ...prevState,
            name: productDetail?.name,
            chemical_reagent: productDetail?.chemical_reagent,
            no_of_tests: productDetail?.no_of_tests,
            dept_reorder:
              productDetail?.product_departments?.length !== 0
                ? [...productDetail?.product_departments]
                : initialData?.dept_reorder,
            active: productDetail?.active === 1 ? true : false,
          }));
          setIsDialogLoading(false);
        })
        .catch((err) => {
          setIsDialogLoading(false);
          const errorMessage = err?.response?.data?.errors
            ? err?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          console.log(err);
        });
    }
  }, [idToBeUpdated]);
  const handleAutoCompleteChange = (e: any, newValue: any, name: string) => {
    setProductFormData((prev: any) => ({
      ...prev,
      [name]: newValue,
    }));

    handleValidation(e);
  };

  const handleAutoCompleteInputChange = (newInputValue: any, name: string) => {
    setProductFormData((prev: any) => ({
      ...prev,
      [name]: newInputValue,
    }));
  };

  const handleSwitchChange = async (e: any) => {
    const { name, checked } = e.target;

    setProductFormData((prev: any) => ({
      ...prev,
      [name]: checked,
    }));
  };
  const handleProductSwitchChange = (e: any, id: number | string) => {
    const { name, checked } = e.target;

    setProductFormData((prev: any) => ({
      ...prev,
      dept_reorder: prev?.dept_reorder?.map(
        (item: any, index: number | string) => {
          if (index === id) {
            return {
              ...item,
              [name]: checked,
            };
          }
          return item;
        }
      ),
    }));
  };
  const handleAddDepartment = async () => {
    setProductFormData((prev: any) => ({
      ...prev,
      dept_reorder: [
        ...prev.dept_reorder,
        {
          dept_id: "",
          re_order_qty: "",
          max_qty: "",
          min_qty: "",
          is_indent: false,
        },
      ],
    }));
  };

  const handleDeleteDepartment = async (id: number) => {
    const newState = { ...productFormData };
    newState?.dept_reorder?.splice(id, 1);
    setProductFormData(newState);
  };
  const createProduct = async () => {
    const departmentsDetails = productFormData?.dept_reorder?.filter(
      (item: any) => item?.dept_id !== ""
    );
    const data = {
      ...productFormData,
      dept_reorder: departmentsDetails?.length
        ? departmentsDetails?.map((item: any) => ({
            dept_id: item?.dept_id,
            re_order_qty: item?.re_order_qty,
            max_qty: item?.max_qty,
            min_qty: item?.min_qty,
            is_indent: item?.is_indent ? 1 : 0,
          }))
        : [],
      active: productFormData?.active ? 1 : 0,
    };

    if (
      productFormData.name === "" ||
      productFormData.chemical_reagent === "" ||
      productFormData.no_of_tests === ""
    ) {
      validateForm();
    } else {
      setIsButtonLoading(true);
      if (idToBeUpdated) {
        await updateLabProductById(idToBeUpdated as number, data)
          .then((res: any) => {
            closeAddAndEditDialog();
            dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
            debouncedGetAllAppointment({
              page: Number(laboratoryMasterProducts.page) + 1,
              pageSize: laboratoryMasterProducts.pageSize,
              column: sortedField.field,
              order: sortedField.order,
              search: filterData.search,
              name: filterData.name,
              chemical: filterData.chemical,
              no_of_tests: filterData.no_of_tests,
            });
            setIsButtonLoading(false);
          })
          .catch((err) => {
            const errorMessage = err?.response?.data?.errors
              ? err?.response?.data?.errors
              : "Unknown error occurred";
            dispatch(
              setSnackBarFailed({
                snackBarMessage: `${errorMessage}`,
              })
            );
            setIsButtonLoading(false);
          });
      } else {
        data["active"] = 1;
        await addLabProducts(data)
          .then((res: any) => {
            setIsButtonLoading(false);
            closeAddAndEditDialog();
            dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
            debouncedGetAllAppointment({
              page: Number(laboratoryMasterProducts.page) + 1,
              pageSize: laboratoryMasterProducts.pageSize,
              column: sortedField.field,
              order: sortedField.order,
              search: filterData.search,
              name: filterData.name,
              chemical: filterData.chemical,
              no_of_tests: filterData.no_of_tests,
            });
          })
          .catch((err) => {
            const errorMessage = err?.response?.data?.errors
              ? err?.response?.data?.errors
              : "Unknown error occurred";
            dispatch(
              setSnackBarFailed({
                snackBarMessage: `${errorMessage}`,
              })
            );
            setIsButtonLoading(false);
          });
      }
    }
  };
  const handleConfirmationDelete = async () => {};

  const openAddAndEditDialog = async () => {
    setIsAddAndEditDialogOpen(true);
  };

  const closeAddAndEditDialog = async () => {
    setIsButtonLoading(false);
    setIsAddAndEditDialogOpen(false);
    setProductFormData(initialData);
    setIdToBeUpdated(null);
    setProductFormError(initialFormError);
  };

  const debouncedGetAllAppointment = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllLabProductList(data)
        .then((res: any) => {
          setProductList(res?.data?.result);
          setPageCount(res?.data?.total as any);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          const errorMessage = err?.response?.data?.errors
            ? err?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          console.log("error getAppointmentList", err);
        });
    }, 300),
    []
  );
  useEffect(() => {
    const data = {
      page: Number(laboratoryMasterProducts.page) + 1,
      pageSize: laboratoryMasterProducts.pageSize,
      column: sortedField.field,
      order: sortedField.order,
      search: filterData.search,
      name: filterData.name,
      chemical: filterData.chemical,
      no_of_tests: filterData.no_of_tests,
    };
    debouncedGetAllAppointment(data);
  }, [
    laboratoryMasterProducts.page,
    laboratoryMasterProducts.pageSize,
    sortedField.order,
    sortedField.field,
    filterData.name,
    filterData.chemical,
    filterData.search,
    filterData.no_of_tests,
  ]);

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  return (
    <>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={CONFIRMATION_DELETE_DIALOG_TITLE}
        handleClick={deleteProductById}
        onClose={closeConfirmationDialog}
        loading={deleteBtnLoader}
      />
      <CustomFilter
        data={productFilter}
        isFilterEnabled={true}
        isSearchEnabled={true}
        searchValue={filterData.search}
        clearFilter={clearFilters}
        searchOnChange={handleFilterInputChange}
        appliedFilterCount={appliedFilterCount}
        searchName={"search"}
        clearSearch={handleClearSearch}
        {...(can("lab_products_add") && {
          editButtonTitle: ADD_BUTTON_TITLE,
          onUpdateButtonClick: openAddAndEditDialog,
          editStartIcon: (
            <Box
              sx={{
                "& path": {
                  fill: "#fff",
                },
              }}
            >
              <PlusIcon width={"12px"} height={"12px"} />
            </Box>
          ),
        })}
      />
      <DataTable
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={productList}
        pageCount={pageCount}
        loading={loading}
        currentPage={"laboratoryMasterProducts"}
      />
      <DialogWrapper
        onClose={closeAddAndEditDialog}
        open={isAddAndEditDialogOpen}
        title={idToBeUpdated === null ? "Add Product" : "Edit Product"}
        loading={isButtonLoading}
        maxWidth="md"
      >
        {isDialogLoading ? (
          <PageLoader />
        ) : (
          <AddAndEditProduct
            fieldData={fieldData}
            handleInputChange={handleInputChange}
            handleValidation={handleValidation}
            handleAutoCompleteChange={handleAutoCompleteChange}
            handleAutoCompleteInputChange={handleAutoCompleteInputChange}
            handleSelectChange={handleSelectChange}
            productFormData={productFormData}
            handleDeleteDepartment={handleDeleteDepartment}
            handleAddDepartment={handleAddDepartment}
            handleSwitchChange={handleSwitchChange}
            idToBeUpdated={idToBeUpdated}
            createProduct={createProduct}
            closeAddAndEditDialog={closeAddAndEditDialog}
            handleProductSwitchChange={handleProductSwitchChange}
            isButtonLoading={isButtonLoading}
          />
        )}
      </DialogWrapper>
    </>
  );
}

export default Products;
