import { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  debounce,
} from "@mui/material";
import { Sort } from "../../../../components/basic";
import {
  ConfirmationDialog,
  DataTable,
  DialogWrapper,
} from "../../../../components/shared";
import { DeleteIcon, EditIcon, PlusIcon } from "../../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  getAllVacations,
  updateVacationsDetails,
} from "../../../../services/appointmentsMasterService";
import CustomFilter from "../../../../components/shared/customFilter";
import {
  appointmentsMasterConst,
  commonText,
  errorMessage,
} from "../../../../constants/displayText";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import {
  dateFormat,
  timeFormat,
  convertTimeToDate,
  convertDateToTime,
} from "../../../../utils/DateTimeFormatUtils";
import { DatePicker, TimePicker, Select } from "../../../../components/basic";
import { doctorDetails } from "../../../../services/appointmentService";
import {
  createVacationsDetails,
  deleteVacationsDetails,
  editVacationsDetails,
} from "../../../../services/appointmentsMasterService";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import moment from "moment";
import PermissionUtils from "../../../../utils/PermissionUtils";
import { setCurrentPage } from "../../../../redux/slices/pagination";

type GridRowData = Record<string, unknown>;

const VacationsList = () => {
  const { can } = PermissionUtils();

  const [vacationsList, setVacationsList] = useState<[]>([]);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [activeSort, setActiveSort] = useState({ field: "", order: "" });
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [addVacation, setAddVacation] = useState(false);
  const [doctorsName, setDoctorsName] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | number | null>("");
  const [saveBtnLoader, setSaveBtnLoader] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [vacationToBeDeleted, setVacationToBeDeleted] = useState<any>(null);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);
  const [vacationId, setVacationId] = useState<number | any>("");

  const initialVacationFormError = {
    doctor_id: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
  };
  const [vacationFormError, setVacationFormError] = useState<any>(
    initialVacationFormError
  );

  const initialData = {
    doctor_id: "",
    start_date: moment().format("DD/MM/YYYY"),
    end_date: moment().format("DD/MM/YYYY"),
    start_time: null,
    end_time: null,
  };

  const [vacationFormData, setVacationFormData] = useState<string | Date | any>(
    initialData
  );

  const fieldData: any = {
    doctor_id: {
      label: "Doctor Name",
      name: "doctor_id", // Include the name field
      value: vacationFormData.doctor_id,
      ref: useRef(null),
      isError: Boolean(vacationFormError.doctor_id),
      helperText: vacationFormError.doctor_id,
    },
    start_date: {
      label: "Start Date",
      name: "start_date",
      value: vacationFormData.start_date,
      isError: Boolean(vacationFormError.start_date),
      helperText: vacationFormError.start_date,
    },
    end_date: {
      label: "End Date",
      name: "end_date",
      value: vacationFormData.end_date,
      isError: Boolean(vacationFormError.end_date),
      helperText: vacationFormError.end_date,
    },

    start_time: {
      label: "Start Time",
      name: "start_time",
      value: vacationFormData.start_time,
      isError: Boolean(vacationFormError.start_time),
      helperText: vacationFormError.start_time,
    },
    end_time: {
      label: "End Time",
      name: "end_time",
      value: vacationFormData.end_time,
      isError: Boolean(vacationFormError.end_time),
      helperText: vacationFormError.end_time,
    },
  };

  const [vacationFieldData, setVacationFieldData] = useState(fieldData);

  const handleDialogOpen = () => {
    setVacationFormData(initialData);
    setAddVacation(true);
    setIsUpdate(false);
  };
  const handleDialogClose = () => {
    setAddVacation(false);
    setVacationFormError(initialVacationFormError);
  };
  const getAllDoctorsName = async () => {
    try {
      doctorDetails().then((result: any) => {
        let data = result?.data;
        const doctorsList = data?.map((uniqueData: any) => {
          return { id: uniqueData?.id, name: uniqueData?.name };
        });
        setDoctorsName(doctorsList);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  let isFocused = false;

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "doctor_id":
      case "start_date":
      case "end_date":
      case "end_time":
      case "start_time": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setVacationFormError
          );

          if (!isFocused) {
            if (fieldData[name]?.ref?.current) {
              fieldData[name]?.ref?.current?.focus();
              isFocused = true;
            }
          }
        } else {
          updateFormDataWithHelperText(name, "", setVacationFormError);
        }
        break;
      }

      // developer comment
      //  we might use these condition in future

      // case "end_time": {
      //   const startTime = vacationFormData.start_time;
      //   const endTime = vacationFormData.end_time;

      //   if (name === "end_time" && startTime !== null) {
      //     if (requiredValidator(value, label)) {
      //       updateFormDataWithHelperText(
      //         name,
      //         requiredValidator(value, label),
      //         setVacationFormError
      //       );
      //     } else {
      //       updateFormDataWithHelperText(name, "", setVacationFormError);
      //     }
      //   }
      //   break;
      // }

      // case "start_time": {
      //   if (vacationFormData.end_time === null) {
      //     updateFormDataWithHelperText("end_time", "", setVacationFormError);
      //   }
      //   break;
      // }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };
  const updateBookingFieldData = () => {
    setVacationFieldData((prevFieldData: any) => {
      return Object.keys(prevFieldData).map((field: any) => {
        return {
          ...field,
          helperText: vacationFormError[field.name],
          value: vacationFormData[field.name],
          isError: vacationFormError[field.name] == "" ? false : true,
        };
      });
    });
  };

  useEffect(() => {
    updateBookingFieldData();
  }, [vacationFormError, vacationFormData]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setVacationFormData((prev: any) => ({
      ...prev,
      doctor_id: value,
    }));
    handleValidation(e);
  };

  const handleDateChange = useCallback((newValue: any, name: any) => {
    if (newValue) {
      setVacationFormData((prev: any) => ({
        ...prev,
        [name]: newValue.toString(),
      }));
    } else {
      setVacationFormData((prev: any) => ({
        ...prev,
        [name]: null,
      }));
    }

    handleValidation({
      target: {
        label: name === "start_date" ? "Start Date" : "End Date",
        name: name,
        value: newValue?.toString(),
      },
    });
  }, []);
  function convertTimeStringToDate(timeString: any) {
    // Split the time string into hours, minutes, and period (AM/PM)
    const timeParts = timeString.split(":");
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const period = timeParts[2];

    // Adjust hours for PM period
    let adjustedHours = hours;
    if (period === "PM" && hours !== 12) {
      adjustedHours += 12;
    } else if (period === "AM" && hours === 12) {
      adjustedHours = 0;
    }

    // Create a new Date object with current date and adjusted hours and minutes
    const currentDate = new Date();
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      adjustedHours,
      minutes
    );

    return newDate;
  }

  const formatTime = (date: any) => {
    const padZero = (num: any) => (num < 10 ? "0" + num : num); // Helper function to pad single digit numbers with leading zero
    const hours = date?.getHours();
    const minutes = date?.getMinutes();
    const meridiem = hours < 12 ? "AM" : "PM"; // Determine AM or PM
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    return `${padZero(formattedHours)}:${padZero(minutes)} ${meridiem}`;
  };

  const handleTimeChange = (newValue: any, name: any) => {
    if (newValue) {
      setVacationFormData((prev: any) => ({
        ...prev,
        [name]: newValue?.toDate(), // Store the JavaScript Date object
      }));
    } else {
      setVacationFormData((prev: any) => ({
        ...prev,
        [name]: null, // Store the JavaScript Date object
      }));
    }

    handleValidation({
      target: {
        label: name === "start_time" ? "Start Time" : "End Time",
        name: name,
        value: newValue ? newValue?.toDate() : null,
      },
    });
  };

  const { doctor_id, start_date, end_date, start_time, end_time } =
    vacationFormData;

  const createVacation = async () => {
    if (
      !Boolean(vacationFormData.doctor_id) ||
      !Boolean(start_date) ||
      !Boolean(end_date) ||
      !Boolean(start_time) ||
      !Boolean(end_time)
    ) {
      validateForm();
      return false;
    }

    try {
      const data = {
        doctor_id: vacationFormData.doctor_id,
        start_date: vacationFormData.start_date,
        end_date: vacationFormData.end_date,
        start_time: convertDateToTime(vacationFormData.start_time),
        end_time: convertDateToTime(vacationFormData.end_time),
      };
      setSaveBtnLoader(true);
      await createVacationsDetails(data)?.then((result) => {
        dispatch(
          setSnackBarSuccess({
            snackBarMessage:
              appointmentsMasterConst.ADDED_SUCCESSFULLY as string,
          })
        );
        setAddVacation(false);
        getVacationsList({
          page: Number(pageInfo.page) + 1,
          pageSize: pageInfo?.pageSize,
          sortBy: sortedField.field,
          sortOrder: sortedField.order,
          searchValue: searchValue,
        });
      });
      setSaveBtnLoader(false);
    } catch (error: any) {
      console.error("An error occurred:", error);
      const errorMessage = error.response.data.errors
        ? error.response.data.errors
        : "Unknown error occurred";
      dispatch(
        setSnackBarFailed({
          snackBarMessage: `${errorMessage}`,
        })
      );
      setSaveBtnLoader(false);
    }
  };

  const editVacation = async (row: any) => {
    try {
      setIsUpdate(true);
      setAddVacation(true);
      setDialogLoading(true);
      await editVacationsDetails(row.id).then((result: any) => {
        setVacationId(result?.data?.id);
        setVacationFormData((prev: any) => ({
          ...prev,
          doctor_id: result?.data?.doctor_id,
          start_date: dateFormat(result?.data?.start_date, "DD/MM/YYYY"),
          end_date: dateFormat(result?.data?.end_date, "DD/MM/YYYY"),
          start_time:
            result?.data?.start_time !== null
              ? convertTimeToDate(timeFormat(result?.data?.start_time))
              : null,
          end_time:
            result?.data?.end_time !== null
              ? convertTimeToDate(timeFormat(result?.data?.end_time))
              : null,
        }));
        setDialogLoading(false);
      });
    } catch (error) {
      console.error("An error occurred:", error);
      setDialogLoading(false);
    }
  };

  const updateVacation = async () => {
    if (
      !Boolean(vacationFormData.doctor_id) ||
      !Boolean(start_date) ||
      !Boolean(end_date) ||
      !Boolean(start_time) ||
      !Boolean(end_time)
    ) {
      validateForm();
      return false;
    }
    try {
      setSaveBtnLoader(true);
      const data = {
        doctor_id: vacationFormData.doctor_id,
        start_date: vacationFormData.start_date,
        end_date: vacationFormData.end_date,
        start_time: convertDateToTime(vacationFormData.start_time),
        end_time: convertDateToTime(vacationFormData.end_time),
      };
      await updateVacationsDetails(vacationId, data).then((result: any) => {
        dispatch(
          setSnackBarSuccess({
            snackBarMessage:
              appointmentsMasterConst.UPDATED_SUCCESSFULLY as string,
          })
        );
        setSaveBtnLoader(false);
        setAddVacation(false);
        getVacationsList({
          page: Number(pageInfo.page) + 1,
          pageSize: pageInfo?.pageSize,
          sortBy: sortedField.field,
          sortOrder: sortedField.order,
          searchValue: searchValue,
        });
      });
    } catch (error) {
      console.error("An error occurred:", error);
      setSaveBtnLoader(false);
    }
  };

  // const updateVacation = () => {
  //   try {
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //   }
  // };

  const deleteVacation = (row: any) => {
    setIsConfirmationDialogOpen(true);
    setVacationToBeDeleted(row.id);
  };
  const handleConfirmDelete = async () => {
    if (vacationToBeDeleted === null) {
      return false;
    }
    try {
      setDeleteBtnLoader(true);
      await deleteVacationsDetails(vacationToBeDeleted).then((result: any) => {
        getVacationsList({
          page: Number(pageInfo.page) + 1,
          pageSize: pageInfo?.pageSize,
          sortBy: sortedField.field,
          sortOrder: sortedField.order,
          searchValue: searchValue,
        });
        setVacationToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        dispatch(
          setSnackBarSuccess({
            snackBarMessage:
              appointmentsMasterConst.DELETED_SUCCESSFULLY as string,
          })
        );
        setDeleteBtnLoader(false);
      });
    } catch (error) {
      setVacationToBeDeleted(null);
      setIsConfirmationDialogOpen(false);
      setDeleteBtnLoader(false);
      console.error("An error occurred:", error);
    }
  };
  const onClose = () => {
    setIsConfirmationDialogOpen(false);
  };
  useEffect(() => {
    getAllDoctorsName();
  }, []);
  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (isFieldSort) {
      setSortedField({ field: field, order: "ASC" });
      setActiveSort({ field: field, order: "ASC" });
    }
    if (!isFieldSort) {
      setSortedField({ field: field, order: "DESC" });
      setActiveSort({ field: field, order: "DESC" });
    }
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {can("vaccation_edit") && (
          <div style={{ cursor: "pointer" }}>
            <IconButton onClick={() => editVacation(row)}>
              <EditIcon />
            </IconButton>
          </div>
        )}
        {can("vaccation_delete") && (
          <div style={{ cursor: "pointer" }}>
            <IconButton onClick={() => deleteVacation(row)}>
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("doctor_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Doctor Name
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "doctor_name" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "doctor_name" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.doctor_name}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "startDate",
      flex: 1,

      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("start_date")}
        >
          <Typography variant="h5" fontSize={14}>
            Start Date
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "start_date" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "start_date" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {dateFormat(row?.start_date)}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "endDate",
      flex: 1,

      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("end_date")}
        >
          <Typography variant="h5" fontSize={14}>
            End Date
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "end_date" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "end_date" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {dateFormat(row?.end_date)}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "startTime",
      flex: 1,

      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("start_time")}
        >
          <Typography variant="h5" fontSize={14}>
            Start Time
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "start_time" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "start_time" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.start_time == null ? "" : timeFormat(row?.start_time)}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "endTime",
      flex: 1,

      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("end_time")}
        >
          <Typography variant="h5" fontSize={14}>
            End Time
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "end_time" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "end_time" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.end_time == null ? "" : timeFormat(row?.end_time)}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    ...(can("vaccation_edit") || can("vaccation_delete")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const dispatch = useDispatch();
  const pageInfo = useSelector(
    (state: RootState) => state?.pagination?.vacationList
  );

  const handleSearchOnChange = (e: any) => {
    setSearchValue(e.target.value);
    dispatch(setCurrentPage({ key: "vacationList", value: 0 }));
  };
  const getVacationsList = useCallback(
    debounce(async (data) => {
      setLoading(true);

      await getAllVacations(data)
        .then((res) => {
          setVacationsList(res?.data?.result as []);
          setPageCount(res?.data?.total as any);
          setLoading(false);
        })
        .catch((err) => {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage?.ERROR_FETCHING_VACATIONS}`,
            })
          );
          setLoading(false);
        });
    }, 300),
    []
  );

  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo?.pageSize,
      sortBy: sortedField.field,
      sortOrder: sortedField.order,
      searchValue: searchValue,
    };
    getVacationsList(data);
  }, [pageInfo, sortedField, searchValue]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CustomFilter
          isSearchEnabled={true}
          searchValue={searchValue}
          searchOnChange={handleSearchOnChange}
          searchName={"search"}
          clearSearch={() => setSearchValue("")}
          {...(can("vaccation_list") && {
            editButtonTitle: commonText.EDIT_BUTTON_TITLE,
            onUpdateButtonClick: () => {
              handleDialogOpen();
            },
            editStartIcon: (
              <Box
                sx={{
                  "& path": {
                    fill: "#fff",
                  },
                }}
              >
                <PlusIcon width={"12px"} height={"12px"} />
              </Box>
            ),
          })}
        />
        <DataTable
          title={appointmentsMasterConst.VACATIONS}
          columns={columns}
          getRowId={(row: GridRowData) => `${String(row.id)}`}
          rows={vacationsList}
          pageCount={pageCount}
          currentPage={"vacationList"}
          loading={loading}
        />
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          title="are you surely want to delete?"
          handleClick={handleConfirmDelete}
          onClose={onClose}
          loading={deleteBtnLoader}
        />
      </Box>
      <Grid>
        <DialogWrapper
          open={addVacation}
          handleClick={() => {
            isUpdate ? updateVacation() : createVacation();
          }}
          title={isUpdate ? "Edit Vacation" : "Add Vacation"}
          onClose={() => {
            handleDialogClose();
          }}
          maxWidth="md"
          loading={saveBtnLoader}
        >
          {dialogLoading ? (
            <Grid
              sx={{
                width: "100%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                height: "40vh",
                background: "transparent",
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box>
                <Select
                  value={fieldData.doctor_id.value}
                  placeholder="Select Doctor"
                  onChange={handleInputChange}
                  options={doctorsName}
                  name={fieldData.doctor_id.name}
                  inputRef={fieldData?.doctor_id?.ref}
                  label={fieldData.doctor_id.label}
                  helperText={fieldData.doctor_id.helperText}
                  sx={{
                    width: {
                      xs: "250px",
                      sm: "427px",
                      md: "427px",
                    },
                  }}
                  onBlur={(e: any) =>
                    handleValidation((e = { target: fieldData.doctor_id }))
                  }
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: 3,
                  "& .MuiFormControl-root": {
                    position: "static",
                  },
                }}
              >
                <DatePicker
                  onChange={(newValue: any) =>
                    handleDateChange(newValue, "start_date")
                  }
                  value={fieldData.start_date.value}
                  placeholder={"Selected start Date"}
                  label={fieldData.start_date.label}
                  name={fieldData.start_date.name}
                  helperText={fieldData.start_date.helperText}
                />

                <DatePicker
                  onChange={(newValue: any) =>
                    handleDateChange(newValue, "end_date")
                  }
                  value={fieldData.end_date.value}
                  placeholder={"Selected End Date"}
                  label={fieldData.end_date.label}
                  name={fieldData.end_date.name}
                  helperText={fieldData.end_date.helperText}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: 3,
                  "& .MuiFormControl-root": {
                    position: "static",
                  },
                }}
              >
                <TimePicker
                  placeholder={"Select Start Time"}
                  label={fieldData.start_time.label}
                  format="hh:mm:A"
                  onChange={(newValue: any) =>
                    handleTimeChange(newValue, "start_time")
                  }
                  hideSeconds
                  value={fieldData.start_time.value}
                  name={fieldData.start_time.name}
                  helperText={fieldData.start_time.helperText}
                />
                <TimePicker
                  placeholder={"Select End Time"}
                  label={fieldData.end_time.label}
                  format="hh:mm A"
                  onChange={(newValue: any) =>
                    handleTimeChange(newValue, "end_time")
                  }
                  hideSeconds
                  value={fieldData.end_time.value}
                  name={fieldData.end_time.name}
                  helperText={fieldData.end_time.helperText}
                />
              </Box>
            </Box>
          )}
        </DialogWrapper>
      </Grid>
    </>
  );
};

export default VacationsList;
