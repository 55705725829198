import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@mui/material";
import { DeleteAlertIcon } from "../../../assets/icons";
import { CancelButton } from "../../basic";
import DeleteButton from "../../basic/buttongroup/deleteButton";
import { displayText } from "../../../constants/displayText";

type DialogProps = {
  children?: React.ReactNode;
  onClose?: () => void;
  handleClick: () => void;
  open: boolean;
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  title: string | React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  type?: string;
  loading?: boolean;
};
const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ConfirmationDialog({
  children,
  onClose,
  handleClick,
  open,
  fullWidth = true,
  maxWidth = "xs",
  title = "",
  confirmText = displayText.DELETE,
  cancelText = displayText.CANCEL,
  type = "confirmation",
  loading,
}: DialogProps) {
  const styles = {
    dialogTitle: {
      fontSize: "14px",
      fontWeight: "400",
      padding: "unset",
      width: "auto",
      color: "#232323",
    },
    defaultButton: { mr: "10px" },
    dialogContent: {
      width: "300px",
      gap: 1,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      p: "10px 20px",
    },
    dialogAction: {
      display: "flex",
      justifyContent: "flex-start",
      p: "10px 20px",
    },
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root": {
            position: "absolute",
            top: "0px",
            height: "145px",
          },
        }}
      >
        <DialogContent sx={{ ...styles.dialogContent }}>
          {type === "confirmation" ? <DeleteAlertIcon /> : null}
          <DialogTitle id="alert-dialog-title" sx={{ ...styles.dialogTitle }}>
            {title}
          </DialogTitle>
        </DialogContent>
        <DialogActions sx={{ ...styles.dialogAction }}>
          {type === "confirmation" ? (
            <>
              <DeleteButton
                handleClick={handleClick}
                buttonText={confirmText}
                loading={loading}
                sx={{ ...styles.defaultButton }}
              />
              <CancelButton
                handleClick={onClose}
                buttonText={cancelText}
                sx={{ ...styles.defaultButton }}
              />
            </>
          ) : type === "info" ? (
            <DeleteButton
              handleClick={handleClick}
              buttonText={confirmText}
              loading={loading}
              sx={{ ...styles.defaultButton }}
            />
          ) : null}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
