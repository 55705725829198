import { useState, useEffect, useRef, useCallback } from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  Box,
  IconButton,
  Typography,
  Grid,
  CircularProgress,
  debounce,
} from "@mui/material";
import { PdfIcon, FolderIcon } from "../../../assets/icons";
import { Sort, InfiniteScroller } from "../../../components/basic";
import { DataTable, CustomFilter } from "../../../components/shared";
import { RootState } from "../../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { RouteUrls } from "../../../constants/routes";
import { getAllPatients } from "../../../services/mainCoreService";
import { getAllPatientsList } from "../../../services/patientHistoryService";
import {
  setFilterDataValues,
  setHandleClearSearch,
  setSortField,
} from "../../../redux/slices/pagination";
import { setCurrentPage } from "../../../redux/slices/pagination";

const PatientsList = () => {
  const { patientsUrl, historyUrl } = RouteUrls;
  const dispatch = useDispatch();
  const { pageInfo, filterData, count, sortField } = useSelector(
    (state: RootState) => ({
      pageInfo: state?.pagination?.patientsList,
      filterData: state?.pagination?.patientsList?.filterData,
      count: state?.pagination?.patientsList?.count,
      sortField: state?.pagination?.patientsList?.sortedField,
    })
  );

  const searchPatientsPagination = useRef({ search: "", page: 1 });

  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  const initialFilterData = {
    patient_uhid: "",
    search: "",
  };

  const [isClearFilter, setIsClearFilter] = useState(false);
  const [patientsList, setPatientsList] = useState<any>([]);
  const [patientsData, setPatientsData] = useState<any>([]);
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  const patientsPagination: any = useRef(1);

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortField?.field === field) {
      dispatch(
        setSortField({
          key: "patientsList",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
    if (sortField?.field !== field) {
      dispatch(
        setSortField({
          key: "patientsList",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
  };

  const handleClearSearch = () => {
    dispatch(
      setHandleClearSearch({ key: "patientsList", value: "", name: "search" })
    );
  };

  const clearFilters = () => {
    if (filterData.patient_uhid) {
      dispatch(
        setFilterDataValues({
          key: "patientsList",
          name: "clearFilter",
          value: initialFilterData,
        })
      );

      setIsClearFilter(true);
      dispatch(setCurrentPage({ key: "patientsList", value: 0 }));
    }
  };

  const TableActions = ({ row }: any) => {
    return (
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          to={`${patientsUrl}${historyUrl}/${row.id}`}
          style={{
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          <IconButton>
            <FolderIcon />
          </IconButton>
        </Link>

        <IconButton>
          <PdfIcon onClick={() => ""} />
        </IconButton>
      </Grid>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "patient_uhid	",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("patient_uhid")}
        >
          <Typography variant="h5" fontSize={14}>
            UHID
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "patient_uhid" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "patient_uhid" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.patient_uhid}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "Name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.name}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "mobile",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("mobile")}
        >
          <Typography variant="h5" fontSize={14}>
            Mobile No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "mobile" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "mobile" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.mobile}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "patient_uhid" && value) {
      const finalValue = patientsList.filter((obj: any) => {
        if (obj.value === value) {
          return obj;
        }
      });
      dispatch(
        setFilterDataValues({
          key: "patientsList",
          name: name,
          value: finalValue[0],
        })
      );

      return;
    }

    dispatch(
      setFilterDataValues({ key: "patientsList", name: name, value: value })
    );

    dispatch(setCurrentPage({ key: "patientsList", value: 0 }));
  };

  const debouncedGetAllPatientsList = debounce(
    async (currentValue, prevOptions, callback) => {
      try {
        return await getAllPatients({
          search: currentValue,
          page:
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page
              : 1,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;
          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions?.some(
                (prevOption: any) => prevOption?.value === option?.value
              )
          );
          setPatientsList(uniqueOptions);
          searchPatientsPagination.current.page =
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page + 1
              : 1;
          searchPatientsPagination.current.search = currentValue;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
      } catch (error) {
        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
      }
    },
    300
  );

  // api call functions to get select options

  const getAllPatientsData = async (currentValue?: any, prevOptions?: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllPatientsList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page =
          patientsPagination.current === Math.ceil(prevOptions.length / 10)
            ? patientsPagination?.current
            : Math?.ceil(prevOptions?.length / 10) + 1;
        return await getAllPatients({
          search: currentValue,
          page: page,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList?.filter(
            (option: any) =>
              !prevOptions?.some(
                (prevOption: any) => prevOption?.value === option?.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setPatientsList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }
          const currentValue = patientsPagination?.current;
          patientsPagination.current = currentValue + 1;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const appointmentFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <InfiniteScroller
          loadOptions={getAllPatientsData}
          options={patientsList}
          isClearFilter={isClearFilter}
          setIsClearFilter={setIsClearFilter}
          label={"Select Patient"}
          handleOnChange={handleInputChange}
          name={"patient_uhid"}
          value={filterData.patient_uhid?.value}
          width={"100%"}
        />
      ),
    },
  ];

  useEffect(() => {
    getAllPatientsData();
    if (filterData.patient_uhid) {
      setPatientsList([filterData.patient_uhid]);
    }
  }, []);

  const debouncedGetAllPatients = useCallback(
    debounce((data: any) => {
      setIsDataTableLoading(true);

      getAllPatientsList(data)
        .then((res: any) => {
          if (res?.data?.result) {
            setPatientsData(res?.data?.result);
            setPageCount(res?.data?.totalRecords);
            setIsDataTableLoading(false);
          }
        })
        .catch((err) => {
          setIsDataTableLoading(false);
          console.log("error while getting patients list", err);
        });
    }, 300),
    []
  );

  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      limit: pageInfo.pageSize,
      column: sortField?.field,
      order: sortField?.order,
      patient_id: filterData.patient_uhid?.value,
      search: filterData.search,
    };
    debouncedGetAllPatients(data);
  }, [
    pageInfo?.page,
    pageInfo?.pageSize,
    sortField?.order,
    sortField?.field,
    filterData?.patient_uhid,
    filterData?.search,
  ]);

  return isPageLoading ? (
    <Grid
      sx={{
        width: "95vw",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        height: "90vh",
        background: "transparent",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Box>
      <CustomFilter
        data={appointmentFilter}
        isFilterEnabled={true}
        isSearchEnabled={true}
        clearFilter={clearFilters}
        searchOnChange={handleInputChange}
        searchName={"search"}
        appliedFilterCount={count}
        clearSearch={handleClearSearch}
        searchValue={filterData.search}
      />
      <DataTable
        loading={isDataTableLoading}
        columns={columns}
        getRowId={(row: any) => `${String(row.id)}`}
        rows={patientsData}
        pageCount={pageCount}
        currentPage={"patientsList"}
      />
    </Box>
  );
};

export default PatientsList;
