import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import HeaderForm from "./HeaderForm";
import "./index.css";
import ItemTable from "./ItemTable";
import Summary from "./Summary";
import {
  AddPatientDialog,
  ConfirmationDialog,
} from "../../../../components/shared";
import {
  getItemsByDepartment,
  getPatientDetails,
  getBillDetailById,
  getExcessAmountByPatientId,
  createPatientBill,
  updatePatientBill,
  deletePatientBillItemById,
  updatePatientBillTotalDetailsById,
  getPatientBillMasterData,
} from "../../../../services/billingService";
import { formula } from "../../../../utils/CalculationUtils";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { commonDateFormatter } from "../../../../utils/DateTimeFormatUtils";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { RouteUrls } from "../../../../constants/routes";
import moment from "moment";
import { PageLoader } from "../../../../components/basic";
import { RootState } from "../../../../redux/store";
import { updatedSequence } from "../../../../services/procurementMasterService";
import { getDepartmentSequence } from "../../../../services/mainCoreService";
import { SequenceWrapper } from "../../../../components/shared";
import { patientBillErrorMessages } from "../../../../constants/displayText";
import { getPrintUrl } from "../../../../utils/GeneralUtils";

const ItemTableMemoized = React.memo(ItemTable);

const { printUrl } = RouteUrls;

const { calculateRoundedOff, calculateNetAmount, calculatePaidAmount } =
  formula?.purchaseBill;

const ErrorModel = (props: {
  isOpen: boolean;
  onClose: () => void;
  content?: any;
}) => {
  const { isOpen, onClose, content } = props;

  return (
    <ConfirmationDialog
      open={isOpen}
      onClose={onClose}
      title={content}
      handleClick={onClose}
      confirmText="Okay"
      maxWidth="xs"
    />
  );
};
const BillsAddAndEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const patient_id = searchParams.get("patient_id");
  const patient_name = searchParams.get("patient_name");
  const doctor_id = searchParams.get("doctor_id");
  const type = searchParams.get("type");
  const op_id = searchParams.get("op_id");
  const op_no = searchParams.get("op_no");
  const address = searchParams.get("address");
  const mobile = searchParams.get("mobile");

  const [departmentOptions, setDepartmentOptions] = useState<any[]>([]);
  const seqRef: any = useRef();
  const itemRef = useRef();
  const { appConfiguration, commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [billData, setBillData] = useState<any>({
    patient_id: "",
    doctor_id: "",
    mobile: "",
    address: "",
    date_created: new Date(),
    payee: 1,
    bill_payment_type: 0,
    bill_no: "",
    refdoctor: "",
    rdType: "IP",
    ip_admission_id: "",
    ip_no_value: "",
    op_no: "",
    op_no_value: "",
    dept_id: "",
    items: [
      {
        id: null,
        item_id: "",
        type: "",
        item_name: "",
        consultant: "",
        desc: "",
        qty: "",
        discount: null,
        row_discount_type: "₹",
        tax: null,
        price: "",
        amount: "",
      },
    ],
    tax_amt: 0.0,
    total_amt: 0.0,
    bill_amt: 0.0,
    discount_val: 0,
    discount_type: "₹",
    discount_amt: 0.0,
    roundOff: 0.0,
    adj_amt: 0.0,
    net_total_amt: 0.0,
    excess: 0.0,
    advance: 0.0,
    overAllDue: 0.0,
    creditLimit: 0.0,
    payments: [
      {
        type: "Cash",
        value: "",
      },
    ],
    cashPaid: "",
    pay_paid_amt: 0.0,
    balance: 0.0,
    excess_amount: 0.0,
    refunded_excess: 0.0,
    net_excess: 0.0,
    print: "",
    bill_no_modified: 0,
  });
  const [isCreatePatient, setIsCreatePatient] = useState<boolean>(false);
  const [consultantList, setConsultantsList] = useState<any>([]);
  const [payeeOptions, setPayeeOptions] = useState<any>([]);
  const [referredDoctorsOptions, setReferredDoctorsOptions] = useState<any[]>(
    []
  );
  const [itemNameOptions, setItemNameOptions] = useState([]);
  const [billingPrintFormatOptions, setBillingPrintFormatOptions] = useState(
    []
  );
  const [typeOptions, setTypeOptions] = useState<
    | {
        id: string;
        name: string;
        disabled: boolean;
      }[]
    | []
  >([]);
  const [errors, setErrors] = useState({
    items: [],
  });
  const [isErrorModel, setIsErrorModel] = useState<{
    isOpen: boolean;
    content: any;
  }>({
    isOpen: false,
    content: "",
  });
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [initialPatientList, setInitialPatientList] = useState<any>([]);
  const [sequenceDialogBox, setSequenceDialogBox] = useState(false);
  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);
  const [isEditSequence, setIsEditSequence] = useState(false);
  const [seqIsOnce, setSeqIsOne] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });

  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });

  const [sequenceNo, setSequenceNo] = useState("");

  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<{
    isOpen: boolean;
    index: null | number;
    row: null | any;
  }>({
    isOpen: false,
    index: null,
    row: null,
  });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const handleChange = (event: any) => {
    const { name, value } = event?.target;

    setBillData((prevState: any) => {
      const newState = { ...prevState };

      if (name === "dept_id" && event.target?.department) {
        newState.department = event.target?.department?.name;
      }

      if (name === "op_no") {
        if (value?.patient_id) {
          newState.patient_id = value?.patient_id;
          newState.doctor_id = value?.doctor_id;
          newState.mobile = value?.mobile;
        }
        newState.op_no_value = value?.name;
        newState.ip_admission_id = "";
        newState.ip_no_value = "";
      }

      if (name === "ip_admission_id") {
        if (value?.patient_id) {
          newState.patient_id = value?.patient_id;
          newState.doctor_id = value?.doctor_id;
          newState.mobile = value?.mobile;
        }
        newState.ip_no_value = value?.name;
        newState.op_no = "";
        newState.op_no_value = "";
      }

      if (
        name === "patient_id" ||
        (name === "rdType" && value === "Counter Sales")
      ) {
        newState.op_no = "";
        newState.ip_admission_id = "";
        newState.ip_no_value = "";
        newState.op_no_value = "";
        // newState.patient_id = "";
      }

      if (name === "patient_id") {
        newState.mobile = value.mobile || "";
        newState.address = value.address || "";
      }

      return {
        ...newState,
        [name]: name === "patient_id" ? value?.id : value,
        items:
          name === "dept_id"
            ? [
                {
                  item_id: "",
                  type: "",
                  item_name: "",
                  consultant: "",
                  desc: "",
                  qty: "",
                  discount: null,
                  row_discount_type: "₹",
                  tax: null,
                  price: "",
                  amount: "",
                },
              ]
            : prevState.items,
      };
    });

    const newErrors = { ...errors } as any;
    if (newErrors[name] && value) {
      newErrors[name] = "";
      setErrors(newErrors);
    }
  };

  const handleAddItem = () => {
    setBillData((prevState: any) => ({
      ...prevState,
      items: [
        ...prevState.items,
        {
          id: null,
          item_id: "",
          type: "",
          item_name: "",
          consultant: "",
          desc: "",
          qty: "",
          discount: null,
          row_discount_type: "₹",
          tax: null,
          price: "",
          amount: "",
        },
      ],
    }));

    setErrors((prevState: any) => {
      const newErrors = { ...prevState };

      newErrors?.items?.push({});
      return newErrors;
    });
  };

  const handleDeleteItem = (index: number, row: any) => {
    setIsOpenDeleteModal({ isOpen: true, index, row });
  };

  const handleConfirmDelete = () => {
    if (isOpenDeleteModal?.row?.id) {
      setIsDeleteLoading(true);
      deletePatientBillItemById(isOpenDeleteModal?.row?.id)
        .then((res: any) => {
          if (res) {
            let net_total_amt = 0;
            let total_amt = 0;
            setBillData((prevState: any) => {
              let newState = { ...prevState };
              if (newState?.items?.length > 0) {
                newState?.items?.splice(isOpenDeleteModal?.index, 1);

                newState.tax_amt = calculateTotalTaxAmount(newState.items);
                newState.total_amt = calculateTotalAmount(newState.items);
                newState.bill_amt = calculateTotalAmount(newState.items);
                newState = calculatePaymentsSections(newState);

                net_total_amt = newState?.net_total_amt || 0;
                total_amt = newState?.total_amt || 0;
              }
              return newState;
            });

            updatePatientBillTotalDetailsById(id, {
              net_total_amt,
              total_amt,
            })
              .then((res) => {})
              .catch((err) => {
                setIsDeleteLoading(false);
                if (err?.data?.response?.errors) {
                  dispatch(
                    setSnackBarFailed({
                      snackBarMessage: err?.data?.response?.errors,
                    })
                  );
                }
              });
            setIsDeleteLoading(false);
            setIsOpenDeleteModal({
              isOpen: false,
              index: null,
              row: null,
            });
            dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
          }
        })
        .catch((err: any) => {
          setIsDeleteLoading(false);
          if (err?.data?.response?.errors) {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.data?.response?.errors,
              })
            );
          }
        });
    } else if (isOpenDeleteModal?.index || isOpenDeleteModal?.index === 0) {
      setBillData((prevState: any) => {
        let newState = { ...prevState };
        if (newState?.items?.length > 0) {
          newState?.items?.splice(isOpenDeleteModal?.index, 1);

          newState.tax_amt = calculateTotalTaxAmount(newState.items);
          newState.total_amt = calculateTotalAmount(newState.items);
          newState.bill_amt = calculateTotalAmount(newState.items);
          newState = calculatePaymentsSections(newState);
        }
        return newState;
      });
      setIsOpenDeleteModal({
        isOpen: false,
        index: null,
        row: null,
      });
    }
  };

  const calculateDiscountAmount = (
    amount: number,
    discount: number,
    discount_type: number | string
  ) => {
    if (amount && discount && discount_type) {
      if (discount_type === "₹") {
        return (amount - discount).toFixed(2);
      } else {
        const discountAmount = (amount * discount) / 100;

        return (amount - discountAmount).toFixed(2);
      }
    }

    return 0;
  };

  const calculateAmount = (data: any, index: number) => {
    if (!data.qty && !data.price) {
      return 0;
    } else {
      const totalAmount = data.qty * data.price;
      if (data.qty && data.price && data.discount && data.tax) {
        const withDiscount: any = calculateDiscountAmount(
          totalAmount,
          data.discount,
          data.row_discount_type
        );
        const tax = (Number(withDiscount) * data.tax) / 100;
        const withTax = Number(withDiscount) + tax;

        return withTax?.toFixed(2);
      } else if (data.discount) {
        const discountAmount = calculateDiscountAmount(
          totalAmount,
          data.discount,
          data.row_discount_type
        );

        return discountAmount || 0;
      } else if (data.tax) {
        const tax = (totalAmount * data.tax) / 100;
        const withTax = totalAmount + tax;

        return withTax?.toFixed(2) || 0;
      }

      return totalAmount;
    }
  };

  const calculateTotalAmount = (data: any[]) => {
    const totalAmount = data
      ?.map((row: any) => row.amount)
      .reduce(
        (accumulator: any, currentValue: any) =>
          accumulator + Number(currentValue),
        0
      );

    return totalAmount.toFixed(2) || 0;
  };

  const calculateTotalTaxAmount = (data: any[]) => {
    const totalAmount = data
      ?.map((row: any, index) => {
        const total = row.qty * row.price;
        let tax = 0;
        if (row.discount && row.row_discount_type) {
          const withDiscount: any = calculateDiscountAmount(
            total,
            row.discount,
            row.row_discount_type
          );
          tax = (Number(withDiscount) * (Number(row.tax) || 0)) / 100;
        } else {
          tax = (Number(total) * (Number(row.tax) || 0)) / 100;
        }

        return tax?.toFixed(2);
      })
      .reduce(
        (accumulator: any, currentValue: any) =>
          accumulator + Number(currentValue),
        0
      );

    return totalAmount.toFixed(2) || 0;
  };

  const calculatePaymentsSections = (state: any) => {
    const summaryData = { ...state };
    summaryData.discount_amt = calculateDiscountAmt(
      Number(summaryData.bill_amt),
      Number(summaryData.discount_val),
      summaryData.discount_type
    );
    summaryData.roundOff =
      calculateRoundedOff({
        bill_total: summaryData?.total_amt,
        bill_discount_amount: summaryData?.discount_amt,
      }) || 0;
    summaryData.net_total_amt =
      calculateNetAmount({
        bill_total: Number(summaryData?.total_amt),
        bill_discount_amount: Number(summaryData?.discount_amt),
        adjustment: Number(summaryData?.adj_amt),
        rounded_off: Number(summaryData?.roundOff),
      }) || 0;
    const totalPayments = summaryData?.payments
      ?.map((ele: any) => ele.value)
      .reduce(
        (accumulator: any, currentValue: any) =>
          accumulator + Number(currentValue),
        0
      );
    summaryData.pay_paid_amt = calculatePaidAmount(summaryData);

    if (!appConfiguration?.direct_excess) {
      summaryData.refunded_excess =
        totalPayments > summaryData?.net_total_amt
          ? totalPayments - summaryData?.net_total_amt
          : 0;
    }

    summaryData.excess_amount =
      totalPayments > summaryData?.net_total_amt
        ? totalPayments - summaryData?.net_total_amt
        : 0;

    if (!id) {
      summaryData.balance =
        summaryData?.net_total_amt > totalPayments
          ? summaryData?.net_total_amt - totalPayments
          : 0;
    }
    summaryData.net_excess = summaryData.excess_amount
      ? summaryData?.excess_amount - summaryData?.refunded_excess
      : 0;

    return summaryData;
  };

  const handleChangeItems = (event: any, index: number) => {
    const { name, value } = event.target;

    setBillData((prevState: any) => {
      let newState = { ...prevState };
      if (newState?.items?.length > 0) {
        newState.items[index][name] = value;
      }

      if (name === "item_id") {
        if (value && newState.items[index].item_name !== value.name) {
          newState.items[index].qty = value.qty;
          newState.items[index].consultant = Number(value.consultant);
          newState.items[index].price = Number(value.price);
          newState.items[index].desc = value.desc;
          newState.items[index].item_name = value.name;
          newState.items[index].type = value.type;
        }
      }

      if (name !== "desc" && name !== "consultant") {
        newState.items[index].amount = calculateAmount(
          newState.items[index],
          index
        );
        newState.tax_amt = calculateTotalTaxAmount(newState.items);
        newState.total_amt = calculateTotalAmount(newState.items);
        newState.bill_amt = calculateTotalAmount(newState.items);

        newState = calculatePaymentsSections(newState);
      }

      setErrors((prevState: any) => {
        const newErrors = { ...prevState };
        if (newErrors?.items?.length > 0 && newErrors?.items[index]) {
          newErrors.items[index][name] = "";
          if (
            newState?.items[index]?.discount &&
            newState?.items[index]?.price
          ) {
            newErrors.items[index].discount =
              newState.items[index].discount >
              newState.items[index].price * newState.items[index].qty
                ? "error"
                : "";
          }

          if (
            name === "item_id" &&
            (newErrors?.items[index]?.qty || newErrors?.items[index]?.price)
          ) {
            newErrors.items[index].qty = "";
            newErrors.items[index].price = "";
          }

          if (
            name === "discount" &&
            newState?.items[index]?.row_discount_type === "%" &&
            Number(value) > 100
          ) {
            newErrors.items[index].discount = "required";
          } else if (
            (name === "discount" || name === "row_discount_type") &&
            (newState?.items[index]?.row_discount_type !== "%" ||
              Number(value) < 100)
          ) {
            newErrors.items[index].discount = "";
          }
        }
        if (
          (name === "refunded_excess" &&
            newState?.excess_amount === 0 &&
            Number(newState?.refunded_excess)) ||
          newState?.refunded_excess > newState?.excess_amount
        ) {
          newErrors.refunded_excess = "error";
        } else {
          newErrors.refunded_excess = "";
        }

        return newErrors;
      });
      return newState;
    });
  };

  const calculateDiscountAmt = (
    total_amt: number,
    discount: number,
    type: number | string
  ) => {
    if (type === "₹") {
      return discount.toFixed(2);
    } else {
      return ((total_amt * discount) / 100).toFixed(2);
    }
  };

  const handleChangeSummaryData = (event: any) => {
    const { name, value } = event?.target;

    setBillData((prevState: any) => {
      let newState = {
        ...prevState,
        [name]: value,
      };

      newState = calculatePaymentsSections(newState);

      setErrors((prevState: any) => {
        const newErrors = { ...prevState };

        if (newErrors[name] && value) {
          newErrors[name] = "";
        }
        if (newState?.bill_amt && newState?.discount_amt) {
          newErrors.discount_amt =
            Number(newState?.discount_amt) > Number(newState?.bill_amt)
              ? "error"
              : "";
        }

        if (
          name === "discount_val" &&
          newState.discount_type === "%" &&
          value > 100
        ) {
          newErrors.discount_amt = "required";
        }

        if (
          (name === "refunded_excess" &&
            newState?.excess_amount === 0 &&
            Number(newState?.refunded_excess)) ||
          newState?.refunded_excess > newState?.excess_amount
        ) {
          newErrors.refunded_excess = "error";
        } else {
          newErrors.refunded_excess = "";
        }

        return newErrors;
      });

      return newState;
    });
  };

  const handleValidation = () => {
    const requiredFields = [
      "patient_id",
      // "mobile",
      "rdType",
      "doctor_id",
      "bill_no",
      "dept_id",
      "date_created",
      "payee",
    ];

    const itemsRequiredFields = ["item_id", "qty", "price"];

    let newErrors = {} as any;

    requiredFields.forEach((key) => {
      if (!billData[key]) {
        newErrors[key] = patientBillErrorMessages[key];
      }
    });

    if (billData.rdType === "IP" && !billData.ip_admission_id) {
      newErrors.ip_admission_id = "IP no is Required field";
    }
    if (billData.rdType === "OP" && !billData.op_no) {
      newErrors.op_no = "OP no is Required field";
    }

    if (
      billData.discount_val &&
      billData.discount_type === "%" &&
      billData.discount_value > 100
    ) {
      newErrors.discount_amt = "Required";
    }

    if (billData.items?.length !== 0) {
      newErrors.items = [];
      billData.items.forEach((ele: any) => {
        let itemError = {} as any;
        itemsRequiredFields.forEach((key) => {
          if (!ele[key]) {
            itemError[key] = `${key} is Required field`;
          }

          if (
            key === "discount" &&
            ele.row_discount_type === "%" &&
            ele.discount > 100
          ) {
            itemError[key] = `${key} is Required field`;
          }
        });
        newErrors.items.push(itemError);
      });
    } else {
      setIsErrorModel({
        isOpen: true,
        content: "Please add the items",
      });
      return false;
    }

    if (
      Number(billData?.pay_paid_amt) > Number(billData?.net_total_amt) &&
      Boolean(id)
    ) {
      setIsErrorModel({
        isOpen: true,
        content: "Please check the payments",
      });
      return false;
    }

    if (Number(billData?.net_total_amt) < Number(billData?.paid_amt)) {
      setIsErrorModel({
        isOpen: true,
        content:
          "Please check the net amount. Net amount cannot exceed the total paid amount",
      });
      return false;
    }

    if (billData?.refunded_excess > billData.pay_paid_amt) {
      setIsErrorModel({
        isOpen: true,
        content: "Please check the refund amount",
      });
      newErrors.refunded_excess = "error";
      return false;
    }

    // if (billData?.bill_payment_type === 0 && !id) {
    //   const hasPayment = billData.payments
    //     ?.map((ele: any) => ele.value)
    //     .reduce(
    //       (accumulator: any, currentValue: any) =>
    //         accumulator + Number(currentValue),
    //       0
    //     );

    //   if (!hasPayment) {
    //     newErrors.payments = [{ value: "Please add the payment" }];
    //   }
    // }

    if (!billData?.print && !id) {
      newErrors.print = "Please select the size";
    }

    let itemsHasError = false;

    if (newErrors?.items?.length > 0) {
      newErrors?.items?.forEach((item: any) => {
        if (Object.keys(item).length > 0) {
          itemsHasError = true;
        }
      });
    }

    if (!itemsHasError) {
      delete newErrors?.items;
    }

    if (Object.keys(newErrors)?.length > 0) {
      setErrors(newErrors);
      return false;
    } else {
      return true;
    }
  };

  const handleSave = () => {
    if (handleValidation()) {
      setIsSaveLoading(true);
      if (id) {
        updatePatientBill(billData.id, {
          id: billData.id,
          ...billData,
          ip_admission_id: billData?.ip_admission_id?.id || null,
          op_id: billData?.op_no?.id || null,
          date_created:
            commonDateFormatter(billData.date_created, "YYYY-MM-DD HH:mm:ss") ||
            null,
          items: billData?.items?.map((ele: any) => ({
            ...ele,
            item_id: ele?.item_id?.id,
            type: ele?.item_id?.type,
          })),
          bill_no_modified: billData.bill_no === initialSequenceNumber ? 0 : 1,
        })
          .then((res) => {
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Patient Bill Updated Successfully",
              })
            );
            setIsSaveLoading(false);
            navigate(`${RouteUrls.billingUrl}${RouteUrls.billingListUrl}`);
          })
          .catch((err) => {
            console.log(err);
            setIsSaveLoading(false);
          });
      } else {
        createPatientBill({
          ...billData,
          ip_admission_id: billData?.ip_admission_id?.id || null,
          op_id: billData?.op_no?.id || null,
          date_created:
            commonDateFormatter(billData.date_created, "YYYY-MM-DD HH:mm:ss") ||
            null,
          items: billData?.items?.map((ele: any) => ({
            ...ele,
            item_id: ele?.item_id?.id,
            type: ele?.item_id?.type,
            discount: ele?.discount || null,
          })),
          bill_no_modified: billData.bill_no === initialSequenceNumber ? 0 : 1,
        })
          .then((res: any) => {
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Patient bill Created Successfully",
              })
            );
            setIsSaveLoading(false);
            const pdfUrl = getPrintUrl(
              `billing/print_patient_bill/${res?.data?.id}/${billData?.print}`
            );
            const queryParams = new URLSearchParams({
              url: pdfUrl,
            }).toString();
            window.open(`${printUrl}?${queryParams}`, "_blank");
            navigate(`${RouteUrls.billingUrl}${RouteUrls.billingListUrl}`);
          })
          .catch((err) => {
            console.log(err);
            setIsSaveLoading(false);
            if (err?.response?.data?.errors) {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
            }
          });
      }
    }
  };

  const handleCloseErrorModal = () => {
    setIsErrorModel({ isOpen: false, content: "" });
  };

  useEffect(() => {
    if (billData.patient_id && !patient_id && !Boolean(id)) {
      getPatientDetails(billData.patient_id)
        .then((res: any) => {
          if (res.data) {
            const patientData = res.data;
            const addressParts = [
              patientData?.line_1 && patientData?.line_1 !== "-"
                ? patientData.line_1
                : "",
              patientData?.line_2 && patientData?.line_2 !== "-"
                ? patientData.line_2
                : "",
              patientData?.area && patientData?.area !== "-"
                ? patientData.area
                : "",
              patientData?.district ? patientData?.district : "",
              patientData?.state ? patientData?.state : "",
              patientData?.pincode ? patientData?.pincode : "",
            ];

            const address = addressParts.filter((part) => part).join("\n");

            setBillData((prevState: any) => ({
              ...prevState,
              mobile: patientData.mobile,
              address: address,
            }));

            const newErrors = { ...errors } as any;
            if (newErrors.mobile) {
              newErrors.mobile = "";
              setErrors(newErrors);
            }
          }
        })
        .catch((err) => console.log("error", err));

      getExcessAmountByPatientId({ patient_id: billData.patient_id })
        .then((res: any) => {
          if (res.data) {
            setBillData((prevState: any) => {
              const newState: any = { ...prevState };
              if (
                newState?.net_total_amt &&
                res?.data?.advance &&
                !Boolean(id)
              ) {
                newState.payments = [
                  {
                    type: "Advance",
                    value: res?.data?.advance,
                  },
                ];
              }
              return {
                ...newState,
                advance: res?.data?.advance,
                creditLimit: res?.data?.creditlimit,
                excess: res?.data?.excess,
                net_total: res?.data?.net_total,
                overAllDue: res?.data?.overalldue,
              };
            });
          }
        })
        .catch((err) => console.log("error", err));
    }
  }, [billData.patient_id]);

  useEffect(() => {
    if (!Boolean(id)) {
      getPatientBillMasterData().then((res: any) => {
        if (res.data) {
          console.log("res", res.data);
          if (res?.data?.doctors) {
            setConsultantsList((prevState: any) => [
              ...prevState,
              ...res?.data?.doctors,
            ]);
          }
          if (res?.data?.payees) {
            setPayeeOptions([...res?.data?.payees]);
          }
          if (res?.data?.refer_doctors) {
            setReferredDoctorsOptions([...res?.data?.refer_doctors]);
          }
          if (res?.data?.departments) {
            setDepartmentOptions(res?.data?.departments);
            if (res?.data?.departments?.length === 1) {
              handleChange({
                target: {
                  name: "dept_id",
                  value: res?.data?.departments[0]?.id,
                },
              });
            }
          }

          if (res?.data?.payment_terms) {
            let extraOptions = [];
            if (res?.data?.payment_terms) {
              extraOptions = res?.data?.payment_terms?.map(
                (ele: { id: number | string; name: string | number }) => ({
                  ...ele,
                  id: ele.name,
                  pay_term_id: ele.id,
                })
              );
            }
            setTypeOptions([...extraOptions]);
          }
        }
      });
    }
    setErrors((prevState: any) => {
      const newErrors = { ...prevState };
      newErrors.items =
        billData.items?.length === 0
          ? []
          : billData.items?.map((ele: any) => ({}));

      return newErrors;
    });
  }, []);

  useEffect(() => {
    if (commonVariables?.billing_print_formats) {
      setBillingPrintFormatOptions(commonVariables?.billing_print_formats);
    }
  }, [commonVariables?.billing_print_formats]);

  const getItemsOptionsByDepartment = () => {
    if (
      billData.dept_id ||
      billData.patient_id ||
      billData?.ip_admission_id?.id
    ) {
      getItemsByDepartment({
        department: billData.dept_id,
        patient_id: billData.patient_id,
        ip_id: billData?.ip_admission_id?.id,
      })
        .then((res: any) => {
          if (res.data) {
            const newItemOptions = [...res.data] as any;
            if (location.state?.bills?.length > 0) {
              location?.state?.bills?.forEach((ele: any) => {
                newItemOptions.push({
                  ...ele,
                  id: ele.draft_id,
                  name: ele.name,
                });
              });
            }

            if (billData?.items?.length > 0 && Boolean(id)) {
              billData?.items?.forEach((ele: any) => {
                newItemOptions.push({
                  ...ele,
                  id: ele.item_id,
                  name: ele.item_name,
                });
              });
            }
            setItemNameOptions(newItemOptions);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (billData.dept_id && departmentOptions?.length > 0) {
      const department: any = departmentOptions.find(
        (ele: { id: number }) => ele.id === billData.dept_id
      );
      getItemsOptionsByDepartment();
      seqRef.current = department?.name;
      if (department?.name) {
        handleSequence(department?.name);
      }
    }
  }, [billData.dept_id, departmentOptions]);

  function onCloseSequenceDialogBox() {
    setSequenceDialogBox(false);
  }

  let isCreatePrescription = true;
  let isSequenceNumberChanged = false;

  const handleSequence = (billNumber?: string) => {
    getDepartmentSequence(
      billNumber === undefined ? seqRef.current : billNumber
    )
      .then((res: any) => {
        setIsPageLoading(true);

        let data = res?.data[0]?.sequence_no;

        if (
          isCreatePrescription &&
          !isSequenceNumberChanged &&
          (!isEditSequence || !id)
        ) {
          setInitialSequenceNumber(data);
          setBillData((prevState: any) => ({
            ...prevState,
            bill_no: data,
          }));

          setErrors((prevState: any) => ({
            ...prevState,
            bill_no: "",
          }));
        }

        if (isSequenceNumberChanged) {
          setBillData((prevState: any) => ({
            ...prevState,
            bill_no: data,
          }));
          setErrors((prevState: any) => ({
            ...prevState,
            bill_no: "",
          }));
        }
        if (res?.data?.length > 0) {
          setSequenceNo((prev) => (prev = res.data[0].sequence.id));
          const newSequenceData = res.data[0].sequence.sequence_preferences.map(
            (ele: {
              id: number;
              type: string;
              value: string;
              separator: string;
            }) => ({
              id: ele.id,
              type: ele.type,
              value: ele.value,
              separator: ele.separator,
            })
          );

          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual: res.data[0].sequence.is_manual === 1 ? true : false,
            is_auto_generate:
              res.data[0].sequence.is_manual !== 1 ? true : false,
          }));
          setSeqIsOne((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          const newErrors = { ...errors } as any;
          if (newErrors.bill_no) {
            newErrors.bill_no = "";
            setErrors(newErrors);
          }
          setIsEditSequence(false);
        }
        setIsPageLoading(false);
      })
      .catch((err: any) => {
        setIsPageLoading(false);
        if (billData?.bill_no) {
          setBillData((prevState: any) => ({
            ...prevState,
            bill_no: "",
          }));
          setSequenceData([{ id: null, type: "", value: "", separator: "" }]);
        }
        if (err?.response?.data?.errors) {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: err.response.data.errors,
            })
          );
        }
      });
  };

  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value === "Select" ? "" : value,
      };
      return newState;
    });
  };
  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      const data = {
        seq_preference: [...sequenceData],
        is_manual: isManual.is_manual ? 1 : 0,
      };
      await updatedSequence(Number(sequenceNo), data)
        .then((res: any) => {
          if (res) {
            isSequenceNumberChanged = true;
            handleSequence(seqRef.current);
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(setSnackBarFailed({ snackBarMessage: err?.message }));
        });
    }
  };

  const handleConfirmSequenceNoChange = () => {
    if (initialSequenceNumber !== billData.bill_no) {
      setSeqIsOne((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: billData.bill_no,
      }));
      setSequenceDialogBox(true);
    }
  };

  const handleSequenceCheckBoxChange = (e: any) => {
    const { name } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };

  useEffect(() => {
    if (id) {
      setIsPageLoading(true);
      getBillDetailById({ id, type: "edit" })
        .then((res: any) => {
          if (res.data) {
            const { bill, bill_items } = res.data;
            const addressParts = [
              bill?.line_1 && bill?.line_1 !== "-" ? bill.line_1 : "",
              bill?.line_2 && bill?.line_2 !== "-" ? bill.line_2 : "",
              bill?.area && bill?.area !== "-" ? bill.area : "",
              bill?.district ? bill?.district : "",
              bill?.state ? bill?.state : "",
              bill?.pincode ? bill?.pincode : "",
            ];

            const address = addressParts.filter((part) => part).join("\n");
            setBillData({
              ...bill,
              mobile: bill?.mobile,
              bill_no: bill.bill_no,
              // ip_no_value: "",
              // op_no_value: "",
              date_created: bill.date_created
                ? moment(bill.date_created, "YYYY-MM-DD HH:mm:ss").format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )
                : null,
              discount_type: bill.discount_type || "₹",
              rdType: bill.ip_admission_id
                ? "IP"
                : bill.op_no
                ? "OP"
                : "Counter Sales",
              op_no: bill.op_id ? { id: bill?.op_id, name: bill?.op_no } : null,
              op_no_value: bill.op_id ? bill?.op_no : null,
              ip_no_value: bill.ip_admission_id ? bill?.ip_no : null,
              ip_admission_id: bill.ip_admission_id
                ? { id: bill?.ip_admission_id, name: bill?.ip_no }
                : null,
              items: bill_items,
              address,
            });
            if (res?.data?.master_data?.doctors) {
              setConsultantsList((prevState: any) => [
                ...prevState,
                ...res?.data?.master_data?.doctors,
              ]);
            }
            if (res?.data?.master_data?.payees) {
              setPayeeOptions([...res?.data?.master_data?.payees]);
            }
            if (res?.data?.master_data?.refer_doctors) {
              setReferredDoctorsOptions([
                ...res?.data?.master_data?.refer_doctors,
              ]);
            }
            if (res?.data?.bill?.department && res?.data?.bill?.dept_id) {
              setDepartmentOptions([
                {
                  id: res?.data?.bill?.dept_id,
                  name: res?.data?.bill?.department,
                },
              ]);
            }
            setInitialSequenceNumber(bill.bill_no);
            isCreatePrescription = false;
            isSequenceNumberChanged = false;
          }
          setIsPageLoading(false);
          // seqRef.current = res?.data?.bill?.department;
          // handleSequence(res?.data?.bill?.department);
          setIsEditSequence(true);
        })
        .catch((err) => {
          console.log(err);
          setIsPageLoading(false);
        });
      if (billData.dept_id) {
        getItemsOptionsByDepartment();
      }
    } else {
      isCreatePrescription = true;
      isSequenceNumberChanged = false;
    }
  }, [id]);

  useEffect(() => {
    if (location.state) {
      setBillData((prevState: any) => {
        let newState = {
          ...prevState,
          patient_id: location?.state?.patient_id || "",
          patient_name: location?.state?.patient_name || "",
          uhid: location?.state?.uhid || "",
          doctor_id: location?.state?.doctor_id || "",
          department: { name: location?.state?.dept_name || "" },
          mobile: location?.state?.mobile || "",
          dept_id: Number(location?.state?.dept_id) || "",
          rdType: location?.state?.draft_type || "",
          items:
            location?.state?.bills?.map((ele: any) => ({
              ...ele,
              item_id: {
                id: ele.id,
                name: ele.name,
              },
              tax: null,
              item_name: ele.name,
              row_discount_type: "₹",
              qty: ele.qty,
              consultant: Number(ele.consultant),
              amount: (Number(ele.qty) || 0) * (Number(ele.price) || 0),
            })) || [],
        };
        newState.tax_amt = calculateTotalTaxAmount(newState.items);
        newState.total_amt = calculateTotalAmount(newState.items);
        newState.bill_amt = calculateTotalAmount(newState.items);
        newState = calculatePaymentsSections(newState);
        return newState;
      });

      setInitialPatientList([
        {
          value: location?.state?.patient_id,
          label: `${location?.state?.uhid} - ${location?.state?.patient_name}`,
        },
      ]);
    }
  }, [location.state]);

  useEffect(() => {
    if (patient_id) {
      setBillData((prevState: any) => ({
        ...prevState,
        patient_id: patient_id || "",
        patient_name: patient_name || "",
        doctor_id: Number(doctor_id) || "",
        rdType: type,
        op_no: { id: Number(op_id), name: op_no },
        op_no_value: op_no,
        mobile: mobile,
        address: address,
      }));
    }
  }, [patient_id, patient_name, type, op_no, doctor_id, op_no]);

  return (
    <>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <Grid
          className="billing"
          sx={{
            height: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <HeaderForm
            data={billData}
            consultantList={consultantList}
            payeeOptions={payeeOptions}
            setPayeeOptions={setPayeeOptions}
            referredDoctorsOptions={referredDoctorsOptions}
            setReferredDoctorsOptions={setReferredDoctorsOptions}
            initialPatientList={initialPatientList}
            onChange={handleChange}
            onCreatePatient={() => setIsCreatePatient(true)}
            onEditPatient={() => setIsCreatePatient(true)}
            error={errors}
            departmentOptions={departmentOptions}
            setSequenceDialogBox={setSequenceDialogBox}
            handleConfirmSequenceNoChange={handleConfirmSequenceNoChange}
          />
          <ItemTableMemoized
            errors={errors?.items}
            data={billData}
            consultantList={consultantList}
            itemNameOptions={itemNameOptions}
            onAddItem={handleAddItem}
            onDeleteItem={handleDeleteItem}
            onChangeItem={handleChangeItems}
            getItemsOptionsByDepartment={getItemsOptionsByDepartment}
            isEdit={!!id}
            itemRef={itemRef}
          />
          <Summary
            error={errors}
            data={billData}
            billingPrintFormatOptions={billingPrintFormatOptions}
            onChange={handleChangeSummaryData}
            onSave={handleSave}
            isEdit={!!id}
            isSaveLoading={isSaveLoading}
            paymentTypeOptions={typeOptions}
          />
          {isCreatePatient && (
            <AddPatientDialog
              open={isCreatePatient}
              onClose={() => setIsCreatePatient(false)}
              patientId={billData.patient_id}
            />
          )}
          {isErrorModel.isOpen && (
            <ErrorModel
              isOpen={isErrorModel.isOpen}
              content={isErrorModel.content}
              onClose={handleCloseErrorModal}
            />
          )}
        </Grid>
      )}
      <SequenceWrapper
        open={sequenceDialogBox}
        title="Pharmacy Bill"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
      {isOpenDeleteModal?.isOpen && (
        <ConfirmationDialog
          open={isOpenDeleteModal.isOpen}
          title={"Do you want to delete this product?"}
          confirmText="Yes"
          handleClick={handleConfirmDelete}
          onClose={() =>
            setIsOpenDeleteModal({ isOpen: false, index: null, row: null })
          }
          loading={isDeleteLoading}
        />
      )}
    </>
  );
};

export default BillsAddAndEdit;
