import React from "react";
import { Badge, Box, useTheme, Typography } from "@mui/material";
import { type ChangeEvent } from "react";
import CustomButton from "../../basic/button";
import { FilterIcon, SearchIcon } from "../../../assets/icons";

interface Props {
  title?: string;
  subtitle?: string;
  addButtonTitle?: string;
  addButtonTitle2?: string;
  editButtonTitle?: string;
  isShowExport?: boolean;
  searchEnabled?: boolean;
  onAddButtonClick?: () => void;
  onAddButtonClick2?: () => void;
  onUploadButtonClick?: (e: ChangeEvent<HTMLInputElement>) => void;
  onUpdateButtonClick?: () => void;
  onExportClick?: () => void;
  handleSearch?: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  flag?: boolean;
  isFilterEnabled?: boolean;
  handleFilterClick?: () => void;
  handleSearchClick?: () => void;
  isSearchOpen?: boolean;
  FilterElement?: any;
  SearchElement?: any;
  isFilterOpen?: boolean;
}

const TableHeader = React.memo(
  ({
    title,
    subtitle,
    addButtonTitle,
    addButtonTitle2,
    editButtonTitle,
    isShowExport,
    onAddButtonClick,
    onAddButtonClick2,
    onUpdateButtonClick,
    onUploadButtonClick,
    onExportClick,
    flag,
    searchEnabled,
    handleSearch,
    isFilterEnabled,
    handleFilterClick,
    handleSearchClick,
    FilterElement,
    SearchElement,
    isFilterOpen,
    isSearchOpen,
  }: Props): JSX.Element => {
    const currentTheme = useTheme();
    const colors = currentTheme.palette;
    const styles = {
      mainBox: {
        display: "flex",
        justifyContent: "space-between",
        pb: 2,
        alignItems: "center",
      },
      buttonGroupBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        width: "100%",
      },
      editButton: {
        textTransform: "none",
        height: 40,
        width: 100,
        margin: 1,
        maxWidth: "max-content",
      },
      addButton: {
        textTransform: "none",
        height: 40,
        width: "100%",
        maxWidth: "max-content",
        margin: 1,
      },
      filterStyles: {
        textAlign: "center",
        alignItems: "center",
        cursor: "pointer",
        paddingBottom: "10px",
        margin: 1,
        marginRight: 2,
      },
      searchStyles: {
        textAlign: "center",
        alignItems: "center",
        cursor: "pointer",
        padding: "1rem",
      },
      filterIconBadge: { padding: "2px" },
    };

    return (
      <>
        <Box sx={styles.mainBox}>
          {title && (
            <Typography variant="h1" sx={{ width: "500px", pl: 1 }}>
              {title}
            </Typography>
          )}
          <Box sx={styles.buttonGroupBox}>
            {editButtonTitle && (
              <CustomButton
                variant="contained"
                buttonText={editButtonTitle}
                sx={styles.editButton}
                backgroundColor="#207DFF"
                textColor="#FFFFFF"
                border={"none"}
                onClick={onUpdateButtonClick}
              />
            )}
            {addButtonTitle && !flag && (
              <CustomButton
                variant="outlined"
                buttonText={addButtonTitle}
                textColor={"#207DFF"}
                onClick={onAddButtonClick}
                sx={styles.addButton}
                border={"1px solid #207DFF"}
              />
            )}
            {addButtonTitle2 && !flag && (
              <CustomButton
                variant="contained"
                buttonText={addButtonTitle2}
                sx={{
                  height: 40,
                  bgcolor: colors.primary.lighter,
                  borderRadius: 2,
                  textTransform: "none",
                  margin: "8px 10px",
                }}
                onClick={onAddButtonClick2}
              >
                {addButtonTitle2}
              </CustomButton>
            )}
            {addButtonTitle && flag && (
              <CustomButton
                variant="contained"
                buttonText={addButtonTitle}
                component="label"
              >
                Upload File
                <input
                  onChange={onUploadButtonClick}
                  hidden
                  accept=".csv,text/xml"
                  type="file"
                />
              </CustomButton>
            )}
            {addButtonTitle && !flag && (
              <CustomButton
                variant="outlined"
                buttonText={addButtonTitle}
                textColor={"#207DFF"}
                onClick={onAddButtonClick}
                sx={styles.addButton}
                border={"1px solid #207DFF"}
              />
            )}
            {addButtonTitle2 && !flag && (
              <CustomButton
                variant="contained"
                buttonText={addButtonTitle2}
                sx={{
                  height: 40,
                  bgcolor: colors.primary.lighter,
                  borderRadius: 2,
                  textTransform: "none",
                  margin: "0 10px 0",
                }}
                onClick={onAddButtonClick2}
              >
                {addButtonTitle2}
              </CustomButton>
            )}
            {addButtonTitle && flag && (
              <CustomButton
                variant="contained"
                buttonText={addButtonTitle}
                component="label"
              >
                Upload File
                <input
                  onChange={onUploadButtonClick}
                  hidden
                  accept=".csv,text/xml"
                  type="file"
                />
              </CustomButton>
            )}
            {isShowExport && (
              <CustomButton
                variant="contained"
                sx={{
                  height: 40,
                  bgcolor: colors.primary.lighter,
                  borderRadius: 2,
                  textTransform: "none",
                  margin: "0 10px 0",
                }}
                onClick={onExportClick}
              >
                Export
              </CustomButton>
            )}
            {isFilterEnabled && (
              <Box sx={styles.filterStyles} onClick={handleFilterClick}>
                <Badge
                  badgeContent={"2"}
                  color="error"
                  sx={styles.filterIconBadge}
                >
                  <FilterIcon />
                </Badge>
              </Box>
            )}
            {searchEnabled && (
              <Box sx={styles.searchStyles} onClick={handleSearchClick}>
                <SearchIcon />
              </Box>
            )}
          </Box>

          {isFilterOpen && FilterElement ? (
            <div
              style={{
                width: "100%",
                marginBottom: "1rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="filter-grid-parent"
            >
              <FilterElement />
            </div>
          ) : null}

          {isSearchOpen && SearchElement ? (
            <div
              style={{
                width: "100%",
                marginBottom: "1rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="filter-grid-parent"
            >
              <SearchElement />
            </div>
          ) : null}
        </Box>
      </>
    );
  }
);

export default TableHeader;
