import React from "react";
import { Grid } from "@mui/material";
import { SettingsIcon } from "../../../assets/icons";
import {
  Select,
  DatePickerWithTime,
  TextField,
} from "../../../components/basic";
import { purchaseBillInfoConst } from "../../../constants/displayText";
import { InfiniteScroller } from "../../../components/basic";

type InfoType = {
  supplier_name: string | null;
  department: string | null;
  invoice_value: string | null;
  invoice_date: string | null;
  bill_no: string | null;
};

type InfoProps = {
  info: InfoType;
  setInfo: React.Dispatch<React.SetStateAction<InfoType>>;
  suppliersList: any;
  departmentList: any;
  isGoodsIssue: any;
  loadOptions: any;
  setIsSequenceWrapperOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsManual: React.Dispatch<React.SetStateAction<any>>;
  isManualAdded: any;
  isManual: any;
  errors: any;
  handleConfirmSequenceNoChange: () => void;
  customSequence?: boolean;
};

const Info = React.memo(
  ({
    info,
    setInfo,
    suppliersList,
    departmentList,
    isGoodsIssue,
    loadOptions,
    setIsSequenceWrapperOpen,
    setIsManual,
    isManualAdded,
    isManual,
    errors,
    handleConfirmSequenceNoChange,
    customSequence,
  }: InfoProps) => {
    const {
      supplierName,
      department,
      invoiceValue,
      invoiceDate,
      billSequenceNo,
    } = purchaseBillInfoConst;

    const handleInputChange = (e: any) => {
      const { name, value } = e.target;
      setInfo((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    };

    const handleDateChange = (newValue: any) => {
      setInfo((prev: any) => ({
        ...prev,
        invoice_date: newValue.toString(),
      }));
    };

    const styles = {
      selectGrid: {
        width: "100%",
        height: "44px",
        boxShadow: "none",
        borderRadius: "5px",
        borderColor: "var(--primary-border-color)",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },

      textFieldStyle: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
          height: "44px",
          borderRadius: "5px",
          paddingLeft: "8px",
          boxShadow: "none",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
    };

    return (
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          m: "0px 0px 30px 0px",
          width: "90vw",
          minHeight: "100px",
          height: "auto",
          gap: 1,
        }}
      >
        <Grid item xs={12} sm={5.9} md={2.9} lg={2} xl={2}>
          <InfiniteScroller
            loadOptions={loadOptions}
            options={suppliersList}
            handleOnChange={handleInputChange}
            placeholder={"Supplier Name"}
            label={supplierName}
            name="supplier_name"
            value={info.supplier_name}
            width="100%"
          />
        </Grid>
        <Grid item xs={12} sm={5.9} md={2.9} lg={2} xl={2}>
          <Select
            value={info.department}
            onChange={handleInputChange}
            placeholder={isGoodsIssue ? "Select Receiver" : "Select Department"}
            options={departmentList}
            label={isGoodsIssue ? "Receiver" : department}
            name="department"
            formControlStyle={{ width: "100%" }}
            width=""
            sx={{
              ...styles.selectGrid,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5.9} md={2.9} lg={2} xl={2}>
          <TextField
            value={info.invoice_value}
            label={invoiceValue}
            placeholder="Enter Invoice Value"
            name="invoice_value"
            onChange={handleInputChange}
            formControlStyle={{ width: "100%" }}
            disabled
            sx={{
              ...styles.textFieldStyle,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5.9}
          md={2.9}
          lg={2}
          xl={2}
          sx={{
            "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container  >div >div .rmdp-input":
              {
                boxSizing: "border-box",
                width: "100%",
              },
          }}
        >
          <DatePickerWithTime
            value={info.invoice_date}
            label={invoiceDate}
            onChange={handleDateChange}
            borderColor="var(--primary-border-color)"
            placeholder="Enter Invoice Date"
            formControlStyle={{ width: "100%" }}
            style={{
              boxShadow: "none",
              height: "44px",
              borderRadius: "5px",
            }}
          />
        </Grid>
        {customSequence ? (
          <Grid item xs={12} sm={5.9} md={2.9} lg={2} xl={2}>
            <TextField
              value={info.bill_no}
              label={billSequenceNo}
              // helperText={errors?.bill_no}
              placeholder="Enter Bill Number"
              onChange={handleInputChange}
              onBlur={handleConfirmSequenceNoChange}
              endPlaceholderIcon={
                <SettingsIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsSequenceWrapperOpen(true);
                  }}
                />
              }
              name="bill_no"
              formControlStyle={{ width: "100%" }}
              sx={{
                ...styles.textFieldStyle,
              }}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }
);

export default Info;
