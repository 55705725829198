import { useCallback, useEffect, useState } from "react";
import { Typography, Box, IconButton, debounce } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DataTable } from "../../../../components/shared";
import { Sort } from "../../../../components/basic";
import { DeleteIcon, EditIcon, PlusIcon, ViewIcon } from "../../../../assets/icons";
import { RouteUrls } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import CustomFilter from "../../../../components/shared/customFilter";
import { getAllWholesSalesPayment } from "../../../../services/procurementService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { setIsAdvance } from "../../../../redux/slices/procurement";
import { formatTwelveHoursTime } from "../../../../utils/DateTimeFormatUtils";
import { deleteWholeSalesPayment } from "../../../../services/gynecologyService";
import {
  setCurrentPage,
  setFilterDataValues,
  setHandleClearSearch,
  setSortField,
} from "../../../../redux/slices/pagination";

type GridRowData = Record<string, unknown>;

const PurchasePaymentList = () => {
  const { procurementUrl, createUrl, editUrl, viewUrl, wholeSalesPayments } =
    RouteUrls;
  const navigate = useNavigate();
  const [purchasePaymentsList, setPurchasePaymentsList] = useState<[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<string | number | null>("");
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const { b2bPayment, filterData, count, sortField } = useSelector(
    (state: RootState) => ({
      b2bPayment: state?.pagination?.b2bPayment,
      filterData: state?.pagination?.b2bPayment?.filterData,
      count: state?.pagination?.b2bPayment?.count,
      sortField: state?.pagination?.b2bPayment?.sortedField,
    })
  );
  const dispatch = useDispatch();
  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortField?.field === field) {
      dispatch(
        setSortField({
          key: "b2bPayment",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
    if (sortField?.field !== field) {
      dispatch(
        setSortField({
          key: "b2bPayment",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          aria-label=""
          onClick={() => {
            navigate(
              `${procurementUrl}${wholeSalesPayments}${editUrl}/${row.id}`
            );
            dispatch(setIsAdvance(row.is_advance));
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label=""
          onClick={() => {
            navigate(
              `${procurementUrl}${wholeSalesPayments}${viewUrl}/${row.id}`
            );
          }}
        >
          <ViewIcon />
        </IconButton>
        <IconButton aria-label="" onClick={() => deletePaymentById(row.id)}>
          <DeleteIcon />
        </IconButton>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },

    {
      field: "date_created",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("date_created")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "date_created" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "date_created" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.date_created ? formatTwelveHoursTime(row.date_created) : ""}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "cash_paid",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("cash_paid")}
        >
          <Typography variant="h5" fontSize={14}>
            Cash Paid
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "cash_paid" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "cash_paid" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.cash_paid}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "type",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("type")}
        >
          <Typography variant="h5" fontSize={14}>
            Type
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "type" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "type" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.type}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  const debouncedGetAllAppointment = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllWholesSalesPayment({
        page: data.page,
        limit: data.pageSize,
        column: data.sortBy,
        order: data.sortOrder,
        search: data.search,
        sortBy: data.sortBy,
        sortOrder: data.sortOrder,
      })
        .then((res) => {
          setPurchasePaymentsList(res.data.result);
          setPageCount(res.data.total as any);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("error getAppointmentList", err);
        });
    }, 300),
    []
  );
  useEffect(() => {
    dispatch(setIsAdvance(null));
    const data = {
      page: Number(b2bPayment.page) + 1,
      pageSize: b2bPayment.pageSize,
      sortBy: sortField?.field,
      sortOrder: sortField?.order,
      search: filterData.search,
    };
    debouncedGetAllAppointment(data);
  }, [
    b2bPayment.page,
    b2bPayment.pageSize,
    sortField?.order,
    sortField?.field,
    filterData.search,
  ]);
  const deletePaymentById = async (id: number | string) => {
    await deleteWholeSalesPayment(id as number)
      .then((res: any) => {
        debouncedGetAllAppointment({
          page: b2bPayment.page,
          pageSize: b2bPayment.pageSize,
        });
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
      })
      .catch((err) => {
        dispatch(
          setSnackBarFailed({ snackBarMessage: err.response.data.errors })
        );
        console.log(err);
      });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    dispatch(
      setFilterDataValues({ key: "b2bPayment", name: name, value: value })
    );
    dispatch(setCurrentPage({ key: "b2bPayment", value: 0 }));
  };
  return (
    <Box>
      <CustomFilter
        editButtonTitle="Add"
        isSearchEnabled={true}
        searchOnChange={handleInputChange}
        onUpdateButtonClick={() => {
          navigate(`${procurementUrl}${wholeSalesPayments}${createUrl}`);
        }}
        searchName={"search"}
        searchValue={filterData.search}
        clearSearch={() =>
          dispatch(
            setHandleClearSearch({
              key: "b2bPayment",
              value: "",
              name: "search",
            })
          )
        }
        editStartIcon={
          <Box
            sx={{
              "& path": {
                fill: "#fff",
              },
            }}
          >
            <PlusIcon width={"12px"} height={"12px"} />
          </Box>
        }
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={purchasePaymentsList}
        pageCount={pageCount}
        currentPage={"b2bPayment"}
      />
    </Box>
  );
};

export default PurchasePaymentList;
