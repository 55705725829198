import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { displayText } from "../../../constants/displayText";

type ButtonProps = {
  variant?: "text" | "outlined" | "contained";
  buttonText?: string;
  handleClick?: () => void;
  sx?: {};
  children?: string;
  isDisabled?: boolean;
  startIcon?: any;
  buttonSize?: "sm" | "md" | "lg" | "xl" | undefined;
};

const commonStyle = {
  sm: {
    minWidth: "88px",
    height: "32px",
    fontSize: "12px",
    padding: "6px 12px",
  },
  md: {
    minWidth: "90px",
    height: "40px",
    fontSize: "14px",
    padding: "10px 20px",
  },
  lg: {
    minWidth: "112px",
    height: "48px",
    fontSize: "16px",
    padding: "14px 28px",
  },
  xl: {
    minWidth: "120px",
    height: "56px",
    fontSize: "16px",
    padding: "18px 32px",
  },
};

const CancelButton = ({
  variant = "outlined",
  buttonText = displayText.CANCEL,
  handleClick,
  sx,
  isDisabled,
  startIcon,
  buttonSize = "md",
}: ButtonProps) => {
  const navigate = useNavigate();
  const styles = {
    boxShadow: "none",
    borderRadius: "6px",
    width: "fit-content",
    height: "40px",
    fontWeight: 400,
    fontSize: 14,
    textTransform: "none",
    borderColor: "greyScale.light",
    color: "greyScale.light",
    backgroundColor: "backgroundPrimary",
    "&:hover": {
      backgroundColor: "initial",
      color: "primary.main",
      borderColor: "primary.main",
    },
  };
  return (
    <>
      <Button
        disabled={isDisabled}
        variant={variant}
        onClick={handleClick ? handleClick : () => navigate(-1)}
        disableRipple
        startIcon={startIcon ? startIcon : null}
        sx={{
          ...styles,
          ...commonStyle[buttonSize],
          ...sx,
        }}
      >
        {buttonText}
      </Button>
    </>
  );
};

export default CancelButton;
