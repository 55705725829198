import React from "react";
import { Typography } from "@mui/material";

type LabelProps = {
  children?: React.ReactNode;
  label?: string | undefined;
  variant?:
    | "body1"
    | "body2"
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "inherit"
    | "overline"
    | "subtitle1"
    | "subtitle2";
  sx?: {} | undefined;
};

function Label({ label = "label", variant = "h6", sx }: LabelProps) {
  const styles = {
    color: "var(--primary-label-color)",
    fontSize: "var(--primary-label-font-size)",
  };
  return (
    <Typography variant={variant} sx={{ ...styles, ...sx }}>
      {label}
    </Typography>
  );
}

export default Label;
