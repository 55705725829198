import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  AutoCompleteWithCheckBoxes,
  Button,
  DatePicker,
  SaveButton,
  Select,
  TextField,
} from "../../../components/basic";
import {
  SearchPlaceholderIcon,
  VaccinesDpIcon,
  TimeIcon,
  DeleteIcon,
  EditBlackColorIcon,
  CompleteIcon,
  PendingIcon,
} from "../../../assets/icons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { DialogWrapper } from "../../../components/shared";

import { useNavigate } from "react-router-dom";
import { RouteUrls } from "../../../constants/routes";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../utils/ValidationUtils";
import TablePagination from "../../../components/shared/dataTable/TablePagination";

const VaccinesList = () => {
  const styles = {
    selectStyle: {
      width: "300px",
      height: "48px",
      boxShadow: "none",
      borderRadius: "8px",
      borderColor: "#E5E7EB",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E5E7EB",
      },
      "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E5E7EB",
      },
      marginRight: "130px",
    },
  };

  const initialError = {
    duration: "",
    vaccines: "",
    dueDate: "",
    givenDate: "",
    height: "",
    weight: "",
    headCircumference: "",
  };
  const ListData = {
    patient_name: "",
    vaccines: "",
    due_date: null,
  };

  const initialData = {
    duration: null,
    vaccines: [],
    dueDate: null,
    givenDate: null,
    height: "",
    weight: "",
    headCircumference: "",
  };

  const [vaccineFormError, setVaccineFormError] = useState<any>(initialError);

  const [vaccineFormData, setVaccineFormData] = useState<any>(initialData);

  const fieldData = {
    duration: {
      label: "Duration",
      name: "duration", // Include the name field
      value: vaccineFormData?.duration,
      isError: vaccineFormError?.duration === "" ? false : true,
      helperText: vaccineFormError?.duration,
    },
    vaccines: {
      label: "Vaccines",
      name: "vaccines", // Include the name field
      value: vaccineFormData?.vaccines,
      isError: vaccineFormError?.vaccines === "" ? false : true,
      helperText: vaccineFormError?.vaccines,
    },
    dueDate: {
      label: "Due Date",
      name: "dueDate", // Include the name field
      value: vaccineFormData?.dueDate,
      isError: vaccineFormError?.dueDate === "" ? false : true,
      helperText: vaccineFormError?.dueDate,
    },
    givenDate: {
      label: "Given Date",
      name: "givenDate", // Include the name field
      value: vaccineFormData?.givenDate,
      isError: vaccineFormError?.givenDate === "" ? false : true,
      helperText: vaccineFormError?.givenDate,
    },
    height: {
      label: "Height(cm)",
      name: "height", // Include the name field
      value: vaccineFormData?.height,
      isError: vaccineFormError?.height === "" ? false : true,
      helperText: vaccineFormError?.height,
    },
    weight: {
      label: "Weight(kg)",
      name: "weight", // Include the name field
      value: vaccineFormData?.weight,
      isError: vaccineFormError?.weight === "" ? false : true,
      helperText: vaccineFormError?.weight,
    },
    headCircumference: {
      label: "Head Circumference",
      name: "headCircumference", // Include the name field
      value: vaccineFormData?.headCircumference,
      isError: vaccineFormError?.headCircumference === "" ? false : true,
      helperText: vaccineFormError?.headCircumference,
    },
  };

  const initialListData = {
    patientName: "",
    vaccines: "",
    dueDate: null,
  };

  const [vaccineListData, setVaccineListData] = useState<any>(initialListData);

  const listData = {
    patient_name: {
      label: "",
      name: "patient_name", // Include the name field
      value: vaccineListData?.patientName,
    },
    vaccines: {
      label: "Vaccines",
      name: "vaccines", // Include the name field
      value: vaccineListData?.vaccines,
    },
    due_date: {
      label: "Due Date",
      name: "due_date", // Include the name field
      value: vaccineListData?.dueDate,
    },
  };

  const vaccinesDetails = [
    {
      id: 1,
      name: "Hepatitis N (1st Dose)",
      duration: "1 Month 15 days",
      administeredDate: "25/12/2024",
      height: "35cm",
      weight: "2kg",
      headCircumference: "1.5",
      circumference: "1.5",
      completed: true,
    },
    {
      id: 2,
      name: "Polio (2nd Dose)",
      duration: "2 Months",
      administeredDate: "10/01/2025",
      height: "40cm",
      weight: "3kg",
      headCircumference: "1.7",
      circumference: "1.6",
      completed: true,
    },
    {
      id: 3,
      name: "Polio (2nd Dose)",
      duration: "2 Months",
      administeredDate: "10/01/2025",
      height: "40cm",
      weight: "3kg",
      headCircumference: "1.7",
      circumference: "1.6",
      completed: true,
    },
    {
      id: 4,
      name: "Polio (2nd Dose)",
      duration: "2 Months",
      administeredDate: "10/01/2025",
      height: "40cm",
      weight: "3kg",
      headCircumference: "1.7",
      circumference: "1.6",
      completed: false,
    },
    {
      id: 5,
      name: "Polio (2nd Dose)",
      duration: "2 Months",
      administeredDate: "10/01/2025",
      height: "40cm",
      weight: "3kg",
      headCircumference: "1.7",
      circumference: "1.6",
      completed: false,
    },
    {
      id: 6,
      name: "Polio (2nd Dose)",
      duration: "2 Months",
      administeredDate: "10/01/2025",
      height: "40cm",
      weight: "3kg",
      headCircumference: "1.7",
      circumference: "1.6",
      completed: false,
    },
    {
      id: 7,
      name: "Polio (2nd Dose)",
      duration: "2 Months",
      administeredDate: "10/01/2025",
      height: "40cm",
      weight: "3kg",
      headCircumference: "1.7",
      circumference: "1.6",
      completed: false,
    },
    // Add more vaccine details as needed
  ];

  const vaccinesCheckBoxList = [
    { id: 1, name: "DPT Or DTaP" },
    { id: 2, name: "Rotaviral 1" },
    {
      id: 3,
      name: "Pneumococcal 1 (Pcv)",
    },
    {
      id: 4,
      name: "Pneumococcal 1 (Pcv)",
    },
    {
      id: 5,
      name: "PHepatitis B2",
    },
  ];
  const [vaccineFieldData, setVaccineFieldData] = useState<any>(fieldData);
  const [selectedPatientId, setSelectedPatientId] = useState<number | null>(
    null
  );
  const [addVaccine, setAddVaccine] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
  const [saveBtnLoader, setSaveBtnLoader] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [addProperty, setAddProperty] = useState(false);
  const [isBulkCreate, setIsBulkCreate] = useState(false);

  const navigate = useNavigate();

  const PatientList = [
    {
      id: 1,
      icon: <VaccinesDpIcon />,
      name: "Swasthika (GSMSH02155)",
      mobile: "98875 46532",
    },
    {
      id: 2,
      icon: <VaccinesDpIcon />,
      name: "Ajay krish (GSMSH022323)",
      mobile: "98875 46532",
    },
    {
      id: 3,
      icon: <VaccinesDpIcon />,
      name: "John vijay KL (GSMSH02155)",
      mobile: "98875 46532",
    },
    {
      id: 4,
      icon: <VaccinesDpIcon />,
      name: "Mask Man (GSMSH032155)",
      mobile: "98875 46532",
    },
    {
      id: 5,
      icon: <VaccinesDpIcon />,
      name: "Alex hales (GSMSH24155)",
      mobile: "98875 46532",
    },
    {
      id: 6,
      icon: <VaccinesDpIcon />,
      name: "James Vince (GSMSH02132)",
      mobile: "98875 46532",
    },
    {
      id: 7,
      icon: <VaccinesDpIcon />,
      name: "Anderson Vince (GSMSH45332)",
      mobile: "98875 46532",
    },
    {
      id: 8,
      icon: <VaccinesDpIcon />,
      name: "Jeason Roy (GSMSH42232)",
      mobile: "98875 333332",
    },
    {
      id: 9,
      icon: <VaccinesDpIcon />,
      name: "Chris (GSMSH342432)",
      mobile: "955575 46532",
    },
    // Add more objects as needed
  ];

  const handleValidation = useCallback(
    (e: any) => {
      const { name, value, label } = e.target;

      switch (name) {
        case "duration":
        case "vaccines":
        case "dueDate":
        case "givenDate":
        case "height":
        case "weight":
        case "headCircumference": {
          if (requiredValidator(value, label)) {
            updateFormDataWithHelperText(
              name,
              requiredValidator(value, label),
              setVaccineFormError
            );
          } else {
            updateFormDataWithHelperText(name, "", setVaccineFormError);
          }
          break;
        }

        default:
          break;
      }
    },
    [vaccineFormError, vaccineFormData]
  );

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };
  const updateBookingFieldData = () => {
    setVaccineFieldData((prevFieldData: any) => {
      return Object.keys(prevFieldData).map((field: any) => {
        return {
          ...field,
          helperText: vaccineFormError[field.name],
          value: vaccineFormData[field.name],
          isError: vaccineFormError[field.name] == "" ? false : true,
        };
      });
    });
  };

  useEffect(() => {
    updateBookingFieldData();
  }, [vaccineFormError, vaccineFormData]);

  const handleInputChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setVaccineFormData((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
      setVaccineListData((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
      handleValidation(e);
    },
    [handleValidation]
  );
  const handleAutoCompleteWithCheckBoxChange = useCallback(
    (e: any, newValue: any) => {
      setVaccineFormData({
        ...vaccineFormData,
        vaccines: newValue,
      });
      handleValidation({
        target: {
          name: "vaccines",
          value: newValue,
        },
      });
    },
    [handleValidation]
  );
  const handleDateChange = (newValue: any, name: any) => {
    setVaccineFormData((prevData: any) => ({
      ...prevData,
      [name]: newValue,
    }));
    setVaccineListData((prevData: any) => ({
      ...prevData,
      [name]: newValue,
    }));
    handleValidation({
      target: {
        label: name === "Due Date" ? "Due Date" : "Given Date",
        name: name,
        value: newValue?.toString(),
      },
    });
  };
  const handleBoxClick = (patient: any) => {
    setSelectedPatientId(patient.id);
  };
  const handleDialogClose = () => {
    setAddVaccine(false);
    setAddProperty(false);
    setVaccineFormError(initialError);
  };
  const handleDialogOpen = () => {
    setVaccineFormData(initialData);
    setAddVaccine(true);
    setIsUpdate(false);
    setIsBulkCreate(false);
  };

  const createVaccine = () => {
    if (
      vaccineFormData.duration == null ||
      vaccineFormData.vaccines.length == 0 ||
      vaccineFormData.vaccines == null ||
      vaccineFormData.dueDate == null ||
      vaccineFormData.givenDate == null ||
      vaccineFormData.height == "" ||
      vaccineFormData.weight == "" ||
      vaccineFormData.headCircumference == ""
    ) {
      validateForm();
    } else {
      try {
        setSaveBtnLoader(true);
        setSaveBtnLoader(false);
      } catch (error: any) {
        setSaveBtnLoader(false);
      }
    }
  };

  const editVaccine = async (id: any) => {
    try {
      setIsUpdate(true);
      setAddVaccine(true);
      setIsDialogLoading(true);
      setIsDialogLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setIsDialogLoading(false);
    }
  };

  const updateVaccine = () => {
    if (
      vaccineFormData.duration == null ||
      vaccineFormData.vaccines.length == 0 ||
      vaccineFormData.dueDate == null ||
      vaccineFormData.givenDate == null ||
      vaccineFormData.height == "" ||
      vaccineFormData.weight == "" ||
      vaccineFormData.headCircumference == ""
    ) {
      validateForm();
    } else {
      try {

        setSaveBtnLoader(true);
      
        setSaveBtnLoader(false);
      } catch (error: any) {
        console.error("An error occurred:", error);
        const errorMessage = error?.response?.data?.errors
          ? error?.response?.data?.errors
          : "Unknown error occurred";
        
        setSaveBtnLoader(false);
      }
    }
  };

  const handlePropertyDialogOpen = () => {
    setAddProperty(true);
    setIsBulkCreate(true);
  };

  const handleCheckBoxChange = (event: any, item: any) => {
    if (event.target.checked) {
      setVaccineFormData((prevData: any) => ({
        ...prevData,
        vaccines: [...prevData?.vaccines, item],
      }));
    } else {
      setVaccineFormData((prevData: any) => ({
        ...prevData,
        vaccines: prevData?.vaccines?.filter((i: any) => i.id !== item.id),
      }));
    }
  };
  return (
    <Grid container spacing={1} sx={{ height: "100%", width: "100%" }}>
      <Grid
        item
        sx={{
          display: "flex",
          gap: 3,
          flexDirection: "column",
          height: {
            xs: "50vh",
            md: "90vh",
          },

          // overflowY: {
          //   xs: PatientList.length > 3 ? "scroll" : "auto",
          //   md: PatientList.length > 6 ? "scroll" : "auto",
          // },
          width: {
            xs: "100%",
            md: "35%",
            lg: "25%",
          },
          mb: 1,
        }}
      >
        <Grid>
          {" "}
          <TextField
            value={listData?.patient_name?.value}
            onChange={handleInputChange}
            placeholder="Search UHID/Patient Name"
            label={listData?.patient_name?.label}
            name={listData?.patient_name?.name}
            endPlaceholderIcon={<SearchPlaceholderIcon />}
            sx={{
              width: "95%",
              mt: "23px",
            }}
          />
        </Grid>
        <Grid
          sx={{
            height: {
              xs: "50vh",
              md: "90vh",
            },
            overflowY: {
              xs: PatientList.length > 3 ? "scroll" : "auto",
              md: PatientList.length > 6 ? "scroll" : "auto",
            },
          }}
        >
          {PatientList?.map((item: any) => (
            <Grid key={item.id} onClick={() => handleBoxClick(item)}>
              <Box
                sx={{
                  width: "95%",
                  height: "90px",
                  backgroundColor:
                    selectedPatientId === item.id
                      ? "primary.lighter"
                      : "#F8F8F8",
                  border:
                    selectedPatientId === item.id
                      ? "1.2px solid #2D84FF"
                      : "1.2px solid transparent",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  mb: "20px",
                }}
              >
                <Box sx={{ display: "flex", gap: 2, ml: "6%", width: "80%" }}>
                  {item.icon}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "70%",

                      gap: "5px",
                    }}
                  >
                    <Typography fontSize={"13px"} fontWeight={600}>
                      {item.name}
                    </Typography>
                    <Typography fontSize={"12px"}>
                      Mobile: {item.mobile}
                    </Typography>
                  </Box>
                </Box>
                <ArrowForwardIosIcon />
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid>
          <TablePagination
            currentPage={"appointmentList"}
            totalCount={130}
            rowsPerPageOptions={[10]}
            isPaginationOnly={true}
          />
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: {
              xs: "flex",
              md: "flex",
            },
            width: "1px",
            height: "100%",
            backgroundColor: "#E6E6E6",
            justifyContent: "flex-end",
            mr: 2,
          }}
        ></Box>
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          width: {
            xs: "100%",
            md: "60%",
            lg: "72%",
            xl: "60%",
          },
          height: {
            xs: "70vh",
            md: "90vh",
          },

          pr: { xs: "5px", lg: "50px" },
          mb: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 5,
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "98%",
          }}
        >
          <Grid sx={{ gap: 2, display: "flex", flexWrap: "wrap" }}>
            <TextField
              value={listData?.vaccines?.value}
              onChange={handleInputChange}
              placeholder="Hepatitis B1"
              label={listData?.vaccines?.label}
              name={listData?.vaccines?.name}
              sx={{
                width: "225px",
              }}
            />

            <DatePicker
              onChange={(newValue: any) =>
                handleDateChange(newValue, "end_date")
              }
              value={listData?.due_date?.value}
              placeholder={"Selected Due Date"}
              label={listData?.due_date?.label}
              name={listData?.due_date?.name}
              style={{
                width: "200px",
              }}
            />
          </Grid>
          <Grid sx={{ mt: "23px", display: "flex", gap: 3 }}>
            <Button
              variant="outlined"
              buttonText="Master"
              sx={{ height: "40px", width: "100px", color: "primary.main" }}
              handleClick={() =>
                navigate(`${RouteUrls.vaccinesUrl}${RouteUrls.masterUrl}`)
              }
            />
            <SaveButton
              buttonText="+Add"
              sx={{ height: "40px", width: "100px" }}
              handleClick={handleDialogOpen}
            />
          </Grid>
        </Box>

        <Box
          sx={{
            overflowY: {
              xs: PatientList.length > 1 ? "scroll" : "auto",
              md: PatientList.length > 6 ? "scroll" : "auto",
            },
            mt: 2,
            width: { xs: "98%", md: "100%" },

            pr: 2,
          }}
        >
          {vaccinesDetails.map((vaccine, index) => (
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  mr: 2,
                }}
              >
                {vaccine.completed ? (
                  <CompleteIcon style={{ color: "#17CF9D" }} />
                ) : (
                  <PendingIcon style={{ color: "#F6B178" }} />
                )}
                {index < vaccinesDetails.length - 1 && (
                  <Box
                    sx={{
                      width: "5px",
                      height: { xs: "240px", lg: "120px" },
                      backgroundColor: vaccine.completed
                        ? "#17CF9D"
                        : "#F6B178",
                      position: "absolute",
                      top: "24px",
                      zIndex: -1,
                    }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  width: "92%",
                  backgroundColor: vaccine.completed ? "#E5FFF8" : "#FFF4EB",
                  height: { xs: "230px", lg: "105px" },
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  pl: "4%",
                  pr: "4%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    pt: 2,
                  }}
                >
                  <Typography fontSize={"16px"} fontWeight={600}>
                    {vaccine?.name}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "3px",
                      cursor: "pointer",
                    }}
                    onClick={handlePropertyDialogOpen}
                  >
                    <TimeIcon />{" "}
                    <Typography fontSize={"12px"} fontWeight={400}>
                      {vaccine?.duration}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      lg: "row",
                    },

                    mt: "8px",
                    gap: {
                      xs: 1,
                      lg: 0,
                    },
                  }}
                >
                  <Typography fontSize={"14px"} fontWeight={500}>
                    Due Date: {vaccine?.administeredDate} -{" "}
                  </Typography>
                  <Typography fontSize={"14px"} fontWeight={500}>
                    {" "}
                    Head Circumference: {vaccine?.headCircumference},{" "}
                  </Typography>
                  <Typography fontSize={"14px"} fontWeight={500}>
                    Height: {vaccine?.height},{" "}
                  </Typography>
                  <Typography fontSize={"14px"} fontWeight={500}>
                    Weight: {vaccine?.weight},{" "}
                  </Typography>
                  <Typography fontSize={"14px"} fontWeight={500}>
                    Circumference: {vaccine?.circumference},{" "}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    mt: 0,
                    pb: 1,
                  }}
                >
                  <IconButton onClick={() => editVaccine(vaccine.id)}>
                    <EditBlackColorIcon />
                  </IconButton>
                  <IconButton onClick={() => ""}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          ))}
        </Box>
      </Grid>
      <DialogWrapper
        open={addProperty || addVaccine}
        handleClick={() => {
          isUpdate ? updateVaccine() : createVaccine();
        }}
        title={isUpdate ? "Edit Vaccine" : "Add Vaccine"}
        onClose={() => {
          handleDialogClose();
        }}
        maxWidth="md"
        loading={saveBtnLoader}
      >
        {isDialogLoading ? (
          <div
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              height: "40vh",
              background: "transparent",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {!isBulkCreate ? (
              <Grid
                sx={{
                  display: "flex",
                  gap: 4,
                  flexWrap: "wrap",
                }}
              >
                <Select
                  value={fieldData?.duration?.value}
                  placeholder="Select Duration"
                  onChange={handleInputChange}
                  options={[{ id: 1, value: "one" }]}
                  name={fieldData?.duration?.name}
                  label={fieldData?.duration?.label}
                  helperText={fieldData?.duration?.helperText}
                  sx={{
                    width: {
                      xs: "300px",
                    },
                  }}
                  width="300px"
                />
                <AutoCompleteWithCheckBoxes
                  name={fieldData?.vaccines?.name}
                  label={fieldData?.vaccines?.label}
                  value={
                    fieldData?.vaccines?.value !== null
                      ? fieldData?.vaccines?.value
                      : null
                  }
                  onChange={(event: any, newValue: any) =>
                    handleAutoCompleteWithCheckBoxChange(event, newValue)
                  }
                  placeholder={"Select Vaccines"}
                  options={[{ id: 1, name: "xxx" }]}
                  defaultValue={
                    fieldData?.vaccines?.value !== null
                      ? fieldData?.vaccines?.value
                      : null
                  }
                  sx={{
                    "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                      boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                      padding: "5px 0px",
                      borderRadius: "8px",
                      paddingLeft: "8px",
                      minHeight: "48px",
                      maxHeight: "110px",
                      overflowX: "hidden",
                    },
                    width: "300px",
                  }}
                  helperText={fieldData?.vaccines?.helperText}
                />
              </Grid>
            ) : (
              <Grid sx={{ display: "flex", flexDirection: "column" }}>
                <Box>
                  <Typography
                    variant="h3"
                    color="primary.main"
                    fontWeight={600}
                  >
                    Duration :1 Month 15 Days
                  </Typography>
                </Box>
                <Box sx={{ maxWidth: "600px", m: "20px 0" }}>
                  {" "}
                  {vaccinesCheckBoxList?.map((item) => (
                    <FormControlLabel
                      control={<Checkbox />}
                      label={item?.name}
                      onChange={handleCheckBoxChange}
                    />
                  ))}
                </Box>
                <Box sx={{ mb: 1 }}>
                  <Typography
                    variant="h3"
                    color="primary.main"
                    fontWeight={600}
                  >
                    Other Details
                  </Typography>
                </Box>
              </Grid>
            )}
            <Grid
              sx={{
                display: "flex",
                gap: 4,
                flexWrap: "wrap",
                "& .MuiFormControl-root": {
                  position: "static",
                },
              }}
            >
              <DatePicker
                onChange={(newValue: any) =>
                  handleDateChange(newValue, "dueDate")
                }
                value={fieldData?.dueDate?.value}
                placeholder={"Select Due Date"}
                label={fieldData?.dueDate?.label}
                name={fieldData?.dueDate?.name}
                helperText={fieldData?.dueDate?.helperText}
                disabled={true}
                style={{
                  width: "280px",
                  backgroundColor: "#F4F4F5",
                }}
              />
              <DatePicker
                onChange={(newValue: any) =>
                  handleDateChange(newValue, "givenDate")
                }
                value={fieldData?.givenDate?.value}
                placeholder={"Select Given Date"}
                label={fieldData?.givenDate?.label}
                name={fieldData?.givenDate?.name}
                helperText={fieldData?.givenDate?.helperText}
                style={{
                  width: "280px",
                }}
              />
            </Grid>
            <Grid sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
              <Grid sx={{ display: "flex" }}>
                <TextField
                  value={fieldData?.weight?.value}
                  onChange={handleInputChange}
                  placeholder="Enter Weight"
                  label={fieldData?.weight?.label}
                  name={fieldData?.weight?.name}
                  inputAdornment="kg"
                  helperText={fieldData?.weight?.helperText}
                  sx={{
                    width: "140px",
                    mr: "25px",
                  }}
                />
                <TextField
                  value={fieldData?.height?.value}
                  onChange={handleInputChange}
                  placeholder="Enter height"
                  label={fieldData?.height?.label}
                  name={fieldData?.height?.name}
                  inputAdornment="cm"
                  helperText={fieldData?.height?.helperText}
                  sx={{
                    width: "140px",
                  }}
                />
              </Grid>
              <Grid>
                <TextField
                  value={fieldData?.headCircumference?.value}
                  onChange={handleInputChange}
                  placeholder="Enter Head Circumference"
                  label={fieldData?.headCircumference?.label}
                  name={fieldData?.headCircumference?.name}
                  helperText={fieldData?.headCircumference?.helperText}
                  sx={{
                    width: "300px",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogWrapper>
    </Grid>
  );
};

export default VaccinesList;
