import React from "react";
import {
  TableCell,
  TableRow,
  Popper,
  Box,
  List,
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  TextField,
  Select,
  DatePicker,
  AutoComplete,
  AutocompleteWithTable,
} from "../../../../components/basic";
import { DeleteIcon, PlusIcon } from "../../../../assets/icons";
import PermissionUtils from "../../../../utils/PermissionUtils";

type PrescriptionRowProps = {
  styles?: any;
  row?: any;
  rowIndex?: number;
  handleDelete: (id: number) => void;
  handleClick: (id: number) => void;
  symptomsOptions?: any;
  productsOptions?: any;
  unitsOptions?: any;
  freqOptions?: any;
  freqTimeTypeOptions?: any;
  durationTypeOptions?: any;
  specificDaysOptions?: any;
  intervalOptions?: any;
  handleInputChange: (e: any, productId: number) => void;
  handleDateChange: (productId: number, newValue: any, name: any) => void;
  handleAutoCompleteChange: (
    e: any,
    newValue: any,
    productId: number,
    name: string
  ) => void;
  handleAutoCompleteInputChange: (
    e: any,
    newInputValue: any,
    productId: number,
    name: string
  ) => void;
  handleClose?: any;
  rowsLength?: number;
  isAutocompleteOptionsLoading?: boolean;
};

const PrescriptionRow = React.memo(
  ({
    styles,
    row,
    handleDelete,
    handleClick,
    symptomsOptions,
    productsOptions,
    unitsOptions,
    freqOptions,
    freqTimeTypeOptions,
    durationTypeOptions,
    specificDaysOptions,
    intervalOptions,
    handleInputChange,
    handleDateChange,
    handleAutoCompleteChange,
    handleAutoCompleteInputChange,
    handleClose,
    isAutocompleteOptionsLoading,
  }: PrescriptionRowProps) => {
    const { can } = PermissionUtils();

    const durationTypes: any = {
      specific_days: "specific_days",
      interval_days: "interval_days",
      custom_date: "custom_date",
    };

    const PopperMy = function (props: any) {
      const { children, ...rest } = props;
      return (
        <Popper
          {...rest}
          placement="bottom-start"
          className="auto-complete-popper"
          sx={{
            width: "700px",
            p: "10px 0px",
            boxSizing: "border-box",
            "& .MuiPaper-root": {
              width: "700px",
              maxHeight: "250px",
              overflow: "hidden",
              borderRadius: "0px 0px 5px 5px",
              boxSizing: "border-box",
            },
            "& .MuiAutocomplete-listbox": {
              overflowX: "hidden",
              maxHeight: "250px",
              p: 0,
            },
          }}
        >
          <Box
            sx={{
              width: "700px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "var(--table-autocomplete-background)",
              p: "0px 10px 0px 0px",
              height: "45px",
              boxSizing: "border-box",
              borderRadius: "5px 5px 0px 0px",
            }}
          >
            <Typography
              sx={{
                width: "50%",
                fontSize: "14px",
                fontWeight: "500",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Product Name
            </Typography>
            <Typography
              sx={{
                width: "50%",
                fontSize: "14px",
                fontWeight: "500",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Generic Name
            </Typography>
            <Typography
              sx={{
                width: "50%",
                fontSize: "14px",
                fontWeight: "500",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Stock
            </Typography>
          </Box>
          {props.children}
        </Popper>
      );
    };

    return (
      <TableRow
        sx={{
          "& td, & th": { border: 0 },
          width: "90vw",
          border: 0,
          borderBottom: 1,
          borderColor: "var(--table-border)",
        }}
      >
        <Tooltip
          open={row?.symptoms?.isSymptomTooltipOpen}
          onClose={() => handleClose("isSymptomTooltipOpen", row.id)}
          title={
            <Typography
              sx={{
                color: "white",
                fontSize: "14px",
              }}
            >
              This field is required
            </Typography>
          }
          arrow
        >
          <TableCell
            align="left"
            sx={{
              width: "12vw",
              height: "100%",
              verticalAlign: "top",
              "& .MuiGrid-root": {
                height: "100%",
              },
              "& .MuiAutocomplete-root": {
                height: "100%",
              },
              "& .MuiFormLabel-root": {
                display: "none",
              },
              "& .MuiInputBase-root": {
                height: "100%",
                p: "0px",
                pr: "0px",
              },
              "& .MuiOutlinedInput-root": {
                p: "0px",
                pr: "0px",
                boxShadow: "none",
              },

              "& .MuiFormControl-root": {
                height: "100%",
              },
            }}
          >
            <AutoComplete
              placeholder="Select Symptom"
              value={row?.symptoms?.products[0]?.symptom}
              inputValue={row?.symptoms?.products[0]?.symptom?.symptom_name}
              name="symptom_id"
              optionName="symptom_name"
              inputRef={row?.symptoms?.symptomRef}
              disableClearable={false}
              isValueAsObject={true}
              onChange={(e: any, newValue: any) =>
                handleAutoCompleteChange(
                  e,
                  newValue,
                  row.symptoms.id,
                  "symptom_id"
                )
              }
              onInputChange={(e: any, newInputValue: any) => {
                handleAutoCompleteInputChange(
                  e,
                  newInputValue,
                  row.symptoms.id,
                  "symptom_name"
                );
              }}
              options={symptomsOptions}
              sx={{
                width: "12vw",
                height: "100%",
                "& .MuiFormControl-root": {
                  height: "100%",
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "& .MuiInputBase-root .MuiInputBase-input": {
                  padding: 0,
                  fontSize: "var(--primary-input-text-font-size)",
                  fontWeight: "400",
                },

                fontFamily: ["Inter", "sans-serif"].join(","),

                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "greyScale.light",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.main",
                },
                "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
                  borderColor: "greyScale.lighter",
                },
                "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                  padding: "6.5px",
                  boxShadow: "none",
                  borderRadius: "8px",
                },
                "& .MuiAutocomplete-endAdornment": {
                  pt: "3px",
                },
              }}
            />
          </TableCell>
        </Tooltip>
        <TableRow
          sx={{
            height: "auto",
            borderBottom: 0,
            verticalAlign: "text-top",
          }}
        >
          {row?.symptoms?.products?.map((productRow: any, index: number) => {
            return (
              <TableRow
                sx={{
                  height: "33px",
                  border: 0,
                  borderBottom:
                    index !== row?.symptoms?.products.length - 1
                      ? "1px solid var(--table-border)"
                      : "none",
                  borderColor: "var(--table-border)",
                }}
              >
                <Tooltip
                  open={productRow?.isProductTooltipOpen}
                  onClose={() => handleClose("isProductTooltipOpen", row.id)}
                  title={
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "14px",
                      }}
                    >
                      This field is required
                    </Typography>
                  }
                  arrow
                >
                  <TableCell
                    align="left"
                    sx={{
                      width: "12vw",
                      "& .MuiAutocomplete-root": {
                        height: "100%",
                      },
                      "& .MuiAutocomplete-endAdornment": {
                        top: "calc(50% - 11px)",
                        mt: "12px",
                      },
                      "& .MuiInputBase-root": {
                        height: "100%",
                        p: "0px",
                        pr: "0px",
                      },
                      "& .MuiOutlinedInput-root": {
                        p: "0px",
                        pr: "0px",
                      },
                    }}
                  >
                    <AutocompleteWithTable
                      placeholder="Select Medicine"
                      name="product_id"
                      value={productRow?.product}
                      inputValue={productRow?.product?.name}
                      inputRef={productRow?.productRef}
                      isValueAsObject={true}
                      onChange={(e: any, newValue: any) =>
                        handleAutoCompleteChange(
                          e,
                          newValue,
                          productRow.id,
                          "product_id"
                        )
                      }
                      onInputChange={(e: any, newInputValue: any) => {
                        handleAutoCompleteInputChange(
                          e,
                          newInputValue,
                          productRow.id,
                          "product_name"
                        );
                      }}
                      noOptionsText={
                        isAutocompleteOptionsLoading
                          ? "...Loading"
                          : "No Options"
                      }
                      options={productsOptions || []}
                      getOptionLabel={(option: any) => option.name}
                      renderOption={(props: any, option: any) => {
                        return (
                          <List
                            {...props}
                            key={option.ID}
                            className="table-body-lists-div"
                            sx={{
                              width: "700px",
                              borderRadius: 0,
                              boxSizing: "border-box",
                              m: "0px",
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "primary.lighter",
                              },
                            }}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              width="700px"
                              className="list-row"
                              sx={{
                                boxSizing: "border-box",
                                m: "0px",
                                p: "0px 10px 0px 0px",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  width: "50%",
                                  textAlign: "left",
                                  p: "0px 10px",
                                }}
                              >
                                {option.name}
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  width: "50%",
                                  textAlign: "left",
                                  p: "0px 10px",
                                }}
                              >
                                {option.generic_name}
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  width: "50%",
                                  textAlign: "left",
                                  p: "0px 10px",
                                }}
                              >
                                {option?.goods_stock_qty ||
                                option?.pur_stock_qty
                                  ? option?.goods_stock_qty ||
                                    0 + option?.pur_stock_qty ||
                                    0
                                  : "-"}
                              </Typography>
                            </Box>
                          </List>
                        );
                      }}
                      sx={{
                        width: "12vw",
                        height: "34px",
                        "& .MuiFormControl-root": {
                          height: "100%",
                        },

                        "& .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                        "& .MuiInputBase-root .MuiInputBase-input": {
                          padding: 0,
                          fontSize: "var(--primary-input-text-font-size)",
                          fontWeight: "400",
                        },
                      }}
                      PopperComponent={PopperMy}
                    />
                  </TableCell>
                </Tooltip>
                <TableCell
                  align="center"
                  sx={{
                    width: "8vw",
                  }}
                >
                  <TextField
                    name="dosage"
                    value={productRow.dosage}
                    placeholder="1-1-1-1"
                    onChange={(e: any) => handleInputChange(e, productRow.id)}
                    sx={{
                      ...styles.textFieldStyle,
                      width: "8vw",
                      "& .MuiInputBase-input": {
                        fontSize: "var(--primary-input-text-font-size)",
                        fontWeight: "400",
                        textAlign: "left",
                        p: "0px 10px",
                      },
                    }}
                  />
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: "6vw",
                  }}
                >
                  <Grid
                    className="select-grid"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "6vw",
                      ...styles.selectGridStyle,
                    }}
                  >
                    <Select
                      label=""
                      options={unitsOptions}
                      placeholder="select"
                      name="units"
                      value={Number(productRow.units)}
                      selectedType="number"
                      onChange={(e: any) => handleInputChange(e, productRow.id)}
                      sx={{
                        width: "6vw",
                        height: "34px",
                        borderRadius: "0px",
                      }}
                      isRemoveClearIcon
                    />
                  </Grid>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: "8vw",
                  }}
                >
                  <Grid
                    className="select-grid"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "8vw",
                      ...styles.selectGridStyle,
                    }}
                  >
                    <Select
                      label=""
                      options={freqOptions}
                      placeholder="Before Food"
                      name="freq"
                      value={productRow.freq}
                      selectedType="number"
                      onChange={(e: any) => handleInputChange(e, productRow.id)}
                      width="inherit"
                      sx={{
                        height: "100%",
                        borderRadius: "0px",
                      }}
                      isRemoveClearIcon
                    />
                  </Grid>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: "8vw",
                    minWidth: "8vw",
                  }}
                >
                  <TextField
                    width="8vw"
                    name="freq_time"
                    value={productRow.freq_time}
                    placeholder="2"
                    onChange={(e: any) => handleInputChange(e, productRow.id)}
                    sx={{
                      ...styles.textFieldStyle,
                      width: "8vw",
                      minWidth: "8vw",
                      "& .MuiInputBase-input": {
                        fontSize: "var(--primary-input-text-font-size)",
                        fontWeight: "400",
                        textAlign: "left",
                        p: "0px 10px",
                      },
                    }}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: "8vw",
                  }}
                >
                  <Grid
                    className="select-grid"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "8vw",
                      ...styles.selectGridStyle,
                    }}
                  >
                    <Select
                      label=""
                      options={freqTimeTypeOptions}
                      placeholder="Hours"
                      name="freq_time_type"
                      value={productRow.freq_time_type}
                      selectedType="number"
                      onChange={(e: any) => handleInputChange(e, productRow.id)}
                      width="inherit"
                      sx={{
                        height: "100%",
                        borderRadius: "0px",
                      }}
                      isRemoveClearIcon
                    />
                  </Grid>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: "14vw",
                  }}
                >
                  <Grid
                    className="select-grid"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "14vw",
                      ...styles.selectGridStyle,
                    }}
                  >
                    <Select
                      label=""
                      options={durationTypeOptions}
                      placeholder="Select Duration Type"
                      name="duration_type"
                      value={productRow.duration_type}
                      onChange={(e: any) => handleInputChange(e, productRow.id)}
                      width="14vw"
                      sx={{
                        height: "100%",
                        borderRadius: "0px",
                        width: "14vw",
                      }}
                      isRemoveClearIcon
                    />
                  </Grid>
                </TableCell>
                <TableRow
                  className="duration-type-options"
                  sx={{
                    width: "30vw",
                    height: "100%",
                  }}
                >
                  <TableCell
                    className="placement cell"
                    sx={{
                      width: durationTypes[productRow?.duration_type]
                        ? "0px"
                        : "10vw",
                      display: durationTypes[productRow?.duration_type]
                        ? "none"
                        : "table-cell",
                    }}
                  >
                    <Typography
                      sx={{
                        width: durationTypes[productRow?.duration_type]
                          ? "0px"
                          : "10vw",
                        display: durationTypes[productRow?.duration_type]
                          ? "none"
                          : "flex",
                      }}
                    ></Typography>
                  </TableCell>

                  <TableCell
                    className="specific-days-cell"
                    align="right"
                    sx={{
                      width:
                        productRow?.duration_type === "specific_days"
                          ? "10vw"
                          : "0px",
                      display:
                        productRow?.duration_type === "specific_days"
                          ? "table-cell"
                          : "none",
                    }}
                  >
                    <Grid
                      className="select-grid"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "10vw",
                        ...styles.selectGridStyle,
                      }}
                    >
                      <Select
                        label=""
                        options={specificDaysOptions}
                        placeholder="Select Days"
                        name="specific_days"
                        isMultiSelect={true}
                        value={productRow?.duration?.specific_days || []}
                        selectedType="number"
                        multiSelectSelectedType="number"
                        optionName="value"
                        onChange={(e: any) =>
                          handleInputChange(e, productRow.id)
                        }
                        width={
                          productRow?.duration_type === "specific_days"
                            ? "10vw"
                            : "0px"
                        }
                        sx={{
                          height: "100%",
                          borderRadius: "0px",
                          display:
                            productRow?.duration_type === "specific_days"
                              ? "flex"
                              : "none",
                        }}
                        isRemoveClearIcon
                      />
                    </Grid>
                  </TableCell>

                  <TableCell
                    className="interval-days"
                    align="right"
                    sx={{
                      width:
                        productRow?.duration_type === "interval_days"
                          ? "10vw"
                          : "0px",
                      display:
                        productRow?.duration_type === "interval_days"
                          ? "table-cell"
                          : "none",
                    }}
                  >
                    <TextField
                      width={
                        productRow?.duration_type === "interval_days"
                          ? "10vw"
                          : "0px"
                      }
                      name="interval_days"
                      placeholder="Ex-2"
                      value={productRow.duration.interval_days}
                      onChange={(e: any) => handleInputChange(e, productRow.id)}
                      sx={{
                        ...styles.textFieldStyle,
                        display:
                          productRow?.duration_type === "interval_days"
                            ? "flex"
                            : "none",
                        width:
                          productRow?.duration_type === "interval_days"
                            ? "10vw"
                            : "0px",
                        "& .MuiInputBase-input": {
                          fontSize: "var(--primary-input-text-font-size)",
                          fontWeight: "400",
                          textAlign: "left",
                          p: "0px 10px",
                        },
                      }}
                    />
                  </TableCell>

                  <TableCell
                    className="custom-date"
                    align="right"
                    sx={{
                      p: "0px 0px",
                      width:
                        productRow?.duration_type === "custom_date"
                          ? "10vw"
                          : "0px",
                      display:
                        productRow?.duration_type === "custom_date"
                          ? "table-cell"
                          : "none",
                      ...styles.datePickerGridStyle,
                    }}
                  >
                    <DatePicker
                      label=""
                      name="custom_date"
                      value={productRow.duration.custom_date}
                      placeholder="Choose Date"
                      onChange={(e: any, newValue: any) =>
                        handleDateChange(productRow.id, newValue, "custom_date")
                      }
                      height="100%"
                      style={{
                        boxShadow: "none",
                        border: "0px solid rgba(138, 138, 138, 0.32)",
                        height: "100%",
                        borderRadius: "5px",
                        padding: "0px",
                        width:
                          productRow?.duration_type === "custom_date"
                            ? "10vw"
                            : "0px",
                        display:
                          productRow?.duration_type === "custom_date"
                            ? "flex"
                            : "none",
                      }}
                    />
                  </TableCell>

                  <TableRow
                    sx={{
                      minWidth: "20vw",
                      width: "20vw",
                      maxWidth: "20vw",
                      height: "100%",
                    }}
                  >
                    <TableCell
                      className="durationCount-cell"
                      align="right"
                      sx={{
                        width: "10vw",
                        height: "20px",
                      }}
                    >
                      <TextField
                        width="10vw"
                        name="duration_count"
                        placeholder="2"
                        value={productRow.duration.duration_count}
                        disabled={
                          productRow?.duration_type === "custom_date" ||
                          productRow?.duration_type === "forever" ||
                          productRow?.duration_type === "" ||
                          productRow?.duration_type === null
                            ? true
                            : false
                        }
                        onChange={(e: any) =>
                          handleInputChange(e, productRow.id)
                        }
                        sx={{
                          ...styles.textFieldStyle,
                          width: "10vw",
                          "& .MuiInputBase-input": {
                            fontSize: "var(--primary-input-text-font-size)",
                            fontWeight: "400",
                            textAlign: "left",
                            p: "0px 10px",
                          },
                        }}
                      />
                    </TableCell>

                    <TableCell
                      className="interval-cell"
                      align="right"
                      sx={{
                        width: "10vw",
                        ...styles.selectGridStyle,
                      }}
                    >
                      <Grid
                        className="select-grid"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "10vw",
                          ...styles.selectGridStyle,
                        }}
                      >
                        <Select
                          label=""
                          options={intervalOptions}
                          placeholder="Select"
                          name="interval"
                          value={Number(productRow.duration.interval)}
                          disabled={
                            productRow?.duration_type === "custom_date" ||
                            productRow?.duration_type === "forever" ||
                            productRow?.duration_type === "" ||
                            productRow?.duration_type === null
                              ? true
                              : false
                          }
                          selectedType="number"
                          onChange={(e: any) =>
                            handleInputChange(e, productRow.id)
                          }
                          width="10vw"
                          sx={{
                            height: "100%",
                            borderRadius: "0px",
                            "&.Mui-disabled.MuiOutlinedInput-root": {
                              borderColor: "initial",
                              pl: "10px",
                              borderRadius: "5px",
                            },
                            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                              borderColor: "initial",
                            },
                          }}
                          isRemoveClearIcon
                        />
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableRow>
                <Tooltip
                  open={productRow?.isStartDateTooltipOpen}
                  onClose={() => handleClose("isStartDateTooltipOpen", row.id)}
                  title={
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "14px",
                      }}
                    >
                      This field is required
                    </Typography>
                  }
                  arrow
                >
                  <TableCell sx={{ ...styles.datePickerGridStyle }}>
                    <DatePicker
                      value={productRow.start_date}
                      label=""
                      name="start_date"
                      onChange={(e: any, newValue: any) =>
                        handleDateChange(productRow.id, newValue, "start_date")
                      }
                      formControlStyle={{ height: "100% !important" }}
                      height="100%"
                      style={{
                        boxShadow: "none",
                        border: "0px solid rgba(138, 138, 138, 0.32)",
                        width: "10vw",
                        height: "100% !important",
                        borderRadius: "5px",
                        padding: "0px",
                      }}
                    />
                  </TableCell>
                </Tooltip>
                <TableCell
                  align="left"
                  sx={{
                    height: "100%",
                    p: "0px 0px",
                    width: "10vw",
                    position: "relative",
                    "&.MuiTableCell-body:has(.Mui-focused)": {
                      border: 0,
                      borderColor: "primary.main",
                      borderWidth: "2px",
                      p: "0px",
                      height: "100%",
                    },
                  }}
                >
                  <Box className="deleteIconGrid">
                    <IconButton
                      sx={{
                        height: "100%",
                        width: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 0,
                      }}
                      onClick={() => handleClick(row?.symptoms?.id)}
                    >
                      <PlusIcon className="plus-icon" />
                    </IconButton>
                    {can("prescription_delete") && (
                      <IconButton
                        sx={{
                          height: "20px",
                          width: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          p: 0,
                        }}
                        onClick={() => handleDelete(productRow.id)}
                      >
                        <DeleteIcon className="delete-icon" />
                      </IconButton>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableRow>
      </TableRow>
    );
  }
);

export default PrescriptionRow;
