/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { Typography, Box, IconButton, debounce } from "@mui/material";
import { DataTable, ConfirmationDialog } from "../../../../components/shared";
import { ViewIcon, DeleteIcon, EditIcon, PlusIcon } from "../../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Sort, TextField } from "../../../../components/basic";
import { RouteUrls } from "../../../../constants/routes";
import { RootState } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import CustomFilter from "../../../../components/shared/customFilter";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { errorMessage } from "../../../../constants/displayText";
import {
  deleteWholeSalesById,
  getAllWholeSalesList,
} from "../../../../services/procurementService";
import { formatTwelveHoursTime } from "../../../../utils/DateTimeFormatUtils";
import { commonDateFormatter } from "../../../../utils/DateTimeFormatUtils";
import {
  setFilterDataValues,
  setSortField,
  setHandleClearSearch,
  setCurrentPage,
} from "../../../../redux/slices/pagination";

type GridRowData = Record<string, unknown>;

const StockAdjustmentList = () => {
  const {
    procurementUrl,
    wholeSalesUrl,
    createUrl,
    viewUrl,
    wholeSalesReturn,
    editUrl,
  } = RouteUrls;
  const style = {
    textFieldStyle: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "45px",
        borderRadius: "5px",
        paddingLeft: "8px",
        boxShadow: "none",
      },
    },
  };
  const dispatch = useDispatch();
  const [stockAdjustmentList, setStockAdjustmentList] = useState<[]>([]);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);

  const [loading, setLoading] = useState(false);
  const [ascendingOrderClicked, setAscendingOrderClicked] = useState(false);
  const [descendingOrderClicked, setDescendingOrderClicked] = useState(false);

  const navigate = useNavigate();
  const { pageInfo, filterData, count, sortField } = useSelector(
    (state: RootState) => ({
      pageInfo: state?.pagination?.stockAdjustment,
      filterData: state?.pagination?.stockAdjustment?.filterData,
      count: state?.pagination?.stockAdjustment?.count,
      sortField: state?.pagination?.stockAdjustment?.sortedField,
    })
  );

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [wholeSaleToBeDeleted, setWholeSaleToBeDeleted] = useState<any>(null);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortField?.field === field) {
      dispatch(
        setSortField({
          key: "stockAdjustment",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
    if (sortField?.field !== field) {
      dispatch(
        setSortField({
          key: "stockAdjustment",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
  };

  const deleteWholeSaleRow = (row: any) => {
    setIsConfirmationDialogOpen(true);
    setWholeSaleToBeDeleted(row.id);
  };
  const deleteWholeSale = async () => {
    if (wholeSaleToBeDeleted === null) {
      return false;
    }
    setDeleteBtnLoader(true);
    await deleteWholeSalesById(wholeSaleToBeDeleted as number)
      .then((res: any) => {
        if (res) {
          setWholeSaleToBeDeleted(null);
          setIsConfirmationDialogOpen(false);
          debouncedGetAllAdjustmentList({
            page: pageInfo.page,
            pageSize: pageInfo.pageSize,
          });
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          setDeleteBtnLoader(false);
        }
      })
      .catch((err: any) => {
        setDeleteBtnLoader(false);
        setWholeSaleToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        dispatch(
          setSnackBarFailed({ snackBarMessage: err.response.data.errors })
        );
        console.log(err);
      });
  };
  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          aria-label=""
          onClick={() => {
            Boolean(row.is_return)
              ? navigate(
                  `${procurementUrl}${wholeSalesReturn}${editUrl}/${row.id}`
                )
              : navigate(
                  `${procurementUrl}${wholeSalesUrl}${editUrl}/${row.id}`
                );
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label=""
          onClick={() => {
            Boolean(row.is_return)
              ? navigate(
                  `${procurementUrl}${wholeSalesReturn}${viewUrl}/${row.id}`
                )
              : navigate(
                  `${procurementUrl}${wholeSalesUrl}${viewUrl}/${row.id}`
                );
          }}
        >
          <ViewIcon />
        </IconButton>

        <IconButton aria-label="">
          <DeleteIcon onClick={() => deleteWholeSaleRow(row)} />
        </IconButton>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "invoice_no",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("invoice_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Invoice No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "invoice_no" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "invoice_no" && sortField?.order === "DESC"
                ? true
                : false
            }
            ascendingOnClick={() =>
              setAscendingOrderClicked(!descendingOrderClicked)
            }
            descendingOnClick={() =>
              setDescendingOrderClicked(!ascendingOrderClicked)
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.invoice_no}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "customer_name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("customer_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Customer
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "customer_name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "customer_name" &&
              sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.customer_name}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "date_created",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("date_created")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "date_created" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "date_created" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {formatTwelveHoursTime(row.date_created)}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "dept_name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("dept_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Department
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "dept_name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "dept_name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.dept_name}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "net_total_amt",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("net_total_amt")}
        >
          <Typography variant="h5" fontSize={14}>
            Bill Amount
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "net_total_amt" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "net_total_amt" &&
              sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.net_total_amt}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "created_user",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("created_user")}
        >
          <Typography variant="h5" fontSize={14}>
            User
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "created_user" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "created_user" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.created_user}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  const clearFilters = () => {
    if (filterData.from_date || filterData.to_date || filterData.invoice_no) {
      dispatch(
        setFilterDataValues({
          key: "stockAdjustment",
          name: "clearFilter",
          value: {
            from_date: new Date(),
            to_date: new Date(),
            invoice_no: "",
          },
        })
      );
    }
  };

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      dispatch(
        setFilterDataValues({
          key: "stockAdjustment",
          name: name,
          value: newValue.validatedValue[0],
        })
      );
      dispatch(setCurrentPage({ key: "stockAdjustment", value: 0 }));
    }
  };

  const handleInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;
      dispatch(
        setFilterDataValues({
          key: "stockAdjustment",
          name: name,
          value: value,
        })
      );
      dispatch(setCurrentPage({ key: "stockAdjustment", value: 0 }));
    }
  };
  const onClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const appointmentFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <DatePicker
          value={filterData.from_date}
          placeholder={"Select From Date"}
          label={"From Date"}
          formControlStyle={{
            width: "90%",
            "& .icon-tabler": {
              left: "95%",
              color: "rgba(0, 0, 0, 0.54)",
            },
          }}
          onChange={(e: any, newValue: any) =>
            handleDateChange(e, newValue, "from_date")
          }
          name={"from_date"}
          width="95%"
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <DatePicker
          value={filterData.to_date}
          placeholder={"Select To Date"}
          label={"To Date"}
          formControlStyle={{
            width: "90%",
            "& .icon-tabler": {
              left: "95%",
              color: "rgba(0, 0, 0, 0.54)",
            },
          }}
          onChange={(e: any, newValue: any) =>
            handleDateChange(e, newValue, "to_date")
          }
          name={"to_date"}
          width="95%"
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData.invoice_no}
          placeholder={"Enter Invoice No"}
          label={"Invoice No"}
          formControlStyle={{ width: "100%" }}
          onChange={handleInputChange}
          name={"invoice_no"}
          sx={{
            ...style.textFieldStyle,
          }}
        />
      ),
    },
  ];

  const debouncedGetAllAdjustmentList = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllWholeSalesList({
        from_date: data.from_date,
        to_date: data.to_date,
        column: data.sortBy,
        order: data.sortOrder,
        limit: data.pageSize,
        page: data.page,
        search: data.search,
        invoice_no: data.invoice_no,
      })
        .then((res: any) => {
          setStockAdjustmentList(res.data.result as []);
          setPageCount(res.data.total as any);
          setLoading(false);
        })
        .catch((err) => {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage.ERROR_FETCHING_STOCK_ADJUSTMENT}`,
            })
          );
          setLoading(false);
          console.log("error wholesales", err);
        });
    }, 300),
    []
  );
  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo.pageSize,
      sortBy: sortField?.field,
      sortOrder: sortField?.order,
      invoiceNo: filterData.invoice_no,
      search: filterData.search,
      from_date: commonDateFormatter(filterData.from_date, "YYYY-MM-DD"),
      to_date: commonDateFormatter(filterData.to_date, "YYYY-MM-DD"),
    };
    debouncedGetAllAdjustmentList(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortField?.order,
    sortField?.field,
    filterData.invoice_no,
    filterData.from_date,
    filterData.to_date,
    filterData.search,
  ]);
  return (
    <Box>
      <CustomFilter
        data={appointmentFilter}
        isFilterEnabled={true}
        isSearchEnabled={true}
        editButtonTitle="Add"
        clearFilter={clearFilters}
        searchOnChange={handleInputChange}
        searchName={"search"}
        searchValue={filterData.search}
        onUpdateButtonClick={() =>
          navigate(`${procurementUrl}${wholeSalesUrl}${createUrl}`)
        }
        clearSearch={() =>
          dispatch(
            setHandleClearSearch({
              key: "stockAdjustment",
              value: "",
              name: "search",
            })
          )
        }
        appliedFilterCount={count}
        editStartIcon={
          <Box
            sx={{
              "& path": {
                fill: "#fff",
              },
            }}
          >
            <PlusIcon width={"12px"} height={"12px"} />
          </Box>
        }
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={stockAdjustmentList}
        pageCount={pageCount}
        currentPage={"stockAdjustment"}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="are you surely want to delete?"
        handleClick={deleteWholeSale}
        onClose={onClose}
        loading={deleteBtnLoader}
      />
    </Box>
  );
};

export default StockAdjustmentList;
