import React from "react";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

type CheckBoxProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  label?: any;
  name?: string;
  error?: boolean;
  helperText?: string;
  formFlexDirection?: string;
  rightAlign?: boolean;
  rightAlignText?: string;
  color?: string | any;
  sx?: {};
  value?: boolean;
  formControlStyle?: {};
  disabled?: boolean;
};

const CustomCheckbox = ({
  checked,
  label,
  error,
  value,
  name,
  onChange,
  helperText,
  formFlexDirection = "rowReverse",
  rightAlign,
  rightAlignText,
  color = "primary.main",
  sx,
  formControlStyle,
  disabled,

}: CheckBoxProps) => {
  const styles = {
    box: {
      display: "flex",
      flexDirection: "rowReverse",
      alignItems: "center",
      marginRight: "0 !important",
      "& .MuiFormControlLabel-root": {
        marginRight: "0",
      },
    },
    typography: { fontSize: "12px", fontWeight: "400", ...sx },
    rightAlignTypho: {
      fontSize: "12px",
      fontWeight: "300",
    },
  };

  return (
    <>
      <FormControl error={error}>
        <Box sx={{ ...styles.box }}>
          {/* <Typography sx={{ ...styles.typography }}>{label}</Typography> */}

          <FormControlLabel
            label=""
            sx={{ ...formControlStyle }}
            control={
              <Checkbox
                checked={checked}
                name={name}
                onChange={onChange}
                inputProps={{ "aria-label": "controlled" }}
                disabled={disabled}
                sx={{
                  color: "#8a8a8a",
                  "&.Mui-checked": {
                    color: color,
                  },
                }}
              />
            }
          />
          <Typography variant="h6" sx={{ ...styles.typography }}>
            {label}
          </Typography>

          {rightAlign && (
            <Typography sx={{ ...styles.rightAlignTypho }}>
              {rightAlignText}
            </Typography>
          )}
        </Box>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default CustomCheckbox;
