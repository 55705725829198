import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  Switch,
  debounce,
  CircularProgress,
} from "@mui/material";
import { Label, Sort, TextField } from "../../../../components/basic";
import {
  DataTable,
  ConfirmationDialog,
  DialogWrapper,
} from "../../../../components/shared";
import { DeleteIcon, EditIcon, PlusIcon } from "../../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { appointmentsMasterConst } from "../../../../constants/displayText";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import CustomFilter from "../../../../components/shared/customFilter";
import {
  getSymptomsList,
  createSymptom,
  editSymptom,
  updateSymptom,
  deleteSymptom,
} from "../../../../services/appointmentsMasterService";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import PermissionUtils from "../../../../utils/PermissionUtils";
import { setCurrentPage } from "../../../../redux/slices/pagination";

type GridRowData = Record<string, unknown>;

const SymptomsMasterList = () => {
  const {
    SYMPTOM_NAME,
    STATUS,
    ADDED_SUCCESSFULLY,
    UPDATED_SUCCESSFULLY,
    DELETED_SUCCESSFULLY,
  } = appointmentsMasterConst;

  const dispatch = useDispatch();
  const { can } = PermissionUtils();

  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [searchValue, setSearchValue] = useState<any>("");
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });

  const pageInfo = useSelector(
    (state: RootState) => state?.pagination?.appointmentMasterSymptomList
  );

  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
  const [symptomsList, setSymptomsList] = useState([]);

  const [open, setOpen] = useState<boolean>(false);
  const [idToBeUpdated, setIdToBeUpdated] = useState<any>(null);

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);

  const [symptomToBeDeleted, setSymptomToBeDeleted] = useState<any>(null);

  const initialSymptomError = {
    symptom_name: "",
    active: "",
  };

  const [symptomError, setSymptomError] = useState<any>(initialSymptomError);

  const initialData = {
    symptom_name: "",
    active: true,
  };

  const [symptomFormData, setSymptomFormData] = useState<string | Date | any>(
    initialData
  );

  const fieldData: any = {
    symptom_name: {
      label: "Symptom Name",
      name: "symptom_name",
      value: symptomFormData.symptom_name,
      ref: React.createRef(),
      isError: Boolean(symptomError.symptom_name),
      helperText: symptomError.symptom_name,
    },
    active: {
      label: "Status",
      name: "active",
      value: Boolean(Number(symptomFormData.active)),
      isError: Boolean(symptomError.active),
      helperText: symptomError.active,
    },
  };

  const [symptomFieldData, setSymptomFieldData] = useState(fieldData);

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "5px",
        }}
      >
        {can("symptoms_edit") && (
          <div style={{ cursor: "pointer" }}>
            <EditIcon onClick={() => symptomEdit(row.id)} />
          </div>
        )}
        {can("symptoms_delete") && (
          <div style={{ cursor: "pointer" }}>
            <DeleteIcon onClick={() => symptomDelete(row.id)} />
          </div>
        )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <Grid
          onClick={() => handleSorting("symptom_name")}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Label
            variant="h5"
            sx={{ color: "primary.main" }}
            label={SYMPTOM_NAME}
          />
          <Sort
            ascendingActive={
              sortedField.field === "symptom_name" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "symptom_name" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.symptom_name == null ? "" : row?.symptom_name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "status",
      flex: 1,
      renderHeader: () => (
        <Grid
          onClick={() => handleSorting("active")}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Label variant="h5" sx={{ color: "primary.main" }} label={STATUS} />
          <Sort
            ascendingActive={
              sortedField.field === "active" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "active" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.active == null
            ? ""
            : Number(row?.active) === 1
            ? "Active"
            : "Inactive"}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    ...(can("symptoms_edit") || can("symptoms_delete")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </Grid>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  const handleSearchOnChange = (e: any) => {
    setSearchValue(e.target.value);
    dispatch(setCurrentPage({ key: "appointmentMasterSymptomList", value: 0 }));
  };

  const getSymptomsDetails = useCallback(
    debounce(async (data) => {
      setLoading(true);

      getSymptomsList(data)
        .then((result: any) => {
          let data = result?.data?.result;

          setSymptomsList(data);
          setPageCount(result?.data?.total as any);
          setLoading(false);
        })
        .catch((error: any) => {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: error?.response?.data?.message,
            })
          );
        });
    }, 300),
    []
  );

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;
    let isFocused = false;

    switch (name) {
      case "symptom_name": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setSymptomError
          );
          if (!isFocused) {
            if (fieldData[name]?.ref?.current) {
              fieldData[name]?.ref?.current?.focus();
              isFocused = true;
            }
          }
        } else {
          updateFormDataWithHelperText(name, "", setSymptomError);
        }
        break;
      }
      case "active": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setSymptomError
          );
        } else {
          updateFormDataWithHelperText(name, "", setSymptomError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateSymptomFieldData = () => {
    setSymptomFieldData((prev: any) => {
      return Object.keys(prev).map((field: any) => {
        return {
          ...field,
          helperText: symptomError[field.name],
          value: symptomFormData[field.name],
          isError: Boolean(symptomError[field.name]),
        };
      });
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setSymptomFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: value,
    }));
    handleValidation(e);
  };

  const handleSwitchChange = async (e: any) => {
    const { name, checked } = e.target;

    setSymptomFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: checked,
    }));
  };

  const symptomCreate = async () => {
    if (!Boolean(symptomFormData.symptom_name)) {
      validateForm();
    } else {
      try {
        const data = {
          symptom_name: symptomFormData.symptom_name,
          active: symptomFormData.active === true ? 1 : 0,
        };
        setButtonLoading(true);

        await createSymptom(data).then((result: any) => {
          dialogClose();
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: ADDED_SUCCESSFULLY,
            })
          );
          getSymptomsDetails({
            page: Number(pageInfo.page) + 1,
            pageSize: pageInfo.pageSize,
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            searchValue: searchValue,
          });
        });
      } catch (error: any) {
        setButtonLoading(false);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
        console.error("An error occurred:", error);
      }
    }
  };

  const symptomEdit = async (id: number) => {
    try {
      if (!Boolean(id)) {
        return false;
      }
      setOpen(true);
      setIsDialogLoading(true);
      await editSymptom(id).then((result: any) => {
        const data = result?.data;
        setSymptomFormData({
          symptom_name: data.symptom_name,
          active: data.active == 0 ? false : true,
        });
        setIdToBeUpdated(id);
        setIsDialogLoading(false);
      });
    } catch (error: any) {
      setOpen(false);
      setIsDialogLoading(false);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.message,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const symptomUpdate = async () => {
    if (!Boolean(symptomFormData.symptom_name)) {
      validateForm();
    } else {
      try {
        if (!Boolean(idToBeUpdated)) {
          return false;
        }
        const data = {
          symptom_name: symptomFormData.symptom_name,
          active: symptomFormData.active === true ? 1 : 0,
        };
        setButtonLoading(true);
        await updateSymptom(idToBeUpdated, data).then((result: any) => {
          dialogClose();
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: UPDATED_SUCCESSFULLY,
            })
          );
          getSymptomsDetails({
            page: Number(pageInfo.page) + 1,
            pageSize: pageInfo.pageSize,
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            searchValue: searchValue,
          });
        });
      } catch (error: any) {
        setButtonLoading(false);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
        console.error("An error occurred:", error);
      }
    }
  };

  const symptomDelete = (symptomId: number) => {
    setIsConfirmationDialogOpen(true);
    setSymptomToBeDeleted(symptomId);
  };

  const handleConfirmDelete = async () => {
    if (!Boolean(symptomToBeDeleted)) {
      return false;
    }
    try {
      setButtonLoading(true);
      await deleteSymptom(symptomToBeDeleted).then((result: any) => {
        dialogClose();
        dispatch(
          setSnackBarSuccess({
            snackBarMessage: DELETED_SUCCESSFULLY,
          })
        );
        getSymptomsDetails({
          page: Number(pageInfo.page) + 1,
          pageSize: pageInfo.pageSize,
          sortBy: sortedField.field,
          sortOrder: sortedField.order,
          searchValue: searchValue,
        });
      });
    } catch (error: any) {
      setButtonLoading(false);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.message,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const onClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const dialogOpen = async () => {
    setOpen(true);
  };

  const dialogClose = async () => {
    setOpen(false);
    setSymptomFormData(initialData);
    setIdToBeUpdated(null);
    setSymptomError(initialSymptomError);
    setSymptomToBeDeleted(null);
    setIsConfirmationDialogOpen(false);
    setIsDialogLoading(false);
    setButtonLoading(false);
  };

  useEffect(() => {
    updateSymptomFieldData();
  }, [symptomError, symptomFormData]);

  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo?.pageSize,
      sortBy: sortedField.field,
      sortOrder: sortedField.order,
      searchValue: searchValue,
    };
    getSymptomsDetails(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortedField.field,
    sortedField.order,
    searchValue,
  ]);

  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          title="are you surely want to delete?"
          handleClick={handleConfirmDelete}
          onClose={onClose}
          loading={buttonLoading}
        />

        <CustomFilter
          isSearchEnabled={true}
          searchValue={searchValue}
          searchOnChange={handleSearchOnChange}
          searchName={"search"}
          clearSearch={() => setSearchValue("")}
          {...(can("symptoms_add") && {
            editButtonTitle: "Add",
            onUpdateButtonClick: dialogOpen,
            editStartIcon: (
              <Box
                sx={{
                  "& path": {
                    fill: "#fff",
                  },
                }}
              >
                <PlusIcon width={"12px"} height={"12px"} />
              </Box>
            ),
          })}
        />
        <DataTable
          title="Symptoms Master"
          loading={loading}
          columns={columns}
          getRowId={(row: GridRowData) => `${String(row.id)}`}
          rows={symptomsList}
          pageCount={pageCount}
          currentPage={"appointmentMasterSymptomList"}
        />
      </Box>

      <DialogWrapper
        onClose={dialogClose}
        open={open}
        handleClick={!Boolean(idToBeUpdated) ? symptomCreate : symptomUpdate}
        title={!Boolean(idToBeUpdated) ? "Add Symptom" : "Edit Symptom"}
        maxWidth="sm"
        loading={buttonLoading}
      >
        {isDialogLoading ? (
          <Grid
            sx={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              height: "20vh",
              background: "transparent",
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <TextField
              value={fieldData.symptom_name.value}
              inputRef={fieldData?.symptom_name?.ref}
              onChange={handleInputChange}
              name={fieldData.symptom_name.name}
              label={fieldData.symptom_name.label}
              helperText={fieldData.symptom_name.helperText}
              sx={{ width: { xs: "250px", sm: "300px" } }}
            />
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>status</Typography>
              <Switch
                name={fieldData.active.name}
                checked={fieldData.active.value}
                onChange={handleSwitchChange}
                disabled={!Boolean(idToBeUpdated) ? true : false}
              />
            </Grid>
          </>
        )}
      </DialogWrapper>
    </div>
  );
};

export default SymptomsMasterList;
