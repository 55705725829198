import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type AutocompleteWithTableProps = {
  value?: string | null;
  name?: string | any;
  onChange?: any;
  placeholder?: string;
  options: string[];
  sx?: {};
  renderOption?: any;
  getOptionLabel?: any;
  PopperComponent?: any;
  onFocus?: any;
  onBlur?: any;
  isHideClearIcon?: boolean;
  isDisabled?: boolean;
  inputRef?: any;
  inputValue?: any;
  onInputChange?: any;
  noOptionsText?: any;
  filterOptions?: any;
  isValueAsObject?: boolean;
  error?: boolean;
};

const MuiAutocompleteWithTable = ({
  value,
  onChange,
  placeholder,
  name,
  options,
  sx,
  renderOption,
  PopperComponent,
  getOptionLabel,
  isHideClearIcon = false,
  onFocus,
  onBlur,
  isDisabled,
  inputRef,
  inputValue,
  onInputChange,
  noOptionsText,
  isValueAsObject,
  filterOptions,
  error,
}: AutocompleteWithTableProps) => {
  return (
    <Autocomplete
      onChange={onChange}
      popupIcon={<ExpandMoreIcon />}
      id="controllable-states-demo"
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      noOptionsText={noOptionsText}
      value={value}
      {...(isValueAsObject && { value })}
      sx={{
        fontFamily: ["Inter", "sans-serif"].join(","),

        "& .MuiChip-filled": {
          backgroundColor: "#F4F4F4",
          borderRadius: "var(--primary-border-radius)",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: error ? "#d32f2f" : "var(--primary-border-color)", // Default border color
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: error ? "#d32f2f" : "var(--primary-border-color)", // Change the border color on hover
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: error ? "#d32f2f" : "primary.main",
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
          borderColor: error ? "#d32f2f" : "var(--primary-border-color)", // Change the border color on hover
        },
        "& .MuiInputBase-input::placeholder": {
          fontSize: "var(--primary-input-text-font-size)",
        },
        "& input": {
          fontSize: "var(--primary-input-text-font-size)",
        },
        "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
          padding: "5px 0px",
          boxShadow: "none",
          borderRadius: "var(--primary-border-radius)",
          paddingLeft: "8px",
          minHeight: "44px",
          maxHeight: "110px",
          overflowX: "hidden",
          overflowY: value && value?.length > 2 ? "scroll" : "none",
        },
        "& .MuiInputBase-input.Mui-disabled": {
          borderColor: "#E5E7EB",
          backgroundColor: "#F4F4F5",
          pl: "5px",
          borderRadius: "var(--primary-border-radius)",
        },
        "& .MuiOutlinedInput-root.Mui-disabled": {
          borderColor: "#E5E7EB",
          backgroundColor: "#F4F4F5",
          // pl: "15px",
          borderRadius: "var(--primary-border-radius)",
        },
        ...sx,
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      disableClearable={isHideClearIcon}
      disabled={isDisabled}
      inputValue={inputValue}
      onInputChange={onInputChange}
      renderInput={(params) => {
        return (
          <>
            <TextField
              {...params}
              name={name}
              {...(!isValueAsObject && { value })}
              inputRef={
                inputRef
                  ? (input) => {
                      inputRef.current = input;
                    }
                  : undefined
              }
              InputProps={{
                ...params.InputProps,
              }}
              placeholder={placeholder}
            />
          </>
        );
      }}
      PopperComponent={PopperComponent}
      includeInputInList
    />
  );
};

export default MuiAutocompleteWithTable;
