import { Typography, Grid, Switch, InputAdornment } from "@mui/material";
import { DeleteIcon } from "../../../../../assets/icons";
import {
  TextField,
  Select,
  SaveButton,
  CancelButton,
} from "../../../../../components/basic";
import {
  AutoComplete,
  CheckBox,
  Button,
} from "../../../../../components/basic";

type AddAndEditProductType = {
  fieldData: any;
  handleInputChange: (e: any, index?: number | string) => void;
  handleValidation: (e: any) => void;
  handleAutoCompleteChange: (e: any, newValue: any, name: string) => void;
  handleAutoCompleteInputChange: (newInputValue: any, name: string) => void;
  handleSelectChange: (e: any, id: number) => void;
  productFormData: any;
  handleDeleteDepartment: (id: number) => Promise<void>;
  handleAddDepartment: () => Promise<void>;
  handleSwitchChange: (e: any) => Promise<void>;
  idToBeUpdated: number | null;
  handleDrugComposition?: any;
  createProduct?: () => void;
  updateExistPackType?: () => void;
  closeAddAndEditDialog: () => void;
  handleProductSwitchChange: (e: any, index: number | string) => void;
  isButtonLoading: boolean;
};

const AddAndEditProduct = ({
  fieldData,
  handleInputChange,
  handleValidation,
  handleSelectChange,
  productFormData,
  handleDeleteDepartment,
  handleAddDepartment,
  handleSwitchChange,
  idToBeUpdated,
  createProduct,
  closeAddAndEditDialog,
  handleProductSwitchChange,
  isButtonLoading
}: AddAndEditProductType) => {
  const departmentOptions = [
    { id: 1, name: "Warehouse" },
    { id: 2, name: "Pharmacy" },
  ];

  return (
    <div>
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          "& .MuiTypography-root": {
            m: "10px 0px",
          },
        }}
      >
        <Typography
          variant="h2"
          sx={{
            width: "100%",
            textAlign: "left",
            color: "primary.main",
          }}
        >
          Product Details
        </Typography>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "space-between",
          }}
        >
          <TextField
            value={fieldData?.name?.value}
            name={fieldData?.name.name}
            onChange={handleInputChange}
            label={fieldData?.name?.label}
            placeholder="Enter Product Name"
            helperText={fieldData?.name?.helperText}
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.name }))
            }
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },
            }}
          />

          <TextField
            value={fieldData?.chemical_reagent?.value}
            name={fieldData?.chemical_reagent?.name}
            onChange={handleInputChange}
            label={fieldData?.chemical_reagent?.label}
            placeholder="Enter Chemical Reagent"
            helperText={fieldData?.chemical_reagent?.helperText}
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.chemical_reagent }))
            }
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },
            }}
          />
        </Grid>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "space-between",
          }}
        >
          <TextField
            value={fieldData?.no_of_tests?.value}
            name={fieldData?.no_of_tests?.name}
            onChange={handleInputChange}
            label={fieldData?.no_of_tests?.label}
            placeholder="Enter No of Tests"
            helperText={fieldData?.no_of_tests?.helperText}
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.no_of_tests }))
            }
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },
            }}
          />
        </Grid>

        {productFormData?.dept_reorder?.map((data: any, index: any) => {
          return (
            <>
              {index === 0 && (
                <Grid
                  key={index}
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "350px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ width: { xs: "50%", md: "350px" } }}
                    >
                      Department
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "350px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        width: { sm: "70px", md: "90px" },
                        textAlign: "left",
                      }}
                    >
                      Re Order Qty
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        width: { sm: "70px", md: "90px" },
                        textAlign: "left",
                      }}
                    >
                      Max Qty
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        width: { sm: "70px", md: "90px" },
                        textAlign: "left",
                      }}
                    >
                      Min Qty
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        width: { sx: "15px" },
                        textAlign: "left",
                      }}
                    ></Typography>
                  </Grid>
                </Grid>
              )}
              <Grid
                key={index}
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: { xs: "row" },
                  alignItems: { xs: "center" },
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "350px",
                    mb: "10px",
                  }}
                >
                  <Select
                    value={data.dept_id}
                    onChange={(e) => handleSelectChange(e, index)}
                    name="dept_id"
                    placeholder="Select Department"
                    options={departmentOptions}
                    width="350px"
                    sx={{
                      width: {
                        xs: "180px",
                        sm: "250px",
                        md: "350px",
                      },
                    }}
                  />
                  <CheckBox
                    name={"is_indent"}
                    value={data.is_indent}
                    sx={{ width: "30px" }}
                    formControlStyle={{ mt: "10px", ml: "0px" }}
                    checked={data.is_indent}
                    onChange={(e: any) => handleProductSwitchChange(e, index)}
                  />
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "350px",
                    mb: "10px",
                  }}
                >
                  <TextField
                    value={data.re_order_qty}
                    name="re_order_qty"
                    onChange={(e) => handleInputChange(e, index)}
                    sx={{
                      width: {
                        sm: "70px",
                        md: "90px",
                      },
                    }}
                  />
                  <TextField
                    value={data.max_qty}
                    name="max_qty"
                    onChange={(e) => handleInputChange(e, index)}
                    sx={{
                      width: {
                        sm: "70px",
                        md: "90px",
                      },
                    }}
                  />
                  <TextField
                    value={data.min_qty}
                    name="min_qty"
                    onChange={(e) => handleInputChange(e, index)}
                    sx={{
                      width: {
                        sm: "70px",
                        md: "90px",
                      },
                    }}
                  />
                  <Grid
                    sx={{
                      width: {
                        sx: "15px",
                      },
                      p: "10px",
                      display: index !== 0 ? "flex" : "hidden",
                      border: index !== 0 ? 1 : 0,
                      borderColor: "backgroundPrimary.main",
                      transition: "all 0.2s linear",
                      "&:hover": { borderColor: "textPrimary.main" },
                      cursor: index !== 0 ? "pointer" : "default",
                    }}
                    onClick={
                      index !== 0
                        ? () => handleDeleteDepartment(index)
                        : () => {}
                    }
                  >
                    {index !== 0 ? (
                      <DeleteIcon width="15px" />
                    ) : (
                      <Typography
                        variant="h6"
                        sx={{
                          width: "15px",
                          color: "backgroundPrimary.main",
                        }}
                      >
                        ""
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        })}
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            borderTop: 0,
            width: "100%",
          }}
        >
          <Button
            variant="text"
            buttonText="+ Add"
            handleClick={handleAddDepartment}
            sx={{
              borderRadius: "0px",
              width: "auto",
              height: "auto",
              fontWeight: 400,
              fontSize: "13px",
              border: 0,
              color: "primary.main",
              "&:hover": {
                backgroundColor: "initial",
                color: "primary.main",
                borderColor: "initial",
              },
              "&.Mui-focusVisible": {
                border: 1,
                borderColor: "primary.main",
              },
            }}
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            mb: "10px",
            width: "100%",
          }}
        >
          <Typography>{fieldData?.status?.label}</Typography>
          <Switch
            name={fieldData?.status?.name}
            checked={fieldData?.status?.value}
            onChange={handleSwitchChange}
            value={fieldData?.status?.active}
            disabled={Boolean(!idToBeUpdated)}
          />
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            gap: 2,
          }}
        >
          <SaveButton
            sx={{ width: "100px", height: "40px" }}
            handleClick={createProduct}
            loading={isButtonLoading}
          />
          <CancelButton
            sx={{ width: "100px", height: "40px", ml: 3 }}
            handleClick={closeAddAndEditDialog}
          />
        </Grid>
      </Grid>
    </div>
  );
};


export default AddAndEditProduct;
