import { useEffect, useState, useCallback, useRef } from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  IconButton,
  debounce,
  Grid,
  Button,
  Switch,
  CircularProgress,
} from "@mui/material";
import {
  Sort,
  Select,
  TimePicker,
  TextField,
} from "../../../../components/basic";
import {
  DataTable,
  ConfirmationDialog,
  DialogWrapper,
} from "../../../../components/shared";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  getWorkingHoursList,
  createWorkingHours,
  editWorkingHours,
  updateWorkingHours,
  deleteWorkingHours,
  getDoctorsList,
  deleteDoctorsWorkingHoursById,
} from "../../../../services/appointmentsMasterService";
import {
  appointmentsMasterConst,
  commonText,
} from "../../../../constants/displayText";
import CustomFilter from "../../../../components/shared/customFilter";
import { PlusIcon, DeleteIcon, EditIcon } from "../../../../assets/icons";
import {
  timeFormat,
  convertTimeToDate,
  convertDateToTime,
} from "../../../../utils/DateTimeFormatUtils";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import PermissionUtils from "../../../../utils/PermissionUtils";
import moment from "moment";
import { setCurrentPage } from "../../../../redux/slices/pagination";

type GridRowData = Record<string, unknown>;

const WorkingHours = () => {
  const {
    DOCTOR_NAME,
    DAY_NAME,
    START_TIME,
    END_TIME,
    INTERVAL,
    ACTIONS,
    ADDED_SUCCESSFULLY,
    UPDATED_SUCCESSFULLY,
    DELETED_SUCCESSFULLY,
  } = appointmentsMasterConst;
  const { can } = PermissionUtils();

  const [doctorOptions, setDoctorOptions] = useState<any>([]);

  const [workingHoursList, setWorkingHoursList] = useState<any>([]);
  const [activeSort, setActiveSort] = useState({ field: "", order: "" });
  const [pageCount, setPageCount] = useState<number | null>(null);

  const [isAddAndEditDialogOpen, setIsAddAndEditDialogOpen] =
    useState<boolean>(false);
  const [idToBeUpdated, setIdToBeUpdated] = useState<any>(null);

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [workingHourToBeDeleted, setWorkingHourToBeDeleted] =
    useState<any>(null);

  const [isFieldSort, setIsFieldSort] = useState(false);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });

  const [searchValue, setSearchValue] = useState<number | string>("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isDataTableLoading, setIsDataTableLoading] = useState<boolean>(false);
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
  const [isDialogInnerComponentLoading, setIsDialogInnerComponentLoading] =
    useState<boolean>(false);
  let isDialogInnerComponentLoadingRef = false;

  const dispatch = useDispatch();

  const pageInfo = useSelector(
    (state: RootState) => state?.pagination?.appointmentMasterWorkingHours
  );

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {can("workhour_edit") && (
          <div style={{ cursor: "pointer" }}>
            <IconButton
              aria-label=""
              onClick={() => workingHoursEdit(row.doctor_id)}
            >
              <EditIcon />
            </IconButton>
          </div>
        )}
        {can("workhour_delete") && (
          <div style={{ cursor: "pointer" }}>
            <IconButton
              aria-label=""
              onClick={() => workingHoursDelete(row.doctor_id)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "doctor_name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("doctor_name")}
        >
          <Typography variant="h5" fontSize={14}>
            {DOCTOR_NAME}
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "doctor_name" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "doctor_name" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.doctor_name}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    ...(can("workhour_edit") || can("workhour_delete")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  {ACTIONS}
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const initialData = [
    {
      doctor_id: null,
      doctor_name: "",
      days: [
        {
          day_name: "Monday",
          active: false,
          times: [
            {
              start_time: null,
              end_time: null,
              interval: null,
            },
          ],
        },
        {
          day_name: "Tuesday",
          active: false,
          times: [
            {
              start_time: null,
              end_time: null,
              interval: null,
            },
          ],
        },
        {
          day_name: "Wednesday",
          active: false,
          times: [
            {
              start_time: null,
              end_time: null,
              interval: null,
            },
          ],
        },
        {
          day_name: "Thursday",
          active: false,
          times: [
            {
              start_time: null,
              end_time: null,
              interval: null,
            },
          ],
        },
        {
          day_name: "Friday",
          active: false,
          times: [
            {
              start_time: null,
              end_time: null,
              interval: null,
            },
          ],
        },
        {
          day_name: "Saturday",
          active: false,
          times: [
            {
              start_time: null,
              end_time: null,
              interval: null,
            },
          ],
        },
        {
          day_name: "Sunday",
          active: false,
          times: [
            {
              start_time: null,
              end_time: null,
              interval: null,
            },
          ],
        },
      ],
    },
  ];

  const [workingHoursData, setWorkingHoursData] = useState<any>(initialData);
  const [isDeleteWorkingHourModal, setIsDeleteWorkingHourModal] = useState<{
    isOpen: boolean;
    id: number | string | null;
    day_name: string | null;
  }>({ isOpen: false, id: null, day_name: null });

  function checkActivityAndTimes(arr: any) {
    if (arr.length === 0) return false;

    const obj = arr[0]; // Get the first object from the array
    const days = obj.days;

    let hasError = false;
    for (let i = 0; i < days.length; i++) {
      const day = days[i];
      if (day?.active) {
        const times = day.times;
        for (let j = 0; j < times.length; j++) {
          const time = times[j];
          if (
            time?.start_time !== null &&
            time?.end_time !== null &&
            time?.interval !== null &&
            time?.interval !== ""
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  const initialWorkingHoursError = {
    doctor_id: "",
    working_hour: "",
  };

  const [workingHoursError, setWorkingHoursError] = useState<any>(
    initialWorkingHoursError
  );
  const [errors, setErrors] = useState<any>({});

  let workingHourValue = checkActivityAndTimes(workingHoursData)
    ? "included"
    : null;

  const fieldData: any = {
    doctor_id: {
      label: "Doctor Name",
      name: "doctor_id",
      value: workingHoursData[0].doctor_id,
      ref: useRef(null),
      isError: Boolean(workingHoursError.doctor_id),
      helperText: workingHoursError.doctor_id,
    },
    working_hour: {
      label: "Working Hour",
      name: "working_hour",
      value: workingHourValue,
      isError: Boolean(workingHoursError.working_hour),
      helperText: workingHoursError.working_hour,
    },
  };

  const [workingHoursFieldData, setWorkingHoursFieldData] = useState(fieldData);

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "doctor_id": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setWorkingHoursError
          );
        } else {
          updateFormDataWithHelperText(name, "", setWorkingHoursError);
        }
        break;
      }
      case "working_hour": {
        if (requiredValidator(workingHourValue, "working hour")) {
          updateFormDataWithHelperText(
            "working_hour",
            requiredValidator(workingHourValue, "working hour"),
            setWorkingHoursError
          );
        } else {
          updateFormDataWithHelperText(
            "working_hour",
            "",
            setWorkingHoursError
          );
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateSymptomFieldData = () => {
    setWorkingHoursFieldData((prev: any) => {
      return Object.keys(prev)?.map((field: any) => {
        return {
          ...field,
          helperText: workingHoursError[field.name],
          value:
            field?.name === "doctor_id"
              ? workingHoursData[0].doctor_id
              : checkActivityAndTimes(workingHoursData)
              ? "included"
              : null,
          isError: Boolean(workingHoursError[field?.name]),
        };
      });
    });
  };

  const convertArrayOfObjectsIntoNestedArray = (arr: any, doctorId: number) => {
    const arrayTwo: any = [];
    const arrayOne: any = arr;

    const weekdaysOrder = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const missingWeekdays = weekdaysOrder.filter(
      (day: any) => !arrayOne?.some((obj: any) => obj?.day_name === day)
    );

    // Create objects for missing weekdays and add them to daysArray
    missingWeekdays?.forEach((day, index) => {
      arrayOne?.push({
        doctor_id: doctorId,
        day_name: day,
        start_time: null,
        end_time: null,
        interval: null,
      });
    });

    // Custom sorting function based on weekdaysOrder
    const sortedArrayByDay = arrayOne?.sort((a: any, b: any) => {
      return (
        weekdaysOrder?.indexOf(a?.day_name) -
        weekdaysOrder?.indexOf(b?.day_name)
      );
    });

    const sortedArrayByTime = sortedArrayByDay?.sort((a: any, b: any) => {
      // Convert start_time strings to Date objects for comparison
      const timeA: any = new Date(`2000-01-01T${a?.start_time}`);
      const timeB: any = new Date(`2000-01-01T${b?.start_time}`);

      // Compare the Date objects
      return timeA - timeB;
    });

    sortedArrayByTime.forEach((item: any) => {
      // Check if the doctor with the same id and name exists in arrayTwo
      const doctorIndex = arrayTwo.findIndex(
        (doc: any) => doc?.doctor_id === item?.doctor_id
      );

      if (doctorIndex === -1) {
        // If the doctor doesn't exist in arrayTwo, create a new entry
        arrayTwo.push({
          doctor_id: item?.doctor_id,
          doctor_name: item?.doctor_name,
          days: [
            {
              day_name: item?.day_name,
              active: !!item?.active,
              times: [
                {
                  id: item?.id,
                  start_time:
                    item?.start_time === null
                      ? null
                      : convertTimeToDate(timeFormat(item?.start_time)),
                  end_time:
                    item?.end_time === null
                      ? null
                      : convertTimeToDate(timeFormat(item?.end_time)),
                  interval: item?.interval,
                },
              ],
            },
          ],
        });
      } else {
        // If the doctor already exists in arrayTwo, find the corresponding day
        const dayIndex = arrayTwo[doctorIndex]?.days?.findIndex(
          (day: any) => day?.day_name === item?.day_name
        );

        if (dayIndex === -1) {
          // If the day doesn't exist, create a new day entry
          arrayTwo[doctorIndex]?.days?.push({
            day_name: item?.day_name,
            active: !!item?.active,
            times: [
              {
                id: item?.id,
                start_time:
                  item?.start_time === null
                    ? null
                    : convertTimeToDate(timeFormat(item?.start_time)),
                end_time:
                  item?.end_time === null
                    ? null
                    : convertTimeToDate(timeFormat(item?.end_time)),
                interval: item?.interval,
              },
            ],
          });
        } else {
          // If the day already exists, add the time slot to the existing day
          arrayTwo[doctorIndex]?.days[dayIndex]?.times?.push({
            id: item.id,
            start_time:
              item?.start_time === null
                ? null
                : convertTimeToDate(timeFormat(item?.start_time)),
            end_time:
              item?.end_time === null
                ? null
                : convertTimeToDate(timeFormat(item?.end_time)),
            interval: item?.interval,
          });
        }
      }
    });

    return arrayTwo;
  };

  const convertNestedArrayIntoArrayOfObjects = (arr: any) => {
    const initialArray: any = [];

    arr?.forEach((doctor: any) => {
      doctor?.days?.forEach((day: any) => {
        day?.times?.forEach((time: any) => {
          if (
            time?.start_time !== null &&
            time?.end_time !== null &&
            time?.interval !== null &&
            time?.interval !== ""
          ) {
            initialArray?.push({
              doctor_id: doctor?.doctor_id,
              doctor_name: doctor?.doctor_name,
              day_name: day?.day_name,
              active: day?.active ? 1 : 0,
              id: time?.id ? time?.id : null,
              start_time: moment(convertDateToTime(time?.start_time), [
                "hh:mm:ss A",
                "HH:mm:ss A",
              ]).format("HH:mm:ss"),
              end_time: moment(convertDateToTime(time?.end_time), [
                "hh:mm:ss A",
                "HH:mm:ss A",
              ]).format("HH:mm:ss"),
              interval: time?.interval,
            });
          }
        });
      });
    });

    return initialArray;
  };

  const getWorkingHoursDetails = useCallback(
    debounce((data) => {
      setIsDataTableLoading(true);
      getWorkingHoursList(data)
        .then((res: any) => {
          const data = res?.data?.result;

          setWorkingHoursList(data);
          setPageCount(res?.data?.total);
          setIsDataTableLoading(false);
        })
        .catch((error: any) => {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: error?.response?.data?.message,
            })
          );
          setIsDataTableLoading(false);
        });
    }, 300),
    []
  );

  const finalValidation = () => {
    const requiredTimingsFields = ["start_time", "end_time", "interval"];

    if (!workingHoursData[0]?.doctor_id) {
      setWorkingHoursError((prev: any) => ({
        ...prev,
        doctor_id: "Please fill the doctor name field",
      }));

      if (fieldData?.doctor_id?.ref?.current) {
        fieldData?.doctor_id?.ref?.current?.focus();
      }
      return false;
    }

    let hasTimes = false;
    workingHoursData[0]?.days?.forEach((day: any) => {
      if (day.active) {
        hasTimes = true;
      }
    });

    if (!hasTimes) {
      setWorkingHoursError((prev: any) => ({
        ...prev,
        working_hour: "Please select at least one working hours.",
      }));
      return false;
    }

    let hasError = true;
    let errors: { [key: string]: any } = {};
    workingHoursData[0]?.days?.forEach((day: any) => {
      if (day.active) {
        errors[day.day_name] = {
          times: [],
        };
        day?.times?.forEach((time: any) => {
          const timeError: any = {};
          requiredTimingsFields.forEach((key) => {
            if (!time[key]) {
              timeError[key] = "required field";
              hasError = false;
            }
          });
          errors[day.day_name].times.push(timeError);
        });
      }
    });

    if (!hasError) {
      setWorkingHoursError((prev: any) => ({
        ...prev,
        working_hour: "Please fill in all required fields for working hours.",
      }));
      setErrors(errors);
    }
    return hasError;
  };

  const workingHoursCreate = async () => {
    if (finalValidation()) {
      //   validateForm();
      // } else {
      try {
        const data = await convertNestedArrayIntoArrayOfObjects(
          workingHoursData
        );
        setIsButtonLoading(true);
        await createWorkingHours(data).then((result: any) => {
          closeAddAndEditDialog();

          dispatch(
            setSnackBarSuccess({
              snackBarMessage: ADDED_SUCCESSFULLY,
            })
          );
          getWorkingHoursDetails({
            page: Number(pageInfo.page) + 1,
            pageSize: pageInfo?.pageSize,
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            searchValue: searchValue,
          });
        });
      } catch (error: any) {
        setIsButtonLoading(false);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
        console.error("An error occurred:", error);
      }
    }
  };

  const workingHoursEdit = async (id: number) => {
    try {
      if (id === null) {
        return false;
      }
      setIsAddAndEditDialogOpen(true);
      if (!isDialogInnerComponentLoadingRef) {
        setIsDialogLoading(true);
      }
      await editWorkingHours(id).then((result: any) => {
        const data = result?.data;
        if (data.length > 0) {
          const convertedArray = convertArrayOfObjectsIntoNestedArray(
            data,
            data[0]?.doctor_id
          );
          setWorkingHoursData(convertedArray);
          setIdToBeUpdated(id);
        } else {
          setWorkingHoursData((prev: any) => {
            return [
              {
                ...initialData[0],
                doctor_id: id,
              },
            ];
          });
          setIdToBeUpdated(null);
          setWorkingHoursError(initialWorkingHoursError);
        }

        setIsDialogLoading(false);
        setIsDialogInnerComponentLoading(false);
        isDialogInnerComponentLoadingRef = false;
      });
    } catch (error: any) {
      setIsDialogLoading(false);
      setIsDialogInnerComponentLoading(false);
      isDialogInnerComponentLoadingRef = false;
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.message,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const workingHoursUpdate = async () => {
    if (
      !Boolean(workingHoursData[0].doctor_id) ||
      !Boolean(checkActivityAndTimes(workingHoursData))
    ) {
      validateForm();
    } else {
      try {
        if (!Boolean(idToBeUpdated)) {
          return false;
        }
        const data = convertNestedArrayIntoArrayOfObjects(workingHoursData);
        setIsButtonLoading(true);
        await updateWorkingHours(idToBeUpdated, data).then((result: any) => {
          closeAddAndEditDialog();
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: UPDATED_SUCCESSFULLY,
            })
          );
          getWorkingHoursDetails({
            page: Number(pageInfo.page) + 1,
            pageSize: pageInfo?.pageSize,
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            searchValue: searchValue,
          });
        });
      } catch (error: any) {
        setIsButtonLoading(false);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
        console.error("An error occurred:", error);
      }
    }
  };

  const workingHoursDelete = (workingHourId: number) => {
    setIsConfirmationDialogOpen(true);
    setWorkingHourToBeDeleted(workingHourId);
  };

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (isFieldSort) {
      setSortedField({ field: field, order: "ASC" });
      setActiveSort({ field: field, order: "ASC" });
    }
    if (!isFieldSort) {
      setSortedField({ field: field, order: "DESC" });
      setActiveSort({ field: field, order: "DESC" });
    }
  };

  const handleConfirmDelete = async () => {
    if (!Boolean(workingHourToBeDeleted)) {
      return false;
    }
    try {
      setIsButtonLoading(true);
      await deleteWorkingHours(workingHourToBeDeleted).then((result: any) => {
        closeAddAndEditDialog();
        dispatch(
          setSnackBarSuccess({
            snackBarMessage: DELETED_SUCCESSFULLY,
          })
        );
        getWorkingHoursDetails({
          page: Number(pageInfo?.page) + 1,
          pageSize: pageInfo?.pageSize,
          sortBy: sortedField.field,
          sortOrder: sortedField.order,
          searchValue: searchValue,
        });
      });
    } catch (error: any) {
      setIsButtonLoading(false);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.message,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const openAddAndEditDialog = async () => {
    setIsAddAndEditDialogOpen(true);
  };

  const closeAddAndEditDialog = async () => {
    setIsButtonLoading(false);
    setIsAddAndEditDialogOpen(false);
    setWorkingHoursData(initialData);
    setIdToBeUpdated(null);
    setWorkingHoursError(initialWorkingHoursError);
    setWorkingHourToBeDeleted(null);
    setIsConfirmationDialogOpen(false);
  };

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleInputChange = (e: any, index: number, day_name: string) => {
    const { name, value } = e.target;

    setWorkingHoursData((prev: any) => {
      return [
        {
          ...prev[0],
          days: prev[0]?.days.map((day: any) => {
            if (day_name === day.day_name) {
              return {
                ...day,
                times: day.times.map((time: any, i: number) => {
                  if (i === index) {
                    return {
                      ...time,
                      [name]: Math.round(Number(value.replace(/[^\d.]/g, ""))),
                    };
                  }
                  return time;
                }),
              };
            } else {
              return day;
            }
          }),
        },
      ];
    });

    handleValidation({
      target: {
        name: "working_hour",
      },
    });
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e.target;

    setWorkingHoursData((prev: any) => {
      return [
        {
          ...prev[0],
          [name]: value,
        },
      ];
    });

    isDialogInnerComponentLoadingRef = true;

    if (value) {
      setIsDialogInnerComponentLoading(true);
      workingHoursEdit(value);
    }

    handleValidation(e);
  };

  const handleTimeChange = (
    newValue: any,
    name: string,
    index: number,
    day_name: string
  ) => {
    setWorkingHoursData((prev: any) => {
      return [
        {
          ...prev[0],
          days: prev[0].days.map((day: any) => {
            if (day_name === day.day_name) {
              return {
                ...day,
                times: day.times.map((time: any, i: number) => {
                  if (i === index) {
                    return {
                      ...time,
                      [name]: !Boolean(newValue) ? null : newValue?.toDate(),
                    };
                  }
                  return time;
                }),
              };
            } else {
              return day;
            }
          }),
        },
      ];
    });
  };

  const handleSwitchChange = async (e: any, dayName: string) => {
    const { name, checked } = e.target;

    setWorkingHoursData((prev: any) => {
      return [
        {
          ...prev[0],
          days: prev[0].days.map((day: any) => {
            if (day.day_name === dayName) {
              return {
                ...day,
                [name]: checked,
                times:
                  day?.times?.length > 0
                    ? day.times
                    : [
                        {
                          start_time: null,
                          end_time: null,
                          interval: null,
                        },
                      ],
              };
            }
            return day;
          }),
        },
      ];
    });
  };

  const handleEditClick = (dayName: string) => {
    setWorkingHoursData((prev: any) => {
      return [
        {
          ...prev[0],
          days: prev[0].days.map((day: any) => {
            if (day?.day_name === dayName) {
              return {
                ...day,
                times: [
                  ...day?.times,
                  {
                    start_time: null,
                    end_time: null,
                    interval: null,
                  },
                ],
              };
            }
            return day;
          }),
        },
      ];
    });
  };

  const handleConfirmWorkingHoursDelete = () => {
    if (isDeleteWorkingHourModal.id) {
      setIsButtonLoading(true);
      deleteDoctorsWorkingHoursById(isDeleteWorkingHourModal.id)
        .then((res: any) => {
          dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
          setWorkingHoursData((prev: any) => {
            return [
              {
                ...prev[0],
                days: prev[0].days.map((day: any) => {
                  if (day?.day_name === isDeleteWorkingHourModal?.day_name) {
                    return {
                      ...day,
                      times: day.times.filter((time: any) => {
                        return time.id !== isDeleteWorkingHourModal?.id;
                      }),
                      active:
                        day.times.filter((time: any) => {
                          return time.id !== isDeleteWorkingHourModal?.id;
                        }).length > 0
                          ? true
                          : false,
                    };
                  } else {
                    return day;
                  }
                }),
              },
            ];
          });
          setIsDeleteWorkingHourModal({
            isOpen: false,
            id: null,
            day_name: null,
          });
          setIsButtonLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsDeleteWorkingHourModal({
            isOpen: false,
            id: null,
            day_name: null,
          });
          setIsButtonLoading(false);
        });
    }
  };

  const handleDeleteClick = (
    index: number,
    day_name: string,
    id: number | string
  ) => {
    if (id) {
      setIsDeleteWorkingHourModal({ isOpen: true, id, day_name });
    } else {
      setWorkingHoursData((prev: any) => {
        return [
          {
            ...prev[0],
            days: prev[0]?.days?.map((day: any) => {
              if (day?.day_name === day_name) {
                return {
                  ...day,
                  times: day.times.filter((time: any, i: number) => {
                    return i !== index;
                  }),
                  active:
                    day.times.filter((time: any, i: number) => {
                      return i !== index;
                    }).length > 0
                      ? true
                      : false,
                };
              } else {
                return day;
              }
            }),
          },
        ];
      });
    }
  };

  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo?.pageSize,
      sortBy: sortedField.field,
      sortOrder: sortedField.order,
      searchValue: searchValue,
    };
    getWorkingHoursDetails(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortedField.order,
    sortedField.field,
    searchValue,
  ]);

  useEffect(() => {
    if (workingHoursData[0]?.doctor_id !== null) {
      handleValidation({
        target: {
          name: "working_hour",
        },
      });
    }
  }, [workingHoursData]);

  useEffect(() => {
    updateSymptomFieldData();
  }, [workingHoursError, workingHoursData]);

  const getAllDoctorsList = async () => {
    try {
      await getDoctorsList().then((result: any) => {
        setDoctorOptions(result.data);
      });
    } catch (error: any) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    getAllDoctorsList();
  }, []);

  const handleSearchOnChange = (e: any) => {
    setSearchValue(e.target.value);
    dispatch(
      setCurrentPage({ key: "appointmentMasterWorkingHours", value: 0 })
    );
  };

  return (
    <div>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="are you surely want to delete?"
        handleClick={handleConfirmDelete}
        onClose={closeConfirmationDialog}
        loading={isButtonLoading}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CustomFilter
          isSearchEnabled={true}
          searchValue={searchValue}
          searchOnChange={handleSearchOnChange}
          searchName={"search"}
          clearSearch={() => setSearchValue("")}
          {...(can("workhour_add") && {
            editButtonTitle: commonText.EDIT_BUTTON_TITLE,
            onUpdateButtonClick: openAddAndEditDialog,
            editStartIcon: (
              <Box
                sx={{
                  "& path": {
                    fill: "#fff",
                  },
                }}
              >
                <PlusIcon width={"12px"} height={"12px"} />
              </Box>
            ),
          })}
        />
        <DataTable
          title={appointmentsMasterConst.WORKING_HOURS}
          columns={columns}
          getRowId={(row: GridRowData) => `${String(row.doctor_id)}`}
          rows={workingHoursList}
          pageCount={pageCount}
          currentPage={"appointmentMasterWorkingHours"}
          loading={isDataTableLoading}
        />
      </Box>
      <DialogWrapper
        onClose={closeAddAndEditDialog}
        open={isAddAndEditDialogOpen}
        handleClick={
          !Boolean(idToBeUpdated) ? workingHoursCreate : workingHoursUpdate
        }
        title={
          !Boolean(idToBeUpdated) ? "Add Working Hours" : "Edit Working Hours"
        }
        loading={isButtonLoading}
      >
        {isDialogLoading ? (
          <Grid
            sx={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              height: "50vh",
              background: "transparent",
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Select
              value={fieldData.doctor_id.value}
              inputRef={fieldData.doctor_id.ref}
              onChange={handleSelectChange}
              name="doctor_id"
              placeholder={appointmentsMasterConst.SELECT_DOCTOR}
              options={doctorOptions}
              label={fieldData.doctor_id.label}
              helperText={fieldData.doctor_id.helperText}
              selectedType="number"
              sx={{
                width: {
                  xs: "250px",
                  sm: "427px",
                  md: "427px",
                },
              }}
            />

            {isDialogInnerComponentLoading ? (
              <Grid
                sx={{
                  width: "100%",
                  margin: "auto",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                  height: "60vh",
                  background: "transparent",
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                sx={{
                  minWidth: { xs: "950px", md: "auto" },
                  mt: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {workingHoursData[0]?.days?.map((day: any) => {
                  return (
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        m: "10px 0px",
                        width: "100%",
                        minWidth: { xs: "950px", md: "auto" },
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mb: "10px",
                          width: "30%",
                          minWidth: { xs: "250px", md: "auto" },
                        }}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "60%",
                            minWidth: "150px",
                          }}
                        >
                          <Typography>{day.day_name}</Typography>
                          <Switch
                            name="active"
                            checked={day.active}
                            onChange={(e: any) =>
                              handleSwitchChange(e, day.day_name)
                            }
                          />
                        </Grid>
                        <Typography
                          sx={{
                            width: "40%",
                            minWidth: { xs: "100px", md: "auto" },
                          }}
                        >
                          {day.active ? "Open" : "Closed"}
                        </Typography>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          width: "70%",
                          minWidth: { xs: "700px", md: "auto" },
                          height: "auto",
                          minHeight: "20px",
                        }}
                      >
                        {day?.times?.map((time: any, index: number) => {
                          return day?.active === true ? (
                            <Box
                              key={index}
                              sx={{
                                width: "80%",
                                minWidth: { xs: "560px", md: "auto" },
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                "& .MuiFormControl-root": {
                                  position: "static",
                                },
                              }}
                            >
                              <TimePicker
                                value={time.start_time}
                                onChange={(newValue: any) => {
                                  handleTimeChange(
                                    newValue,
                                    "start_time",
                                    index,
                                    day.day_name
                                  );

                                  if (
                                    errors[day.day_name]?.times[index]
                                      ?.start_time &&
                                    newValue
                                  ) {
                                    setErrors((prev: any) => {
                                      const newError = { ...prev };

                                      if (
                                        newError[day.day_name]?.times[index]
                                          .start_time
                                      ) {
                                        newError[day.day_name].times[
                                          index
                                        ].start_time = "";
                                      }

                                      return newError;
                                    });
                                  }
                                }}
                                name="start_time"
                                width="85px"
                                format="hh:mm A"
                                hideSeconds
                                style={{
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  boxShadow: "none",
                                  outline: "none",
                                  borderRadius: "0",
                                  borderBottom: errors[day.day_name]?.times[
                                    index
                                  ]?.start_time
                                    ? "1px solid #d32f2f"
                                    : "1px solid #EFEFEF",
                                }}
                                borderBottom={true}
                              />
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                }}
                              >
                                to
                              </Typography>
                              <TimePicker
                                value={time.end_time}
                                name="end_time"
                                format="hh:mm A"
                                onChange={(newValue: any) => {
                                  handleTimeChange(
                                    newValue,
                                    "end_time",
                                    index,
                                    day.day_name
                                  );
                                  if (
                                    errors[day.day_name]?.times[index]
                                      ?.end_time &&
                                    newValue
                                  ) {
                                    setErrors((prev: any) => {
                                      const newError = { ...prev };

                                      if (
                                        newError[day.day_name]?.times[index]
                                          .end_time
                                      ) {
                                        newError[day.day_name].times[
                                          index
                                        ].end_time = "";
                                      }

                                      return newError;
                                    });
                                  }
                                }}
                                width="85px"
                                hideSeconds
                                style={{
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  boxShadow: "none",
                                  outline: "none",
                                  borderRadius: "0",
                                  borderBottom: errors[day.day_name]?.times[
                                    index
                                  ]?.end_time
                                    ? "1px solid #d32f2f"
                                    : "1px solid #EFEFEF",
                                }}
                                borderBottom={true}
                              />
                              <TextField
                                sx={{
                                  width: "85px",
                                  border: "none",
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: 0,
                                    borderRadius: "0px",
                                    borderBottom: 1,
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    boxShadow: "none",
                                    pl: 1,
                                  },
                                  "& .Mui-error .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderLeftWidth: 0,
                                    },
                                  "& .MuiOutlinedInput-root.Mui-focused": {
                                    borderColor: "backgroundPrimary.main",
                                  },
                                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "backgroundPrimary.main",
                                      borderBottom: 1,
                                      borderBottomColor: "greyScale.lighter",
                                    },
                                }}
                                placeholder="Interval"
                                value={time.interval}
                                name="interval"
                                onChange={(e: any) => {
                                  handleInputChange(e, index, day.day_name);
                                  if (
                                    errors[day.day_name]?.times[index]
                                      ?.interval &&
                                    e?.target?.value
                                  ) {
                                    setErrors((prev: any) => {
                                      const newError = { ...prev };

                                      if (
                                        newError[day.day_name]?.times[index]
                                          .interval
                                      ) {
                                        newError[day.day_name].times[
                                          index
                                        ].interval = "";
                                      }

                                      return newError;
                                    });
                                  }
                                }}
                                error={
                                  !!errors[day.day_name]?.times[index]?.interval
                                }
                              />
                              <Grid
                                sx={{
                                  width: "10%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  onClick={() => handleEditClick(day.day_name)}
                                  sx={{ height: "40px" }}
                                >
                                  <PlusIcon />
                                </Button>
                                <Button
                                  onClick={() =>
                                    handleDeleteClick(
                                      index,
                                      day.day_name,
                                      time.id
                                    )
                                  }
                                  sx={{ height: "40px" }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Grid>
                            </Box>
                          ) : null;
                        })}
                      </Grid>
                    </Grid>
                  );
                })}
                {fieldData.working_hour.isError ? (
                  <Typography
                    sx={{ width: "100%", textAlign: "left", color: "red" }}
                  >
                    {fieldData.working_hour.helperText}
                  </Typography>
                ) : null}
              </Grid>
            )}
          </>
        )}
        <ConfirmationDialog
          open={isDeleteWorkingHourModal.isOpen}
          title="are you surely want to delete working hour?"
          handleClick={handleConfirmWorkingHoursDelete}
          onClose={() =>
            setIsDeleteWorkingHourModal({
              isOpen: false,
              id: null,
              day_name: null,
            })
          }
          loading={isButtonLoading}
        />
      </DialogWrapper>
    </div>
  );
};

export default WorkingHours;
