import React from "react";
import { Grid } from "@mui/material";
import { SettingsIcon } from "../../../../assets/icons";
import { Select, TextField } from "../../../../components/basic";

import { stockAdjustmentInfoConst } from "../../../../constants/displayText";

type InfoProps = {
  info: {
    department: string | number;
    bill_no: number;
  };
  setInfo: React.Dispatch<any>;
  departmentList: {
    id: number | string | null;
    name: number | string | null;
  }[];
  setSequenceDialogBox?: any;
  setIsManual?: any;
  isManualAdded?: any;
  isManual?: any;
  handleInvoiceNoChange?: any;
  customSequence?: boolean;
  isSelectLoading?: boolean;
};

const Info = React.memo(
  ({
    info,
    setInfo,
    departmentList,
    setSequenceDialogBox,
    handleInvoiceNoChange,
    customSequence,
    isSelectLoading,
  }: InfoProps) => {
    const styles = {
      selectGrid: {
        width: "100%",
        height: "44px",
        boxShadow: "none",
        borderRadius: "5px",
        borderColor: "var(--primary-border-color)",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
      textFieldStyle: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
          height: "44px",
          borderRadius: "5px",
          paddingLeft: "8px",
          boxShadow: "none",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
    };
    const { department, billNo } = stockAdjustmentInfoConst;

    const handleInputChange = (e: any) => {
      const { name, value } = e.target;

      setInfo((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    };

    return (
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          m: "0px 0px 30px 0px",
          width: "90vw",
          minHeight: "100px",
          height: "auto",
          gap: 1,
        }}
      >
        <Grid item xs={12} sm={5.9} md={3} lg={1.9} xl={1.9}>
          <Select
            value={info.department}
            onChange={handleInputChange}
            placeholder="Select Department"
            options={departmentList}
            label={department}
            name="department"
            width=""
            formControlStyle={{ width: "100%" }}
            sx={{
              ...styles.selectGrid,
            }}
            isLoading={isSelectLoading}
          />
        </Grid>
        {customSequence ? (
          <Grid item xs={12} sm={5.9} md={3} lg={1.9} xl={1.9}>
            <TextField
              value={info.bill_no}
              label={billNo}
              onChange={handleInputChange}
              endPlaceholderIcon={
                <SettingsIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSequenceDialogBox(true);
                  }}
                />
              }
              formControlStyle={{ width: "100%" }}
              onBlur={handleInvoiceNoChange}
              placeholder=""
              name="bill_no"
              sx={{
                ...styles.textFieldStyle,
              }}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }
);

export default Info;
