import { Box } from "@mui/material";
import { loginPageConst } from "../../constants/displayText";
import { CheckBox, TextField, SaveButton, Label } from "../../components/basic";
import "./Auth.css";
import React, { useState, useRef } from "react";
import { login, loginCheck } from "../../services/authService";
import { getUserDetails } from "../../services/userService";
import { RouteUrls } from "../../constants/routes";
import {
  setAuthUser,
  setIsLoggedIn,
  // setOrganizationList,
} from "../../redux/slices/auth";
import { useDispatch } from "react-redux";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../redux/slices/snackbar";
import { LoginImg } from "../../assets/images";
import { HidePasswordIcon, ShowPasswordIcon } from "../../assets/icons";
// import { tenantLogin } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { crypt } from "../../utils/GeneralUtils";
import {
  getAllCommons,
  getCommonAppConfigurations,
} from "../../services/gynecologyService";
import {
  setAppConfiguration,
  setCommonVariable,
} from "../../redux/slices/appconfiguration";

export default function SignIn({ navigateHook, locationHook }: any) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [passwordCheck, setPasswordCheck] = useState<string>("");
  const [phoneNumberCheck, setPhoneNumberCheck] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [passwordType, setPasswordType] = useState(false);
  const inputAutoFocus: any = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginStyles = {
    loginMainGrid: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    signInMainBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    signInTextStyle: {
      color: "primary.main",
      fontSize: "30px",
      fontWeight: "700",
      fontFamily: "Inter !important",
      pb: 1,
    },
    signInStayUpdated: {
      color: "textPrimary.main",
      fontSize: "10px",
      fontWeight: "700",
      fontFamily: "Inter !important",
      pb: 4,
    },
    emailInput: {
      "& p": {
        color: "red",
      },
      "@media screen and (min-width: 768px) and (max-width: 1000px)": {
        width: "327px",
      },
      width: "427px",
    },
    passwordInput: {
      "& p": {
        color: "red",
      },
      "@media screen and (min-width: 768px) and (max-width: 1000px)": {
        width: "327px",
      },
      width: "427px",
    },
    submitBtn: {
      mt: 3,
      mb: 2,
      borderRadius: 2,
      py: 1.5,
      width: "100%",
    },
    forgotPasswordGrid: {
      textAlign: "center",
    },
    inputBoxMainBox: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  };
  const handleInputChange = (
    type: string,
    e: { target: { value: string } }
  ): string | undefined => {
    switch (type) {
      case "phonenumber": {
        setPhoneNumber(e.target.value);
        return;
      }
      case "password": {
        setPassword(e.target.value);
        return;
      }
      default:
        return "";
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (
      phoneNumberError === "" &&
      passwordCheck === "" &&
      phoneNumberCheck === "" &&
      phoneNumberError === ""
    ) {
      setLoader(!loader);

      void (async () => {
        await login(phoneNumber, password)
          .then(async () => {
            setLoader(false);
            dispatch(setIsLoggedIn(true));
            getUserDetails().then((res: any) => {
              const user = res?.data?.user;
              if (user) {
                dispatch(setAuthUser(user));

                const encryptedText = crypt(
                  "user_detail_salt",
                  JSON.stringify(user)
                );
                localStorage.setItem("userDetails", encryptedText);
                getCommonAppConfigurations()
                  .then((res: any) => {
                    const data = res.data;
                    if (data) {
                      dispatch(setAppConfiguration(data));
                      const encryptedText = crypt(
                        "app_configuration_salt",
                        JSON.stringify(data)
                      );
                      localStorage.setItem("appConfiguration", encryptedText);
                    }
                  })
                  .catch((error: any) => {
                    console.error("An error occurred:", error);
                  });
                getAllCommons()
                  .then((res: any) => {
                    const data = res.data;
                    if (data) {
                      dispatch(setCommonVariable(data));
                      const encryptedText = crypt(
                        "common_variable_salt",
                        JSON.stringify(data)
                      );
                      localStorage.setItem("commonVariables", encryptedText);
                    }
                  })
                  .catch((error: any) => {
                    console.error("An error occurred:", error);
                  });
                dispatch(
                  setSnackBarSuccess({
                    snackBarMessage:
                      loginPageConst.SUCCESSFULLY_LOGIN as string,
                  })
                );
              }
            });
          })
          .catch((err: any) => {
            setLoader(false);
            console.log(err);
            if (
              err?.response?.data?.errors ===
              loginPageConst?.INVALID_USER_PASSWORD
            ) {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: loginPageConst?.INVALID_USER_PASSWORD,
                })
              );
            } else if (
              err?.response?.data?.errors?.mobile?.length > 0 &&
              err?.response?.data?.errors?.mobile[0] ===
                loginPageConst?.MOBILE_REQUIRED
            ) {
              setPhoneNumberError(loginPageConst?.MOBILE_REQUIRED);
              setPhoneNumberCheck(err?.response?.data.errors);
            } else if (
              err?.response?.data?.errors?.password?.length > 0 &&
              err?.response?.data?.errors?.password[0] ===
                loginPageConst?.PASSWORD_REQUIRED
            ) {
              setPasswordError(loginPageConst?.PASSWORD_REQUIRED);
              setPasswordCheck(err?.response?.data?.errors);
            } else {
              console.log(err?.response?.data?.errors);
            }
            if (err?.response?.data?.errors !== undefined) {
            }
            console.log(err?.response?.data?.errors);
          });
      })();
    }
  };

  const handlePhoneValidation = (e: { target: { value: string } }): void => {
    const hasAlphabets = /[a-zA-Z]/.test(e.target.value);
    if (e?.target?.value?.length !== 10) {
      setPhoneNumberError(loginPageConst.PHONE_NUMBER_REQUIRED);
    } else {
      if (hasAlphabets) {
        setPhoneNumberError(loginPageConst.DONT_USE_ALPHABETS);
      } else {
        setPhoneNumberError("");
      }
    }
  };

  const PhoneValidation = (): void => {
    if (phoneNumber !== "") {
      if (phoneNumberCheck) {
        if (phoneNumberCheck === loginPageConst.MOBILE_REQUIRED) {
          setPhoneNumberError(loginPageConst.PHONE_NUMBER_REQUIRED);
        } else {
          setPhoneNumberError(loginPageConst.PHONE_INVALID);
        }
      } else {
        setPhoneNumberError("");
      }
    } else {
      setPhoneNumberError(loginPageConst.PHONE_MUST_ENTERED);
    }
  };
  const handlePasswordValidation = (e: { target: { value: string } }): void => {
    if (e?.target?.value === "") {
      setPasswordError(loginPageConst.PASSWORD_MUST_ENTERED);
    } else {
      setPasswordError("");
    }
  };
  const PasswordValidation = (): void => {
    if (password !== "") {
      if (passwordCheck) {
        if (passwordCheck === loginPageConst.SETUP_PASSWORD) {
          setPasswordError(loginPageConst.SETUP_PASSWORD);
        } else {
          setPasswordError(loginPageConst.PASSWORD_INVALID);
        }
      } else {
        setPasswordError("");
      }
    } else {
      setPasswordError(loginPageConst.PASSWORD_MUST_ENTERED);
    }
  };

  // const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
  //   console.log(e, "enter");
  // };

  return (
    <>
      <div
        style={{ display: "flex", height: "100vh" }}
        ref={inputAutoFocus}
        // onKeyDown={handleKeyDown}
      >
        <div>
          <img src={LoginImg} alt="login-pic" className="login_img" />
        </div>
        <div className="login_modal_wrapper">
          <Box className="signin_main_box">
            <Box sx={loginStyles.signInTextStyle}>{loginPageConst.SIGN_IN}</Box>
            <Box sx={loginStyles.signInStayUpdated}>
              {loginPageConst.STAY_UPDATED_HEALTH}
            </Box>
            <Box
              sx={loginStyles.inputBoxMainBox}
              component="form"
              onSubmit={handleSubmit}
              noValidate
            >
              <Label label={loginPageConst.PHONE_NUMBER} />
              <TextField
                onChange={(e: any) => {
                  handleInputChange("phonenumber", e);
                  handlePhoneValidation(e);
                }}
                helperText={phoneNumberError}
                error={phoneNumberError === "" ? false : true}
                sx={loginStyles.emailInput}
                name={"phonenumber"}
                value={phoneNumber}
              />
              <Label label={loginPageConst.PASSWORD} />
              <TextField
                type={!passwordType ? "password" : "text"}
                name={"password"}
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange("password", e);
                  handlePasswordValidation(e);
                }}
                endPlaceholderIcon={
                  !passwordType ? (
                    <ShowPasswordIcon
                      onClick={() => setPasswordType(!passwordType)}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <HidePasswordIcon
                      onClick={() => setPasswordType(!passwordType)}
                      style={{ cursor: "pointer" }}
                    />
                  )
                }
                helperText={passwordError}
                error={passwordError === "" ? false : true}
                sx={loginStyles.passwordInput}
              />
              <CheckBox
                rightAlign
                rightAlignText={loginPageConst.REMEMBER_ME}
              />
              <SaveButton
                sx={loginStyles.submitBtn}
                buttonText={loginPageConst.LOGIN}
                loading={loader}
                disabled={password === "" || phoneNumber === ""}
                handleClick={() => {
                  setPasswordCheck("");
                  PasswordValidation();
                  setPhoneNumberCheck("");
                  PhoneValidation();
                }}
                buttonSize="lg"
              />
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
}
