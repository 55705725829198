import {
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  Box,
  LinearProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationIcon from "../../basic/notificationIcon";
import { Avatar } from "../../basic";
import { setAppBarTitle, setMobileOpen } from "../../../redux/slices/layout";
import { RootState, AppDispatch } from "../../../redux/store";
import { useEffect } from "react";
import { getAppBarTitleFromPath } from "../../../utils/GeneralUtils";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const Header = ({ locationHook }: any) => {
  const mobileOpen = useSelector((state: RootState) => state.layout.mobileOpen);
  const appBarTitle = useSelector(
    (state: RootState) => state.layout.appBarTitle
  );
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: any) => state.loader.isLoading);

  const handleDrawerToggle = () => {
    dispatch(setMobileOpen(!mobileOpen));
  };
  useEffect(() => {
    const optionalParam = location.pathname.split("/").pop();

    const pageTitle = getAppBarTitleFromPath(location.pathname, optionalParam);

    dispatch(setAppBarTitle(pageTitle));
  }, [dispatch, location.pathname]);

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - 70px)` },
        backgroundColor: "backgroundPrimary.main",
        color: "textPrimary.main",
        boxShadow: "none",
        borderBottom: "1px solid #D9D9D9",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 5px !important",
        }}
      >
        <Box sx={{ p: 1, display: "flex", alignItems: "center" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 0, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h1" noWrap>
            {appBarTitle}
          </Typography>
        </Box>

        <Box sx={{ p: 1, display: "flex" }}>
          <NotificationIcon />
          <Avatar />
        </Box>
      </Toolbar>
      {isLoading ? (
        <LinearProgress
          sx={{
            "&.MuiLinearProgress-root": {
              backgroundColor: "white",
            },
            "& .MuiLinearProgress-bar": {
              width: "60vw",
              background: "linear-gradient(to right, #17CF9D , #207DFF)",
            },
            "& .css-1r8wrcl-MuiLinearProgress-bar2": {
              visibility: "hidden",
            },
          }}
        />
      ) : (
        ""
      )}
    </AppBar>
  );
};

export default Header;
