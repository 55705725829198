import { Outlet, Navigate, useLocation } from "react-router-dom";
import { RouteUrls } from "../constants/routes";

type PrivateRoutesTypes = {
  isLoggedIn?: boolean;
};

const PrivateRoutes = ({ isLoggedIn }: PrivateRoutesTypes) => {
  const { loginUrl } = RouteUrls;
  const location = useLocation();

  if (!isLoggedIn && location?.pathname !== loginUrl) {
    localStorage.setItem(
      "lastVisitedRoute",
      JSON.stringify(location?.pathname)
    );
  }

  return isLoggedIn ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
