import React, { useCallback, useEffect, useRef, useState } from "react";
import { Grid, Typography, Box, IconButton, debounce } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ConfirmationDialog, DataTable } from "../../../../components/shared";
import { Sort } from "../../../../components/basic";
import {
  DeleteIcon,
  EditIcon,
  ViewIcon,
  PaymentRupeeIcon,
  PlusIcon,
} from "../../../../assets/icons";
import { TextField, DatePicker } from "../../../../components/basic";
import { RouteUrls } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import CustomFilter from "../../../../components/shared/customFilter";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { commonDateFormatter } from "../../../../utils/DateTimeFormatUtils";
import { setCurrentPage } from "../../../../redux/slices/pagination";
import {
  deleteLabPurchaseBillById,
  getAllLabPurchaseBill,
  getAllLabPurchaseSuppliers,
} from "../../../../services/laboratoryService";
import moment from "moment";
import { InfiniteScroller } from "../../../../components/basic";
import { errorMessage } from "../../../../constants/displayText";
import PermissionUtils from "../../../../utils/PermissionUtils";

type GridRowData = Record<string, unknown>;

const style = {
  selectStyle: {
    width: {
      xs: "90vw",
      sm: "45vw",
      md: "30vw",
      lg: "13vw",
      xl: "13vw",
    },
    height: "45px",
    boxShadow: "none",
    borderRadius: "5px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primary-border-color)",
    },
    "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primary-border-color)",
    },
  },
  DatePickerStyle: {
    "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container >div >div .rmdp-input":
      {
        boxSizing: "border-box",
        width: "100%",
      },
  },
  textFieldStyle: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: "45px",
      borderRadius: "5px",
      paddingLeft: "8px",
      boxShadow: "none",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primary-border-color)",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primary-border-color)",
    },
  },
};
const LabPurchaseBillList = () => {
  const {
    laboratoryUrl,
    createUrl,
    editUrl,
    viewUrl,
    purchaseReturnUrl,
    labPurchaseUrl,
    labPurchasePayments,
  } = RouteUrls;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { can } = PermissionUtils();

  const [suppliersList, setSuppliersList] = useState<any>([]);

  const [purchaseBillList, setPurchaseBillList] = useState<[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [searchValue, setSearchValue] = useState<any>("");
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState({
    isOpen: false,
    id: null,
  });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState({
    isOpen: false,
    errors: [],
  });
  const pageInfo = useSelector(
    (state: RootState) => state?.pagination?.laboratoryPurchaseBill
  );
  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {row?.is_return === 1
          ? can("lab_purchasebill_edit")
          : can("lab_purchase_return_edit") && (
              <IconButton
                aria-label=""
                onClick={() => {
                  if (row.is_return) {
                    navigate(`${laboratoryUrl}${purchaseReturnUrl}/${row.id}`);
                  } else {
                    navigate(
                      `${laboratoryUrl}${labPurchaseUrl}${editUrl}/${row.id}`
                    );
                  }
                }}
              >
                <EditIcon />
              </IconButton>
            )}
        {row?.is_return === 1
          ? can("lab_purchasebill_view")
          : can("lab_purchase_return_view") && (
              <IconButton
                aria-label=""
                onClick={() => {
                  navigate(
                    `${laboratoryUrl}${labPurchaseUrl}${viewUrl}/${row.id}`
                  );
                }}
              >
                <ViewIcon />
              </IconButton>
            )}
        {!row.is_cancel && can("lab_purchasebill_delete") && (
          <IconButton
            aria-label=""
            onClick={() => setIsOpenDeleteModal({ isOpen: true, id: row.id })}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {can("lab_purchasebill_pay_edit") && (
          <IconButton
            aria-label=""
            onClick={() =>
              navigate(
                `${laboratoryUrl}${labPurchasePayments}${editUrl}/${row.id}`
              )
            }
          >
            <PaymentRupeeIcon />
          </IconButton>
        )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "bill_ref_no",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("bill_ref_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Ref-No
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "bill_ref_no" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "bill_ref_no" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.bill_ref_no}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Suppliers
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "invoice_no",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("invoice_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Invoice No
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "invoice_no" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "invoice_no" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.invoice_no}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "created_at",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("created_at")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "created_at" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "created_at" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.created_at
            ? moment(row.created_at).format("YYYY-MM-DD hh:mm:ss A")
            : ""}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "bill_amt",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("bill_amt")}
        >
          <Typography variant="h5" fontSize={14}>
            Bill Amount
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "bill_amt" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "bill_amt" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.bill_amt}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "payment",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("payment")}
        >
          <Typography variant="h5" fontSize={14}>
            Paid Amount
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "payment" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "payment" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.payment}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "balance",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("balance")}
        >
          <Typography variant="h5" fontSize={14}>
            Balance
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "balance" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "balance" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.balance}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    ...(can("lab_purchasebill_edit") ||
    can("lab_purchasebill_view") ||
    can("lab_purchasebill_delete") ||
    can("lab_purchasebill_pay_edit") ||
    can("lab_purchase_return_view") ||
    can("lab_purchase_return_edit")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const [filterData, setFilterData] = useState<{
    supplier_id: string;
    product_name: string;
    bill_no: string;
    from_date: any;
    to_date: any;
  }>({
    supplier_id: "",
    product_name: "",
    bill_no: "",
    from_date: "",
    to_date: "",
  });

  const clearFilters = () => {
    if (
      filterData.supplier_id ||
      filterData.product_name ||
      filterData.bill_no ||
      filterData.from_date ||
      filterData.to_date
    ) {
      setFilterData({
        ...filterData,
        supplier_id: "",
        product_name: "",
        bill_no: "",
        from_date: "",
        to_date: "",
      });
      setAppliedFilterCount(0);
    }
  };

  const handleInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;

      setFilterData((prevState: any) => {
        const newFilter = { ...prevState, [name]: value };
        if (name !== "search") {
          const appliedFilterCount =
            Object.values(newFilter).filter(Boolean).length;
          setAppliedFilterCount(appliedFilterCount);
        }
        return newFilter;
      });
      dispatch(setCurrentPage({ key: "laboratoryPurchaseBill", value: 0 }));
    }
  };

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      setFilterData((prevState: any) => {
        if (name !== "search") {
          const newFilter = {
            ...prevState,
            [name]: newValue.validatedValue[0],
          };
          const appliedFilterCount =
            Object.values(newFilter).filter(Boolean).length;
          setAppliedFilterCount(appliedFilterCount);
          return newFilter;
        }
      });
      dispatch(setCurrentPage({ key: "laboratoryPurchaseBill", value: 0 }));
    }
  };

  const supplierPagination = useRef(1);
  const searchSupplierPagination = useRef({ search: "", page: 1 });

  const debouncedGetAllSupplierList = useCallback(
    debounce(async (currentValue, prevOptions, callback) => {
      try {
        return await getAllLabPurchaseSuppliers({
          search: currentValue,
          page:
            searchSupplierPagination.current?.search === currentValue
              ? searchSupplierPagination.current?.page
              : 1,
          limit: 10,
        }).then((result: any) => {
          let data = result?.data?.result;
          const formattedSuppliersList = data.map((uniqueData: any) => {
            return { value: uniqueData.id, label: uniqueData.name };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions?.some(
                (prevOption: any) => prevOption?.value === option?.value
              )
          );
          setSuppliersList(formattedSuppliersList);
          searchSupplierPagination.current.page =
            searchSupplierPagination.current?.search === currentValue
              ? searchSupplierPagination.current?.page + 1
              : 1;
          searchSupplierPagination.current.search = currentValue;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
      } catch (error) {
        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
      }
    }, 300),
    []
  );
  const getAllSuppliersList = async (currentValue?: any, prevOptions?: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllSupplierList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        return await getAllLabPurchaseSuppliers({
          search: currentValue,
          page: supplierPagination.current,
          limit: 10,
        }).then((result: any) => {
          let data = result?.data?.result;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return { value: uniqueData.id, label: uniqueData.name };
          });
          if (formattedSuppliersList.length > 0) {
            setSuppliersList((prevState: any) => [
              ...prevState,
              ...formattedSuppliersList,
            ]);
          }
          const uniqueOptions = formattedSuppliersList?.filter(
            (option: any) =>
              !prevOptions?.some(
                (prevOption: any) => prevOption?.value === option?.value
              )
          );
          const currentValue = supplierPagination.current;
          supplierPagination.current = currentValue + 1;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const appointmentFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 1.8, xl: 1.8 },
      children: (
        <InfiniteScroller
          label="Suppliers"
          loadOptions={getAllSuppliersList}
          options={suppliersList}
          handleOnChange={handleInputChange}
          name={"supplier_id"}
          value={filterData?.supplier_id}
          width="100%"
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 1.8, xl: 1.8 },
      children: (
        <TextField
          value={filterData.product_name}
          label="Product Name"
          name="product_name"
          placeholder="Enter Product Name"
          onChange={handleInputChange}
          sx={{
            ...style.textFieldStyle,
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 1.8, xl: 1.8 },
      children: (
        <TextField
          value={filterData.bill_no}
          label="Bill No"
          name="bill_no"
          placeholder="Enter Bill No"
          onChange={handleInputChange}
          sx={{
            ...style.textFieldStyle,
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 1.8, xl: 1.8 },
      children: (
        <Grid
          sx={{
            ...style.DatePickerStyle,
          }}
        >
          <DatePicker
            label="From Date"
            name="from_date"
            placeholder="Select Date"
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "from_date")
            }
            format="DD/MM/YYYY"
            formControlStyle={{ width: "100%" }}
            value={filterData.from_date}
            style={{
              boxShadow: "none",
              height: "45px",
              borderRadius: "5px",
              width: "100%",
            }}
            borderColor="var(--primary-border-color)"
          />
        </Grid>
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 1.8, xl: 1.8 },
      children: (
        <Grid
          sx={{
            ...style.DatePickerStyle,
          }}
        >
          <DatePicker
            label="To Date"
            name="to_date"
            placeholder="Select Date"
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "to_date")
            }
            formControlStyle={{ width: "100%" }}
            format="DD/MM/YYYY"
            value={filterData.to_date}
            style={{
              boxShadow: "none",
              height: "45px",
              borderRadius: "5px",
              width: "100%",
            }}
            borderColor="var(--primary-border-color)"
          />{" "}
        </Grid>
      ),
    },
  ];

  const debouncedGetAllPurchaseBill = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllLabPurchaseBill(data)
        .then((res: any) => {
          setPurchaseBillList(res.data.result as []);
          setPageCount(res.data.total as any);
          setLoading(false);
        })
        .catch((err) => {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage.ERROR_FETCHING_PURCHASE_BILL}`,
            })
          );
          setLoading(false);
          console.log("error getAppointmentList", err);
        });
    }, 300),
    []
  );

  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      limit: pageInfo.pageSize,
      column: sortedField.field,
      order: sortedField.order,
      from_date: filterData.from_date
        ? commonDateFormatter(filterData.from_date, "YYYY-MM-DD")
        : null,
      to_date: filterData.to_date
        ? commonDateFormatter(filterData.to_date, "YYYY-MM-DD")
        : null,
      bill_no: filterData.bill_no,
      product_name: filterData.product_name,
      supplier_id: filterData.supplier_id,
      search: searchValue,
    };
    debouncedGetAllPurchaseBill(data);
  }, [pageInfo, sortedField, filterData, searchValue]);

  const handleConfirmDelete = () => {
    if (isOpenDeleteModal.id) {
      setIsDeleteLoading(true);
      deleteLabPurchaseBillById(isOpenDeleteModal.id)
        .then((res: any) => {
          setIsDeleteLoading(false);
          const data = {
            page: Number(pageInfo.page) + 1,
            limit: pageInfo.pageSize,
            column: sortedField.field,
            order: sortedField.order,
            from_date: filterData.from_date
              ? commonDateFormatter(filterData.from_date, "YYYY-MM-DD")
              : null,
            to_date: filterData.to_date
              ? commonDateFormatter(filterData.to_date, "YYYY-MM-DD")
              : null,
            bill_no: filterData.bill_no,
            product_name: filterData.product_name,
            supplier_id: filterData.supplier_id,
            search: searchValue,
          };
          debouncedGetAllPurchaseBill(data);
          setIsOpenDeleteModal({ isOpen: false, id: null });
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        })
        .catch((err) => {
          console.log("err", err?.response?.data?.errors?.message);
          setIsDeleteLoading(false);
          setIsOpenDeleteModal({ isOpen: false, id: null });
          setIsOpenErrorModal({
            isOpen: true,
            errors: err?.response?.data?.errors?.message,
          });
        });
    }
  };

  return (
    <Box>
      <CustomFilter
        data={appointmentFilter}
        isFilterEnabled={true}
        isSearchEnabled={true}
        clearFilter={clearFilters}
        searchOnChange={(e: any) => {
          dispatch(setCurrentPage({ key: "laboratoryPurchaseBill", value: 0 }));
          setSearchValue(e.target.value);
        }}
        searchName={"search"}
        searchValue={searchValue}
        appliedFilterCount={appliedFilterCount}
        clearSearch={() => setSearchValue("")}
        {...(can("lab_purchasebill_add") && {
          editButtonTitle: "Create Bill",
          onUpdateButtonClick: () =>
            navigate(`${laboratoryUrl}${labPurchaseUrl}${createUrl}`),
          editStartIcon: (
            <Box
              sx={{
                "& path": {
                  fill: "#fff",
                },
              }}
            >
              <PlusIcon width={"12px"} height={"12px"} />
            </Box>
          ),
        })}
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={purchaseBillList}
        pageCount={pageCount}
        currentPage={"laboratoryPurchaseBill"}
      />
      <ConfirmationDialog
        open={isOpenDeleteModal.isOpen}
        title="are you surely want to delete"
        handleClick={handleConfirmDelete}
        onClose={() => setIsOpenDeleteModal({ isOpen: false, id: null })}
        loading={isDeleteLoading}
      />
      <ConfirmationDialog
        open={isOpenErrorModal.isOpen}
        title={
          <ul>
            {isOpenErrorModal?.errors?.map((ele: any) => (
              <li>{ele?.table}</li>
            ))}
          </ul>
        }
        handleClick={() => setIsOpenErrorModal({ isOpen: false, errors: [] })}
        onClose={() => setIsOpenErrorModal({ isOpen: false, errors: [] })}
        type={"info"}
        confirmText="Okay"
      />
    </Box>
  );
};

export default LabPurchaseBillList;
