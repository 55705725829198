import { Grid } from "@mui/material";
import { SettingsIcon } from "../../../../assets/icons";
import {
  TextField,
  AutoComplete,
  DatePickerWithTime,
  InfiniteScroller,
  Select,
} from "../../../../components/basic";
import { salesBillInfoConst } from "../../../../constants/displayText";
import { useCallback } from "react";

type InfoProps = {
  setInfoData: any;
  fieldData: any;
  handleValidation: (e: any) => void;
  getAllPatients?: any;
  patientsList?: any;
  departmentOptions?: any;
  setSequenceDialogBox: React.Dispatch<React.SetStateAction<boolean>>;
  isManualAdded?: any;
  isManual?: any;
  handleInvoiceNoChange?: () => void;
  billTypes?: any;
  isEdit?: boolean;
  setIsManual: React.Dispatch<React.SetStateAction<any>>;
  customSequence?: boolean;
  billNumberOptions?: any;
  handleRemoveInfoProductData?: any;
};

const Info = ({
  handleInvoiceNoChange,
  setInfoData,
  fieldData,
  handleValidation,
  getAllPatients,
  patientsList,
  departmentOptions,
  setSequenceDialogBox,
  setIsManual,
  isManualAdded,
  isManual,
  billTypes,
  billNumberOptions,
  isEdit,
  handleRemoveInfoProductData,
}: InfoProps) => {
  const { mobile, patientName } = salesBillInfoConst;

  const styles = {
    selectGrid: {
      width: "100%",
      height: "44px",
      boxShadow: "none",
      borderRadius: "5px",
      borderColor: "var(--primary-border-color)",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
    textFieldStyle: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "44px",
        borderRadius: "5px",
        paddingLeft: "8px",
        boxShadow: "none",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
    autoCompleteStyle: {
      width: "100%",
      height: "44px",
      boxSizing: "border-box",
      margin: 0,
      "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
        height: "45px",
        p: 0,
        boxShadow: "none",
        borderRadius: "5px",
      },
    },
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e?.target;
    setInfoData((prev: any) => {
      const newState = { ...prev };
      newState[name] = value;
      if (name === "patient_id") {
        const { patient_details } = e?.target;
        newState.mobile = patient_details.patient_mobile;
        newState.patient_name = patient_details.name;
      }
      return newState;
    });
    if (name === "patient_id" || name === "department" || name === "bill_no") {
      handleRemoveInfoProductData(name);
    }
    handleValidation(e);
  };
  const handleAutoCompleteChange = (e: any, newValue: any, name: string) => {
    if (newValue === null) {
      return false;
    }
    setInfoData((prev: any) => ({
      ...prev,
      [name]: newValue?.id,
    }));
    handleValidation(e);
  };

  const handleDateChange = useCallback((e: any, newValue: any, name: any) => {
    setInfoData((prevState: any) => ({
      ...prevState,
      date_created: newValue.validatedValue[0],
    }));
  }, []);

  const patientInformation = [
    {
      name: "patient_id",
      data: fieldData.UHID,
      field: "infinite_scroll",
      label: "UHID",
      disabled: Boolean(isEdit),
    },
    {
      name: mobile,
      data: fieldData.mobile,
      field: "textField",
      label: "Mobile",
      disabled: true,
    },
    {
      name: patientName,
      data: fieldData.patient_name,
      field: "textField",
      label: "Patient Name",
      disabled: true,
    },
    {
      name: "date_created",
      data: fieldData.date_created,
      field: "date",
      onClick: handleDateChange,
      label: "Return Date",
    },
    {
      name: "department",
      data: fieldData.department,
      field: "select",
      label: "Department",
      options: departmentOptions,
      isShow: isEdit ? true : false,
    },
    // {
    //   name: "days",
    //   data: fieldData.days,
    //   field: "textField",
    //   label: "Days",
    //   isShow: isEdit ? true : false,
    // },
    // {
    //   name: "type",
    //   data: fieldData.type,
    //   field: "select",
    //   label: "Type",
    //   options: billTypes,
    //   isShow: isEdit ? true : false,
    // },
    {
      name: "invoice_no",
      data: fieldData.invoice_no,
      field: "textField",
      label: "Return No",
    },
    {
      name: "bill_no",
      data: fieldData.bill_no,
      field: "select",
      label: "Bill No",
      options: billNumberOptions,
      isShow: isEdit ? true : false,
    },
  ];

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        mb: "30px",
      }}
      spacing={1}
    >
      {patientInformation.map((row, index) => {
        return (
          !row.isShow && (
            <Grid key={index} item xs={12} md={4} sm={6} xl={2} lg={2}>
              {row?.field === "autocomplete" && (
                <AutoComplete
                  value={row?.data?.value}
                  options={row?.options}
                  name={row?.data?.name}
                  onChange={(e: any, newValue: any) =>
                    handleAutoCompleteChange(e, newValue, row?.data?.name)
                  }
                  label={row?.label}
                  placeholder={row?.data?.placeholder}
                  error={row?.data?.isError}
                  helperText={row?.data?.helperText}
                  onBlur={(e: any) => handleValidation({ target: row.data })}
                  sx={{ ...styles.autoCompleteStyle }}
                />
              )}
              {row?.field === "infinite_scroll" && !isEdit ? (
                <InfiniteScroller
                  loadOptions={getAllPatients}
                  options={patientsList}
                  name={row?.data?.name}
                  value={row?.data?.value}
                  width="100%"
                  handleOnChange={handleInputChange}
                  label={row?.label}
                  error={row?.data?.isError}
                  helperText={row?.data?.helperText}
                />
              ) : row?.field === "infinite_scroll" && isEdit ? (
                <TextField
                  value={row?.data?.value}
                  fullWidth
                  formControlStyle={{ width: "100%" }}
                  name={row?.data?.name}
                  disabled
                  label="UHID"
                />
              ) : null}
              {row?.field === "textField" && (
                <TextField
                  value={row?.data?.value}
                  name={row?.data?.name}
                  onChange={handleInputChange}
                  placeholder={row?.data?.placeholder}
                  error={row?.data?.isError}
                  helperText={row?.data?.helperText}
                  onBlur={(e: any) =>
                    handleValidation((e = { target: row.data }))
                  }
                  disabled={row?.disabled}
                  label={row?.label}
                  endPlaceholderIcon={
                    row?.data?.name === "invoice_no" ? (
                      <SettingsIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSequenceDialogBox(true);
                          setIsManual((prevState: any) => ({
                            ...prevState,
                            is_manual: isManualAdded ? true : false,
                            is_auto_generate:
                              !isManualAdded && !isManual.is_once
                                ? true
                                : false,
                          }));
                        }}
                      />
                    ) : undefined
                  }
                  formControlStyle={{ width: "100%" }}
                  sx={{
                    ...styles.textFieldStyle,
                  }}
                />
              )}
              {row?.field === "date" && (
                <DatePickerWithTime
                  value={row?.data?.value}
                  name={row?.data?.name}
                  onChange={(e: any, newValue: any) =>
                    handleDateChange(e, newValue, "date_created")
                  }
                  placeholder={row?.data?.placeholder}
                  error={row?.data?.isError}
                  helperText={row?.data?.helperText}
                  onBlur={(e: any) => handleValidation({ target: row.data })}
                  label={row?.label}
                  formControlStyle={{
                    width: "90%",
                    "& .icon-tabler": {
                      left: "95%",
                      color: "rgba(0, 0, 0, 0.54)",
                    },
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    height: "37px",
                    border: "1px solid var(--primary-border-color)",
                  }}
                />
              )}
              {row.field === "select" && (
                <Select
                  label={row.label}
                  name={row.data.name}
                  value={row.data.value}
                  formControlStyle={{ width: "100%" }}
                  width=""
                  options={row.options}
                  onChange={handleInputChange}
                />
              )}
            </Grid>
          )
        );
      })}
    </Grid>
  );
};

export default Info;
