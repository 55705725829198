/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { Typography, Box, IconButton, debounce } from "@mui/material";
import { DataTable } from "../../../../components/shared";
import { DeleteIcon, EditIcon, PlusIcon, ViewIcon } from "../../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Sort, TextField } from "../../../../components/basic";
import { RouteUrls } from "../../../../constants/routes";
import { RootState } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import CustomFilter from "../../../../components/shared/customFilter";
import { setSnackBarFailed } from "../../../../redux/slices/snackbar";
import { errorMessage } from "../../../../constants/displayText";
import { getAllStockAdjustment } from "../../../../services/procurementService";
import {
  setCurrentPage,
  setFilterDataValues,
  setHandleClearSearch,
  setSortField,
} from "../../../../redux/slices/pagination";

type GridRowData = Record<string, unknown>;

const StockAdjustmentList = () => {
  const { procurementUrl, stockAdjustmentUrl, createUrl, viewUrl } = RouteUrls;
  const style = {
    textFieldStyle: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "45px",
        borderRadius: "5px",
        paddingLeft: "8px",
        boxShadow: "none",
      },
    },
  };
  const dispatch = useDispatch();
  const [stockAdjustmentList, setStockAdjustmentList] = useState<[]>([]);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ascendingOrderClicked, setAscendingOrderClicked] = useState(false);
  const [descendingOrderClicked, setDescendingOrderClicked] = useState(false);
  const navigate = useNavigate();
  const { pageInfo, filterData, count, sortField } = useSelector(
    (state: RootState) => ({
      pageInfo: state?.pagination?.procurementStockAdjustment,
      filterData: state?.pagination?.procurementStockAdjustment?.filterData,
      count: state?.pagination?.procurementStockAdjustment?.count,
      sortField: state?.pagination?.procurementStockAdjustment?.sortedField,
    })
  );
  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortField?.field === field) {
      dispatch(
        setSortField({
          key: "procurementStockAdjustment",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
    if (sortField?.field !== field) {
      dispatch(
        setSortField({
          key: "procurementStockAdjustment",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          aria-label=""
          onClick={() => {
            navigate(
              `${procurementUrl}${stockAdjustmentUrl}${viewUrl}/${row.dept_id}`,
              { state: { product_id: row.product_id } }
            );
          }}
        >
          <ViewIcon />
        </IconButton>
        {/* <IconButton
          aria-label=""
        
        >
          <DeleteIcon />
        </IconButton> */}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "dept_name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("dept_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Department
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "dept_name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "dept_name" && sortField?.order === "DESC"
                ? true
                : false
            }
            ascendingOnClick={() =>
              setAscendingOrderClicked(!descendingOrderClicked)
            }
            descendingOnClick={() =>
              setDescendingOrderClicked(!ascendingOrderClicked)
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.dept_name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "productName",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Product Name
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "pack_type",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("pack_type")}
        >
          <Typography variant="h5" fontSize={14}>
            Pack Type
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "pack_type" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "pack_type" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.pack_type}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "increase_qty",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("increase_qty")}
        >
          <Typography variant="h5" fontSize={14}>
            Increased Qty
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "increase_qty" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "increase_qty" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.increase_qty}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "decrease_qty",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("decrease_qty")}
        >
          <Typography variant="h5" fontSize={14}>
            Decreased Qty
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "decrease_qty" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "decrease_qty" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.decrease_qty}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  const clearFilters = () => {
    if (filterData.product) {
      dispatch(
        setFilterDataValues({
          key: "procurementStockAdjustment",
          name: "clearFilter",
          value: {
            product: "",
            search: "",
          },
        })
      );
    }
  };

  const handleInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;
      dispatch(
        setFilterDataValues({
          key: "procurementStockAdjustment",
          name: name,
          value: value,
        })
      );
      dispatch(setCurrentPage({ key: "procurementStockAdjustment", value: 0 }));
    }
  };

  const appointmentFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData.product}
          placeholder={"Enter Product Name"}
          label={"Product Name"}
          formControlStyle={{ width: "100%" }}
          onChange={handleInputChange}
          name={"product"}
          sx={{
            ...style.textFieldStyle,
          }}
        />
      ),
    },
  ];

  const debouncedGetAllAdjustmentList = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllStockAdjustment({
        page: data.page,
        limit: data.pageSize,
        column: data.sortBy,
        order: data.sortOrder,
        product: data.product,
        search: data.search,
      })
        .then((res: any) => {
          setStockAdjustmentList(res.data.result as []);
          setPageCount(res.data.total as any);
          setLoading(false);
        })
        .catch((err) => {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage.ERROR_FETCHING_STOCK_ADJUSTMENT}`,
            })
          );
          setLoading(false);
          console.log("error getAppointmentList", err);
        });
    }, 300),
    []
  );
  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo.pageSize,
      sortBy: sortField?.field,
      sortOrder: sortField?.order,
      product: filterData.product,
      search: filterData.search,
    };
    debouncedGetAllAdjustmentList(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortField?.order,
    sortField?.field,
    filterData.product,
    filterData.search,
  ]);

  return (
    <Box>
      <CustomFilter
        data={appointmentFilter}
        isFilterEnabled={true}
        isSearchEnabled={true}
        searchValue={filterData.search}
        editButtonTitle="Add"
        clearFilter={clearFilters}
        searchOnChange={handleInputChange}
        searchName={"search"}
        onUpdateButtonClick={() =>
          navigate(`${procurementUrl}${stockAdjustmentUrl}${createUrl}`)
        }
        clearSearch={() =>
          dispatch(
            setHandleClearSearch({
              key: "procurementStockAdjustment",
              value: "",
              name: "search",
            })
          )
        }
        appliedFilterCount={count}
        editStartIcon={
          <Box
            sx={{
              "& path": {
                fill: "#fff",
              },
            }}
          >
            <PlusIcon width={"12px"} height={"12px"} />
          </Box>
        }
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={stockAdjustmentList}
        pageCount={pageCount}
        currentPage={"procurementStockAdjustment"}
      />
    </Box>
  );
};

export default StockAdjustmentList;
