import React, { useEffect, useState } from "react";
import { useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import { Box, FormControl, FormHelperText, Grid } from "@mui/material";

type TextAreaEditorProps = {
  value?: any;
  toolbar?: boolean;
  onChange?: (value: any) => void | any;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  name?: string;
  label?: string;
};

const buttons = [
  "undo",
  "redo",
  "|",
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "ul",
  "ol",
  "|",
  "|",
  "align",
  "|",
  "|",
  "font",
  "fontsize",
  "brush",
  "|",

  "table",
  "|",
  "eraser",
  "|",
  "source",
  "fullsize",
];

const TextAreaEditor = ({
  toolbar = true,
  value,
  onChange,
  placeholder,
  helperText,
  error,
  name,
  label,
}: TextAreaEditorProps) => {
  const editorConfig: any = useMemo(
    () => ({
      readonly: false,
      toolbar: toolbar,
      toolbarInline: false,
      spellcheck: false,
      language: "en",
      toolbarButtonSize: "medium",
      toolbarAdaptive: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      buttons: buttons,
      uploader: {
        insertImageAsBase64URI: true,
      },
      width: "100%",
      minHeight: "200px",
      placeholder: placeholder ? placeholder : "",
      enter: "br",
      tabIndex: 4,
    }),
    []
  );
  const [isFocused, setIsFocused] = useState<any>(false);

  const editor = useRef<any>(null);
  useEffect(() => {
    if (editor.current) {
      editor.current.focus();
    }
  }, []);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <>
      <style>{`
        .jodit-add-new-line::after {
          display: none; /* Hide the pseudo-element */
        }

        .jodit-add-new-line {
          display: none !important; /* Hide the element itself */
        }
        .jodit-toolbar__box {
          background-color: white !important; /* Hide the element itself */
        }
        .jodit-container {
          border: ${
            isFocused
              ? "2px solid var(--primary-main)  !important"
              : "1px solid var(--primary-border-color)  !important"
          };
          border-radius: var(--primary-border-radius)  !important
        }
        .jodit-toolbar__box:not(:empty){
          border-bottom: 0.5px solid var(--primary-border-color) !important;
        }
        .jodit-ui-separator {
          border-right: 1px solid var(--primary-border-color) !important;
        }
      `}</style>
      {
        <div onFocus={handleFocus} onBlur={handleBlur} tabIndex={0}>
          <JoditEditor
            className="jodit-editor"
            ref={editor}
            value={value}
            config={editorConfig}
            onChange={onChange}
          />
        </div>
      }
      <FormHelperText error={error} sx={{ ml: 2, fontSize: "11px" }}>
        {helperText}
      </FormHelperText>
    </>
  );
};

export default TextAreaEditor;
