import React, { useState } from "react";
import {
  FormHelperText,
  TextField,
  TextFieldProps,
  Typography,
  Grid,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { InputLabel } from "@mui/material";

type PropsType = TextFieldProps & {
  value?: any;
  name?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | any;
  onBlur?: any;
  placeholder?: string;
  type?: string;
  width?: string;
  sx?: any;
  disabled?: boolean;
  formControlStyle?: {};
  defaultValue?: string | number;
  textAreaGridStyle?: {};
  inputRef?: any;
};

const TextArea = ({
  value,
  name,
  error,
  helperText,
  onChange,
  onBlur,
  width = "360px",
  placeholder,
  label,
  sx,
  disabled = false,
  formControlStyle,
  textAreaGridStyle,
  inputRef,
}: PropsType) => {
  const [isFocused, setIsFocused] = useState(false);
  const focusOut = () => {
    setIsFocused(false);
  };
  const focusIn = () => {
    setIsFocused(true);
  };

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        ...textAreaGridStyle,
      }}
    >
      {label && (
        <InputLabel
          focused={isFocused}
          sx={{ color: "var(--primary-label-color)", fontSize: "var(--primary-label-font-size)" }}
        >
          {label}
        </InputLabel>
      )}
      <FormControl sx={{ mt: label ? "5px" : "0px", ...formControlStyle }}>
        <textarea
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          spellCheck="false"
          disabled={disabled}
          draggable={false}
          ref={inputRef}
          onFocus={focusIn}
          onBlur={focusOut}
          style={{
            width: width,
            height: "120px",
            borderRadius: "var(--primary-border-radius)",
            padding: "10px",
            outline: "none",
            border: isFocused
              ? "2px solid var(--primary-main)"
              : "1px solid var(--primary-border-color)",
            marginTop: "5px",
            fontFamily: "inter",
            fontSize: "var(--primary-input-text-font-size)",
            "::placeholder": {
              color: "var(--placeholder-color)",
            },
            ...sx,
          }}
        />
      </FormControl>
      {helperText !== undefined && (
        <FormHelperText
          error={error}
          sx={{
            ml: 2,
            fontSize: "11px",
            minHeight: "20px",
            height: "auto",
            color: "#d32f2f",
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </Grid>
  );
};

export default TextArea;
