import {
  Box,
  CircularProgress,
  debounce,
  Grid,
  List,
  Popper,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import {
  AutoComplete,
  AutoCompleteWithTable,
  CheckBox,
  Select,
  TextField,
} from "../../../../components/basic";
import { getAllSuppliers } from "../../../../services/gynecologyService";

type ProductRowProps = {
  styles?: any;
  product?: any;
  selectedProducts?: any;
  handleCheck: (row: any) => void;
  setIsOpenConfirmModal: ({
    isOpen,
    data,
  }: {
    isOpen: boolean;
    data: {
      status: string | number;
      indent_id: string | number;
      indent_product_id: string | number;
    };
  }) => void;
  handleChange: (e: any, id: number | string, parentId?: number) => void;
  index?: number;
  handleAutoCompleteInputChange?: any;
  handleAutoCompleteChange?: any;
  isPurchaseOrder?: boolean;
  statusOptions?: any[];
  batches?: any;
};

const ProductRow = React.memo(
  ({
    styles,
    product,
    index,
    selectedProducts,
    handleCheck,
    setIsOpenConfirmModal,
    handleChange,
    handleAutoCompleteInputChange,
    handleAutoCompleteChange,
    isPurchaseOrder,
    statusOptions,
    batches,
  }: ProductRowProps) => {
    const [suppliersList, setSuppliersList] = useState<any[]>(
      product?.supplier_id ? [product?.supplier_id] : []
    );
    const [isSupplierLoading, setIsSupplierLoading] = useState<boolean>(false);

    const PopperMy = function (props: any) {
      const { children, ...rest } = props;
      return (
        <Popper
          {...rest}
          placement="bottom-start"
          className="auto-complete-popper"
          sx={{
            width: "450px",
            p: "10px 0px",
            boxSizing: "border-box",
            "& .MuiPaper-root": {
              width: "450px",
              maxHeight: "250px",
              overflow: "hidden",
              borderRadius: "0px 0px 5px 5px",
              boxSizing: "border-box",
            },
            "& .MuiAutocomplete-listbox": {
              overflowX: "hidden",
              maxHeight: "250px",
              p: 0,
            },
          }}
        >
          <Box
            sx={{
              width: "450px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "var(--table-autocomplete-background)",
              p: "0px 10px 0px 0px",
              height: "44px",
              boxSizing: "border-box",
              borderRadius: "5px 5px 0px 0px",
            }}
          >
            <Typography
              sx={{
                width: "33%",
                fontSize: "var(--primary-body-font-size)",
                fontWeight: "500",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Batch No
            </Typography>
            <Typography
              sx={{
                width: "33%",
                fontSize: "var(--primary-body-font-size)",
                fontWeight: "500",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Expire Date
            </Typography>
            <Typography
              sx={{
                width: "33%",
                fontSize: "var(--primary-body-font-size)",
                fontWeight: "500",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Stock Quantity
            </Typography>
          </Box>
          {props.children}
        </Popper>
      );
    };

    const getAllSuppliersList = (value?: string) => {
      getAllSuppliers({
        search: value,
        limit: 20,
      })
        .then((result: any) => {
          let data = result.data.result;
          const formattedSuppliersList = data.map((uniqueData: any) => {
            return { id: uniqueData.id, name: uniqueData.name };
          });
          setSuppliersList(formattedSuppliersList);
          setIsSupplierLoading(false);
        })
        .catch((err) => {
          setIsSupplierLoading(false);
          setSuppliersList([]);
        });
    };

    const debouncedGetAllSupplierList = useCallback(
      debounce((value: string) => {
        getAllSuppliersList(value);
      }, 500),
      []
    );

    const handleAutoCompleteRowInputChange = (
      e: any,
      newValue: any,
      id: string | number,
      name: string
    ) => {
      handleAutoCompleteInputChange(e, newValue, id, name);
      if (newValue?.length >= 3) {
        setIsSupplierLoading(true);
        debouncedGetAllSupplierList(newValue);
      }
    };

    const inputRef = useRef<any>(null);
    return (
      <TableRow
        sx={{
          "& td, & th": { border: 0 },
          width: "90vw",
        }}
      >
        <TableCell
          className="last-cell"
          align="center"
          sx={{
            width: "20%",
            position: "relative",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              ml: "10px",
            }}
          >
            <CheckBox
              checked={selectedProducts?.includes(product.id)}
              onChange={() => handleCheck(product)}
            />
            <Typography
              sx={{
                ...styles.textStyle,
              }}
            >
              {product?.indent_no}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell
          className="last-cell"
          align="center"
          sx={{
            width: "20%",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              ...styles.textStyle,
            }}
          >
            {product?.name}
          </Typography>
        </TableCell>
        <TableCell
          className="last-cell"
          align="center"
          sx={{
            width: "10%",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              ...styles.textStyle,
            }}
          >
            {product?.requirement}
          </Typography>
        </TableCell>
        <TableCell
          className="last-cell"
          align="center"
          sx={{
            width: "15%",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              ...styles.textStyle,
            }}
          >
            {product?.dept_name}
          </Typography>
        </TableCell>
        {!isPurchaseOrder ? (
          <TableCell
            sx={{
              width: "20%",
              position: "relative",
              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
                borderWidth: 0,
                height: "34px",
                maxHeight: "34px",
                p: 0,
              },

              "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "primary.main",
                  borderWidth: "1px",
                },

              "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderWidth: 0,
                },
            }}
          >
            <AutoCompleteWithTable
              placeholder="Select Batch"
              name="batch"
              value={product?.batch}
              onChange={(e: any, newValue: any) =>
                handleAutoCompleteChange(
                  e,
                  newValue,
                  product?.id,
                  "batch"
                  // parentId
                )
              }
              options={
                batches[product?.product_id]?.map((ele: any) => ({
                  ...ele,
                  name: `${ele.batch_no}#${ele.stock_qty}`,
                })) || []
              }
              renderOption={(props: any, option: any) => (
                <List
                  {...props}
                  key={option.id}
                  className="table-body-lists-div"
                  sx={{
                    width: "450px",
                    borderRadius: 0,
                    boxSizing: "border-box",
                    m: "0px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "primary.lighter",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="450px"
                    className="list-row"
                    sx={{
                      boxSizing: "border-box",
                      m: "0px",
                      p: "0px 10px 0px 0px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        width: "33%",
                        textAlign: "left",
                        p: "0px 10px",
                      }}
                    >
                      {option.batch_no}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        width: "33%",
                        textAlign: "left",
                        p: "0px 10px",
                      }}
                    >
                      {option.expires_at}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        width: "33%",
                        textAlign: "left",
                        p: "0px 10px",
                      }}
                    >
                      {option.stock_qty}
                    </Typography>
                  </Box>
                </List>
              )}
              getOptionLabel={(option: any) => option.name}
              sx={{
                width: "100%",
                height: "34px",
                "& .MuiFormControl-root": {
                  height: "32px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "& .MuiInputBase-root .MuiInputBase-input": {
                  padding: 0,
                  fontSize: "12px",
                  fontWeight: "400",
                },
              }}
              PopperComponent={PopperMy}
              isHideClearIcon
              onBlur={() => console.log("blur")}
            />
          </TableCell>
        ) : (
          <TableCell
            className="last-cell"
            align="center"
            sx={{
              width: "20%",
              position: "relative",
              "& .MuiOutlinedInput-root": {
                p: "0px",
                pr: "0px",
                boxShadow: "none",
              },
            }}
          >
            <AutoComplete
              placeholder="select"
              value={product?.supplier_id}
              isAddAutocompleteValue
              inputValue={product?.supplier_name_input_value}
              name="supplier_id"
              onChange={(e: any, newValue: any) => {
                handleAutoCompleteChange(
                  e,
                  newValue,
                  product?.id,
                  "supplier_id"
                );
              }}
              disableClearable={false}
              onInputChange={(e: any, newInputValue: any) => {
                handleAutoCompleteRowInputChange(
                  e,
                  newInputValue,
                  product?.id,
                  "supplier_name_input_value"
                );
              }}
              filterOptions={(options: any) => options}
              noOptionsText={
                isSupplierLoading ? (
                  <div>
                    <CircularProgress
                      style={{ width: "10px", height: "10px" }}
                    />{" "}
                    Loading ...
                  </div>
                ) : product?.supplier_name_input_value?.length < 3 ||
                  !product?.supplier_name_input_value ? (
                  `Please enter ${
                    3 - (product?.supplier_name_input_value?.length || 0)
                  } or more characters`
                ) : (
                  "No options"
                )
              }
              options={suppliersList}
              sx={{
                width: "100%",
                height: "100%",
                "& .MuiFormControl-root": {
                  height: "100%",
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "& .MuiInputBase-root .MuiInputBase-input": {
                  padding: 0,
                  fontSize: "12px",
                  fontWeight: "400",
                },

                fontFamily: ["Inter", "sans-serif"].join(","),

                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "greyScale.light",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.main",
                },
                "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
                  borderColor: "greyScale.lighter",
                },
                "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                  padding: "6.5px",
                  boxShadow: "none",
                  borderRadius: "8px",
                },
              }}
              onBlur={() => setSuppliersList([])}
            />
          </TableCell>
        )}
        <TableCell
          className="last-cell"
          align="center"
          sx={{
            width: "15%",
            position: "relative",
            border: product?.error?.indent
              ? "2px solid #d32f2f !important"
              : "",
          }}
        >
          <TextField
            inputRef={inputRef}
            name={"indent"}
            value={product?.indent}
            sx={{ ...styles.textFieldStyle }}
            type="number"
            onChange={(e: any) => handleChange(e, product?.id)}
            onFocus={(event: any) => {
              if (event?.target?.select) {
                event?.target?.select();
              }
            }}
          />
        </TableCell>
        <TableCell
          className="last-cell"
          align="center"
          sx={{
            width: "15%",
            position: "relative",
            "& .MuiOutlinedInput-notchedOutline": {
              border: 0,
              borderWidth: 0,
              height: "34px",
              maxHeight: "34px",
              p: 0,
            },

            "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "primary.main",
                borderWidth: "1px",
              },

            "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderWidth: 0,
              },
          }}
        >
          <Select
            value={product?.status}
            name="status"
            placeholder="Select status"
            options={statusOptions}
            width={"200px"}
            formControlStyle={{
              width: "100%",
              "& .MuiInputBase-formControl": {
                boxShadow: "unset",
              },
            }}
            onChange={(e: any) =>
              setIsOpenConfirmModal({
                isOpen: true,
                data: {
                  status: e.target.value,
                  indent_id: product?.indent_id,
                  indent_product_id: product.id,
                },
              })
            }
            disabled={product?.issued_qty}
          />
        </TableCell>
      </TableRow>
    );
  }
);

export default ProductRow;
