import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  debounce,
} from "@mui/material";
import {
  CancelButton,
  DatePickerWithTime,
  SaveButton,
  Select,
  TextField,
  CheckBox,
  AutoComplete,
} from "../../../components/basic";
import { RouteUrls } from "../../../constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { SequenceWrapper } from "../../../components/shared";
import { KebabMenuIcon, SettingsLightIcon } from "../../../assets/icons";
import WardRoomModal from "./WardRoomModal";
import { InfiniteScroller } from "../../../components/basic";
import {
  getAllPatientsList,
  createAdmission,
  updateAdmission,
  getConsultantsList,
  getIpTypes,
  getIpDetailsById,
  getPatientDetails,
  getSequenceNo,
  updateSequenceNo,
  getIpStatus,
} from "../../../services/admissionService";
import moment from "moment";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

import { ipAdmissionConst } from "../../../constants/displayText";

type CommonGridComponentProps = {
  label: string;
  children: React.ReactNode;
};

const CommonGridComponent = (props: CommonGridComponentProps) => {
  const { label, children } = props;
  const styles = {
    labelStyle: {
      color: "#232323",
      fontFamily: "Inter",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      textTransform: "capitalize",
    },
  };

  return (
    <Grid item xs={12} sm={5.5} md={3.8} xl={3.9}>
      <Grid container columns={12} alignItems={"center"}>
        <Grid item xs={3} sm={3} md={3}>
          <Typography sx={styles.labelStyle}>{label}</Typography>
        </Grid>
        <Grid
          item
          xs={9}
          sm={9}
          md={9}
          sx={{
            "& .MuiFormControl-root": {
              position: "static",
            },
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function AdmissionView() {
  const { ADDED_SUCCESSFULLY, UPDATED_SUCCESSFULLY, DELETED_SUCCESSFULLY } =
    ipAdmissionConst;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  // model and loader variables
  const { appConfiguration, commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );

  const [customSequence, setCustomSequence] = useState(true);

  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [isOpenModals, setIsOpenModals] = useState<{
    isOpenSequenceModal: boolean;
    isOpenWardRoomModal: boolean;
  }>({
    isOpenSequenceModal: false,
    isOpenWardRoomModal: false,
  });
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  // select/ autocomplete options variables

  const [billingConsultationOptions, setBillingConsultationOptions] = useState(
    []
  );
  const [ipTypes, setIpTypes] = useState([]);
  const [ipStatus, setIpStatus] = useState([]);
  const [patientsList, setPatientsList] = useState<any>([]);
  const [errors, setErrors] = useState<any>([]);

  //field variables

  const initialAdmissionDate = {
    patient_id: null,
    patientRef: useRef(null),
    doctor_id: null,
    doctor_id_input: "",
    doctorRef: useRef(null),
    mobile: null,
    doa: null,
    doaRef: useRef(null),
    bed: "",
    ip_type: "",
    ipTypeRef: useRef(null),
    ip_no: "",
    ipNoRef: useRef(null),
    attender: "",
    attender_mobile: "",
    second_attender: "",
    second_attender_mobile: "",
    health_condition: "",
    wardRoom: "",
    wardRoomRef: useRef(null),
    reason: null,
    is_discharge: "",
    dod: null,
    dodRef: useRef(null),
    mlc: false,
  };

  const [admissionData, setAdmissionData] = useState<any>(initialAdmissionDate);

  const patientsPagination: any = useRef(1);
  const searchPatientsPagination = useRef({ search: "", page: 1 });

  // sequence number variables

  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");

  const [isSequenceWrapperOpen, setIsSequenceWrapperOpen] = useState(false);

  const [sequenceId, setSequenceId] = useState(null);

  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);

  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });

  const [isManualAdded, setIsManualAdded] = useState(false);

  const [seqIsOnce, setSeqIsOnce] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });

  // sequence number generator functions

  let isCreatePrescription = true;
  let isSequenceNumberChanged = false;

  const getSequenceDetails = async () => {
    try {
      // setIsPageLoading(true);
      await getSequenceNo("Inpatient").then((result: any) => {
        let data = result?.data[0]?.sequence_no;

        if (isCreatePrescription && !isSequenceNumberChanged) {
          setInitialSequenceNumber(data);
          setAdmissionData((prevState: any) => ({
            ...prevState,
            ip_no: data,
          }));

          setErrors((prevState: any) => ({
            ...prevState,
            ip_no: "",
          }));
        }

        if (isSequenceNumberChanged) {
          setAdmissionData((prevState: any) => ({
            ...prevState,
            ip_no: data,
          }));
          setErrors((prevState: any) => ({
            ...prevState,
            ip_no: "",
          }));
        }

        if (result?.data?.length > 0) {
          setSequenceId(result?.data[0]?.sequence?.id);
          const newSequenceData =
            result?.data[0]?.sequence?.sequence_preferences.map(
              (ele: {
                id: number;
                type: string;
                value: string;
                separator: string;
              }) => ({
                id: ele.id,
                type: ele.type,
                value: ele.value,
                separator: ele.separator,
              })
            );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual:
              result?.data[0]?.sequence?.is_manual === 1 ? true : false,
            is_auto_generate:
              result?.data[0]?.sequence?.is_manual !== 1 ? true : false,
          }));
          setSeqIsOnce((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(result?.data[0]?.sequence?.is_manual === 1));
        }
        setIsPageLoading(false);
      });
    } catch (error: any) {
      setIsPageLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const handleConfirmSequenceNoChange = () => {
    if (initialSequenceNumber !== admissionData.ip_no) {
      setSeqIsOnce((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: admissionData.ip_no,
      }));
      setIsSequenceWrapperOpen(true);
    }
  };

  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value !== "Select" ? value : null,
      };
      return newState;
    });
  };

  const onCloseSequenceDialogBox = () => {
    setIsSequenceWrapperOpen(false);
  };

  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      try {
        const data = {
          seq_preference: [...sequenceData],
          is_manual: isManual.is_manual ? 1 : 0,
        };
        await updateSequenceNo(Number(sequenceId), data).then((res: any) => {
          if (res) {
            isSequenceNumberChanged = true;
            getSequenceDetails();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        });
      } catch (error: any) {
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.errors,
          })
        );
      }
    }
  };

  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };

  // fields onchange functions

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    if (name === "attender_mobile" || name === "second_attender_mobile") {
      const numericValue = value?.replace(/[^\d.]/g, "").slice(0, 10);
      setAdmissionData((prevState: any) => ({
        ...prevState,
        [name]: numericValue,
      }));
    } else {
      setAdmissionData((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (value && errors[name]) {
      setErrors((prevState: any) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };

  const handleAutoCompleteChange = (e: any, newValue: any, name: string) => {
    setAdmissionData((prevState: any) => ({
      ...prevState,
      [name]: newValue,
    }));

    if (newValue && errors[name]) {
      setErrors((prevState: any) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };

  const handleAutoCompleteInputChange = (
    e: any,
    newInputValue: any,
    name: string
  ) => {
    setAdmissionData((prevState: any) => ({
      ...prevState,
      [name]: newInputValue,
    }));

    if (newInputValue && errors[name]) {
      setErrors((prevState: any) => ({
        ...prevState,
        doctor_id: "",
      }));
    }
  };

  const handleDateChange = (name: string, value: any) => {
    if (value === null) {
      return false;
    }

    setAdmissionData((prevState: any) => ({
      ...prevState,
      [name]: value.toString(),
    }));

    if (value && errors[name]) {
      setErrors((prevState: any) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;

    setAdmissionData((prev: any) => ({
      ...prev,
      [name]: checked,
    }));
  };

  // ward room model functions

  const handleSelectRoom = (value: any) => {
    setIsOpenModals((prevState: any) => ({
      ...prevState,
      isOpenWardRoomModal: false,
    }));
    setAdmissionData((prevState: any) => ({
      ...prevState,
      wardRoom: `${value?.selectedWard} - ${value?.room_no}`,
      bed: value.id,
    }));
    if (value && errors?.bed) {
      setErrors((prevState: any) => ({
        ...prevState,
        bed: "",
      }));
    }
  };

  // api function calls to get select options
  const debouncedGetAllPatientsList = debounce(
    async (currentValue, prevOptions, callback) => {
      try {
        return await getAllPatientsList({
          search: currentValue,
          page:
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page
              : 1,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;
          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData?.id,
              label: `${uniqueData?.uhid} - ${uniqueData?.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList?.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption?.value === option?.value
              )
          );
          setPatientsList(uniqueOptions);
          searchPatientsPagination.current.page =
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page + 1
              : 1;
          searchPatientsPagination.current.search = currentValue;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;

          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
      } catch (error) {
        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
      }
    },
    300
  );

  const getAllPatientsData = async (currentValue?: any, prevOptions?: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllPatientsList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page =
          patientsPagination.current === Math.ceil(prevOptions?.length / 10)
            ? patientsPagination?.current
            : Math.ceil(prevOptions?.length / 10) + 1;
        return await getAllPatientsList({
          search: currentValue,
          page: page,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData?.id,
              label: `${uniqueData?.uhid} - ${uniqueData?.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList?.filter(
            (option: any) =>
              !prevOptions?.some(
                (prevOption: any) => prevOption?.value === option?.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setPatientsList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }
          const currentValue = patientsPagination?.current;
          patientsPagination.current = currentValue + 1;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const getOptionsList = () => {
    getConsultantsList()
      .then((res: any) => {
        if (res?.data) {
          setBillingConsultationOptions(res?.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    if (id) {
      getIpStatus()
        .then((res: any) => {
          if (res?.data) {
            setIpStatus(res?.data);
          }
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    }
  };

  const getPatientInfo = () => {
    getPatientDetails(admissionData?.patient_id)
      .then((res) => {
        if (res.data) {
          const patientData = res.data;
          setAdmissionData((prevState: any) => ({
            ...prevState,
            mobile: patientData.mobile,
          }));
        }
      })
      .catch((err) => console.log("error", err));
  };

  // crud api functions

  const handleValidation = () => {
    let isFocused = false;

    let requiredFields = [];

    if (id) {
      if (admissionData?.is_discharge === 4) {
        requiredFields = [
          "patient_id",
          "ip_no",
          "doctor_id",
          "ip_type",
          "bed",
          "doa",
          "dod",
          "death_date",
        ];
      } else if (admissionData?.is_discharge === 1) {
        requiredFields = [
          "patient_id",
          "ip_no",
          "doctor_id",
          "ip_type",
          "bed",
          "doa",
        ];
      } else {
        requiredFields = [
          "patient_id",
          "doctor_id",
          "doa",
          "bed",
          "ip_type",
          "ip_no",
          "dod",
        ];
      }
    } else {
      requiredFields = [
        "patient_id",
        "doctor_id",
        "ip_no",
        "ip_type",
        "bed",
        "doa",
      ];
    }

    let requiredFieldsRef: any = {
      patient_id: admissionData?.patientRef,
      doctor_id: admissionData?.doctorRef,
      doa: admissionData?.doaRef,
      bed: admissionData?.wardRoomRef,
      ip_type: admissionData?.ipTypeRef,
      ip_no: admissionData?.ipNoRef,
      dod: admissionData?.dod,
      death_date: admissionData?.death_date,
    };

    const newErrors: any = {};
    requiredFields.forEach((key) => {
      if (key === "doctor_id") {
        if (!admissionData?.doctor_id?.id) {
          newErrors[key] = "This field is required";

          if (!isFocused && requiredFieldsRef[key]?.current) {
            requiredFieldsRef[key]?.current?.focus();
            isFocused = true;
          }
        }
      } else {
        if (!admissionData[key]) {
          newErrors[key] = "This field is required";

          if (!isFocused && requiredFieldsRef[key]?.current) {
            requiredFieldsRef[key]?.current?.focus();
            isFocused = true;
          }
        }
      }
    });

    if (Object.keys(newErrors)?.length > 0) {
      setErrors(newErrors);
      return false;
    } else {
      return true;
    }
  };

  const getAdmissionDetails = async () => {
    try {
      setIsPageLoading(true);
      if (id) {
        setIsEdit(true);
        getIpDetailsById(id).then((res: any) => {
          if (res?.data) {
            const { data } = res;
            setAdmissionData((prev: any) => ({
              ...prev,
              patient_id: data?.patient_id,
              doctor_id: { id: data?.doctor_id, name: data?.doctor_name },
              doctor_id_input: data?.doctor_name,
              mobile: data?.patient_details?.mobile,
              doa: data?.doa
                ? moment(data?.doa, [
                    "DD/MM/YYYY hh:mm:ss",
                    "YYYY-MM-DD HH:mm:ss",
                  ]).format("DD/MM/YYYY hh:mm:ss A")
                : null,
              bed: data?.bed,
              ip_type: data?.ip_type,
              ip_no: data?.ip_no,
              attender: data?.attender,
              attender_mobile: data?.attender_mobile,
              second_attender: data?.second_attender,
              second_attender_mobile: data?.second_attender_mobile,
              health_condition: data?.health_condition,
              wardRoom: data?.room_no,
              reason: data?.reason,
              is_discharge: data?.is_discharge,
              dod: data?.dod
                ? moment(data?.dod, [
                    "DD/MM/YYYY hh:mm:ss",
                    "YYYY-MM-DD HH:mm:ss",
                  ]).format("DD/MM/YYYY hh:mm:ss A")
                : null,
              mlc: data?.mlc === 1 ? true : false,
              death_date: data?.death_date
                ? moment(data?.death_date, [
                    "DD/MM/YYYY hh:mm:ss",
                    "YYYY-MM-DD HH:mm:ss",
                  ]).format("DD/MM/YYYY hh:mm:ss A")
                : null,
            }));
            isCreatePrescription = false;
            isSequenceNumberChanged = false;
          }
          setIsPageLoading(false);
          getSequenceDetails();
        });
      } else {
        isCreatePrescription = true;
        isSequenceNumberChanged = false;
        getSequenceDetails();
      }
    } catch (error) {
      setIsPageLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const handleSave = async () => {
    if (handleValidation()) {
      setIsSaveLoading(true);

      const payloadData = {
        patient_id: admissionData?.patient_id,
        doctor_id: admissionData?.doctor_id?.id,
        doa: admissionData?.doa,
        bed: admissionData?.bed,
        ip_type: admissionData?.ip_type,
        ip_no: admissionData?.ip_no,
        attender: admissionData?.attender,
        attender_mobile: admissionData?.attender_mobile,
        second_attender: admissionData?.second_attender,
        second_attender_mobile: admissionData?.second_attender_mobile,
        health_condition: admissionData?.health_condition,
        ip_no_modified:
          Number(admissionData.ip_no) === Number(initialSequenceNumber) ? 0 : 1,
        mlc: admissionData?.mlc === false ? 0 : 1,
      };

      if (id) {
        await updateAdmission(id, payloadData)
          .then((res) => {
            navigate(`${RouteUrls.inPatients}${RouteUrls.list}`);
            setIsSaveLoading(false);
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: ADDED_SUCCESSFULLY,
              })
            );
          })
          .catch((err) => {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.response?.data?.errors,
              })
            );
            setIsSaveLoading(false);
            console.log("err", err);
          });
      } else {
        await createAdmission(payloadData)
          .then((res) => {
            navigate(`${RouteUrls.inPatients}${RouteUrls.list}`);
            setIsSaveLoading(false);
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: UPDATED_SUCCESSFULLY,
              })
            );
          })
          .catch((err) => {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.response?.data?.errors,
              })
            );
            setIsSaveLoading(false);
            console.log("err", err);
          });
      }
    }
  };

  useEffect(() => {
    getOptionsList();
    getAdmissionDetails();
  }, []);

  useEffect(() => {
    if (admissionData.patient_id && !id) {
      getPatientInfo();
    }
  }, [admissionData.patient_id]);

  useEffect(() => {
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }
    if (commonVariables?.ip_type) {
      setIpTypes(commonVariables?.ip_type);
    }
  }, [appConfiguration, commonVariables]);

  return isPageLoading ? (
    <Grid
      sx={{
        width: "92vw",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        height: "90vh",
        background: "transparent",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid>
      <SequenceWrapper
        open={isSequenceWrapperOpen}
        title="In Patients"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />

      <WardRoomModal
        isOpen={isOpenModals?.isOpenWardRoomModal}
        onClose={() =>
          setIsOpenModals((prevState: any) => ({
            ...prevState,
            isOpenWardRoomModal: false,
          }))
        }
        onSelectRoom={handleSelectRoom}
      />
      <Grid
        container
        columns={12}
        rowSpacing={2}
        p={1}
        alignItems={"center"}
        columnGap={2}
      >
        {!isEdit && (
          <CommonGridComponent
            label="Patient"
            children={
              <InfiniteScroller
                loadOptions={getAllPatientsData}
                options={patientsList}
                handleOnChange={handleInputChange}
                name={"patient_id"}
                value={admissionData.patient_id || ""}
                width={"90%"}
                helperText={errors?.patient_id}
              />
            }
          />
        )}
        {!isEdit && (
          <CommonGridComponent
            label="Mobile"
            children={
              <TextField
                value={admissionData.mobile}
                fullWidth
                formControlStyle={{ width: "90%" }}
                name="mobile"
                onChange={handleInputChange}
                disabled
              />
            }
          />
        )}
        {customSequence ? (
          <CommonGridComponent
            label="IP No"
            children={
              <TextField
                fullWidth
                formControlStyle={{ width: "90%" }}
                name="ip_no"
                onChange={handleInputChange}
                helperText={errors?.ip_no}
                value={admissionData.ip_no}
                inputRef={admissionData?.ipNoRef}
                onBlur={handleConfirmSequenceNoChange}
                endPlaceholderIcon={
                  <SettingsLightIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsSequenceWrapperOpen(true);
                    }}
                  />
                }
              />
            }
          />
        ) : null}
        <CommonGridComponent
          label="Doctor"
          children={
            <AutoComplete
              label=""
              value={admissionData.doctor_id}
              inputRef={admissionData?.doctorRef}
              inputValue={admissionData.doctor_id_input}
              disableClearable={false}
              isValueAsObject={true}
              placeholder="Select Doctor Name"
              name="doctor_id"
              onChange={(e: any, newValue: any) =>
                handleAutoCompleteChange(e, newValue, "doctor_id")
              }
              onInputChange={(e: any, newValue: any) =>
                handleAutoCompleteInputChange(e, newValue, "doctor_id_input")
              }
              options={billingConsultationOptions}
              helperText={errors?.doctor_id}
              sx={{ width: "90%" }}
            />
          }
        />
        <CommonGridComponent
          label="Attender"
          children={
            <TextField
              value={admissionData.attender}
              fullWidth
              formControlStyle={{ width: "90%" }}
              name="attender"
              onChange={handleInputChange}
            />
          }
        />
        <CommonGridComponent
          label="Attender Contact"
          children={
            <TextField
              value={admissionData.attender_mobile}
              fullWidth
              formControlStyle={{ width: "90%" }}
              name="attender_mobile"
              onChange={handleInputChange}
            />
          }
        />
        <CommonGridComponent
          label="IP Type"
          children={
            <Select
              label=""
              name="ip_type"
              options={ipTypes}
              formControlStyle={{ width: "90%" }}
              width=""
              value={admissionData.ip_type}
              inputRef={admissionData?.ipTypeRef}
              onChange={handleInputChange}
              helperText={errors?.ip_type}
            />
          }
        />
        <CommonGridComponent
          label="Second Attender"
          children={
            <TextField
              value={admissionData.second_attender}
              fullWidth
              formControlStyle={{ width: "90%" }}
              name="second_attender"
              onChange={handleInputChange}
            />
          }
        />
        <CommonGridComponent
          label="Second Attender Contact"
          children={
            <TextField
              value={admissionData.second_attender_mobile}
              fullWidth
              formControlStyle={{ width: "90%" }}
              name="second_attender_mobile"
              onChange={handleInputChange}
            />
          }
        />
        {!isEdit && (
          <CommonGridComponent
            label="Ward/Room No"
            children={
              <TextField
                value={admissionData.wardRoom}
                inputRef={admissionData?.wardRoomRef}
                fullWidth
                formControlStyle={{ width: "90%" }}
                name="bed"
                endPlaceholderIcon={
                  <Box sx={{ rotate: "90deg", "& path": { fill: "#207DFF" } }}>
                    <KebabMenuIcon
                      width="20px"
                      height="20px"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsOpenModals((prevState: any) => ({
                          ...prevState,
                          isOpenWardRoomModal: true,
                        }));
                      }}
                    />
                  </Box>
                }
                helperText={errors?.bed}
              />
            }
          />
        )}
        <CommonGridComponent
          label="Admission Date"
          children={
            <DatePickerWithTime
              name="doa"
              placeholder="Select Admission Date"
              width="93%"
              onChange={(value: any) => handleDateChange("doa", value)}
              formControlStyle={{ width: "90%" }}
              format="DD/MM/YYYY hh:mm:ss A"
              value={admissionData.doa}
              helperText={errors?.doa}
              maxDate={admissionData.dod}
            />
          }
        />
        <CommonGridComponent
          label="Health"
          children={
            <TextField
              value={admissionData.health_condition}
              fullWidth
              formControlStyle={{ width: "90%" }}
              name="health_condition"
              onChange={handleInputChange}
            />
          }
        />
        <CommonGridComponent
          label="MLC"
          children={
            <CheckBox
              checked={admissionData?.mlc}
              onChange={handleCheckBoxChange}
              name="mlc"
              sx={{ fontSize: "13px", fontStyle: "normal", fontWeight: 600 }}
            ></CheckBox>
          }
        />
        {isEdit && (
          <CommonGridComponent
            label="Status"
            children={
              <Select
                label=""
                name="is_discharge"
                options={ipStatus}
                formControlStyle={{ width: "90%" }}
                width=""
                value={admissionData.is_discharge}
                onChange={handleInputChange}
              />
            }
          />
        )}
        {isEdit && admissionData.is_discharge !== 1 && (
          <CommonGridComponent
            label="Discharge Date"
            children={
              <DatePickerWithTime
                name="dod"
                placeholder="Select Discharge Date"
                isDisabled={admissionData.is_discharge === 1 ? true : false}
                width="93%"
                onChange={(value: any) => handleDateChange("dod", value)}
                formControlStyle={{ width: "90%" }}
                format="DD/MM/YYYY hh:mm:ss A"
                value={admissionData.dod}
                minDate={admissionData.doa}
                helperText={errors?.dod}
              />
            }
          />
        )}
        {isEdit && admissionData.is_discharge === 4 && (
          <CommonGridComponent
            label="Death Date"
            children={
              <DatePickerWithTime
                name="death_date"
                placeholder="Select Death Date"
                width="93%"
                onChange={(value: any) => handleDateChange("death_date", value)}
                formControlStyle={{ width: "90%" }}
                format="DD/MM/YYYY hh:mm:ss A"
                value={admissionData.death_date}
                helperText={errors?.death_date}
              />
            }
          />
        )}
        {isEdit && (
          <CommonGridComponent
            label="Reason"
            children={
              <TextField
                value={admissionData.reason}
                fullWidth
                formControlStyle={{ width: "90%" }}
                name="reason"
                onChange={handleInputChange}
              />
            }
          />
        )}
      </Grid>
      <Box sx={{ m: "20px 0px" }}>
        <SaveButton
          sx={{ width: "100px", height: "40px", mr: "20px" }}
          loading={isSaveLoading}
          handleClick={handleSave}
        />
        <CancelButton
          buttonText="Cancel"
          isDisabled={isSaveLoading}
          sx={{ width: "100px", height: "40px" }}
          handleClick={() =>
            navigate(`${RouteUrls.inPatients}${RouteUrls.list}`)
          }
        />
      </Box>
    </Grid>
  );
}
