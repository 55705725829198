import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  debounce,
  Grid,
  Typography,
} from "@mui/material";
import {
  PageLoader,
  Switch,
  CancelButton,
  Radio,
  SaveButton,
  Select,
  TextField,
  AutoComplete,
  AutoCompleteWithCheckBoxes,
} from "../../../../components/basic";
import ItemsList from "./ItemsList";
import { Card, ConfirmationDialog } from "../../../../components/shared";
import { AddIcon, RupeeIcon } from "../../../../assets/icons";
import {
  addItemsConst,
  displayText,
  errorMessages,
} from "../../../../constants/displayText";
import RangeModal from "./RangeModal";
import { useNavigate, useParams } from "react-router-dom";
import { RouteUrls } from "../../../../constants/routes";
import {
  LabTest,
  createBillingItem,
  getBillingItemById,
  getConsultantsList,
  getDepartments,
  getGroupNames,
  updateBillingItemById,
  deleteRowItemById,
  getPackageItems,
} from "../../../../services/billingService";
import { RootState } from "../../../../redux/store";
import { setIsLoading } from "../../../../redux/slices/loader";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import GroupNameAddModal from "./GroupNameAddModal";
import { Can } from "../../../../utils/PermissionUtils";

const ItemsListMemoized = React.memo(ItemsList);

type ItemsAddProps = {
  state: any;
  setState?: any;
  isEdit?: boolean;
  billingDepartmentOptions: any;
  billingGroupNameOptions: any;
  billingConsultationOptions: any;
  billingItemsOptions: any;
  handleChange: (event: any) => void;
  errors?: any;
  setBillingGroupNameOptions?: any;
  handleAutoCompleteChange?: any;
  handleAutoCompleteInputChange?: any;
  isGroupNameLoading?: boolean;
};

type CommonGridComponentProps = {
  label: string;
  children: React.ReactNode;
};

const initialData: any = {
  dept_id: "",
  type: "",
  group_id: null,
  group_input_value: "",
  name: "",
  align_order: "",
  desc: "",
  price: "",
  consultants: "",
  is_lab_test: "",
  is_outsource: 0,
  package_tests: [],
  package_test_value: "",
  lab_tests: [
    {
      subHeading: "",
      subTests: [
        {
          active: true,
          test_name: "",
          align_order: "",
          test_dept: "",
          result: [],
          methodology_id: "",
          investigation: "",
          unit: "",
          reference_ranges: [
            {
              age_group_id: "",
              min_value: "",
              range_type: "",
              max_value: "",
              health_condition: "",
            },
          ],
          note: "",
          price: "",
        },
      ],
    },
  ],
};

const CommonGridComponent = (props: CommonGridComponentProps) => {
  const { label, children } = props;
  const styles = {
    labelStyle: {
      color: "#232323",
      fontFamily: "Inter",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      textTransform: "capitalize",
    },
  };
  return (
    <Grid item xs={12} sm={5.5} md={5} lg={3.8} xl={3.9}>
      <Grid container columns={12} alignItems={"center"}>
        <Grid item xs={4} sm={3} md={3}>
          <Typography sx={styles.labelStyle} mb={1}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={8} sm={9} md={9}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

const ItemsAdd = (props: ItemsAddProps) => {
  const {
    state,
    setState,
    billingDepartmentOptions,
    billingGroupNameOptions,
    billingConsultationOptions,
    billingItemsOptions,
    handleChange,
    isEdit,
    errors,
    setBillingGroupNameOptions,
    handleAutoCompleteInputChange,
    handleAutoCompleteChange,
    isGroupNameLoading,
  } = props;
  const { commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [isOpenGroupModal, setIsOpenGroupModal] = useState<boolean>(false);
  const [itemCategoryOptions, setItemCategoryOptions] = useState<any[]>([]);

  const handleClose = (isSaveCompleted?: boolean, data?: any) => {
    setIsOpenGroupModal(false);
    if (isSaveCompleted) {
      setBillingGroupNameOptions((prevState: any) => ({
        ...prevState,
        rows: [
          ...prevState?.rows,
          { id: data?.id, name: data?.name, active: data?.active },
        ],
      }));
      setState((prevState: any) => ({
        ...prevState,
        group_id: { id: data?.id, name: data?.name, active: data?.active },
        group_input_value: data?.name,
      }));
    }
  };

  useEffect(() => {
    if (commonVariables?.billing_items_category) {
      setItemCategoryOptions(commonVariables?.billing_items_category);
    }
  }, [commonVariables]);

  return (
    <Card
      sx={{
        borderRadius: "5px",
        border: "1px solid var(--table-border)",
        background: "#FFF",
        boxShadow: "none",
      }}
    >
      <Grid
        container
        columns={12}
        rowSpacing={1}
        p={1}
        alignItems={"center"}
        columnGap={2}
      >
        <CommonGridComponent
          label={addItemsConst.DEPARTMENTLABEL}
          children={
            <Select
              value={state.dept_id || null}
              label=""
              placeholder={addItemsConst.DEPARTMENTPLACEHOLDER}
              name="dept_id"
              options={billingDepartmentOptions}
              formControlStyle={{ width: "90%" }}
              width=""
              onChange={handleChange}
              error={!!errors?.dept_id}
              helperText={errors?.dept_id}
            />
          }
        />
        <CommonGridComponent
          label={addItemsConst.ITEMTYPLABEL}
          children={
            <Radio
              value={
                state.type === ""
                  ? ""
                  : state.type === "IND"
                  ? "Individual"
                  : "Package"
              }
              label=""
              name="type"
              options={["Individual", "Package"]}
              onChange={handleChange}
              error={!!errors?.type}
              helperText={errors?.type}
              isDisabled={isEdit}
            />
          }
        />
        <CommonGridComponent
          label={addItemsConst.GROUPNAMELABEL}
          children={
            <Grid
              width={Can("items_group_add") ? "90%" : "100%"}
              display={"flex"}
            >
              <Box width={"90%"}>
                <AutoComplete
                  label=""
                  value={state.group_id || null}
                  inputValue={state.group_input_value || ""}
                  placeholder={addItemsConst.GROUPNAMEPLACEHOLDER}
                  name="group_id"
                  optionName="name"
                  onChange={(e: any, newValue: any) =>
                    handleAutoCompleteChange(e, newValue, "group_id")
                  }
                  onInputChange={(e: any, newValue: any) =>
                    handleAutoCompleteInputChange(
                      e,
                      newValue,
                      "group_input_value"
                    )
                  }
                  options={billingGroupNameOptions?.rows || []}
                  noOptionsText={
                    isGroupNameLoading ? (
                      <div>
                        <CircularProgress
                          style={{ width: "10px", height: "10px" }}
                        />{" "}
                        Loading ...
                      </div>
                    ) : (
                      "No options"
                    )
                  }
                  error={!!errors?.group_id}
                  helperText={errors?.group_id}
                  sx={{ width: "98%" }}
                />
              </Box>
              {Can("items_group_add") && (
                <Box display={"flex"} alignItems={"center"}>
                  <Box
                    sx={{
                      width: "30px",
                      height: "30px",
                      borderRadius: " 5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#ECF4FF ",
                      mb: 3,
                      cursor: "pointer",
                    }}
                  >
                    <AddIcon onClick={() => setIsOpenGroupModal(true)} />
                  </Box>
                </Box>
              )}
            </Grid>
          }
        />
        <CommonGridComponent
          label={addItemsConst.TESTNAMEANDSEQLABEL}
          children={
            <Box display={"flex"} width={"90%"}>
              <div style={{ width: "80%" }}>
                <TextField
                  value={state.name || ""}
                  formControlStyle={{ width: "100%" }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& input": {
                        paddingLeft: "10px",
                      },
                      "& fieldset": {
                        borderRight: "none !important",
                        borderRadius: "8px 0px 0px 8px !important",
                        borderColor: "var(--primary-border-color) !important",
                        // borderRight: !!errors.name
                        //   ? "1px solid #EFEFEF !important"
                        //   : "none !important",
                        // borderRadius: !!errors.name
                        //   ? "8px"
                        //   : "8px 0px 0px 8px !important",
                        // borderColor: !!errors.name
                        //   ? "#d32f2f !important"
                        //   : "#EFEFEF !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderRadius: "8px !important",
                        borderRight: "2px solid #207DFF !important",
                        border: "2px solid #207DFF !important",
                        // borderRight: !!errors.name
                        //   ? "1px solid #d32f2f !important"
                        //   : "2px solid #207DFF !important",
                        // border: !!errors.name
                        //   ? "1px solid #d32f2f !important"
                        //   : "2px solid #207DFF !important",
                      },
                    },
                  }}
                  name="name"
                  onChange={handleChange}
                  error={!!errors?.name}
                  helperText={errors?.name}
                />
              </div>
              <div style={{ width: "20%" }}>
                <TextField
                  value={state.align_order || ""}
                  type="number"
                  formControlStyle={{ width: "100%" }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& input": {
                        paddingLeft: "10px",
                      },
                      "& fieldset": {
                        borderRadius: "0px 8px 8px 0px !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderRadius: "8px !important",
                        borderRight: "2px solid #207DFF !important",
                      },
                    },
                    "&input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                  }}
                  name="align_order"
                  onChange={(event: any) =>
                    handleChange({
                      target: {
                        name: "align_order",
                        value: Number(event?.target?.value),
                      },
                    })
                  }
                />
              </div>
            </Box>
          }
        />
        <CommonGridComponent
          label={addItemsConst.DESCRIPTIONLABEL}
          children={
            <TextField
              value={state.desc || ""}
              fullWidth
              formControlStyle={{ width: "90%" }}
              name="desc"
              onChange={handleChange}
              helperText=""
            />
          }
        />
        <CommonGridComponent
          label={addItemsConst.PRICELABEL}
          children={
            <TextField
              value={state.price || ""}
              fullWidth
              name="price"
              type="number"
              formControlStyle={{ width: "90%" }}
              endPlaceholderIcon={<RupeeIcon />}
              sx={{
                "&input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
              }}
              onChange={handleChange}
              error={!!errors?.price}
              helperText={errors?.price}
            />
          }
        />
        <CommonGridComponent
          label={addItemsConst.CONSULTANTLABEL}
          children={
            <Select
              value={state.consultants || null}
              label=""
              placeholder={addItemsConst.CONSULTANTPLACEHOLDER}
              name="consultants"
              options={billingConsultationOptions}
              formControlStyle={{ width: "90%" }}
              width=""
              sx={{
                "&.MuiPopover-paper .MuiMenu-paper .MuiMenu-paper": {
                  width: "100%",
                  border: "1px solid red",
                },
              }}
              onChange={handleChange}
              helperText=""
            />
          }
        />
        {state.type === "PAK" && (
          <CommonGridComponent
            label={addItemsConst.ITEMSLABEL}
            children={
              <AutoCompleteWithCheckBoxes
                label=""
                value={state.package_tests || []}
                inputValue={state.package_test_value || ""}
                placeholder={addItemsConst.ITEMSPLACEHOLDER}
                name="package_tests"
                isVirtualization
                options={billingItemsOptions}
                onChange={(e: any, newValue: any) =>
                  handleAutoCompleteChange(e, newValue, "package_tests")
                }
                onInputChange={(e: any, newValue: any) =>
                  handleAutoCompleteInputChange(
                    e,
                    newValue,
                    "package_test_value"
                  )
                }
                sx={{
                  width: "90%",
                  "& .MuiInputBase-root": {
                    border: "1px solid var(--primary-border-color)",
                  },
                  "& fieldset": {
                    border: "unset",
                  },
                  '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused':
                    {
                      background: "unset !important",
                    },
                }}
                error={!!errors?.package_tests}
                helperText={errors?.package_tests}
              />
            }
          />
        )}

        {state.type === "IND" && (
          <CommonGridComponent
            label={addItemsConst.CATEGORYLABEL}
            children={
              // <Radio
              //   value={state.is_lab_test ? "Yes" : "No"}
              //   name="is_lab_test"
              //   label="category"
              //   options={["Yes", "No"]}
              //   onChange={handleChange}
              // />
              <Select
                value={state.is_lab_test || null}
                name="is_lab_test"
                label=""
                options={itemCategoryOptions}
                onChange={handleChange}
                placeholder={addItemsConst.CATEGORYPLACEHOLDER}
                formControlStyle={{ width: "90%" }}
                width=""
                disabled={isEdit}
                error={!!errors?.is_lab_test}
                helperText={errors?.is_lab_test}
              />
            }
          />
        )}
        {state.is_lab_test === 1 && state.type === "IND" && (
          <CommonGridComponent
            label={addItemsConst.LABOUTSOURCELABEL}
            children={
              <Radio
                value={state.is_outsource ? "Yes" : "No"}
                name="is_outsource"
                label=""
                options={["Yes", "No"]}
                onChange={handleChange}
              />
            }
          />
        )}
        {isEdit && (
          <CommonGridComponent
            label={addItemsConst.STATUSLABEL}
            children={
              <Box
                sx={{
                  "&.MuiSwitch-root": {
                    margin: "0px !important",
                    border: "1px solid red",
                  },
                }}
              >
                <Switch
                  Details={false}
                  name={"active"}
                  value={Boolean(state.active)}
                  onChange={handleChange}
                />
              </Box>
            }
          />
        )}
        <GroupNameAddModal isOpen={isOpenGroupModal} onClose={handleClose} />
      </Grid>
    </Card>
  );
};

const BillingAddItems = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state: RootState) => state.loader);

  const [state, setState] = useState<any>({});
  const [items, setItems] = useState<any>([]);
  const [isSubTestAddedOrDeleted, setIsSubTestAddedOrDeleted] =
    useState<boolean>(false); // Added this state to render row only when row is added or removed
  const [billingDepartmentOptions, setBillingDepartmentOptions] = useState<
    { id: string | number; name: string }[] | []
  >([]);
  const [billingGroupNameOptions, setBillingGroupNameOptions] = useState<any>({
    rows: [],
    total: 0,
  });
  const [billingConsultationOptions, setBillingConsultationOptions] = useState(
    []
  );
  const [billingItemsOptions, setBillingItemsOptions] = useState<any>([]);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [errors, setErrors] = useState<any>({
    dept_id: "",
    type: "",
    group_id: "",
    name: "",
    desc: "",
    consultants: "",
    price: "",
    is_lab_test: "",
    package_tests: "",
    lab_tests: [],
  });
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<{
    isOpen: boolean;
    rowIndex: number;
    subTestIndex: number;
    id: null | number | string | undefined;
  }>({
    isOpen: false,
    rowIndex: 0,
    subTestIndex: 0,
    id: null,
  });
  const [isGroupNameLoading, setIsGroupNameLoading] = useState(false);

  const [
    isConfirmationDialogButtonLoading,
    setIsConfirmationDialogButtonLoading,
  ] = useState<boolean>(false);

  const handleChange = useCallback((event: any) => {
    const { name, value, checked }: any = event.target;

    switch (name) {
      case "type": {
        setState((prevState: any) => ({
          ...prevState,
          [name]: value === "Individual" ? "IND" : "PAK",
        }));
        break;
      }

      case "is_outsource": {
        setState((prevState: any) => ({
          ...prevState,
          [name]: value === "Yes" ? 1 : 0,
        }));
        break;
      }
      case "active": {
        setState((prevState: any) => ({
          ...prevState,
          [name]: checked,
        }));
        break;
      }
      default:
        setState((prevState: any) => ({ ...prevState, [name]: value }));
        break;
    }
    if (value) {
      setErrors((prevState: any) => ({
        ...prevState,
        [name]: "",
      }));
    } else {
      setErrors((prevState: any) => ({
        ...prevState,
        [name]: errorMessages[name],
      }));
    }
  }, []);

  const [rangeModalData, setRangeModalData] = useState<any>({
    rowIndex: null,
    subTestIndex: null,
    isOpen: false,
    data: null,
  });

  const handleAddItem = useCallback(() => {
    setItems((prevState: any) => {
      const laboratoryItems = [
        ...prevState,
        {
          sub_heading: "",
          subTests: [
            {
              test_name: "",
              align_order: "",
              test_dept: "",
              result: [],
              methodology_id: "",
              investigation: "",
              unit: "",
              reference_ranges: [
                {
                  age_group_id: "",
                  min_value: "",
                  range_type: "",
                  max_value: "",
                  health_condition: "",
                },
              ],
              note: "",
              price: "",
              active: true,
            },
          ],
        },
      ];

      return [...laboratoryItems];
    });
  }, []);

  const handleAddSubTest = useCallback((index: number) => {
    setItems((prevState: any) => {
      const laboratoryItems = [...prevState];

      laboratoryItems[index].subTests = [
        ...laboratoryItems[index]?.subTests,
        {
          test_name: "",
          align_order: "",
          test_dept: "",
          result: [],
          methodology_id: "",
          investigation: "",
          unit: "",
          reference_ranges: [
            {
              age_group_id: "",
              min_value: "",
              range_type: "",
              max_value: "",
              health_condition: "",
            },
          ],
          notes: "",
          price: "",
          active: true,
        },
      ];

      return [...laboratoryItems];
    });
    setIsSubTestAddedOrDeleted((prevState: boolean) => !prevState);
  }, []);

  const handleDeleteItem = useCallback(
    (rowIndex: number, subTestIndex: number, id?: number | string) => {
      setIsOpenConfirmDelete({
        isOpen: true,
        rowIndex,
        subTestIndex,
        id,
      });
    },
    []
  );

  const handleCloseDeleteConfirmModal = () => {
    setIsOpenConfirmDelete({
      isOpen: false,
      rowIndex: 0,
      subTestIndex: 0,
      id: null,
    });
  };
  const handleConfirmDelete = useCallback((isOpenConfirmDelete: any) => {
    if (isOpenConfirmDelete?.id) {
      setIsConfirmationDialogButtonLoading(true);
      deleteRowItemById(isOpenConfirmDelete?.id)
        .then((res) => {
          setItems((prevState: any) => {
            const laboratoryItems = [...prevState];
            laboratoryItems[isOpenConfirmDelete?.rowIndex]?.subTests?.splice(
              isOpenConfirmDelete?.subTestIndex,
              1
            );

            if (
              laboratoryItems[isOpenConfirmDelete?.rowIndex].subTests
                ?.length === 0
            ) {
              laboratoryItems.splice(isOpenConfirmDelete?.rowIndex, 1);
            }
            return [...laboratoryItems];
          });
          setErrors((prevState: any) => {
            if (
              prevState?.length > isOpenConfirmDelete?.rowIndex &&
              prevState[isOpenConfirmDelete?.rowIndex]
            ) {
              if (
                prevState[isOpenConfirmDelete?.rowIndex]?.length >
                  isOpenConfirmDelete?.subTestIndex &&
                prevState[isOpenConfirmDelete?.rowIndex]?.subTests[
                  isOpenConfirmDelete?.subTestIndex
                ]
              ) {
                prevState[isOpenConfirmDelete?.rowIndex]?.subTests?.splice(
                  isOpenConfirmDelete.subTestIndex,
                  1
                );
              }
            }

            return prevState;
          });
          setIsSubTestAddedOrDeleted((prevState: boolean) => !prevState);
          setIsConfirmationDialogButtonLoading(false);
          handleCloseDeleteConfirmModal();
          dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
        })
        .catch((err) => {
          console.log("err", err);
          setIsConfirmationDialogButtonLoading(false);
          if (err?.response?.data?.errors) {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.response?.data?.errors,
              })
            );
          }
        });
    } else {
      setItems((prevState: any) => {
        const laboratoryItems = [...prevState];
        laboratoryItems[isOpenConfirmDelete?.rowIndex]?.subTests?.splice(
          isOpenConfirmDelete?.subTestIndex,
          1
        );

        if (
          laboratoryItems[isOpenConfirmDelete?.rowIndex].subTests?.length === 0
        ) {
          laboratoryItems.splice(isOpenConfirmDelete?.rowIndex, 1);
        }
        return [...laboratoryItems];
      });

      setErrors((prevState: any) => {
        if (
          prevState?.length > isOpenConfirmDelete?.rowIndex &&
          prevState[isOpenConfirmDelete?.rowIndex]
        ) {
          if (
            prevState[isOpenConfirmDelete?.rowIndex]?.length >
              isOpenConfirmDelete?.subTestIndex &&
            prevState[isOpenConfirmDelete?.rowIndex]?.subTests[
              isOpenConfirmDelete?.subTestIndex
            ]
          ) {
            prevState[isOpenConfirmDelete?.rowIndex]?.subTests?.splice(
              isOpenConfirmDelete.subTestIndex,
              1
            );
          }
        }
        return prevState;
      });
      setIsSubTestAddedOrDeleted((prevState: boolean) => !prevState);
      handleCloseDeleteConfirmModal();
    }
  }, []);

  const handleClickRange = useCallback(
    (rowIndex: number, subTestIndex: number) => {
      setRangeModalData((prevState: any) => {
        let newItems: any = [];
        setItems((prevState: any) => {
          newItems = [...prevState];
          return prevState;
        });

        return {
          isOpen: true,
          data: newItems[rowIndex]?.subTests[subTestIndex]?.reference_ranges,
          rowIndex,
          subTestIndex,
        };
      });
    },
    []
  );

  const handleAddRangeItem = useCallback(
    (data: any) => {
      setItems((prevState: any) => {
        const laboratoryItems = [...prevState];
        laboratoryItems[rangeModalData?.rowIndex].subTests[
          rangeModalData?.subTestIndex
        ].reference_ranges = data;
        return [...laboratoryItems];
      });
    },
    [rangeModalData]
  );

  const handleValidation = (data: any) => {
    const requiredFields = ["dept_id", "type", "group_id", "name", "price"];

    const newErrors: any = {};
    requiredFields.forEach((field) => {
      if (!data[field]) {
        newErrors[field] = errorMessages[field];
      } else {
        newErrors[field] = "";
      }
    });

    if (data.type === "PAK" && data.package_tests?.length === 0) {
      newErrors.package_tests = errorMessages.package_tests;
    } else if (data?.type === "IND" && !data?.is_lab_test) {
      newErrors.is_lab_test = errorMessages.is_lab_test;
    }

    if (
      data.type === "IND" &&
      data.is_lab_test === 1 &&
      data.lab_tests?.length > 0
    ) {
      const requiredItemFields = [
        "test_name",
        "test_dept",
        "price",
        "methodology_id",
      ];

      newErrors.lab_tests = [];

      data.lab_tests?.forEach((labTest: any, index: number) => {
        let error: { subTests: { [key: string]: string }[] } = { subTests: [] };
        if (labTest?.subTests?.length > 0) {
          labTest?.subTests?.forEach((ele: any) => {
            let subTestError: { [key: string]: string } = {};
            requiredItemFields?.forEach((field) => {
              if (!ele[field]) {
                subTestError[field] = errorMessages[field];
              }
            });
            error.subTests?.push(subTestError);
          });
        }
        newErrors.lab_tests.push(error);
      });
      let labTestHasError = false;

      if (newErrors.lab_tests?.length > 0) {
        newErrors?.lab_tests?.forEach((labTest: any) => {
          if (labTest?.subTests?.length > 0) {
            labTest?.subTests?.forEach((subTest: any) => {
              if (Object.keys(subTest).length > 0) {
                labTestHasError = true;
              }
            });
          }
        });
      }

      if (!labTestHasError) {
        delete newErrors?.lab_tests;
      }
    }

    if (Object.values(newErrors)?.filter((ele) => Boolean(ele)).length > 0) {
      setErrors((prevState: any) => ({
        ...prevState,
        ...newErrors,
      }));
      return false;
    } else {
      return true;
    }
  };

  const handleSave = useCallback(() => {
    let newState: any;
    let laboratoryItems;
    setState((prevState: any) => {
      newState = {};
      newState.group_id = prevState?.group_id?.id;
      newState.active = prevState?.active ? 1 : 0;
      newState.package_tests = prevState?.package_tests?.map(
        (ele: any) => ele?.custom_id
      );
      newState.dept_id = prevState?.dept_id;
      newState.type = prevState?.type;
      newState.name = prevState?.name;
      newState.align_order = prevState?.align_order;
      newState.desc = prevState?.desc;
      newState.price = prevState?.price;
      newState.consultants = prevState?.consultants;
      newState.is_lab_test = prevState?.is_lab_test;
      newState.is_outsource = prevState?.is_outsource;
      newState.desc = prevState?.desc;
      return prevState;
    });

    setItems((prevItems: any) => {
      laboratoryItems = prevItems;
      return prevItems;
    });
    const formedData = {
      ...newState,
      lab_tests: deformatItemsData(laboratoryItems),
    };

    if (formedData.type === "IND") {
      delete formedData.package_tests;

      if (formedData.is_lab_test !== 1) {
        delete formedData.lab_tests;
      }
    } else {
      delete formedData.lab_tests;
    }
    delete formedData.department;

    if (handleValidation({ ...newState, lab_tests: laboratoryItems })) {
      setIsSaveLoading(true);
      if (id) {
        updateBillingItemById(id, formedData)
          .then((res) => {
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            navigate(`${RouteUrls.billingUrl}${RouteUrls.billingItemsUrl}`);
            setIsSaveLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsSaveLoading(false);
          });
      } else {
        createBillingItem(formedData)
          .then((res) => {
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            navigate(`${RouteUrls.billingUrl}${RouteUrls.billingItemsUrl}`);
            setIsSaveLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsSaveLoading(false);
            if (err?.response?.data?.errors)
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
          });
      }
    }
  }, []);

  const handleChangeRowData = useCallback(
    (
      event: any,
      rowIndex: number,
      subTestIndex: number,
      isSubHeading?: boolean
    ) => {
      const { name, value }: any = event.target;

      setItems((prevState: any) => {
        const updatedLaboratoryItems = prevState.map(
          (row: any, index: number) => {
            if (rowIndex === index) {
              if (row && row?.subTests && !isSubHeading) {
                return {
                  ...row,
                  subTests: row.subTests.map(
                    (subTest: any, subIndex: number) => {
                      if (subTestIndex === subIndex) {
                        if (name === "align_order") {
                          return { ...subTest, [name]: Number(value) };
                        }
                        return { ...subTest, [name]: value };
                      }
                      return subTest;
                    }
                  ),
                };
              }

              if (isSubHeading) {
                return {
                  ...row,
                  [name]: value,
                };
              }
            }

            return row;
          }
        );

        return [...updatedLaboratoryItems];
      });

      if (value) {
        setErrors((prevState: any) => {
          const lab_tests = prevState?.lab_tests
            ? [...prevState?.lab_tests]
            : [];
          if (lab_tests.length > rowIndex) {
            if (lab_tests[rowIndex].subTests?.length > subTestIndex) {
              lab_tests[rowIndex].subTests[subTestIndex][name] = "";
            }
          }
          return {
            ...prevState,
            lab_tests,
          };
        });
      } else {
        setErrors((prevState: any) => {
          const lab_tests = prevState?.lab_tests
            ? [...prevState?.lab_tests]
            : [];
          if (lab_tests.length > rowIndex) {
            if (lab_tests[rowIndex].subTests?.length > subTestIndex) {
              lab_tests[rowIndex].subTests[subTestIndex][name] =
                errorMessages[name];
            }
          }
          return {
            ...prevState,
            lab_tests,
          };
        });
      }
    },
    []
  );

  const formatItemsData = (data: LabTest[]) => {
    const newItemsMap = new Map();

    data.forEach((ele) => {
      const subHeading = ele.sub_heading;
      if (!newItemsMap.has(subHeading)) {
        newItemsMap.set(subHeading, {
          id: newItemsMap.size + 1,
          sub_heading: subHeading,
          subTests: [],
        });
      }

      newItemsMap.get(subHeading).subTests.push({
        ...ele,
        result: ele?.result?.split(",").map((result) => Number(result)),
      });
    });

    const newItems = Array.from(newItemsMap.values());
    setItems(newItems);
  };

  const deformatItemsData = (data: any) => {
    const newItems: any = [];

    data?.forEach((ele: any) => {
      ele.subTests.forEach((elem: any) => {
        let reference_ranges = elem.reference_ranges;

        if (
          reference_ranges?.length === 1 &&
          Object.values(reference_ranges[0]).filter((ranges) => Boolean(ranges))
            ?.length === 0
        ) {
          reference_ranges = [];
        }
        newItems.push({
          ...elem,
          active: elem.active ? 1 : 0,
          test_id: elem.id || null,
          sub_heading: ele.sub_heading,
          reference_ranges,
        });
      });
    });

    return newItems;
  };

  useEffect(() => {
    if (id) {
      dispatch(setIsLoading(true));
      getBillingItemById(id)
        .then((res: any) => {
          if (res.data) {
            setState({
              ...res.data,
              dept_id: Number(res?.data?.dept_id),
              group_id: res?.data?.item_group,
              group_input_value: res?.data?.item_group?.name,
              consultants: Number(res?.data?.consultants),
              package_tests: res?.data?.package_tests
                ?.split(",")
                ?.map((packageId: string | number) => packageId),
            });
            formatItemsData(res.data?.lab_tests);
            setIsSubTestAddedOrDeleted((prevState: boolean) => !prevState);
          }
          dispatch(setIsLoading(false));
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(setIsLoading(false));
        });
    } else {
      setState(initialData);
      setItems(initialData.lab_tests);
    }
  }, [id]);

  const getGroupNamesList = (search?: string) => {
    getGroupNames({ active: 1, limit: 100, search })
      .then((res: any) => {
        if (res.data) {
          setBillingGroupNameOptions((prevState: any) => {
            const newState = {
              ...prevState,
              rows: res?.data?.result,
            };

            if (!search) {
              newState.total = res?.data?.total;
            }
            return newState;
          });
        }
        setIsGroupNameLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsGroupNameLoading(false);
        if (err?.response?.data?.errors) {
          dispatch(
            setSnackBarFailed({ snackBarMessage: err?.response?.data?.errors })
          );
        }
      });
  };

  const getPackageItemsList = (search?: string, newState?: any) => {
    getPackageItems({})
      .then((res: any) => {
        if (res.data) {
          setBillingItemsOptions(res?.data?.result);

          if (
            Boolean(id) &&
            state.package_tests?.length > 0 &&
            res?.data?.result?.length > 0
          ) {
            setState((prevState: any) => ({
              ...prevState,
              package_tests: state?.package_tests?.map((ele: any) => {
                const option = res?.data?.result?.find(
                  (option: any) => ele === option.custom_id
                );

                if (option) {
                  return option;
                }
                return ele;
              }),
            }));
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
        if (err?.response?.data?.errors) {
          dispatch(
            setSnackBarFailed({ snackBarMessage: err?.response?.data?.errors })
          );
        }
      });
  };
  useEffect(() => {
    getDepartments()
      .then((res: any) => {
        if (res.data) {
          setBillingDepartmentOptions(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
        if (err?.response?.data?.errors) {
          dispatch(
            setSnackBarFailed({ snackBarMessage: err?.response?.data?.errors })
          );
        }
      });
    getConsultantsList()
      .then((res: any) => {
        if (res.data) {
          setBillingConsultationOptions(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    getGroupNamesList();
  }, []);

  useEffect(() => {
    if (
      (state.type === "PAK" && billingItemsOptions?.length === 0) ||
      (Boolean(id) && state.type === "PAK")
    ) {
      getPackageItemsList("", state);
    }
  }, [state.type]);

  const handleAutoCompleteChange = (e: any, newValue: any, name: string) => {
    if (newValue === null) {
      return false;
    }

    setState((prevState: any) => ({ ...prevState, [name]: newValue }));

    if (newValue) {
      setErrors((prevState: any) => ({
        ...prevState,
        [name]: "",
      }));
    } else {
      setErrors((prevState: any) => ({
        ...prevState,
        [name]: errorMessages[name],
      }));
    }
  };

  const debouncedGetGroupNamesList = useCallback(
    debounce((search: string) => {
      getGroupNamesList(search);
    }, 300),
    []
  );

  const handleAutoCompleteInputChange = (
    e: any,
    newInputValue: any,
    name: string
  ) => {
    if (newInputValue === "") {
      return false;
    }

    setState((prevState: any) => {
      const newState = { ...prevState, [name]: newInputValue };
      if (newInputValue) {
        newState[name] = newInputValue;
      }

      if (
        name === "group_input_value" &&
        billingGroupNameOptions?.total > billingGroupNameOptions?.rows?.length
      ) {
        setIsGroupNameLoading(true);
        debouncedGetGroupNamesList(newInputValue);
      }
      return newState;
    });
  };

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div style={{ overflow: "hidden" }}>
          <ItemsAdd
            errors={errors}
            billingDepartmentOptions={billingDepartmentOptions}
            billingGroupNameOptions={billingGroupNameOptions}
            billingConsultationOptions={billingConsultationOptions}
            billingItemsOptions={billingItemsOptions}
            state={state}
            setState={setState}
            isEdit={!!id}
            isGroupNameLoading={isGroupNameLoading}
            handleChange={handleChange}
            setBillingGroupNameOptions={setBillingGroupNameOptions}
            handleAutoCompleteChange={handleAutoCompleteChange}
            handleAutoCompleteInputChange={handleAutoCompleteInputChange}
          />
          {state.is_lab_test === 1 && state.type === "IND" && (
            <Box
              mt={4}
              sx={{
                display:
                  state.is_lab_test === 1 && state.type === "IND"
                    ? "flex"
                    : "none",
                overflow: "auto",
              }}
            >
              <ItemsListMemoized
                errors={errors}
                isSubTestAddedOrDeleted={isSubTestAddedOrDeleted}
                items={items}
                handleClickRange={handleClickRange}
                handleDeleteItem={handleDeleteItem}
                handleAddSubTest={handleAddSubTest}
                handleAddItem={handleAddItem}
                handleChangeRowData={handleChangeRowData}
              />
            </Box>
          )}
          <Box mt={5}>
            <SaveButton
              buttonText={displayText.SAVE}
              sx={{ width: "100px", height: "40px", mr: "20px" }}
              loading={isSaveLoading}
              handleClick={handleSave}
            />
            <CancelButton
              isDisabled={isSaveLoading}
              buttonText={displayText.CANCEL}
              sx={{ width: "100px", height: "40px" }}
              handleClick={() =>
                navigate(`${RouteUrls.billingUrl}${RouteUrls.billingItemsUrl}`)
              }
            />
          </Box>
          {isOpenConfirmDelete?.isOpen && (
            <ConfirmationDialog
              open={isOpenConfirmDelete?.isOpen}
              title="Are you surely want to delete?"
              handleClick={() => handleConfirmDelete(isOpenConfirmDelete)}
              onClose={handleCloseDeleteConfirmModal}
              loading={isConfirmationDialogButtonLoading}
            />
          )}
          {rangeModalData?.isOpen && (
            <RangeModal
              isOpen={rangeModalData.isOpen}
              onClose={() => setRangeModalData({ isOpen: false, data: null })}
              data={rangeModalData?.data}
              onSave={handleAddRangeItem}
            />
          )}
        </div>
      )}
    </>
  );
};

export default BillingAddItems;
