import axios, {
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
} from "axios";
import { logout, refreshTokenUpdate } from "../services/authService";
import { getCookie } from "./GeneralUtils";
import store from "../redux/store";
import { setIsRefreshTokenExpired } from "../redux/slices/auth";

interface Credentials {
  access_token: string | null;
  token_type: "Bearer";
  refresh_token: string | null;
}
interface FailedQueueItem {
  resolve?: (value?: string | PromiseLike<string> | null) => void;
  reject?: (reason?: any) => void;
}
interface InternalAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
  headers?: any;
}

const getToken = (): {
  access_token: string | null;
  token_type: string;
  refresh_token: string | null;
} => {
  const localStorageAccessToken: any = getCookie("accessToken");
  const localStorageRefreshToken: any = getCookie("refreshToken");
  const accessToken = localStorageAccessToken ?? null;
  const refreshToken = localStorageRefreshToken ?? null;
  if (accessToken != null && refreshToken != null) {
    return {
      access_token: JSON.parse(accessToken),
      token_type: "Bearer",
      refresh_token: JSON.parse(refreshToken),
    };
  } else {
    return {
      access_token: null,
      token_type: "Bearer",
      refresh_token: null,
    };
  }
};

// Function to refresh the token
const getRefreshToken = async (): Promise<any> => {
  try {
    const storedRefreshToken: any = getCookie("refreshToken");
    if (!storedRefreshToken) {
      return null;
    }
    const response = await refreshTokenUpdate({
      refresh_token: JSON.parse(storedRefreshToken),
    });
    if (!response.data.access_token) {
      return null;
    }
    return response.data.access_token;
  } catch (error) {
    throw error;
  }
};

let isRefreshing = false;
let failedQueue: FailedQueueItem[] = [];

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config as InternalAxiosRequestConfig;
    if (error?.response?.status === 401 && !originalRequest?._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newAccessToken = await getRefreshToken();
          if (newAccessToken) {
            processQueue(null, newAccessToken);
            return await retryOriginalRequest(originalRequest, newAccessToken);
          }
          return Promise.reject(new Error("Error refreshing token"));
        } catch (refreshError) {
          store.dispatch(setIsRefreshTokenExpired(true));
          logout();
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      } else {
        return retryFailedRequest(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

const retryOriginalRequest = async (
  requestConfig: InternalAxiosRequestConfig,
  token: string
): Promise<any> => {
  requestConfig.headers.Authorization = `Bearer ${token}`;
  requestConfig._retry = true;
  return axios(requestConfig);
};

const retryFailedRequest = (
  originalRequest: InternalAxiosRequestConfig
): Promise<any> => {
  return new Promise((resolve, reject) => {
    failedQueue.push({
      resolve: (token: string | null | any) => {
        if (token) {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        } else {
          reject(new Error("Token refresh failed"));
        }
      },
      reject: (error: any) => {
        reject(error);
      },
    });
  });
};

const processQueue = (
  error: AxiosError | null,
  token: string | null = null
): void => {
  failedQueue.forEach((prom: any) => {
    if (error !== null && error !== undefined) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const apiUtils = {
  getWithoutToken: async (url: string) =>
    await new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        })
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    }),
  getWithToken: async (url: string) =>
    await new Promise((resolve, reject) => {
      const credentials = getToken() as Credentials;

      if (
        credentials?.access_token != null &&
        credentials?.token_type?.length > 0 &&
        credentials?.token_type?.length > 0
      ) {
        axios
          .get(url, {
            headers: {
              authorization: `Bearer ${credentials.access_token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response: AxiosResponse) => {
            resolve(response.data);
          })
          .catch((error: AxiosError) => {
            reject(error);
          });
      } else {
        reject(new Error("token is not valid"));
      }
    }),

  getExcelFile: async (url: string) =>
    await new Promise((resolve, reject) => {
      const credentials = getToken() as Credentials;
      if (
        credentials?.access_token != null &&
        credentials?.token_type.length > 0 &&
        credentials?.token_type.length > 0
      )
        axios
          .get(url, {
            headers: {
              authorization: `Bearer ${credentials.access_token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            responseType: "arraybuffer",
          })
          .then((response: AxiosResponse) => {
            resolve(response);
          })
          .catch((error: AxiosError) => {
            reject(error);
          });
    }),

  postWithoutToken: async (url: string, body?: unknown) =>
    await new Promise((resolve, reject) => {
      axios
        .post(url, body, {
          headers: {
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    }),

  postWithToken: async (url: string, body?: unknown) =>
    await new Promise((resolve, reject) => {
      const credentials = getToken() as Credentials;

      if (
        credentials?.access_token != null &&
        credentials?.token_type?.length > 0 &&
        credentials?.token_type?.length > 0
      ) {
        axios
          .post(url, body, {
            headers: {
              authorization: `Bearer ${credentials.access_token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response: AxiosResponse) => {
            resolve(response.data);
          })
          .catch((error: AxiosError) => {
            reject(error);
          });
      } else {
        reject(new Error("token is not valid"));
      }
    }),

  putWithToken: async (url: string, body?: unknown) =>
    await new Promise((resolve, reject) => {
      const credentials = getToken() as Credentials;

      if (
        credentials?.access_token != null &&
        credentials?.token_type?.length > 0 &&
        credentials?.token_type?.length > 0
      ) {
        axios
          .put(url, body, {
            headers: {
              authorization: `Bearer ${credentials.access_token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response: AxiosResponse) => {
            resolve(response.data);
          })
          .catch((error: AxiosError) => {
            reject(error);
          });
      } else {
        reject(new Error("token is not valid"));
      }
    }),

  putWithoutToken: async (url: string, body?: unknown) =>
    await new Promise((resolve, reject) => {
      axios
        .put(url, body, {
          headers: {
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        })
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    }),
  patchWithToken: async (url: string, body?: unknown) =>
    await new Promise((resolve, reject) => {
      const credentials = getToken() as Credentials;

      if (
        credentials?.access_token != null &&
        credentials?.token_type?.length > 0 &&
        credentials?.token_type?.length > 0
      ) {
        axios
          .patch(url, body, {
            headers: {
              authorization: `Bearer ${credentials.access_token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response: AxiosResponse) => {
            resolve(response.data);
          })
          .catch((error: AxiosError) => {
            reject(error);
          });
      } else {
        reject(new Error("token is not valid"));
      }
    }),

  deleteWithToken: async (url: string, body?: unknown) =>
    await new Promise((resolve, reject) => {
      const credentials = getToken() as Credentials;

      if (
        credentials?.access_token != null &&
        credentials?.token_type?.length > 0 &&
        credentials?.token_type?.length > 0
      ) {
        axios
          .delete(url, {
            data: body,
            headers: {
              authorization: `Bearer ${credentials.access_token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response: AxiosResponse) => {
            resolve(response.data);
          })
          .catch((error: AxiosError) => {
            reject(error);
          });
      } else {
        reject(new Error("token is not valid"));
      }
    }),
};

export default apiUtils;
