import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography, debounce } from "@mui/material";
import CustomFilter from "../../../components/shared/customFilter";
import { InfiniteScroller } from "../../../components/basic";
import { getAllPatientsList } from "../../../services/admissionService";
import { DatePicker, Sort, TextField } from "../../../components/basic";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../../../redux/slices/pagination";
import { DataTable } from "../../../components/shared";
import { RootState } from "../../../redux/store";
import { commonDateFormatter } from "../../../utils/DateTimeFormatUtils";
import { ViewIcon, EditIcon, ReportIcon } from "../../../assets/icons";
import { RouteUrls } from "../../../constants/routes";
import { useNavigate } from "react-router-dom";
import { getAllLabBills } from "../../../services/laboratoryService";
import { handleFilterCounts } from "../../../utils/GeneralUtils";
import PermissionUtils from "../../../utils/PermissionUtils";

type GridRowData = Record<string, unknown>;
const { laboratoryUrl, labBillsUrl, createUrl, viewUrl } = RouteUrls;

const LabBills = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { can } = PermissionUtils();

  const { labBills } = useSelector((state: RootState) => state.pagination);
  const [search, setSearch] = useState<string>("");
  const patientsPagination: any = useRef(1);
  const searchPatientsPagination = useRef({ search: "", page: 1 });
  const [patientsList, setPatientsList] = useState<any>([]);
  const [filterData, setFilterData] = useState({
    patient_id: "",
    bill_no: "",
    from_date: new Date(),
    to_date: new Date(),
  });
  const [filterDataChanged, setFilterDataChanged] = useState({
    patient_id: false,
    bill_no: false,
    from_date: false,
    to_date: false,
  });
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [labBillList, setLabBillList] = useState({ rows: [], pageCount: 0 });
  const [activeSort, setActiveSort] = useState({ field: "", order: "" });
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [isClearFilter, setIsClearFilter] = useState(false);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "search") {
      setSearch(value);
    } else {
      setFilterData((prevState: any) => {
        const newFilter = { ...prevState, [name]: value };

        handleFilterCounts(
          newFilter,
          name,
          setFilterDataChanged,
          setAppliedFilterCount
        );

        return newFilter;
      });
    }
    dispatch(setCurrentPage({ key: "labBills", value: 0 }));
  };

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      setFilterData((prevState: any) => {
        const newFilter = { ...prevState, [name]: newValue.validatedValue[0] };
        handleFilterCounts(
          newFilter,
          name,
          setFilterDataChanged,
          setAppliedFilterCount
        );
        return newFilter;
      });
      dispatch(setCurrentPage({ key: "labBills", value: 0 }));
    }
  };

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (isFieldSort) {
      setActiveSort({ field: field, order: "ASC" });
    }
    if (!isFieldSort) {
      setActiveSort({ field: field, order: "DESC" });
    }
  };

  const getAllLabBillsData = (data: any) => {
    setLoading(true);
    getAllLabBills(data)
      .then((res: any) => {
        if (res.data) {
          setLabBillList({
            rows: res?.data.result,
            pageCount: res?.data?.total,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const debouncedGetAllPatientsList = debounce(
    async (currentValue, prevOptions, callback) => {
      try {
        return await getAllPatientsList({
          search: currentValue,
          page:
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page
              : 1,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;
          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );
          setPatientsList(uniqueOptions);
          searchPatientsPagination.current.page =
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page + 1
              : 1;
          searchPatientsPagination.current.search = currentValue;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
      } catch (error) {
        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
      }
    },
    300
  );

  const getAllPatientsData = async (currentValue?: any, prevOptions?: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllPatientsList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page =
          patientsPagination.current === Math.ceil(prevOptions.length / 10)
            ? patientsPagination.current
            : Math.ceil(prevOptions.length / 10) + 1;
        return await getAllPatientsList({
          search: currentValue,
          page: page,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setPatientsList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }
          const currentValue = patientsPagination.current;
          patientsPagination.current = currentValue + 1;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const debouncedGetLabBillData = useCallback(
    debounce((data) => {
      getAllLabBillsData(data);
    }, 300),
    []
  );

  const clearFilters = () => {
    if (
      filterData.from_date ||
      filterData.to_date ||
      filterData.bill_no ||
      filterData.patient_id
    ) {
      setFilterData({
        ...filterData,
        patient_id: "",
        bill_no: "",
        from_date: new Date(),
        to_date: new Date(),
      });
      setFilterDataChanged({
        ...filterDataChanged,
        patient_id: false,
        bill_no: false,
        from_date: false,
        to_date: false,
      });
      setAppliedFilterCount(0);
      setIsClearFilter(true);
    }
  };

  const filters = [
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 1.7, xl: 1.7 },
      children: (
        <InfiniteScroller
          loadOptions={getAllPatientsData}
          options={patientsList}
          handleOnChange={handleInputChange}
          isClearFilter={isClearFilter}
          setIsClearFilter={setIsClearFilter}
          name={"patient_id"}
          label={"Select Patient"}
          value={filterData.patient_id}
          width="100%"
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 1.7, xl: 1.7 },
      children: (
        <TextField
          value={filterData.bill_no}
          placeholder={"Bill No"}
          label={"Bill No"}
          formControlStyle={{ width: "100%" }}
          sx={{
            "& fieldset": {},
            width: "100%",
          }}
          onChange={handleInputChange}
          name={"bill_no"}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 1.7, xl: 1.7 },
      children: (
        <DatePicker
          label="From Date"
          name="fromDate"
          placeholder="Select Date"
          width="95%"
          onChange={(e: any, newValue: any) =>
            handleDateChange(e, newValue, "from_date")
          }
          formControlStyle={{
            width: "90%",
            "& .icon-tabler": {
              left: "95%",
              color: "rgba(0, 0, 0, 0.54)",
            },
          }}
          format="DD/MM/YYYY"
          value={filterData.from_date}
          maxDate={new Date()}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 1.7, xl: 1.7 },
      children: (
        <DatePicker
          label="To Date"
          name="toDate"
          placeholder="Select Date"
          width="95%"
          onChange={(e: any, newValue: any) =>
            handleDateChange(e, newValue, "to_date")
          }
          formControlStyle={{
            width: "90%",
            "& .icon-tabler": {
              left: "95%",
              color: "rgba(0, 0, 0, 0.54)",
            },
          }}
          format="DD/MM/YYYY"
          maxDate={new Date()}
          value={filterData.to_date}
        />
      ),
    },
  ];

  const TableActions = (props: any) => {
    const { row } = props;

    return (
      <Box>
        {!row.report_id && can("labreport_add") ? (
          <Box
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={() =>
              navigate(`${laboratoryUrl}${labBillsUrl}${createUrl}/${row.id}`)
            }
          >
            <ReportIcon />
          </Box>
        ) : (
          <>
            {can("labreport_edit") ||
              (can("labreport_view") && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {can("labreport_edit") && (
                    <Box
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                      onClick={() =>
                        navigate(`${laboratoryUrl}${labBillsUrl}/${row.id}`)
                      }
                    >
                      <EditIcon />
                    </Box>
                  )}
                  {can("labreport_view") && (
                    <Box
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        navigate(
                          `${laboratoryUrl}${labBillsUrl}${viewUrl}/${row.id}`
                        )
                      }
                    >
                      <ViewIcon />
                    </Box>
                  )}
                </Box>
              ))}
          </>
        )}
      </Box>
    );
  };

  const columns = [
    {
      field: "billNo",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("bill_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Bill No
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "bill_no" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "bill_no" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.bill_no}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "patient's uhid",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("uhid")}
        >
          <Typography variant="h5" fontSize={14}>
            Patient's UHID
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "uhid" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "uhid" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.uhid}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "patient's name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Patient's Name
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "name" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "name" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "visit no",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("raw_op_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Visit No
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "raw_op_no" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "raw_op_no" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.raw_op_no ? row.raw_op_no : row.raw_ip_no}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "visit date",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("appoint_date")}
        >
          <Typography variant="h5" fontSize={14}>
            Visit Date
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "appoint_date" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "appoint_date" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.doa}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "doctor",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("doctor_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Doctor
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "doctor_name" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "doctor_name" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.doctor_name}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "mobile",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("mobile")}
        >
          <Typography variant="h5" fontSize={14}>
            Mobile
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "mobile" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "mobile" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.mobile}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "bill date",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("date_created")}
        >
          <Typography variant="h5" fontSize={14}>
            Bill Date
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "date_created" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "date_created" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.date_created}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "Created by",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("created_user")}
        >
          <Typography variant="h5" fontSize={14}>
            Created By
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "created_user" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "created_user" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.created_user}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    ...(can("labreport_add") || can("labreport_edit") || can("labreport_view")
      ? [
          {
            field: "Action",
            flex: 1,
            cellClassName: "name-column--cell",
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Action
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => <TableActions row={row} />,
            minWidth: 100,
            sortable: false,
          },
        ]
      : []),
  ];

  useEffect(() => {
    const data = {
      ...filterData,
      from_date: filterData.from_date
        ? commonDateFormatter(filterData.from_date, "YYYY-MM-DD")
        : null,
      to_date: filterData.to_date
        ? commonDateFormatter(filterData.to_date, "YYYY-MM-DD")
        : null,
      search,
      column: activeSort.field,
      order: activeSort.order,
      patientId: filterData.patient_id,
      page: labBills.page,
      limit: labBills.pageSize,
    };
    debouncedGetLabBillData(data);
  }, [filterData, search, activeSort, labBills]);

  return (
    <Box>
      <CustomFilter
        data={filters}
        isFilterEnabled
        isSearchEnabled={true}
        searchOnChange={handleInputChange}
        searchName={"search"}
        clearSearch={() => setSearch("")}
        clearFilter={clearFilters}
        searchValue={search}
        appliedFilterCount={appliedFilterCount}
      />
      <DataTable
        loading={isLoading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={labBillList.rows}
        pageCount={labBillList.pageCount}
        currentPage={"labBills"}
      />
    </Box>
  );
};

export default LabBills;
