import React, { useEffect } from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { DeleteIcon, EditIcon, ViewIcon } from "../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { Sort, TextField } from "../../components/basic";
import { DataTable } from "../../components/shared";
import { setAppBarTitle } from "../../redux/slices/layout";
import { AppDispatch } from "../../redux/store";
import CustomFilter from "../../components/shared/customFilter";
import { useParams } from "react-router-dom";
import {
  UserPatientsTypes,
  getAllUserPatientsList,
  getPatientUser,
  updatePatientUser,
} from "../../services/mainCoreService";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../redux/slices/snackbar";
import { AddPatientDialog } from "../../components/shared";

type GridRowData = Record<string, unknown>;
export type PatientsUserTypes = {
  active: number;
  alt_mobile: string;
  area: string;
  device_tokens: null | string | any;
  district: string;
  email: string;
  id: number | string;
  language: string;
  last_name: string;
  line_1: string;
  line_2: string;
  mobile: string;
  name: string;
  paid: number | string;
  paid_date: string;
  pincode: string;
  state: string;
  title: string;
};
const EditUser = () => {
  const patientUserDetails: PatientsUserTypes = {
    active: 0,
    alt_mobile: "",
    area: "",
    device_tokens: "",
    district: "",
    email: "",
    id: "",
    language: "",
    last_name: "",
    line_1: "",
    line_2: "",
    mobile: "",
    name: "",
    paid: "",
    paid_date: "",
    pincode: "",
    state: "",
    title: "",
  };
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const [patientUserDetail, setPatientUserDetail] =
    useState<PatientsUserTypes>(patientUserDetails);
  const [userPatientsList, setUserPatientsList] = useState<UserPatientsTypes[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(setAppBarTitle("Edit User Account"));
  }, [dispatch]);

  const [isPatientDialogOpen, setIsPatientDialogOpen] = useState(false);
  const [patientId, setPatientId] = useState<null | number | string>();
  const handlePatientDialog = () => {
    setIsPatientDialogOpen(false);
    setPatientId(null);
  };

  type InputValuesTypes = {
    consultation?: number;
    patientName?: number;
  };
  const [inputValues, setInputValues] = useState<InputValuesTypes>({
    consultation: undefined,
    patientName: undefined,
  });
  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        <div style={{ cursor: "pointer" }}>
          <EditIcon
            onClick={() => {
              setIsPatientDialogOpen(true);
              setPatientId(row.id);
            }}
          />
        </div>
        <div style={{ cursor: "pointer" }}>
          <ViewIcon />
        </div>
        <div style={{ cursor: "pointer" }}>
          <DeleteIcon />
        </div>
      </div>
    );
  };
  const columns: GridColDef[] = [
    {
      field: "UHID",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            UHID
          </Typography>
          <Sort />
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.patient_uhid}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },

    {
      field: "Patient’s",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Patient’s Name
          </Typography>
          <Sort />
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {`${row.title}.${row.name}`}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },

    {
      field: "mobile",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Mobile No
          </Typography>
          <Sort />
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.mobile}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },

    {
      field: "dateOfBirth",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Date of Birth
          </Typography>
          <Sort />
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.age}
        </Typography>
      ),
      minWidth: 160,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "44px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  const data = [
    {
      id: 1,
      patient: "demoi",
      mobile: "1234567890",
      dateOfBirth: "02/12/2023",
      buttons: {
        buttonText: "cancelled",
        backgroundColor: "#FDE6EB",
        textColor: "#AC054A",
      },
    },
    {
      id: 2,
      patient: "demoi",
      mobile: "1234567890",
      dateOfBirth: "02/12/2023",
      buttons: {
        buttonText: "cancelled",
        backgroundColor: "#FDE6EB",
        textColor: "#AC054A",
      },
    },
    {
      id: 3,
      patient: "demoi",
      mobile: "1234567890",
      dateOfBirth: "02/12/2023",
      buttons: {
        buttonText: "cancelled",
        backgroundColor: "#FDE6EB",
        textColor: "#AC054A",
      },
    },
    {
      id: 4,
      patient: "demoi",
      mobile: "1234567890",
      dateOfBirth: "02/12/2023",
      buttons: {
        buttonText: "cancelled",
        backgroundColor: "#FDE6EB",
        textColor: "#AC054A",
      },
    },
    {
      id: 5,
      patient: "demoi",
      mobile: "1234567890",
      dateOfBirth: "02/12/2023",
      buttons: {
        buttonText: "cancelled",
        backgroundColor: "#FDE6EB",
        textColor: "#AC054A",
      },
    },
  ];

  const clearFilters = () => {
    setInputValues({
      consultation: undefined,
      patientName: undefined,
    });
  };
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setPatientUserDetail((prevFilterData: any) => ({
      ...prevFilterData,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    setLoading(true);
    const body = {
      // orig_paid: 0,
      title: patientUserDetail.title,
      name: patientUserDetail.name,
      last_name: patientUserDetail.last_name,
      email: patientUserDetail.email,
      mobile: patientUserDetail.mobile,
      alt_mobile: patientUserDetail.alt_mobile,
      language: patientUserDetail.language,
      line_1: patientUserDetail.line_1,
      line_2: patientUserDetail.line_2,
      pincode: patientUserDetail.pincode,
      area: patientUserDetail.area,
      district: patientUserDetail.district,
      state: patientUserDetail.state,
      // paid: 0,
    };
    updatePatientUser(id, body)
      .then((res) => {
        setPatientUserDetail(res.data as PatientsUserTypes);
        dispatch(
          setSnackBarSuccess({ snackBarMessage: "User Updated Successfully" })
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackBarFailed({ snackBarMessage: `User Update Error:${err}` })
        );
        setLoading(false);
      });
  };
  useEffect(() => {
    getPatientUser(id)
      .then((res) => {
        setPatientUserDetail(res.data as PatientsUserTypes);
      })
      .catch((err) => {
        console.log(err);
      });
    getAllUserPatientsList({
      userId: id,
    })
      .then((res) => {
        setUserPatientsList(res.data.result as UserPatientsTypes[]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <Typography variant="h2" component="div" gutterBottom sx={{ mb: 5 }}>
        Account Information
      </Typography>
      <Paper
        elevation={1}
        style={{
          padding: "20px",
          border: "1px solid #efefef",
          borderRadius: "5px",
        }}
      >
        <form>
          <Grid direction="column">
            <Grid container direction="row">
              <Grid
                container
                direction="row"
                alignItems="center"
                md={4}
                spacing={2}
              >
                <Grid item md={3.5}>
                  <Typography sx={{ fontWeight: "600", fontSize: "13px" }}>
                    First Name
                  </Typography>
                </Grid>
                <Grid item md={5}>
                  <TextField
                    name="name"
                    width="250px"
                    value={patientUserDetail.name}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                md={4}
                spacing={2}
              >
                <Grid item md={3.5}>
                  <Typography sx={{ fontWeight: "600", fontSize: "13px" }}>
                    Last Name
                  </Typography>
                </Grid>
                <Grid item md={5}>
                  <TextField
                    name="last_name"
                    width="250px"
                    value={patientUserDetail.last_name}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                md={4}
                spacing={2}
              >
                <Grid item md={3.5}>
                  <Typography sx={{ fontWeight: "600", fontSize: "13px" }}>
                    Email
                  </Typography>
                </Grid>
                <Grid item md={5}>
                  <TextField
                    name="email"
                    width="250px"
                    value={patientUserDetail.email}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid direction="column" mt={3}>
            <Grid container direction="row">
              <Grid
                container
                direction="row"
                alignItems="center"
                md={4}
                spacing={2}
              >
                <Grid item md={3.5}>
                  <Typography sx={{ fontWeight: "600", fontSize: "13px" }}>
                    Mobile Number
                  </Typography>
                </Grid>
                <Grid item md={5}>
                  <TextField
                    name="mobile"
                    width="250px"
                    value={patientUserDetail.mobile}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                md={4}
                spacing={2}
              >
                <Grid item md={3.5}>
                  <Typography sx={{ fontWeight: "600", fontSize: "13px" }}>
                    Alt Mobile
                  </Typography>
                </Grid>
                <Grid item md={5}>
                  <TextField
                    name="alt_mobile"
                    width="250px"
                    value={patientUserDetail.alt_mobile}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                md={4}
                spacing={2}
              >
                <Grid item md={3.5}>
                  <Typography sx={{ fontWeight: "600", fontSize: "13px" }}>
                    Language
                  </Typography>
                </Grid>
                <Grid item md={5}>
                  <TextField
                    name="language"
                    width="250px"
                    value={patientUserDetail.language}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid direction="column" mt={3}>
            <Grid container direction="row">
              <Grid
                container
                direction="row"
                alignItems="center"
                md={4}
                spacing={2}
              >
                <Grid item md={3.5}>
                  <Typography sx={{ fontWeight: "600", fontSize: "13px" }}>
                    Address
                  </Typography>
                </Grid>
                <Grid item md={5}>
                  <TextField
                    name="line_1"
                    width="250px"
                    value={patientUserDetail.line_1}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" md={1.2}>
                <Grid item md={10}>
                  <Typography sx={{ fontWeight: "600", fontSize: "13px" }}>
                    Pincode
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center" md={1.6}>
                <Grid item md={10}>
                  <TextField
                    name="pincode"
                    width="142px"
                    value={patientUserDetail.pincode}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center" md={1.6}>
                <Grid item md={10}>
                  <TextField
                    name="area"
                    width="142px"
                    value={patientUserDetail.area}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center" md={1.6}>
                <Grid item md={10}>
                  <TextField
                    name="district"
                    width="142px"
                    value={patientUserDetail.district}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center" md={1.6}>
                <Grid item md={10}>
                  <TextField
                    name="state"
                    width="142px"
                    value={patientUserDetail.state}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid item xs={1} mt={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  fullWidth
                  style={{ height: "45px" }}
                  disabled={loading}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Box sx={{ mt: 3 }}>
        <CustomFilter
          isFilterEnabled={true}
          isSearchEnabled={true}
          editButtonTitle="+ Add"
          clearFilter={clearFilters}
          onUpdateButtonClick={() => setIsPatientDialogOpen(true)}
        />
        <DataTable
          title="Patient Information"
          columns={columns}
          getRowId={(row: GridRowData) => `${String(row.id)}`}
          rows={userPatientsList}
          pageCount={data.length}
          currentPage={"dashboard"}
        />
      </Box>
      <AddPatientDialog
        open={isPatientDialogOpen}
        onClose={handlePatientDialog}
        patientId={patientId}
      />
    </div>
  );
};

export default EditUser;
