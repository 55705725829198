import { useCallback, useEffect, useState } from "react";
import { Typography, Box, IconButton, debounce } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ConfirmationDialog, DataTable } from "../../../components/shared";
import { Sort } from "../../../components/basic";
import { DeleteIcon, EditIcon, PlusIcon, ViewIcon } from "../../../assets/icons";
import CustomFilter from "../../../components/shared/customFilter";
import {
  deletePurchaseOrderById,
  getAllPurchaseOrders,
} from "../../../services/procurementService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import {
  errorMessage,
  indentStatusBackgroundColors,
} from "../../../constants/displayText";
import {
  setHandleClearSearch,
  setFilterDataValues,
  setSortField,
  setCurrentPage,
} from "../../../redux/slices/pagination";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "../../../constants/routes";
import { Can } from "../../../utils/PermissionUtils";
const { procurementUrl, purchaseOrdersUrl, viewUrl, overviewUrl } = RouteUrls;

type GridRowData = Record<string, unknown>;

const PurchaseOrdersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [purchaseOrdersList, setPurchaseOrdersList] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const { pageInfo, filterData, count, sortField } = useSelector(
    (state: RootState) => ({
      pageInfo: state?.pagination?.PurchaseOrders,
      filterData: state?.pagination?.PurchaseOrders?.filterData,
      count: state?.pagination?.PurchaseOrders?.count,
      sortField: state?.pagination?.PurchaseOrders?.sortedField,
    })
  );
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState<{
    isOpen: boolean;
    id: null | string | number;
  }>({ isOpen: false, id: null });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const { commonVariables } = useSelector(
    (state: RootState) => state?.appConfiguration
  );

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortField?.field === field) {
      dispatch(
        setSortField({
          key: "PurchaseOrders",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
    if (sortField?.field !== field) {
      dispatch(
        setSortField({
          key: "PurchaseOrders",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
  };

  const handleConfirmDelete = () => {
    if (isOpenConfirmationModal.id) {
      setIsDeleteLoading(true);
      deletePurchaseOrderById(isOpenConfirmationModal.id)
        .then((res: any) => {
          if (res) {
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            setIsDeleteLoading(false);
            setIsOpenConfirmationModal({ isOpen: false, id: null });
            const data = {
              page: Number(pageInfo.page) + 1,
              pageSize: pageInfo?.pageSize,
              sortBy: sortField?.field,
              sortOrder: sortField?.order,
              searchValue: filterData?.search,
            };
            getPurchaseOrdersList(data);
          }
        })
        .catch((err: any) => {
          setIsDeleteLoading(false);
          console.log("err", err);
          if (err?.response?.data?.errors) {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.response?.data?.errors,
              })
            );
          }
        });
    }
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {Can("purchase_order_view") && (
          <IconButton
            aria-label=""
            onClick={() =>
              navigate(
                `${procurementUrl}${purchaseOrdersUrl}${viewUrl}/${row.id}`
              )
            }
          >
            <ViewIcon />
          </IconButton>
        )}
        {Can("purchase_order_delete") && (
          <IconButton
            aria-label=""
            onClick={() =>
              setIsOpenConfirmationModal({ isOpen: true, id: row.id })
            }
          >
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    );
  };

  const getStatus = (id: number) => {
    let status = "";
    if (id) {
      status =
        commonVariables?.purchase_order_status?.find(
          (ele: any) => ele.id === id
        )?.value || "";
    }
    return status;
  };
  const columns: GridColDef[] = [
    {
      field: "sequence_no",
      flex: 0.8,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("sequence_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Po.No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "sequence_no" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "sequence_no" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.sequence_no}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },

    {
      field: "created_at",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("created_at")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "created_at" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "created_at" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {moment(row.created_at).format("YYYY-MM-DD hh:mm:ss A")}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "supplier_name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("supplier_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Supplier
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "supplier_name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "supplier_name" &&
              sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.supplier_name}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "created_user",
      flex: 0.8,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("created_user")}
        >
          <Typography variant="h5" fontSize={14}>
            User
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "created_user" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "created_user" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.created_user}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "status",
      flex: 0.5,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("status")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "status" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "status" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h5"
          fontSize={14}
          sx={{
            padding: "6px",
            borderRadius: "5px",
            minWidth: "80px",
            textAlign: "center",
            background: row?.status
              ? indentStatusBackgroundColors[getStatus(row?.status?.toString())]
                  ?.background
              : "none",
            color: row?.status
              ? indentStatusBackgroundColors[getStatus(row?.status?.toString())]
                  ?.color
              : "none",
          }}
        >
          {getStatus(row?.status?.toString())}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    ...(Can("purchase_order_view") || Can("purchase_order_delete")
      ? [
          {
            field: "actions",
            flex: 0.5,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];
  // const purchaseOrdersList = [
  //   {
  //     id: 1,
  //     poNo: "001",
  //     date: "12-04-2023",
  //     supplier: "Glen",
  //     user: "Kane",
  //     status: "Success",
  //   },
  // ];

  const getPurchaseOrdersList = async (data: any) => {
    setLoading(true);

    await getAllPurchaseOrders(data)
      .then((res) => {
        setPurchaseOrdersList(res.data.result as []);
        setPageCount(res.data.total as any);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(
          setSnackBarFailed({
            snackBarMessage: `${errorMessage.ERROR_FETCHING_PURCHASE_ORDERS}`,
          })
        );
        setLoading(false);
      });
  };

  const debouncedGetPurchaseOrdersList = useCallback(
    debounce((data) => {
      getPurchaseOrdersList(data);
    }, 500),
    []
  );

  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo?.pageSize,
      sortBy: sortField?.field,
      sortOrder: sortField?.order,
      searchValue: filterData.search,
    };
    debouncedGetPurchaseOrdersList(data);
  }, [pageInfo, sortField, filterData]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    dispatch(
      setFilterDataValues({ key: "PurchaseOrders", name: name, value: value })
    );
    dispatch(setCurrentPage({ key: "PurchaseOrders", value: 0 }));
  };

  return (
    <Box>
      <CustomFilter
        isSearchEnabled={true}
        searchOnChange={handleInputChange}
        searchName={"search"}
        searchValue={filterData.search}
        clearSearch={() =>
          dispatch(
            setHandleClearSearch({
              key: "PurchaseOrders",
              value: "",
              name: "search",
            })
          )
        }
        {...(Can("purchase_order_add") && {
          editButtonTitle: "Add",
          onUpdateButtonClick: () =>
            navigate(`${procurementUrl}${purchaseOrdersUrl}${overviewUrl}`),
          editStartIcon: (
            <Box
              sx={{
                "& path": {
                  fill: "#fff",
                },
              }}
            >
              <PlusIcon width={"12px"} height={"12px"} />
            </Box>
          ),
        })}
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={purchaseOrdersList}
        pageCount={pageCount}
        currentPage={"PurchaseOrders"}
      />
      <ConfirmationDialog
        open={isOpenConfirmationModal.isOpen}
        title="are you surely want to delete the order?"
        handleClick={handleConfirmDelete}
        onClose={() => setIsOpenConfirmationModal({ isOpen: false, id: null })}
        loading={isDeleteLoading}
        confirmText="Yes"
      />
    </Box>
  );
};

export default PurchaseOrdersList;
