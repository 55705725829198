import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getCookie, removeCookie, decrypt } from "../../utils/GeneralUtils";
import { jwtDecode } from "jwt-decode";

interface AuthState {
  isLoggedIn: boolean;
  authUser: unknown;
  organizationList: { tenants: []; phoneNumber: string | number | null };
  isRefreshTokenExpired: boolean;
}

const loginCheck = (): boolean => {
  const credentials: any = getCookie("accessToken");
  if (credentials) {
    const { exp }: { exp: number } = jwtDecode(JSON.parse(credentials));
    if (Date.now() <= exp * 1000) {
      return true;
    } else {
      removeCookie("accessToken");
      removeCookie("refreshToken");
      return false;
    }
  }
  return false;
};

const getAuthUserDetails = () => {
  const user = localStorage.getItem("userDetails");
  if (!user) {
    return {};
  }

  const decryptedString = decrypt("user_detail_salt", user);

  try {
    return JSON.parse(decryptedString);
  } catch (e) {
    console.error("Failed to parse user details:", e);
    return {};
  }
};

const initialState: AuthState = {
  isLoggedIn: Boolean(loginCheck()),
  authUser: Object(getAuthUserDetails()),
  organizationList: { tenants: [], phoneNumber: "" },
  isRefreshTokenExpired: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, { payload }: PayloadAction<string | null>) => ({
      ...state,
      authUser: payload,
    }),
    setIsLoggedIn: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoggedIn: payload,
    }),
    setOrganizationList: (
      state,
      { payload }: PayloadAction<{ tenants: []; phoneNumber: "" }>
    ) => ({
      ...state,
      organizationList: payload,
    }),
    setIsRefreshTokenExpired: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isRefreshTokenExpired: payload,
    }),
  },
});

export const {
  setAuthUser,
  setIsLoggedIn,
  setOrganizationList,
  setIsRefreshTokenExpired,
} = authSlice.actions;

export default authSlice.reducer;
