import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  debounce,
  IconButton,
  Switch,
  CircularProgress,
} from "@mui/material";
import {
  Label,
  Sort,
  TextField,
  TimePicker,
} from "../../../../components/basic";
import {
  DataTable,
  DialogWrapper,
  ConfirmationDialog,
} from "../../../../components/shared";
import { DeleteIcon, EditIcon, PlusIcon } from "../../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { appointmentsMasterConst } from "../../../../constants/displayText";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import CustomFilter from "../../../../components/shared/customFilter";
import {
  getConsultationsList,
  createConsultation,
  editConsultation,
  updateConsultation,
  deleteConsultation,
} from "../../../../services/appointmentsMasterService";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import {
  timeFormat,
  convertTimeToDate,
  convertDateToTime,
} from "../../../../utils/DateTimeFormatUtils";
import { setCurrentPage } from "../../../../redux/slices/pagination";

const ConsultationType = () => {
  const {
    NAME,
    START_TIME,
    END_TIME,
    STATUS,
    ADDED_SUCCESSFULLY,
    UPDATED_SUCCESSFULLY,
    DELETED_SUCCESSFULLY,
  } = appointmentsMasterConst;

  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [idToBeUpdated, setIdToBeUpdated] = useState<any>(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [consultationToBeDeleted, setConsultationToBeDeleted] =
    useState<any>(null);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [searchValue, setSearchValue] = useState<any>("");

  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });

  const pageInfo = useSelector(
    (state: RootState) => state?.pagination?.appointmentMasterConsultationType
  );

  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);

  const initialConsultationError = {
    name: "",
    start: "",
    end: "",
    active: "",
  };
  const [consultationError, setConsultationError] = useState<any>(
    initialConsultationError
  );

  const initialData = {
    name: "",
    start: null,
    end: null,
    active: true,
  };

  const [consultationFormData, setConsultationFormData] = useState<
    string | Date | any
  >(initialData);

  const fieldData: any = {
    name: {
      label: "Name",
      name: "name",
      value: consultationFormData.name,
      ref: useRef(null),
      isError: Boolean(consultationError.name),
      helperText: consultationError.name,
    },
    start: {
      label: "Start Time",
      name: "start",
      value: consultationFormData.start,
      ref: useRef(null),
      isError: Boolean(consultationError.start),
      helperText: consultationError.start,
    },
    end: {
      label: "End Time",
      name: "end",
      value: consultationFormData.end,
      ref: useRef(null),
      isError: Boolean(consultationError.end),
      helperText: consultationError.end,
    },
    active: {
      label: "Status",
      name: "active",
      value: consultationFormData.active,
      isError: Boolean(consultationError.active),
      helperText: consultationError.active,
    },
  };

  const [consultationFieldData, setConsultationFieldData] = useState(fieldData);

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "5px",
        }}
      >
        <div style={{ cursor: "pointer" }}>
          <IconButton onClick={() => consultationEdit(row.id)}>
            <EditIcon />
          </IconButton>
        </div>

        <div style={{ cursor: "pointer" }}>
          <IconButton onClick={() => consultationDelete(row.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <Grid
          onClick={() => handleSorting("name")}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <Label variant="h5" sx={{ color: "primary.main" }} label={NAME} />
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.name == null ? "" : row?.name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "starttime",
      flex: 1,
      renderHeader: () => (
        <Grid
          onClick={() => handleSorting("start")}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <Label
            variant="h5"
            sx={{ color: "primary.main" }}
            label={START_TIME}
          />
          <Sort
            ascendingActive={
              sortedField.field === "start" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "start" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.start == null ? "" : timeFormat(row?.start)}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "endtime",
      flex: 1,
      renderHeader: () => (
        <Grid
          onClick={() => handleSorting("end")}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <Label variant="h5" sx={{ color: "primary.main" }} label={END_TIME} />
          <Sort
            ascendingActive={
              sortedField.field === "end" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "end" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.end == null ? "" : timeFormat(row?.end)}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "status",
      flex: 1,
      renderHeader: () => (
        <Grid
          onClick={() => handleSorting("active")}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <Label variant="h5" sx={{ color: "primary.main" }} label={STATUS} />
          <Sort
            ascendingActive={
              sortedField.field === "active" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "active" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.active == null ? "" : row?.active === 1 ? "Active" : "Inactive"}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  let isFocused = false;
  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "name":
      case "start":
      case "end": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setConsultationError
          );

          if (!isFocused) {
            if (fieldData[name]?.ref?.current) {
              fieldData[name]?.ref?.current?.focus();
              isFocused = true;
            }
          }
        } else {
          updateFormDataWithHelperText(name, "", setConsultationError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateConsultationFieldData = () => {
    setConsultationFieldData((prev: any) => {
      return Object.keys(prev).map((field: any) => {
        return {
          ...field,
          helperText: consultationError[field.name],
          value: consultationFormData[field.name],
          isError: Boolean(consultationError[field.name]),
        };
      });
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setConsultationFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: value,
    }));
    handleValidation(e);
  };

  const handleTimeChange = (newValue: any, name: string) => {
    if (newValue) {
      setConsultationFormData((prev: any) => ({
        ...prev,
        [name]: newValue?.toDate(),
      }));
    } else {
      setConsultationFormData((prev: any) => ({
        ...prev,
        [name]: null,
      }));
    }
    handleValidation({
      target: {
        label: name === "start" ? "Start Time" : "End Time",
        name: name,
        value: !Boolean(newValue) ? null : newValue?.toDate(),
      },
    });
  };

  const handleSwitchChange = async (e: any) => {
    const { name, checked } = e.target;
    setConsultationFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: checked,
    }));
  };

  const handleSearchOnChange = (e: any) => {
    setSearchValue(e.target.value);
    dispatch(
      setCurrentPage({ key: "appointmentMasterConsultationType", value: 0 })
    );
  };

  const getConsultationsDetails = useCallback(
    debounce(async (data) => {
      setLoading(true);

      getConsultationsList(data)
        .then((result: any) => {
          let data = result?.data?.result;
          setProductsList(data);
          setPageCount(result?.data?.total as any);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          console.log("error", err);
        });
    }, 300),
    []
  );

  const consultationCreate = async () => {
    if (
      !Boolean(consultationFormData.name) ||
      !Boolean(consultationFormData.start) ||
      !Boolean(consultationFormData.end)
    ) {
      validateForm();
    } else {
      try {
        const data = {
          name: consultationFormData.name,
          start: convertDateToTime(consultationFormData.start),
          end: convertDateToTime(consultationFormData.end),
          active: consultationFormData.active,
        };

        setButtonLoading(true);

        await createConsultation(data).then((result: any) => {
          dialogClose();
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: ADDED_SUCCESSFULLY,
            })
          );
          getConsultationsDetails({
            page: Number(pageInfo.page) + 1,
            pageSize: Number(pageInfo.pageSize),
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            searchValue: searchValue,
          });
        });
      } catch (error: any) {
        setButtonLoading(false);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.error_data?.end[0],
          })
        );
        console.error("An error occurred:", error);
      }
    }
  };

  const consultationEdit = async (id: number) => {
    try {
      if (!Boolean(id)) {
        return false;
      }

      setOpen(true);
      setIsDialogLoading(true);
      await editConsultation(id)?.then((result: any) => {
        const data = result?.data;
        const FormattedStartTime = timeFormat(data?.start);
        const FormattedEndTime = timeFormat(data?.end);

        setConsultationFormData({
          name: data?.name,
          start: convertTimeToDate(FormattedStartTime),
          end: convertTimeToDate(FormattedEndTime),
          active: data?.active === 1 ? true : false,
        });
        setIdToBeUpdated(id);
        setIsDialogLoading(false);
      });
    } catch (error: any) {
      setOpen(false);
      setIsDialogLoading(false);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.message,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const consultationUpdate = async () => {
    if (
      !Boolean(consultationFormData.name) ||
      !Boolean(consultationFormData.start) ||
      !Boolean(consultationFormData.end)
    ) {
      validateForm();
    } else {
      try {
        if (!Boolean(idToBeUpdated)) {
          return false;
        }
        const data = {
          name: consultationFormData.name,
          start: convertDateToTime(consultationFormData.start),
          end: convertDateToTime(consultationFormData.end),
          active: consultationFormData.active,
        };

        setButtonLoading(true);
        await updateConsultation(idToBeUpdated, data).then((result: any) => {
          dialogClose();
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: UPDATED_SUCCESSFULLY,
            })
          );
          getConsultationsDetails({
            page: Number(pageInfo.page) + 1,
            pageSize: Number(pageInfo.pageSize),
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            searchValue: searchValue,
          });
        });
      } catch (error: any) {
        setButtonLoading(false);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
        console.error("An error occurred:", error);
      }
    }
  };

  const consultationDelete = (symptomId: number) => {
    setIsConfirmationDialogOpen(true);
    setConsultationToBeDeleted(symptomId);
  };

  const handleConfirmDelete = async () => {
    if (!Boolean(consultationToBeDeleted)) {
      return false;
    }
    try {
      setButtonLoading(true);
      await deleteConsultation(consultationToBeDeleted).then((result: any) => {
        dialogClose();
        dispatch(
          setSnackBarSuccess({
            snackBarMessage: DELETED_SUCCESSFULLY,
          })
        );
        getConsultationsDetails({
          page: Number(pageInfo.page) + 1,
          pageSize: pageInfo.pageSize,
          sortBy: sortedField.field,
          sortOrder: sortedField.order,
          searchValue: searchValue,
        });
      });
    } catch (error: any) {
      setButtonLoading(false);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.errors,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const onClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const dialogOpen = async () => {
    setOpen(true);
  };

  const dialogClose = async () => {
    setButtonLoading(false);
    setOpen(false);
    setConsultationFormData(initialData);
    setIsDialogLoading(false);
    setIdToBeUpdated(null);
    setConsultationError(initialConsultationError);
    setConsultationToBeDeleted(null);
    setIsConfirmationDialogOpen(false);
    setIsDialogLoading(false);
  };

  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      pageSize: Number(pageInfo.pageSize),
      sortBy: sortedField.field,
      sortOrder: sortedField.order,
      searchValue: searchValue,
    };
    getConsultationsDetails(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortedField.field,
    sortedField.order,
    searchValue,
  ]);

  useEffect(() => {
    updateConsultationFieldData();
  }, [consultationError, consultationFormData]);

  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          title="are you surely want to delete?"
          handleClick={handleConfirmDelete}
          onClose={onClose}
          loading={buttonLoading}
        />

        <CustomFilter
          isSearchEnabled={true}
          searchValue={searchValue}
          searchOnChange={handleSearchOnChange}
          searchName={"search"}
          clearSearch={() => setSearchValue("")}
          editButtonTitle="Add"
          onUpdateButtonClick={dialogOpen}
          editStartIcon={
            <Box
              sx={{
                "& path": {
                  fill: "#fff",
                },
              }}
            >
              <PlusIcon width={"12px"} height={"12px"} />
            </Box>
          }
        />
        <DataTable
          title="Consultations List"
          loading={loading}
          columns={columns}
          getRowId={(row: any) => `${String(row.id)}`}
          rows={productsList}
          pageCount={pageCount}
          currentPage={"appointmentMasterConsultationType"}
        />
      </Box>

      <DialogWrapper
        onClose={dialogClose}
        open={open}
        handleClick={
          !Boolean(idToBeUpdated) ? consultationCreate : consultationUpdate
        }
        title={
          !Boolean(idToBeUpdated) ? "Add Consultation" : "Edit Consultation"
        }
        maxWidth="sm"
        loading={buttonLoading}
      >
        {isDialogLoading ? (
          <Grid
            sx={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              height: "50vh",
              background: "transparent",
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid sx={{ mb: "10px" }}>
              <TextField
                sx={{ width: { xs: "250px", sm: "527px" } }}
                value={fieldData.name.value}
                inputRef={fieldData?.name?.ref}
                name={fieldData.name.name}
                onChange={handleInputChange}
                label={fieldData.name.label}
                helperText={fieldData.name.helperText}
                onBlur={(e: any) =>
                  handleValidation((e = { target: fieldData.name }))
                }
              />
            </Grid>
            <Grid sx={{ mb: "10px" }}>
              <TimePicker
                format="hh:mm A"
                name={fieldData.start.name}
                value={fieldData.start.value}
                onChange={(newValue: any) =>
                  handleTimeChange(newValue, fieldData.start.name)
                }
                hideSeconds
                label={fieldData.start.label}
                helperText={fieldData.start.helperText}
                onBlur={(e: any) =>
                  handleValidation((e = { target: fieldData.start }))
                }
              />
            </Grid>
            <Grid sx={{ mb: "10px" }}>
              <TimePicker
                format="hh:mm A"
                name={fieldData.end.name}
                value={fieldData.end.value}
                onChange={(newValue: any) =>
                  handleTimeChange(newValue, fieldData.end.name)
                }
                label={fieldData.end.label}
                helperText={fieldData.end.helperText}
                onBlur={(e: any) =>
                  handleValidation((e = { target: fieldData.end }))
                }
              />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: "10px",
              }}
            >
              <Typography>{fieldData.active.label}</Typography>
              <Switch
                name={fieldData.active.name}
                checked={fieldData.active.value}
                onChange={handleSwitchChange}
                disabled={!Boolean(idToBeUpdated) ? true : false}
              />
            </Grid>
          </>
        )}
      </DialogWrapper>
    </div>
  );
};

export default ConsultationType;
