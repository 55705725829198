import { useEffect, useRef, useState } from "react";
import { DialogWrapper } from "../../../../components/shared";
import { displayText } from "../../../../constants/displayText";
import { Grid, InputLabel } from "@mui/material";
import { PageLoader, Switch, TextField } from "../../../../components/basic";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import {
  createCustomerMaster,
  getCustomerMasterById,
  updateCustomerMaster,
} from "../../../../services/procurementMasterService";

type CustomerFormModalProps = {
  id?: number | string | null;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
};
const CustomerFormModal = (props: CustomerFormModalProps) => {
  const { isOpen, id, onClose, onSave } = props;
  const { ADD, EDIT } = displayText;
  const dispatch = useDispatch();
  const [isSaveBtnLoading, setIsSaveBtnLoading] = useState<boolean>(false);
  const [isAddressFocused, setIsAddressFocused] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({
    name: "",
    phone: "",
    email: "",
    gst_no: "",
    dl_no: "",
    credit_limit: "",
    address: "",
    active: true,
  });
  const [refData, setRefData] = useState<any>({
    name_ref: useRef(null),
    phone_ref: useRef(null),
    email_ref: useRef(null),
    gst_no_ref: useRef(null),
    dl_no_ref: useRef(null),
    credit_limit_ref: useRef(null),
    address_ref: useRef(null),
  });

  const [error, setError] = useState<any>({
    name: "",
    phone: "",
    gst_no: "",
    dl_no: "",
  });
  let isFocused = false;
  const handleValidation = () => {
    const requiredFields = ["name", "phone", "gst_no", "dl_no"];
    let requiredFieldsRef: any = {
      name: refData.name_ref,
      phone: refData.phone_ref,
      gst_no: refData.gst_no_ref,
      dl_no: refData.dl_no_ref,
    };
    let error: any = {};
    requiredFields.forEach((key: string) => {
      if (!data[key]) {
        error[key] = "*This field is required";
        if (!isFocused) {
          if (requiredFieldsRef[key].current) {
            requiredFieldsRef[key].current.focus();
            isFocused = true;
          }
        }
      }
    });
    if (Object.values(error)?.length > 0) {
      setError(error);
      return false;
    }

    return true;
  };
  const handleSave = () => {
    if (handleValidation()) {
      setIsSaveBtnLoading(true);
      if (id) {
        updateCustomerMaster(id, { ...data, active: data.active ? 1 : 0 })
          .then((res: any) => {
            dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
            setIsSaveBtnLoading(false);
            onSave();
          })
          .catch((err) => {
            const errorMessage = err?.response?.data?.errors
              ? err?.response?.data?.errors
              : "Unknown error occurred";
            dispatch(
              setSnackBarFailed({
                snackBarMessage: `${errorMessage}`,
              })
            );
            console.log("err", err);
            setIsSaveBtnLoading(false);
          });
      } else {
        createCustomerMaster({ ...data, active: data.active ? 1 : 0 })
          .then((res: any) => {
            dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
            setIsSaveBtnLoading(false);
            onSave();
          })
          .catch((err) => {
            const errorMessage = error?.response?.data?.errors
              ? error?.response?.data?.errors
              : "Unknown error occurred";
            dispatch(
              setSnackBarFailed({
                snackBarMessage: `${errorMessage}`,
              })
            );
            console.log("err", err);
            setIsSaveBtnLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getCustomerMasterById(id)
        .then((res: any) => {
          if (res?.data) {
            setIsLoading(false);
            console.log("res", res?.data);
            setData(res?.data);
          }
        })
        .catch((err) => {
          const errorMessage = error?.response?.data?.errors
            ? error?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          setIsLoading(false);
          console.log("err", err);
        });
    }
  }, [id]);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setData((prevState: any) => ({ ...prevState, [name]: value }));

    if (value === "") {
      setError((prevState: any) => ({
        ...prevState,
        [name]: "*This field is required",
      }));
    } else {
      setError((prevState: any) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };
  return (
    <DialogWrapper
      onClose={onClose}
      open={isOpen}
      handleClick={handleSave}
      title={id === null ? ADD : EDIT}
      loading={isSaveBtnLoading}
      maxWidth="sm"
    >
      {isLoading ? (
        <PageLoader />
      ) : (
        <Grid container columnGap={1} rowGap={0.5}>
          <Grid item xl={12}>
            <TextField
              label="Name"
              name="name"
              value={data?.name}
              inputRef={refData.name_ref}
              placeholder="Enter Name"
              onChange={handleInputChange}
              helperText={error.name || ""}
            />
          </Grid>
          <Grid item xl={12}>
            <TextField
              label="Phone"
              name="phone"
              value={data?.phone}
              inputRef={refData.phone_ref}
              placeholder="Enter Phone Number"
              onChange={handleInputChange}
              helperText={error.phone || ""}
            />
          </Grid>
          <Grid item xl={12}>
            <TextField
              label="Email"
              name="email"
              value={data?.email}
              inputRef={refData.email_ref}
              placeholder="Enter Email"
              onChange={handleInputChange}
              helperText={""}
            />
          </Grid>
          <Grid item xl={12}>
            <TextField
              label="GST No"
              name="gst_no"
              value={data?.gst_no}
              inputRef={refData.gst_no_ref}
              placeholder="Enter Gst No"
              onChange={handleInputChange}
              helperText={error.gst_no || ""}
            />
          </Grid>
          <Grid item xl={12}>
            <TextField
              label="DL No"
              name="dl_no"
              value={data?.dl_no}
              inputRef={refData.dl_no_ref}
              placeholder="Enter DL No"
              onChange={handleInputChange}
              helperText={error.dl_no || ""}
            />
          </Grid>
          <Grid item xl={12}>
            <TextField
              label="Credit Limit"
              name="credit_limit"
              value={data?.credit_limit}
              placeholder="Enter Credit Limit"
              onChange={handleInputChange}
              helperText={""}
            />
          </Grid>
          <Grid item xl={12}>
            <InputLabel
              focused={isAddressFocused}
              sx={{ color: "greyScale.main", fontSize: "12px" }}
            >
              Address
            </InputLabel>
            <textarea
              style={{
                width: "96.5%",
                height: "90px",
                borderRadius: "8px",
                resize: "none",
                outline: "none",
                border: isAddressFocused
                  ? "2px solid #207dff"
                  : "1px solid #EFEFEF",
                borderColor: isAddressFocused ? "primary.main" : "#EFEFEF",
                fontFamily: "inter",
                fontSize: "14px",
                marginTop: "3px",
                padding: "10px",
              }}
              spellCheck="false"
              value={data.address}
              name="address"
              onChange={handleInputChange}
              placeholder="Enter Address"
              onFocus={() => setIsAddressFocused(true)}
              onBlur={() => setIsAddressFocused(false)}
            />
          </Grid>
          <Grid item xl={12}>
            <Switch
              name={"active"}
              label="status"
              Details={false}
              value={data.active}
              onChange={(event: any) =>
                setData((prevState: any) => ({
                  ...prevState,
                  active: event?.target?.checked,
                }))
              }
              isDisabled={!Boolean(id)}
            />
          </Grid>
        </Grid>
      )}
    </DialogWrapper>
  );
};

export default CustomerFormModal;
