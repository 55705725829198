import { useCallback, useEffect, useState } from "react";
import { Box, Typography, debounce } from "@mui/material";
import CustomFilter from "../../../../components/shared/customFilter";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialog, DataTable } from "../../../../components/shared";
import { setCurrentPage } from "../../../../redux/slices/pagination";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { GridColDef } from "@mui/x-data-grid";
import { Sort } from "../../../../components/basic";
import { EditIcon, DeleteIcon, PlusIcon } from "../../../../assets/icons";
import {
  setHandleClearSearch,
  setSortField,
} from "../../../../redux/slices/pagination";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import {
  deleteAdjustmentById,
  getAllAdjustments,
} from "../../../../services/paymentService";
import AddAndEditModal from "../AddAndEditModal";
import { formattedDateWithSlash } from "../../../../utils/DateTimeFormatUtils";
import PermissionUtils from "../../../../utils/PermissionUtils";

type GridRowData = Record<string, unknown>;

const AdjustmentList = () => {
  const { can } = PermissionUtils();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { adjustmentList, filterData, sortField } = useSelector(
    (state: RootState) => ({
      adjustmentList: state?.pagination?.adjustmentList,
      filterData: state?.pagination?.adjustmentList?.filterData,
      sortField: state?.pagination?.adjustmentList?.sortedField,
    })
  );
  const [adjustments, setAdjustments] = useState({
    rows: [],
    pageCount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<{
    isOpen: boolean;
    id: null | number | string;
  }>({
    isOpen: false,
    id: null,
  });
  const [isOpenAddAndEditModal, setIsOpenAddAndEditModal] = useState<{
    isOpen: boolean;
    id: null | number | string;
  }>({
    isOpen: false,
    id: null,
  });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    dispatch(
      setHandleClearSearch({ key: "adjustmentList", value: value, name: name })
    );
    dispatch(setCurrentPage({ key: "adjustmentList", value: 0 }));
  };

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortField?.field === field) {
      dispatch(
        setSortField({
          key: "adjustmentList",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
    if (sortField?.field !== field) {
      dispatch(
        setSortField({
          key: "adjustmentList",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
  };

  const handleConfirmDelete = () => {
    if (isOpenDeleteModal.id) {
      setIsDeleteLoading(true);
      deleteAdjustmentById(isOpenDeleteModal.id)
        .then((res: any) => {
          if (res) {
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          }
          setIsDeleteLoading(false);
          const data = {
            page: Number(adjustmentList.page) + 1,
            limit: adjustmentList.pageSize,
            column: sortField?.field,
            order: sortField?.order,
            search: filterData?.search,
          };
          getAllAdjustmentsList(data);
          setIsOpenDeleteModal({ isOpen: false, id: null });
        })
        .catch((err: any) => {
          console.log(err);
          setIsDeleteLoading(false);
          if (err?.response?.data?.error) {
            dispatch(
              setSnackBarFailed({ snackBarMessage: err?.response?.data?.error })
            );
          }
        });
    }
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        {can("adjustment_edit") && (
          <div style={{ cursor: "pointer" }}>
            <EditIcon
              onClick={() =>
                setIsOpenAddAndEditModal({ isOpen: true, id: row.id })
              }
            />
          </div>
        )}
        {can("adjustment_delete") && (
          <div style={{ cursor: "pointer" }}>
            <DeleteIcon
              onClick={() => setIsOpenDeleteModal({ isOpen: true, id: row.id })}
            />
          </div>
        )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "patient_name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Patient Name
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "adjustment",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("is_advance")}
        >
          <Typography variant="h5" fontSize={14}>
            Adjustments
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "is_advance" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "is_advance" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.is_advance === 0 ? "Excess" : "Advance"}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "amount",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("cash_received")}
        >
          <Typography variant="h5" fontSize={14}>
            Amount
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "cash_received" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "cash_received" &&
              sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.is_advance === 0 ? row?.net_excess : row?.cash_received}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "type",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("type")}
        >
          <Typography variant="h5" fontSize={14}>
            Type
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "type" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "type" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.type}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "date",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("date_created")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "date_created" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "date_created" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.date_created ? formattedDateWithSlash(row?.date_created) : ""}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "user",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("user_name")}
        >
          <Typography variant="h5" fontSize={14}>
            User
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "user_name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "user_name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.user_name}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    ...(can("adjustment_edit") || can("adjustment_delete")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const getAllAdjustmentsList = (data: any) => {
    setLoading(true);
    getAllAdjustments(data)
      .then((res: any) => {
        if (res.data) {
          console.log("getAllAdjustments", res.data);
          setAdjustments({
            rows: res?.data?.result,
            pageCount: res?.data?.total,
          });
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };

  const debouncedGetAllAdjustmentsList = useCallback(
    debounce((data) => {
      getAllAdjustmentsList(data);
    }, 300),
    []
  );

  const handleClose = (isSave: boolean) => {
    if (isSave) {
      const data = {
        page: Number(adjustmentList.page) + 1,
        limit: adjustmentList.pageSize,
        column: sortField?.field,
        order: sortField?.order,
        search: filterData?.search,
      };
      getAllAdjustmentsList(data);
    }
    setIsOpenAddAndEditModal({ isOpen: false, id: null });
  };

  useEffect(() => {
    const data = {
      page: Number(adjustmentList.page) + 1,
      limit: adjustmentList.pageSize,
      column: sortField?.field,
      order: sortField?.order,
      search: filterData?.search,
    };
    debouncedGetAllAdjustmentsList(data);
  }, [adjustmentList, sortField, filterData]);
  return (
    <Box>
      <CustomFilter
        isSearchEnabled
        editButtonTitle={can("adjustment_add") ? "Add" : null}
        searchOnChange={handleInputChange}
        searchName={"search"}
        searchValue={filterData?.search}
        clearSearch={() =>
          dispatch(
            setHandleClearSearch({
              key: "adjustmentList",
              value: "",
              name: "search",
            })
          )
        }
        onUpdateButtonClick={() =>
          setIsOpenAddAndEditModal({ isOpen: true, id: null })
        }
        editStartIcon={
          <Box
            sx={{
              "& path": {
                fill: "#fff",
              },
            }}
          >
            <PlusIcon width={"12px"} height={"12px"} />
          </Box>
        }
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={adjustments?.rows}
        pageCount={adjustments?.pageCount}
        currentPage={"adjustmentList"}
      />
      <ConfirmationDialog
        open={isOpenDeleteModal.isOpen}
        title="are you surely want to delete?"
        handleClick={handleConfirmDelete}
        onClose={() => setIsOpenDeleteModal({ isOpen: false, id: null })}
        loading={isDeleteLoading}
      />
      <AddAndEditModal
        isOpen={isOpenAddAndEditModal.isOpen}
        id={isOpenAddAndEditModal.id}
        onClose={handleClose}
      />
    </Box>
  );
};

export default AdjustmentList;
