import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { CancelButton, PageLoader } from "../../../../components/basic";
import { ConfirmationDialog } from "../../../../components/shared";
import { indentsConst } from "../../../../constants/displayText";
import Info from "./Info";
import { useParams } from "react-router-dom";
import { getIndentById } from "../../../../services/procurementService";
import ProductRow from "./ProductRow";

type TableHeaderCell = {
  TableCellName: string;
  alignment: "left" | "right" | "center";
  sx?: {};
  hasCheckBox?: boolean;
};

const BillProducts = () => {
  const { id } = useParams();
  const { productName, requirement, issuedQty, department, status } =
    indentsConst;

  const [billProducts, setBillProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<{
    isOpen: boolean;
    id: string | number | null;
  }>({
    isOpen: false,
    id: null,
  });
  const [isStatusLoading, setIsStatusLoading] = useState(false);

  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: "100%",
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: "100%",
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "34px !important",
          maxHeight: "34px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },
        "& .MuiTableCell-root": {
          minHeight: "0px",
        },
        "& .MuiTableCell-head": {
          height: "43px",
          maxHeight: "43px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "13px",
          fontWeight: "600",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body": {
          height: "32px !important",
          maxHeight: "32px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "12px",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "31px",
        },
        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "30px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },
      textStyle: {
        fontSize: "13px",
        fontWeight: "400",
        color: "textPrimary.main",
      },
      textFieldStyle: {
        height: "34px",
        width: "auto",
        fontSize: "6px",

        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "34px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "12px",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "32px",
          maxHeight: "32px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },
      },
    }),
    []
  );

  const handleConfirmChangeStatus = () => {
    if (isOpenConfirmModal.id) {
      setIsStatusLoading(true);
      setIsOpenConfirmModal({ isOpen: false, id: null });
      setIsStatusLoading(false);
    }
  };

  const initialInfo: any = {
    indent_no: "",
    department: "",
    created_at: "",
    user: "",
  };

  const [info, setInfo] = React.useState(initialInfo);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getIndentById(id)
        .then((res: any) => {
          if (res.data) {
            const { bill } = res.data;
            setInfo({
              indent_no: bill?.indent_no,
              indent_no_modified: 0,
              created_at: bill?.created_at,
              created_user: bill?.created_user,
            });
            const supplierList: any = [];
            setBillProducts(() =>
              bill?.indent_products?.map((ele: any) => {
                if (ele.supplier) {
                  supplierList.push(ele.supplier);
                }
                return {
                  id: ele.id,
                  product_id: ele.product_id,
                  product_name: ele?.product?.name,
                  department_name: ele?.department?.dept_name,
                  requirement: ele.requirement,
                  supplier_id: ele?.supplier_id,
                  issued_qty: ele?.issued_qty,
                  status: ele?.status,
                };
              })
            );
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    }
  }, [id]);

  const TableHeaderCells: TableHeaderCell[] = [
    { TableCellName: productName, alignment: "left", hasCheckBox: false },
    {
      TableCellName: department,
      alignment: "center",
    },
    {
      TableCellName: requirement,
      alignment: "center",
    },
    {
      TableCellName: issuedQty,
      alignment: "center",
    },
    {
      TableCellName: status,
      alignment: "center",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Grid>
          <Info info={info} />
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              overflowX: { md: "scroll", lg: "hidden" },
            }}
          >
            <TableContainer
              className="table-container"
              component={Paper}
              sx={{
                ...styles.tableContainerStyle,
              }}
            >
              <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {TableHeaderCells.map((cell, index) => {
                      return (
                        <TableCell
                          align={cell.alignment}
                          sx={cell.sx}
                          key={index}
                        >
                          {cell.TableCellName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ height: "auto", width: "90vw" }}>
                  {billProducts?.map((row: any, index: number) => {
                    return (
                      <ProductRow
                        key={row.id}
                        index={index}
                        row={row}
                        styles={styles}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            sx={{
              width: "40vw",
              display: "flex",
              alignItems: "flex-end",
              gap: 2,
              m: "20px 0px",
            }}
          >
            <CancelButton
              buttonText="Cancel"
            />
          </Grid>
          {isOpenConfirmModal.isOpen && (
            <ConfirmationDialog
              open={isOpenConfirmModal.isOpen}
              title="are you surely want to change the status?"
              handleClick={handleConfirmChangeStatus}
              onClose={() => setIsOpenConfirmModal({ isOpen: false, id: null })}
              loading={isStatusLoading}
              confirmText="Yes"
            />
          )}
        </Grid>
      )}
    </>
  );
};

export default BillProducts;
