/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { Typography, Box, IconButton, Grid, debounce } from "@mui/material";
import { DataTable, ConfirmationDialog } from "../../../../components/shared";
import { DeleteIcon, EditIcon, PlusIcon, ViewIcon } from "../../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  Sort,
  TextField,
  DatePicker,
  Select,
} from "../../../../components/basic";
import { RouteUrls } from "../../../../constants/routes";
import { RootState } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import CustomFilter from "../../../../components/shared/customFilter";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { errorMessage, CurrentDate } from "../../../../constants/displayText";
import { getAllLabGoodsIssue } from "../../../../services/laboratoryService";
import moment from "moment";
import { deleteLabGoodsIssueById } from "../../../../services/laboratoryService";
import { handleFilterCounts } from "../../../../utils/GeneralUtils";
import PermissionUtils from "../../../../utils/PermissionUtils";
import { Link } from "react-router-dom";

type GridRowData = Record<string, unknown>;

const GoodsIssueList = () => {
  const style = {
    selectStyle: {
      width: {
        xs: "90vw",
        sm: "45vw",
        md: "30vw",
        lg: "13vw",
        xl: "13vw",
      },
      height: "45px",
      boxShadow: "none",
      borderRadius: "5px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
    DatePickerStyle: {
      "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container >div >div .rmdp-input":
        {
          boxSizing: "border-box",
          width: "100%",
        },
    },
    textFieldStyle: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "45px",
        borderRadius: "5px",
        paddingLeft: "8px",
        boxShadow: "none",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
  };

  const dispatch = useDispatch();
  const { can } = PermissionUtils();

  const [goodsIssueList, setGoodsIssueList] = useState<[]>([]);

  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);

  const [appliedFilterCount, setAppliedFilterCount] = useState(0);
  const [filterData, setFilterData] = useState({
    invoice_no: "",
    product: "",
    from_date: CurrentDate,
    to_date: CurrentDate,
    search: "",
  });
  const [filterDataChanged, setFilterDataChanged] = useState({
    invoice_no: false,
    product: false,
    from_date: false,
    to_date: false,
    search: false,
  });
  const [loading, setLoading] = useState(false);
  const [ascendingOrderClicked, setAscendingOrderClicked] = useState(false);
  const [descendingOrderClicked, setDescendingOrderClicked] = useState(false);
  const pageInfo = useSelector(
    (state: RootState) => state?.pagination?.goodsIssue
  );
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [searchValue, setSearchValue] = useState<any>("");

  const { laboratoryUrl, labGoodsIssue, createUrl, editUrl, viewUrl } =
    RouteUrls;
  const navigate = useNavigate();

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [goodsToBeDeleted, setGoodsToBeDeleted] = useState<any>(null);

  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  const deleteGoods = (row: any) => {
    setIsConfirmationDialogOpen(true);
    setGoodsToBeDeleted(row.id);
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {row?.is_return === 1
          ? can("lab_goods_issue_edit")
          : can("lab_goods_issue_return_edit") && (
              <Link
                to={`${laboratoryUrl}${labGoodsIssue}${editUrl}/${row.id}`}
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <EditIcon />
              </Link>
            )}
        {can("lab_goods_issue_view") && (
          <Link
            to={`${laboratoryUrl}${labGoodsIssue}${viewUrl}/${row.id}`}
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <ViewIcon />
          </Link>
        )}
        {!row.is_cancel && can("lab_goods_issue_delete") && (
          <IconButton
            aria-label=""
            onClick={() => {
              deleteGoods(row.id);
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    );
  };

  const deleteGoodsIssue = async () => {
    if (goodsToBeDeleted === null) {
      return false;
    }
    setDeleteBtnLoader(true);
    await deleteLabGoodsIssueById(goodsToBeDeleted as number)
      .then((res: any) => {
        setGoodsToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        debouncedGetAllPurchaseBill({
          page: pageInfo.page,
          pageSize: pageInfo.pageSize,
        });
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        setDeleteBtnLoader(false);
      })
      .catch((err) => {
        setDeleteBtnLoader(false);
        setGoodsToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        const errorList = Array.isArray(err.response.data.errors.message);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: errorList
              ? err.response.data.errors.message[0]?.table
              : err.response.data.errors,
          })
        );
        console.log(err);
      });
  };
  const onClose = () => {
    setIsConfirmationDialogOpen(false);
  };
  const columns: GridColDef[] = [
    {
      field: "invoice_no",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("invoice_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Invoice No
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "invoice_no" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "invoice_no" && sortedField.order === "DESC"
                ? true
                : false
            }
            ascendingOnClick={() =>
              setAscendingOrderClicked(!descendingOrderClicked)
            }
            descendingOnClick={() =>
              setDescendingOrderClicked(!ascendingOrderClicked)
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.invoice_no}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "created_at",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("created_at")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "created_at" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "created_at" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {moment(row?.created_at).format("DD/MM/YYYY hh:mm:ss A")}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "from_dept_name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("from_dept_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Issuer
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "from_dept_name" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "from_dept_name" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.from_dept_name}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "to_dept_name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("to_dept_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Receiver
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "to_dept_name" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "to_dept_name" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.to_dept_name}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "bill_amt",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("bill_amt")}
        >
          <Typography variant="h5" fontSize={14}>
            Bill Amount
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "bill_amt" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "bill_amt" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.bill_amt}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "created_user",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("created_user")}
        >
          <Typography variant="h5" fontSize={14}>
            User
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "created_user" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "created_user" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.created_user}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    ...(can("lab_goods_issue_edit") ||
    can("lab_goods_issue_view") ||
    can("lab_goods_issue_delete") ||
    can("lab_goods_issue_return_edit")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const clearFilters = () => {
    if (
      filterData.invoice_no ||
      filterData.product ||
      filterData.from_date ||
      filterData.to_date
    ) {
      setFilterData({
        ...filterData,
        invoice_no: "",
        product: "",
        from_date: CurrentDate,
        to_date: CurrentDate,
      });
      setFilterDataChanged({
        ...filterDataChanged,
        invoice_no: false,
        product: false,
        from_date: false,
        to_date: false,
        search: false,
      });
      setAppliedFilterCount(0);
    }
  };

  const handleInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;

      setFilterData((prevState: any) => {
        const newFilter = { ...prevState, [name]: value };
        if (name !== "search") {
          // const appliedFilterCount = Object.values(newFilter).filter(Boolean).length;
          // setAppliedFilterCount(appliedFilterCount);
          handleFilterCounts(
            newFilter,
            name,
            setFilterDataChanged,
            setAppliedFilterCount
          );
        }
        return newFilter;
      });
    }
  };

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      setFilterData((prevState: any) => {
        const newFilter = {
          ...prevState,
          [name]: newValue.validatedValue[0],
        };
        handleFilterCounts(
          newFilter,
          name,
          setFilterDataChanged,
          setAppliedFilterCount
        );
        // const appliedFilterCount = Object.values(newFilter).filter(Boolean).length;
        // setAppliedFilterCount(appliedFilterCount);
        return newFilter;
      });
    }
  };

  const appointmentFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData.invoice_no}
          placeholder={"Enter Invoice No"}
          label={"Invoice No"}
          formControlStyle={{ width: "100%" }}
          onChange={handleInputChange}
          name={"invoice_no"}
          sx={{
            ...style.textFieldStyle,
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData.product}
          placeholder={"Enter Product Name"}
          label={"Product Name"}
          formControlStyle={{ width: "100%" }}
          onChange={handleInputChange}
          name={"product"}
          sx={{
            ...style.textFieldStyle,
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <Grid
          sx={{
            ...style.DatePickerStyle,
          }}
        >
          <DatePicker
            label="From Date"
            name="from_date"
            placeholder="Select Date"
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "from_date")
            }
            format="DD/MM/YYYY"
            value={filterData.from_date}
            formControlStyle={{ width: "100%" }}
            style={{
              boxShadow: "none",
              height: "45px",
              borderRadius: "5px",
              width: "100%",
            }}
            borderColor="var(--primary-border-color)"
          />
        </Grid>
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <Grid
          sx={{
            ...style.DatePickerStyle,
          }}
        >
          <DatePicker
            label="To Date"
            name="to_date"
            placeholder="Select Date"
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "to_date")
            }
            format="DD/MM/YYYY"
            value={filterData.to_date}
            formControlStyle={{ width: "100%" }}
            style={{
              boxShadow: "none",
              height: "45px",
              borderRadius: "5px",
              width: "100%",
            }}
            borderColor="var(--primary-border-color)"
          />{" "}
        </Grid>
      ),
    },
  ];

  const debouncedGetAllPurchaseBill = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllLabGoodsIssue({
        page: data.page,
        limit: data.pageSize,
        column: data.sortBy,
        order: data.sortOrder,
        from_date: data.fromDate
          ? data.fromDate.split("/").reverse().join("-")
          : "",
        to_date: data.toDate ? data.toDate.split("/").reverse().join("-") : "",
        // type: data.type,
        invoice_no: data.invoice_no,
        product: data.product,
        search: data.search,
      })
        .then((res: any) => {
          setGoodsIssueList(res.data.result as []);
          setPageCount(res.data.total as any);
          setLoading(false);
        })
        .catch((err) => {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage.ERROR_FETCHING_GOODS_ISSUE}`,
            })
          );
          setLoading(false);
          console.log("error getAppointmentList", err);
        });
    }, 300),
    []
  );
  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo.pageSize,
      sortBy: sortedField.field,
      sortOrder: sortedField.order,
      fromDate: filterData.from_date,
      toDate: filterData.to_date,
      invoice_no: filterData.invoice_no,
      product: filterData.product,
      search: searchValue,
    };
    debouncedGetAllPurchaseBill(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortedField.order,
    sortedField.field,
    filterData.from_date,
    filterData.to_date,
    filterData.from_date,
    filterData.to_date,
    filterData.product,
    filterData.invoice_no,
    searchValue,
  ]);

  return (
    <Box>
      <CustomFilter
        data={appointmentFilter}
        isFilterEnabled={true}
        isSearchEnabled={true}
        clearFilter={clearFilters}
        searchOnChange={debounce(
          (e: any) => setSearchValue(e.target.value),
          300
        )}
        searchName={"search"}
        appliedFilterCount={appliedFilterCount}
        {...(can("lab_goods_issue_add") && {
          editButtonTitle: "Add",
          onUpdateButtonClick: () =>
            navigate(`${laboratoryUrl}${labGoodsIssue}${createUrl}`),
          editStartIcon: (
            <Box
              sx={{
                "& path": {
                  fill: "#fff",
                },
              }}
            >
              <PlusIcon width={"12px"} height={"12px"} />
            </Box>
          ),
        })}
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={goodsIssueList}
        pageCount={pageCount}
        currentPage={"goodsIssue"}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="are you surely want to delete?"
        handleClick={deleteGoodsIssue}
        onClose={onClose}
        loading={deleteBtnLoader}
      />
    </Box>
  );
};

export default GoodsIssueList;
