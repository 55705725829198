import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { decrypt } from "../../utils/GeneralUtils";

type AppConfigState = {
  isTokenEnable: boolean;
  appConfiguration: any;
  commonVariables: any;
  userDetails: any;
  printPdfUrl: string | null;
};

const getCommonVariables = () => {
  const commonVariables = localStorage.getItem("commonVariables");
  if (!commonVariables) {
    return {};
  }

  const decryptedString = decrypt("common_variable_salt", commonVariables);
  try {
    return JSON.parse(decryptedString);
  } catch (e) {
    console.error("Failed to parse commonVariables:", e);
    return {};
  }
};

const getAppConfiguration = () => {
  const appConfiguration = localStorage.getItem("appConfiguration");
  if (!appConfiguration) {
    return {};
  }

  const decryptedString = decrypt("app_configuration_salt", appConfiguration);
  try {
    return JSON.parse(decryptedString);
  } catch (e) {
    console.error("Failed to parse appConfiguration:", e);
    return {};
  }
};

const initialAppConfigState: AppConfigState = {
  isTokenEnable: false,
  appConfiguration: Object(getAppConfiguration()),
  commonVariables: Object(getCommonVariables()),
  userDetails: null,
  printPdfUrl: null,
};

const appConfigSlice = createSlice({
  name: "appconfig",
  initialState: initialAppConfigState,
  reducers: {
    setIsTokenEnable: (state, action: PayloadAction<boolean>) => {
      state.isTokenEnable = action.payload;
    },
    setCommonVariable: (state, action: PayloadAction<any>) => {
      state.commonVariables = action.payload;
    },
    setAppConfiguration: (state, action: PayloadAction<any>) => {
      state.appConfiguration = action.payload;
    },
    setUserDetails: (state, action: PayloadAction<any>) => {
      state.userDetails = action.payload;
    },
    setPrintPdfUrl: (state, { payload }: PayloadAction<string | null>) => {
      state.printPdfUrl = payload;
    },
  },
});

export const {
  setIsTokenEnable,
  setCommonVariable,
  setAppConfiguration,
  setUserDetails,
  setPrintPdfUrl,
} = appConfigSlice.actions;

export default appConfigSlice.reducer;
