import { Box, Grid, Typography } from "@mui/material";
import { Switch, TextField } from "../../../../components/basic";
import { DialogWrapper } from "../../../../components/shared";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import {
  createItemGroupMaster,
  createLabResultMaster,
  createMethodologyMaster,
  updateItemsGroupMaster,
  updateLabResultMaster,
  updateMethodologyMaster,
} from "../../../../services/billingService";

type MasterAddAndEditDialogProps = {
  isOpen: boolean;
  isEdit?: boolean;
  type: string | undefined;
  onClose: () => void;
  data?: any;
  setIsUpdated?: any;
};

const MasterAddAndEditDialog = (props: MasterAddAndEditDialogProps) => {
  const { isOpen, isEdit, data, type, onClose, setIsUpdated } = props;
  const dispatch = useDispatch();
  const [masterData, setMasterData] = useState<{
    id: number | string;
    name: string;
    active: number;
  }>({ id: 0, name: "", active: 1 });
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setMasterData((prevState: any) => ({ ...prevState, [name]: value }));

    setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
  };

  const handleClose = () => {
    setMasterData({ id: 0, name: "", active: 1 });
    onClose();
    setErrors({});
  };

  const updateMasterData = (func: any) => {
    setLoading(true);
    func(masterData)
      .then((res: any) => {
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        handleClose();
        setIsUpdated((prevState: any) => !prevState);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("err", err);
        setLoading(false);
        if (err?.response?.data?.errors) {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: err?.response?.data?.errors,
            })
          );
        }
      });
  };

  const createMasterData = (func: any) => {
    setLoading(true);
    func(masterData)
      .then((res: any) => {
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        handleClose();
        setIsUpdated((prevState: any) => !prevState);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log("err", err);
        if (err?.response?.data?.errors) {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: err?.response?.data?.errors,
            })
          );
        }
      });
  };
  const [errors, setErrors] = useState<{ name?: string }>({});
  const validate = () => {
    let tempErrors: { name?: string } = {};

    if (!masterData.name) {
      tempErrors.name = "*Name is required";
    }

    setErrors(tempErrors);

    // Return true if no errors, otherwise false
    return Object.keys(tempErrors).length === 0;
  };
  const handleSave = () => {
    if (validate()) {
      if (isEdit) {
        if (
          masterData.name !== data.name ||
          masterData.active !== data.active
        ) {
          updateMasterData(
            type === "Items Group"
              ? updateItemsGroupMaster
              : type === "Methodology"
              ? updateMethodologyMaster
              : updateLabResultMaster
          );
        } else {
          handleClose();
        }
      } else {
        if (masterData.name) {
          createMasterData(
            type === "Items Group"
              ? createItemGroupMaster
              : type === "Methodology"
              ? createMethodologyMaster
              : createLabResultMaster
          );
        }
      }
    }
  };

  useEffect(() => {
    if (isEdit && data) {
      setMasterData(data);
    }
  }, [data]);

  return (
    <DialogWrapper
      open={isOpen}
      onClose={handleClose}
      title={isEdit ? "Edit" : "Add"}
      maxWidth="xs"
      confirmText="Save"
      cancelText="Cancel"
      handleClick={handleSave}
      loading={loading}
    >
      <Grid container spacing={1}>
        <Grid item xl={12}>
          <TextField
            formControlStyle={{ width: "100%" }}
            value={masterData?.name || ""}
            fullWidth
            name="name"
            label="Name"
            onChange={handleChange}
            helperText={errors.name ? errors.name : ""} //
          />
        </Grid>
        <Grid item xl={12}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography
              style={{
                fontWeight: "400 ",
                lineHeight: "1.4375em",
                color: "#8A8A8A",
                fontSize: "12px",
                // margin: "0px 10px",
              }}
            >
              Status
            </Typography>
            <Switch
              name="active"
              value={Boolean(Number(masterData?.active))}
              sx={{
                "&.MuiSwitch-root": {
                  margin: "0px !important",
                  border: "1px solid red",
                },
              }}
              Details={false}
              onChange={(event: any) => {
                const { name, checked } = event.target;
                setMasterData((prevState: any) => ({
                  ...prevState,
                  [name]: checked ? 1 : 0,
                }));
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </DialogWrapper>
  );
};

export default MasterAddAndEditDialog;
