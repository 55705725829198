import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  debounce,
  Box,
} from "@mui/material";
import { formula } from "../../../../utils/CalculationUtils";
import { useNavigate, useParams } from "react-router-dom";
import Info from "./info";
import Summary from "./Summary";
import {
  CancelButton,
  SaveButton,
  Button,
  PageLoader,
  DeleteDialog,
} from "../../../../components/basic";
import { goodsIssueConst } from "../../../../constants/displayText";
import ProductRow from "./ProductRow";
import {
  createGoodsIssue,
  createTempGoodsIssue,
  getAllMasterDepartments,
  getGoodsIssueProduct,
  getGoodsIssueProductById,
  updateGoodsIssueById,
} from "../../../../services/gynecologyService";
import {
  formattedDateWithSlash,
  formatTwelveHoursTime,
} from "../../../../utils/DateTimeFormatUtils";
import {
  setSnackBarFailed,
  setSnackBarInfo,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import SequenceWrapper from "../../../../components/shared/sequenceWrapper";
import {
  getDepartmentSequence,
  updatedSequence,
} from "../../../../services/mainCoreService";
import moment from "moment";
import { ConfirmationDialog } from "../../../../components/shared";
const BillProducts = () => {
  const { productName, pack, batchNo, expiryDate, qty, discount, tax, amount } =
    goodsIssueConst;

  const {
    calculateAmount,
    calculateTaxAmount,
    calculateRoundedOff,
    calculateNetAmount,
  } = formula.goodsIssue;
  const {
    calculateSubTaxes,
    calculateDiscountAmount,
    calculateTotalDiscountAmount,
    calculateBillTotal,
    calculateBillDataDiscountAmount,
  } = formula.purchaseBill;
  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: {
          xs: "92vw",
        },
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "90vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "44px !important",
          maxHeight: "44px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },
        "& .MuiTableCell-root": {
          minHeight: "0px",
        },
        "& .MuiTableCell-head": {
          height: "44px",
          maxHeight: "44px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "var(--table-header-font-size)",
          fontWeight: "500",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body": {
          height: "44px !important",
          maxHeight: "44px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "var(--table-body-font-size)",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "44px",
        },
        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "44px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },

      textFieldStyle: {
        height: "44px",
        width: "auto",

        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "44px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "44px",
          maxHeight: "44px",
          top: 0,
        },

        "& input": {
          height: "100%",
        },
        "& input::placeholder": {
          fontSize: "12px",
        },
      },

      selectStyle: {
        "& .MuiFormControl-root": { width: "100%" },

        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
          borderWidth: 0,
          height: "44px",
          maxHeight: "44px",
          p: 0,
        },

        "& .MuiInputBase-root": {
          padding: "0px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },

        "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "primary.main",
            borderWidth: "1px",
          },

        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: 0,
        },
      },
    }),
    []
  );
  const [productRow, setProductRow] = useState([]);
  const [summaryError, setSummaryErrors] = useState<any>({});
  const { id } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const TableHeaderCells: any = [
    { TableCellName: productName, alignment: "left" },
    { TableCellName: batchNo, alignment: "left" },
    { TableCellName: pack, alignment: "right" },
    ...(id ? [{ TableCellName: "Used.Strip.Qty", alignment: "right" }] : []),
    ...(id ? [{ TableCellName: "Used.Qty", alignment: "right" }] : []),
    { TableCellName: "Strip Qty	", alignment: "right" },
    { TableCellName: qty, alignment: "right" },
    { TableCellName: expiryDate, alignment: "right" },
    { TableCellName: "PTR/Unit", alignment: "right" },
    { TableCellName: "MRP/Unit", alignment: "right" },
    { TableCellName: "Discount", alignment: "left" },
    { TableCellName: tax, alignment: "right" },
    {
      TableCellName: amount,
      alignment: "right",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];
  interface BillProduct {
    pack: any;
    qty: any;
    mrp: number;
    id: number;
    product_name: string | null;
    product_name_input_value: string | null;
    stock: any;
    free: any;
    ret_qty: any;
    f_ret_qty: any;
    strip_qty: string;
    used_qty: string;
    discount: string;
    used_strip_qty: string;
    batch_no: string;
    expiry_date: string;
    tax: string;
    ptr: string;
    amount: any;
    discount_amount: any;
    tax_amount: any;
  }
  const initialBillProduct: BillProduct = {
    pack: null,
    qty: null,
    mrp: 0,
    id: 0,
    product_name: null,
    product_name_input_value: null,
    stock: null,
    free: null,
    ret_qty: null,
    f_ret_qty: null,
    strip_qty: "",
    used_qty: "",
    discount: "",
    used_strip_qty: "",
    batch_no: "",
    expiry_date: "",
    tax: "",
    ptr: "",
    amount: null,
    discount_amount: null,
    tax_amount: null,
  };
  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);
  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<{
    isOpen: boolean;
    selectedRow: {
      index: number;
      batchNo?: string;
      id?: number;
    } | null;
  }>({
    isOpen: false,
    selectedRow: null,
  });
  const [isManualAdded, setIsManualAdded] = useState(false);
  const [sequenceNo, setSequenceNo] = useState("");
  const [sequenceDialogBox, setSequenceDialogBox] = useState(false);
  const [autoGeneratedSeqNo, setAutoGeneratedSeqNo] = useState("");
  const [seqIsOnce, setSeqIsOne] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });
  const [deletedId, setDeletedId] = useState<any>([]);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<{
    isOpen: boolean;
    content: string;
  }>({
    isOpen: false,
    content: "",
  });
  const calculateMonthDifference = (date: any) => {
    const today = moment();
    const givenDate = moment(date, "DD/MM/YYYY");

    const monthDifference = today.diff(givenDate, "months");
    return Math.abs(monthDifference);
  };

  const handleExpDateCheck = (expires_at: string) => {
    if (expires_at) {
      const monthDifference = calculateMonthDifference(expires_at);
      if (monthDifference <= 6 && monthDifference !== 0) {
        setIsOpenErrorModal({
          isOpen: true,
          content: `This product will expire in ${monthDifference} months.`,
        });
      }
      if (monthDifference === 0) {
        setIsOpenErrorModal({
          isOpen: true,
          content: `This product will expire in This months.`,
        });
      }
    }
  };
  const [billProducts, setBillProducts] = useState<any>([initialBillProduct]);
  const [issuerList, setIssuerList] = useState([]);
  const [receiverList, setReceiverList] = useState([]);
  const initialInfo: any = {
    bill_no: "",
    issuer: "",
    receiver: "",
  };
  const [batchIdNo, setBatchIdNo] = useState<number[] | []>([]);
  const [info, setInfo] = useState<{} | any>(initialInfo);
  const initialSummary = {
    total_discount_amount: 0,
    goods_value: 0,
    CGST: 0,
    SGST: 0,
    tax_amount: 0,
    rounded_off: 0,
    bill_amount: 0,
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSelectLoading, setIsSelectLoading] = useState(false);
  const [isSubmitLoaded, setIsSubmitLoaded] = useState(false);
  const [summary, setSummary] = useState<{} | any>(initialSummary);
  const [formError, setFormError] = useState<
    {
      qty: boolean;
      // discount: boolean;
    }[]
  >([
    {
      qty: false,
      // discount: false,
    },
  ]);
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [customSequence, setCustomSequence] = useState(true);
  const [billingSummary, setBillingSummary] = React.useState({});
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [errors, setErrors] = useState<any>([]);
  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");

  const handleValidation = (
    currentValue: any,
    updatedValue: any,
    idx: number | string
  ) => {
    if (Number(currentValue?.qty) > Number(updatedValue?.stock_qty)) {
      setFormError((prevState) => {
        const newState = [...prevState];
        newState[idx as number]["qty"] = true;
        return newState;
      });
    } else {
      setFormError((prevState) => {
        const newState = [...prevState];
        newState[idx as number]["qty"] = false;
        return newState;
      });
    }
    // if (
    //   currentValue.discount_type === "%" &&
    //   Number(currentValue?.discount) > 100
    // ) {
    //   setFormError((prevState) => {
    //     const newState = [...prevState];
    //     newState[idx as number]["discount"] = true;
    //     return newState;
    //   });
    // } else if (
    //   currentValue.discount_type === "₹" &&
    //   Number(currentValue?.discount) > Number(currentValue?.amount)
    // ) {
    //   setFormError((prevState) => {
    //     const newState = [...prevState];
    //     newState[idx as number]["discount"] = true;
    //     return newState;
    //   });
    // } else {
    //   setFormError((prevState) => {
    //     const newState = [...prevState];
    //     newState[idx as number]["discount"] = false;
    //     return newState;
    //   });
    // }
  };
  const handleCalculateAmount = (product: any) => {
    let num = 0;
    if (isNaN(product?.qty) || isNaN(product.ptr)) {
      return num;
    }
    if (product?.qty) {
      num = product.qty * product.ptr;
      num = num - product.discount_amount;
      return num;
    }
    return num;
  };

  const handleInputChange = useCallback((e: any, index: number) => {
    const { name, value } = e?.target;
    let numericValue = value?.replace(/\D/g, "");
    setCurrentIndex(index as any);

    setBillProducts((prevState: any) => {
      const newState = [...prevState];

      newState[index as number] = {
        ...newState[index as number],
        [name]: numericValue,
      };
      if (name === "qty") {
        newState[index as number].strip_qty = Math.ceil(
          Number(numericValue) / Number(newState[index as number].pack)
        );
      }
      if (name === "strip_qty") {
        newState[index as number].qty = Math.ceil(
          Number(numericValue) * Number(newState[index as number].pack)
        );
      }
      if (name === "discount_type") {
        newState[index as number].discount_type = value;
      }
      if (name === "qty") {
        handleValidation(
          newState[index as number],
          newState[index as number].product_name,
          index
        );
      }
      if (name === "strip_qty") {
        handleValidation(
          newState[index as number],
          newState[index as number].product_name,
          index
        );
      }
      newState[index as number] = {
        ...newState[index as number],
        discount_amount:
          calculateDiscountAmount({
            row_discount_type: newState[index as number].discount_type,
            discount: newState[index as number].discount,
            qty: newState[index as number].qty,
            p_rate: newState[index as number].ptr,
          }) || 0,
      };
      newState[index as number] = {
        ...newState[index as number],
        amount: handleCalculateAmount(newState[index as number]),
      };
      // if (name === "discount"||name==="discount_type") {
      //   handleValidation(
      //     newState[index as number],
      //     newState[index as number].product_name,
      //     index
      //   );
      // }

      calculateBillData(newState);
      return newState;
    });
  }, []);

  const handleAutoCompleteChange = useCallback(
    (newValue: any, idx: number | string, name: string) => {
      if (newValue === null) {
        return false;
      }
      setBillProducts((prevState: any) => {
        let newState = [...prevState];
        if (newState.length > 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            id: null,
            mrp: newValue.mrp / newValue.pack_type,
            expiry_date: newValue.expires_at,
            batch_no: `${newValue.batch_no}#${newValue.stock_qty}`,
            bill_batch_id: newValue.batch_id,
            amount: 0,
            discount: newValue.discount,
            discount_type: newValue.discount_type,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
            pack: newValue.pack_type,
            barcode: newValue?.barcode,
            ptr: Number(newValue?.p_rate / newValue?.pack_type).toFixed(2),
            stock_qty: newValue.stock_qty,
          };
        }
        if (newState.length === 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            id: null,
            mrp: newValue.mrp / newValue.pack_type,
            expiry_date: newValue.expires_at,
            batch_no: `${newValue.batch_no}#${newValue.stock_qty}`,
            bill_batch_id: newValue.batch_id,
            amount: 0,
            discount: newValue.discount,
            discount_type: newValue.discount_type,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
            pack: newValue.pack_type,
            barcode: newValue?.barcode,
            ptr: Number(newValue?.p_rate / newValue?.pack_type).toFixed(2),
            stock_qty: newValue.stock_qty,
          };
        }

        handleExpDateCheck(formattedDateWithSlash(newValue.expires_at));
        let newBatchIds = [...batchIdNo];
        if (newBatchIds[idx as number]) {
          newBatchIds[idx as number] = newValue?.batch_id;
        } else {
          newBatchIds.push(newValue?.batch_id);
        }
        setBatchIdNo(newBatchIds);
        return newState;
      });
    },
    [batchIdNo, calculateDiscountAmount]
  );

  const handleAutoCompleteInputChange = useCallback(
    (newValue: any, idx: number, name: string) => {
      setBillProducts((prevState: any) => {
        const newState = [...prevState];
        if (newState.length > 0 && newState[idx]) {
          newState[idx][name] = newValue;
        }
        return newState;
      });
    },
    []
  );

  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };
  const handleInvoiceNoChange = () => {
    if (autoGeneratedSeqNo !== info.bill_no && Boolean(!isManual.is_once)) {
      setSeqIsOne((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: info.bill_no,
      }));
      setSequenceDialogBox(true);
      setIsManual((prevState) => ({
        ...prevState,
        is_manual: false,
        is_auto_generate: false,
        is_once: true,
      }));
    }
  };

  let isCreatePrescription = true;
  let isSequenceNumberChanged = false;

  const handleSequence = () => {
    getDepartmentSequence("Goods Issue")
      .then((res: any) => {
        let data = res?.data[0]?.sequence_no;

        if (isCreatePrescription && !isSequenceNumberChanged) {
          setInitialSequenceNumber(data);
          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: data,
          }));

          setErrors((prevState: any) => ({
            ...prevState,
            ip_no: "",
          }));
        }

        if (isSequenceNumberChanged) {
          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: data,
          }));
          setErrors((prevState: any) => ({
            ...prevState,
            ip_no: "",
          }));
        }
        if (res?.data?.length > 0) {
          setAutoGeneratedSeqNo(res?.data[0]?.sequence_no);
          setSequenceNo(res.data[0].sequence.id);
          const newSequenceData = res.data[0].sequence.sequence_preferences.map(
            (ele: {
              id: number;
              type: string;
              value: string;
              separator: string;
            }) => ({
              id: ele.id,
              type: ele.type,
              value: ele.value,
              separator: ele.separator,
            })
          );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual: res.data[0].sequence.is_manual === 1 ? true : false,
            is_auto_generate:
              res.data[0].sequence.is_manual !== 1 ? true : false,
          }));
          setSeqIsOne((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(res.data[0].sequence.is_manual === 1));
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }
  }, [appConfiguration]);
  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value === "Select" ? "" : value,
      };
      return newState;
    });
  };
  function onCloseSequenceDialogBox() {
    setSequenceDialogBox(false);
  }

  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      const data = {
        seq_preference: [...sequenceData],
        is_manual: isManual.is_manual ? 1 : 0,
      };
      await updatedSequence(Number(sequenceNo), data)
        .then((res) => {
          if (res) {
            isSequenceNumberChanged = true;
            handleSequence();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(setSnackBarFailed({ snackBarMessage: err.message }));
        });
    }
  };
  useEffect(() => {
    if (id) {
      setIsPageLoading(true);
      getGoodsIssueProductById(id)
        .then((res: any) => {
          const purchaseBill = res.data.bill;
          const billProducts = res.data.bill_products;
          if (purchaseBill === null || billProducts === null) {
            dispatch(
              setSnackBarInfo({
                snackBarMessage: "Currently this bill was not available",
              })
            );
            navigate("/procurement/goods-issue");
          } else {
            setInfo((prevState: any) => ({
              ...prevState,
              bill_no: purchaseBill.invoice_no,
              issuer: purchaseBill.from_dept_id,
              receiver: purchaseBill.to_dept_id,
              date: formatTwelveHoursTime(purchaseBill.created_at),
              disabled: true,
            }));
            const data = billProducts.map((res: any) => ({
              id: res.id,
              product_name: {
                ...res,
                stock_qty: res.goods_org_qty
                  ? Number(res.stock_qty | 0) +
                    Number(res.goods_org_qty | 0) -
                    Number(res.goods_return | 0)
                  : Number(res.stock_qty | 0),
              },
              batch_id: res.batch_id,
              batch_no: `${res.batch_no}#${
                res.goods_org_qty
                  ? Number(res.stock_qty | 0) +
                    Number(res.goods_org_qty | 0) -
                    Number(res.goods_return | 0)
                  : Number(res.stock_qty | 0)
              }`,
              expiry_date: res.expires_at
                ? res.expires_at.split("-").reverse().join("/")
                : "",
              qty: res.goods_org_qty,
              mrp: res.mrp / res.pack_type,
              pack: res.pack_type,
              bill_batch_id: res.batch_id,
              discount: res.discount || 0,
              discount_type: res.discount_type,
              discount_amount:
                calculateDiscountAmount({
                  row_discount_type: res.discount_type,
                  discount: res.discount || 0,
                  qty: res.goods_org_qty,
                  p_rate: res.p_rate,
                }) || 0,
              tax: `${res.taxname}-${res.tax_id}%`,
              amount:
                res.goods_org_qty * (res?.p_rate / res.pack_type) -
                (calculateDiscountAmount({
                  row_discount_type: res.discount_type,
                  discount: res.discount || 0,
                  qty: res.goods_org_qty,
                  p_rate: res.p_rate,
                }) || 0),
              barcode: res?.barcode,
              isId: id,
              used_strip_qty:
                res.goods_adj < 0
                  ? res.goods_sales + res.goods_return - res.goods_adj || 0
                  : res.goods_sales + res.goods_return / res.pack_type,
              used_qty:
                res.goods_adj < 0
                  ? res.goods_sales + res.goods_return - res.goods_adj
                  : res.goods_sales + res.goods_return,
              strip_qty: res.goods_org_qty / res.pack_type,
              ptr: Number(res?.p_rate / res?.pack_type).toFixed(2),
              stock_qty: res.goods_org_qty
                ? Number(res.stock_qty | 0) +
                  Number(res.goods_org_qty | 0) -
                  Number(res.goods_return | 0)
                : Number(res.stock_qty | 0),
            }));
            const newError = billProducts.map((ele: any) => ({
              qty: false,
              // discount: false,
            }));
            setFormError(newError);
            setBillProducts(data);
            const newBatchIds = billProducts.map(
              (ele: { batch_id: number }) => ele.batch_id
            );

            setBatchIdNo(newBatchIds);
            setBillingSummary((prevState: any) => ({
              ...prevState,
              goods_value: purchaseBill.orig_goods_val,
              tax: purchaseBill.gst_amt,
              roundedOff: purchaseBill.roundedOff || 0.0,
              netAmount: purchaseBill.bill_amt,
            }));
            calculateBillData(data);
            setInitialSequenceNumber(purchaseBill.invoice_no);

            setIsPageLoading(false);
            isCreatePrescription = false;
            isSequenceNumberChanged = false;

            handleSequence();
          }
        })
        .catch((err: any) => {
          setIsPageLoading(false);
          console.log(err);
        });
    } else {
      isCreatePrescription = true;
      isSequenceNumberChanged = false;
      handleSequence();
    }
  }, [id]);
  // useEffect(() => {
  //   if (info.issuer) {
  //     const data: any = {
  //       department: info.issuer,
  //       selected_batches: batchIdNo,
  //     };
  //     getGoodsIssueProduct(data)
  //       .then((res: any) => {
  //         setProductRow(res.data);
  //       })
  //       .catch((err: any) => {
  //         console.log(err);
  //       });
  //   }
  // }, [info.issuer, info.receiver, batchIdNo]);
  const handleClick = useCallback(() => {
    setBillProducts((prev: any) => [
      ...prev,
      {
        ...initialBillProduct,
        id: prev.length,
      },
    ]);
    setFormError((prev) => [
      ...prev,
      {
        qty: false,
        // discount: false
      },
    ]);
  }, []);
  const handleDelete = () => {
    const { index, batchNo, id }: any = isDeleteDialogOpen.selectedRow;
    setLoading(true);
    const newBillProducts = [...billProducts];
    newBillProducts.splice(index, 1);
    setBillProducts(newBillProducts);

    const newFormError = [...formError];
    newFormError.splice(index, 1);
    setFormError(newFormError);

    if (batchNo) {
      const newBatchIds = [...batchIdNo];
      const findBatchIndex = batchIdNo.findIndex(
        (ele) => ele === Number(batchNo)
      );
      newBatchIds.splice(findBatchIndex, 1);
      setBatchIdNo(newBatchIds);
    }
    if (id) {
      setDeletedId((prevState: any) => [...prevState, id]);
    }
    calculateBillData(newBillProducts);
    setLoading(false);
    setIsDeleteDialogOpen({ isOpen: false, selectedRow: null });
  };

  const calculateBillData = (allProducts: any) => {
    const newValues =
      allProducts?.length > 0 &&
      allProducts.map((ele: any) => {
        const newNum = Number(ele.qty * ele.ptr) - ele.discount_amount;
        const newGoodsValue =
          appConfiguration?.goods_issue_tax === "exclusive"
            ? newNum
            : newNum / (1 + ele.product_name?.tax_id / 100);
        const newTaxValue = (ele.product_name?.tax_id / 100) * newGoodsValue;
        return {
          newNum: newNum,
          newGoodsValue: newGoodsValue,
          newTaxValue: newTaxValue,
          tax: {
            id: ele.product_name?.id,
            name: ele.tax,
            tax_name: ele.product_name?.taxname,
            tax_rate: ele.product_name?.tax_id,
            sub_taxes: ele.product_name?.subtax,
          },
          tax_amount: newTaxValue,
        };
      });

    const total_discount_amount =
      calculateTotalDiscountAmount(allProducts) || 0;
    const value = calculateSubTaxes(newValues) || 0;
    const newTaxValue = calculateProductAmount(newValues, "newTaxValue");
    const newGoodsValue = calculateProductAmount(newValues, "newGoodsValue");
    const newRoundOff =
      calculateRoundedOff({
        goods_value: Number(newGoodsValue),
        tax_amount: newTaxValue,
      }) || 0;
    setSummary((prevState: any) => ({
      ...prevState,
      goods_value: newGoodsValue,
      tax_amount: newTaxValue,
      bill_amount: calculateNetAmount({
        goods_value: Number(newGoodsValue),
        tax_amount: newTaxValue,
        rounded_off: newRoundOff,
      }),
      bill_total:
        calculateBillTotal({
          goods_value: Number(newGoodsValue),
          total_tax: newTaxValue,
        }) || 0,
      CGST: value.CGST,
      SGST: value.SGST,
      total_discount_amount: total_discount_amount,
      rounded_off: newRoundOff,
    }));
  };

  const calculateProductAmount = (rows: any, name: string) => {
    const updatedValue = rows?.reduce(
      (acc: any, current: any) => acc + current[name],
      0
    );
    return updatedValue || 0;
  };
  const updateRows = (currentIndex: number) => {
    const amount = calculateAmount(billProducts, currentIndex);
    const discountAmount = calculateDiscountAmount(billProducts, currentIndex);
    const taxAmount = calculateTaxAmount(billProducts, currentIndex);

    if (
      amount !== +billProducts[currentIndex]?.amount ||
      taxAmount !== billProducts[currentIndex]?.tax_amount ||
      discountAmount !== billProducts[currentIndex]?.discount_amount
    ) {
      setBillProducts((prev: any) =>
        prev.map((billProduct: any, i: number) => {
          return i === currentIndex
            ? {
                ...billProduct,
                amount: amount,
                discount_amount: discountAmount,
                tax_amount: taxAmount,
              }
            : billProduct;
        })
      );
    }
  };

  const getAllIssuerDepartmentList = async () => {
    const data = { active: 1, type: "issuer" };
    setIsSelectLoading(true);
    await getAllMasterDepartments(data)
      .then((res: any) => {
        const formattedValue = res.data?.result?.map(
          (ele: { dept_name: string; id: number; type: string }) => ({
            name: ele.dept_name,
            id: ele.id,
            type: ele.type,
          })
        );
        setIssuerList(formattedValue);
        setIsSelectLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsSelectLoading(false);
      });
  };
  const getAllReceiverDepartmentList = async () => {
    const data = { active: 1, type: "receiver" };
    setIsSelectLoading(true);
    await getAllMasterDepartments(data)
      .then((res: any) => {
        const formattedValue = res.data?.result?.map(
          (ele: { dept_name: string; id: number; type: string }) => ({
            name: ele.dept_name,
            id: ele.id,
            type: ele.type,
          })
        );
        setReceiverList(formattedValue);
        setIsSelectLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsSelectLoading(false);
      });
  };

  useEffect(() => {
    getAllIssuerDepartmentList();
    getAllReceiverDepartmentList();
    if (!id) {
      getDepartmentSequence("Goods Issue").then((res: any) => {
        if (res.data.length > 0) {
          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: res.data[0].sequence_no,
          }));
        }
      });
    }
  }, []);

  useEffect(() => {
    updateRows(currentIndex);
  }, []);

  const validateForm = (details: any, billProductList: any) => {
    let validate = true;
    const findEmptyProduct = billProductList?.find(
      (ele: { product_name: any }) => Boolean(!ele.product_name)
    );
    const checkIsEmptyRetQty = billProductList?.findIndex(
      (ele: { strip_qty: any }) => Boolean(!ele.strip_qty)
    );
    if (details.issuer === "") {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Supplier not to be empty!" })
      );
      validate = false;
    }

    if (details.receiver === "") {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Receiver not to be empty!" })
      );
      validate = false;
    }
    if (Boolean(formError.find((ele) => ele.qty))) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please check Table Values!" })
      );
      validate = false;
    }
    if (checkIsEmptyRetQty !== -1) {
      const newFormError = [...formError];
      newFormError[checkIsEmptyRetQty as number].qty = true;
      setFormError(newFormError);
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please check Table Values!" })
      );
      validate = false;
    }
    if (findEmptyProduct) {
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "Please Add / Remove Empty Product",
        })
      );
      validate = false;
    }
    return validate;
  };

  const handleSubmit = async () => {
    if (validateForm(info, billProducts)) {
      setIsSubmitLoaded(true);
      const productDetails: any =
        billProducts.length > 0
          ? billProducts.map((obj: any) => {
              let newObj = {};
              const {
                product_id,
                pack_type,
                batch_id,
                batch_no,
                expires_at,
                tax_id,
                tax_type,
                supplier_id,
                mrp,
                p_rate,
              } = obj.product_name;
              newObj = {
                product_id,
                pack_type,
                expires_at,
                qty: obj.qty,
                discount: obj.discount,
                supplier_id,
                tax_id,
                tax_type,
                mrp,
                p_rate,
                amount: obj.amount,
                batch_no: batch_no,
                batch_id: batch_id,
                id: obj.id,
                discount_type: obj.discount_type,
              };
              return newObj;
            })
          : 0;
      const data: any = {
        from_dept: info.issuer,
        to_dept: info.receiver,
        invoice_no: info.bill_no,
        invoice_no_modified: info.bill_no === initialSequenceNumber ? 0 : 1,
        product: [...productDetails],
        tax_settings: appConfiguration?.goods_issue_tax === "exclusive" ? 1 : 0,
        bill_amt: summary.bill_amount,
        orig_goods_val: summary.goods_value.toFixed(2),
        disc_goods_val: summary.goods_value.toFixed(2),
        disc_val: summary.total_discount_amount,
        total_amt: summary.bill_total,
        gst_amt: summary.tax_amount,
        tax_split_info: JSON.stringify({
          CGST: summary.CGST,
          SGST: summary.SGST,
        }),
      };
      if (id) {
        data.is_transfer = 0;
        if (deletedId?.length > 0) {
          data.hidden_del_id = deletedId.join();
        }
        updateGoodsIssueById(id as string, data as any)
          .then((res: any) => {
            navigate("/procurement/goods-issue");
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            setIsSubmitLoaded(false);
          })
          .catch((err: any) => {
            if (err?.response?.data?.errors?.data) {
              const { data, message } = err.response.data.errors;
              const newBillProducts = [...billProducts];
              newBillProducts[
                data.key
              ].batch_no = `${data.batch_no}#${data.current_stock}`;
              newBillProducts[data.key].stock_qty = data.current_stock;
              setBillProducts(newBillProducts);
              const newFormError = [...formError];
              newFormError[data.key]["qty"] = true;
              setFormError(newFormError);

              dispatch(setSnackBarFailed({ snackBarMessage: message }));
              console.log(err);
              setIsSubmitLoaded(false);
            } else if (typeof err?.response?.data?.errors === "string") {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
            }
            setIsSubmitLoaded(false);
            console.log(err);
          });
      } else {
        appConfiguration?.goods_issue_approval
          ? await createTempGoodsIssue(data as any)
              .then((res: any) => {
                navigate("/procurement/goods-issue");
                dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
                setIsSubmitLoaded(false);
              })
              .catch((err: any) => {
                dispatch(
                  setSnackBarFailed({
                    snackBarMessage: err.response.data.errors,
                  })
                );
                setIsSubmitLoaded(false);
                console.log(err);
              })
          : await createGoodsIssue(data as any)
              .then((res: any) => {
                navigate("/procurement/goods-issue");
                dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
                setIsSubmitLoaded(false);
              })
              .catch((err: any) => {
                if (err?.response?.data?.errors?.data) {
                  const { data, message } = err.response.data.errors;

                  const newBillProducts = [...billProducts];
                  newBillProducts[
                    data.key
                  ].batch_no = `${data.batch_no}#${data.current_stock}`;
                  newBillProducts[data.key].stock_qty = data.current_stock;
                  setBillProducts(newBillProducts);
                  const newFormError = [...formError];
                  newFormError[data.key]["qty"] = true;
                  setFormError(newFormError);

                  dispatch(setSnackBarFailed({ snackBarMessage: message }));
                  console.log(err);
                  setIsSubmitLoaded(false);
                } else if (typeof err?.response?.data?.errors === "string") {
                  dispatch(
                    setSnackBarFailed({
                      snackBarMessage: err?.response?.data?.errors,
                    })
                  );
                }
                setIsSubmitLoaded(false);
                console.log(err);
              });
      }
    }
  };
  const handleChangeSummary = (e: any) => {
    const { name, value } = e.target;
    let numericValue = value;
    if (
      name !== "payment_method" &&
      name !== "bill_discount_type" &&
      name !== "invoice_file"
    ) {
      // Replace any invalid characters with an empty string
      const validValue = value?.replace(/[^-?\d.]/g, "");

      // Ensure there is only one decimal point and handle the leading minus sign
      numericValue = validValue
        ?.split("")
        ?.reduce((acc: any, char: any, index: any) => {
          if (char === "-" && index !== 0) {
            return acc; // Remove invalid minus sign not at the start
          }
          if (char === "." && acc.includes(".")) {
            return acc; // Remove additional decimal points
          }
          return acc + char;
        }, "");
    }

    setSummary((prev: any) => {
      const newState = { ...prev };
      newState[name] = numericValue;
      if (
        name === "adjustment" ||
        name === "bill_discount" ||
        name === "bill_discount_type"
      ) {
        newState.bill_discount_amount =
          calculateBillDataDiscountAmount(newState);
        newState.rounded_off = calculateRoundedOff({
          goods_value: Number(newState.goods_value).toFixed(2),
          tax_amount: Number(newState.tax_amount).toFixed(2),
          discount: Number(newState.bill_discount_amount),
        });
        newState.bill_amount = calculateNetAmount({
          goods_value: Number(newState.goods_value).toFixed(2),
          tax_amount: Number(newState.tax_amount).toFixed(2),
          rounded_off: newState.rounded_off,
          discount: newState.bill_discount_amount,
        });
      }

      if (
        newState.bill_discount_type === "%" &&
        (name === "bill_discount_type" || name === "bill_discount")
      ) {
        setSummaryErrors((prevState: any) => ({
          ...prevState,
          bill_discount:
            Number(newState.bill_discount) > 100
              ? "Discount percentage cannot greater than 100"
              : "",
        }));
      } else {
        setSummaryErrors((prevState: any) => ({
          ...prevState,
          bill_discount:
            Number(newState.bill_total) < Number(newState.bill_discount_amount)
              ? "Discount percentage cannot greater than 100"
              : "",
        }));
      }
      return {
        ...newState,
      };
    });
  };
  const handleBarcodeOnChange = useCallback(
    (barcodeVal: string | number) => {
      if (!info.issuer || !info.receiver) {
        return;
      }
      const isExist = billProducts.find(
        (ele: { barcode: number }) => ele.barcode === barcodeVal
      );
      if (isExist) {
        const findRow: any = productRow.find(
          (ele: { barcode: number }) => ele.barcode === barcodeVal
        );
        const findRowIndex = productRow.findIndex(
          (ele: { barcode: number }) => ele.barcode === barcodeVal
        );
        if (findRow !== undefined && findRowIndex !== -1) {
          setBillProducts((prevState: any) => {
            let newState = [...prevState];
            if (newState.length > 1) {
              newState[findRowIndex] = {
                ...newState[findRowIndex],
                product_name: findRow,
                id: findRow?.id,
                mrp: handleCalculateAmount({
                  qty: 1,
                  product_name: {
                    pack_type: findRow?.pack_type || 0,
                    mrp: findRow?.mrp || 0,
                  },
                }),
                discount_amount:
                  calculateDiscountAmount(
                    newState[findRowIndex]?.amount,
                    newState[findRowIndex]?.product_name?.discount || 0,
                    newState[findRowIndex]?.product_name?.discount_type
                  ) || 0,
                expiry_date: findRow?.expires_at,
                batch_no: `${findRow?.batch_no}#${findRow?.original_qty}`,
                amount: 0,
                discount: `${findRow?.discount || 0}%`,
                tax: `${findRow?.taxname}-${findRow?.tax_id}%`,
                pack: findRow?.pack_type,
                barcode: findRow?.barcode,
              };
            }
            if (newState.length === 1) {
              newState[findRowIndex] = {
                ...newState[findRowIndex],
                product_name: findRow,
                pack: findRow?.pack_type,
                id: findRow?.id,
                expiry_date: findRow?.expires_at,
                mrp: handleCalculateAmount({
                  qty: 1,
                  product_name: {
                    pack_type: findRow?.pack_type || 0,
                    mrp: findRow?.mrp || 0,
                  },
                }),
                discount_amount:
                  calculateDiscountAmount(
                    newState[findRowIndex]?.amount,
                    newState[findRowIndex]?.product_name?.discount || 0,
                    newState[findRowIndex]?.product_name?.discount_type
                  ) || 0,
                batch_no: `${findRow?.batch_no}#${findRow?.original_qty}`,
                amount: 0,
                discount: `${findRow?.discount || 0}%`,
                tax: `${findRow?.taxname}-${findRow?.tax_id}%`,
                barcode: findRow?.barcode,
              };
            }

            return newState;
          });
          handleInputChange(
            {
              target: {
                name: "qty",
                value: `${Number(billProducts[findRowIndex].qty) + 1}`,
              },
            },
            findRowIndex
          );
        }
      }
      if (!isExist) {
        const findRow: any = productRow.find(
          (ele: { barcode: number }) => ele?.barcode === barcodeVal
        );
        const findRowIndex = productRow.findIndex(
          (ele: { barcode: number }) => ele?.barcode === barcodeVal
        );
        setBillProducts((prevState: any) => {
          let newState = [...prevState];
          if (newState.length > 1) {
            newState[findRowIndex] = {
              ...newState[findRowIndex],
              product_name: findRow,
              id: findRow?.id,
              mrp: handleCalculateAmount({
                qty: 1,
                product_name: {
                  pack_type: findRow?.pack_type || 0,
                  mrp: findRow?.mrp || 0,
                },
              }),
              discount_amount:
                calculateDiscountAmount(
                  newState[findRowIndex]?.amount,
                  newState[findRowIndex]?.product_name?.discount || 0,
                  newState[findRowIndex]?.product_name?.discount_type
                ) || 0,
              expiry_date: findRow?.expires_at,
              batch_no: `${findRow?.batch_no}#${findRow?.original_qty}`,
              amount: 0,
              discount: `${findRow?.discount || 0}%`,
              tax: `${findRow?.taxname}-${findRow?.tax_id}%`,
              pack: findRow?.pack_type,
              barcode: findRow?.barcode,
            };
          }
          if (newState.length === 1) {
            newState[findRowIndex] = {
              ...newState[findRowIndex],
              product_name: findRow,
              pack: findRow?.pack_type,
              id: findRow?.id,
              expiry_date: findRow?.expires_at,
              mrp: handleCalculateAmount({
                qty: 1,
                product_name: {
                  pack_type: findRow?.pack_type,
                  mrp: findRow?.mrp,
                },
              }),
              discount_amount:
                calculateDiscountAmount(
                  newState[findRowIndex]?.amount,
                  newState[findRowIndex]?.product_name?.discount || 0,
                  newState[findRowIndex]?.product_name?.discount_type
                ) || 0,
              batch_no: `${findRow?.batch_no}#${findRow?.original_qty}`,
              amount: 0,
              discount: `${findRow?.discount || 0}%`,
              tax: `${findRow?.taxname}-${findRow?.tax_id}%`,
              barcode: findRow?.barcode,
            };
          }

          return newState;
        });
        if (findRowIndex !== -1) {
          handleInputChange(
            {
              target: {
                name: "qty",
                value: `${Number(billProducts[findRowIndex]?.qty) + 1}`,
              },
            },
            findRowIndex
          );
          setInfo((prevState: any) => ({ ...prevState, barcode: "" }));
        }
      }
    },
    [billProducts, productRow, handleInputChange, calculateDiscountAmount]
  );
  const debouncedHandleBarcodeOnChange = debounce(handleBarcodeOnChange, 300);
  return (
    <>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <Grid sx={{ width: "92vw" }}>
          <Info
            info={info}
            setInfo={setInfo}
            issuerList={issuerList}
            receiverList={receiverList}
            handleBarcodeOnChange={debouncedHandleBarcodeOnChange}
            isManual={isManual}
            isManualAdded={isManualAdded}
            setIsManual={setIsManual}
            setSequenceDialogBox={setSequenceDialogBox}
            handleInvoiceNoChange={handleInvoiceNoChange}
            customSequence={customSequence}
            selectLoading={isSelectLoading}
          />

          <Grid
            sx={{
              width: {
                xs: "92vw",
              },
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              overflowX: { md: "scroll", lg: "hidden" },
            }}
          >
            <TableContainer
              className="table-container"
              component={Paper}
              sx={{
                ...styles.tableContainerStyle,
              }}
            >
              <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {TableHeaderCells.map((cell: any, index: number) => {
                      return (
                        <TableCell
                          align={cell.alignment}
                          sx={cell.sx}
                          key={index}
                        >
                          {cell.TableCellName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    height: "auto",
                    width: {
                      xs: "400vw",
                      sm: "200vw",
                      md: "120vw",
                      lg: "90vw",
                    },
                  }}
                >
                  {billProducts?.map((row: any, index: number) => {
                    return (
                      <ProductRow
                        key={row.id}
                        index={index}
                        row={row}
                        billId={id}
                        styles={styles}
                        allRows={productRow}
                        formError={formError}
                        infoData={info}
                        batchIdNo={batchIdNo}
                        totalLength={billProducts.length}
                        handleInputChange={handleInputChange}
                        handleAutoCompleteInputChange={
                          handleAutoCompleteInputChange
                        }
                        handleDelete={handleDelete}
                        handleAutoCompleteChange={handleAutoCompleteChange}
                        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                        // handleAutocompleteInputChange={
                        //   handleAutocompleteInputChange
                        // }
                      />
                    );
                  })}
                </TableBody>
              </Table>
              <Grid
                sx={{
                  width: {
                    xs: "400vw",
                    sm: "200vw",
                    md: "120vw",
                    lg: "90vw",
                  },
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "0px 0px 5px 5px",
                  border: "1px solid",
                  borderColor: "var(--table-border)",
                  borderTop: 0,
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: {
                      xs: "39%",
                      lg: "39%",
                    },
                  }}
                >
                  <Button
                    variant="text"
                    buttonText="+ Add Product"
                    handleClick={handleClick}
                    sx={{
                      borderRadius: "0px",
                      width: "auto",
                      height: "auto",
                      fontWeight: 400,
                      border: 0,
                      color: "primary.main",
                      "&:hover": {
                        backgroundColor: "initial",
                        color: "primary.main",
                        borderColor: "initial",
                      },
                      "&.Mui-focusVisible": {
                        border: 1,
                        borderColor: "primary.main",
                        borderRadius: "5px",
                      },
                    }}
                  />
                  <Grid sx={{ display: "flex" }}>
                    <Typography sx={{ fontSize: "var(--primary-header-font-size)", fontWeight: "500" }}>
                      Discount Value :
                    </Typography>
                    <Typography sx={{ fontSize: "var(--primary-header-font-size)", fontWeight: "500" }}>
                      {summary?.total_discount_amount.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  sx={{ fontSize: "var(--primary-header-font-size)", fontWeight: "500", mr: "10.6%" }}
                >
                  Goods Value :{summary?.goods_value.toFixed(2)}
                </Typography>
              </Grid>
            </TableContainer>
          </Grid>
          <Summary
            summary={summary}
            setSummary={setSummary}
            handleSaveData={() => {}}
            handleInputChange={handleChangeSummary}
            summaryError={summaryError}
          />
          <Grid
            sx={{ display: "flex", flexDirection: "row", m: "35px 0 45px 0" }}
          >
            <SaveButton
              handleClick={() => handleSubmit()}
              loading={isSubmitLoaded}
              sx={{ width: "100px", height: "40px", mr: "20px" }}
            />
            <CancelButton sx={{ width: "100px", height: "40px" }} />
          </Grid>
        </Grid>
      )}

      <SequenceWrapper
        open={sequenceDialogBox}
        title="Goods Issue"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
      <DeleteDialog
        open={isDeleteDialogOpen.isOpen}
        loading={loading}
        onClose={() =>
          setIsDeleteDialogOpen({ isOpen: false, selectedRow: null })
        }
        onConfirm={handleDelete}
      />
      <ConfirmationDialog
        open={isOpenErrorModal.isOpen}
        title={<Box>{isOpenErrorModal.content}</Box>}
        confirmText="Okay"
        type="info"
        handleClick={() => {
          setIsOpenErrorModal({ isOpen: false, content: "" });
        }}
        onClose={() => setIsOpenErrorModal({ isOpen: false, content: "" })}
        // loading={isConfirmationDialogButtonLoading}
      />
    </>
  );
};

export default BillProducts;
