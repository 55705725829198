import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  IconButton,
  Box,
  Paper,
  CircularProgress,
  debounce,
} from "@mui/material";
import {
  CancelButton,
  SaveButton,
  TextField,
  Button,
  Select,
  CheckBox,
  TextAreaEditor,
  AutoComplete,
} from "../../../../components/basic";
import { appointmentDetailsConst } from "../../../../constants/displayText";
import {
  SettingsLightIcon,
  PrintingLightIcon,
  HistoryIcon,
  DeleteLightIcon,
  DeleteIcon,
  SettingsIcon,
} from "../../../../assets/icons";
import PrescriptionRow from "./PrescriptionRow";
import "./index.css";
import { appointmentDetailsPrescriptionsConst } from "../../../../constants/displayText";
import {
  getSymptomsList,
  getProductsList,
  getPxProductsList,
  prescriptionInfo,
  deletePrescriptionProduct,
  deletePrescription,
  updatedSequence,
  getInvoiceNumber,
  getAllUnits,
  getAllPrintNotesOpTypes,
} from "../../../../services/appointmentService";
import { ConfirmationDialog } from "../../../../components/shared";
import {
  createPrescriptionProduct,
  updatePrescriptionProduct,
} from "../../../../services/appointmentService";
import { useDispatch, useSelector } from "react-redux";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../../redux/slices/snackbar";
import { SequenceWrapper } from "../../../../components/shared";
import { RootState } from "../../../../redux/store";
import PermissionUtils from "../../../../utils/PermissionUtils";
import moment from "moment";

type PrescriptionsProps = {
  appointmentsDetails?: any;
};

const Prescriptions = ({ appointmentsDetails }: PrescriptionsProps) => {
  const {
    symptoms,
    medicineName,
    mafen,
    units,
    timeType,
    freq,
    time,
    duration,
    durationTime,
    days,
    startDate,
    ADDED_SUCCESSFULLY,
    UPDATED_SUCCESSFULLY,
    PRESCRIPTION_DELETED_SUCCESSFULLY,
    PRESCRIPTION_PRODUCT_DELETED_SUCCESSFULLY,
  } = appointmentDetailsPrescriptionsConst;

  const dispatch = useDispatch();
  const { can } = PermissionUtils();

  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        boxShadow: "none",
        overflowY: "hidden",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "auto",
          lg: "auto",
        },
        overflowX: { xs: "scroll", lg: "scroll" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        border: 0,
        borderRight: 1,
        borderLeft: 1,
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "auto",
          lg: "auto",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },

        " & .MuiTableBody-root": {
          border: 0,
        },

        "& .MuiTableCell-root": {
          minHeight: "0px",
        },

        "& .MuiTableCell-head": {
          height: "var(--table-header-height)",
          maxHeight: "var(--table-header-height)",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "var(--table-header-font-size)",
          fontWeight: "var(--table-header-font-weight)",
          borderBottom: 0,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },

        "& .MuiTableCell-body": {
          height: "var(--table-body-height) !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "var(--table-header-font-size)",
          fontWeight: "var(--table-header-font-size)",
          boxSizing: "content-box",
          m: 0,
        },

        "& .MuiTableCell-body:has(.Mui-focused)": {
          border: 1,
          borderColor: "primary.main",
          borderWidth: "2px",
          height: "var(--table-body-height)",
          p: "0px",
        },

        "& .deleteIconGrid": {
          width: "50px",
          height: "var(--table-body-height)",
          cursor: "pointer",
          position: "absolute",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          right: {
            xs: "0px",
            sm: "0px",
            md: "0px",
            lg: "-5vw",
          },
          bottom: "5px",
        },
      },

      textFieldStyle: {
        height: "100%",
        width: "auto",
        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "100%",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "var(--primary-input-text-font-size)",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "10px",
          maxHeight: "10px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "var(--primary-placeholder-font-size)",
        },
      },

      selectGridStyle: {
        "& .MuiFormControl-root": { width: "100%" },

        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
          borderWidth: 0,
          height: "10px",
          maxHeight: "10px",
          p: 0,
          top: "10px",
        },

        "& .MuiInputBase-root": {
          padding: "0px 0px 0px 10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          boxShadow: "none",
        },

        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: 0,
        },
        "& .MuiSelect-icon": {
          display: "none",
        },
        "&:hover .MuiSelect-icon": {
          display: "inline-flex",
        },
      },

      datePickerGridStyle: {
        "& .MuiGrid-root": {
          height: "100%",
          width: "100%",
        },
        "& .MuiFormControl-root": {
          height: "100%",
          width: "100%",
          position: "static",
        },
        "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container ": {
          height: "100%",
          width: "100%",
          border: 0,
          p: "0px",
        },

        "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container  >div >div .rmdp-input":
          {
            height: "100%",
            width: "95%",
            p: "0px 10px",
            border: 0,
            m: 0,
            borderWidth: "0px",
            borderRadius: 0,
          },

        "& .rmdp-wrapper": {
          position: "initial",
        },

        "& .rmdp-container": {
          height: "100% !important",
        },
        "& div": {
          height: "100% !important",
        },

        "& input": {
          height: "100%",
        },
      },
    }),
    []
  );

  const { commonVariables, appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );

  // table and editable fields variables

  const initialPrescriptions = [
    {
      prescription_id: null,
      symptoms: {
        id: Date.now(),
        symptom_id: null,
        isSymptomTooltipOpen: false,
        symptomRef: useRef(null),
        products: [
          {
            id: Date.now(),
            prescription_id: null,
            product_id: null,
            symptom_id: null,
            dosage: "", //"1-1-1-1"
            units: null, //"2"
            freq: null, //"Before Food", //medication-frontEnd
            freq_time: null, //"10",//freq-frontEnd
            freq_time_type: null, //"Hours",//time-frontEnd
            duration: {
              specific_days: null, //["mon"] - array of strings
              custom_date: null, //"string"
              interval_days: null, //"3"
              duration_count: null, //"2" //duration time .//time-frontEnd
              interval: null, // "Days"  //duration days or weeks or months, //Days-frontEnd// eat medicines for 2days with the gap of 3 days (3 days once eat tablets for 2 days)
            },
            duration_type: "", //"forever", everyday, specific days, interval days, custom days.
            is_remainder: null,
            start_date: new Date(), //"16/02/2024"
            dosage_time: [], // ["09:00:00", "09:00:00", "09:00:00", "09:00:00"]
            symptom: { id: null, symptom_name: "" },
            product: {
              id: null,
              name: "",
              generic_name: "",
              prescription: "",
            },
            isProductTooltipOpen: false,
            isStartDateTooltipOpen: false,
            productRef: useRef(null),
            startDateRef: useRef(null),
          },
        ],
      },
    },
  ];

  const [prescriptions, setPrescriptions] = useState<any>(initialPrescriptions);

  const [isSaveButtonLoading, setIsSaveButtonLoading] =
    useState<boolean>(false);

  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [symptomsOptions, setSymptomsOptions] = useState<any>([]);
  const [productsOptions, setProductsOptions] = useState<any>([]);
  const [unitsOptions, setUnitsOptions] = useState<any>([]);
  const [freqOptions, setFreqOptions] = useState<any>([]);
  const [freqTimeTypeOptions, setFreqTimeTypeOptions] = useState<any>([]);
  const [durationTypeOptions, setDurationTypeOptions] = useState<any>([]);
  const [specificDaysOptions, setSpecificDaysOptions] = useState<any>([]);
  const [intervalOptions, setIntervalOptions] = useState<any>([]);
  const [productToBeDeleted, setProductToBeDeleted] = useState<any>(null);
  const [prescriptionId, setPrescriptionId] = useState<any>(null);
  const [notesTemplateOptions, setNotesTemplateOptions] = useState<any>([]);
  const [noteTemplateType, setNoteTemplateType] = useState<any>([]);
  const [noteTemplateTypeInput, setNoteTemplateTypeInput] = useState<any>([]);

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [callUseEffect, setCallUseEffect] = useState<boolean>(false);

  const [isDeleteButtonLoading, setIsDeleteButtonLoading] =
    useState<boolean>(false);

  const [isPrescriptionProductDelete, setIsPrescriptionProductDelete] =
    useState<boolean>(true);

  const [prescriptionToBeDeleted, setPrescriptionToBeDeleted] =
    useState<any>(null);

  const [isCreate, setIsCreate] = useState<boolean>(true);
  const [isRemainder, setIsRemainder] = useState<boolean>(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] =
    useState<boolean>(false);

  const [advice, setAdvice] = useState("");

  const [content, setContent] = useState<any>("");

  const [isAutocompleteOptionsLoading, setIsAutocompleteOptionsLoading] =
    useState<boolean>(false);

  // sequence number variables

  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");

  const [sequenceNumber, setSequenceNumber] = useState<
    string | number | undefined
  >("");

  const [isSequenceWrapperOpen, setIsSequenceWrapperOpen] = useState(false);

  const [sequenceId, setSequenceId] = useState(null);

  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);

  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });

  const [isManualAdded, setIsManualAdded] = useState(false);

  const [customSequence, setCustomSequence] = useState(true);

  const [seqIsOnce, setSeqIsOnce] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });

  let isCreatePrescription = true;
  let isSequenceNumberChanged = false;

  // sequence number generator functions

  const getSequenceDetails = async () => {
    try {
      await getInvoiceNumber("Prescription").then((result: any) => {
        let data = result?.data[0]?.sequence_no;

        if (isCreatePrescription && !isSequenceNumberChanged) {
          setInitialSequenceNumber(data);
          setSequenceNumber(data);
        }

        if (isSequenceNumberChanged) {
          setSequenceNumber(data);
        }

        if (result?.data?.length > 0) {
          setSequenceId(result?.data[0]?.sequence?.id);
          const newSequenceData =
            result?.data[0]?.sequence?.sequence_preferences?.map(
              (ele: {
                id: number;
                type: string;
                value: string;
                separator: string;
              }) => ({
                id: ele?.id,
                type: ele?.type,
                value: ele?.value,
                separator: ele?.separator,
              })
            );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual:
              result?.data[0]?.sequence?.is_manual === 1 ? true : false,
            is_auto_generate:
              result?.data[0]?.sequence?.is_manual !== 1 ? true : false,
          }));
          setSeqIsOnce((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(result?.data[0]?.sequence?.is_manual === 1));
        }
      });
    } catch (error: any) {
      console.error("An error occurred:", error);
    }
  };

  const handleSequenceNoChange = (e: any) => {
    const { name, value } = e.target;
    setSequenceNumber(value);
  };

  const handleConfirmSequenceNoChange = () => {
    if (initialSequenceNumber !== sequenceNumber) {
      setSeqIsOnce((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: sequenceNumber,
      }));
      setIsSequenceWrapperOpen(true);
    }
  };

  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value !== "Select" ? value : null,
      };
      return newState;
    });
  };

  function onCloseSequenceDialogBox() {
    setIsSequenceWrapperOpen(false);
  }

  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      try {
        const data = {
          seq_preference: [...sequenceData],
          is_manual: isManual?.is_manual ? 1 : 0,
        };
        await updatedSequence(Number(sequenceId), data)?.then((res: any) => {
          if (res) {
            isSequenceNumberChanged = true;
            getSequenceDetails();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        });
      } catch (error: any) {
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
      }
    }
  };

  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };

  // textarea functions

  const handleChange = (event: any) => {
    setAdvice(event.target.value);
  };

  const handleTextAreaChange = (value: any) => {
    setContent(value);
  };

  // select options api calls

  const getSymptomsDetails = async () => {
    try {
      await getSymptomsList().then((result: any) => {
        let data = result?.data?.result;
        setSymptomsOptions(data);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getCommonVariablesDetails = async () => {
    if (commonVariables) {
      setFreqOptions(commonVariables?.freq);
      setFreqTimeTypeOptions(commonVariables?.freq_time_type);
      setDurationTypeOptions(commonVariables?.duration_type);
      setSpecificDaysOptions(commonVariables?.specific_days);
      setIntervalOptions(commonVariables?.interval);
    }
  };

  const getProductsOptions = useCallback(
    debounce(async (search?: any) => {
      setIsAutocompleteOptionsLoading(true);

      try {
        let pxProducts = appConfiguration?.px_products;

        const data = {
          search: search,
          page: 1,
          size: "20",
        };

        if (pxProducts) {
          await getPxProductsList(data).then((result: any) => {
            let data = result?.data;
            const newArray = [...productsOptions, ...data];

            const updatedArray = newArray.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.id === value.id)
            );

            setProductsOptions(updatedArray);
            setIsAutocompleteOptionsLoading(false);
          });
        } else {
          await getProductsList(data).then((result: any) => {
            let data = result?.data?.result;
            const newArray = [...productsOptions, ...data];

            const updatedArray = newArray.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.id === value.id)
            );

            setProductsOptions(updatedArray);
            setIsAutocompleteOptionsLoading(false);
          });
        }
      } catch (error) {
        setIsAutocompleteOptionsLoading(false);
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  const getAllUnitList = async () => {
    try {
      await getAllUnits().then((result: any) => {
        let data = result?.data?.result;

        setUnitsOptions(data);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getPrintNotesOpTypes = async () => {
    try {
      await getAllPrintNotesOpTypes().then((result: any) => {
        let data = result?.data?.result;

        setNotesTemplateOptions(data);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // table cell handle change functions

  const handleSymptomAdd = useCallback(() => {
    setPrescriptions((prevPrescriptions: any) => [
      ...prevPrescriptions,
      {
        prescription_id: prevPrescriptions[0]?.prescription_id,
        symptoms: {
          id: Date.now(),
          symptom_id: null,
          isSymptomTooltipOpen: false,
          symptomRef: React.createRef(),
          products: [
            {
              id: Date.now(),
              isProductTooltipOpen: false,
              isStartDateTooltipOpen: false,
              productRef: React.createRef(),
              startDateRef: React.createRef(),
              prescription_id: prevPrescriptions[0]?.prescription_id,
              product_id: null,
              symptom_id: null,
              dosage: "",
              units: "",
              freq: "",
              freq_time: "",
              freq_time_type: "",
              duration: {
                specific_days: [],
                custom_date: "",
                interval_days: "",
                duration_count: "",
                interval: "",
              },
              duration_type: "",
              is_remainder: 0,
              start_date: new Date(),
              dosage_time: ["09:00:00", "09:00:00", "09:00:00", "09:00:00"],
              product: {
                id: null,
                name: "",
              },
              symptom: {
                id: null,
                symptom_name: "",
              },
            },
          ],
        },
      },
    ]);
  }, []);

  const handleProductAdd = useCallback((symptom_id: number) => {
    setPrescriptions((prevPrescriptions: any) =>
      prevPrescriptions.map((prescription: any) => {
        return prescription?.symptoms?.id === symptom_id
          ? {
              ...prescription,
              symptoms: {
                ...prescription?.symptoms,
                products: [
                  ...prescription?.symptoms?.products,
                  {
                    id: Date.now(),
                    isProductTooltipOpen: false,
                    isStartDateTooltipOpen: false,
                    productRef: React.createRef(),
                    startDateRef: React.createRef(),
                    prescription_id: null,
                    product_id: null,
                    symptom_id: prescription.symptoms.products[0].symptom_id,
                    dosage: "",
                    units: "",
                    freq: "",
                    freq_time: "",
                    freq_time_type: "",
                    duration: {
                      specific_days: [""],
                      custom_date: "",
                      interval_days: "",
                      duration_count: "",
                      interval: "",
                    },
                    duration_type: "",
                    is_remainder: null,
                    start_date: new Date(),
                    dosage_time: [],
                    product: null,
                    symptom: {
                      id: null,
                      symptom_name: "",
                    },
                  },
                ],
              },
            }
          : prescription;
      })
    );
  }, []);

  const handleInputChange = useCallback((e: any, productId: number) => {
    const { name, value } = e.target;

    setPrescriptions((prevPrescriptions: any) => {
      return prevPrescriptions.map((pres: any) => {
        let products = pres?.symptoms?.products;

        return {
          ...pres,
          symptoms: {
            ...pres.symptoms,
            products: products.map((product: any) => {
              if (product.id === productId) {
                if (
                  name === "specific_days" ||
                  name === "interval_days" ||
                  name === "custom_date" ||
                  name === "duration_count" ||
                  name === "interval"
                ) {
                  return {
                    ...product,
                    duration: {
                      ...product.duration,
                      [name]: value,
                    },
                  };
                } else {
                  return {
                    ...product,
                    [name]: value,
                  };
                }
              }
              return product;
            }),
          },
        };
      });
    });
  }, []);

  const handleDateChange = useCallback(
    (productId: number, newValue: any, name: any) => {
      setPrescriptions((prevPrescriptions: any) => {
        return prevPrescriptions.map((pres: any) => {
          let products = pres?.symptoms?.products;

          return {
            ...pres,
            symptoms: {
              ...pres.symptoms,
              products: products.map((product: any) => {
                if (product.id === productId) {
                  if (name === "custom_date") {
                    return {
                      ...product,
                      duration: {
                        ...product.duration,
                        [name]: newValue.validatedValue[0],
                      },
                    };
                  } else {
                    return {
                      ...product,
                      [name]: newValue.validatedValue[0],
                    };
                  }
                }
                return product;
              }),
            },
          };
        });
      });
    },
    []
  );

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setIsRemainder(checked);
  };

  const handleAutoCompleteChange = useCallback(
    (e: any, newValue: any, productId: number, name: string) => {
      if (name === "symptom_id") {
        setPrescriptions((prevPrescriptions: any) => {
          return prevPrescriptions.map((pres: any) => {
            let products = pres?.symptoms?.products;
            if (pres.symptoms.id === productId) {
              return {
                ...pres,
                symptoms: {
                  ...pres.symptoms,
                  [name]: newValue ? newValue?.id : null,
                  products: products.map((product: any) => {
                    return {
                      ...product,
                      symptom_id: newValue ? newValue?.id : null,
                      symptom: {
                        id: newValue ? newValue?.id : null,
                        symptom_name: newValue?.symptom_name
                          ? newValue?.symptom_name
                          : "",
                      },
                    };
                  }),
                },
              };
            }
            return pres;
          });
        });
      }

      if (name === "product_id") {
        setPrescriptions((prevPrescriptions: any) => {
          return prevPrescriptions.map((pres: any) => {
            let products = pres?.symptoms?.products;

            return {
              ...pres,
              symptoms: {
                ...pres.symptoms,
                products: products.map((product: any) => {
                  if (product.id === productId) {
                    return {
                      ...product,
                      [name]: newValue ? newValue?.id : null,
                      product: {
                        id: newValue ? newValue?.id : null,
                        name: newValue ? newValue?.name : "",
                      },
                    };
                  }
                  return product;
                }),
              },
            };
          });
        });
      }

      if (name === "noteTemplateType") {
        setNoteTemplateType(newValue);
        setContent(newValue?.content);
      }
    },
    []
  );

  const handleAutoCompleteInputChange = useCallback(
    (e: any, newInputValue: any, productId: number, name: string) => {
      if (name === "symptom_name") {
        setPrescriptions((prevPrescriptions: any) => {
          return prevPrescriptions.map((pres: any) => {
            let products = pres?.symptoms?.products;
            if (pres.symptoms.id === productId) {
              return {
                ...pres,
                symptoms: {
                  ...pres.symptoms,
                  products: products.map((product: any) => {
                    return {
                      ...product,
                      symptom: {
                        ...product.symptom,
                        symptom_name: newInputValue,
                      },
                    };
                  }),
                },
              };
            }
            return pres;
          });
        });
      }

      if (name === "product_name") {
        setPrescriptions((prevPrescriptions: any) => {
          return prevPrescriptions.map((pres: any) => {
            let products = pres?.symptoms?.products;

            return {
              ...pres,
              symptoms: {
                ...pres.symptoms,
                products: products.map((product: any) => {
                  if (product.id === productId) {
                    return {
                      ...product,
                      product: {
                        ...product.product,
                        name: newInputValue,
                      },
                    };
                  }
                  return product;
                }),
              },
            };
          });
        });

        getProductsOptions(newInputValue);
      }

      if (name === "noteTemplateTypeInput") {
        setNoteTemplateTypeInput(newInputValue);
      }
    },
    []
  );

  const updatePrescriptionAfterProductDelete = () => {
    setPrescriptions((prev: any) => {
      return prev.filter((item: any) => item.symptoms.products.length > 0);
    });
  };

  // prescriptions crud operation api functions

  const setToInitialState = async () => {
    setProductToBeDeleted(null);
    setProductToBeDeleted(null);
    setIsPrescriptionProductDelete(false);
    setIsConfirmationDialogOpen(false);
    setIsDeleteButtonLoading(false);
  };

  const getPrescriptionDetails = async () => {
    if (!Boolean(appointmentsDetails.id)) {
      return false;
    }

    try {
      setIsPageLoading(true);
      await prescriptionInfo(appointmentsDetails.id).then((result: any) => {
        const data = result?.data[0]?.prescription_product;

        const groupedData = data?.reduce(
          (acc: any, curr: any, index: number) => {
            const { prescription_id, symptom_id, ...rest } = curr;

            const existingEntry = acc.find(
              (item: any) => item?.symptoms?.symptom_id === symptom_id
            );

            if (existingEntry) {
              existingEntry?.symptoms?.products?.push({
                ...rest,
                symptom_id: symptom_id,
                isProductTooltipOpen: false,
                isStartDateTooltipOpen: false,
                productRef: React.createRef(),
                startDateRef: React.createRef(),
              });
            } else {
              acc.push({
                prescription_id,
                symptoms: {
                  id: Date.now() + index,
                  symptom_id: symptom_id,
                  isSymptomTooltipOpen: false,
                  symptomRef: React.createRef(),
                  products: [
                    {
                      ...rest,
                      symptom_id: symptom_id,
                      isProductTooltipOpen: false,
                      isStartDateTooltipOpen: false,
                      productRef: React.createRef(),
                      startDateRef: React.createRef(),
                    },
                  ],
                },
              });
            }
            return acc;
          },
          []
        );

        let isRemainder = result?.data[0]?.is_remainder;

        if (isRemainder == 1) {
          setIsRemainder(true);
        } else {
          setIsRemainder(false);
        }

        if (result?.data?.length > 0) {
          setPrescriptions(groupedData);
          setInitialSequenceNumber(result?.data[0]?.invoice_no);
          setSequenceNumber(result?.data[0]?.invoice_no);
          setPrescriptionId(result?.data[0]?.id);
          setContent(result?.data[0]?.content);
          setAdvice(result?.data[0]?.advice);
          setIsCreate(false);
          isCreatePrescription = false;
          isSequenceNumberChanged = false;
          setIsDeleteButtonDisabled(false);
          getSequenceDetails();
        } else {
          isCreatePrescription = true;
          isSequenceNumberChanged = false;
          setPrescriptions(initialPrescriptions);
          getSequenceDetails();
          setIsCreate(true);
          setIsDeleteButtonDisabled(true);
        }

        setIsPageLoading(false);
      });
    } catch (error) {
      console.error("An error occurred:", error);
      setIsPageLoading(false);
    }
  };

  const prescriptionProductCreate = async () => {
    try {
      if (appointmentsDetails?.id === undefined) {
        return false;
      }

      setIsSaveButtonLoading(true);

      const transformedData = prescriptions.flatMap((item: any) => {
        return (
          item?.symptoms?.products?.map((product: any) => {
            return {
              id: product?.id.toString().length > 12 ? null : product?.id,
              prescription_id: isCreate
                ? product?.prescription_id
                : prescriptionId,
              product_id: product?.product_id,
              symptom_id: product?.symptom_id,
              dosage: product?.dosage,
              units: product?.units,
              freq: product?.freq,
              freq_time: product?.freq_time,
              freq_time_type: product?.freq_time_type,
              duration: {
                specific_days:
                  product?.duration_type === "specific_days"
                    ? product?.duration?.specific_days
                    : null,
                custom_date:
                  product?.duration_type === "custom_date"
                    ? product?.duration?.custom_date
                    : null,
                interval_days:
                  product?.duration_type === "interval_days"
                    ? product?.duration?.interval_days
                    : null,
                duration_count:
                  product?.duration_type === "custom_date" ||
                  product?.duration_type === "forever" ||
                  product?.duration_type === ""
                    ? null
                    : product?.duration?.duration_count,
                interval:
                  product?.duration_type === "custom_date" ||
                  product?.duration_type === "forever" ||
                  product?.duration_type === ""
                    ? null
                    : product?.duration?.interval,
              },
              duration_type: product?.duration_type,
              start_date: moment(product?.start_date, ["DD/MM/YYYY"]).format(
                "DD/MM/YYYY"
              ),
            };
          }) || []
        );
      });

      const data = {
        remainder: isRemainder ? 1 : 0,
        invoice_no: sequenceNumber,
        invoice_no_modified: sequenceNumber === initialSequenceNumber ? 0 : 1,
        appoint_id: appointmentsDetails?.id,
        patient_id: appointmentsDetails?.patient_id,
        advice: advice,
        content: content,
        pres: transformedData,
      };

      if (isCreate) {
        await createPrescriptionProduct(data).then((result) => {
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: ADDED_SUCCESSFULLY,
            })
          );
          setIsCreate(false);
          setIsSaveButtonLoading(false);
          getPrescriptionDetails();
        });
      } else {
        if (!appointmentsDetails.id || !prescriptionId) {
          setIsSaveButtonLoading(false);
          return false;
        }

        await updatePrescriptionProduct(prescriptionId, data).then((result) => {
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: UPDATED_SUCCESSFULLY,
            })
          );
          setIsSaveButtonLoading(false);
          getPrescriptionDetails();
        });
      }
    } catch (error: any) {
      setIsSaveButtonLoading(false);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.errors,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const handleSaveData = () => {
    const isSymptomNotFilled = prescriptions
      .map((pres: any) => {
        return pres.symptoms.symptom_id;
      })
      .includes(null);

    const isProductNotFilled = prescriptions
      .flatMap((item: any) => {
        return (
          item?.symptoms?.products?.map((product: any) => {
            return product?.product_id;
          }) || []
        );
      })
      .includes(null);

    const isStartDateNotFilled = prescriptions
      .flatMap((item: any) => {
        return (
          item?.symptoms?.products?.map((product: any) => {
            return product?.start_date;
          }) || []
        );
      })
      .includes(null || undefined);

    if (isSymptomNotFilled) {
      setTimeout(() => {
        setPrescriptions((prevPrescriptions: any) => {
          return prevPrescriptions.map((pres: any) => {
            return {
              ...pres,
              symptoms: {
                ...pres.symptoms,
                isSymptomTooltipOpen: !Boolean(pres.symptoms.symptom_id),
              },
            };
          });
        });
      }, 300);

      const reversedArray = prescriptions?.slice().reverse();

      reversedArray?.forEach((pres: any) => {
        if (
          pres?.symptoms?.symptomRef?.current &&
          !Boolean(pres?.symptoms?.symptom_id)
        ) {
          pres?.symptoms?.symptomRef?.current.focus();
        }
      });
    } else if (isProductNotFilled) {
      setTimeout(() => {
        setPrescriptions((prevPrescriptions: any) => {
          return prevPrescriptions.map((pres: any) => {
            let products = pres?.symptoms?.products;

            return {
              ...pres,
              symptoms: {
                ...pres.symptoms,
                products: products.map((product: any) => {
                  return {
                    ...product,
                    isProductTooltipOpen: !Boolean(product?.product_id),
                  };
                }),
              },
            };
          });
        });
      }, 300);

      const reversedArray = prescriptions?.slice().reverse();

      reversedArray?.forEach((pres: any) => {
        pres?.symptoms?.products?.forEach((product: any) => {
          if (product?.productRef?.current && Boolean(product?.product_id)) {
            product?.productRef?.current?.focus();
          }
        });
      });
    } else if (isStartDateNotFilled) {
      setPrescriptions((prevPrescriptions: any) => {
        return prevPrescriptions.map((pres: any) => {
          let products = pres?.symptoms?.products;

          return {
            ...pres,
            symptoms: {
              ...pres.symptoms,
              products: products.map((product: any) => {
                return {
                  ...product,
                  isStartDateTooltipOpen: !Boolean(product?.start_date)
                    ? true
                    : false,
                };
              }),
            },
          };
        });
      });

      const reversedArray = prescriptions?.slice().reverse();

      reversedArray?.forEach((pres: any) => {
        pres?.symptoms?.products?.forEach((product: any) => {
          if (product?.productRef?.current && Boolean(product?.product_id)) {
            product?.productRef?.current?.focus();
          }
        });
      });
    } else {
      prescriptionProductCreate();
    }
  };

  // delete functions

  const handlePrescriptionProductDelete = useCallback((productId: number) => {
    if (productId.toString().length > 10) {
      setPrescriptions((prevPrescriptions: any) => {
        return prevPrescriptions.map((pres: any) => {
          let products = pres?.symptoms?.products;
          return {
            ...pres,
            symptoms: {
              ...pres?.symptoms,
              products: products?.filter(
                (product: any) => product.id !== productId
              ),
            },
          };
        });
      });
      setCallUseEffect((prev) => !prev);
    } else {
      setIsPrescriptionProductDelete(true);
      setIsConfirmationDialogOpen(true);
      setProductToBeDeleted(productId);
    }
  }, []);

  const handleConfirmDelete = async () => {
    if (!Boolean(appointmentsDetails?.id)) {
      return false;
    }

    try {
      if (isPrescriptionProductDelete) {
        if (!Boolean(productToBeDeleted) || !Boolean(productToBeDeleted)) {
          return false;
        }
        setIsDeleteButtonLoading(true);

        await deletePrescriptionProduct(productToBeDeleted).then(
          (result: any) => {
            setToInitialState();
            setPrescriptions((prevPrescriptions: any) => {
              return prevPrescriptions.map((pres: any) => {
                let products = pres?.symptoms?.products;
                return {
                  ...pres,
                  symptoms: {
                    ...pres?.symptoms,
                    products: products?.filter(
                      (product: any) => product.id !== productToBeDeleted
                    ),
                  },
                };
              });
            });
            setCallUseEffect((prev) => !prev);
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: PRESCRIPTION_PRODUCT_DELETED_SUCCESSFULLY,
              })
            );
          }
        );
      } else {
        if (!Boolean(prescriptionToBeDeleted)) {
          return false;
        }
        setIsDeleteButtonLoading(true);

        await deletePrescription(prescriptionToBeDeleted).then(
          (result: any) => {
            getPrescriptionDetails();
            setToInitialState();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: PRESCRIPTION_DELETED_SUCCESSFULLY,
              })
            );
          }
        );
      }
    } catch (error: any) {
      setToInitialState();
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.message,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const onConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const prescriptionDelete = () => {
    if (!Boolean(appointmentsDetails?.id)) {
      return false;
    }
    setIsPrescriptionProductDelete(false);
    setIsConfirmationDialogOpen(true);
    setPrescriptionToBeDeleted(prescriptionId);
  };

  // tooltip - validation function

  const handleClose = useCallback((name: string, id: number) => {
    if (name === "isSymptomTooltipOpen") {
      setPrescriptions((prevPrescriptions: any) => {
        return prevPrescriptions.map((pres: any) => {
          if (pres.id === id) {
            return {
              ...pres,
              symptoms: {
                ...pres.symptoms,
                isSymptomTooltipOpen: false,
              },
            };
          }
          return pres;
        });
      });
    }

    if (name === "isProductTooltipOpen" || name === "isStartDateTooltipOpen") {
      setPrescriptions((prevPrescriptions: any) => {
        return prevPrescriptions.map((pres: any) => {
          let products = pres?.symptoms?.products;

          return {
            ...pres,
            symptoms: {
              ...pres.symptoms,
              products: products.map((product: any) => {
                return {
                  ...product,
                  isProductTooltipOpen: false,
                  isStartDateTooltipOpen: false,
                };
              }),
            },
          };
        });
      });
    }
  }, []);

  // useEffect

  useEffect(() => {
    getSymptomsDetails();
    getPrescriptionDetails();
    getAllUnitList();
    getProductsOptions();
    getPrintNotesOpTypes();
  }, []);

  useEffect(() => {
    getCommonVariablesDetails();
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }
  }, [commonVariables, appConfiguration]);

  useEffect(() => {
    updatePrescriptionAfterProductDelete();
  }, [callUseEffect]);

  return isPageLoading ? (
    <Grid
      sx={{
        width: "95vw",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        height: "50vh",
        background: "transparent",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid
      item
      className="prescriptions"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: {
          xs: "column",
        },
        gap: 3,
      }}
    >
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="are you surely want to delete"
        handleClick={handleConfirmDelete}
        onClose={onConfirmationDialogClose}
        loading={isDeleteButtonLoading}
      />
      <SequenceWrapper
        open={isSequenceWrapperOpen}
        title="Appointments"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
      <Grid
        sx={{
          display: "flex",
          flexDirection: {
            md: "row",
            xs: "column",
          },
          justifyContent: "space-between",
        }}
      >
        <Grid sx={{ display: "flex", alignItems: "center", pb: 1 }}>
          <Typography
            variant="h2"
            style={{
              fontSize: "var(--primary-header-font-size) !important",
              fontWeight: "var(--primary-header-font-weight)",
            }}
          >
            {appointmentDetailsConst.PRESCRIPTIONS}
          </Typography>
          {customSequence && (
            <TextField
              placeholder="INVES0001"
              value={sequenceNumber}
              onBlur={handleConfirmSequenceNoChange}
              onChange={handleSequenceNoChange}
              endPlaceholderIcon={
                <SettingsIcon
                  width="20px"
                  height="20px"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsSequenceWrapperOpen(true);
                  }}
                />
              }
              sx={{
                maxWidth: "200px",
                minWidth: "150px",
                width: "100%",
                m: "0 0px 0 15px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  borderRadius: "6px",
                  paddingLeft: "8px",
                },
              }}
            />
          )}
          <IconButton
            onClick={prescriptionDelete}
            disabled={isDeleteButtonDisabled}
            sx={{ ml: "15px" }}
          >
            {isDeleteButtonDisabled ? <DeleteLightIcon /> : <DeleteIcon />}
          </IconButton>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            pb: 1,
          }}
        >
          <CheckBox
            label="Enable Reminder"
            checked={isRemainder}
            onChange={handleCheckBoxChange}
            sx={{ mr: "10px" }}
          ></CheckBox>
          {/* <TextField
            placeholder="Investigation Template"
            sx={{
              width: "157px",
              ml: 2,
              "& .MuiOutlinedInput-root": {
                height: "48px",
                borderRadius: "8px",
                paddingLeft: "8px",
              },
            }}
          />
          <Box sx={{ m: "0 15px" }}>
            <HistoryIcon />
          </Box> */}
          {can("prescription_print") && (
            <Select
              placeholder={<PrintingLightIcon />}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-input": {
                  padding: "14px 5px",
                },
              }}
              width=""
            />
          )}
        </Grid>
      </Grid>
      <Grid className="TableContainer-grid">
        <TableContainer
          className="table-container"
          component={Paper}
          sx={{
            ...styles.tableContainerStyle,
          }}
        >
          <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "rgba(236, 244, 255, 0.8)",
                  width: {
                    xs: "400vw",
                    sm: "200vw",
                    md: "auto",
                    lg: "auto",
                  },
                }}
              >
                <TableCell>{symptoms}</TableCell>
                <TableRow>
                  <TableRow>
                    <TableCell sx={{ width: "12vw" }}>{medicineName}</TableCell>
                    <TableCell sx={{ width: "8vw" }}>{mafen}</TableCell>
                    <TableCell sx={{ width: "6vw" }}>{units}</TableCell>{" "}
                    <TableCell sx={{ width: "8vw" }}>{freq}</TableCell>
                    <TableCell sx={{ width: "8vw" }}>{time}</TableCell>
                    <TableCell sx={{ width: "8vw" }}>{timeType}</TableCell>
                    <TableCell sx={{ width: "14vw" }}>{duration}</TableCell>
                    <TableRow>
                      <TableCell sx={{ width: "10vw" }}></TableCell>
                      <TableCell sx={{ width: "10vw" }}>
                        {durationTime}
                      </TableCell>
                      <TableCell sx={{ width: "10vw" }}>{days}</TableCell>
                    </TableRow>
                    <TableCell sx={{ width: "10vw" }}>{startDate}</TableCell>
                  </TableRow>
                </TableRow>
              </TableRow>
            </TableHead>

            <TableBody sx={{ height: "auto", width: "auto" }}>
              {prescriptions?.map((row: any, index: number) => {
                return (
                  <PrescriptionRow
                    key={index}
                    row={row}
                    styles={styles}
                    handleDelete={handlePrescriptionProductDelete}
                    handleClick={handleProductAdd}
                    symptomsOptions={symptomsOptions}
                    productsOptions={productsOptions}
                    unitsOptions={unitsOptions}
                    freqOptions={freqOptions}
                    freqTimeTypeOptions={freqTimeTypeOptions}
                    durationTypeOptions={durationTypeOptions}
                    specificDaysOptions={specificDaysOptions}
                    intervalOptions={intervalOptions}
                    handleInputChange={handleInputChange}
                    handleDateChange={handleDateChange}
                    handleAutoCompleteChange={handleAutoCompleteChange}
                    handleAutoCompleteInputChange={
                      handleAutoCompleteInputChange
                    }
                    handleClose={handleClose}
                    isAutocompleteOptionsLoading={isAutocompleteOptionsLoading}
                  />
                );
              })}
            </TableBody>
          </Table>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderColor: "var(--table-border)",
              borderTop: 0,
              m: "10px 0px",
              width: {
                xs: "200vw",
                sm: "150vw",
                md: "auto",
                lg: "auto",
              },
            }}
          >
            <Button
              variant="text"
              buttonText="+ Add Product"
              handleClick={handleSymptomAdd}
              sx={{
                borderRadius: "0px",
                width: "auto",
                height: "auto",
                fontWeight: 400,
                fontSize: "14px",
                border: 0,
                color: "primary.main",
                "&:hover": {
                  backgroundColor: "initial",
                  color: "primary.main",
                  borderColor: "initial",
                },
                "&.Mui-focusVisible": {
                  border: 1,
                  borderColor: "primary.main",
                },
              }}
            />
          </Grid>
        </TableContainer>
      </Grid>
      <Grid item sx={{ display: "flex", flexDirection: "column", gap: "2" }}>
        <Typography
          variant="p"
          sx={{
            color: "primary.main",
            fontWeight: "var(--primary-header-font-weight)",
            fontSize: "var(--primary-header-font-size)",
          }}
        >
          Advice
        </Typography>

        <textarea
          value={advice}
          onChange={handleChange}
          spellCheck="false"
          style={{
            width: "100%",
            height: "154px",
            borderRadius: "8px",
            padding: "10px",
            resize: "none",
            outline: "none",
            border: "1px solid var(--primary-border-color)",
            marginTop: "10px",
            fontFamily: "inter",
            fontSize: "14px",
          }}
        />
      </Grid>
      <Grid item xl={12} md={12} sm={12} xs={12}>
        <AutoComplete
          label="Notes Template"
          value={noteTemplateType}
          inputValue={noteTemplateTypeInput}
          placeholder="Select Notes Template"
          name="noteTemplateType"
          onChange={(e: any, newValue: any) =>
            handleAutoCompleteChange(e, newValue, 1, "noteTemplateType")
          }
          onInputChange={(e: any, newValue: any) =>
            handleAutoCompleteInputChange(
              e,
              newValue,
              1,
              "noteTemplateTypeInput"
            )
          }
          options={notesTemplateOptions}
          optionName="type"
          sx={{
            width: {
              xs: "300px",
            },
            height: "45px",
            boxSizing: "border-box",
            margin: 0,
            "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
              padding: "5px 0px",
              boxShadow: "none",
              borderRadius: "5px",
              height: "45px",
            },
          }}
        />
      </Grid>
      <Grid item xl={12} md={12} sm={12} xs={12}>
        <Grid>
          <TextAreaEditor
            value={content}
            onChange={(content: any) => handleTextAreaChange(content)}
          />
        </Grid>
      </Grid>
      {can("prescription_add") && (
        <Grid item sx={{ display: "flex", gap: 2, mt: 6 }}>
          <SaveButton
            sx={{ width: "100px", height: "40px" }}
            handleClick={handleSaveData}
            loading={isSaveButtonLoading}
            disabled={appointmentsDetails?.status === 2 ? true : false}
          />
          <CancelButton sx={{ width: "100px", height: "42px" }} />
        </Grid>
      )}
    </Grid>
  );
};

export default Prescriptions;
