import { Box, IconButton, Typography, debounce } from "@mui/material";
import { ConfirmationDialog, DataTable } from "../../../../components/shared";
import { useCallback, useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { CustomButton, Sort } from "../../../../components/basic";
import { ActiveStatusColor } from "../../../../utils/GeneralUtils";
import { DeleteIcon, EditIcon, PlusIcon } from "../../../../assets/icons";
import CustomFilter from "../../../../components/shared/customFilter";
import { displayText } from "../../../../constants/displayText";
import CustomerFormModal from "./CustomerForModal";
import {
  deleteCustomerMasterById,
  getAllCustomers,
} from "../../../../services/procurementMasterService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { setCurrentPage } from "../../../../redux/slices/pagination";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";

type GridRowData = Record<string, unknown>;

const Customers = () => {
  const { ADD_BUTTON_TITLE, CONFIRMATION_DELETE_DIALOG_TITLE } = displayText;
  const { procurementMasterCustomers } = useSelector(
    (state: RootState) => state.pagination
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [searchValue, setSearchValue] = useState("");
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<{
    isOpen: boolean;
    id: null | string | string;
  }>({ isOpen: false, id: null });
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isOpenAddEditModal, setIsOpenAddEditModal] = useState<{
    isOpen: boolean;
    id: null | string | string;
  }>({
    isOpen: false,
    id: null,
  });

  const handleSearchChange = (event: any) => {
    const { name, value } = event.target;
    setSearchValue(value);
    const params = {
      page: procurementMasterCustomers.page,
      limit: procurementMasterCustomers.pageSize,
      column: sortedField.field,
      order: sortedField.order,
      search: value,
    };

    debouncedGetAllCustomersList(params);
    dispatch(setCurrentPage({ key: "procurementMasterCustomers", value: 0 }));
  };

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          onClick={() => setIsOpenAddEditModal({ isOpen: true, id: row.id })}
        >
          <EditIcon />
        </IconButton>

        <IconButton
          onClick={() =>
            setIsConfirmationDialogOpen({ isOpen: true, id: row.id })
          }
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "phone",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("phone")}
        >
          <Typography variant="h5" fontSize={14}>
            Phone
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "phone" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "phone" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.phone || ""}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "email",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("email")}
        >
          <Typography variant="h5" fontSize={14}>
            Email
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "email" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "email" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.email || ""}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "gst_no",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("gst_no")}
        >
          <Typography variant="h5" fontSize={14}>
            GST No
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "gst_no" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "gst_no" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.gst_no || ""}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "dl_no",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("dl_no")}
        >
          <Typography variant="h5" fontSize={14}>
            DL No
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "dl_no" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "dl_no" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.dl_no || ""}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "credit_limit",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("credit_limit")}
        >
          <Typography variant="h5" fontSize={14}>
            Credit Limit
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "credit_limit" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "credit_limit" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.credit_limit || ""}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "address",
      flex: 1.5,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("address")}
        >
          <Typography variant="h5" fontSize={14}>
            Address
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "address" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "address" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.address || ""}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "active",
      flex: 0.5,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("active")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "active" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "active" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => {
        const statusValue = row ? row.active : null;
        const { buttonText, backgroundColor, textColor } =
          ActiveStatusColor[statusValue] || {};

        return (
          <Typography variant="h5" fontSize={14}>
            <CustomButton
              buttonText={buttonText}
              backgroundColor={backgroundColor}
              textColor={textColor}
              fontSize={"10px"}
              fontWeight={500}
              textTransform={"capitalize"}
            />
          </Typography>
        );
      },
      minWidth: 120,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  const handleConfirmationDelete = () => {
    if (isConfirmationDialogOpen.id) {
      setIsButtonLoading(true);
      deleteCustomerMasterById(isConfirmationDialogOpen.id)
        .then((res: any) => {
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          setIsButtonLoading(false);
          setIsConfirmationDialogOpen({ isOpen: false, id: null });
          const params: any = {
            page: procurementMasterCustomers.page,
            limit: procurementMasterCustomers.pageSize,
            column: sortedField.field,
            order: sortedField.order,
          };
          getAllCustomersList(params);
        })
        .catch((error) => {
          const errorMessage = error?.response?.data?.errors
            ? error?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          console.error("An error occurred:", error);

          setIsButtonLoading(false);
        });
    }
  };
  const handleSave = () => {
    const params: any = {
      page: procurementMasterCustomers.page,
      limit: procurementMasterCustomers.pageSize,
      column: sortedField.field,
      order: sortedField.order,
    };
    getAllCustomersList(params);
    setIsOpenAddEditModal({ isOpen: false, id: null });
  };

  const getAllCustomersList = (params: {
    search?: string;
    column: string;
    order: string;
    page: number;
    limit: number;
  }) => {
    setLoading(true);
    getAllCustomers(params)
      .then((res: any) => {
        if (res.data) {
          setCustomerList(res?.data?.result);
          setPageCount(res?.data?.total);
        }
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.errors
          ? error?.response?.data?.errors
          : "Unknown error occurred";
        dispatch(
          setSnackBarFailed({
            snackBarMessage: `${errorMessage}`,
          })
        );
        console.error("An error occurred:", error);
        setLoading(false);
      });
  };

  const debouncedGetAllCustomersList = useCallback(
    debounce((params) => {
      getAllCustomersList(params);
    }, 500),
    []
  );

  useEffect(() => {
    const params: any = {
      page: procurementMasterCustomers.page,
      limit: procurementMasterCustomers.pageSize,
      column: sortedField.field,
      order: sortedField.order,
    };
    getAllCustomersList(params);
  }, [procurementMasterCustomers, sortedField]);
  return (
    <Box>
      <CustomFilter
        isSearchEnabled={true}
        editButtonTitle={ADD_BUTTON_TITLE}
        onUpdateButtonClick={() =>
          setIsOpenAddEditModal({ isOpen: true, id: null })
        }
        searchOnChange={handleSearchChange}
        searchName={"search"}
        searchValue={searchValue}
        clearSearch={() => {
          handleSearchChange({ target: { name: "search", value: "" } });
          dispatch(
            setCurrentPage({ key: "procurementMasterCustomers", value: 0 })
          );
        }}
        editStartIcon={
          <Box
            sx={{
              "& path": {
                fill: "#fff",
              },
            }}
          >
            <PlusIcon width={"12px"} height={"12px"} />
          </Box>
        }
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={customerList}
        pageCount={pageCount}
        currentPage={"procurementMasterCustomers"}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen.isOpen}
        title={CONFIRMATION_DELETE_DIALOG_TITLE}
        handleClick={handleConfirmationDelete}
        onClose={() => setIsConfirmationDialogOpen({ isOpen: false, id: null })}
        loading={isButtonLoading}
      />
      {isOpenAddEditModal.isOpen && (
        <CustomerFormModal
          isOpen={isOpenAddEditModal.isOpen}
          id={isOpenAddEditModal.id}
          onClose={() => setIsOpenAddEditModal({ isOpen: false, id: null })}
          onSave={handleSave}
        />
      )}
    </Box>
  );
};

export default Customers;
