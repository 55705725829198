import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import { Button, PageLoader } from "../../../../components/basic";
import Info from "./Info";
import ProductRow from "./ProductRow";
import Summary from "./Summary";
import { billProductsConst } from "../../../../constants/displayText";
import { formattedDateWithSlash } from "../../../../utils/DateTimeFormatUtils";
import { formula } from "../../../../utils/CalculationUtils";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createPurchaseBill,
  getAllProcurementProducts,
  getExcessAmountBySupplierId,
  getPurchaseBillById,
  getTaxOptions,
  updatePurchaseBillById,
  uploadInvoiceFile,
} from "../../../../services/procurementService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import moment from "moment";
import { commonDateFormatter } from "../../../../utils/DateTimeFormatUtils";
import { RouteUrls } from "../../../../constants/routes";
import PriceMarginModal from "./PriceMarginModal";
import { updatedSequence } from "../../../../services/procurementMasterService";
import { getSequenceNoByDepartment } from "../../../../services/procurementService";

import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import { useParams } from "react-router-dom";
import { SequenceWrapper } from "../../../../components/shared";
import { ConfirmationDialog } from "../../../../components/shared";
import POProductSelectModal from "./POProductSelectModal";

const { procurementUrl, purchaseBillUrl } = RouteUrls;
type BillProduct = {
  id: number | null;
  product_id: number | null;
  pack_type: string;
  qty: number | null;
  free: number | null;
  discount: number | null;
  row_discount_type: string | null;
  batch_no: string | null;
  barcode: string | null;
  tax_type: number | null;
  p_rate: number | null;
  mrp: number | null;
  amount: number | null;
  product_name: number | null;
  autocomplete_input_value: string | number | null;
  expires_at: Date | string | null;
  discount_amount: number | null;
  tax_amount: number | null;
  used_qty: number | string;
  used_free: number | string;
  original_qty: number | null;
};

export default function BillProducts() {
  const {
    productName,
    usedQty,
    usedFree,
    packType,
    qty,
    originalQty,
    free,
    discount,
    batchNo,
    expiryDate,
    tax,
    ptr,
    mrp,
    amount,
    barCode,
  } = billProductsConst;
  const navigate = useNavigate();

  const {
    calculateTotalDiscountAmount,
    calculateDiscountAmount,
    calculateTaxAmount,
    calculateGoodsValue,
    calculateSubTaxes,
    calculateAmount,
    calculateBillTotal,
    calculateBillDataDiscountAmount,
    calculateRoundedOff,
    calculateNetAmount,
    // calculateBalance,
    calculateTotalTaxAmount,
  } = formula.purchaseBill;

  const dispatch = useDispatch();

  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: "92vw",
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "90vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "44px !important",
          maxHeight: "44px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },

        "& .MuiTableCell-root": {
          minHeight: "0px",
        },

        "& .MuiTableCell-head": {
          height: "44px",
          maxHeight: "44px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "14px",
          fontWeight: "500",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },

        "& .MuiTableCell-body": {
          height: "44px !important",
          maxHeight: "44px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "14px",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },

        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "44px",
        },

        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "44px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },
      dialogTitle: { p: "20px 24px" },
      textFieldStyle: {
        height: "100%",
        width: "auto",
        fontSize: "14px",

        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "100%",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "14px",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "44px",
          maxHeight: "32px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },

        "& input": {
          height: "100%",
        },
      },
    }),
    []
  );

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const purchase_approval = searchParams.get("isApprovalList");

  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );

  const [taxOptions, setTaxOptions] = useState<any>([]);
  const [allProducts, setAllProducts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

  const initialBillProduct = {
    id: null,
    product_id: null,
    pack_type: "",
    qty: null,
    free: null,
    discount: null,
    row_discount_type: "₹",
    batch_no: "",
    barcode: "",
    tax_type: null,
    expires_at: null,
    p_rate: 0,
    mrp: 0,
    amount: null,
    product_name: null,
    autocomplete_input_value: "",
    used_qty: "",
    used_free: "",
    original_qty: null,
    discount_amount: null,
    tax_amount: null,
  };

  const [billProducts, setBillProducts] = useState<any>([
    initialBillProduct as any,
  ]);
  const [isOpenPriceMargin, setIsOpenPriceMargin] = useState({
    isOpen: false,
    product_id: null,
  });
  const [deletedIds, setDeletedIds] = useState<string[] | number[]>([]);
  const [isOpenPoModal, setIsOpenPoModal] = useState<any>({
    isOpen: false,
    po_no: null,
    is_po_already_exist: false,
  });

  const initialSummary = {
    total_discount_amount: null,
    goods_value: null,
    disc_goods_val: null,
    CGST: null,
    SGST: null,
    total_tax: null,
    bill_total: null,
    bill_discount: null,
    bill_discount_type: "₹",
    bill_discount_amount: null,
    rounded_off: null,
    adjustment: null,
    net_amount: null,
    payment_method: "Cash",
    cash_paid: null,
    amount_paid: null,
    balance: 0,
    refunded_amount: null,
    excess_amount: null,
    net_excess: null,
    invoice_file: null,
  };

  const [summary, setSummary] = useState<any>(initialSummary);

  const initialInfoError = {
    supplier_id: "",
    dept_id: "",
    invoice_no: "",
    invoice_value: "",
    invoice_date: "",
    ref_no: "",
    due_date: "",
    po_no: "",
  };

  const [infoError, setInfoError] = useState<any>(initialInfoError);
  const [productError, setProductErrors] = useState<any>([{}]);
  const [summaryError, setSummaryErrors] = useState<any>({});

  const initialInfoData = {
    supplier_id: null,
    dept_id: null,
    department: "",
    to_dept: null,
    invoice_no: null,
    invoice_value: null,
    invoice_date: new Date(),
    ref_no: null,
    ref_no_modified: 0,
    due_date: null,
    po_no: null,
  };

  const [infoData, setInfoData] = useState<any>(initialInfoData);
  const [initialSuppliersList, setInitialSuppliersList] = useState<any>([]);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<{
    isOpen: boolean;
    content: string;
  }>({
    isOpen: false,
    content: "",
  });
  const [customSequence, setCustomSequence] = useState(true);

  const TableHeaderCells: any = [
    { TableCellName: productName, alignment: "left" },
    ...(id ? [{ TableCellName: usedQty, alignment: "right" }] : []),
    ...(id && !purchase_approval
      ? [{ TableCellName: usedFree, alignment: "right" }]
      : []),
    { TableCellName: packType, alignment: "right" },
    { TableCellName: qty, alignment: "right" },
    { TableCellName: originalQty, alignment: "right" },
    { TableCellName: free, alignment: "right" },
    ...((
      Boolean(id)
        ? infoData?.discount_setting === 2 || infoData?.discount_setting === 0
        : appConfiguration?.purchase_discount !== "overall" ||
          appConfiguration?.purchase_discount === "both"
    )
      ? [{ TableCellName: discount, alignment: "left" }]
      : []),
    { TableCellName: batchNo, alignment: "left" },
    { TableCellName: barCode, alignment: "left" },
    { TableCellName: expiryDate, alignment: "left" },
    { TableCellName: tax, alignment: "left" },
    { TableCellName: ptr, alignment: "left" },
    { TableCellName: mrp, alignment: "right" },
    {
      TableCellName: amount,
      alignment: "right",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];

  const fieldData = {
    supplier_id: {
      label: "Supplier Name",
      name: "supplier_id",
      value: infoData.supplier_id,
      placeholder: "Select Supplier",
      isError: infoError.supplier_id === "" ? false : true,
      helperText: infoError.supplier_id,
      isDisabled: id ? true : false,
    },
    dept_id: {
      label: "Department",
      name: "dept_id",
      value: infoData.dept_id,
      placeholder: "Select Department",
      isError: infoError.dept_id === "" ? false : true,
      helperText: infoError.dept_id,
      isDisabled: id ? true : false,
    },
    ...(appConfiguration?.goods_issue_disable
      ? {
          to_dept: {
            label: "Receiver",
            name: "to_dept",
            value: infoData.to_dept,
            placeholder: "Select Receiver",
            isError: infoError.to_dept === "" ? false : true,
            helperText: infoError.to_dept,
            isDisabled: Boolean(id),
          },
        }
      : {}),
    invoice_no: {
      label: "Invoice No",
      name: "invoice_no",
      value: infoData.invoice_no,
      placeholder: "Enter Invoice No",
      isError: infoError.invoice_no === "" ? false : true,
      helperText: infoError.invoice_no,
      isDisables: false,
    },
    invoice_value: {
      label: "Invoice Value",
      name: "invoice_value",
      value: infoData.invoice_value,
      placeholder: "Enter Invoice Value",
      isError: infoError.invoice_value === "" ? false : true,
      helperText: infoError.invoice_value,
      isDisables: false,
    },
    invoice_date: {
      label: "Invoice Date",
      name: "invoice_date",
      value: infoData.invoice_date,
      placeholder: "Enter Invoice Date",
      isError: infoError.invoice_date === "" ? false : true,
      helperText: infoError.invoice_date,
      isDisables: false,
    },
    ref_no: {
      label: "Bill Sequence Number",
      name: "ref_no",
      value: infoData.ref_no,
      placeholder: "Enter Bill Sequence Number",
      isError: infoError.ref_no === "" ? false : true,
      helperText: infoError.ref_no,
      isDisables: false,
    },
    due_date: {
      label: "Payment Due Date",
      name: "due_date",
      value: infoData.due_date,
      placeholder: "Payment due date",
      isError: infoError.due_date === "" ? false : true,
      helperText: infoError.due_date,
      isDisables: false,
    },
    po_no: {
      label: "PO No",
      name: "po_no",
      value: infoData.po_no,
      placeholder: "Select PO Number",
      isError: infoError.po_no === "" ? false : true,
      helperText: infoError.po_no,
      isDisabled: id ? true : false,
    },
  };

  // sequence number variables

  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");

  const [isSequenceWrapperOpen, setIsSequenceWrapperOpen] = useState(false);

  const [sequenceId, setSequenceId] = useState(null);

  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);

  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });

  const [isManualAdded, setIsManualAdded] = useState(false);

  const [seqIsOnce, setSeqIsOnce] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });

  // sequence number generator functions

  let isCreatePrescription = true;
  let isSequenceNumberChanged = false;

  const getSequenceDetails = async (name?: string) => {
    try {
      setIsLoading(true);
      await getSequenceNoByDepartment(name === undefined ? "Warehouse" : name)
        .then((res: any) => {
          let data = res?.data[0]?.sequence_no;

          if (isCreatePrescription && !isSequenceNumberChanged) {
            setInitialSequenceNumber(data);
            setInfoData((prevState: any) => ({
              ...prevState,
              ref_no: res?.data[0]?.sequence_no,
            }));

            setInfoError((prevState: any) => ({
              ...prevState,
              ref_no: "",
            }));
          }

          if (isSequenceNumberChanged) {
            setInfoData((prevState: any) => ({
              ...prevState,
              ref_no: res?.data[0]?.sequence_no,
            }));
            setInfoError((prevState: any) => ({
              ...prevState,
              ref_no: "",
            }));
          }
          if (res?.data && res?.data?.length > 0) {
            setSequenceId(res.data[0].sequence.id);
            const newSequenceData =
              res?.data[0]?.sequence?.sequence_preferences.map(
                (ele: {
                  id: number;
                  type: string;
                  value: string;
                  separator: string;
                }) => ({
                  id: ele.id,
                  type: ele.type,
                  value: ele.value,
                  separator: ele.separator,
                })
              );
            setSequenceData(newSequenceData);
            setIsManual((prevState) => ({
              ...prevState,
              is_manual: res?.data[0]?.sequence?.is_manual === 1 ? true : false,
              is_auto_generate:
                res?.data[0]?.sequence?.is_manual !== 1 ? true : false,
            }));
            setSeqIsOnce((prevState) => ({
              ...prevState,
              isOnce: false,
              isOnceSeqValue: "",
            }));
            setIsManualAdded(Boolean(res?.data[0]?.sequence?.is_manual === 1));
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } catch (error: any) {
      setIsLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const handleConfirmSequenceNoChange = () => {
    if (initialSequenceNumber !== infoData.ref_no) {
      setSeqIsOnce((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: infoData.ref_no,
      }));
      setIsSequenceWrapperOpen(true);
    }
  };

  const handleResetBillProducts = () => {
    setBillProducts([
      {
        ...initialBillProduct,
      },
    ]);
    setSummary({
      ...initialSummary,
    });
  };

  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value !== "Select" ? value : null,
      };
      return newState;
    });
  };

  const onCloseSequenceDialogBox = () => {
    setIsSequenceWrapperOpen(false);
  };

  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      try {
        const data = {
          seq_preference: [...sequenceData],
          is_manual: isManual.is_manual ? 1 : 0,
        };
        await updatedSequence(Number(sequenceId), data).then((res: any) => {
          if (res) {
            isSequenceNumberChanged = true;
            getSequenceDetails();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        });
      } catch (error: any) {
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
      }
    }
  };

  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };

  const handleClosePoModal = (isSave?: boolean) => {
    if (isSave) {
      setIsOpenPoModal((prevState: any) => ({
        ...prevState,
        isOpen: false,
        is_po_already_exist: true,
      }));
    } else {
      setIsOpenPoModal((prevState: any) => ({
        ...prevState,
        isOpen: false,
      }));
    }
  };
  const handleAddPoProducts = (data: any) => {
    if (data?.length > 0) {
      const newBillProducts = data?.map((item: any) => ({
        ...initialBillProduct,
        id: item.id,
        product_id: item.product_id,
        pack_type: item.product?.pack_type,
        received_qty: item?.received_qty,
        product_name: item?.product,
        is_po_product: true,
      }));
      setBillProducts((prevState: any) => {
        let filteredProducts = [...prevState];

        if (isOpenPoModal?.is_po_already_exist) {
          filteredProducts = prevState?.filter(
            (ele: any) => !ele.is_po_product
          );
        }

        if (
          filteredProducts?.length === 1 &&
          !filteredProducts[0]?.product_name
        ) {
          filteredProducts = [];
        }
        const newAllProducts = [...filteredProducts, ...newBillProducts]?.map(
          (item: any) => item.product
        );
        setAllProducts(newAllProducts);
        setProductErrors(newBillProducts?.map(() => ({})));
        return [...filteredProducts, ...newBillProducts];
      });
      setInfoData((prevState: any) => ({
        ...prevState,
        po_no: isOpenPoModal?.po_no,
      }));
    }
    handleClosePoModal(true);
  };

  //form validation functions

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "supplier_id":
      case "dept_id":
      case "invoice_no":
      case "invoice_value":
      case "invoice_date": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setInfoError
          );
        } else {
          updateFormDataWithHelperText(name, "", setInfoError);
        }
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }
  }, [appConfiguration]);

  const handleInputChange = useCallback(
    (e: any, index: number, row?: any) => {
      const { name, value } = e.target;

      let numericValue = value;

      if (
        name !== "batch_no" &&
        name !== "barcode" &&
        name !== "row_discount_type" &&
        name !== "tax" &&
        name !== "product_id" &&
        name !== "product_value"
      ) {
        // Replace any invalid characters with an empty string
        const validValue = value?.replace(/[^-?\d.]/g, "");

        // Ensure there is only one decimal point and handle the leading minus sign
        numericValue = validValue
          ?.split("")
          ?.reduce((acc: any, char: any, index: any) => {
            if (char === "-" && index !== 0) {
              return acc; // Remove invalid minus sign not at the start
            }
            if (char === "." && acc.includes(".")) {
              return acc; // Remove additional decimal points
            }
            return acc + char;
          }, "");
      }

      setBillProducts((prev: any) => {
        const newBillProducts = prev.map((billProduct: any, i: number) => {
          const newBillProduct: any = { ...billProduct };

          if (i === index) {
            newBillProduct[name] = numericValue;

            if (name === "qty") {
              newBillProduct.original_qty =
                newBillProduct.pack_type *
                (name === "qty" ? numericValue : billProduct.qty);
            }

            // if (newBillProduct.p_rate) {
            newBillProduct.discount_amount =
              newBillProduct.p_rate && newBillProduct.qty
                ? calculateDiscountAmount(newBillProduct, index)
                : 0;
            newBillProduct.amount = calculateAmount(newBillProduct, index);
            newBillProduct.tax_amount =
              Number(
                calculateTaxAmount(
                  newBillProduct,
                  Boolean(id)
                    ? infoData.tax_settings === 1
                      ? "exclusive"
                      : "inclusive"
                    : appConfiguration?.purchase_tax
                )
              ) || 0;
            // }

            if (name === "tax") {
              newBillProduct.tax_id = value.tax_rate;
              newBillProduct.tax_type = value.id;
              newBillProduct.tax = value;
            }

            setProductErrors((prevState: any) => {
              const newState = [...prevState];
              if (value && newState.length > 0 && newState[index]) {
                newState[index][name] = "";
              }

              if ((name === "qty" || name === "free") && id) {
                if (
                  Number(newBillProduct.used_qty) >
                  Number(newBillProduct.qty) +
                    (Number(newBillProduct.free) || 0)
                ) {
                  newState[index][name] =
                    "Quantity cannot exceed used quantity";
                } else {
                  newState[index].qty = "";
                  newState[index].free = "";
                }
              }

              if (name === "row_discount_type" || name === "discount") {
                if (name === "row_discount_type" && value === "%") {
                  newState[index].discount =
                    (Number(newBillProduct?.discount) || 0) > 100
                      ? "Discount percentage cannot greater than 100"
                      : "";
                } else if (value !== "%") {
                  newState[index].discount = "";
                }

                if (
                  name === "discount" &&
                  newBillProduct.row_discount_type === "%"
                ) {
                  newState[index].discount =
                    (Number(value) || 0) > 100
                      ? "Discount percentage cannot greater than 100"
                      : "";
                }
              }

              return newState;
            });

            return newBillProduct;
          } else {
            return newBillProduct;
          }
        });

        setSummary((prev: any) => {
          const newState = { ...prev };
          newState.sub_taxes = calculateSubTaxes(newBillProducts) || 0;
          newState.total_discount_amount =
            calculateTotalDiscountAmount(newBillProducts) || 0;
          newState.goods_value =
            calculateGoodsValue(
              newBillProducts,
              Boolean(id)
                ? infoData.tax_settings === 1
                  ? "exclusive"
                  : "inclusive"
                : appConfiguration?.purchase_tax
            ) || 0;
          newState.disc_goods_val =
            calculateGoodsValue(
              newBillProducts,
              Boolean(id)
                ? infoData.tax_settings === 1
                  ? "exclusive"
                  : "inclusive"
                : appConfiguration?.purchase_tax
            ) || 0;
          newState.total_tax = calculateTotalTaxAmount(newBillProducts) || 0;
          newState.bill_total = calculateBillTotal(newState);
          newState.bill_discount_amount =
            calculateBillDataDiscountAmount(newState);
          newState.rounded_off = calculateRoundedOff(newState);
          newState.net_amount = calculateNetAmount(newState);
          newState.balance = newState.net_amount - newState.cash_paid;
          newState.excess_amount = newState.cash_paid
            ? newState.cash_paid - newState.net_amount
            : 0;
          newState.net_excess = newState.cash_paid
            ? newState.excess_amount - newState.refunded_amount
            : 0;
          if (Number(newState.adjustment)) {
            setSummaryErrors((prevState: any) => ({
              ...prevState,
              adjustment:
                Number(newState.net_amount) < 0 ? "invalid adjustment" : "",
            }));
          }
          return newState;
        });

        return newBillProducts;
      });

      // if (name === "product_value") {
      //   if (!!id) {
      //     debouncedGetAllProcurementProductsData({
      //       department: infoData.dept_id ? infoData.dept_id : infoData.to_dept,
      //       search: value,
      //       start: 1,
      //       length: 20,
      //     });
      //   } else {
      //     debouncedGetAllProcurementProductsData({
      //       department: appConfiguration?.goods_issue_disable
      //         ? infoData.to_dept
      //         : infoData.dept_id,
      //       search: value,
      //       start: 1,
      //       length: 20,
      //     });
      //   }
      // }
    },
    [infoData]
  );

  const handleChangeSummary = (e: any) => {
    const { name, value } = e.target;
    let numericValue = value;
    if (
      name !== "payment_method" &&
      name !== "bill_discount_type" &&
      name !== "invoice_file"
    ) {
      // Replace any invalid characters with an empty string
      const validValue = value?.replace(/[^-?\d.]/g, "");

      // Ensure there is only one decimal point and handle the leading minus sign
      numericValue = validValue
        ?.split("")
        ?.reduce((acc: any, char: any, index: any) => {
          if (char === "-" && index !== 0) {
            return acc; // Remove invalid minus sign not at the start
          }
          if (char === "." && acc.includes(".")) {
            return acc; // Remove additional decimal points
          }
          return acc + char;
        }, "");
    }

    setSummary((prev: any) => {
      const newState = { ...prev };
      newState[name] = numericValue;
      if (
        name === "adjustment" ||
        name === "bill_discount" ||
        name === "bill_discount_type"
      ) {
        newState.bill_discount_amount =
          calculateBillDataDiscountAmount(newState);
        newState.rounded_off = calculateRoundedOff(newState);
        newState.net_amount = calculateNetAmount(newState);
        newState.balance =
          Number(newState.net_amount) > (Number(newState?.cash_paid) || 0)
            ? Number(newState.net_amount) - (Number(newState?.cash_paid) || 0)
            : 0;
        newState.excess_amount =
          (Number(newState.cash_paid) || 0) > Number(newState?.net_amount)
            ? Number(newState.cash_paid) - Number(newState?.net_amount)
            : 0;
      }

      if (name === "cash_paid") {
        newState.balance = newState.net_amount - numericValue;
        newState.amount_paid =
          newState.net_amount > numericValue
            ? numericValue
            : newState.net_amount;

        if (numericValue > newState.net_amount) {
          newState.excess_amount = numericValue - newState.net_amount;
          newState.balance = 0;
          newState.net_excess =
            newState.excess_amount - newState.refunded_amount;
        } else {
          newState.excess_amount = 0;
          newState.net_excess = 0;
        }
      }

      if (name === "cash_paid" || name === "payment_method") {
        if (
          newState.payment_method === "Excess" &&
          Number(newState.cash_paid) > Number(newState.excess)
        ) {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            cash_paid: "Paid amount is not exceeded than the excess",
          }));
        } else if (
          newState.payment_method === "Advance" &&
          Number(newState.cash_paid) > Number(newState.advance)
        ) {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            cash_paid: "Paid amount is not exceeded than the Advance",
          }));
        } else {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            cash_paid: "",
          }));
        }

        if (newState.refunded_amount > newState.excess_amount) {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            refunded_amount: "refunded amount is greater than excess amount",
          }));
        } else {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            refunded_amount: "",
          }));
        }
      }

      if (name === "refunded_amount") {
        newState.refunded_amount = numericValue;
        newState.net_excess = newState.excess_amount - newState.refunded_amount;

        if (numericValue > newState.excess_amount) {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            refunded_amount: "refunded amount is greater than excess amount",
          }));
        } else {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            refunded_amount: "",
          }));
        }
      }

      if (
        newState.bill_discount_type === "%" &&
        (name === "bill_discount_type" || name === "bill_discount")
      ) {
        setSummaryErrors((prevState: any) => ({
          ...prevState,
          bill_discount:
            Number(newState.bill_discount) > 100
              ? "Discount percentage cannot greater than 100"
              : "",
        }));
      } else {
        setSummaryErrors((prevState: any) => ({
          ...prevState,
          bill_discount:
            Number(newState.bill_total) < Number(newState.bill_discount_amount)
              ? "Discount percentage cannot greater than 100"
              : "",
        }));
      }

      if (Number(newState.adjustment)) {
        setSummaryErrors((prevState: any) => ({
          ...prevState,
          adjustment:
            Number(newState.net_amount) < 0 ? "invalid adjustment" : "",
        }));
      }

      return {
        ...newState,
      };
    });
  };

  const handleDateChange = useCallback(
    (index: any, newValue: any, name: any) => {
      setBillProducts((prevBillProduct: any) =>
        prevBillProduct?.map((row: any, i: number) =>
          i === index ? { ...row, [name]: newValue?.validatedValue[0] } : row
        )
      );

      setProductErrors((prevState: any) => {
        const newState = [...prevState];
        if (
          newValue?.validatedValue[0] &&
          newState.length > 0 &&
          newState[index]
        ) {
          newState[index][name] = "";
        }

        return newState;
      });
    },
    []
  );

  const handleAutoCompleteChange = useCallback(
    (newValue: any, index: number, name: string) => {
      if (newValue === null) {
        return false;
      }
      setBillProducts((prevBillProduct: any) =>
        prevBillProduct?.map((billProduct: any, i: number) => {
          return i === index
            ? {
                ...billProduct,
                [name]: newValue,
                product_id: newValue?.id,
                pack_type: newValue.pack_type,
                original_qty:
                  Number(newValue.pack_type) * Number(billProduct.qty),
              }
            : billProduct;
        })
      );
      setProductErrors((prevState: any) => {
        const newState = [...prevState];
        if (newValue && newState.length > 0 && newState[index]) {
          newState[index][name] = "";
        }

        return newState;
      });
    },
    []
  );

  const handleClick = useCallback(() => {
    setBillProducts((prevBillProduct: any) => [
      ...prevBillProduct,
      {
        ...initialBillProduct,
      },
    ]);
    setProductErrors((prevState: any) => [...prevState, {}]);
  }, []);

  const handleDelete = useCallback(
    (index: number, id: number | string) => {
      setBillProducts((prevBillProduct: any) => {
        const newBillProducts = prevBillProduct.filter(
          (billProduct: any, i: number) => i !== index
        );

        setSummary((prev: any) => {
          const newState = { ...prev };
          newState.sub_taxes = calculateSubTaxes(newBillProducts) || 0;
          newState.total_discount_amount =
            calculateTotalDiscountAmount(newBillProducts) || 0;
          newState.goods_value =
            calculateGoodsValue(
              newBillProducts,
              Boolean(id)
                ? infoData.tax_settings === 1
                  ? "exclusive"
                  : "inclusive"
                : appConfiguration?.purchase_tax
            ) || 0;
          newState.disc_goods_val =
            calculateGoodsValue(
              newBillProducts,
              Boolean(id)
                ? infoData.tax_settings === 1
                  ? "exclusive"
                  : "inclusive"
                : appConfiguration?.purchase_tax
            ) || 0;
          newState.total_tax = calculateTotalTaxAmount(newBillProducts) || 0;
          newState.bill_total = calculateBillTotal(newState);
          newState.bill_discount_amount =
            calculateBillDataDiscountAmount(newState);
          newState.rounded_off = calculateRoundedOff(newState);
          newState.net_amount = calculateNetAmount(newState);
          return newState;
        });

        return newBillProducts;
      });

      if (productError[index]) {
        setProductErrors((prevState: any) =>
          prevState.filter((ele: any, idx: number) => idx !== index)
        );
      }

      if (id) {
        setDeletedIds((prevState: any) => [...prevState, id]);
      }
    },
    [infoData]
  );

  const handleOverAllValidation = (info: any, products: any, summary: any) => {
    const infoRequiredFields = [
      "supplier_id",
      "invoice_no",
      "invoice_value",
      "invoice_date",
      "ref_no",
      // "due_date",
    ];
    infoRequiredFields.push(
      appConfiguration?.goods_issue_disable ? "to_dept" : "dept_id"
    );
    const productsRequiredFields = [
      "product_id",
      "product_name",
      "qty",
      "batch_no",
      "tax_type",
      "expires_at",
      "p_rate",
      "mrp",
    ];
    const infoErrors = {} as any;
    const productsError = [] as any;

    infoRequiredFields.forEach((key) => {
      if (!info[key]) {
        infoErrors[key] = "required field";
      }
    });

    if (products?.length === 0) {
      setIsOpenErrorModal({
        isOpen: true,
        content: "Add at least one product",
      });
    }

    products.forEach((ele: any) => {
      const productError: { [key: string]: string } = {};
      productsRequiredFields.forEach((key) => {
        if (!ele[key]) {
          productError[key] = "required field";
        }

        if (key === "qty" && id) {
          if (
            Number(ele.used_qty) >
            Number(ele.qty) + (Number(ele.free) || 0)
          ) {
            productError.qty = "Quantity cannot exceed used quantity";
          }
        }
      });
      productsError.push(productError);
    });

    let productsHasError = false;

    if (productsError?.length > 0) {
      productsError?.forEach((item: any) => {
        if (Object.keys(item).length > 0) {
          productsHasError = true;
        }
      });
    }

    if (Object.keys(infoErrors)?.length > 0 || productsHasError) {
      setInfoError(infoErrors);
      setProductErrors(productsError);
      return false;
    }

    if (info.invoice_value !== summary.net_amount) {
      setIsOpenErrorModal({
        isOpen: true,
        content: "Invoice value does not match net amount",
      });
      return false;
    }

    if (
      summary?.bill_discount_type === "%" &&
      Number(summary?.discount_value > 100)
    ) {
      setSummaryErrors((prevState: any) => ({
        ...prevState,
        cash_paid: "Discount percentage cannot greater than 100",
      }));

      return false;
    }
    if (
      summary.payment_method === "Excess" &&
      summary.cash_paid > summary.excess
    ) {
      setSummaryErrors((prevState: any) => ({
        ...prevState,
        cash_paid: "The paid amount cannot exceed the excess amount",
      }));
      setIsOpenErrorModal({
        isOpen: true,
        content: "The paid amount cannot exceed the excess amount",
      });
      return false;
    } else if (
      summary.payment_method === "Advance" &&
      summary.cash_paid > summary.advance
    ) {
      setSummaryErrors((prevState: any) => ({
        ...prevState,
        cash_paid: "The paid amount cannot exceed the advance amount",
      }));
      setIsOpenErrorModal({
        isOpen: true,
        content: "The paid amount cannot exceed the advance amount",
      });
      return false;
    }

    if (summary?.refunded_amount > summary.excess_amount) {
      setSummaryErrors((prevState: any) => ({
        ...prevState,
        refunded_amount: "refunded amount is greater than excess amount",
      }));
      setIsOpenErrorModal({
        isOpen: true,
        content: "The refunded amount cannot exceed the excess amount",
      });
      return false;
    }

    if (Number(info.adjustment) && Number(info.net_amount) < 0) {
      setSummaryErrors((prevState: any) => ({
        ...prevState,
        adjustment: "invalid adjustment",
      }));

      return false;
    }

    if (Number(info?.payment) > Number(summary.net_amount)) {
      setIsOpenErrorModal({
        isOpen: true,
        content: `The net amount cannot be less than the paid amount (${info?.payment})`,
      });
      return false;
    }
    return true;
  };

  const handleSaveData = useCallback(() => {
    if (handleOverAllValidation(infoData, billProducts, summary)) {
      setIsSaveLoading(true);
      if (id) {
        updatePurchaseBillById(
          id,
          {
            ...infoData,
            po_no: infoData.po_no,
            due_date: infoData.due_date
              ? commonDateFormatter(infoData.due_date, "YYYY-MM-DD")
              : null,
            invoice_date: infoData.invoice_date
              ? moment(infoData.invoice_date, "DD/MM/YYYY hh:mm:ss A").format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : null,
            ref_no: infoData.ref_no,
            ref_no_modified:
              Number(infoData.ref_no) === Number(initialSequenceNumber) ? 0 : 1,
            product: billProducts?.map((ele: any) => ({
              ...ele,
              expires_at: ele.expires_at
                ? commonDateFormatter(ele.expires_at, "YYYY-MM-DD")
                : null,
            })),
            disc_val: summary?.total_discount_amount || 0,
            orig_goods_val: summary?.goods_value,
            disc_goods_val: summary?.disc_goods_val,
            gst_amt: summary?.total_tax,
            net_total_amt: summary?.bill_total,
            discount_val: summary?.bill_discount,
            discount_type: summary?.bill_discount_type
              ? summary?.bill_discount_type?.toString()
              : "",
            discount_amt: summary?.bill_discount_amount,
            adj_amt: summary?.adjustment,
            bill_amt: summary?.net_amount,
            tax_settings: Number(infoData?.tax_settings),
            discount_setting: infoData?.discount_setting,
            hidden_del_id: deletedIds?.length > 0 ? deletedIds?.join(",") : "",
          },
          Boolean(purchase_approval)
        )
          .then((res: any) => {
            if (summary.invoice_file && summary.invoice_file?.length > 0) {
              if (id) {
                const formData = new FormData();
                formData.append(
                  "invoice_file",
                  summary?.invoice_file?.length > 0
                    ? summary?.invoice_file[0]
                    : ""
                );

                uploadInvoiceFile({ id, formData })
                  .then((res: any) => {
                    dispatch(
                      setSnackBarSuccess({
                        snackBarMessage: res.message,
                      })
                    );
                    setIsSaveLoading(false);
                    navigate(`${procurementUrl}${purchaseBillUrl}`);
                  })
                  .catch((err: any) => {
                    console.log(err);
                    setIsSaveLoading(false);
                  });
              }
            } else {
              dispatch(
                setSnackBarSuccess({
                  snackBarMessage: res.message,
                })
              );
              setIsSaveLoading(false);
              navigate(`${procurementUrl}${purchaseBillUrl}`);
            }
          })
          .catch((err) => {
            console.log(err);
            if (err?.response?.data?.errors?.data) {
              const { data, message, error_type } = err.response.data.errors;
              setBillProducts((prev: any) => {
                const newBillProducts = [...prev];
                newBillProducts[data.key].used_qty = data.sales_return;
                newBillProducts[data.key].used_free = data.free_usd;
                return newBillProducts;
              });

              setProductErrors((prev: any) => {
                const newErrors = [...prev];
                if (newErrors[data.key]) {
                  newErrors[data.key][
                    error_type === "invalid_qty" ? "qty" : "free"
                  ] = error_type;
                } else {
                  for (let i = 0; i <= data.key; i += 1) {
                    const error = {} as any;
                    if (i === data.key) {
                      error[error_type === "invalid_qty" ? "qty" : "free"] =
                        error_type;
                    }
                    newErrors.push(error);
                  }
                }
                return newErrors;
              });
              setIsOpenErrorModal({
                isOpen: true,
                content: message,
              });
            } else if (typeof err?.response?.data?.errors === "string") {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
            }
            setIsSaveLoading(false);
          });
      } else {
        createPurchaseBill(
          {
            ...infoData,
            due_date: infoData.due_date
              ? commonDateFormatter(infoData.due_date, "YYYY-MM-DD")
              : null,
            invoice_date: infoData.invoice_date
              ? moment(infoData.invoice_date, "DD/MM/YYYY hh:mm:ss A").format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : null,
            ref_no_modified: infoData.ref_no_modified,
            product: billProducts.map((ele: any) => ({
              ...ele,
              expires_at: ele.expires_at
                ? commonDateFormatter(ele.expires_at, "YYYY-MM-DD")
                : null,
            })),
            disc_val: summary?.total_discount_amount || 0,
            orig_goods_val: summary?.goods_value,
            disc_goods_val: summary?.disc_goods_val,
            gst_amt: summary?.total_tax,
            net_total_amt: summary?.bill_total,
            discount_val: summary?.bill_discount,
            discount_type: summary?.bill_discount_type
              ? summary?.bill_discount_type?.toString()
              : "",
            discount_amt: summary?.bill_discount_amount,
            adj_amt: summary?.adjustment,
            bill_amt: summary?.net_amount,
            type: summary?.payment_method
              ? summary?.payment_method?.toString()
              : "",
            cash_paid: summary?.cash_paid,
            pay_paid_amt: summary?.amount_paid,
            balance: summary?.balance,
            excess_amount: summary?.excess_amount,
            refunded_excess: summary?.refunded_amount,
            net_excess: summary?.net_excess,
            tax_settings:
              appConfiguration?.purchase_tax === "exclusive" ? 1 : 0,
            discount_setting:
              appConfiguration?.purchase_discount === "overall"
                ? 1
                : appConfiguration?.purchase_discount === "both"
                ? 2
                : 0,
          },
          appConfiguration?.purchase_approval
        )
          .then((res: any) => {
            if (summary.invoice_file && summary.invoice_file?.length > 0) {
              if (res.data?.id) {
                const formData = new FormData();
                formData.append(
                  "invoice_file",
                  summary?.invoice_file?.length > 0
                    ? summary?.invoice_file[0]
                    : ""
                );

                uploadInvoiceFile({ id: res.data?.id, formData })
                  .then((res: any) => {
                    navigate(`${procurementUrl}${purchaseBillUrl}`);
                    dispatch(
                      setSnackBarSuccess({
                        snackBarMessage: res.message,
                      })
                    );
                    setIsSaveLoading(false);
                  })
                  .catch((err: any) => {
                    console.log(err);
                    setIsSaveLoading(false);
                  });
              }
            } else {
              navigate(`${procurementUrl}${purchaseBillUrl}`);
              dispatch(
                setSnackBarSuccess({
                  snackBarMessage: res.message,
                })
              );
              setIsSaveLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            if (err?.response?.data?.message) {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: `${err?.response?.data?.error_type}: ${err?.response?.data?.message}`,
                })
              );
            } else if (typeof err?.response?.data?.errors === "string") {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
            }
            setIsSaveLoading(false);
          });
      }
    }
  }, [billProducts, summary, infoData]);

  const getAllProcurementProductsData = async (data: any) => {
    const res: any = await getAllProcurementProducts(data);
    if (res.data) {
      setAllProducts((prevState: any) => {
        const newState = data.search ? [] : [...prevState];
        res?.data?.forEach((ele: { id: number }) => {
          let isProductExists = false;
          prevState.forEach((elem: { id: number }) => {
            if (ele?.id === elem?.id) {
              isProductExists = true;
            }
          });
          if (!isProductExists) {
            newState.push(ele);
          }
        });

        return newState;
      });
    }
  };


  useEffect(() => {
    getTaxOptions().then((res: any) => {
      if (res.data) {
        setTaxOptions(
          res.data?.map((ele: any) => ({
            ...ele,
            name: `${ele.name}-${ele.tax_rate}%`,
          }))
        );
      }
    });
  }, []);

  useEffect(() => {
    if (infoData.supplier_id) {
      getExcessAmountBySupplierId({ supplier_id: infoData.supplier_id })
        .then((res: any) => {
          if (res.data) {
            setSummary((prevState: any) => ({
              ...prevState,
              excess: res?.data?.excess,
              advance: res?.data?.advance,
            }));

            if (
              summary.payment_method === "Advance" ||
              summary.payment_method === "Excess"
            ) {
              if (
                summary?.payment_method === "Excess" &&
                Number(summary?.cash_paid) > Number(res?.data?.excess)
              ) {
                setSummaryErrors((prevState: any) => ({
                  ...prevState,
                  cash_paid: "Paid amount is not exceeded than the excess",
                }));
              } else if (
                summary?.payment_method === "Advance" &&
                Number(summary?.cash_paid) > Number(res?.data?.advance)
              ) {
                setSummaryErrors((prevState: any) => ({
                  ...prevState,
                  cash_paid: "Paid amount is not exceeded than the Advance",
                }));
              } else {
                setSummaryErrors((prevState: any) => ({
                  ...prevState,
                  cash_paid: "",
                }));
              }
            }
          }
        })
        .catch((err: any) => console.log(err));
    }
  }, [infoData.supplier_id]);

  const calculateUsedQty = (product: any) => {
    if (product.adj_qty < 0) {
      const usedQty =
        (product.sale_qty +
          product.return_qty +
          product.free_return_qty -
          product.adj_qty) /
        product.pack_type;

      return Math.ceil(usedQty) || 0;
    } else {
      const usedQty =
        (product.sale_qty + product.return_qty + product.free_return_qty) /
        product.pack_type;
      return Math.ceil(usedQty) || 0;
    }
  };

  const getTaxOption = async (product: any, taxes: any) => {
    const tax = taxes?.data?.find((ele: any) => ele.id === product.tax_type);

    let newTax = { ...tax };
    if (tax) {
      newTax.name = `${newTax.name}-${newTax.tax_rate}%`;
    }
    return newTax;
  };

  const calculateRowAmount = (product: any) => {
    const qty = Number(product.original_qty) / Number(product.pack_type) || 0;
    const p_rate = Number(product.p_rate) || 0;
    const discount = product.discount || 0;
    const row_discount_type = product.discount_type || 0;
    const discount_amount = calculateDiscountAmount({
      qty,
      p_rate,
      discount,
      row_discount_type,
    });

    const amount = calculateAmount({ qty, p_rate, discount_amount });

    return amount.toFixed(2) || 0;
  };

  const getPurchaseBillData = async (id: string | number) => {
    try {
      setIsLoading(true);
      const selectedProducts: any = [];
      const res: any = await getPurchaseBillById(id, purchase_approval as any);
      if (res.data) {
        const { bill, bill_products } = res.data;
        const taxes = await getTaxOptions();
        const formattedBillProducts = await Promise.all(
          bill_products?.map(async (ele: any) => {
            const qty = Number(ele.original_qty) / (Number(ele.pack_type) || 0);
            const p_rate = Number(ele.p_rate) || 0;
            const discount = ele.discount || 0;
            const row_discount_type = ele.discount_type || 0;
            const discount_amount = calculateDiscountAmount({
              qty,
              p_rate,
              discount,
              row_discount_type,
            });
            const tax = await getTaxOption(ele, taxes);
            selectedProducts.push({
              id: ele.product_id,
              name: ele.product_name,
              generic_name: ele.generic_name,
              pack_type: ele.pack_type,
            });
            return {
              ...ele,
              qty,
              used_qty: calculateUsedQty(ele),
              used_free: Number(ele.free_return_qty) / Number(ele.pack_type),
              free: Number(ele.free) / Number(ele.pack_type),
              tax,
              amount: calculateRowAmount(ele),
              tax_amount: calculateTaxAmount(
                {
                  qty,
                  p_rate,
                  discount_amount,
                  tax,
                },
                Number(bill.tax_settings) === 1 ? "exclusive" : "inclusive"
              ),
              expires_at: ele.expires_at
                ? formattedDateWithSlash(ele.expires_at)
                : null,
              row_discount_type: ele.discount_type,
              product_name: {
                id: ele.product_id,
                name: ele.product_name,
                generic_name: ele.generic_name,
                pack_type: ele.pack_type,
              },
            };
          })
        );

        setAllProducts(selectedProducts);

        setInfoData({
          supplier_id: bill.supplier_id,
          supplier_name: bill.name,
          dept_id: bill.dept_id,
          department: bill.department,
          to_dept: bill.to_dept,
          invoice_no: bill.invoice_no,
          invoice_value: bill.invoice_value,
          invoice_date: bill.invoice_date
            ? moment(bill.invoice_date, "YYYY-MM-DD HH:mm:ss").format(
                "DD/MM/YYYY hh:mm:ss A"
              )
            : null,
          ref_no: bill.ref_no,
          ref_no_modified: 0,
          due_date: bill.due_date
            ? moment(bill.due_date, "YYYY-MM-DD").format("DD/MM/YYYY")
            : null,
          po_no: bill.po_no,
          tax_settings: Number(bill.tax_settings),
          discount_setting: bill.discount_setting,
          discount_id: bill.discount_id,
          payment: bill.payment,
          po_options: [
            {
              id: bill?.purchase_order?.id,
              name: bill?.purchase_order?.sequence_no,
            },
          ],
        });

        setInitialSequenceNumber(bill.ref_no);

        setInitialSuppliersList([
          { value: bill.supplier_id, label: bill.name },
        ]);
        setBillProducts(formattedBillProducts);
        setProductErrors(formattedBillProducts?.map(() => ({})));

        setSummary((prevState: any) => ({
          excess: prevState.excess,
          advance: prevState.advance,
          total_discount_amount: Number(bill.disc_val) || 0,
          goods_value: Number(bill.orig_goods_val),
          disc_goods_val: Number(bill.disc_goods_val),
          total_tax: Number(bill.gst_amt),
          bill_total: Number(bill.orig_goods_val) + Number(bill.gst_amt) || 0,
          bill_discount: bill.discount_val,
          bill_discount_type: bill.discount_type,
          bill_discount_amount: bill.discount_amt,
          rounded_off: calculateRoundedOff({
            bill_total: Number(bill.orig_goods_val) + bill.gst_amt || 0,
            bill_discount_amount: bill.discount_amt || 0,
          }),
          adjustment: Number(bill.adj_amt),
          net_amount: Number(bill.bill_amt)?.toFixed(2) || 0,
          payment_method: bill.type,
          cash_paid: bill.cash_paid,
          amount_paid: bill.pay_paid_amt,
          balance: Number(bill.balance),
          refunded_amount: bill.refunded_excess,
          excess_amount: bill.excess_amount,
          net_excess: bill.net_excess,
        }));
        setIsLoading(false);

        isCreatePrescription = false;
        isSequenceNumberChanged = false;
        getSequenceDetails();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getPurchaseBillData(id);
    } else {
      isCreatePrescription = true;
      isSequenceNumberChanged = false;
      getSequenceDetails();
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <PageLoader />
        </Box>
      ) : (
        <Grid sx={{ width: "92vw" }}>
          <Info
            error={infoError}
            initialSupplier={initialSuppliersList}
            setInfoData={setInfoData}
            setInfoError={setInfoError}
            fieldData={fieldData}
            infoData={infoData}
            handleValidation={handleValidation}
            isEdit={!!id}
            customSequence={customSequence}
            setIsSequenceWrapperOpen={setIsSequenceWrapperOpen}
            handleConfirmSequenceNoChange={handleConfirmSequenceNoChange}
            getSequenceDetails={getSequenceDetails}
            handleResetBillProducts={handleResetBillProducts}
            setIsOpenPoModal={setIsOpenPoModal}
          />
          <Grid
            sx={{
              width: "92vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              overflowX: { md: "scroll", lg: "hidden" },
            }}
          >
            <TableContainer
              className="table-container"
              component={Paper}
              sx={{
                ...styles.tableContainerStyle,
              }}
            >
              <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {TableHeaderCells?.map((cell: any, index: number) => {
                      return (
                        <TableCell
                          align={cell.alignment}
                          sx={cell.sx}
                          key={index}
                        >
                          {cell.TableCellName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody sx={{ height: "auto", width: "90vw" }}>
                  {billProducts.length > 0 ? (
                    <>
                      {billProducts?.map((row: any, index: number) => {
                        return (
                          <ProductRow
                            key={row.id}
                            index={index}
                            row={row}
                            error={
                              productError?.length > 0
                                ? productError[index]
                                : null
                            }
                            billId={id}
                            styles={styles}
                            billProducts={billProducts}
                            allRows={allProducts}
                            handleInputChange={handleInputChange}
                            handleDateChange={handleDateChange}
                            handleDelete={handleDelete}
                            handleAutoCompleteChange={handleAutoCompleteChange}
                            taxesOptions={taxOptions}
                            setIsOpenErrorModal={setIsOpenErrorModal}
                            setIsOpenPriceMargin={setIsOpenPriceMargin}
                            purchase_approval={purchase_approval}
                            infoData={infoData}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell
                        sx={{ border: "unset !important" }}
                      ></TableCell>
                      {id && (
                        <TableCell
                          sx={{ border: "unset !important" }}
                        ></TableCell>
                      )}
                      {id && !purchase_approval && (
                        <TableCell
                          sx={{ border: "unset !important" }}
                        ></TableCell>
                      )}
                      <TableCell
                        sx={{ border: "unset !important" }}
                      ></TableCell>
                      <TableCell
                        sx={{ border: "unset !important" }}
                      ></TableCell>
                      <TableCell
                        sx={{ border: "unset !important" }}
                      ></TableCell>
                      <TableCell
                        sx={{ border: "unset !important" }}
                      ></TableCell>
                      {(Boolean(id)
                        ? infoData?.discount_setting !== 1
                        : appConfiguration?.purchase_discount !==
                          "overall") && (
                        <TableCell
                          sx={{ border: "unset !important" }}
                        ></TableCell>
                      )}
                      <TableCell sx={{ border: "unset !important" }}>
                        No Rows
                      </TableCell>
                      <TableCell
                        sx={{ border: "unset !important" }}
                      ></TableCell>
                      <TableCell
                        sx={{ border: "unset !important" }}
                      ></TableCell>
                      <TableCell
                        sx={{ border: "unset !important" }}
                      ></TableCell>
                      <TableCell
                        sx={{ border: "unset !important" }}
                      ></TableCell>
                      <TableCell
                        sx={{ border: "unset !important" }}
                      ></TableCell>
                      <TableCell
                        sx={{ border: "unset !important" }}
                      ></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Grid
                sx={{
                  width: {
                    xs: "400vw",
                    sm: "200vw",
                    md: "120vw",
                    lg: "90vw",
                  },
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "0px 0px 5px 5px",
                  border: 1,
                  borderColor: "var(--table-border)",
                  borderTop: 0,
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: {
                      xs: "35%",
                    },
                  }}
                >
                  <Button
                    variant="text"
                    buttonText="+ Add Product"
                    handleClick={handleClick}
                    sx={{
                      borderRadius: "0px",
                      width: "auto",
                      height: "auto",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: 0,
                      color: "primary.main",
                      "&:hover": {
                        backgroundColor: "initial",
                        color: "primary.main",
                        borderColor: "initial",
                      },
                      "&.Mui-focusVisible": {
                        border: 1,
                        borderColor: "primary.main",
                        borderRadius: "5px",
                      },
                    }}
                  />
                  <Grid
                    sx={{
                      width: "45%",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Grid
                      sx={{
                        width: "60%",
                      }}
                    >
                      <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                        Discount Value :
                      </Typography>
                    </Grid>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        mr: "1%",
                        textAlign: "left",
                      }}
                    >
                      {summary?.total_discount_amount?.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: {
                      xs: "65%",
                    },
                  }}
                >
                  <Grid
                    sx={{
                      width: "20%",
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                      Goods Value :
                    </Typography>
                  </Grid>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: "500", mr: "2%" }}
                  >
                    {summary?.goods_value?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </TableContainer>
          </Grid>
          <Summary
            isSaveLoading={isSaveLoading}
            isEdit={!!id}
            summary={summary}
            infoData={infoData}
            summaryError={summaryError}
            purchase_approval={
              !!id ? purchase_approval : appConfiguration?.purchase_approval
            }
            handleInputChange={handleChangeSummary}
            handleSaveData={handleSaveData}
          />
          <ConfirmationDialog
            open={isOpenErrorModal.isOpen}
            title={<Box>{isOpenErrorModal.content}</Box>}
            confirmText="Okay"
            type="info"
            handleClick={() => {
              setIsOpenErrorModal({ isOpen: false, content: "" });
            }}
            onClose={() => setIsOpenErrorModal({ isOpen: false, content: "" })}
            // loading={isConfirmationDialogButtonLoading}
          />
        </Grid>
      )}
      <SequenceWrapper
        open={isSequenceWrapperOpen}
        title="Purchase Bill"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
      <PriceMarginModal
        isOpen={isOpenPriceMargin.isOpen}
        onClose={() =>
          setIsOpenPriceMargin({ isOpen: false, product_id: null })
        }
        product_id={isOpenPriceMargin?.product_id}
      />
      {isOpenPoModal.isOpen && (
        <POProductSelectModal
          isOpenPoModalData={isOpenPoModal}
          onClose={handleClosePoModal}
          infoData={infoData}
          onAdd={(data: any) => handleAddPoProducts(data)}
        />
      )}
    </>
  );
}
