import { ApiUtils } from "../utils";
import { patientHistoryEndPoints } from "../constants/apiEndPoints";
import QueryParamsBuilder from "../helper/QueryParamsBuilder";
import { getCookie } from "../utils/GeneralUtils";
import axios from "axios";

const setQueryParams = (data: any, baseUrl: string) => {
  const keys = Object.keys(data);

  let queryString = "?";
  keys.map((key) => {
    if (data[key] && !Array.isArray(data[key])) {
      queryString += `${key}=${data[key]}&`;
    }
    if (Array.isArray(data[key])) {
      data[key].map(
        (ele: any, index: number) => (queryString += `${key}[${index}]=${ele}&`)
      );
    }
  });
  if (queryString.endsWith("&")) {
    queryString = queryString.slice(0, -1);
  }

  const apiUrl = baseUrl + queryString;

  return apiUrl;
};

const {
  getPatientsList,
  getPatientHistory,
  getPatientRecentVisits,
  getPatientInvestigations,
  getPatientRadiology,
  getPatientMedicalRecords,
  getPatientPrescriptions,
  getPatientVitals,
  getPatientConsultants,
  createPatientConsents,
} = patientHistoryEndPoints;

const getAllPatientsList = async ({
  page,
  limit,
  column,
  order,
  patient_id,
  search,
}: any) => {
  const apiUrl = setQueryParams(
    {
      page,
      limit,
      column,
      order,
      patient_id,
      search,
    },
    getPatientsList
  );

  return await ApiUtils.getWithToken(apiUrl);
};

const getPatientHistoryById = async (patientId: any, type: string | null) => {
  const apiUrl = setQueryParams(
    {
      patient_id: patientId,
      type: type,
    },
    getPatientHistory
  );

  return await ApiUtils.getWithToken(apiUrl);
};

const getPatientRecentVisitsById = async (
  patientId: any,
  limit: number | null
) => {
  return await ApiUtils.getWithToken(
    `${getPatientRecentVisits}?patient_id=${patientId}&&limit=${limit}`
  );
};

const getPatientInvestigationsById = async (
  patientId: any,
  limit: number | null
) => {
  return await ApiUtils.getWithToken(
    `${getPatientInvestigations}?patient_id=${patientId}&&limit=${limit}`
  );
};

const getPatientRadiologyById = async (
  patientId: any,
  limit: number | null
) => {
  return await ApiUtils.getWithToken(
    `${getPatientRadiology}?patient_id=${patientId}&&limit=${limit}`
  );
};

const getPatientRadiologyReportById = async (
  patientId: any,
  limit: number | null
) => {
  const data = {
    result: [
      {
        name: "Ultrasound Scan",
        reportLink:
          "http://drcarrot.techbumbles.co.in:8042/orthanc/ui/app/token-landing.html?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Im9ydGhhbmMiLCJyZXNvdXJjZXMiOlt7ImRpY29tX3VpZCI6IjEuMi44NDAuMTEzNjE5LjIuNDYyLjIxNTUwNzE4NjMyODE0MC4xNzA0MDkwOTMwLjIiLCJvcnRoYW5jX2lkIjpudWxsLCJ1cmwiOm51bGwsImxldmVsIjoic3R1ZHkifV0sInR5cGUiOiJzdG9uZS12aWV3ZXItcHVibGljYXRpb24iLCJleHBpcmF0aW9uX2RhdGUiOiIyMDI1LTA3LTMwVDE0OjMyOjAwKzAwOjAwIiwidmFsaWRpdHlfZHVyYXRpb24iOm51bGx9.ch7QgNuUAMOgRqo7Je6mpCZHue-Mcj7BIvspwKXnMao",
      },
      {
        name: "CT Scan",
        reportLink:
          "http://drcarrot.techbumbles.co.in:8042/orthanc/ui/app/token-landing.html?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Im9ydGhhbmMiLCJyZXNvdXJjZXMiOlt7ImRpY29tX3VpZCI6IjEuMi44MjYuMC4xLjM2ODAwNDMuOC4xMDU1LjEuMjAxMTExMDIxNTA3NTg1OTEuOTI0MDI0NjUuNzYwOTUxNzAiLCJvcnRoYW5jX2lkIjpudWxsLCJ1cmwiOm51bGwsImxldmVsIjoic3R1ZHkifV0sInR5cGUiOiJzdG9uZS12aWV3ZXItcHVibGljYXRpb24iLCJleHBpcmF0aW9uX2RhdGUiOiIyMDI1LTA3LTMwVDE0OjMyOjAwKzAwOjAwIiwidmFsaWRpdHlfZHVyYXRpb24iOm51bGx9.dRopCOzaPcA7dWbgW_ZdEpl5RTuKgmzIC9ADzuHT7fE",
      },
      {
        name: "MRI Scan",
        reportLink:
          "http://drcarrot.techbumbles.co.in:8042/orthanc/ui/app/token-landing.html?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Im9ydGhhbmMiLCJyZXNvdXJjZXMiOlt7ImRpY29tX3VpZCI6IjEuMi44MjYuMC4xLjM2ODAwNDMuOC4xMDU1LjEuMjAxMTExMDMxMTExNDgyODguOTgzNjE0MTQuNzkzNzk2MzkiLCJvcnRoYW5jX2lkIjpudWxsLCJ1cmwiOm51bGwsImxldmVsIjoic3R1ZHkifV0sInR5cGUiOiJzdG9uZS12aWV3ZXItcHVibGljYXRpb24iLCJleHBpcmF0aW9uX2RhdGUiOiIyMDI1LTA3LTMwVDE0OjMyOjAwKzAwOjAwIiwidmFsaWRpdHlfZHVyYXRpb24iOm51bGx9.oE8wSxLmtiHKMVexV6yyI0N0Ed0b2re0NkQPp4FZjx8",
      },
      {
        name: "X-Ray Skull",
        reportLink:
          "http://drcarrot.techbumbles.co.in:8042/orthanc/ui/app/token-landing.html?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Im9ydGhhbmMiLCJyZXNvdXJjZXMiOlt7ImRpY29tX3VpZCI6IjEuMi44MjYuMC4xLjM2ODAwNDMuOC4xMDU1LjEuMjAxNjAyMjQxMzAxMjE0MjYuMzE1Mjk5ODcyLjU2NDM0MjciLCJvcnRoYW5jX2lkIjpudWxsLCJ1cmwiOm51bGwsImxldmVsIjoic3R1ZHkifV0sInR5cGUiOiJzdG9uZS12aWV3ZXItcHVibGljYXRpb24iLCJleHBpcmF0aW9uX2RhdGUiOiIyMDI1LTA3LTMwVDE0OjMyOjAwKzAwOjAwIiwidmFsaWRpdHlfZHVyYXRpb24iOm51bGx9.UWFsPXdRC5pXSTDYBKeQkYpj9e-NiULm_1cqEZqvxIw",
      },
      {
        name: "X-Ray Angiography",
        reportLink:
          "http://drcarrot.techbumbles.co.in:8042/orthanc/ui/app/token-landing.html?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Im9ydGhhbmMiLCJyZXNvdXJjZXMiOlt7ImRpY29tX3VpZCI6Ijk5OS45OTkuMS4xOTk0MTAxNi4wODMwMDAiLCJvcnRoYW5jX2lkIjpudWxsLCJ1cmwiOm51bGwsImxldmVsIjoic3R1ZHkifV0sInR5cGUiOiJzdG9uZS12aWV3ZXItcHVibGljYXRpb24iLCJleHBpcmF0aW9uX2RhdGUiOiIyMDI1LTA3LTMwVDE0OjMyOjAwKzAwOjAwIiwidmFsaWRpdHlfZHVyYXRpb24iOm51bGx9.nE-c20p3rCLgcv3BNtSaTIMvZF3l1pMz-YQBrp3qOIo",
      },
      {
        name: "Nuclear Medicine (NM)",
        reportLink:
          "http://drcarrot.techbumbles.co.in:8042/orthanc/ui/app/token-landing.html?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Im9ydGhhbmMiLCJyZXNvdXJjZXMiOlt7ImRpY29tX3VpZCI6IjIuMTYuODQwLjEuMTEzNjYyLjUuODc5NjgxODQ0OTQ3Ni4xMjE0MjM0ODkuMSIsIm9ydGhhbmNfaWQiOm51bGwsInVybCI6bnVsbCwibGV2ZWwiOiJzdHVkeSJ9XSwidHlwZSI6InN0b25lLXZpZXdlci1wdWJsaWNhdGlvbiIsImV4cGlyYXRpb25fZGF0ZSI6IjIwMjUtMDctMzBUMTQ6MzI6MDArMDA6MDAiLCJ2YWxpZGl0eV9kdXJhdGlvbiI6bnVsbH0.wCpt2FvFmP6fny2Djk92y_54uchkqoS0s0eT55piARg",
      },
      {
        name: "Digital Radiography (DX)",
        reportLink:
          "http://drcarrot.techbumbles.co.in:8042/orthanc/ui/app/token-landing.html?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Im9ydGhhbmMiLCJyZXNvdXJjZXMiOlt7ImRpY29tX3VpZCI6IjIuMjUuNTQxNjI5NDE2NjkxODYzMDI5MTc1MDM2MzQ2NjQwMjE5NjM4Iiwib3J0aGFuY19pZCI6bnVsbCwidXJsIjpudWxsLCJsZXZlbCI6InN0dWR5In1dLCJ0eXBlIjoic3RvbmUtdmlld2VyLXB1YmxpY2F0aW9uIiwiZXhwaXJhdGlvbl9kYXRlIjoiMjAyNS0wNy0zMFQxNDozMjowMCswMDowMCIsInZhbGlkaXR5X2R1cmF0aW9uIjpudWxsfQ.M0i0ScZzZqnQ3Kk2P1y9xsw3xFkh4n0wHPgGnpN2YBk",
      },
      {
        name: "Mammography",
        reportLink:
          "http://drcarrot.techbumbles.co.in:8042/orthanc/ui/app/token-landing.html?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Im9ydGhhbmMiLCJyZXNvdXJjZXMiOlt7ImRpY29tX3VpZCI6IjEuMy42LjEuNC4xLjU5NjIuOTkuMS4yMjgwOTQzMzU4LjcxNjIwMDQ4NC4xMzYzNzg1NjA4OTU4LjYxLjAiLCJvcnRoYW5jX2lkIjpudWxsLCJ1cmwiOm51bGwsImxldmVsIjoic3R1ZHkifV0sInR5cGUiOiJzdG9uZS12aWV3ZXItcHVibGljYXRpb24iLCJleHBpcmF0aW9uX2RhdGUiOiIyMDI1LTA3LTMwVDE0OjMyOjAwKzAwOjAwIiwidmFsaWRpdHlfZHVyYXRpb24iOm51bGx9._O--HEqQpFhu-uHhziZl_59piajo-kgE2zHDJ1YgCjE",
      },
      {
        name: "PET AC LUNG DELAY",
        reportLink:
          "http://drcarrot.techbumbles.co.in:8042/orthanc/ui/app/token-landing.html?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Im9ydGhhbmMiLCJyZXNvdXJjZXMiOlt7ImRpY29tX3VpZCI6IjEuMy42LjEuNC4xLjE0NTE5LjUuMi4xLjk5LjEwNzEuMTc1NTY5Njc4MDAzNDgzNjY4ODg0NTIxNTY3ODA0MDciLCJvcnRoYW5jX2lkIjpudWxsLCJ1cmwiOm51bGwsImxldmVsIjoic3R1ZHkifV0sInR5cGUiOiJzdG9uZS12aWV3ZXItcHVibGljYXRpb24iLCJleHBpcmF0aW9uX2RhdGUiOiIyMDI1LTA3LTMwVDE0OjMyOjAwKzAwOjAwIiwidmFsaWRpdHlfZHVyYXRpb24iOm51bGx9.KV3znLF0GeZk5l5subucjqXv7vBYkyMseQOABDzTMzM",
      },
    ],
    count: 9,
  };

  return { data };
  // return await ApiUtils.getWithToken(
  //   `${getPatientRadiology}?patient_id=${patientId}&&limit=${limit}`
  // );
};

const getPatientMedicalRecordsById = async (
  patientId: any,
  limit: number | null
) => {
  return await ApiUtils.getWithToken(
    `${getPatientMedicalRecords}?patient_id=${patientId}&&limit=${limit}`
  );
};

const getPatientPrescriptionsById = async (
  patientId: any,
  limit: number | null
) => {
  return await ApiUtils.getWithToken(
    `${getPatientPrescriptions}?patient_id=${patientId}&&limit=${limit}`
  );
};

const getPatientVitalsById = async (patientId: any, limit: number | null) => {
  return await ApiUtils.getWithToken(
    `${getPatientVitals}?patient_id=${patientId}&&limit=${limit}`
  );
};

const getPatientConsultantsById = async (
  patientId: any,
  limit: number | null
) => {
  return await ApiUtils.getWithToken(
    `${getPatientConsultants}?patient_id=${patientId}&&limit=${limit}`
  );
};

const createPatientConsent = async (data: any) => {
  // if (type === 1) {
  const localStorageAccessToken: any = getCookie("accessToken");

  if (localStorageAccessToken) {
    const access_token = JSON.parse(localStorageAccessToken);
    return await axios.post(createPatientConsents, data, {
      headers: {
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        authorization: `Bearer ${access_token}`,
      },
    });
  }
};

const getPatientConsentsById = async (patientId: any, limit: number | null) => {
  return await ApiUtils.getWithToken(
    `${createPatientConsents}?patient_id=${patientId}&&limit=${limit}`
  );
};
export {
  getAllPatientsList,
  getPatientHistoryById,
  getPatientRecentVisitsById,
  getPatientInvestigationsById,
  getPatientRadiologyById,
  getPatientMedicalRecordsById,
  getPatientPrescriptionsById,
  getPatientVitalsById,
  getPatientConsultantsById,
  getPatientRadiologyReportById,
  createPatientConsent,
  getPatientConsentsById,
};
