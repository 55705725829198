import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, Typography ,debounce} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import {
  AutoComplete,
  CancelButton,
  CheckBox,
  PageLoader,
  SaveButton,
  Select,
  TextField,
} from "../../../../components/basic";
import { ConfirmationDialog, DataTable } from "../../../../components/shared";
import {
  indentStatusOptions,
  indentsConst,
} from "../../../../constants/displayText";
import Info from "./Info";
import { useParams } from "react-router-dom";
import {
  getAllSuppliers,
  getIndentById,
} from "../../../../services/laboratoryService";
import ConfirmPoModal from "./ConfirmPoModal";

const BillProducts = () => {
  const { id } = useParams();
  const { productName, requirement, department, supplier, indent, status } =
    indentsConst;

  const [billProducts, setBillProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [suppliersList, setSuppliersList] = useState<any[]>([]);
  const [isOpenConfirmPoModal, setIsOpenConfirmPoModal] =
    useState<boolean>(false);
  const [selectedSupplierProducts, setSelectedSupplierProducts] = useState({
    indent_id: null,
    indent_no: "",
    rows: [],
  });
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<{
    isOpen: boolean;
    id: string | number | null;
  }>({
    isOpen: false,
    id: null,
  });
  const [isStatusLoading, setIsStatusLoading] = useState(false);

  const handleChange = (event: any, id: number | string) => {
    const { name, value } = event.target;
    setBillProducts((prev: any) =>
      prev.map((row: any) => {
        return row.id === id
          ? {
              ...row,
              [name]: value,
            }
          : row;
      })
    );
  };

  const handleCheckAll = () => {
    setSelectedProducts((prevState: any) => {
      if (prevState.length === billProducts.length) {
        return [];
      }
      return billProducts?.map((ele: any) => ele.id);
    });
  };

  const handleCheck = (row: { id: number | string }) => {
    const index = selectedProducts.findIndex((ele: any) => ele === row.id);

    if (index === -1) {
      setSelectedProducts((prev: any) => [...prev, row.id]);
    } else {
      setSelectedProducts((prev: any) => {
        const newState = [...prev];
        newState.splice(index, 1);
        return newState;
      });
    }
  };

  const handleAutoCompleteChange = (
    e: any,
    newValue: any,
    id: string | number,
    name: string
  ) => {
    setBillProducts((prev: any) => {
      return prev.map((ele: any) => {
        if (ele.id === id) {
          return {
            ...ele,
            [name]: newValue,
            supplier_id:
              name === "supplier_name" ? newValue?.id : ele?.supplier_id,
          };
        } else {
          return ele;
        }
      });
    });
  };

  const handleAutoCompleteInputChange = (
    e: any,
    newValue: any,
    id: string | number,
    name: string
  ) => {
    setBillProducts((prev: any) => {
      return prev.map((ele: any) => {
        if (ele.id === id) {
          return {
            ...ele,
            [name]: newValue,
          };
        } else {
          return ele;
        }
      });
    });
  };

  const handleGeneratePoClick = () => {
    setSelectedSupplierProducts(() => {
      const newSelectedSupplierProducts: any = {
        indent_id: id,
        indent_no: info.indent_no,
        rows: [],
      };
      selectedProducts.forEach((id: number) => {
        const product: any = billProducts.find(
          (ele: { id: number }) => ele.id === id
        );
        const index = newSelectedSupplierProducts?.rows?.findIndex(
          (ele: any) => ele.supplier_id === product.supplier_id
        );
        if (index === -1) {
          newSelectedSupplierProducts?.rows.push({
            supplier_id: product.supplier_id,
            supplier: product.supplier_name,
            items: [
              {
                product_id: product.product_id,
                product_name: product.product_name,
                total_requirement: product.requirement,
                indent_qty: product.indent,
              },
            ],
          });
        } else {
          const productIndex = newSelectedSupplierProducts?.rows[
            index
          ]?.items?.findIndex(
            (ele: any) => ele.product_id === product.product_id
          );

          if (productIndex !== -1) {
            newSelectedSupplierProducts.rows[index].items[
              productIndex
            ].total_requirement += product?.requirement;
            newSelectedSupplierProducts.rows[index].items[
              productIndex
            ].indent_qty += product?.indent;
          } else {
            newSelectedSupplierProducts?.rows[index].items.push({
              product_id: product.product_id,
              product_name: product.product_name,
              total_requirement: product.requirement,
              indent_qty: product.indent,
            });
          }
        }
      });

      return newSelectedSupplierProducts;
    });
    setIsOpenConfirmPoModal(true);
  };

  const handleConfirmChangeStatus = () => {
    if (isOpenConfirmModal.id) {
      setIsStatusLoading(true);
      setIsOpenConfirmModal({ isOpen: false, id: null });
      setIsStatusLoading(false);
    }
  };
  const supplierPagination = useRef(1);
  // const debouncedGetAllSupplierList = useCallback(
  //   debounce(async (currentValue) => {
  //     return await getAllSuppliers({
  //       search: currentValue,
  //       page: supplierPagination.current,
  //       limit: 10,
  //     }).then((result: any) => {
  //       let data = result.data.data;
  //       const formattedSuppliersList = data.map((uniqueData: any) => {
  //         return { id: uniqueData.id, value: uniqueData.name };
  //       });
  //       setSuppliersList(formattedSuppliersList);
  //       supplierPagination.current = 1;
  //       const hasMore = result.data.total > suppliersList?.length;
  //       return {
  //         options: suppliersList,
  //         hasMore,
  //       };
  //     });
  //   }, 300),
  //   []
  // );

  // const getAllSuppliersList = async (currentValue: any) => {
  //   try {
  //     if (currentValue) {
  //       debouncedGetAllSupplierList(currentValue);
  //     } else {
  //       return await getAllSuppliers({
  //         search: currentValue,
  //         page: supplierPagination.current,
  //         limit: 10,
  //       }).then((result: any) => {
  //         let data = result.data.result;

  //         console.log("data", data);

  //         const formattedSuppliersList = data?.map((uniqueData: any) => {
  //           return { value: uniqueData.id, label: uniqueData.name };
  //         });
  //         if (formattedSuppliersList.length > 0) {
  //           setSuppliersList((prevState: any) => [
  //             ...prevState,
  //             ...formattedSuppliersList,
  //           ]);
  //         }
  //         const currentValue = supplierPagination.current;
  //         supplierPagination.current = currentValue + 1;
  //         const hasMore = result.data.total > suppliersList?.length;
  //         return {
  //           options: suppliersList,
  //           hasMore,
  //         };
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const debouncedGetAllSupplierList = useCallback(
    debounce(async (currentValue: string) => {
      return await getAllSuppliers({
        search: currentValue,
        limit: 20,
      }).then((result: any) => {
        let data = result.data.data;
        const formattedSuppliersList = data.map((uniqueData: any) => {
          return { value: uniqueData.id, label: uniqueData.name };
        });
        setSuppliersList(formattedSuppliersList);
      });
    }, 300),
    []
  );

  const columns: GridColDef[] = [
    {
      field: "product_name",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <CheckBox
            checked={selectedProducts.length === billProducts.length}
            onChange={handleCheckAll}
          />
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {productName}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Box display={"flex"} alignItems={"center"}>
          <CheckBox
            checked={
              selectedProducts?.findIndex((ele: any) => ele === row.id) !== -1
            }
            onChange={() => handleCheck(row)}
          />
          <Typography variant="h6" ml={1}>
            {row.product_name}
          </Typography>
        </Box>
      ),
      sortable: false,
    },
    {
      field: "department_name",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {department}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.department_name}</Typography>
      ),
      sortable: false,
    },
    {
      field: "requirement",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {requirement}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.requirement}</Typography>
      ),
      sortable: false,
    },
    {
      field: "Supplier",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {supplier}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Box
          sx={{
            height: "100%",
            "& .MuiGrid-root": {
              height: "100%",
            },
            "& .MuiFormControl-root": {
              height: "100%",
            },
          }}
        >
          {/* <InfiniteScroller
            loadOptions={getAllSuppliersList}
            options={suppliersList}
            handleOnChange={handleChange}
            place
            name={"supplier_id"}
            value={row?.supplier_id}
            width="188px"
          /> */}
          <AutoComplete
            placeholder="select"
            value={row.supplier_name}
            inputValue={row.supplier_name_input_value}
            name="supplier_name"
            onChange={(e: any, newValue: any) => {
              handleAutoCompleteChange(e, newValue, row.id, "supplier_name");
            }}
            onInputChange={(e: any, newInputValue: any) => {
              handleAutoCompleteInputChange(
                e,
                newInputValue,
                row.id,
                "supplier_name_input_value"
              );
            }}
            options={suppliersList}
            sx={{
              height: "100%",
              width: "12vw",
              background: "#fff",
              "& .MuiFormControl-root": {
                height: "100%",
              },
              "& .MuiTextField-root": {
                height: "100%",
              },
              "& .MuiInputBase-root": {
                height: "100%",
              },
              "& fieldset": {
                height: "100%",
              },

              "& .MuiInputBase-root .MuiInputBase-input": {
                padding: 0,
                fontSize: "12px",
                fontWeight: "400",
              },

              fontFamily: ["Inter", "sans-serif"].join(","),

              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "greyScale.light",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "primary.main",
              },
              "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
                borderColor: "greyScale.lighter",
              },
              "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                padding: "6.5px",
                boxShadow: "none",
                borderRadius: "8px",
              },
            }}
          />
        </Box>
      ),
      sortable: false,
    },
    {
      field: "indent",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {indent}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <TextField
          name={indent}
          value={row.indent}
          onChange={(e: any) => handleChange(e, row.id)}
        />
      ),
      sortable: false,
    },
    {
      field: "Status",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {status}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Select
          value={row.status}
          name="status"
          placeholder="Select status"
          options={indentStatusOptions}
          width={"200px"}
          formControlStyle={{ width: "100%" }}
          onChange={(e: any) =>
            setIsOpenConfirmModal({ isOpen: true, id: e.target.value })
          }
        />
      ),
      sortable: false,
    },
  ];

  const initialInfo: any = {
    indent_no: 123456,
    department: "warehouse",
  };

  const [info, setInfo] = React.useState(initialInfo);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getIndentById(id)
        .then((res: any) => {
          if (res.data) {
            const { bill } = res.data;
            setInfo({
              indent_no: bill?.indent_no,
              department:
                bill?.indent_items?.length > 0
                  ? bill?.indent_items[0]?.department?.dept_name
                  : "",
              indent_no_modified: 0,
              date_created: bill?.date_created,
            });
            const supplierList: any = [];
            setBillProducts(() =>
              bill?.indent_items?.map((ele: any) => {
                if (ele.supplier) {
                  supplierList.push(ele.supplier);
                }
                return {
                  id: ele.id,
                  product_id: ele.product_id,
                  product_name: ele?.product?.name,
                  department_name: ele?.department?.dept_name,
                  requirement: ele.requirement,
                  supplier_id: ele?.supplier_id,
                  indent: ele?.requirement,
                  status: ele?.status,
                };
              })
            );

            if (supplierList.length > 0) {
              setSuppliersList(supplierList);
            }
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    }

    getAllSuppliers({
      limit: 20,
    }).then((result: any) => {
      let data = result.data.result;
      const formattedSuppliersList = data.map((uniqueData: any) => {
        return { id: uniqueData.id, name: uniqueData.name };
      });
      setSuppliersList(formattedSuppliersList);
    });
  }, [id]);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Grid>
          <Info info={info} />
          {/* <InfiniteScroller
            loadOptions={getAllSuppliersList}
            options={suppliersList}
            handleOnChange={handleChange}
            place
            name={"supplier_id"}
            // value={row?.supplier_id}
            width="188px"
          /> */}
          <Grid sx={{ m: "20px 0px" }}>
            <DataTable
              columns={columns}
              rows={billProducts}
              tableOnly={true}
              // customizedTable={true}
            />
          </Grid>
          <Grid
            sx={{
              width: "40vw",
              display: "flex",
              alignItems: "flex-end",
              gap: 2,
              m: "20px 0px",
            }}
          >
            <SaveButton
              buttonText="GEN PO"
              sx={{ width: "100px", height: "40px" }}
              handleClick={handleGeneratePoClick}
            />
            <CancelButton
              buttonText="Cancel"
              sx={{ width: "100px", height: "40px" }}
            />
          </Grid>
          {isOpenConfirmModal && (
            <ConfirmPoModal
              isOpen={isOpenConfirmPoModal}
              data={selectedSupplierProducts}
              onClose={() => setIsOpenConfirmPoModal(false)}
            />
          )}
          {isOpenConfirmModal.isOpen && (
            <ConfirmationDialog
              open={isOpenConfirmModal.isOpen}
              title="are you surely want to change the status?"
              handleClick={handleConfirmChangeStatus}
              onClose={() => setIsOpenConfirmModal({ isOpen: false, id: null })}
              loading={isStatusLoading}
              confirmText="Yes"
            />
          )}
        </Grid>
      )}
    </>
  );
};

export default BillProducts;
