import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DataTable } from "../../../../components/shared";
import { PrintIcon } from "../../../../assets/icons";
import { useParams } from "react-router-dom";
import { getPatientHistoryById } from "../../../../services/patientHistoryService";
import moment from "moment";

type PropsType = {
  data?: any;
};

const Radiology = ({ data }: PropsType) => {
  const { id } = useParams();

  // data table variables

  const [totalCount, setTotalCount] = useState(null);
  const [initialCount, setInitialCount] = useState(null);
  const [type, setType]: any = useState(null);
  const [allData, setAllData] = useState<any>([]);
  const [dataGridData, setDataGridData]: any = useState([]);
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);
  const [isDataGridExpanded, setIsDataGridExpanded] = useState(false);

  const TableActions = ({ row }: any) => {
    return (
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton>
          <PrintIcon onClick={() => ""} />
        </IconButton>
      </Grid>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "sNo",
      flex: 0.5,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          S.No
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.sNo}
        </Typography>
      ),
      minWidth: 60,
      maxWidth: 60,
      sortable: false,
    },
    {
      field: "date",
      flex: 1,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Date
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.created_at
            ? moment(row?.created_at, ["YYYY-MM-DD HH:mm:ss"]).format(
                "DD/MM/YYYY"
              )
            : null}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "billNo",
      flex: 2,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Bill Number
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.bill_no}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "item",
      flex: 2,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Item Name
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.name}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "doctor",
      flex: 1,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Doctor
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.doctor}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "actions",
      flex: 0.4,
      renderHeader: () => (
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}></Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 60,
      sortable: false,
    },
  ];

  const toggleDataGrid = () => {
    setIsDataGridExpanded((prev) => !prev);
    if (totalCount && initialCount && totalCount > initialCount) {
      setType("radiology");
    }
  };

  const assignDetails = (data: any) => {
    setTotalCount(data?.radiology?.count);
    if (!type) {
      setInitialCount(data?.radiology?.result?.length);
    }
    const modifiedData = data?.radiology?.result?.map(
      (item: any, index: number) => ({
        ...item,
        sNo: index + 1,
      })
    );
    setAllData(modifiedData);
    setDataGridData(modifiedData);
    setIsDataTableLoading(false);
  };

  const getRadiologyDetails = async () => {
    try {
      await getPatientHistoryById(id, type).then((result: any) => {
        const data = result?.data;
        if (data) {
          assignDetails(data);
        }
      });
    } catch (error) {
      console.error("An error occurred:", error);
      setIsDataTableLoading(false);
    }
  };

  useEffect(() => {
    if (isDataGridExpanded) {
      setDataGridData(allData);
    } else {
      const slicedData = allData.slice(0, initialCount);
      setDataGridData(slicedData);
    }
  }, [isDataGridExpanded]);

  useEffect(() => {
    if (id) {
      setIsDataTableLoading(true);
      if (type) {
        getRadiologyDetails();
      } else {
        assignDetails(data);
      }
    }
  }, [type]);

  return (
    <Grid
      sx={{
        width: { xs: "90%", md: "49%" },
        minWidth: { xs: "90%", md: "49%" },
        boxShadow: "3px 5px 15px 0px #23232314",
        borderRadius: "5px",
        height: "auto",
        maxHeight: isDataGridExpanded
          ? `${200 + allData?.length * 50}px`
          : `${200 + dataGridData?.length * 50}px`,
        m: "20px 0px",
        border: 1,
        borderColor: "greyScale.lighter",
      }}
    >
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "12px 16px 0px",
        }}
      >
        <Typography variant="h2" sx={{ color: "primary.main" }}>
          Radiology
        </Typography>
        <Button
          sx={{
            color: "textPrimary.main",
            backgroundColor: "#F4F4F4",
            p: "10px",
            fontWeight: "500",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
          onClick={toggleDataGrid}
        >
          View All
        </Button>
      </Grid>
      <Grid
        sx={{
          width: "100%",
          p: "12px 16px 18px",
        }}
      >
        <DataTable
          loading={isDataTableLoading}
          columns={columns}
          rows={dataGridData}
          getRowId={(row: any) => `${String(row?.id)}`}
          tableOnly={true}
        />
      </Grid>
    </Grid>
  );
};

export default Radiology;
