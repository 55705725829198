import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import ItemRow from "./ItemRow";

type TableBodyComponentProps = {
  rows: any;
  errors?: any;
  itemNameOptions: any;
  consultantList: any;
  onDelete: (index: number, row: any) => void;
  onChange: (event: any, index: number) => void;
  getItemsOptionsByDepartment?: () => void;
  isEdit?: boolean;
  itemRef?: any;
};

const styles = {
  tableContainerStyle: {
    borderRadius: "5px",
    boxShadow: "none",
    overflow: "hidden",
    width: "100%",
    overflowX: { xs: "scroll", lg: "hidden" },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tableStyle: {
    border: "1px solid",
    borderColor: "var(--table-border)",
    width: "100%",
    minWidth: "1200px",
    overflowX: "auto",
    "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
    "& .MuiTableRow-head": {
      backgroundColor: "var(--table-header)",
    },
    " & .MuiTableBody-root": {
      border: 0,
    },
    "& .MuiTableRow-root": {
      height: "44px !important",
      maxHeight: "44px !important",
      minHeight: "0px",
      lineHeight: "0px",
      border: 0,
    },

    "& .MuiTableCell-root": {
      minHeight: "0px",
    },

    "& .MuiTableCell-body": {
      height: "44px !important",
      maxHeight: "44px !important",
      minHeight: "0px",
      lineHeight: "0px",
      p: "0px 0px",
      fontSize: "12px",
      fontWeight: "400",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "var(--table-border)",
      overflow: "hidden",
    },

    "& .MuiTableCell-body:has(.Mui-focused)": {
      borderColor: "primary.main",
      borderWidth: "2px",
      p: "0px",
      height: "31px",
    },

    "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
      borderColor: "primary.main",
      borderWidth: "2px",
      p: "0px",
      height: "31px",
    },
    "& .deleteIconGrid": {
      cursor: "pointer",
      position: "absolute",
      right: {
        xs: "0px",
        sm: "0px",
        md: "0px",
        lg: "-2vw",
      },
      bottom: "5px",
      mb: "10px",
    },
  },
  header: {
    backgroundColor: "var(--table-header)",
  },
  headerStyle: {
    color: "#000",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "15px",
    borderBottom: "1px solid var(--table-border)",
    borderRight: "1px solid var(--table-border)",
    padding: "12px 12px 12px 20px",
    marginLeft: "8px",
  },
};

const columns = [
  {
    header: "S.No",
    textAlignment: "left",
    width: 5,
    name: "sNo",
    headerStyle: styles.headerStyle,
  },
  {
    header: "Item Name",
    textAlignment: "left",
    width: 14,
    name: "item_id",
    headerStyle: styles.headerStyle,
  },
  {
    header: "Consultant",
    textAlignment: "left",
    width: 12,
    name: "consultants",
    headerStyle: styles.headerStyle,
  },
  {
    header: "Description",
    textAlignment: "left",
    width: 14,
    name: "desc",
    headerStyle: styles.headerStyle,
  },
  {
    header: "Quantity",
    textAlignment: "left",
    width: 12,
    name: "qty",
    headerStyle: styles.headerStyle,
  },
  {
    header: "Discount",
    textAlignment: "left",
    width: 15,
    name: "discount",
    headerStyle: styles.headerStyle,
  },
  {
    header: "Tax (%)",
    textAlignment: "left",
    width: 8,
    name: "tax",
    headerStyle: styles.headerStyle,
  },
  {
    header: "Rate",
    textAlignment: "right",
    width: 7,
    name: "ptr",
    headerStyle: styles.headerStyle,
  },
  {
    header: "Amount",
    textAlignment: "right",
    width: 9,
    name: "amount",
    headerStyle: styles.headerStyle,
  },
  {
    header: "",
    textAlignment: "left",
    width: 4,
    headerStyle: { ...styles.headerStyle, borderRight: "none" },
  },
];

type ItemTableProps = {
  data: any;
  errors?: any;
  itemNameOptions: any;
  consultantList: any;
  onChangeItem: (event: any, index: number) => void;
  onDeleteItem: (index: number, row: any) => void;
  onAddItem?: () => void;
  getItemsOptionsByDepartment?: () => void;
  isEdit?: boolean;
  itemRef?: any;
};

const ItemRowMemoized = React.memo(ItemRow);

const TableHeader = React.memo((props: { columns: any }) => {
  const { columns } = props;

  return (
    <TableHead sx={{ ...styles.header }}>
      <TableRow>
        {columns?.map((column: any) => (
          <TableCell
            align={column.textAlignment}
            sx={{ ...column.headerStyle, width: `${column.width}%` }}
          >
            {column?.header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

const TableBodyComponent = React.memo((props: TableBodyComponentProps) => {
  const {
    rows,
    itemNameOptions,
    consultantList,
    onChange,
    onDelete,
    getItemsOptionsByDepartment,
    errors,
    isEdit,
    itemRef,
  } = props;
  return (
    <TableBody
      sx={{
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderBottomColor: "#207DFF !important",
        },
      }}
    >
      {rows?.map((row: any, rowIndex: number) => (
        <ItemRowMemoized
          row={row}
          index={rowIndex}
          columns={columns}
          allProducts={itemNameOptions}
          consultantList={consultantList}
          onChange={onChange}
          onDelete={onDelete}
          error={errors?.length > 0 ? errors[rowIndex] : null}
          isDeleteDisabled={rows.length === 1}
          isEdit={isEdit}
          getItemsOptionsByDepartment={getItemsOptionsByDepartment}
          itemRef={itemRef}
        />
      ))}
    </TableBody>
  );
});

const ItemTable = (props: ItemTableProps) => {
  const {
    errors,
    data,
    itemNameOptions,
    consultantList,
    onDeleteItem,
    onAddItem,
    onChangeItem,
    getItemsOptionsByDepartment,
    isEdit,
    itemRef,
  } = props;

  return (
    <Box
      style={{
        overflowX: "auto",
        width: "98.5%",
        marginTop: "15px",
      }}
      px={1}
    >
      <TableContainer sx={{ ...styles.tableContainerStyle }}>
        <Table sx={{ ...styles.tableStyle }}>
          <TableHeader columns={columns} />
          <TableBodyComponent
            errors={errors}
            rows={data.items}
            itemNameOptions={itemNameOptions}
            consultantList={consultantList}
            onDelete={onDeleteItem}
            onChange={onChangeItem}
            getItemsOptionsByDepartment={getItemsOptionsByDepartment}
            isEdit={isEdit}
            itemRef={itemRef}
          />
        </Table>
      </TableContainer>
      <Box mt={3}>
        <Typography
          style={{
            color: "#3A8DFF",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "26px",
            cursor: "pointer",
            width: "fit-content",
          }}
          onClick={onAddItem}
        >
          + Add items
        </Typography>
      </Box>
    </Box>
  );
};

export default ItemTable;
