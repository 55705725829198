import ApiUtil from "../utils/ApiUtils";
import { billingEndPoints } from "../constants/apiEndPoints";

type GetBillingItemsListProps = {
  department: number | string;
  name: string;
  description: string;
  price: number | string;
  search: string;
  column: string;
  order: string;
  page: number;
  length: number;
  per_page: number;
};

type GetPatientBillListProps = {
  doctor_id: number | string;
  department: number | string;
  patient_id: number | string;
  op_seq_no_id: number | string;
  from_date: any;
  to_date: any;
  search: string;
  column: string;
  order: string;
  start: number;
  length: number;
};

type PatientBillType = {
  id: string | number;
  bill_no: string | number;
  is_cancel: number;
  patient_id: string | number;
  date_created: string;
  department: string | number;
  net_total_amt: string | number;
  paid_amt: string | number;
  balance: string | number;
  name: string;
  uhid: string;
  created_user: string;
  doctor_name: string | null;
  status: string;
};

type BillingItemType = {
  id: number | string;
  name: string;
  desc: string;
  price: string | number;
  dept_name: string | number;
};

type RefRange = {
  id?: string | number;
  age_group_id: string | number;
  min_value: number;
  range_type: string | number;
  max_value: number;
  health_condition: string;
};

export type LabTest = {
  id?: string | number;
  bill_item_id?: string | number;
  sub_heading: string;
  align_order: number;
  test_dept: string;
  note: string;
  price: string | number;
  test_name: string;
  investigation: string;
  unit: string;
  result: string;
  methodology_id: string | number;
  active: string | number;
  reference_ranges: RefRange[];
};

type WholeBillingItemType = {
  id: string | number;
  department?: string;
  dept_id: string | number;
  name: string;
  desc: string | null;
  price: string | number;
  type: string;
  consultants: string;
  align_order: number;
  group_id: string | number;
  is_lab_test: string | number;
  is_outsource: string;
  active: string | number;
  lab_tests: LabTest[];
  package_tests: string;
};

export type ItemListType = {
  result: BillingItemType[] | [];
  total: number | null;
};

type PatientsUserTypes = {
  active: number;
  alt_mobile: string;
  area: string;
  device_tokens: null | string | any;
  district: string;
  email: string;
  id: number | string;
  language: string;
  last_name: string;
  line_1: string;
  line_2: string;
  mobile: string;
  name: string;
  paid: number | string;
  paid_date: string;
  pincode: string;
  state: string;
  title: string;
};

const payeeList = [
  { id: 1, name: "Self" },
  { id: 2, name: "Test" },
];

const paymentTypes = [
  { id: 1, name: "Pay Now" },
  { id: 2, name: "Credit" },
];

const referralDoctors = [];

const ipOptions = [
  { id: 1, name: "IP001" },
  { id: 2, name: "IP002" },
  { id: 3, name: "IP003" },
];

const opOptions = [
  { id: 1, name: "OP001" },
  { id: 2, name: "OP002" },
  { id: 3, name: "OP003" },
];

const allProducts = {
  1: [
    {
      id: "item@@2",
      type: "Item",
      custom_id: 2,
      qty: 1,
      name: "Anti- Hcv / Anti- HAV",
      desc: null,
      price: "1400.00",
      package_tests: ",",
      consultant: null,
    },
    {
      id: "item@@3",
      type: "Item",
      custom_id: 3,
      qty: 1,
      name: "Homocystive",
      desc: null,
      price: "1500.00",
      package_tests: ",",
      consultant: null,
    },
    {
      id: "item@@4",
      type: "Item",
      custom_id: 4,
      qty: 1,
      name: "Total iron binding capacity (TIBC)",
      desc: null,
      price: "750.00",
      package_tests: ",",
      consultant: "",
    },
    {
      id: "item@@6",
      type: "Item",
      custom_id: 6,
      qty: 1,
      name: "Test2",
      desc: "tes",
      price: "500.00",
      package_tests: ",",
      consultant: "3",
    },
    {
      id: "item@@13",
      type: "Package",
      custom_id: 13,
      qty: 1,
      name: "demo-poll-build1",
      desc: "Description - 1",
      price: "1500.00",
      package_tests: "1,2,3,",
      consultant: null,
    },
    {
      id: "lab_test@@2",
      type: "Test",
      custom_id: 2,
      qty: 1,
      name: "Anti- Hcv / Anti- HAV",
      desc: "",
      price: "1400.00",
      consultant: [],
    },
    {
      id: "lab_test@@2",
      type: "Test",
      custom_id: 2,
      qty: 1,
      name: "RBC",
      desc: "",
      price: "1400.00",
      consultant: [],
    },
    {
      id: "lab_test@@2",
      type: "Test",
      custom_id: 2,
      qty: 1,
      name: "HCP",
      desc: "",
      price: "1400.00",
      consultant: [],
    },
    {
      id: "lab_test@@6",
      type: "Test",
      custom_id: 6,
      qty: 1,
      name: "Testing",
      desc: "",
      price: "500.00",
      consultant: [],
    },
  ],
  2: [
    {
      id: "item@@10",
      type: "Item",
      custom_id: 10,
      qty: 1,
      name: "trytr",
      desc: null,
      price: "20.00",
      package_tests: ",",
      consultant: null,
    },
  ],
  3: [
    {
      id: "item@@12",
      type: "Item",
      custom_id: 12,
      qty: 1,
      name: "Test",
      desc: null,
      price: "500.00",
      package_tests: ",",
      consultant: "3",
    },
    {
      id: "lab_test@@12",
      type: "Test",
      custom_id: 12,
      qty: 1,
      name: "test",
      desc: "",
      price: "500.00",
      consultant: [],
    },
  ],
  4: [
    {
      id: "item@@1",
      type: "Item",
      custom_id: 1,
      qty: 1,
      name: "cc",
      desc: null,
      price: "100.00",
      package_tests: ",",
      consultant: "doctor@@3",
    },
    {
      id: "item@@5",
      type: "Item",
      custom_id: 5,
      qty: 1,
      name: "test1",
      desc: "test",
      price: "50.00",
      package_tests: ",",
      consultant: "3",
    },
    {
      id: "item@@7",
      type: "Item",
      custom_id: 7,
      qty: 1,
      name: "Consultation Fee",
      desc: null,
      price: "100.00",
      package_tests: ",",
      consultant: "3",
    },
    {
      id: "item@@8",
      type: "Item",
      custom_id: 8,
      qty: 1,
      name: "Teleconsultation Fee",
      desc: null,
      price: "500.00",
      package_tests: ",",
      consultant: "3",
    },
    {
      id: "item@@9",
      type: "Item",
      custom_id: 9,
      qty: 1,
      name: "test",
      desc: null,
      price: "100.00",
      package_tests: ",",
      consultant: "3",
    },
  ],
  5: [
    {
      id: "item@@15",
      type: "Package",
      custom_id: 15,
      qty: 1,
      name: "kfjkdlsa",
      desc: null,
      price: "1000.00",
      package_tests: ",",
      consultant: null,
    },
  ],
  6: [
    {
      id: "lab_test@@11",
      type: "Test",
      custom_id: 11,
      qty: 1,
      name: "book test",
      desc: "",
      price: "500.00",
      consultant: [],
    },
    {
      id: "lab_test@@16",
      type: "Test",
      custom_id: 16,
      qty: 1,
      name: "lklkfllksafda",
      desc: "",
      price: "1000.00",
      consultant: [],
    },
  ],
};

const {
  getItemListUrl,
  getDepartmentsUrl,
  billingItemUrl,
  getGroupNamesUrl,
  getConsultantsListUrl,
  getCommonVariablesUrl,
  getLabResultOptionsUrl,
  getMethodologyOptionsUrl,
  getAgeGroupOptionsUrl,
  getItemsGroupListUrl,
  itemGroupMasterUrl,
  methodologyListUrl,
  labResultsListUrl,
  getAllPatientsName,
  getBillingDepartments,
  getPatientDetailsUrl,
  patientBillUrl,
  payeeUrl,
  sequenceUrl,
  referralDoctorUrl,
  ipUrl,
  opUrl,
  // billDraftUrl,
  getSequenceNumber,
  updateSequenceNumber,
  billDraftUrl,
  patientExcessAndAdvanceUrl,
  cardUrl,
  packageItemsUrl,
} = billingEndPoints;

const setQueryParams = (data: any, baseUrl: string) => {
  const keys = Object.keys(data);

  let queryString = "?";
  keys.map((key) => {
    if (data[key]) {
      queryString += `${key}=${data[key]}&`;
    }
  });
  if (queryString.endsWith("&")) {
    queryString = queryString.slice(0, -1);
  }

  const apiUrl = baseUrl + queryString;

  return apiUrl;
};

// Select options apis
const getDepartments = async (): Promise<{
  data: { id: string | number; name: string }[];
}> => {
  try {
    return (await ApiUtil.getWithToken(getDepartmentsUrl)) as {
      data: { id: string | number; name: string }[];
    } as {
      data: { id: string | number; name: string }[];
    };
  } catch (error) {
    console.log("Error Occurs while getting the departments:", error);
    throw error;
  }
};

const getGroupNames = async (
  params: any
): Promise<{
  data: { id: string | number; name: string }[];
}> => {
  try {
    const apiUrl = setQueryParams(params, getGroupNamesUrl);
    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: { id: string | number; name: string }[];
    };
  } catch (error) {
    console.log("Error Occurs while getting the departments:", error);
    throw error;
  }
};

const getConsultantsList = async () => {
  try {
    return ApiUtil.getWithToken(getConsultantsListUrl);
  } catch (error) {
    console.log("Error Occurs while getting the departments:", error);
  }
};

const getPackageItems = async (params: any) => {
  try {
    const apiUrl = setQueryParams(params, packageItemsUrl);
    return ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error Occurs while getting the departments:", error);
  }
};

const getBillingItemTestDepartments = async () => {
  try {
    const response = (await ApiUtil.getWithToken(getCommonVariablesUrl)) as any;
    return response?.data?.billig_item_test_department;
  } catch (error) {
    console.log(
      "Error Occurs while getting the billing items testing departments:",
      error
    );
    throw error;
  }
};

const getBillingItemLabResults = async (params: any) => {
  try {
    const apiUrl = setQueryParams(params, getLabResultOptionsUrl);
    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log(
      "Error Occurs while getting the billing items lab results:",
      error
    );
    throw error;
  }
};

const getBillingMethodology = async (params: any) => {
  try {
    const apiUrl = setQueryParams(params, getMethodologyOptionsUrl);
    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log(
      "Error Occurs while getting the billing items methodology:",
      error
    );
    throw error;
  }
};

const getAgeGroupOptions = async () => {
  try {
    return await ApiUtil.getWithToken(getAgeGroupOptionsUrl);
  } catch (error) {
    console.log(
      "Error Occurs while getting the billing items age groups:",
      error
    );
    throw error;
  }
};

// Billing item apis
const getBillingItemsList = async (params: {
  page: number | string | null;
  per_page: number;
  search?: string;
}): Promise<{
  data: { result: BillingItemType[]; totalRecords: number | null };
}> => {
  try {
    const apiUrl = setQueryParams(params, getItemListUrl);

    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: { result: BillingItemType[]; totalRecords: number | null };
    } as {
      data: { result: BillingItemType[]; totalRecords: number | null };
    };
  } catch (error) {
    console.log("Error Occurs while getting the billing items list:", error);
    throw error;
  }
};

const getBillingItemById = async (
  id: string | number
): Promise<{
  data: WholeBillingItemType[];
}> => {
  try {
    return (await ApiUtil.getWithToken(`${billingItemUrl}/${id}`)) as {
      data: WholeBillingItemType[];
    };
  } catch (error) {
    console.log("Error Occurs while getting the billing items by id:", error);
    throw error;
  }
};

const updateBillingItemById = async (
  id: number | string,
  data: WholeBillingItemType
): Promise<{ message: string }> => {
  try {
    return (await ApiUtil.putWithToken(`${billingItemUrl}/${id}`, data)) as {
      message: string;
    };
  } catch (error) {
    console.log("Error Occurs while updating the billing items by id:", error);
    throw error;
  }
};

const createBillingItem = async (
  data: WholeBillingItemType
): Promise<{ message: string }> => {
  try {
    return (await ApiUtil.postWithToken(billingItemUrl, data)) as {
      message: string;
    };
  } catch (error) {
    console.log("Error Occurs while adding the billing item:", error);
    throw error;
  }
};

const deleteBillItemById = async (
  id: string | number
): Promise<{ message: string }> => {
  try {
    return (await ApiUtil.deleteWithToken(`${billingItemUrl}/${id}`)) as {
      message: string;
    };
  } catch (error) {
    console.log("Error Occurs while deleting the billing item:", error);
    throw error;
  }
};

const deleteRowItemById = async (
  id: string | number
): Promise<{ message: string }> => {
  try {
    return (await ApiUtil.deleteWithToken(
      `${billingItemUrl}/lab_test_destroy/${id}`
    )) as {
      message: string;
    };
  } catch (error) {
    console.log("Error Occurs while deleting the billing item:", error);
    throw error;
  }
};

// Items Group master apis
const getItemsGroupList = async (params: {
  search: string;
  column: string;
  order: string;
  page: number;
  limit: number;
}): Promise<{
  data: {
    result: { id: number | string; name: string; active: string }[];
    total: number;
  };
}> => {
  try {
    const apiUrl = setQueryParams(params, getItemsGroupListUrl);

    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: {
        result: { id: number | string; name: string; active: string }[];
        total: number;
      };
    };
  } catch (error) {
    console.log("Error Occurs while getting the items group list:", error);
    throw error;
  }
};

const updateItemsGroupMaster = async (data: {
  id: number | string;
  name: string;
  active: number;
}) => {
  try {
    return await ApiUtil.putWithToken(`${itemGroupMasterUrl}/${data.id}`, {
      name: data.name,
      active: data.active,
    });
  } catch (error) {
    console.log("Error Occurs while updating the items group master:", error);
    throw error;
  }
};

const createItemGroupMaster = async (data: {
  id: number | string;
  name: string;
  active: number;
}) => {
  try {
    return await ApiUtil.postWithToken(`${itemGroupMasterUrl}`, data);
  } catch (error) {
    console.log("Error Occurs while creating the items group master:", error);
    throw error;
  }
};

const deleteItemGroupMasterById = async (id: number | string) => {
  try {
    return await ApiUtil.deleteWithToken(`${itemGroupMasterUrl}/${id}`);
  } catch (error) {
    console.log("Error Occurs while deleting the items group master:", error);
    throw error;
  }
};

// Methodology master apis
const getMethodologyList = async (params: {
  search: string;
  column: string;
  order: string;
  page: number;
  limit: number;
}) => {
  try {
    const apiUrl = setQueryParams(params, methodologyListUrl);

    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error Occurs while getting the items group list:", error);
    throw error;
  }
};

const createMethodologyMaster = async (data: {
  id: number | string;
  name: string;
  active: number;
}) => {
  try {
    return await ApiUtil.postWithToken(`${methodologyListUrl}`, data);
  } catch (error) {
    console.log("Error Occurs while creating the methodology master:", error);
    throw error;
  }
};

const updateMethodologyMaster = async (data: {
  id: number | string;
  name: string;
  active: number;
}) => {
  try {
    return await ApiUtil.putWithToken(`${methodologyListUrl}/${data.id}`, {
      name: data.name,
      active: data.active,
    });
  } catch (error) {
    console.log("Error Occurs while updating the methodology master:", error);
    throw error;
  }
};

const deleteMethodologyMasterById = async (id: number | string) => {
  try {
    return await ApiUtil.deleteWithToken(`${methodologyListUrl}/${id}`);
  } catch (error) {
    console.log("Error Occurs while deleting the methodology master:", error);
    throw error;
  }
};

// Lab result Master apis
const getLabResultsList = async (params: {
  search: string;
  column: string;
  order: string;
  page: number;
  limit: number;
}) => {
  try {
    const apiUrl = setQueryParams(params, labResultsListUrl);

    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error Occurs while getting the items group list:", error);
    throw error;
  }
};

const createLabResultMaster = async (data: {
  id: number | string;
  name: string;
  active: number;
}) => {
  try {
    return await ApiUtil.postWithToken(`${labResultsListUrl}`, data);
  } catch (error) {
    console.log("Error Occurs while creating the lab result master:", error);
    throw error;
  }
};

const updateLabResultMaster = async (data: {
  id: number | string;
  name: string;
  active: number;
}) => {
  try {
    return await ApiUtil.putWithToken(`${labResultsListUrl}/${data.id}`, {
      name: data.name,
      active: data.active,
    });
  } catch (error) {
    console.log("Error Occurs while updating the lab result master:", error);
    throw error;
  }
};

const deleteLabResultMasterById = async (id: number | string) => {
  try {
    return await ApiUtil.deleteWithToken(`${labResultsListUrl}/${id}`);
  } catch (error) {
    console.log("Error Occurs while deleting the lab result master:", error);
    throw error;
  }
};

const getAllPatientsList = async (params: {
  page: number | string | null;
  per_page: number;
  search?: string;
}) => {
  const apiUrl = setQueryParams(params, getAllPatientsName);
  return await ApiUtil.getWithToken(apiUrl);
};

const getDepartmentList = async () => {
  return await ApiUtil.getWithToken(`${getBillingDepartments}`);
};

const getPatientDetails = async (
  id: string | null | any
): Promise<{
  data: PatientsUserTypes;
}> => {
  return (await ApiUtil.getWithToken(`${getPatientDetailsUrl}/${id}`)) as {
    data: PatientsUserTypes;
  };
};

const getPayeeList = async () => {
  try {
    return await ApiUtil.getWithToken(payeeUrl);
  } catch (error) {
    console.log("Error while getting the payee list", error);
    throw error;
  }
};

const addPayee = async (value: { name: string; creditLimit: string }) => {
  try {
    return await ApiUtil.postWithToken(payeeUrl, value);
  } catch (error) {
    console.log("Error while adding the payee", error);
    throw error;
  }
};

const getPaymentTypes = async () => {
  try {
    return { data: paymentTypes };
  } catch (error) {
    console.log("Error while getting the payment types", error);
    throw error;
  }
};

const getReferralDoctorsList = async () => {
  try {
    return await ApiUtil.getWithToken(referralDoctorUrl);
  } catch (error) {
    console.log("Error while getting the referral doctors list", error);
    throw error;
  }
};

const addReferralDoctor = async (value: {
  name: string;
  email: string;
  mobile: string | number;
  address: string;
}) => {
  try {
    return await ApiUtil.postWithToken(referralDoctorUrl, {
      ...value,
      active: 1,
    });
  } catch (error) {
    console.log("Error while adding the referral doctors", error);
    throw error;
  }
};

const getOpList = async (patient_id: string | number, search: string) => {
  try {
    const params = { search, patient_id } as any;
    if (search) {
      params.search = search;
    }
    if (!search && patient_id) {
      params.patient_id = patient_id;
    }
    const apiUrl = setQueryParams(params, opUrl);
    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error while getting the Op list", error);
    throw error;
  }
};

const getIpList = async (patient_id: string | number, search: string) => {
  try {
    const params = {} as any;
    if (search) {
      params.search = search;
    }
    if (!search && patient_id) {
      params.patient_id = patient_id;
    }
    const apiUrl = setQueryParams(params, ipUrl);

    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error while getting the Ip list", error);
    throw error;
  }
};

const getItemsByDepartment = async (params: {
  department: string | number;
  patient_id: string | number;
  ip_id: string | number;
}) => {
  try {
    const apiUrl = setQueryParams(params, `${patientBillUrl}/items`);
    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error while getting the item name list", error);
    throw error;
  }
};

// Bill list apis

const getPatientBillList = async (
  params: GetPatientBillListProps
): Promise<{
  data: {
    result: PatientBillType[];
    total: number | null;
    sum_net_total_amt: number | null;
    sum_paid_amt: number | null;
    sum_balance: number | null;
  };
}> => {
  try {
    const apiUrl = setQueryParams(params, patientBillUrl);

    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: {
        result: PatientBillType[];
        total: number | null;
        sum_net_total_amt: number | null;
        sum_paid_amt: number | null;
        sum_balance: number | null;
      };
    };
  } catch (error) {
    console.log("Error while getting the patient's bill list", error);
    throw error;
  }
};

const deletePatientBillById = async (id: number | string) => {
  try {
    return await ApiUtil.deleteWithToken(`${patientBillUrl}/${id}`);
  } catch (error) {
    console.log("Error while deleting the patient's bill by id", error);
    throw error;
  }
};

const deletePatientBillItemById = async (id: number | string) => {
  try {
    return await ApiUtil.deleteWithToken(`${patientBillUrl}/bill-item/${id}`);
  } catch (error) {
    console.log("Error while deleting the patient's bill item by id", error);
    throw error;
  }
};

const updatePatientBillTotalDetailsById = async (
  id: number | string | any,
  data: { total_amt: number; net_total_amt: number }
) => {
  try {
    return await ApiUtil.putWithToken(
      `${patientBillUrl}/bill-details/${id}`,
      data
    );
  } catch (error) {
    console.log("Error while deleting the patient's bill item by id", error);
    throw error;
  }
};

const getBillDetailById = async ({
  id,
  type,
}: {
  id: number | string;
  type?: string;
}): Promise<{
  data: {
    bill: any;
    bill_items: any[];
  };
}> => {
  try {
    const apiUrl = setQueryParams({ type }, `${patientBillUrl}/${id}`);
    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: {
        bill: any;
        bill_items: any[];
      };
    };
  } catch (error) {
    console.log("Error while getting the patient's bill", error);
    throw error;
  }
};

const createPatientBill = async (data: any) => {
  try {
    return await ApiUtil.postWithToken(patientBillUrl, data);
  } catch (error) {
    console.log("Error while creating the patient's bill", error);
    throw error;
  }
};

const updatePatientBill = async (id: number | string, data: any) => {
  try {
    delete data.id;
    return await ApiUtil.putWithToken(`${patientBillUrl}/${id}`, data);
  } catch (error) {
    console.log("Error while creating the patient's bill", error);
    throw error;
  }
};

const cancelPatientBillById = async (id: number | string) => {
  try {
    return await ApiUtil.putWithToken(`${patientBillUrl}/${id}/cancel`);
  } catch (error) {
    console.log("Error while creating the patient's bill", error);
    throw error;
  }
};

const getBillTermsOptions = async () => {
  try {
    const result = (await ApiUtil.getWithToken(getCommonVariablesUrl)) as any;

    return result.data.bill_payment_type;
  } catch (error) {
    console.log("Error while getting the patient's bill list", error);
    throw error;
  }
};

const getBillCollection = async (
  date: string
): Promise<{
  data: {
    bill_collection_data: {
      first_shift_amt: number;
      second_shift_amt: number;
      third_shift_amt: number;
      user_name: string;
    }[];
    first_shift_amt_total: number;
    second_shift_amt_total: number;
    third_shift_amt_total: number;
  };
}> => {
  try {
    const apiUrl = setQueryParams(
      { date },
      `${patientBillUrl}/bill-collection`
    );
    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: {
        bill_collection_data: {
          first_shift_amt: number;
          second_shift_amt: number;
          third_shift_amt: number;
          user_name: string;
        }[];
        first_shift_amt_total: number;
        second_shift_amt_total: number;
        third_shift_amt_total: number;
      };
    };
  } catch (error) {
    console.log("Error while getting the patient's bill list", error);
    throw error;
  }
};

const getPatientBillMasterData = async () => {
  try {
    return await ApiUtil.getWithToken(`${patientBillUrl}/master-data`);
  } catch (error) {
    console.log("Error while getting the patient bill master data", error);
    throw error;
  }
};

const getBillSequence = async (department: string | number) => {
  try {
    return await ApiUtil.getWithToken(`${sequenceUrl}/${department}`);
  } catch (error) {
    console.log("Error while getting the item name list", error);
    throw error;
  }
};

const getExcessAmountByPatientId = async (params: {
  patient_id: string | number;
}) => {
  try {
    const apiurl = setQueryParams(params, patientExcessAndAdvanceUrl);
    return await ApiUtil.getWithToken(apiurl);
  } catch (error) {
    console.log("Error while getting the excess amount by patient id", error);
    throw error;
  }
};

const getCommonVariables = async () => {
  try {
    return await ApiUtil.getWithToken(getCommonVariablesUrl);
  } catch (error) {
    console.log("Error while getting the common variables", error);
    throw error;
  }
};

const getBillDraftList = async (params: {
  uhid: string | number;
  ipOp: string | number;
  from_date: Date;
  to_date: Date;
  search: string;
  column: string;
  order: string;
  page: number;
  limit: number;
}) => {
  try {
    const apiUrl = setQueryParams(params, billDraftUrl);
    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error while getting the common variables", error);
    throw error;
  }
};

const getBillDraftDetailsById = async (
  id: string | number,
  draftType?: string | null
) => {
  try {
    return await ApiUtil.getWithToken(`${billDraftUrl}/${draftType}/${id}`);
  } catch (error) {
    console.log("Error while getting the common variables", error);
    throw error;
  }
};

const getSequenceNo = async (departmentName: string) => {
  return await ApiUtil.getWithToken(`${getSequenceNumber}/${departmentName}`);
};

const updateSequenceNo = async (sequenceId: number, data: any) => {
  return await ApiUtil.putWithToken(
    `${updateSequenceNumber}/${sequenceId}`,
    data
  );
};

const getCardOptionDetails = async () => {
  try {
    return await ApiUtil.getWithToken(cardUrl);
  } catch (error) {
    console.log("Error occurs while getting payment list:", error);
    throw error;
  }
};

export {
  getBillingItemsList,
  getDepartments,
  getBillingItemById,
  getGroupNames,
  getConsultantsList,
  updateBillingItemById,
  getBillingItemTestDepartments,
  getBillingItemLabResults,
  getBillingMethodology,
  getAgeGroupOptions,
  createBillingItem,
  deleteBillItemById,
  deleteRowItemById,
  getItemsGroupList,
  updateItemsGroupMaster,
  createItemGroupMaster,
  deleteItemGroupMasterById,
  getMethodologyList,
  createMethodologyMaster,
  updateMethodologyMaster,
  deleteMethodologyMasterById,
  getLabResultsList,
  createLabResultMaster,
  updateLabResultMaster,
  deleteLabResultMasterById,
  getAllPatientsList,
  getDepartmentList,
  getPatientDetails,
  getPayeeList,
  addPayee,
  getPaymentTypes,
  getReferralDoctorsList,
  addReferralDoctor,
  getIpList,
  getOpList,
  getItemsByDepartment,
  getPatientBillList,
  getBillTermsOptions,
  getBillCollection,
  getBillDetailById,
  deletePatientBillById,
  createPatientBill,
  updatePatientBill,
  getBillSequence,
  getExcessAmountByPatientId,
  getCommonVariables,
  getBillDraftList,
  getBillDraftDetailsById,
  getSequenceNo,
  updateSequenceNo,
  getCardOptionDetails,
  cancelPatientBillById,
  getPackageItems,
  deletePatientBillItemById,
  updatePatientBillTotalDetailsById,
  getPatientBillMasterData,
};
