import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Collapse, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DataTable } from "../../../../../../components/shared";
import { useParams } from "react-router-dom";
import { vitalsByAppointmentDetails } from "../../../../../../services/appointmentService";
import moment from "moment";

const Vitals = () => {
  const { appointmentId } = useParams();

  // data table variables

  const [initialCount, setInitialCount] = useState(5);
  const [allData, setAllData] = useState<any>([]);
  const [dataGridData, setDataGridData]: any = useState([]);
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);
  const [isDataGridExpanded, setIsDataGridExpanded] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "sNo",
      flex: 0.5,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          S.No
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.sNo}
        </Typography>
      ),
      minWidth: 60,
      maxWidth: 60,
      sortable: false,
    },
    {
      field: "observations",
      flex: 1,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Observations
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.vital_name}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "values",
      flex: 1,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Values
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.vital_value}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
  ];

  const toggleDataGrid = () => {
    setIsDataGridExpanded((prev) => !prev);
  };

  const getAppointmentVitals = async () => {
    try {
      await vitalsByAppointmentDetails(appointmentId).then((result: any) => {
        const data = result?.data;

        if (data) {
          const modifiedData = data?.map((item: any, index: number) => ({
            ...item,
            sNo: index + 1,
          }));

          const slicedData = modifiedData?.slice(0, initialCount);
          setAllData(modifiedData);
          setDataGridData(slicedData);
        }
        setIsDataTableLoading(false);
      });
    } catch (error) {
      console.error("An error occurred:", error);
      setIsDataTableLoading(false);
    }
  };

  useEffect(() => {
    if (isDataGridExpanded) {
      setDataGridData(allData);
    } else {
      const slicedData = allData.slice(0, initialCount);
      setDataGridData(slicedData);
    }
  }, [isDataGridExpanded]);

  useEffect(() => {
    if (appointmentId) {
      setIsDataTableLoading(true);
      setTimeout(getAppointmentVitals, 1500);
    }
  }, []);

  return (
    <Grid
      sx={{
        width: { xs: "98%", md: "49%" },
        minWidth: { xs: "98%", md: "49%" },
        boxShadow: "3px 5px 15px 0px #23232314",
        borderRadius: "5px",
        height: "auto",
        maxHeight: isDataGridExpanded
          ? `${200 + allData?.length * 50}px`
          : `${200 + initialCount * 50}px`,
        m: "20px 0px",
        border: 1,
        borderColor: "greyScale.lighter",
      }}
    >
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "12px 16px 0px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "primary.main",
            fontSize: "var(--primary-header-font-size) !important",
            fontWeight: "var(--primary-header-font-weight)",
          }}
        >
          Vitals
        </Typography>
        <Button
          sx={{
            color: "textPrimary.main",
            backgroundColor: "#F4F4F4",
            p: "10px",
            fontWeight: "500",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
          onClick={toggleDataGrid}
        >
          View All
        </Button>
      </Grid>
      <Grid
        sx={{
          width: "100%",
          p: "12px 16px 18px",
        }}
      >
        <DataTable
          loading={isDataTableLoading}
          columns={columns}
          rows={dataGridData}
          getRowId={(row: any) => `${String(row?.sNo)}`}
          tableOnly={true}
        />
      </Grid>
    </Grid>
  );
};

export default Vitals;
