import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import { formula } from "../../../utils/CalculationUtils";
import { Button, SaveButton, CancelButton } from "../../../components/basic";
import Info from "./Info";
import ProductRow from "./ProductRow";
import Summary from "./Summary";
import { labPurchaseReturnConst } from "../../../constants/displayText";
import {
  addLabPurchaseReturnProduct,
  getDepartmentSequence,
  getLabPurchaseReturnById,
  getLabPurchaseReturnProduct,
  updateLabPurchaseReturnProduct,
} from "../../../services/laboratoryService";
import { updatedSequence } from "../../../services/procurementMasterService";
import {
  timeWithTwentyFourHoursConverter,
  formatTwelveHoursTime,
  formattedDateWithSlash,
} from "../../../utils/DateTimeFormatUtils";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { SequenceWrapper } from "../../../components/shared";
import PermissionUtils from "../../../utils/PermissionUtils";

type TableHeaderCell = {
  TableCellName: string;
  alignment: "left" | "right";
  sx?: {};
};

type InfoType = {
  supplier_name: string | null;
  department: string | null;
  invoice_value: string | null;
  invoice_date: string | null;
  bill_no: string | null;
};

const BillProducts = ({ suppliersList, departmentList, loadOptions }: any) => {
  const {
    productName,
    batNo,
    disc,
    expDate,
    fBox,
    fQty,
    fRetBox,
    maxBox,
    maxQty,
    retBox,
    retQty,
    fRetQty,
    tax,
    ptr,
    amount,
  } = labPurchaseReturnConst;

  const {
    calculateDiscountAmount,
    calculateTaxAmount,
    calculateAmount,
    calculateBillAmount,
  } = formula.purchaseReturn;
  const [productRow, setProductRow] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { can } = PermissionUtils();

  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: {
          xs: "92vw",
        },
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: 1,
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "90vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "34px !important",
          maxHeight: "34px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },
        "& .MuiTableCell-root": {
          minHeight: "0px",
        },
        "& .MuiTableCell-head": {
          height: "43px",
          maxHeight: "43px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "13px",
          fontWeight: "600",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body": {
          height: "32px !important",
          maxHeight: "32px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "12px",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "31px",
        },
        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "30px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },

      textFieldStyle: {
        height: "34px",
        width: "auto",
        fontSize: "6px",

        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "34px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "12px",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "32px",
          maxHeight: "32px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },
      },
    }),
    []
  );

  const TableHeaderCells: TableHeaderCell[] = [
    { TableCellName: productName, alignment: "left" },
    { TableCellName: maxQty, alignment: "right" },
    { TableCellName: fQty, alignment: "right" },
    { TableCellName: maxBox, alignment: "right" },
    { TableCellName: fBox, alignment: "right" },
    { TableCellName: retBox, alignment: "right" },
    { TableCellName: retQty, alignment: "right" },
    { TableCellName: fRetBox, alignment: "right" },
    { TableCellName: fRetQty, alignment: "right" },
    { TableCellName: disc, alignment: "right" },
    { TableCellName: batNo, alignment: "left" },
    { TableCellName: expDate, alignment: "left" },
    { TableCellName: tax, alignment: "right" },
    { TableCellName: ptr, alignment: "right" },
    {
      TableCellName: amount,
      alignment: "right",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];

  const initialBillProducts = {
    id: null,
    product_name: null,
    autoComplete_input_value: null,
    max_qty: null,
    f_qty: null,
    max_box: null,
    f_box: null,
    ret_box: null,
    f_ret_box: null,
    net_free_return_qty: null,
    free_return_qty: null,
    net_return_qty: null,
    return_qty: null,
    discount: "",
    batch_no: "",
    expiry_date: "",
    tax: "",
    ptr: "",
    amount: 0,
    discount_amount: null,
    tax_amount: null,
    no_of_tests: null,
  };
  const [isSubmitLoaded, setIsSubmitLoaded] = useState(false);
  const [billProducts, setBillProducts] = useState<any>([initialBillProducts]);

  const [summary, setSummary] = useState({
    net_amount: 0,
    rounded_off: 0,
    bill_amount: 0,
  });

  const initialInfo = {
    supplier_name: "",
    department: "",
    invoice_value: "",
    invoice_date: "",
    bill_no: "",
  };

  const [customSequence, setCustomSequence] = useState(true);

  const [info, setInfo] = useState<InfoType>(initialInfo);
  const [batchIdNo, setBatchIdNo] = useState<any[]>([]);
  const [formError, setFormError] = useState<
    {
      net_return_qty: boolean;
      return_qty: boolean;
      net_free_return_qty: boolean;
      free_return_qty: boolean;
    }[]
  >([
    {
      net_return_qty: false,
      return_qty: false,
      net_free_return_qty: false,
      free_return_qty: false,
    },
  ]);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const [sequenceId, setSequenceId] = useState(null);

  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);

  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });

  const [seqIsOnce, setSeqIsOnce] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });

  const [isManualAdded, setIsManualAdded] = useState(false);

  const [isSequenceWrapperOpen, setIsSequenceWrapperOpen] = useState(false);

  const [errors, setErrors] = useState<any>([]);

  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");

  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };

  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value !== "Select" ? value : null,
      };
      return newState;
    });
  };

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number | string) => {
      const { name, value } = e.target;

      let numericValue = value.replace(/\D/g, "");
      setCurrentIndex(index as any);
      setBillProducts((prevState: any) => {
        const newState = [...prevState];

        newState[index as number] = {
          ...newState[index as number],
          [name]: numericValue,
        };
        if (name === "net_return_qty") {
          newState[index as number] = {
            ...newState[index as number],
            return_qty: Number(value) * newState[index as number].no_of_tests,
          };
        }
        if (name === "net_free_return_qty") {
          newState[index as number] = {
            ...newState[index as number],
            free_return_qty:
              Number(value) * newState[index as number].no_of_tests,
          };
        }
        handleValidation(
          { target: { name: name, value: newState[index as number][name] } },
          newState[index as number],
          index
        );
        newState[index as number] = {
          ...newState[index as number],
          amount: handleCalculateAmount(
            newState[index as number],
            newState[index as number]?.product_name
          ),
        };

        return newState;
      });
    },
    []
  );
  const handleValidation = (
    e: any,
    updatedValue: any,
    idx: number | string
  ) => {
    const { name, value } = e.target;
    switch (name) {
      case "net_return_qty": {
        if (Number(value | 0) > Number(updatedValue.max_box)) {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["net_return_qty"] = true;
            newState[idx as number]["return_qty"] = true;
            return newState;
          });
        } else if (
          Number(value | 0) + Number(updatedValue?.net_free_return_qty || 0) >
          Number(updatedValue.max_box)
        ) {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["net_return_qty"] = true;
            newState[idx as number]["return_qty"] = true;
            return newState;
          });
        } else {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["net_return_qty"] = false;
            newState[idx as number]["return_qty"] = false;
            return newState;
          });
        }
        break;
      }
      case "net_free_return_qty": {
        if (Number(value) > Number(updatedValue.f_box)) {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["net_free_return_qty"] = true;
            newState[idx as number]["free_return_qty"] = true;
            return newState;
          });
        } else {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["net_free_return_qty"] = false;
            newState[idx as number]["free_return_qty"] = false;
            return newState;
          });
        }
        break;
      }
      default:
        break;
    }
  };
  let isCreateLabPurchaseReturn = true;
  let isSequenceNumberChanged = false;

  const getSequenceDetails = async () => {
    try {
      setIsSubmitLoaded(true);

      await getDepartmentSequence("Lab Purchase Return").then((res: any) => {
        let data = res?.data[0]?.sequence_no;

        if (isCreateLabPurchaseReturn && !isSequenceNumberChanged) {
          setInitialSequenceNumber(data);
          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: data,
          }));

          setErrors((prevState: any) => ({
            ...prevState,
            bill_no: "",
          }));
        }

        if (isSequenceNumberChanged) {
          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: data,
          }));
          setErrors((prevState: any) => ({
            ...prevState,
            bill_no: "",
          }));
        }
        if (res?.data?.length > 0) {
          setSequenceId(res.data[0].sequence.id);
          const newSequenceData =
            res?.data[0]?.sequence?.sequence_preferences.map(
              (ele: {
                id: number;
                type: string;
                value: string;
                separator: string;
              }) => ({
                id: ele.id,
                type: ele.type,
                value: ele.value,
                separator: ele.separator,
              })
            );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual: res?.data[0]?.sequence?.is_manual === 1 ? true : false,
            is_auto_generate:
              res?.data[0]?.sequence?.is_manual !== 1 ? true : false,
          }));
          setSeqIsOnce((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(res?.data[0]?.sequence?.is_manual === 1));

          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: res?.data[0]?.sequence_no,
          }));

          setIsSubmitLoaded(false);
        }
      });
    } catch (error: any) {
      setIsSubmitLoaded(false);
      console.error("An error occurred:", error);
    }
  };

  const handleConfirmSequenceNoChange = () => {
    if (initialSequenceNumber !== info.bill_no) {
      setSeqIsOnce((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: info.bill_no,
      }));
      setIsSequenceWrapperOpen(true);
    }
  };

  const onCloseSequenceDialogBox = () => {
    setIsSequenceWrapperOpen(false);
  };

  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      try {
        const data = {
          seq_preference: [...sequenceData],
          is_manual: isManual.is_manual ? 1 : 0,
        };
        await updatedSequence(Number(sequenceId), data).then((res: any) => {
          if (res) {
            isSequenceNumberChanged = true;
            getSequenceDetails();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        });
      } catch (error: any) {
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
      }
    }
  };

  const handleCalculation = (name: string, productDetail: any) => {
    let num: string | number = 0;
    let qtyVal: string | number = 0;
    switch (name) {
      case "max_qty": {
        if (appConfiguration?.lab_goods_issue_disable) {
          num = productDetail?.original_qty
            ? (
                parseInt(productDetail?.original_qty) +
                parseInt(
                  productDetail?.adj_qty < 0 ? productDetail?.adj_qty : 0
                ) -
                parseInt(productDetail?.return_qty) -
                parseInt(productDetail?.sale_qty)
              ).toString()
            : "";
        } else {
          num = productDetail?.original_qty
            ? (
                parseInt(productDetail?.original_qty) +
                parseInt(productDetail?.free || 0) +
                parseInt(
                  productDetail?.adj_qty < 0 ? productDetail.adj_qty : 0
                ) -
                parseInt(productDetail?.return_qty) -
                parseInt(productDetail?.sale_qty) -
                parseInt(productDetail?.free_return_qty || 0)
              ).toString()
            : "";
        }
        break;
      }
      case "max_box": {
        if (appConfiguration?.lab_goods_issue_disable) {
          qtyVal = productDetail?.original_qty
            ? (
                parseInt(productDetail.original_qty) +
                parseInt(
                  productDetail.adj_qty < 0 ? productDetail.adj_qty : 0
                ) -
                parseInt(productDetail.return_qty) -
                parseInt(productDetail.sale_qty)
              ).toString()
            : "";
          num = qtyVal
            ? Math.ceil(
                Number(qtyVal) /
                  Number(
                    productDetail.no_of_tests == null
                      ? 0
                      : parseInt(productDetail.no_of_tests)
                  )
              )
            : 0;
        } else {
          qtyVal = productDetail?.original_qty
            ? (
                parseInt(productDetail?.original_qty) +
                parseInt(productDetail?.free || 0) +
                parseInt(
                  productDetail?.adj_qty < 0 ? productDetail.adj_qty : 0
                ) -
                parseInt(productDetail?.return_qty) -
                parseInt(productDetail?.sale_qty) -
                parseInt(productDetail?.free_return_qty || 0)
              ).toString()
            : "";
          num = qtyVal
            ? Math.ceil(
                Number(qtyVal) /
                  Number(
                    productDetail.no_of_tests == null
                      ? 0
                      : parseInt(productDetail.no_of_tests)
                  )
              )
            : 0;
        }
        break;
      }
      case "f_qty": {
        num =
          parseInt(productDetail?.free == null ? 0 : productDetail?.free) -
          parseInt(
            productDetail?.free_return_qty == null
              ? 0
              : productDetail?.free_return_qty
          );
        break;
      }
      case "f_box": {
        num =
          parseInt(
            productDetail.net_free == null ? 0 : productDetail.net_free
          ) -
          parseInt(
            productDetail.net_free_return_qty == null
              ? 0
              : productDetail.net_free_return_qty
          );
        break;
      }
      case "p_rate": {
        num = productDetail?.p_rate
          ? parseFloat(productDetail?.p_rate).toFixed(2)
          : "";
        break;
      }
      default: {
        break;
      }
    }
    return num;
  };

  const handleAutoCompleteChange = useCallback(
    (newValue: any, idx: number | string, name: string) => {
      if (newValue === null) {
        return false;
      }
      setBillProducts((prevState: any) => {
        let newState = [...prevState];
        if (newState.length > 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            max_qty: handleCalculation("max_qty", newValue),
            max_box: handleCalculation("max_box", newValue),
            ptr: handleCalculation("p_rate", newValue),
            f_qty: handleCalculation("f_qty", newValue),
            f_box: handleCalculation("f_box", newValue),
            no_of_tests: newValue.no_of_tests,
            expiry_date: formattedDateWithSlash(newValue.expires_at),
            batch_no: newValue.batch_no,
            amount: 0,
            discount: `${newValue.discount || 0}%`,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
          };
        }
        if (newState.length === 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            max_qty: handleCalculation("max_qty", newValue),
            max_box: handleCalculation("max_box", newValue),
            ptr: handleCalculation("p_rate", newValue),
            f_qty: handleCalculation("f_qty", newValue),
            f_box: handleCalculation("f_box", newValue),
            expiry_date: formattedDateWithSlash(newValue.expires_at),
            batch_no: newValue.batch_no,
            amount: 0,
            no_of_tests: newValue.no_of_tests,
            discount: `${newValue.discount || 0}%`,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
          };
        }
        setBatchIdNo((prev) => [...prev, newValue?.batch_id]);
        return newState;
      });
    },
    [appConfiguration]
  );
  const handleClick = useCallback(() => {
    setBillProducts((prev: any) => [
      ...prev,
      {
        ...initialBillProducts,
        id: prev.length,
      },
    ]);
    if (billProducts.length > 0) {
      setFormError((prev) => [
        ...prev,
        {
          free_return_qty: false,
          net_free_return_qty: false,
          net_return_qty: false,
          return_qty: false,
        },
      ]);
    }
  }, []);

  const handleDelete = (id: number) => {
    const newBillProducts: any = [...billProducts];
    newBillProducts.splice(id, 1);
    setBillProducts(newBillProducts);
    if (billProducts.length > 0) {
      const newErrorValue: any = [...formError];
      newErrorValue.splice(id, 1);
      setFormError(newErrorValue);
    }
  };
  const handleCalculateAmount = (product: any, productDetail: any) => {
    let amount_value: string | number = 0;
    let disc_value: string | number = 0;
    let tax_value: string | number = 0;
    const per_rate = productDetail?.p_rate ? Number(productDetail?.p_rate) : 0;
    const isInclusive =
      productDetail?.tax_settings === 0 ? "inclusive" : "exclusive";
    if (isNaN(product?.net_return_qty) || isNaN(per_rate)) {
      amount_value = 0;
    } else {
      amount_value = (product?.net_return_qty * per_rate).toFixed(2);
    }

    if (Boolean(!productDetail?.discount) || Boolean(!amount_value)) {
      disc_value = 0;
    } else {
      disc_value = (
        (productDetail?.discount / 100) *
        Number(amount_value)
      ).toFixed(2);
    }

    amount_value = Number(amount_value) - Number(disc_value);

    let tax_per = productDetail?.tax_id;
    if (isNaN(tax_per)) {
      tax_value = 0;
    } else {
      if (isInclusive === "exclusive") {
        tax_value = (Number(amount_value) * tax_per) / 100;
      } else {
        tax_value = 0;
      }
    }
    amount_value = (
      amount_value ? Number(amount_value) + Number(tax_value) : 0
    ).toFixed(2);
    return amount_value;
  };
  const updateBillData = () => {
    const billAmount = calculateBillAmount(billProducts);
    const decimalPart = Math.round((billAmount % 1) * 100);
    const roundedValue: any =
      Math.trunc(billAmount) + (decimalPart >= 50 ? 1 : 0);
    const difference = roundedValue - billAmount;
    setSummary((prevState) => ({
      ...prevState,
      bill_amount: isNaN(billAmount) ? 0 : billAmount,
      rounded_off: isNaN(difference) ? 0 : difference,
      net_amount: isNaN(roundedValue) ? 0 : roundedValue,
    }));
    setInfo((prev) => ({
      ...prev,
      invoice_value: roundedValue || "0.00",
    }));
  };
  const validateForm = (details: any) => {
    let validate = true;

    if (details.supplier_name === "") {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Supplier not to be empty!" })
      );
      validate = false;
    }
    if (details.department === "") {
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "department or supplier not to be empty!",
        })
      );
      validate = false;
    }
    if (details.invoice_date === "") {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Invoice Date not to be empty!" })
      );
      validate = false;
    }
    if (details.invoice_value === "") {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Invoice value not to be empty!" })
      );
      validate = false;
    }
    if (
      Boolean(formError.find((ele) => ele.net_free_return_qty)) ||
      Boolean(formError.find((ele) => ele.net_return_qty)) ||
      Boolean(formError.find((ele) => ele.return_qty)) ||
      Boolean(formError.find((ele) => ele.free_return_qty))
    ) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please check Table Values!" })
      );
      validate = false;
    }
    return validate;
  };
  const handleSubmit = async () => {
    if (validateForm(info)) {
      setIsSubmitLoaded(true);
      const productDetails: any =
        billProducts.length > 0
          ? billProducts.map((obj: any) => {
              let newObj: any = {};
              const {
                product_id,
                discount,
                bill_id,
                expires_at,
                tax_id,
                tax_type,
                p_rate,
                no_of_tests,
              } = obj.product_name;
              newObj = {
                product_id,
                no_of_tests,
                net_return_qty: obj.net_return_qty,
                return_qty: obj.return_qty,
                net_free_return_qty: obj.net_free_return_qty,
                free_return_qty: obj.free_return_qty,
                discount,
                expires_at,
                bill_id,
                tax_id,
                tax_type,
                p_rate,
                amount: obj.amount,
              };
              if (id) {
                newObj = {
                  ...newObj,
                  id: obj.product_name.id,
                  batch_no: bill_id,
                };
              }
              if (!id) {
                newObj = { ...newObj, batch_no: bill_id };
              }
              return newObj;
            })
          : 0;
      const data: any = {
        supplier_id: info.supplier_name,
        dept_id: info.department,
        invoice_value: info.invoice_value,
        invoice_date: info.invoice_date
          ? timeWithTwentyFourHoursConverter(info.invoice_date)
          : "",
        ref_no: info.bill_no,
        ref_no_modified:
          Number(info.bill_no) === Number(initialSequenceNumber) ? 0 : 1,
        product: [...productDetails],
        bill_amt: summary.bill_amount,
        total_amt: summary.net_amount,
      };
      if (id) {
        if (appConfiguration?.lab_goods_issue_disable) {
          data.to_dept = "1";
        }
        updateLabPurchaseReturnProduct(id as string, data as any)
          .then((res: any) => {
            navigate("/laboratory/purchase-bill");
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            setIsSubmitLoaded(false);
          })
          .catch((err) => {
            dispatch(
              setSnackBarFailed({ snackBarMessage: err.response.data.errors })
            );
            setIsSubmitLoaded(false);
            console.log(err);
          });
      } else {
        if (appConfiguration?.lab_goods_issue_disable) {
          data.to_dept = "1";
        }
        await addLabPurchaseReturnProduct(data as any)
          .then((res: any) => {
            navigate("/laboratory/purchase");
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            setIsSubmitLoaded(false);
          })
          .catch((err) => {
            dispatch(
              setSnackBarFailed({ snackBarMessage: err.response.data.errors })
            );
            setIsSubmitLoaded(false);
            console.log(err);
          });
      }
    }
  };
  const updateRows = (currentIndex: number) => {
    const amount = calculateAmount(billProducts, currentIndex);
    const discountAmount = calculateDiscountAmount(billProducts, currentIndex);
    const taxAmount = calculateTaxAmount(billProducts, currentIndex);

    if (
      amount !== +billProducts[currentIndex]?.amount ||
      taxAmount !== billProducts[currentIndex]?.tax_amount ||
      discountAmount !== billProducts[currentIndex]?.discount_amount
    ) {
      setBillProducts((prev: any) =>
        prev.map((billProduct: any, i: number) => {
          return i === currentIndex
            ? {
                ...billProduct,
                amount: isNaN(amount) ? 0 : amount,
                discount_amount: isNaN(discountAmount) ? 0 : discountAmount,
                tax_amount: isNaN(taxAmount) ? 0 : taxAmount,
              }
            : billProduct;
        })
      );
    }
  };
  useEffect(() => {
    updateRows(currentIndex);
  }, []);

  useEffect(() => {
    updateBillData();
  }, [billProducts]);

  useEffect(() => {
    if (info.department && info.supplier_name && !id && batchIdNo) {
      const data: any = {
        supplier_id: info.supplier_name,
        dept_id: info.department,
      };
      getLabPurchaseReturnProduct(data)
        .then((res: any) => setProductRow(res.data))
        .catch((err) => console.log(err));
    }
  }, [info.department, info.supplier_name, batchIdNo]);

  useEffect(() => {
    if (id) {
      getLabPurchaseReturnById(id as string)
        .then((res: any) => {
          const bill = res.data.bill;
          const billProducts = res.data.bill_products;
          setInfo((prevState) => ({
            ...prevState,
            bill_no: bill?.ref_no,
            department: bill?.dept_id,
            invoice_date: formatTwelveHoursTime(bill?.invoice_date),
            invoice_value: bill?.invoice_value,
            supplier_name: bill?.supplier_id,
          }));
          setInitialSequenceNumber(bill?.ref_no);
          const newData = billProducts.map((ele: any) => ({
            product_id: ele.product_id,
            isId: id,
            free: handleCalculation("free", ele),
            net_return_qty: ele.net_return_qty,
            f_net_return_qty: ele.free_return_qty,
            product_name: ele,
            max_qty: handleCalculation("max_qty", ele),
            max_box: handleCalculation("max_box", ele),
            ptr: handleCalculation("p_rate", ele),
            f_qty: handleCalculation("f_qty", ele),
            f_box: handleCalculation("f_box", ele),
            no_of_tests: ele.no_of_tests,
            return_qty: ele.return_qty,
            net_free_return_qty: ele.net_free_return_qty,
            free_return_qty: ele.free_return_qty,
            expiry_date: formattedDateWithSlash(ele.expires_at),
            batch_no: ele.batch_no,
            discount: `${ele.discount || 0}%`,
            tax: `${ele.taxname}-${ele.tax_id}%`,
            amount: Number(ele.amount) || 0,
          }));
          const newError = newData.map((ele: any) => ({
            net_return_qty: false,
            return_qty: false,
            net_free_return_qty: false,
            free_return_qty: false,
          }));
          setFormError(newError);
          setBillProducts(newData);
          isCreateLabPurchaseReturn = false;
          isSequenceNumberChanged = false;
          getSequenceDetails();
        })
        .catch((err) => console.log(err));
    } else {
      isCreateLabPurchaseReturn = true;
      isSequenceNumberChanged = false;
      getSequenceDetails();
    }
  }, [id, appConfiguration]);
  useEffect(() => {
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }
  }, [appConfiguration]);

  return (
    <Grid sx={{ width: "92px" }}>
      <Info
        info={info}
        setInfo={setInfo}
        suppliersList={suppliersList}
        departmentList={departmentList}
        isGoodsIssue={appConfiguration?.lab_goods_issue_disable}
        loadOptions={loadOptions}
        setIsSequenceWrapperOpen={setIsSequenceWrapperOpen}
        isManualAdded={isManualAdded}
        isManual={isManual}
        setIsManual={setIsManual}
        errors={errors}
        handleConfirmSequenceNoChange={handleConfirmSequenceNoChange}
        customSequence={customSequence}
      />
      <Grid
        sx={{
          width: {
            xs: "92vw",
          },
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          overflowX: { md: "scroll", lg: "hidden" },
        }}
      >
        <TableContainer
          className="table-container"
          component={Paper}
          sx={{
            ...styles.tableContainerStyle,
          }}
        >
          <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {TableHeaderCells.map((cell, index) => {
                  return (
                    <TableCell align={cell.alignment} sx={cell.sx} key={index}>
                      {cell.TableCellName}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody
              sx={{
                height: "auto",
                width: {
                  xs: "400vw",
                  sm: "200vw",
                  md: "120vw",
                  lg: "90vw",
                },
              }}
            >
              {billProducts.map((row: any, index: number) => {
                return (
                  <ProductRow
                    key={row.id}
                    index={index}
                    row={row}
                    styles={styles}
                    allRows={productRow}
                    formError={formError}
                    handleInputChange={handleInputChange}
                    handleDelete={handleDelete}
                    handleAutoCompleteChange={handleAutoCompleteChange}
                  />
                );
              })}
            </TableBody>
          </Table>
          <Grid
            sx={{
              width: {
                xs: "400vw",
                sm: "200vw",
                md: "120vw",
                lg: "90vw",
              },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "0px 0px 5px 5px",
              border: 1,
              borderColor: "var(--table-border)",
              borderTop: 0,
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: {
                  xs: "50%",
                },
              }}
            >
              <Button
                variant="text"
                buttonText="+ Add Product"
                handleClick={handleClick}
                sx={{
                  borderRadius: "0px",
                  width: "auto",
                  height: "auto",
                  fontWeight: 400,
                  fontSize: "13px",
                  border: 0,
                  color: "primary.main",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "primary.main",
                    borderColor: "initial",
                  },
                  "&.Mui-focusVisible": {
                    border: 1,
                    borderColor: "primary.main",
                    borderRadius: "5px",
                  },
                }}
              />
            </Grid>
          </Grid>
        </TableContainer>
      </Grid>
      <Summary summary={summary} />
      {can("lab_purchase_return_add") && (
        <Grid
          sx={{ display: "flex", flexDirection: "row", m: "35px 0 45px 0" }}
        >
          <SaveButton
            handleClick={() => handleSubmit()}
            loading={isSubmitLoaded}
            sx={{ width: "100px", height: "40px", mr: "20px" }}
          />
          <CancelButton sx={{ width: "100px", height: "40px" }} />
        </Grid>
      )}
      <SequenceWrapper
        open={isSequenceWrapperOpen}
        title="Lab Purchase Return"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
    </Grid>
  );
};

export default BillProducts;
