import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
} from "@mui/material";
import { formula } from "../../../utils/CalculationUtils";
import { Button, SaveButton, CancelButton } from "../../../components/basic";
import Info from "./Info";
import ProductRow from "./ProductRow";
import Summary from "./Summary";
import { goodsIssueReturnConst } from "../../../constants/displayText";
import {
  createLabGoodsIssueReturn,
  getLabGoodsReturnProducts,
  getAllLabMasterDepartments,
} from "../../../services/laboratoryService";

import { getDepartmentSequence } from "../../../services/mainCoreService";
import { useDispatch, useSelector } from "react-redux";
import { formattedDateWithSlash } from "../../../utils/DateTimeFormatUtils";

import { RootState } from "../../../redux/store";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import { useNavigate } from "react-router-dom";
import { SequenceWrapper } from "../../../components/shared";
import { updatedSequence } from "../../../services/procurementMasterService";
import PermissionUtils from "../../../utils/PermissionUtils";

type TableHeaderCell = {
  TableCellName: string;
  alignment: "left" | "right";
  sx?: {};
};

type SummaryType = {
  total_discount_amount: number;
  goods_value: number;
  CGST: number;
  SGST: number;
  tax_amount: number;
  rounded_off: number;
  bill_amount: number;
};

type InfoType = {
  from_dept: string | number;
  to_dept: string | number;
  invoice_no: number | null;
};

const BillProducts = React.memo(() => {
  const {
    productName,
    batchNo,
    expiryDate,
    stock,
    retQty,
    mrp,
    discount,
    tax,
    amount,
  } = goodsIssueReturnConst;

  const {
    calculateAmount,
    calculateTaxAmount,
    calculateRoundedOff,
    calculateNetAmount,
  } = formula.goodsIssueReturn;
  const {
    calculateSubTaxes,
    calculateDiscountAmount,
    calculateTotalDiscountAmount,
  } = formula.purchaseBill;
  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: {
          xs: "92vw",
        },
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "90vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "34px !important",
          maxHeight: "34px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },
        "& .MuiTableCell-root": {
          minHeight: "0px",
        },
        "& .MuiTableCell-head": {
          height: "43px",
          maxHeight: "43px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "13px",
          fontWeight: "600",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body": {
          height: "32px !important",
          maxHeight: "32px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "12px",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "31px",
        },
        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "30px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },

      textFieldStyle: {
        height: "34px",
        width: "auto",
        fontSize: "6px",

        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "34px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "12px",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "32px",
          maxHeight: "32px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },
      },

      selectStyle: {
        "& .MuiFormControl-root": { width: "100%" },

        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
          borderWidth: 0,
          height: "34px",
          maxHeight: "34px",
          p: 0,
        },

        "& .MuiInputBase-root": {
          padding: "0px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },

        "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "primary.main",
            borderWidth: "1px",
          },

        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: 0,
        },
      },
    }),
    []
  );

  const TableHeaderCells: TableHeaderCell[] = [
    { TableCellName: productName, alignment: "left" },
    { TableCellName: batchNo, alignment: "left" },
    { TableCellName: expiryDate, alignment: "left" },
    { TableCellName: stock, alignment: "right" },
    { TableCellName: retQty, alignment: "right" },
    { TableCellName: mrp, alignment: "right" },
    { TableCellName: discount, alignment: "right" },
    { TableCellName: tax, alignment: "right" },
    {
      TableCellName: amount,
      alignment: "right",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { can } = PermissionUtils();

  const [isSubmitLoaded, setIsSubmitLoaded] = useState(false);
  const [customSequence, setCustomSequence] = useState(true);
  const initialBillProduct = {
    pack: null,
    qty: null,
    mrp: 0,
    id: 0,
    product_name: null,
    autoComplete_input_value: null,
    stock: null,
    free: null,
    ret_qty: null,
    f_ret_qty: null,
    discount: "",
    batch_no: "",
    expiry_date: "",
    tax: "",
    ptr: "",
    amount: null,
    discount_amount: null,
    tax_amount: null,
  };

  const [billProducts, setBillProducts] = useState<any>([initialBillProduct]);
  const [productRow, setProductRow] = useState([]);

  const initialSummary = {
    total_discount_amount: 0,
    goods_value: 0,
    CGST: 0,
    SGST: 0,
    tax_amount: 0,
    rounded_off: 0,
    bill_amount: 0,
  };

  const [summary, setSummary] = useState<SummaryType>(initialSummary);
  const [issuerList, setIssuerList] = useState([]);
  const [receiverList, setReceiverList] = useState([]);
  const initialInfo = {
    from_dept: "",
    to_dept: "",
    invoice_no: null,
  };

  const [info, setInfo] = useState<InfoType>(initialInfo);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [initialRender, setInitialRender] = useState(true);
  const [formError, setFormError] = useState<
    {
      ret_qty: boolean;
    }[]
  >([
    {
      ret_qty: false,
    },
  ]);
  const [sequenceId, setSequenceId] = useState(null);

  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);

  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });

  const [seqIsOnce, setSeqIsOnce] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });

  const [isManualAdded, setIsManualAdded] = useState(false);

  const [isSequenceWrapperOpen, setIsSequenceWrapperOpen] = useState(false);

  const [errors, setErrors] = useState<any>([]);

  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");

  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };

  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value !== "Select" ? value : null,
      };
      return newState;
    });
  };

  let isCreateLabGoodsIsuue = true;
  let isSequenceNumberChanged = false;

  const getSequenceDetails = async () => {
    try {
      setIsSubmitLoaded(true);

      await getDepartmentSequence("Lab Goods Issue Return").then((res: any) => {
        let data = res?.data[0]?.sequence_no;

        if (isCreateLabGoodsIsuue && !isSequenceNumberChanged) {
          setInitialSequenceNumber(data);
          setInfo((prevState: any) => ({
            ...prevState,
            invoice_no: data,
          }));

          setErrors((prevState: any) => ({
            ...prevState,
            invoice_no: "",
          }));
        }

        if (isSequenceNumberChanged) {
          setInfo((prevState: any) => ({
            ...prevState,
            invoice_no: data,
          }));
          setErrors((prevState: any) => ({
            ...prevState,
            invoice_no: "",
          }));
        }
        if (res?.data?.length > 0) {
          setSequenceId(res.data[0].sequence.id);
          const newSequenceData =
            res?.data[0]?.sequence?.sequence_preferences.map(
              (ele: {
                id: number;
                type: string;
                value: string;
                separator: string;
              }) => ({
                id: ele.id,
                type: ele.type,
                value: ele.value,
                separator: ele.separator,
              })
            );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual: res?.data[0]?.sequence?.is_manual === 1 ? true : false,
            is_auto_generate:
              res?.data[0]?.sequence?.is_manual !== 1 ? true : false,
          }));
          setSeqIsOnce((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(res?.data[0]?.sequence?.is_manual === 1));

          setIsSubmitLoaded(false);
        }
      });
    } catch (error: any) {
      setIsSubmitLoaded(false);
      console.error("An error occurred:", error);
    }
  };

  const handleConfirmSequenceNoChange = () => {
    if (initialSequenceNumber !== info.invoice_no) {
      setSeqIsOnce((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: info.invoice_no,
      }));
      setIsSequenceWrapperOpen(true);
    }
  };

  const onCloseSequenceDialogBox = () => {
    setIsSequenceWrapperOpen(false);
  };

  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      try {
        const data = {
          seq_preference: [...sequenceData],
          is_manual: isManual.is_manual ? 1 : 0,
        };
        await updatedSequence(Number(sequenceId), data).then((res: any) => {
          if (res) {
            isSequenceNumberChanged = true;
            getSequenceDetails();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        });
      } catch (error: any) {
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
      }
    }
  };

  const handleValidation = (
    currentValue: any,
    updatedValue: any,
    idx: number | string
  ) => {
    if (Number(currentValue?.ret_qty) > Number(updatedValue?.original_qty)) {
      setFormError((prevState) => {
        const newState = [...prevState];
        newState[idx as number]["ret_qty"] = true;
        return newState;
      });
    } else {
      setFormError((prevState) => {
        const newState = [...prevState];
        newState[idx as number]["ret_qty"] = false;
        return newState;
      });
    }
  };
  const handleCalculateAmount = (product: any, name: string) => {
    let num = 0;
    if (isNaN(product?.qty) || isNaN(product?.product_name?.mrp)) {
      return num;
    }
    if (name === "mrp" && product?.qty) {
      num =
        (product?.qty * product?.product_name?.mrp) /
        Number(product?.product_name?.no_of_tests);
      return num;
    }
    if (name === "amount" && product?.ret_qty) {
      num =
        (product?.ret_qty * product?.product_name?.mrp) /
        Number(product?.product_name?.no_of_tests);
      return num;
    }
    return num;
  };

  const handleInputChange = useCallback((e: any, index: number) => {
    const { name, value } = e.target;

    let numericValue = value.replace(/\D/g, "");

    setCurrentIndex(index as any);

    setBillProducts((prevState: any) => {
      const newState = [...prevState];

      newState[index as number] = {
        ...newState[index as number],
        [name]: numericValue,
      };
      handleValidation(
        newState[index as number],
        newState[index as number].product_name,
        index
      );
      newState[index as number] = {
        ...newState[index as number],
        amount: handleCalculateAmount(newState[index as number], "amount"),
      };
      newState[index as number] = {
        ...newState[index as number],
        discount_amount:
          calculateDiscountAmount(
            newState[index as number]?.amount || 0,
            newState[index as number]?.product_name?.discount || 0,
            newState[index as number]?.product_name?.discount_type
          ) || 0,
      };
      calculateBillData(newState);
      return newState;
    });
  }, []);

  const handleAutoCompleteChange = useCallback(
    (newValue: any, idx: number | string, name: string) => {
      if (newValue === null) {
        return false;
      }
      setBillProducts((prevState: any) => {
        let newState = [...prevState];
        if (newState.length > 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            mrp: handleCalculateAmount(
              {
                qty: 1,
                product_name: {
                  no_of_tests: newValue.no_of_tests,
                  mrp: newValue.mrp,
                },
              },
              "mrp"
            ),
            stock_qty: newValue.original_qty,
            expiry_date: formattedDateWithSlash(newValue.expires_at),
            batch_no: `${newValue.batch_no}#${newValue.original_qty}`,
            amount: 0,
            discount: `${newValue.discount || 0}%`,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
            discount_amount:
              calculateDiscountAmount(
                newState[idx as number]?.amount,
                newState[idx as number]?.newValue?.discount || 0,
                newState[idx as number]?.newValue?.discount_type
              ) || 0,
          };
        }
        if (newState.length === 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            // pack: newValue.pack_type,
            expiry_date: formattedDateWithSlash(newValue.expires_at),
            mrp: handleCalculateAmount(
              {
                qty: 1,
                product_name: {
                  no_of_tests: newValue.no_of_tests,
                  mrp: newValue.mrp,
                },
              },
              "mrp"
            ),
            stock_qty: newValue.original_qty,
            batch_no: `${newValue.batch_no}#${newValue.original_qty}`,
            amount: 0,
            discount: `${newValue.discount || 0}%`,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
            discount_amount:
              calculateDiscountAmount(
                newState[idx as number]?.amount,
                newState[idx as number]?.newValue?.discount || 0,
                newState[idx as number]?.newValue?.discount_type
              ) || 0,
          };
        }
        // setBatchIdNo((prev) => [...prev, newValue?.batch_id]);
        return newState;
      });
    },
    []
  );
  const getAllDepartmentList = async () => {
    const data = { active: 1 };
    await getAllLabMasterDepartments(data)
      .then((res: any) => {
        const formattedValue = res.data?.result?.map(
          (ele: { dept_name: string; id: number; type: string }) => ({
            name: ele.dept_name,
            id: ele.id,
            type: ele.type,
          })
        );
        const newIssuerList = formattedValue?.filter(
          (ele: { dept_name: string; id: number; type: string }) =>
            ele?.type !== "B2B" && ele?.type === "issuer"
        );
        const newReceiverList = formattedValue?.filter(
          (ele: { dept_name: string; id: number; type: string }) =>
            ele?.type !== "B2B" && ele?.type === "receiver"
        );
        setIssuerList(newIssuerList);
        setReceiverList(newReceiverList);
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    getAllDepartmentList();
    getSequenceDetails();
  }, []);

  const handleClick = useCallback(() => {
    setBillProducts((prev: any) => [
      ...prev,
      {
        ...initialBillProduct,
        id: prev.length,
      },
    ]);
    setFormError((prev) => [...prev, { ret_qty: false }]);
  }, []);

  const handleDelete = useCallback(
    (id: number) => {
      const newBillProducts = [...billProducts];
      newBillProducts.splice(id, 1);
      setBillProducts(newBillProducts);

      const newFormError = [...formError];
      newFormError.splice(id, 1);
      setFormError(newFormError);
    },
    [billProducts, formError]
  );

  const updateBillData = () => {
    const roundedOff = calculateRoundedOff(summary);
    const netAmount = calculateNetAmount(summary);
    if (
      roundedOff !== summary.rounded_off ||
      netAmount !== summary.bill_amount
    ) {
      setSummary((prevBillData: any) => ({
        ...prevBillData,
        rounded_off: roundedOff,
        bill_amount: netAmount,
      }));
    }
  };

  const calculateBillData = (allProducts: any) => {
    const newValues = allProducts.map((ele: any) => {
      const newNum = Boolean(ele?.ret_qty) ? ele?.ret_qty * ele?.mrp : 0;
      const newGoodsValue = newNum / (1 + ele?.product_name?.tax_id / 100);
      const newTaxValue = (ele?.product_name?.tax_id / 100) * newGoodsValue;
      return {
        newNum: newNum,
        newGoodsValue: newGoodsValue,
        newTaxValue: newTaxValue,
        tax: {
          id: ele.product_name?.id,
          name: ele.tax,
          tax_name: ele.product_name?.taxname,
          tax_rate: ele.product_name?.tax_id,
          sub_taxes: ele.product_name?.sub_tax,
        },
        tax_amount: newTaxValue,
      };
    });
    const total_discount_amount =
      calculateTotalDiscountAmount(allProducts) || 0;
    const value = calculateSubTaxes(newValues) || 0;
    setSummary((prevState: any) => ({
      ...prevState,
      goods_value: calculateProductAmount(newValues, "newGoodsValue"),
      tax_amount: calculateProductAmount(newValues, "newTaxValue"),
      bill_amount: calculateProductAmount(newValues, "newNum"),
      CGST: value.CGST,
      SGST: value.SGST,
      total_discount_amount: total_discount_amount,
    }));
  };
  const calculateProductAmount = (rows: any, name: string) => {
    const updatedValue = rows[0][name];
    return updatedValue || 0;
  };
  const updateRows = (currentIndex: number) => {
    const amount = calculateAmount(billProducts, currentIndex);
    const discountAmount = calculateDiscountAmount(billProducts, currentIndex);
    const taxAmount = calculateTaxAmount(billProducts, currentIndex);

    if (
      amount !== +billProducts[currentIndex]?.amount ||
      taxAmount !== billProducts[currentIndex]?.tax_amount ||
      discountAmount !== billProducts[currentIndex]?.discount_amount
    ) {
      setBillProducts((prev: any) =>
        prev.map((billProduct: any, i: number) => {
          return i === currentIndex
            ? {
                ...billProduct,
                amount: amount,
                discount_amount: discountAmount,
                tax_amount: taxAmount,
              }
            : billProduct;
        })
      );
    }
  };

  useEffect(() => {
    updateRows(currentIndex);
  }, []);

  useEffect(() => {
    updateBillData();
  }, [billProducts]);

  useEffect(() => {
    if (info.from_dept && info.to_dept) {
      const data: any = {
        from_dept: info.from_dept,
        to_dept: info.to_dept,
      };
      getLabGoodsReturnProducts(data)
        .then((res: any) => setProductRow(res.data))
        .catch((err) => console.log(err));
    }
  }, [info.from_dept, info.to_dept]);
  useEffect(() => {
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }
  }, [appConfiguration]);

  const validateForm = (details: any) => {
    let validate = true;
    if (!details.from_dept) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Issuer not to be empty!" })
      );
      validate = false;
    }

    if (!details.to_dept) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Receiver not to be empty!" })
      );
      validate = false;
    }
    if (Boolean(billProducts.find((ele: any) => !ele.product_name))) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please select product!" })
      );
      validate = false;
    }
    if (Boolean(billProducts.find((ele: any) => !ele.ret_qty))) {
      dispatch(setSnackBarFailed({ snackBarMessage: "Please Enter Ret Qty!" }));
      validate = false;
    }
    if (Boolean(formError.find((ele) => ele.ret_qty))) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please check Table Values!" })
      );
      validate = false;
    }
    return validate;
  };
  const handleSubmit = async () => {
    if (validateForm(info)) {
      const productDetails: any =
        billProducts.length > 0
          ? billProducts.map((obj: any) => {
              let newObj = {};
              const {
                product_id,
                pack_type,
                discount,
                expires_at,
                tax_id,
                tax_type,
                supplier_id,
                p_rate,
                bill_id,
                issue_batch_id,
                no_of_tests,
                id,
              } = obj.product_name;
              newObj = {
                product_id,
                ref_bill: bill_id,
                pack_type,
                ref_batch_id: id,
                p_rate,
                supplier_id,
                issue_batch_id,
                batch_no: id,
                expires_at,
                no_of_tests,
                qty: obj.ret_qty,
                mrp: obj.mrp,
                discount,
                tax_id,
                tax_type,
                amount: obj.amount,
              };
              return newObj;
            })
          : 0;
      const data = {
        from_dept: info.from_dept,
        to_dept: info.to_dept,
        invoice_no: info.invoice_no,
        invoice_no_modified:
          Number(info.invoice_no) === Number(initialSequenceNumber) ? 0 : 1,
        product: [...productDetails],
        bill_amt: summary.bill_amount,
        orig_goods_val: summary.goods_value.toFixed(2),
        disc_goods_val: summary.goods_value.toFixed(2),
        disc_val: 0,
        gst_amt: 18,
      };
      setIsSubmitLoaded(true);
      await createLabGoodsIssueReturn(data as any)
        .then((res: any) => {
          navigate("/laboratory/lab-goods-issue");
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          setIsSubmitLoaded(false);
        })
        .catch((err) => {
          dispatch(
            setSnackBarFailed({ snackBarMessage: err.response.data.errors })
          );
          setIsSubmitLoaded(false);
          console.log(err);
        });
    }
  };
  return (
    <Grid sx={{ width: "92vw" }}>
      <Info
        info={info}
        setInfo={setInfo}
        issuerList={issuerList}
        receiverList={receiverList}
        setIsSequenceWrapperOpen={setIsSequenceWrapperOpen}
        isManualAdded={isManualAdded}
        isManual={isManual}
        setIsManual={setIsManual}
        errors={errors}
        handleConfirmSequenceNoChange={handleConfirmSequenceNoChange}
        customSequence={customSequence}
      />
      <Grid
        sx={{
          width: {
            xs: "92vw",
          },
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          overflowX: { md: "scroll", lg: "hidden" },
        }}
      >
        <TableContainer
          className="table-container"
          component={Paper}
          sx={{
            ...styles.tableContainerStyle,
          }}
        >
          <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {TableHeaderCells.map((cell, index) => {
                  return (
                    <TableCell align={cell.alignment} sx={cell.sx} key={index}>
                      {cell.TableCellName}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody
              sx={{
                height: "auto",
                width: {
                  xs: "400vw",
                  sm: "200vw",
                  md: "120vw",
                  lg: "90vw",
                },
              }}
            >
              {billProducts?.map((row: any, index: number) => {
                return (
                  <ProductRow
                    key={row.id}
                    index={index}
                    row={row}
                    styles={styles}
                    allRows={productRow}
                    formError={formError}
                    handleInputChange={handleInputChange}
                    handleDelete={handleDelete}
                    handleAutoCompleteChange={handleAutoCompleteChange}
                  />
                );
              })}
            </TableBody>
          </Table>
          <Grid
            sx={{
              width: {
                xs: "400vw",
                sm: "200vw",
                md: "120vw",
                lg: "90vw",
              },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "0px 0px 5px 5px",
              border: 1,
              borderColor: "var(--table-border)",
              borderTop: 0,
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: {
                  xs: "78%",
                },
              }}
            >
              <Button
                variant="text"
                buttonText="+ Add Product"
                handleClick={handleClick}
                sx={{
                  borderRadius: "0px",
                  width: "auto",
                  height: "auto",
                  fontWeight: 400,
                  fontSize: "13px",
                  border: 0,
                  color: "primary.main",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "primary.main",
                    borderColor: "initial",
                  },
                  "&.Mui-focusVisible": {
                    border: 1,
                    borderColor: "primary.main",
                    borderRadius: "5px",
                  },
                }}
              />
              <Grid
                sx={{
                  width: "23%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  sx={{
                    width: "60%",
                  }}
                >
                  <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                    Discount Value :
                  </Typography>
                </Grid>
                <Typography
                  sx={{ fontSize: "13px", fontWeight: "600", mr: "1%" }}
                >
                  {summary?.total_discount_amount?.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                width: {
                  xs: "20%",
                },
              }}
            >
              <Grid
                sx={{
                  width: "60%",
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                  Goods Value :
                </Typography>
              </Grid>
              <Typography
                sx={{ fontSize: "13px", fontWeight: "600", mr: "2%" }}
              >
                {summary?.goods_value?.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </TableContainer>
      </Grid>
      <Summary
        summary={summary}
        setSummary={setSummary}
        handleSaveData={() => {}}
      />
      {can("lab_goods_issue_transfer_add") && (
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            m: "35px 0 45px 0",
          }}
        >
          <SaveButton
            handleClick={() => handleSubmit()}
            loading={isSubmitLoaded}
            sx={{ width: "100px", height: "40px", mr: "20px" }}
          />
          <CancelButton sx={{ width: "100px", height: "40px" }} />
        </Grid>
      )}
      <SequenceWrapper
        open={isSequenceWrapperOpen}
        title="Lab Goods Issue Return"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
    </Grid>
  );
});

export default BillProducts;
