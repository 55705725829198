import CryptoJS from "crypto-js";

// Type for encryption result
interface EncryptedData {
  iv: string;
  value: string;
  mac: string;
}

// CookieEncryption class for handling encryption and decryption
export class CookieEncryption {
  private key: CryptoJS.lib.WordArray;
  private static encryptionKey: string = process.env.REACT_APP_ENCRYPTION_KEY || "";

  constructor() {
    this.key = CryptoJS.enc.Base64.parse(CookieEncryption.encryptionKey); // Parse the base64 key once
  }

  // Encrypt function
  encrypt(data: string): string {
    let iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
    let options = { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 };

    let encrypted = CryptoJS.AES.encrypt(data, this.key, options).toString();
    let ivBase64 = CryptoJS.enc.Base64.stringify(iv); // Convert IV to Base64 format
    let mac = CryptoJS.HmacSHA256(ivBase64 + encrypted, this.key).toString(); // HMAC for integrity

    let result: EncryptedData = { iv: ivBase64, value: encrypted, mac: mac };
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(result))); // Base64 encoded result
  }

  // Decrypt function
  decrypt(encryptStr: string): string | null {
    try {
      const decodedStr = CryptoJS.enc.Base64.parse(encryptStr).toString(CryptoJS.enc.Utf8);
      let encryptData: EncryptedData = JSON.parse(decodedStr);

      let iv = CryptoJS.enc.Base64.parse(encryptData.iv);
      let decrypted = CryptoJS.AES.decrypt(encryptData.value, this.key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      return CryptoJS.enc.Utf8.stringify(decrypted); // Return decrypted value
    } catch (error) {
      console.error("Decryption failed:", error);
      return null;
    }
  }
}
