import Cookies from "js-cookie";
import { RouteUrls } from "../constants/routes";
import moment from "moment";
import { CookieEncryption } from './CookieEncryption';

// Function to set an encrypted cookie
const setCookie = (key: string, value: object | string): void => {
  const encryptor = new CookieEncryption();
  let serializedValue = typeof value === "object" ? JSON.stringify(value) : value;
  const encryptedValue = encryptor.encrypt(serializedValue);

  const options: any = {
    path: "/",
  };

  // Add cookie options based on env
  if (process.env.REACT_APP_SECURE_COOKIE === "true") {
    options.domain = process.env.REACT_APP_COOKIE_DOMAIN;
    options.sameSite = process.env.REACT_APP_COOKIE_SAME_SITE?.toLowerCase() as 'strict' | 'lax' | 'none' | undefined;
    options.secure = true;
  }

  Cookies.set(key, encryptedValue, options);
};

// Function to get and decrypt an encrypted cookie
const getCookie = (key: string): string | null => {
  const encryptor = new CookieEncryption();
  const encryptedValue = Cookies.get(key);
  if (encryptedValue) {
    return encryptor.decrypt(encryptedValue);
  }
  return null; // Return null if the cookie doesn't exist
};

const removeCookie = (key: string) => {
  Cookies.remove(key);
};
const getAppBarTitleFromPath = (
  pathname: string,
  optionalParam?: string | number
) => {
  switch (pathname) {
    case `${RouteUrls.signInUrl}`:
      return "Sign In";
    case `${RouteUrls.appointmentUrl}${RouteUrls.dashboardUrl}`:
      return "Dashboard";
    case `${RouteUrls.dashboardUrl}`:
      return "Dashboard";
    case RouteUrls.appointmentUrl:
      return "Appointments";
    case `${RouteUrls.appointmentUrl}${RouteUrls.appointmentsBooking}`:
      return "Book Appointments";
    case `${RouteUrls.appointmentUrl}${RouteUrls.listUrl}`:
      return "Appointment List";
    case `${RouteUrls.appointmentUrl}${RouteUrls.masterUrl}`:
      return "Appointment Master";
    case `${RouteUrls.appointmentUrl}${RouteUrls.appointmentUrl}`:
      return "Consultations";
    case `${RouteUrls.appointmentUrl}${RouteUrls.appointmentsDetails}/${optionalParam}`:
      return "Appointment Details";
    case RouteUrls.patientUrl:
      return "Patients";
    case RouteUrls.paymentUrl:
      return "Payment";
    case RouteUrls.procurementUrl:
      return "Procurement";
    case `${RouteUrls.procurementUrl}${RouteUrls.masterUrl}`:
      return "Master";
    case `${RouteUrls.procurementUrl}${RouteUrls.purchaseBillUrl}`:
      return "Purchase Bill";
    case `${RouteUrls.procurementUrl}${RouteUrls.purchaseBillUrl}${RouteUrls.createUrl}`:
      return "Create Purchase Bill";
    case `${RouteUrls.procurementUrl}${RouteUrls.purchasePaymentListUrl}`:
      return "Purchase Payment List";
    case `${RouteUrls.procurementUrl}${RouteUrls.goodsIssueUrl}`:
      return "Goods Issue Bills";
    case `${RouteUrls.procurementUrl}${RouteUrls.goodsIssueUrl}${RouteUrls.createUrl}`:
      return "Add Goods Issue";
    case `${RouteUrls.procurementUrl}${RouteUrls.stockAdjustmentUrl}`:
      return "Stock Adjustments";
    case `${RouteUrls.procurementUrl}${RouteUrls.stockAdjustmentUrl}${RouteUrls.createUrl}`:
      return "Add Stock Adjustment";
    case `${RouteUrls.procurementUrl}${RouteUrls.purchaseOrdersUrl}`:
      return "Purchase Orders";
    case `${RouteUrls.procurementUrl}${RouteUrls.purchasePaymentListUrl}${RouteUrls.createUrl}`:
      return "Add Purchase Payment";
    case `${RouteUrls.procurementUrl}${RouteUrls.purchaseReturnUrl}`:
      return "Purchase Return";
    case `${RouteUrls.adminUrl}${RouteUrls.patientAccountUrl}`:
      return "Patients Account";
    case `${RouteUrls.appointmentUrl}${RouteUrls.rescheduleAppointment}/${optionalParam}`:
      return "Reschedule Appointment";
    case `${RouteUrls.appointmentUrl}${RouteUrls.reviewAppointment}/${optionalParam}`:
      return "Review Appointment";
    case `${RouteUrls.billingUrl}${RouteUrls.billingItemsUrl}`:
      return "Items List";
    case `${RouteUrls.billingUrl}${RouteUrls.billingAddItemUrl}`:
      return "Add Item";
    case `${RouteUrls.billingUrl}${RouteUrls.billingAddItemUrl}/${optionalParam}`:
      return "Edit Item";
    case `${RouteUrls.paymentUrl}${RouteUrls.listUrl}`:
      return "Payment List";
    case `${RouteUrls.paymentUrl}${RouteUrls.createUrl}`:
      return "Add Payment";
    case `${RouteUrls.paymentUrl}${RouteUrls.editUrl}/${optionalParam}`:
      return "Edit Payment";
    case `${RouteUrls.paymentUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Payment";
    case `${RouteUrls.paymentUrl}${RouteUrls.paymentAdjustmentUrl}`:
      return "Payment Adjustments";
    case `${RouteUrls.billingUrl}${RouteUrls.billingItemsMasterUrl}`:
      return "Items Master";
    case `${RouteUrls.billingUrl}${RouteUrls.billingListUrl}`:
      return "Patients Bills";
    case `${RouteUrls.billingUrl}${RouteUrls.createUrl}`:
      return "Add Patient Bill";
    case `${RouteUrls.billingUrl}${RouteUrls.listUrl}/${optionalParam}`:
      return "Edit Patient Bill";
    case `${RouteUrls.billingUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Patient Bill";
    case `${RouteUrls.appointmentUrl}${RouteUrls.viewRecords}${RouteUrls.report}/${optionalParam}`:
      return "Medical Records";
    case `${RouteUrls.procurementUrl}${RouteUrls.goodsIssueReturnUrl}`:
      return "Goods Issue Return";
    case `${RouteUrls.procurementUrl}${RouteUrls.goodsIssueReturnUrl}/${optionalParam}`:
      return "Edit Goods Issue Return";
    case `${RouteUrls.procurementUrl}${RouteUrls.goodsIssueReturnUrl}${RouteUrls.view}/${optionalParam}`:
      return "View Goods Issue Return";
    // case `${RouteUrls.procurementUrl}${RouteUrls.purchasePaymentListUrl}`:
    //   return "Purchase Payment list";
    // case `${RouteUrls.procurementUrl}${RouteUrls.purchasePaymentListUrl}${RouteUrls.createUrl}`:
    //   return "Purchase Payments Add";
    case `${RouteUrls.procurementUrl}${RouteUrls.purchasePaymentListUrl}${RouteUrls.editUrl}/${optionalParam}`:
      return "Edit Purchase Payments";
    case `${RouteUrls.procurementUrl}${RouteUrls.purchasePaymentListUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Purchase Payments";
    case `${RouteUrls.procurementUrl}${RouteUrls.indentsListUrl}`:
      return "Indent List";
    case `${RouteUrls.procurementUrl}${RouteUrls.indentsListUrl}${RouteUrls.createUrl}`:
      return "Add Indent";
    case `${RouteUrls.procurementUrl}${RouteUrls.purchaseOrdersUrl}${RouteUrls.overviewUrl}`:
      return "Purchase Order Over view";
    case `${RouteUrls.billingUrl}${RouteUrls.billingListUrl}${RouteUrls.createUrl}`:
      return "Add Patient Bill";
    case `${RouteUrls.pharmacyUrl}${RouteUrls.billDraftUrl}${RouteUrls.view}/${optionalParam}`:
      return "Pharmacy Bill Draft View";
    case `${RouteUrls.pharmacyUrl}${RouteUrls.billDraftUrl}${RouteUrls.createUrl}/${optionalParam}`:
      return "Pharmacy Bill Draft Create";
    case `${RouteUrls.billingUrl}${RouteUrls.billingListUrl}/${optionalParam}`:
      return "Edit Patient Bill";
    case `${RouteUrls.pharmacyUrl}${RouteUrls.salesBillListUrl}`:
      return "Sales Bills";
    case `${RouteUrls.pharmacyUrl}${RouteUrls.salesReturnUrl}`:
      return "Sales Return";
    case `${RouteUrls.pharmacyUrl}${RouteUrls.salesBillListUrl}${RouteUrls.createUrl}`:
      return "Add Sales bill";
    case `${RouteUrls.pharmacyUrl}${RouteUrls.salesBillListUrl}${RouteUrls.editUrl}/${optionalParam}`:
      return "Edit Sales bill";
    case `${RouteUrls.pharmacyUrl}${RouteUrls.salesBillListUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Sales bill";
    case `${RouteUrls.pharmacyUrl}${RouteUrls.salesReturnUrl}/${optionalParam}`:
      return "Sales Return Edit";
    case `${RouteUrls.pharmacyUrl}${RouteUrls.salesReturnUrl}${RouteUrls.view}/${optionalParam}`:
      return "Sales Return View";
    case `${RouteUrls.pharmacyUrl}${RouteUrls.billDraft}`:
      return "Pharmacy Draft List";
    case `${RouteUrls.procurementUrl}${RouteUrls.goodsIssueUrl}${RouteUrls.editUrl}/${optionalParam}`:
      return "Edit Goods Issue";
    case `${RouteUrls.procurementUrl}${RouteUrls.goodsIssueUrl}/approval_list${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Goods Issue";
    case `${RouteUrls.procurementUrl}${RouteUrls.goodsIssueUrl}/bill_list${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Goods Issue";
    case `${RouteUrls.procurementUrl}${RouteUrls.stockAdjustmentUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Stock Adjustment";
    case `${RouteUrls.procurementUrl}${RouteUrls.goodsIssueReturnUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "Goods Issue Return View";
    case `${RouteUrls.procurementUrl}${RouteUrls.goodsIssueReturnUrl}${RouteUrls.edit}/${optionalParam}`:
      return "Goods Issue Return Edit";
    case `${RouteUrls.procurementUrl}${RouteUrls.purchaseBillUrl}${RouteUrls.editUrl}/${optionalParam}`:
      return "Edit Purchase Bill";
    case `${RouteUrls.procurementUrl}${RouteUrls.purchaseBillUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Purchase Bill";
    case `${RouteUrls.procurementUrl}${RouteUrls.indentsListUrl}${RouteUrls.editUrl}/${optionalParam}`:
      return "Edit Indent";
    case `${RouteUrls.procurementUrl}${RouteUrls.indentsListUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Indent";
    case `${RouteUrls.procurementUrl}${RouteUrls.stockTransferUrl}`:
      return "Create Stock Transfer";
    case `${RouteUrls.procurementUrl}${RouteUrls.stockTransferUrl}/${optionalParam}`:
      return "Edit Stock Transfer";
    case `${RouteUrls.procurementUrl}${RouteUrls.stockTransferUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Stock Transfer";
    case `${RouteUrls.procurementUrl}${RouteUrls.purchaseBillUrl}/${optionalParam}`:
      return "Edit Bill";
    case `${RouteUrls.procurementUrl}${RouteUrls.wholeSalesUrl}`:
      return "B2B Bills";
    case `${RouteUrls.procurementUrl}${RouteUrls.wholeSalesUrl}${RouteUrls.createUrl}`:
      return "Add B2B";
    case `${RouteUrls.procurementUrl}${RouteUrls.wholeSalesUrl}${RouteUrls.editUrl}/${optionalParam}`:
      return "Edit B2B";
    case `${RouteUrls.procurementUrl}${RouteUrls.wholeSalesUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View B2B";
    case `${RouteUrls.procurementUrl}${RouteUrls.wholeSalesReturn}`:
      return "B2B Return";
    case `${RouteUrls.procurementUrl}${RouteUrls.wholeSalesReturn}${RouteUrls.editUrl}/${optionalParam}`:
      return "Edit B2B Return Bill";
    case `${RouteUrls.procurementUrl}${RouteUrls.wholeSalesReturn}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View B2B Return Bill";
    case `${RouteUrls.procurementUrl}${RouteUrls.wholeSalesPayments}`:
      return "B2B Payments List";
    case `${RouteUrls.procurementUrl}${RouteUrls.wholeSalesPayments}${RouteUrls.createUrl}`:
      return "B2B Payments Add";
    case `${RouteUrls.procurementUrl}${RouteUrls.wholeSalesPayments}${RouteUrls.editUrl}/${optionalParam}`:
      return "Edit B2B Payments";
    case `${RouteUrls.procurementUrl}${RouteUrls.wholeSalesPayments}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View B2B Payments";
    case `${RouteUrls.procurementUrl}${RouteUrls.adjustmentUrl}`:
      return "B2B Payment Adjustment";
    case `${RouteUrls.procurementUrl}${RouteUrls.purchaseReturnUrl}/${optionalParam}`:
      return "Edit Purchase Return";
    case `${RouteUrls.procurementUrl}${RouteUrls.purchaseReturnUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Purchase Return";
    case `${RouteUrls.inPatients}${RouteUrls.listUrl}`:
      return "IP List";
    case `${RouteUrls.inPatients}${RouteUrls.addUrl}`:
      return "Add IP Admission";
    case `${RouteUrls.inPatients}${RouteUrls.editUrl}/${optionalParam}`:
      return "Edit IP Admission";
    case `${RouteUrls.campaignUrl}${RouteUrls.listUrl}`:
      return "Campaigns List";
    case `${RouteUrls.campaignUrl}${RouteUrls.inhouseLeadsUrl}`:
      return "Inhouse Leads";
    case `${RouteUrls.campaignUrl}${RouteUrls.coldLeadsUrl}`:
      return "Cold Leads";
    case `${RouteUrls.inPatients}${RouteUrls.ipSummary}/${optionalParam}`:
      return "Ip Summary";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labMasterUrl}`:
      return "Lab Master";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labBillsUrl}`:
      return "Lab Bills";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labBillsUrl}${RouteUrls.createUrl}/${optionalParam}`:
      return "Add Lab Report";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labBillsUrl}/${optionalParam}`:
      return "Edit Lab Report";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labBillsUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Lab Report";
    case `${RouteUrls.procurementUrl}${RouteUrls.purchaseOrdersUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Purchase Order";
    case `${RouteUrls.gynecologyUrl}${RouteUrls.listUrl}`:
      return "Gynecology List";
    case `${RouteUrls.gynecologyUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "Patient Details";
    case `${RouteUrls.gynecologyUrl}${RouteUrls.addFollowUpUrl}/${optionalParam}`:
      return "Edit FollowUp";
    case `${RouteUrls.gynecologyUrl}${RouteUrls.addFollowUpUrl}`:
      return "Add FollowUp";
    case `${RouteUrls.patientUrl}${RouteUrls.listUrl}`:
      return "Patients List";
    case `${RouteUrls.patientUrl}${RouteUrls.historyUrl}/${optionalParam}`:
      return "Patient History";
    case RouteUrls.printUrl:
      return "Print";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labPurchaseUrl}`:
      return "Lab Purchase Bills";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labPurchaseUrl}${RouteUrls.createUrl}`:
      return "Add Lab Purchase Bill";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labPurchaseUrl}${RouteUrls.editUrl}/${optionalParam}`:
      return "Edit Lab Purchase Bill";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labPurchaseUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Lab Purchase Bill";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labIndentsListUrl}`:
      return "Lab Indents";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labIndentsListUrl}${RouteUrls.createUrl}`:
      return "Add Lab Indent";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labIndentsListUrl}${RouteUrls.editUrl}/${optionalParam}`:
      return "Edit Lab Indent";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labIndentsListUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Lab Indent";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labPurchaseOrderUrl}`:
      return "Lab Purchase Orders";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labPurchaseOrderUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Lab Purchase Order";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labPurchasePayments}`:
      return "Lab Purchase Payment List";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labPurchasePayments}${RouteUrls.createUrl}`:
      return "Add Lab Purchase Payment";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labPurchasePayments}${RouteUrls.editUrl}/${optionalParam}`:
      return "Edit Lab Purchase Payment";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labPurchasePayments}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Lab Purchase Payment";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labPurchaseReturnUrl}`:
      return "Lab Purchase Return";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labGoodsIssue}`:
      return "Lab Goods Issue Bills";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labGoodsIssue}${RouteUrls.createUrl}`:
      return "Add Lab Goods Issue";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labGoodsIssue}${RouteUrls.editUrl}/${optionalParam}`:
      return "Edit Lab Goods Issue Bill";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labGoodsIssue}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Lab Goods Issue Bill";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labReturnLabGoodsIssue}`:
      return "Lab Goods Issue Return";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labIssueStockAdjustments}`:
      return "Lab Stock Adjustment";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labIssueStockAdjustments}${RouteUrls.createUrl}`:
      return "Add Lab Stock Adjustment";
    case `${RouteUrls.laboratoryUrl}${RouteUrls.labIssueStockAdjustments}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View Lab Stock Adjustment";
    case `${RouteUrls.vaccinesUrl}${RouteUrls.listUrl}`:
      return "Vaccines List ";
    case `${RouteUrls.vaccinesUrl}${RouteUrls.masterUrl}`:
      return "Vaccine Master";
    case `${RouteUrls.billingUrl}${RouteUrls.billDraftUrl}`:
      return "Bill Draft List";
    case `${RouteUrls.billingUrl}${RouteUrls.billDraftUrl}${RouteUrls.view}/${optionalParam}`:
      return "Draft View";
    case `${RouteUrls.inPatients}${RouteUrls.operationTheatreUrl}${RouteUrls.addUrl}/${optionalParam}`:
      return "Add OT";
    case `${RouteUrls.inPatients}${RouteUrls.operationTheatreUrl}${RouteUrls.editUrl}/${optionalParam}`:
      return "Edit OT";
    case `${RouteUrls.inPatients}${RouteUrls.operationTheatreUrl}${RouteUrls.viewUrl}/${optionalParam}`:
      return "View OT";
    case `${RouteUrls.inPatients}${RouteUrls.operationTheatreUrl}${RouteUrls.otJobUrl}/${optionalParam}`:
      return "OT Job";
    default:
      return "Default Title";
  }
};

const calculateEpochTime = (date: { unix: number }) => {
  const dates = new Date(date.unix * 1000);
  return moment(dates).format("YYYY-MM-DD");
};
const statusColor: any = {
  1: {
    buttonText: "Confirmed",
    backgroundColor: "#CCFBF1",
    textColor: "#006B63",
  },
  2: {
    buttonText: "Canceled",
    backgroundColor: "#FDE6EB",
    textColor: "#AC054A",
  },
  3: {
    buttonText: "Rescheduled",
    backgroundColor: "#CCFBF1",
    textColor: "#006B63",
  },
};

const formatValue = (value: number, digits: number = 2) => {
  // Convert value to a number if it's not already
  value = Number(value);

  // Round the value to the specified number of digits after the dot
  let formattedValue = value.toFixed(digits);

  if (formattedValue.indexOf(".") === -1) {
    formattedValue += ".00";
  }

  return Number(formattedValue);
};
const ActiveStatusColor: any = {
  1: {
    buttonText: "Active",
    backgroundColor: "#CCFBF1",
    textColor: "#006B63",
  },
  0: {
    buttonText: "InActive",
    backgroundColor: "#FDE6EB",
    textColor: "#AC054A",
  },
  3: {
    buttonText: "Rescheduled",
    backgroundColor: "#CCFBF1",
    textColor: "#006B63",
  },
};
const activeInActiveColor: any = {
  1: {
    buttonText: "Active",
    backgroundColor: "#CCFBF1",
    textColor: "#006B63",
  },
  0: {
    buttonText: "InActive",
    backgroundColor: "#FDE6EB",
    textColor: "#AC054A",
  },
};

const handleFilterCounts = (
  newData: object,
  name: any,
  filterDataTrack: any,
  filterCount: any
) => {
  const data = Object.entries(newData);
  data.forEach((element) => {
    element.forEach((nextElement) => {
      if (nextElement === name) {
        filterDataTrack((prev: any) => {
          const filt = { ...prev, [name]: true };
          let appliedfilt = Object.values(filt).filter(Boolean).length;
          filterCount(appliedfilt);

          return filt;
        });
      }
    });
  });
};
const setLocalStorageDetails = (name: string, details: any) => {
  localStorage.setItem(name, details);
};
const getLocalStorageDetails = (name: string) => {
  localStorage.getItem(name as string);
};
const clearLocalStorageDetailsByName = (name: string) => {
  localStorage.removeItem(name as string);
};
const crypt = (salt: string, text: string) => {
  const textToChars = (text: string) =>
    text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n: number) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code: number) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    .split("")
    .map(textToChars)
    .flat()
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
};

const decrypt = (salt: string, encoded: any) => {
  const textToChars = (text: string) =>
    text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code: number) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex: any) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode: any) => String.fromCharCode(charCode))
    .join("");
};
const getToken = (): {
  access_token: string | null;
  token_type: string;
  refresh_token: string | null;
} => {
  const localStorageAccessToken: any = getCookie("accessToken");
  const localStorageRefreshToken: any = getCookie("refreshToken");
  const accessToken = localStorageAccessToken ?? null;
  const refreshToken = localStorageRefreshToken ?? null;
  if (accessToken != null && refreshToken != null) {
    return {
      access_token: JSON.parse(accessToken),
      token_type: "Bearer",
      refresh_token: JSON.parse(refreshToken),
    };
  } else {
    return {
      access_token: null,
      token_type: "Bearer",
      refresh_token: null,
    };
  }
};

const printOpenWithHeader = async (urlLink: string) => {
  const credentials = getToken();
  try {
    const response = await fetch(urlLink, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${credentials?.access_token}`,
      },
    });

    if (response) {
      const blob = await response.blob();
      const url: any = URL.createObjectURL(blob);
      window.open(url);
    } else {
      console.error("Failed to fetch PDF:");
    }
  } catch (error) {
    console.log("err", error);
  }
};

const getPrintUrl = (url: any) => {
  const BASE_URL = process.env.REACT_APP_DR_CARROT_BASE_URL;
  const API_PATH = "/api";
  const v1 = "/v1";
  const BASE_PATH = `${BASE_URL}${API_PATH}`;
  const newUrl = `${BASE_PATH}${v1}/${url}`;
  return newUrl;
};

export {
  crypt,
  decrypt,
  printOpenWithHeader,
  setLocalStorageDetails,
  getLocalStorageDetails,
  clearLocalStorageDetailsByName,
  activeInActiveColor,
  setCookie,
  getCookie,
  removeCookie,
  getAppBarTitleFromPath,
  calculateEpochTime,
  statusColor,
  formatValue,
  ActiveStatusColor,
  handleFilterCounts,
  getPrintUrl,
};
