import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  debounce,
} from "@mui/material";
import {
  Button,
  SaveButton,
  CancelButton,
  PageLoader,
} from "../../../../components/basic";
import { RootState } from "../../../../redux/store";
import Info from "./Info";
import ProductRow from "./ProductRow";
import { indentsConst } from "../../../../constants/displayText";
import {
  createIndentsById,
  deleteIndentById,
  getAllProcurementProducts,
  getIndentById,
  updateIndentsById,
} from "../../../../services/procurementService";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../../redux/slices/snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { RouteUrls } from "../../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationDialog } from "../../../../components/shared";
import { SequenceWrapper } from "../../../../components/shared";
import { updatedSequence } from "../../../../services/procurementMasterService";
import { getDepartmentSequence } from "../../../../services/mainCoreService";
import moment from "moment";

const { procurementUrl, indentsListUrl } = RouteUrls;

type TableHeaderCell = {
  TableCellName: string;
  alignment: "left" | "right" | "center";
  sx?: {};
};

type InfoType = {
  indent_no: number | null;
  department: number | null;
  indent_no_modified: number | null;
  department_name?: string;
  created_at?: string;
};

const BillProducts = React.memo(() => {
  const { productName, requirement, currentStock, reOrderQuantity } =
    indentsConst;
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: {
          xs: "92vw",
        },
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "90vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "44px !important",
          maxHeight: "44px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },
        "& .MuiTableCell-root": {
          minHeight: "0px",
        },
        "& .MuiTableCell-head": {
          height: "44px",
          maxHeight: "44px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "var(--table-header-font-size)",
          fontWeight: "var(--table-header-font-weight)",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body": {
          height: "44px !important",
          maxHeight: "44px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "12px",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "44px",
        },
        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "44px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },

      textFieldStyle: {
        height: "44px",
        width: "auto",
        fontSize: "6px",
        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "44px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "var(--primary-input-text-font-size)",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "44px",
          maxHeight: "44px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },
      },

      selectStyle: {
        "& .MuiFormControl-root": { width: "100%" },

        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
          borderWidth: 0,
          height: "44px",
          maxHeight: "44px",
          p: 0,
        },

        "& .MuiInputBase-root": {
          padding: "0px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },

        "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "primary.main",
            borderWidth: "1px",
          },

        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: 0,
        },
      },
    }),
    []
  );

  const TableHeaderCells: TableHeaderCell[] = [
    { TableCellName: productName, alignment: "center" },
    {
      TableCellName: currentStock,
      alignment: "center",
    },
    {
      TableCellName: reOrderQuantity,
      alignment: "center",
    },
    {
      TableCellName: requirement,
      alignment: "center",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];

  const initialBillProduct = {
    id: null,
    product_id: null,
    product_name: null,
    product_name_input_value: "",
    requirement: "",
    re_order_qty: "",
  };

  const [billProducts, setBillProducts] = useState<any>([initialBillProduct]);
  const [allProducts, setAllProducts] = useState<any>([]);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [infoError, setInfoError] = useState<any>({});
  const [productErrors, setProductErrors] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<{
    isOpen: boolean;
    id: number | string | null;
    index: number | null;
  }>({ isOpen: false, id: null, index: null });
  const [isDeleteButtonLoading, setIsDeleteButtonLoading] =
    useState<boolean>(false);
  const [excludeIds, setExcludeIds] = useState<any>([""]);

  const initialInfo = {
    indent_no: null,
    department: null,
    indent_no_modified: 0,
    department_name: "",
  };
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );

  const [info, setInfo] = useState<any>(initialInfo);

  const [indentChange, setIndentChange] = useState<any>({
    isCreateIndent: true,
    isSequenceNumberChanged: false,
  });
  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);
  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });
  const [isManualAdded, setIsManualAdded] = useState(false);
  const [sequenceNo, setSequenceNo] = useState("");
  const [sequenceDialogBox, setSequenceDialogBox] = useState(false);
  const [autoGeneratedSeqNo, setAutoGeneratedSeqNo] = useState("");
  const [seqIsOnce, setSeqIsOne] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });
  const [customSequence, setCustomSequence] = useState(true);

  const [sequenceId, setSequenceId] = useState(null);

  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");

  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };

  let isCreatePrescription = true;
  let isSequenceNumberChanged = false;
  const handleSequence = () => {
    getDepartmentSequence("indent")
      .then((res: any) => {
        let data = res?.data[0]?.sequence_no;

        if (isCreatePrescription && !isSequenceNumberChanged) {
          setInitialSequenceNumber(data);
          setInfo((prevState: any) => ({
            ...prevState,
            indent_no: res?.data[0]?.sequence_no,
          }));

          setInfoError((prevState: any) => ({
            ...prevState,
            indent_no: "",
          }));
        }

        if (isSequenceNumberChanged) {
          setInfo((prevState: any) => ({
            ...prevState,
            indent_no: res?.data[0]?.sequence_no,
          }));
          setInfoError((prevState: any) => ({
            ...prevState,
            indent_no: "",
          }));
        }
        if (res?.data?.length > 0) {
          setAutoGeneratedSeqNo(res?.data[0]?.sequence_no);
          setSequenceNo(res.data[0].sequence.id);
          const newSequenceData = res.data[0].sequence.sequence_preferences.map(
            (ele: {
              id: number;
              type: string;
              value: string;
              separator: string;
            }) => ({
              id: ele.id,
              type: ele.type,
              value: ele.value,
              separator: ele.separator,
            })
          );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual: res.data[0].sequence.is_manual === 1 ? true : false,
            is_auto_generate:
              res.data[0].sequence.is_manual !== 1 ? true : false,
          }));
          setSeqIsOne((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(res.data[0].sequence.is_manual === 1));
        }
      })
      .catch((err) => console.log(err));
  };
  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value === "Select" ? "" : value,
      };
      return newState;
    });
  };
  function onCloseSequenceDialogBox() {
    setSequenceDialogBox(false);
  }

  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      const data = {
        seq_preference: [...sequenceData],
        is_manual: isManual.is_manual ? 1 : 0,
      };
      await updatedSequence(Number(sequenceNo), data)
        .then((res) => {
          if (res) {
            isSequenceNumberChanged = true;
            handleSequence();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(setSnackBarFailed({ snackBarMessage: err.message }));
        });
    }
  };

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const { name, value } = e.target;

      setBillProducts((prev: any) =>
        prev.map((billProduct: any, i: number) => {
          return index === i ? { ...billProduct, [name]: value } : billProduct;
        })
      );

      setInfoError((prev: any) => ({
        ...prev,
        [name]: value ? "" : prev[name],
      }));
      setProductErrors((prev: any) => {
        const newProductsErrors = [...prev];

        if (newProductsErrors[index]) {
          newProductsErrors[index][name] = value ? "" : prev[index][name];
        }
        return newProductsErrors;
      });
    },
    []
  );

  const handleAutoCompleteChange = useCallback(
    (e: any, newValue: any, index: Number, name: string) => {
      if (newValue === null) {
        return false;
      }

      setBillProducts((prev: any) =>
        prev.map((row: any, i: number) => {
          return i === index
            ? {
                ...row,
                [name]: newValue,
                product_id:
                  name === "product_name" ? newValue?.id : row.product_id,
                re_order_qty: newValue?.re_order_qty,
                current_stock: newValue?.current_stock || 0,
              }
            : row;
        })
      );

      if (name === "product_name") {
        setExcludeIds((prevState: any) =>
          prevState?.map((ele: any, i: number) => {
            if (i === index) {
              return newValue?.id ? newValue?.id : "";
            }
            return ele;
          })
        );
      }

      if (newValue) {
        setProductErrors((prev: any) => {
          const newState = [...prev];
          if (newState[index as number]) {
            newState[index as number][
              name === "product_name" ? "product_id" : name
            ] = newValue ? "" : prev[name];
          }
          return newState;
        });
      }
    },
    []
  );

  const handleAutoCompleteInputChange = useCallback(
    (e: any, newInputValue: any, index: Number, name: string) => {
      if (newInputValue === null) {
        return false;
      }

      if (name === "product_name_input_value") {
        const data = {
          department: info.department,
          search: newInputValue,
          page: 1,
          limit: 20,
        };
      }

      setBillProducts((prev: any) =>
        prev.map((row: any, i: number) => {
          return i === index ? { ...row, [name]: newInputValue } : row;
        })
      );
    },
    []
  );

  const handleDelete = useCallback((id: number | string, index: number) => {
    setIsOpenDeleteModal({ isOpen: true, id, index });
  }, []);

  const handleClick = useCallback(() => {
    setBillProducts((prev: any) => [
      ...prev,
      {
        ...initialBillProduct,
      },
    ]);
    setProductErrors((prev: any) => [...prev, {}]);
    setExcludeIds((prevState: any) => [...prevState, ""]);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    if (isOpenDeleteModal.id) {
      setIsDeleteButtonLoading(true);
      deleteIndentById(isOpenDeleteModal.id)
        .then((res: any) => {
          setIsDeleteButtonLoading(false);
          setBillProducts((prev: any) =>
            prev?.filter(
              (billProduct: any) => billProduct.id !== isOpenDeleteModal.id
            )
          );
          setProductErrors((prevState: any) =>
            prevState?.filter(
              (ele: any, index: number) => index !== isOpenDeleteModal.index
            )
          );
          setExcludeIds((prevState: any) =>
            prevState?.filter(
              (ele: any, index: number) => index !== isOpenDeleteModal.index
            )
          );
          setIsOpenDeleteModal({ isOpen: false, id: null, index: null });
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        })
        .catch((err) => {
          console.log(err);
          setIsDeleteButtonLoading(false);
        });
    } else {
      setBillProducts((prev: any) =>
        prev?.filter(
          (billProduct: any, index: number) => index !== isOpenDeleteModal.index
        )
      );
      setProductErrors((prevState: any) =>
        prevState?.filter(
          (ele: any, index: number) => index !== isOpenDeleteModal.index
        )
      );
      setExcludeIds((prevState: any) =>
        prevState?.filter(
          (ele: any, index: number) => index !== isOpenDeleteModal.index
        )
      );
      setIsOpenDeleteModal({ isOpen: false, id: null, index: null });
    }
  }, [isOpenDeleteModal]);

  const getAllProcurementProductsData = async (data: any) => {
    const res: any = await getAllProcurementProducts(data);
    if (res.data) {
      // setAllProducts(res.data);
    }
  };

  const debouncedGetAllProcurementProductsData = useCallback(
    debounce((data) => {
      getAllProcurementProductsData(data);
    }, 500),
    []
  );

  const handleValidation = () => {
    const requireInfoFields = ["indent_no", "department"];

    const errors: { [key: string]: string } = {};
    requireInfoFields.forEach((key: string) => {
      if (!info[key]) {
        errors[key] = "required field";
      }
    });

    const requiredProductField = ["requirement", "product_id"];

    const productsErrors: { [key: string]: string }[] = [];

    billProducts.forEach((ele: any) => {
      const newError: { [key: string]: string } = {};

      requiredProductField.forEach((key) => {
        if (!ele[key]) {
          newError[key] = "required field";
        }
      });

      productsErrors.push(newError);
    });

    let productsHasError = false;

    if (productsErrors?.length > 0) {
      productsErrors?.forEach((item: any) => {
        if (Object.keys(item).length > 0) {
          productsHasError = true;
        }
      });
    }

    if (Object.keys(errors)?.length > 0) {
      setInfoError(errors);
      return false;
    }

    if (productsHasError) {
      setProductErrors(productsErrors);
      return false;
    }

    return true;
  };

  const handleConfirmSequenceNoChange = () => {
    if (initialSequenceNumber !== info.indent_no) {
      setSeqIsOne((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: info.indent_no,
      }));
      setSequenceDialogBox(true);
    }
  };

  const handleResetBillProducts = () => {
    setBillProducts([
      {
        id: null,
        product_id: null,
        product_name: null,
        product_name_input_value: "",
        requirement: "",
        re_order_qty: "",
      },
    ]);
  };

  const handleSave = () => {
    if (handleValidation()) {
      setIsSaveLoading(true);
      if (id) {
        updateIndentsById(id, {
          dept_id: info.department,
          indent_no: info.indent_no,
          indent_no_modified:
            Number(info.indent_no) === Number(initialSequenceNumber) ? 0 : 1,
          product: billProducts,
        })
          .then((res: any) => {
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: res.message,
              })
            );
            setIsSaveLoading(false);
            navigate(`${procurementUrl}${indentsListUrl}`);
          })
          .catch((err) => {
            console.log(err);
            setIsSaveLoading(false);
          });
      } else {
        createIndentsById({
          dept_id: info.department,
          indent_no: info.indent_no,
          indent_no_modified: info.indent_no === initialSequenceNumber ? 0 : 1,
          product: billProducts,
        })
          .then((res: any) => {
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: res.message,
              })
            );
            setIsSaveLoading(false);
            navigate(`${procurementUrl}${indentsListUrl}`);
          })
          .catch((err) => {
            console.log(err);
            setIsSaveLoading(false);
          });
      }
    }
  };
  useEffect(() => {
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }
  }, [appConfiguration]);

  // useEffect(() => {
  //   if (info.department) {
  //     const data = {
  //       department: info.department,
  //       page: 1,
  //       limit: 20,
  //     };
  //     getAllProcurementProductsData(data);
  //   }
  // }, [info.department]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getIndentById(id)
        .then((res: any) => {
          if (res.data) {
            const { bill } = res.data;
            setInfo({
              indent_no: bill?.indent_no,
              department:
                bill?.indent_products?.length > 0
                  ? bill?.indent_products[0]?.dept_id
                  : null,
              department_name:
                bill?.indent_products?.length > 0
                  ? bill?.indent_products[0]?.department?.dept_name
                  : "",
              indent_no_modified: 0,
              created_at: bill?.created_at
                ? moment(bill.created_at).format("DD/MM/YYYY hh:mm:ss")
                : "",
            });
            const productList: any = [];
            setBillProducts(() =>
              bill?.indent_products?.map(
                (ele: {
                  id: number;
                  product: any;
                  product_id: number;
                  requirement: any;
                }) => {
                  productList.push(ele.product);
                  return {
                    id: ele.id,
                    product_id: ele.product_id,
                    product_name: ele.product,
                    product_name_input_value: ele?.product?.name,
                    requirement: ele.requirement,
                  };
                }
              )
            );
            setInitialSequenceNumber(bill?.indent_no);

            setAllProducts(productList);
            setIsLoading(false);

            isCreatePrescription = false;
            isSequenceNumberChanged = false;
            setProductErrors(bill?.indent_products?.map(() => ({})));
            setExcludeIds(
              bill?.indent_products?.map((ele: any) => ele.product_id)
            );
          }
          handleSequence();
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    } else {
      isCreatePrescription = true;
      isSequenceNumberChanged = false;
      handleSequence();
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Grid sx={{ width: "92vw" }}>
          <Info
            info={info}
            setInfo={setInfo}
            resetBillProducts={handleResetBillProducts}
            errors={infoError}
            isEdit={!!id}
            setInfoError={setInfoError}
            indentChange={indentChange}
            isManual={isManual}
            isManualAdded={isManualAdded}
            setIsManual={setIsManual}
            handleConfirmSequenceNoChange={handleConfirmSequenceNoChange}
            customSequence={customSequence}
            sequenceDialogBox={sequenceDialogBox}
            setSequenceDialogBox={setSequenceDialogBox}
          />
          <Grid
            sx={{
              width: {
                xs: "92vw",
              },
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              overflowX: { md: "scroll", lg: "hidden" },
            }}
          >
            <TableContainer
              className="table-container"
              component={Paper}
              sx={{
                ...styles.tableContainerStyle,
              }}
            >
              <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {TableHeaderCells.map((cell, index) => {
                      return (
                        <TableCell
                          align={cell.alignment}
                          sx={cell.sx}
                          key={index}
                        >
                          {cell.TableCellName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    height: "auto",
                    width: {
                      xs: "400vw",
                      sm: "200vw",
                      md: "120vw",
                      lg: "90vw",
                    },
                  }}
                >
                  {billProducts?.map((row: any, index: number) => {
                    return (
                      <ProductRow
                        key={row.id}
                        index={index}
                        row={row}
                        error={productErrors[index] || {}}
                        styles={styles}
                        handleInputChange={handleInputChange}
                        handleAutoCompleteChange={handleAutoCompleteChange}
                        handleAutoCompleteInputChange={
                          handleAutoCompleteInputChange
                        }
                        handleDelete={handleDelete}
                        products={allProducts}
                        isShowDeleteIcon={
                          billProducts?.length === 1 &&
                          row?.issued_qty === 0 &&
                          row.status === 1
                        }
                        isEdit={!!id}
                        infoData={info}
                        excludeIds={excludeIds}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            sx={{
              width: {
                xs: "400vw",
                sm: "200vw",
                md: "120vw",
                lg: "90vw",
              },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: {
                  xs: "78%",
                },
              }}
            >
              <Button
                variant="text"
                buttonText="+ Add Product"
                handleClick={handleClick}
                sx={{
                  borderRadius: "0px",
                  width: "auto",
                  height: "auto",
                  fontWeight: 400,
                  fontSize: "14px",
                  border: 0,
                  color: "primary.main",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "primary.main",
                    borderColor: "initial",
                  },
                  "&.Mui-focusVisible": {
                    border: 1,
                    borderColor: "primary.main",
                    borderRadius: "5px",
                  },
                }}
              />
              {/* <Grid
                sx={{
                  width: "23%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  sx={{
                    width: "60%",
                  }}
                >
                  <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                    Total Value :
                  </Typography>
                </Grid>
                <Typography
                  sx={{ fontSize: "13px", fontWeight: "600", mr: "1%" }}
                >
                  {}
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              m: "35px 0 45px 0",
            }}
          >
            <SaveButton
              loading={isSaveLoading}
              handleClick={handleSave}
              sx={{ width: "100px", height: "40px", mr: "20px" }}
            />
            <CancelButton sx={{ width: "100px", height: "40px" }} />
          </Grid>
          <ConfirmationDialog
            open={isOpenDeleteModal.isOpen}
            title="are you surely want to delete"
            handleClick={handleConfirmDelete}
            onClose={() =>
              setIsOpenDeleteModal({ isOpen: false, id: null, index: null })
            }
            loading={isDeleteButtonLoading}
          />
          <SequenceWrapper
            open={sequenceDialogBox}
            title="Indent"
            onClose={onCloseSequenceDialogBox}
            handleChange={handleSequenceOnChange}
            sequenceValue={sequenceData}
            isOnce={seqIsOnce}
            handleClick={handleSequenceSubmit}
            handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
            isManual={isManual}
          />
        </Grid>
      )}
    </>
  );
});

export default BillProducts;
