import { useEffect, useState, useMemo, useCallback } from "react";
import { Box, Grid, Typography, Button, CircularProgress } from "@mui/material";
import { DialogWrapper } from "../../../components/shared";
import {
  CancelButton,
  SaveButton,
  TextField,
  DatePickerWithTime,
  AutoComplete,
} from "../../../components/basic";
import {
  getAllRooms,
  createIpRoomTransferById,
  updateIpRoomTransferById,
  getIpAdmissionRoomTransferHistoryDetail,
} from "../../../services/admissionService";
import { KebabMenuIcon } from "../../../assets/icons";
import WardRoomModal from "../View/WardRoomModal";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../utils/ValidationUtils";
import moment from "moment";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../redux/slices/snackbar";
import { ipAdmissionConst } from "../../../constants/displayText";
import { useDispatch, useSelector } from "react-redux";
import { setRoomTransferHistoryList } from "../../../redux/slices/inpatients";
import { RootState } from "../../../redux/store";

type RoomTransferModalProps = {
  isOpen: boolean;
  onClose: () => void;
  ipAdmissionDetails?: any;
  setWard?: any;
};

const CommonGrid = ({ name, value, isIncludeMarginBottom = true }: any) => {
  return (
    <Grid
      sx={{
        width: {
          xs: "100%",
          md: "70%",
          lg: "70%",
        },
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        color: "textPrimary.main",
        mb: isIncludeMarginBottom ? "23px" : "0px",
      }}
      className="row"
    >
      <Grid
        sx={{
          width: "30%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>
          {name}
        </Typography>
        <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>:</Typography>
      </Grid>
      <Grid
        sx={{
          width: "70%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          pl: 3,
        }}
      >
        <Typography variant="h6" sx={{ color: "greyScale.main" }}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const RoomTransferModal = (props: RoomTransferModalProps) => {
  const styles = useMemo(
    () => ({
      textFieldStyle: {
        width: {
          xs: "190px",
          xl: "265px",
        },
        "& .MuiOutlinedInput-root": {
          height: "45px",
          borderRadius: "5px",
          paddingLeft: "10px",
        },
      },
      autocompleteStyle: {
        width: {
          xs: "275px",
        },
        height: "45px",
        "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
          padding: "5px 0px",
          boxShadow: "none",
          borderRadius: "5px",
        },
      },
      datePickerWithTimeStyle: {
        width: "245px",
        height: "35px",
        borderRadius: "5px",
        boxShadow: "none",
      },
      labelStyle: {
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "20px",
        color: "#8A8A8A",
      },
      valueStyle: {
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "12px",
        color: "#232323",
      },
      datePickerGridStyle: {
        "& .MuiGrid-root": {
          height: "34px",
          width: "100%",
        },
        "& .MuiFormControl-root": {
          height: "34px",
          width: "100%",
          position: "static",
        },
        "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container ": {
          height: "34px",
          width: "100%",
          border: 0,
          p: "0px",
        },

        "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container  >div >div .rmdp-input":
          {
            height: "20px",
            width: "95%",
            p: "0px 10px",
            border: 0,
            m: 0,
            borderWidth: "0px",
            borderRadius: 0,
          },

        "& .rmdp-wrapper": {
          position: "initial",
        },
      },
    }),
    []
  );
  const { ADDED_SUCCESSFULLY, UPDATED_SUCCESSFULLY, DELETED_SUCCESSFULLY } =
    ipAdmissionConst;

  const dispatch = useDispatch();

  const { roomTransferHistoryList } = useSelector(
    (state: RootState) => state.inPatients
  );

  const { isOpen, onClose, ipAdmissionDetails, setWard } = props;

  // model and loader states

  const [isWardRoomModelOpen, setIsWardRoomModelOpen] = useState(false);
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
  const [isUpdateButtonLoading, setIsUpdateButtonLoading] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);

  // field states

  const initialRoomTransferHistory = {
    ip_admissions: {
      id: ipAdmissionDetails?.id,
      ip_no: ipAdmissionDetails?.ip_no,
      uhid: ipAdmissionDetails?.patient_details?.uhid,
      name: ipAdmissionDetails?.patient_details?.name,
      mobile: ipAdmissionDetails?.patient_details?.mobile,
      doctor_name: ipAdmissionDetails?.doctor_name,
      floor_name: ipAdmissionDetails?.floor_name,
    },
  };

  const [roomTransferHistory, setRoomTransferHistory] = useState<any>(
    initialRoomTransferHistory
  );
  const [roomOptions, setRoomOptions] = useState<any>([]);
  const [id, setId] = useState<any>(ipAdmissionDetails?.id);

  //  ward room model - prop functions

  const handleSelectRoom = (value: any) => {
    setFormData((prev: any) => ({
      ...prev,
      room: `${value.selectedWard} - ${value.room_no}`,
      roomId: value.id,
    }));

    setIsWardRoomModelOpen(false);

    handleValidation({
      target: {
        name: "room",
        value: value.room_no,
      },
    });
  };

  // input field functions

  const handleDateChange = (newValue: any, name: string) => {
    if (newValue === null) {
      return false;
    }

    setFormData((prev: any) => ({
      ...prev,
      [name]: newValue.toString(),
    }));

    handleValidation({
      target: {
        name: name,
        value: newValue.toString(),
      },
    });
  };

  const handleRoomHistoryDateChange = (
    newValue: any,
    name: string,
    index: number
  ) => {
    if (newValue === null) {
      return false;
    }

    const newArray = [...roomTransferHistory.room_transfer_history];

    newArray[index][name] = newValue.toString();

    setRoomTransferHistory((prev: any) => ({
      ...prev,
      room_transfer_history: newArray,
    }));

    handleValidation({
      target: {
        name: name,
        value: newValue.toString(),
      },
    });
  };

  const handleAutoCompleteChange = (
    e: any,
    newValue: any,
    name: string,
    index: number
  ) => {
    if (newValue === null) {
      return false;
    }
    const newArray = [...roomTransferHistory.room_transfer_history];

    newArray[index][name] = newValue.id;

    setRoomTransferHistory((prev: any) => ({
      ...prev,
      room_transfer_history: newArray,
    }));
  };

  const handleAutoCompleteInputChange = (
    e: any,
    newInputValue: any,
    name: string,
    index: number
  ) => {
    if (newInputValue === "") {
      return false;
    }

    const newArray = [...roomTransferHistory.room_transfer_history];

    newArray[index][name] = newInputValue;

    setRoomTransferHistory((prev: any) => ({
      ...prev,
      room_transfer_history: newArray,
    }));
  };

  const handleEditButtonChange = (
    name: string,
    value: boolean,
    index: number
  ) => {
    const newArray = [...roomTransferHistory.room_transfer_history];

    newArray[index][name] = value;

    setRoomTransferHistory((prev: any) => ({
      ...prev,
      room_transfer_history: newArray,
    }));
  };

  // input fields states with validations

  const initialFormError = {
    checkOutDate: "",
    room: "",
    checkInDate: "",
  };

  const [formError, setFormError] = useState<any>(initialFormError);

  const initialFormData = {
    checkOutDate: new Date(),
    room: null,
    roomId: null,
    checkInDate: new Date(),
  };

  const [formData, setFormData] = useState<any>(initialFormData);

  const fieldData: any = {
    checkOutDate: {
      label: "CheckOut Date",
      name: "checkOutDate",
      placeholder: "Choose CheckOut Date",
      value: formData.checkOutDate,
      inputValue: formData?.checkOutDate,
      isError: formError.checkOutDate === "" ? false : true,
      helperText: formError.checkOutDate,
    },
    room: {
      label: "New Room No",
      name: "room",
      placeholder: "Select Room No",
      value: formData.room,
      inputValue: formData?.room,
      isError: formError.room === "" ? false : true,
      helperText: formError.room,
    },
    checkInDate: {
      label: "CheckIn Date",
      name: "checkInDate",
      placeholder: "Select CheckIn Date",
      value: formData.checkInDate,
      inputValue: formData?.checkInDate,
      isError: formError.checkInDate === "" ? false : true,
      helperText: formError.checkInDate,
    },
  };

  const [formFieldData, setFormFieldData] = useState(fieldData);

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "checkOutDate":
      case "room":
      case "checkInDate": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateFormFieldData = () => {
    setFormFieldData((prev: any) => {
      return Object.keys(prev).map((field: any) => {
        return {
          ...field,
          value: formData[field.name],
          helperText: formError[field.name],
          isError: formError[field.name] === "" ? false : true,
        };
      });
    });
  };

  useEffect(() => {
    updateFormFieldData();
  }, [formError, formData]);

  // api function calls to get select options

  const getInPatientAdmissionRoomTransferHistoryDetail = async () => {
    if (!id) {
      return false;
    }
    try {
      setIsDialogLoading(true);
      await getIpAdmissionRoomTransferHistoryDetail(id).then((result: any) => {
        let data = result?.data;

        const newArray = data?.room_transfer_history?.map((item: any) => {
          return {
            ...item,
            name: `${item?.tname} / ${item?.room_no}`,
            isEditHistory: false,
          };
        });

        setRoomTransferHistory((prev: any) => ({
          ...prev,
          room_transfer_history: newArray,
        }));

        const modifiedData = data?.room_transfer_history?.map(
          (item: any, index: number) => ({
            ...item,
            sNo: index + 1,
          })
        );

        dispatch(setRoomTransferHistoryList(modifiedData));
        setIsDialogLoading(false);
      });
    } catch (error) {
      setIsDialogLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const getAllRoomsList = async () => {
    try {
      await getAllRooms().then((result: any) => {
        let data = result?.data?.result;

        const modifiedData = data?.map((item: any) => {
          return {
            id: item?.id,
            name: `${item?.floor_name} / ${item?.room_no}`,
          };
        });

        setRoomOptions(modifiedData);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // crud api functions

  const handleSave = async () => {
    if (
      formData?.checkOutDate === null ||
      formData?.room === null ||
      formData?.checkInDate === null
    ) {
      validateForm();
    } else {
      try {
        setIsSaveButtonLoading(true);
        const id = roomTransferHistory?.ip_admissions?.id;
        const data = {
          room_id: formData?.roomId,
          doa: formData?.checkInDate
            ? moment(formData?.checkInDate, [
                "DD/MM/YYYY hh:mm:ss A",
                "YYYY-MM-DD HH:mm:ss A",
              ]).format("YYYY-MM-DD HH:mm:ss")
            : null,
          dod: formData?.checkOutDate
            ? moment(formData?.checkOutDate, [
                "DD/MM/YYYY hh:mm:ss A",
                "YYYY-MM-DD HH:mm:ss A",
              ]).format("YYYY-MM-DD HH:mm:ss")
            : null,
        };

        await createIpRoomTransferById(id, data).then((result: any) => {
          let data = result?.data;
          setWard(formData?.room);
          // onClose();
          getInPatientAdmissionRoomTransferHistoryDetail();
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: ADDED_SUCCESSFULLY,
            })
          );
          setIsSaveButtonLoading(false);
        });
      } catch (error: any) {
        setIsSaveButtonLoading(false);
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
      }
    }
  };

  const updateHistory = async (data: any) => {
    try {
      setIsUpdateButtonLoading(true);
      const id = data?.id;
      const payload = {
        room_id: data?.new_bed_id,
        ip_admission_id: roomTransferHistory?.ip_admissions?.id,
        doa: data?.doa
          ? moment(data?.doa, [
              "DD/MM/YYYY hh:mm:ss A",
              "YYYY-MM-DD HH:mm:ss A",
            ]).format("YYYY-MM-DD HH:mm:ss")
          : null,
        dod: data?.dod
          ? moment(data?.dod, [
              "DD/MM/YYYY hh:mm:ss A",
              "YYYY-MM-DD HH:mm:ss A",
            ]).format("YYYY-MM-DD HH:mm:ss")
          : null,
      };

      await updateIpRoomTransferById(id, payload).then((result: any) => {
        dispatch(
          setSnackBarSuccess({
            snackBarMessage: UPDATED_SUCCESSFULLY,
          })
        );
        setIsDialogLoading(true);
        setIsUpdateButtonLoading(false);
        getInPatientAdmissionRoomTransferHistoryDetail();
      });
    } catch (error: any) {
      setIsUpdateButtonLoading(false);
      console.error("An error occurred:", error);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.message,
        })
      );
    }
  };

  useEffect(() => {
    if (!roomTransferHistoryList) {
      getInPatientAdmissionRoomTransferHistoryDetail();
    } else {
      const newArray = roomTransferHistoryList?.map((item: any) => {
        return {
          ...item,
          name: `${item?.tname} / ${item?.room_no}`,
          isEditHistory: false,
        };
      });

      setRoomTransferHistory((prev: any) => ({
        ...prev,
        room_transfer_history: newArray,
      }));
    }
    getAllRoomsList();
  }, []);

  return (
    <DialogWrapper
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="md"
      title="Room Transfer"
    >
      {isDialogLoading ? (
        <Grid
          sx={{
            width: "100%",
            margin: "auto",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            height: "50vh",
            background: "transparent",
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid>
          <Grid>
            <CommonGrid
              name="IP No"
              value={roomTransferHistory?.ip_admissions?.ip_no}
            />
            <CommonGrid
              name="Patient UHID"
              value={roomTransferHistory?.ip_admissions?.uhid}
            />
            <CommonGrid
              name="Patient Name"
              value={roomTransferHistory?.ip_admissions?.name}
            />
            <CommonGrid
              name="Patient Mobile"
              value={roomTransferHistory?.ip_admissions?.mobile}
            />
            <CommonGrid
              name="Doctor Name"
              value={roomTransferHistory?.ip_admissions?.doctor_name}
            />
            <CommonGrid
              name="Current Ward/Room No"
              value={roomTransferHistory?.ip_admissions?.floor_name}
            />
            <CommonGrid
              name={fieldData.checkOutDate.label}
              value={
                <DatePickerWithTime
                  value={fieldData.checkOutDate.value}
                  placeholder={fieldData.checkOutDate.placeholder}
                  name={fieldData.checkOutDate.name}
                  onChange={(newValue: any) =>
                    handleDateChange(newValue, "checkOutDate")
                  }
                  maxDate={formData.checkInDate}
                  format="DD/MM/YYYY hh:mm:ss A"
                  error={fieldData.checkOutDate.isError}
                  helperText={fieldData.checkOutDate.helperText}
                  style={{ ...styles.datePickerWithTimeStyle }}
                />
              }
              isIncludeMarginBottom={false}
            />
            <CommonGrid
              name={fieldData?.room?.label}
              value={
                <TextField
                  value={fieldData?.room?.value}
                  name={fieldData?.room?.name}
                  error={fieldData?.room?.isError}
                  helperText={fieldData?.room?.helperText}
                  endPlaceholderIcon={
                    <Box
                      sx={{ rotate: "90deg", "& path": { fill: "#207DFF" } }}
                    >
                      <KebabMenuIcon
                        width="20px"
                        height="20px"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsWardRoomModelOpen(true);
                        }}
                      />
                    </Box>
                  }
                  sx={{ ...styles.textFieldStyle }}
                />
              }
              isIncludeMarginBottom={false}
            />
            <CommonGrid
              name={fieldData.checkInDate.label}
              value={
                <DatePickerWithTime
                  value={fieldData.checkInDate.value}
                  placeholder={fieldData.checkInDate.placeholder}
                  name={fieldData.checkInDate.name}
                  onChange={(newValue: any) =>
                    handleDateChange(newValue, "checkInDate")
                  }
                  minDate={formData.checkOutDate}
                  format="DD/MM/YYYY hh:mm:ss A"
                  error={fieldData.checkInDate.isError}
                  helperText={fieldData.checkInDate.helperText}
                  style={{ ...styles.datePickerWithTimeStyle }}
                />
              }
              isIncludeMarginBottom={false}
            />
            <Box my={2}>
              <SaveButton
                sx={{ mr: "20px" }}
                loading={isSaveButtonLoading}
                handleClick={handleSave}
              />
              <CancelButton buttonText="Cancel" handleClick={onClose} />
            </Box>
          </Grid>
          <Typography variant="h2" sx={{ m: "30px 0px" }}>
            Room History
          </Typography>
          <Grid sx={{ m: "20px 0px", minWidth: { xs: "900px", lg: "0px" } }}>
            {roomTransferHistory?.room_transfer_history?.length > 0 &&
              roomTransferHistory?.room_transfer_history?.map(
                (row: any, index: any) => {
                  return (
                    <>
                      {index === 0 && (
                        <Grid
                          key={index}
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: 1,
                            borderColor: "var(--table-border)",
                            pb: "10px",
                          }}
                        >
                          <Typography
                            variant="h3"
                            sx={{
                              width: { xs: "35%", md: "35%" },
                              textAlign: "left",
                            }}
                          >
                            Ward/Room No
                          </Typography>
                          <Typography
                            variant="h3"
                            sx={{
                              width: { xs: "25%", md: "25%" },
                              textAlign: "left",
                              borderColor: "var(--table-border)",
                            }}
                          >
                            In
                          </Typography>
                          <Typography
                            variant="h3"
                            sx={{
                              width: { xs: "25%", md: "25%" },
                              textAlign: "left",
                              borderColor: "var(--table-border)",
                            }}
                          >
                            Out
                          </Typography>
                          <Typography
                            variant="h3"
                            sx={{
                              width: { xs: "15%", md: "15%" },
                              textAlign: "left",
                            }}
                          >
                            Action
                          </Typography>
                        </Grid>
                      )}
                      <Grid
                        key={index}
                        sx={{
                          width: "100%",
                          minHeight: "50px",
                          display: "flex",
                          flexDirection: { xs: "row" },
                          alignItems: { xs: "center" },
                          justifyContent: "space-between",
                          borderBottom: 1,
                          borderColor: "var(--table-border)",
                        }}
                      >
                        <Grid
                          sx={{
                            width: "32%",
                            height: "34px",
                            "& .MuiAutocomplete-root": {
                              height: "32px",
                            },
                            "& .MuiFormLabel-root": {
                              display: "none",
                            },
                            "& .MuiInputBase-root": {
                              height: "32px",
                              p: "0px",
                              pr: "0px",
                            },
                            "& .MuiOutlinedInput-root": {
                              p: "0px",
                              pr: "0px",
                              boxShadow: "none",
                            },
                            "& .MuiSvgIcon-root": {
                              display: "none",
                            },
                            "&:hover .MuiSvgIcon-root": {
                              display: "inline-flex",
                            },
                          }}
                        >
                          <AutoComplete
                            name="new_bed_id"
                            value={row?.new_bed_id}
                            inputValue={row?.name}
                            options={roomOptions}
                            disabled={!row.isEditHistory ? true : false}
                            onChange={(e: any, newValue: any) =>
                              handleAutoCompleteChange(
                                e,
                                newValue,
                                "new_bed_id",
                                index
                              )
                            }
                            onInputChange={(e: any, newValue: any) =>
                              handleAutoCompleteInputChange(
                                e,
                                newValue,
                                "name",
                                index
                              )
                            }
                            width="100%"
                            sx={{
                              width: "100%",
                              height: "36px",
                              "& .MuiFormControl-root": {
                                height: "36px",
                              },

                              "& .MuiOutlinedInput-notchedOutline": {
                                border: 0,
                              },
                              "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: 0,
                                },
                              "& .MuiInputBase-root .MuiInputBase-input": {
                                padding: 0,
                                fontSize: "12px",
                                fontWeight: "400",
                              },

                              fontFamily: ["Inter", "sans-serif"].join(","),

                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "greyScale.light",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "primary.main",
                                },
                              "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "greyScale.lighter",
                                },
                              "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                                padding: "6.5px",
                                boxShadow: "none",
                                borderRadius: "8px",
                              },
                              "& .MuiAutocomplete-endAdornment": {
                                pt: "3px",
                              },
                            }}
                          />
                        </Grid>
                        <Grid
                          sx={{
                            width: "25%",
                            ...styles.datePickerGridStyle,
                            mt: "-10px",
                          }}
                        >
                          <DatePickerWithTime
                            value={moment(row?.doa, [
                              "DD/MM/YYYY hh:mm:ss",
                              "YYYY-MM-DD HH:mm:ss",
                            ]).format("DD/MM/YYYY hh:mm:ss A")}
                            name="doa"
                            isDisabled={!row.isEditHistory ? true : false}
                            format="DD/MM/YYYY hh:mm:ss A"
                            onChange={(newValue: any) =>
                              handleRoomHistoryDateChange(
                                newValue,
                                "doa",
                                index
                              )
                            }
                            style={{
                              boxShadow: "none",
                              border: "0px solid rgba(138, 138, 138, 0.32)",
                              height: "34px",
                              borderRadius: "5px",
                              padding: "0px",
                              width: "100%",
                              display: "flex",
                            }}
                          />
                        </Grid>
                        <Grid
                          sx={{
                            width: "25%",
                            ...styles.datePickerGridStyle,
                            mt: "-10px",
                          }}
                        >
                          <DatePickerWithTime
                            value={moment(row?.dod, [
                              "DD/MM/YYYY hh:mm:ss",
                              "YYYY-MM-DD HH:mm:ss",
                            ]).format("DD/MM/YYYY hh:mm:ss A")}
                            name="dod"
                            isDisabled={!row.isEditHistory ? true : false}
                            format="DD/MM/YYYY hh:mm:ss A"
                            onChange={(newValue: any) =>
                              handleRoomHistoryDateChange(
                                newValue,
                                "dod",
                                index
                              )
                            }
                            style={{
                              boxShadow: "none",
                              border: "0px solid rgba(138, 138, 138, 0.32)",
                              height: "34px",
                              borderRadius: "5px",
                              padding: "0px",
                              width: "100%",
                              display: "flex",
                            }}
                          />
                        </Grid>
                        {!row?.isEditHistory && (
                          <Button
                            variant="text"
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "15%",
                              height: "34px",
                              textTransform: "none",
                            }}
                            onClick={() =>
                              handleEditButtonChange(
                                "isEditHistory",
                                true,
                                index
                              )
                            }
                          >
                            <Typography variant="h4">Edit</Typography>
                          </Button>
                        )}
                        {row?.isEditHistory && (
                          <Grid
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "15%",
                            }}
                          >
                            <SaveButton
                              buttonText="Save"
                              loading={isUpdateButtonLoading}
                              handleClick={() => updateHistory(row)}
                              sx={{
                                height: "40px",
                                display: "flex",
                                justifyContent: "flex-start",
                                width: "50%",
                                minWidth: "50%",
                                p: "0px 0px 0px 10px",
                                border: 0,
                                textTransform: "none",
                              }}
                            />
                            <CancelButton
                              buttonText="Cancel"
                              handleClick={() =>
                                handleEditButtonChange(
                                  "isEditHistory",
                                  false,
                                  index
                                )
                              }
                              sx={{
                                height: "40px",
                                display: "flex",
                                justifyContent: "flex-start",
                                width: "50%",
                                minWidth: "50%",
                                textTransform: "none",
                                p: "0px 0px 0px 5px",
                                border: 0,
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </>
                  );
                }
              )}
          </Grid>
          <WardRoomModal
            isOpen={isWardRoomModelOpen}
            onClose={() => setIsWardRoomModelOpen(false)}
            onSelectRoom={handleSelectRoom}
          />
        </Grid>
      )}
    </DialogWrapper>
  );
};

export default RoomTransferModal;
