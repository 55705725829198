import React, { useCallback, useState } from "react";
import {
  TableCell,
  TableRow,
  Grid,
  Popper,
  Box,
  Typography,
  List,
  CircularProgress,
  debounce,
} from "@mui/material";
import { DeleteIcon, DisabledDelete } from "../../../assets/icons";
import { TextField, AutoCompleteWithTable } from "../../../components/basic";
import { getGoodsReturnProducts } from "../../../services/gynecologyService";

type summary = {
  total_discount_amount: number;
  goods_value: number;
  tax_amount: number;
  rounded_off: number;
  bill_amount: number;
};

type ProductRowProps = {
  styles?: any;
  row: any;
  handleDelete: () => void;
  summary?: summary;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number | string | any
  ) => void;
  handleAutoCompleteChange: (
    newValue: any,
    idx: number | string | any,
    name: string
  ) => false | void;
  handleAutoCompleteInputChange: (
    newValue: any,
    idx: number,
    name: string
  ) => false | void;
  index: number;
  allRows?: any;
  formError?: any;
  totalLength?: any;
  isEdit?: boolean;
  setIsDeleteDialogOpen?: any;
  info?: any;
  batchIdNo?: any;
};

const ProductRow = React.memo(
  ({
    styles,
    row,
    handleInputChange,
    handleAutoCompleteChange,
    handleDelete,
    allRows,
    index,
    formError,
    totalLength,
    isEdit,
    setIsDeleteDialogOpen,
    handleAutoCompleteInputChange,
    batchIdNo,
    info,
  }: ProductRowProps) => {
    const [allProducts, setAllProducts] = useState<any[]>([]);
    const [isProductLoading, setIsProductLoading] = useState(false);

    const PopperMy = function (props: any) {
      const { children, ...rest } = props;
      return (
        <Popper
          {...rest}
          placement="bottom-start"
          className="auto-complete-popper"
          sx={{
            width: "700px",
            p: "10px 0px",
            boxSizing: "border-box",
            "& .MuiPaper-root": {
              width: "700px",
              maxHeight: "250px",
              overflow: "hidden",
              borderRadius: "0px 0px 5px 5px",
              boxSizing: "border-box",
            },
            "& .MuiAutocomplete-listbox": {
              overflowX: "hidden",
              maxHeight: "250px",
              p: 0,
            },
          }}
        >
          <Box
            sx={{
              width: "700px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "var(--table-autocomplete-background)",
              p: "0px 10px 0px 0px",
              height: "44px",
              boxSizing: "border-box",
              borderRadius: "5px 5px 0px 0px",
            }}
          >
            <Typography
              sx={{
                width: "25%",
                fontSize: "14px",
                fontWeight: "500",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 2px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Generic Name
            </Typography>
            <Typography
              sx={{
                width: "25%",
                fontSize: "14px",
                fontWeight: "500",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Bill No
            </Typography>
            <Typography
              sx={{
                width: "25%",
                fontSize: "14px",
                fontWeight: "500",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Batch No
            </Typography>
            <Typography
              sx={{
                width: "25%",
                fontSize: "14px",
                fontWeight: "500",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Quantity
            </Typography>
          </Box>
          {props.children}
        </Popper>
      );
    };

    const getAllProcurementProductsData = async (
      search: any,
      infoData: any
    ) => {
      if (infoData.from_dept && infoData.to_dept && batchIdNo) {
        const data: any = {
          from_dept: infoData.from_dept,
          to_dept: infoData.to_dept,
          selected_batches: batchIdNo,
          search,
        };
        getGoodsReturnProducts(data)
          .then((res: any) => {
            setAllProducts(res.data);
            setIsProductLoading(false);
          })
          .catch((err: any) => {
            console.log(err);
            setIsProductLoading(false);
          });
      } else {
        setIsProductLoading(false);
      }
    };

    const debouncedGetAllProcurementList = useCallback(
      debounce((value: string, info: any) => {
        getAllProcurementProductsData(value, info);
      }, 500),
      []
    );

    const handleAutoCompleteRowInputChange = (
      e: any,
      newInputValue: any,
      index: number,
      name: string
    ) => {
      handleAutoCompleteInputChange(newInputValue, index, name);
      console.log(row, newInputValue);
      if (
        newInputValue?.length >= 3 &&
        newInputValue !== row.product_name?.product_name
      ) {
        setIsProductLoading(true);
        debouncedGetAllProcurementList(newInputValue, info);
      }
    };

    return (
      <TableRow
        sx={{
          "& td, & th": { border: 0 },
          width: "90vw",
        }}
      >
        <TableCell
          align="left"
          sx={{
            width: "12%",
            "& .MuiAutocomplete-root": {
              height: "44px",
            },
            "& .MuiInputBase-root": {
              height: "44px",
              p: "0px",
              pr: "0px",
            },
            "& .MuiOutlinedInput-root": {
              p: "0px",
              pr: "0px",
            },
          }}
        >
          {row.row_id ? (
            <TextField
              name="product_name"
              value={row?.product_name?.product_name}
              disabled={true}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          ) : (
            <AutoCompleteWithTable
              placeholder="Select Product"
              name="product_name"
              value={row?.product_name}
              onChange={(e: any, newValue: any) =>
                handleAutoCompleteChange(newValue, index, "product_name")
              }
              options={allProducts}
              renderOption={(props: any, option: any) => {
                return (
                  <List
                    {...props}
                    key={option.batch_id}
                    sx={{
                      width: "700px",
                      borderRadius: 0,
                    }}
                  >
                    <Box
                      className="list-box"
                      display="flex"
                      justifyContent="space-between"
                      width="700px"
                    >
                      <Typography
                        variant="h6"
                        sx={{ width: "15%", textAlign: "center", pl: "5%" }}
                      >
                        {option.product_name}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ width: "25%", textAlign: "center", pl: "5%" }}
                      >
                        {option.invoice_no ? option.invoice_no : ""}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ width: "25%", textAlign: "center", pl: "5%" }}
                      >
                        {option.batch_no}
                      </Typography>

                      <Typography
                        variant="h6"
                        sx={{ width: "25%", textAlign: "center", pl: "5%" }}
                      >
                        {option.stock_qty || 0}
                      </Typography>
                    </Box>
                  </List>
                );
              }}
              getOptionLabel={(option: any) => option?.product_name}
              sx={{
                width: "100%",
                height: "100%",
                "& .MuiFormControl-root": {
                  height: "100%",
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "& .MuiInputBase-root .MuiInputBase-input": {
                  padding: 0,
                  height: "100%",
                  fontWeight: "400",
                },
              }}
              PopperComponent={PopperMy}
              inputValue={row?.product_name_input_value}
              onInputChange={(e: any, newValue: any) => {
                handleAutoCompleteRowInputChange(
                  e,
                  newValue,
                  index,
                  "product_name_input_value"
                );
                if (newValue?.length < 3) {
                  setAllProducts([]);
                }
              }}
              noOptionsText={
                isProductLoading ? (
                  <div>
                    <CircularProgress
                      style={{ width: "10px", height: "10px" }}
                    />{" "}
                    Loading ...
                  </div>
                ) : row?.product_name_input_value?.length < 3 ||
                  !row?.product_name_input_value ? (
                  `Please enter ${
                    3 - (row?.product_name_input_value?.length || 0)
                  } or more characters`
                ) : (
                  "No options"
                )
              }
              filterOptions={(options: any) => options}
              onBlur={() => setAllProducts([])}
            />
          )}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "6%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="batch_no"
            value={row.batch_no}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="expiry_date"
            value={row.expiry_date}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "8%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="pack_type"
            value={row.pack_type}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
            }}
          />
        </TableCell>
        {isEdit && (
          <TableCell
            align="center"
            sx={{
              width: "8%",
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          >
            <TextField
              name="max_strip_qty"
              value={row.max_strip_qty}
              disabled={true}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
                "& .MuiInputBase-input": {
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "left",
                  p: "0px 10px",
                },
              }}
            />
          </TableCell>
        )}
        {isEdit && (
          <TableCell
            align="center"
            sx={{
              width: "8%",
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          >
            <TextField
              name="max_qty"
              value={row.max_qty}
              disabled={true}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
                "& .MuiInputBase-input": {
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "left",
                  p: "0px 10px",
                },
              }}
            />
          </TableCell>
        )}
        {!isEdit && (
          <TableCell
            align="center"
            sx={{
              width: "10%",
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          >
            <TextField
              name="stock_qty"
              value={row.stock_qty}
              disabled={true}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
                "& .MuiInputBase-input": {
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "left",
                  p: "0px 10px",
                },
              }}
            />
          </TableCell>
        )}
        <TableCell
          align="center"
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="ret_strip_qty"
            value={row.ret_strip_qty}
            disabled={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, index)
            }
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "8%",
            border: Boolean(formError[index as number]?.ret_qty)
              ? "2px solid red !important"
              : "",
          }}
        >
          <TextField
            name="ret_qty"
            value={row.ret_qty}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, index)
            }
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="ptr"
            value={row.ptr}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="discount"
            value={
              row.discount
                ? `${row.discount || 0} ${row.discount_type || ""}`
                : ""
            }
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "7%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            width="100px"
            name="tax"
            value={row.tax}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
            }}
          />
        </TableCell>
        <TableCell
          className="last-cell"
          align="right"
          sx={{
            backgroundColor: "var(--disabled-background)",
            width: "10%",
            position: "relative",
          }}
        >
          <TextField
            name="amount"
            value={row.amount}
            disabled={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, index)
            }
            sx={{
              ...styles.textFieldStyle,
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          />
          {!isEdit && (
            <Grid className="deleteIconGrid">
              {totalLength === 1 ? (
                <DisabledDelete className="delete-icon" />
              ) : (
                <DeleteIcon
                  className="delete-icon"
                  onClick={() => {
                    setIsDeleteDialogOpen({
                      isOpen: true,
                      selectedRow: {
                        id: row.id,
                        batchNo: row.bill_batch_id,
                      },
                    });
                  }}
                />
              )}

              <TextField
                width="0px"
                name="discountAmount"
                value={row?.discountAmount}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e, index)
                }
                sx={{
                  display: "none",
                  transform: "translateX(100vw)",
                }}
              />
              <TextField
                width="0px"
                name="taxAmount"
                value={row?.taxAmount}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e, index)
                }
                sx={{
                  display: "none",
                  transform: "translateX(100vw)",
                }}
              />
            </Grid>
          )}
        </TableCell>
      </TableRow>
    );
  }
);

export default ProductRow;
