import React, { useEffect } from "react";
// import { useNavigate, uselocationHook } from 'react-router';
import { ListItemButton, ListItemText, ListItemIcon } from "@mui/material";
import NavConfig from "./NavConfig";
import { setMobileOpen, setAppBarTitle } from "../../../redux/slices/layout";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Link } from "react-router-dom";
import AccordionSummary from "@mui/material/AccordionSummary";

type HeaderProps = {
  handleMouseLeave: () => void;
  isMouseExit: boolean;
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  minHeight: "0px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}));

function MainListItems({
  handleMouseLeave,
  isMouseExit,
  navigateHook,
  locationHook,
}: any) {
  const navConfig = NavConfig();
  const dispatch = useDispatch<AppDispatch>();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <React.Fragment>
      {navConfig.map((nav: any, index) => {
        if (!nav.children) {
          if (nav.hasPermission) {
            return (
              <Link
                to={nav.path}
                key={index}
                style={{
                  textDecoration: "none",
                }}
              >
                <ListItemButton
                  key={index}
                  disableRipple={true}
                  sx={{
                    maxWidth: "236px",
                    minHeight: "0px",
                    height: "40px",
                    borderRadius: "5px",
                    m: "15px 4px 0px 6px",
                    display: "flex",
                    flexDirection: "row",
                    ...(locationHook.pathname === nav.path
                      ? { background: "#ECF4FF" }
                      : { background: null }),
                  }}
                  onClick={() => {
                    navigateHook(nav.path);
                    handleMouseLeave();
                    dispatch(setMobileOpen(false));
                    setExpanded("noPanel");
                  }}
                >
                  <ListItemIcon sx={{ color: "#7F7F7F" }}>
                    {nav.icon}
                  </ListItemIcon>
                  <Typography
                    sx={{
                      color:
                        locationHook.pathname === nav.path
                          ? "#207DFF"
                          : "var(--text-secondary)",
                      fontSize: "15px",
                      fontWeight: "400",
                    }}
                  >
                    {nav.title}
                  </Typography>
                </ListItemButton>
              </Link>
            );
          }
        }

        if (nav.children) {
          if (nav.hasPermission) {
            return (
              <Grid
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  m: "15px 4px 0 6px",
                  width: "229px",
                  "& .MuiAccordionSummary-root": {
                    minHeight: "0px",
                    height: "40px",
                    width: "236px",
                    p: "0px 10px 0px 16px",
                  },

                  "& .MuiAccordionDetails-root": {
                    p: "0 0 0 25px",
                    m: "0 0 0 25px",
                    width: "209",
                    borderLeft: 1,
                    borderColor: isMouseExit ? "none" : "#232323",
                  },

                  "& .MuiCollapse-entered": {
                    display: isMouseExit ? "none" : "block",
                    transition: "display 8s linear 5s",
                  },
                  "& .MuiCollapse-hidden": {
                    display: isMouseExit ? "block" : "none",
                    transition: "display 8s linear 5s",
                  },

                  "&.Mui-expanded": {
                    display: isMouseExit ? "none" : "block",
                    transition: "display 8s linear 5s",
                  },
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                  },
                }}
              >
                <Accordion
                  expanded={expanded === `${nav.title}`}
                  onChange={handleChange(`${nav.title}`)}
                >
                  <Grid
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                      borderRadius: "5px",
                      width: "236px",
                      position: "relative",

                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <KeyboardArrowRightIcon
                          sx={{
                            color: nav.children.some((child: any) =>
                              locationHook.pathname.includes(
                                nav.path + child.path
                              )
                            )
                              ? "primary.main"
                              : "#7F7F7F",
                            width:
                              expanded === `${nav.title}` ? "20px" : "24px",
                            height:
                              expanded === `${nav.title}` ? "20px" : "26px",
                          }}
                        />
                      }
                      sx={{
                        borderRadius: "5px",
                        background: nav.children.some((child: any) =>
                          locationHook.pathname.includes(nav.path + child.path)
                        )
                          ? "#ECF4FF"
                          : "initial",
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <ListItemIcon sx={{ color: "black" }}>
                          {nav.icon}
                        </ListItemIcon>
                        <Typography
                          sx={{
                            color: nav.children.some((child: any) =>
                              locationHook.pathname.includes(
                                nav.path + child.path
                              )
                            )
                              ? "primary.main"
                              : "var(--text-secondary)",
                            fontSize: "15px",
                            fontWeight: "400",
                          }}
                        >
                          {nav.title}
                        </Typography>
                      </Grid>
                    </AccordionSummary>
                  </Grid>
                  <AccordionDetails>
                    {nav.children.map((navChild: any, index: any) => {
                      if (navChild.hasPermission) {
                        return (
                          <Link
                            to={navChild?.hasExternalURL
                              ? navChild.path
                              : nav.path + navChild.path}
                            key={index}
                            style={{
                              textDecoration: "none",
                            }}
                          >
                            <ListItemButton
                              key={index}
                              disableRipple={true}
                              sx={{
                                minHeight: "0px",
                                height: "40px",
                                borderRadius: "5px",
                                m: "12px 10px 0px 6px",
                                display: "flex",
                                flexDirection: "row",
                                width: "max-content",
                                position: "relative",
                                justifyContent: "flex-start",
                              }}
                              onClick={() => {
                                handleMouseLeave();
                                dispatch(setMobileOpen(false));
                              }}
                            >
                              <Typography
                                sx={{
                                  color: locationHook?.pathname?.includes(
                                    nav.path + navChild.path
                                  )
                                    ? "primary.main"
                                    : "#7F7F7F",
                                  fontSize: "15px",
                                  fontWeight: locationHook?.pathname?.includes(
                                    nav.path + navChild.path
                                  )
                                    ? "500"
                                    : "400",
                                }}
                              >
                                {navChild.title}
                              </Typography>
                            </ListItemButton>
                          </Link>
                        );
                      }
                    })}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          }
        }
      })}
      {/* </Grid> */}
    </React.Fragment>
  );
}

export default MainListItems;
