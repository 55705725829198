import React, { useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {
  Divider,
  Typography,
  InputLabel,
  FormControl,
  InputAdornment,
  MenuItem,
  FormHelperText,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useFormControl } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { VariableSizeList } from "react-window";

type AutoSelectProps = {
  value?: string | null | any;
  name?: string | any;
  onChange?: any;
  onClick?: any;
  placeholder?: string;
  placeholderIcon?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  options: string[] | object[];
  onInputChange?: any;
  label?: any;
  inputValue?: string;
  width?: string;
  sx?: {};
  error?: boolean;
  helperText?: string;
  onBlur?: any;
  inputAdornmentIcon?: any;
  customButton?: {
    enabled?: boolean;
    text?: string;
  };
  inputRef?: any;
  key?: any;
  handlePagination?: any;
  loading?: any;
  disabled?: boolean;
  disableClearable?: boolean | undefined;
  optionName?: string | undefined;
  handleCustomButtonClick?: () => void;
  menuItemWidth?: any;
  isMultiple?: boolean;
  popperComponentWidth?: string;
  noOptionsText?: any;
  isAddAutocompleteValue?: boolean;
  isVirtualization?: any;
  filterOptions?: any;
  isValueAsObject?: boolean;
  autoFocus?: boolean;
  onFocus?: any;
};

export default function MuiAutoComplete({
  value,
  onChange,
  placeholder,
  inputValue,
  onInputChange,
  name,
  placeholderIcon,
  options,
  label,
  sx,
  onClick,
  error,
  onBlur,
  helperText,
  inputAdornmentIcon,
  customButton = { enabled: false },
  inputRef,
  key,
  handlePagination,
  loading,
  disabled = false,
  disableClearable = true,
  optionName = "name",
  handleCustomButtonClick,
  menuItemWidth,
  isMultiple = false,
  popperComponentWidth,
  isAddAutocompleteValue = false,
  isVirtualization = false,
  filterOptions,
  noOptionsText,
  isValueAsObject = false,
  autoFocus,
  onFocus,
}: AutoSelectProps) {
  const { focused } = useFormControl() || {};

  const [isFocused, setIsFocused] = useState(false);

  const listRef = useRef<VariableSizeList | null>(null);

  const LISTBOX_PADDING = 8; // px
  const OuterElementContext = React.createContext({});

  const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }

  const ListboxComponent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
  >(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: any = React.Children.toArray(children);

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getHeight = () => {
      if (itemCount > 8) {
        return 7 * 40;
      }
      return itemCount * 40;
    };

    const gridRef = useResetCache(itemCount);
    listRef.current = gridRef.current;

    useEffect(() => {
      if (value) {
        const selectedIndex = options.findIndex(
          (option: any) =>
            option[optionName] === (isValueAsObject ? value[optionName] : value)
        );
        if (selectedIndex >= 0 && gridRef.current) {
          gridRef.current.scrollToItem(selectedIndex, "center");
        }
      }
    }, [value, options, optionName, isValueAsObject]);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="li"
            itemSize={() => itemSize}
            overscanCount={5}
            itemCount={itemCount}
            style={{
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {({ index, style }: any) => (
              <div
                style={{
                  ...style,
                  top: (style.top as number) + LISTBOX_PADDING,
                }}
              >
                {itemData[index]}
              </div>
            )}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

  const handleFocus = () => {
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  };

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {label && (
        <InputLabel
          htmlFor="merchant-autocomplete"
          focused={isFocused}
          sx={{
            fontSize: "var(--primary-label-font-size)",
            color: "var(--primary-label-color)",
          }}
        >
          {label}
        </InputLabel>
      )}
      <FormControl fullWidth sx={{ mt: label ? "5px" : "0px" }}>
        <Autocomplete
          onFocus={handleFocus}
          multiple={isMultiple}
          onChange={onChange}
          id="merchant-autocomplete"
          options={options || []}
          key={key}
          includeInputInList={true}
          {...(isValueAsObject && { value })}
          autoFocus={autoFocus}
          {...(isAddAutocompleteValue && { value })}
          inputValue={inputValue}
          onInputChange={onInputChange}
          noOptionsText={noOptionsText}
          ListboxComponent={isVirtualization ? ListboxComponent : undefined}
          disabled={disabled}
          disableClearable={disableClearable}
          {...(isFocused && { openOnFocus: true })}
          popupIcon={<ExpandMoreIcon />}
          sx={{
            fontFamily: ["Inter", "sans-serif"].join(","),
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: error ? "#d32f2f" : "var(--primary-border-color)", // Default border color
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: error ? "#d32f2f" : "var(--primary-border-color)", // Change the border color on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: error ? "#d32f2f" : "primary.main",
            },
            "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
              borderColor: error ? "#d32f2f" : "var(--primary-border-color)", // Change the border color on hover
            },
            "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
              padding: "4.7px",
              // boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              borderRadius: "var(--primary-border-radius)",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              borderColor: "#E5E7EB",
              backgroundColor: "#F4F4F5",
              pl: "5px",
              borderRadius: "var(--primary-border-radius)",
            },
            "& .MuiOutlinedInput-root.Mui-disabled": {
              borderColor: "#E5E7EB",
              backgroundColor: "#F4F4F5",
              // pl: "15px",
              borderRadius: "var(--primary-border-radius)",
            },
            ...sx,
          }}
          getOptionLabel={(option: any) => option[optionName]}
          filterOptions={filterOptions}
          renderOption={(props, option: any) => (
            <MenuItem
              sx={{
                "&:hover, &.Mui-focused:hover": {
                  color: "backgroundPrimary.main",
                  backgroundColor: "primary.main",
                },
                borderRadius: "var(--primary-border-radius)",
                p: "15px",
                m: "0 5px",
                fontSize: "var(--primary-placeholder-font-size)",
                width: menuItemWidth ? menuItemWidth : "initial",
              }}
              {...props}
            >
              {isValueAsObject ? option.name : option[optionName]}
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              {...(!isValueAsObject && { value })}
              onBlur={(e: any) => {
                setIsFocused(false);
                onBlur && onBlur(e);
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: inputAdornmentIcon ? (
                  <InputAdornment position="start" sx={{ pl: 1 }}>
                    {inputAdornmentIcon}
                  </InputAdornment>
                ) : null,
              }}
              inputRef={
                inputRef
                  ? (input) => {
                      inputRef.current = input;
                    }
                  : undefined
              }
              placeholder={placeholder}
              sx={{
                "& input::placeholder": {
                  fontSize: "var(--primary-placeholder-font-size)",
                  color: "black",
                },
              }}
            />
          )}
          PaperComponent={({ children }) => {
            return (
              <Paper
                sx={{
                  width: popperComponentWidth
                    ? popperComponentWidth
                    : "initial",
                }}
              >
                {children}
                {customButton?.enabled === true ? (
                  <>
                    <Divider />
                    <Button
                      color="primary"
                      fullWidth
                      sx={{
                        justifyContent: "flex-start",
                        p: 2,
                        pl: 2,
                        color: "#207DFF",
                      }}
                      onMouseDown={onClick}
                      onClick={handleCustomButtonClick}
                    >
                      <Typography variant="h4" sx={{ textTransform: "none" }}>
                        {customButton.text}
                      </Typography>
                    </Button>
                  </>
                ) : null}
              </Paper>
            );
          }}
        />
      </FormControl>
      {helperText !== undefined && (
        <FormHelperText
          error={error}
          sx={{
            ml: 2,
            fontSize: "var(--error-text-font-size)",
            minHeight: "20px",
            height: "auto",
            color: "var(--error-text-color)",
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </Grid>
  );
}
