import React, { useCallback, useState } from "react";
import {
  TableCell,
  TableRow,
  Popper,
  Box,
  List,
  Grid,
  Typography,
  CircularProgress,
  debounce,
} from "@mui/material";
import { TextField, AutoCompleteWithTable } from "../../../components/basic";
import { DeleteIcon, DisabledDelete } from "../../../assets/icons";
import { getPurchaseReturnProduct } from "../../../services/gynecologyService";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

type ProductRowProps = {
  index: number;
  styles?: any;
  row?: any;
  handleDelete: (index: any, batch_no?: string, id?: number) => void;
  allRows?: any;
  summary?: any;
  handleInputChange: (e: any, index: number | string) => void;
  handleAutoCompleteChange: (
    newValue: any,
    idx: number | string,
    name: string
  ) => false | void;
  handleAutoCompleteInputChange: (
    newValue: any,
    idx: number,
    name: string
  ) => false | void;
  formError?: any;
  totalLength?: number;
  setIsDeleteDialogOpen?: any;
  info?: any;
  batchIdNo?: any;
};

const ProductRow = React.memo(
  ({
    styles,
    row,
    allRows,
    handleInputChange,
    handleAutoCompleteChange,
    handleAutoCompleteInputChange,
    handleDelete,
    index,
    formError,
    totalLength,
    setIsDeleteDialogOpen,
    info,
    batchIdNo,
  }: ProductRowProps) => {
    const { appConfiguration } = useSelector(
      (state: RootState) => state.appConfiguration
    );
    const [allProduct, setAllProducts] = useState([]);
    const [isProductLoading, setIsProductLoading] = useState(false);

    const getAllProcurementProductsData = async (search: any) => {
      if (
        (!appConfiguration?.goods_issue_disable &&
          info.supplier_name &&
          info.department) ||
        (appConfiguration?.goods_issue_disable &&
          info.supplier_name &&
          info.to_dept_id)
      ) {
        const data: any = {
          supplier_id: info.supplier_name,
          dept_id: !appConfiguration?.goods_issue_disable
            ? info.department
            : info.to_dept_id,
          selected_batches: batchIdNo,
          search,
        };
        getPurchaseReturnProduct(data)
          .then((res: any) => {
            setIsProductLoading(false);
            setAllProducts(res.data);
          })
          .catch((err: any) => console.log(err));
      } else {
        setIsProductLoading(false);
      }
    };

    const debouncedGetAllProcurementList = useCallback(
      debounce((value: string) => {
        getAllProcurementProductsData(value);
      }, 500),
      []
    );

    const handleAutoCompleteRowInputChange = (
      e: any,
      newInputValue: any,
      index: number,
      name: string
    ) => {
      handleAutoCompleteInputChange(newInputValue, index, name);
      if (newInputValue?.length >= 3) {
        setIsProductLoading(true);
        debouncedGetAllProcurementList(newInputValue);
      }
    };

    const PopperMy = function (props: any) {
      const { children, ...rest } = props;
      return (
        <Popper
          {...rest}
          placement="bottom-start"
          className="auto-complete-popper"
          sx={{
            width: "700px",
            p: "10px 0px",
            boxSizing: "border-box",
            "& .MuiPaper-root": {
              width: "700px",
              maxHeight: "250px",
              overflow: "hidden",
              borderRadius: "0px 0px 5px 5px",
              boxSizing: "border-box",
            },
            "& .MuiAutocomplete-listbox": {
              overflowX: "hidden",
              maxHeight: "250px",
              p: 0,
            },
          }}
        >
          <Box
            sx={{
              width: "700px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "var(--table-autocomplete-background)",
              p: "0px 10px 0px 0px",
              height: "45px",
              boxSizing: "border-box",
              borderRadius: "5px 5px 0px 0px",
            }}
          >
            <Typography
              sx={{
                width: "50%",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              productName
            </Typography>
            <Typography
              sx={{
                width: "20%",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Bill No
            </Typography>
            <Typography
              sx={{
                width: "20%",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Batch No
            </Typography>
            <Typography
              sx={{
                width: "20%",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Quantity
            </Typography>
          </Box>
          {props.children}
        </Popper>
      );
    };

    return (
      <TableRow
        sx={{
          "& td, & th": { border: 0 },
          width: "90vw",
        }}
      >
        <TableCell
          align="left"
          sx={{
            width: "12%",
            "& .MuiAutocomplete-root": {
              height: "44px",
            },
            "& .MuiInputBase-root": {
              height: "44px",
              p: "0px",
              pr: "0px",
            },
            "& .MuiOutlinedInput-root": {
              p: "0px",
              pr: "0px",
            },
            "& input": {
              height: "100%",
            },
          }}
        >
          {!row.isId ? (
            <AutoCompleteWithTable
              placeholder="Select Product"
              name="product_name"
              value={row?.product_name}
              onChange={(e: any, newValue: any) =>
                handleAutoCompleteChange(newValue, index, "product_name")
              }
              options={allProduct}
              renderOption={(props: any, option: any) => {
                return (
                  <List
                    {...props}
                    key={option.ID}
                    className="table-body-lists-div"
                    sx={{
                      width: "700px",
                      borderRadius: 0,
                      boxSizing: "border-box",
                      m: "0px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "primary.lighter",
                      },
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="700px"
                      className="list-row"
                      sx={{
                        boxSizing: "border-box",
                        m: "0px",
                        p: "0px 10px 0px 0px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          width: "50%",
                          textAlign: "left",
                          p: "0px 10px",
                        }}
                      >
                        {option.product_name}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          width: "20%",
                          textAlign: "left",
                          p: "0px 10px",
                        }}
                      >
                        {option.invoice_no}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          width: "20%",
                          textAlign: "left",
                          p: "0px 10px",
                        }}
                      >
                        {option.batch_no}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          width: "20%",
                          textAlign: "left",
                          p: "0px 10px",
                        }}
                      >
                        {option.stock_qty || 0}
                      </Typography>
                    </Box>
                  </List>
                );
              }}
              isHideClearIcon
              getOptionLabel={(option: any) => option?.product_name}
              sx={{
                width: "100%",
                height: "34px",
                "& .MuiFormControl-root": {
                  height: "32px",
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "& .MuiInputBase-root .MuiInputBase-input": {
                  padding: 0,
                  fontSize: "12px",
                  fontWeight: "400",
                },
              }}
              inputValue={row?.product_value}
              onInputChange={(e: any, newInputValue: any) => {
                handleAutoCompleteRowInputChange(
                  e,
                  newInputValue,
                  index,
                  "product_value"
                );
                if (newInputValue?.length < 3) {
                  setAllProducts([]);
                }
              }}
              filterOptions={(options: any) => options}
              onBlur={() => setAllProducts([])}
              noOptionsText={
                isProductLoading ? (
                  <div>
                    <CircularProgress
                      style={{ width: "10px", height: "10px" }}
                    />{" "}
                    Loading ...
                  </div>
                ) : row?.product_value?.length < 3 || !row?.product_value ? (
                  `Please enter ${
                    3 - (row?.product_value?.length || 0)
                  } or more characters`
                ) : (
                  "No options"
                )
              }
              PopperComponent={PopperMy}
            />
          ) : (
            <TextField
              name="product_name"
              value={row.product_name.product_name}
              disabled={true}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
                "& .MuiInputBase-input": {
                  textAlign: "left",
                },
                "& .MuiInputBase-input.Mui-disabled": {
                  paddingLeft: "14px",
                },
              }}
            />
          )}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "6%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="stock"
            value={row.stock}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="free"
            value={row.free}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            border: Boolean(formError[index]?.ret_qty)
              ? "2px solid red !important"
              : "",
          }}
        >
          <TextField
            name="ret_qty"
            value={row.ret_qty}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            border: Boolean(formError[index]?.free_ret_qty)
              ? "2px solid red !important"
              : "",
          }}
        >
          <TextField
            name="f_ret_qty"
            value={row.f_ret_qty}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="discount"
            value={`${row.discount || 0} ${row.discount_type || ""}`}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="batch_no"
            value={row.batch_no}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            backgroundColor: "var(--table-cell-disabled-background)",
            width: "10%",
          }}
        >
          <TextField
            name="expiry_date"
            value={row.expiry_date}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "8%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            width="100px"
            name="tax"
            value={row.tax}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "8%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            width="100px"
            name="ptr"
            value={row.ptr}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
            }}
          />
        </TableCell>
        <TableCell
          className="last-cell"
          align="right"
          sx={{
            backgroundColor: "var(--disabled-background)",
            width: "10%",
            position: "relative",
          }}
        >
          <TextField
            name="amount"
            value={row.amount}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          />

          <Grid className="deleteIconGrid">
            {index === 0 && totalLength === 1 ? (
              <DisabledDelete className="delete-icon" />
            ) : (
              <DeleteIcon
                className="delete-icon"
                onClick={() => {
                  setIsDeleteDialogOpen({
                    isOpen: true,
                    selectedRow: {
                      id: index,
                      batchNo: row.bill_batch_id,
                      rowId: row.row_id,
                    },
                  });
                }}
              />
            )}

            <TextField
              width="0px"
              name="discountAmount"
              value={row.discountAmount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
            <TextField
              width="0px"
              name="taxAmount"
              value={row.taxAmount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
);

export default ProductRow;
