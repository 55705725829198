import { Tooltip } from "@mui/material";
import { ReactNode } from "react";

type TooltipProps = {
  arrow?: boolean;
  components?: any;
  componentsProps?: any;
  children?: any;
  title: ReactNode;
  sx?: any;
  placement:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  disableInteractive?: boolean;
  disableHoverListener?: boolean;
  disableTouchListener?: boolean;
  disableFocusListener?: boolean;
};

const TooltipComponent = (props: TooltipProps) => {
  const {
    arrow = true,
    components,
    componentsProps,
    children,
    title,
    sx,
    placement,
    disableInteractive,
    disableHoverListener,
    disableTouchListener,
    disableFocusListener,
  } = props;

  const commonTooltipStyle = {
    tooltip: {
      sx: {
        color: "#FFF",
        fontSize: "12px",
        background: "#414141",
        padding: "10px",
        boxShadow: "unset",
        borderRadius: "6px",
        ml: 3,
        "& .MuiTooltip-arrow": {
          "&::before": {
            border: " 1.5px solid #D8D8D8",
            boxShadow: "unset",
            background: "#414141",
          },
        },
      },
    },
    ...componentsProps,
  };
  return (
    <Tooltip
      arrow={arrow}
      components={components}
      componentsProps={commonTooltipStyle}
      title={title}
      sx={sx}
      placement={placement}
      disableInteractive={disableInteractive}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableTouchListener}
      disableFocusListener={disableFocusListener}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipComponent;
