import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import {
  TextField,
  Select,
  SaveButton,
  CancelButton,
} from "../../../../components/basic";
import { billSummaryConst } from "../../../../constants/displayText";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { paymentOptions } from "../../../../constants/dropdownOptions";
type SummaryProps = {
  isSaveLoading?: boolean;
  isEdit?: boolean;
  summary: any;
  summaryError?: any;
  handleSaveData?: () => void;
  handleInputChange?: any;
};

const Summary = React.memo(
  ({
    isSaveLoading,
    isEdit,
    summary,
    summaryError,
    handleInputChange,
    handleSaveData,
  }: SummaryProps) => {
    const {
      goodsValue,
      CGST,
      SGST,
      GSTAmount,
      billTotal,
      discount,
      roundedOff,
      adjustment,
      netAmount,
      excess,
      advance,
      type,
      cashPaid,
      amountPaid,
      balance,
      excessAmount,
      refundedAmount,
      netExcess,
      creditLimit,
      due,
    } = billSummaryConst;
    const { appConfiguration } = useSelector(
      (state: RootState) => state.appConfiguration
    );
    const [paymentTypeOptions, setPaymentTypeOptions] = useState<any>([]);
    const summaryOne = [
      {
        key: goodsValue,
        value: summary.disc_goods_val,
      },
      {
        key: "CGST",
        value: summary?.CGST,
      },
      {
        key: "SGST",
        value: summary?.SGST,
      },
    ];
    const summaryTwo = [
      {
        key: GSTAmount,
        name: "total_tax",
        value: Number(summary.total_tax)?.toFixed(2),
      },
      {
        key: billTotal,
        name: "billTotal",
        value: Number(summary.bill_total)?.toFixed(2),
        isHighlight: true,
      },
      ...(appConfiguration?.purchase_discount === "overall" ||
      appConfiguration?.purchase_discount === "both"
        ? [
            {
              key: discount,
              name: "billDiscountAmount",
              value: Number(summary.bill_discount_amount).toFixed(2),
              isSelectField: true,
            },
          ]
        : []),
      {
        key: roundedOff,
        name: "roundedOff",
        value: summary.rounded_off,
      },
      {
        key: adjustment,
        name: "adjustment",
        value: summary.adjustment,
        isInputField: true,
      },
      {
        key: netAmount,
        name: "net_amount",
        value: summary.net_amount,
        isHighlight: true,
      },
    ];
    const summaryThree = [
      { key: excess, value: summary.excess, isDisabled: true },
      { key: advance, value: summary.advance, isDisabled: true },
      { key: due, value: summary.overalldue, isDisabled: true },
      { key: creditLimit, value: summary.creditlimit, isDisabled: true },
    ];
    const summaryFour = [
      {
        key: type,
        value: summary.payment_method,
        isSelect: true,
      },
      {
        key: cashPaid,
        name: "cash_paid",
        value: summary.cash_paid,
        isError: summaryError.cash_paid ? true : false,
        helperText: summaryError.cash_paid,
      },
      {
        key: amountPaid,
        name: "amount_paid",
        value: summary.amount_paid,
        isDisabled: true,
      },

      {
        key: balance,
        name: "balance",
        value: summary.balance,
        isDisabled: true,
      },
      ...(summary.payment_method !== "Excess" &&
      summary.payment_method !== "Advance"
        ? [
            {
              key: excessAmount,
              value: summary.excess_amount,
              isDisabled: true,
            },
            {
              key: refundedAmount,
              name: "refunded_amount",
              value: summary.refunded_amount,
              isError: summaryError.refunded_amount ? true : false,
              helperText: summaryError.refunded_amount,
            },
            {
              key: netExcess,
              value: summary.net_excess,
              isDisabled: true,
            },
          ]
        : []),
    ];

    const styles = {
      textFieldStyle: {
        height: "35px",
        fontSize: "6px",

        "& .MuiOutlinedInput-root": {
          height: "35px",
          paddingLeft: "10px",
          textAlign: "center",
          borderRadius: "5px",
          boxShadow: "none",
        },

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },

      selectStyle: {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
    };

    useEffect(() => {
      const options = paymentOptions?.map((ele: any) => {
        if (!summary.excess && ele.id === "Excess") {
          return {
            ...ele,
            disabled: true,
          };
        }
        if (!summary.advance && ele.id === "Advance") {
          return {
            ...ele,
            disabled: true,
          };
        }
        return {
          ...ele,
        };
      });
      setPaymentTypeOptions(options);
    }, [summary.excess, summary.advance]);

    return (
      <Grid container>
        <Grid
          container
          sx={{
            width: { xs: "90vw", sm: "80vw", md: "90vw" },
            display: "flex",
            flexDirection: "column",
            m: "20px 0",
          }}
        >
          <Typography
            variant="h3"
            color="initial"
            sx={{
              fontWeight: "600",
              color: "primary.main",
              m: "39px 0 15px 0",
            }}
          >
            Billing Details
          </Typography>
          <Grid
            container
            sx={{
              width: { xs: "90vw", sm: "80vw", md: "90vw" },
              borderRadius: "5px",
              border: "1px solid #D1D1D1",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Grid
              item
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "center", md: "flex-start" },
                justifyContent: { xs: "space-around" },
              }}
            >
              <Grid
                item
                sx={{
                  width: {
                    xs: "70%",
                    sm: "70%",
                    md: "45%",
                    lg: "35%",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  m: "51px 0px 45px 0px",
                }}
              >
                {summaryOne.map((row, index) => {
                  return (
                    <Grid
                      key={index}
                      sx={{
                        width: {
                          xs: "100%",
                          lg: "100%",
                        },
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        maxHeight: "35px",
                        mb: "23px",
                        color: "textPrimary.main",
                      }}
                      className="row"
                    >
                      <Grid
                        sx={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h4">{row.key}</Typography>
                        <Typography variant="h4">:</Typography>
                      </Grid>
                      <Typography>
                        {row.key === goodsValue
                          ? row?.value?.toFixed(2)
                          : row?.value}
                      </Typography>
                    </Grid>
                  );
                })}

                {summary?.sub_taxes &&
                  Object?.keys(summary?.sub_taxes)?.map((key: any) => {
                    const value = summary?.sub_taxes[key];
                    return (
                      <Grid
                        key={key}
                        sx={{
                          width: {
                            xs: "100%",
                            lg: "100%",
                          },
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          maxHeight: "35px",
                          mb: "23px",
                          color: "textPrimary.main",
                        }}
                        className="row"
                      >
                        <Grid
                          sx={{
                            width: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h4">{key}</Typography>
                          <Typography variant="h4">:</Typography>
                        </Grid>
                        <Typography>{value}</Typography>
                      </Grid>
                    );
                  })}
              </Grid>
              <Grid
                item
                sx={{
                  width: {
                    xs: "70%",
                    sm: "70%",
                    md: "45%",
                    lg: "35%",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  m: "51px 0px 45px 0px",
                }}
              >
                {summaryTwo.map((row, index) => {
                  return (
                    <Grid
                      key={index}
                      sx={{
                        width: {
                          xs: "100%",
                          lg: "100%",
                        },
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        maxHeight: "35px",
                        mb: "23px",
                        color: "textPrimary.main",
                      }}
                      className="row"
                    >
                      <Grid
                        sx={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            color: row?.isHighlight ? "primary.main" : null,
                          }}
                        >
                          {row.key}
                        </Typography>
                        <Typography variant="h4">:</Typography>
                      </Grid>
                      {row?.isSelectField && (
                        <>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "100px",
                              height: "35px",
                            }}
                          >
                            <TextField
                              name="bill_discount"
                              onChange={(e) => handleInputChange(e)}
                              value={summary.bill_discount}
                              height="35px"
                              sx={{
                                ...styles.textFieldStyle,
                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "var(--primary-border-color)",
                                    borderRadius: "5px 0px 0px 5px",
                                  },
                                width: "60px",
                              }}
                            />
                            <Grid
                              sx={{
                                height: "35px",
                                "& .MuiInputBase-root": {
                                  padding: "0px",
                                },
                              }}
                            >
                              <Select
                                label=""
                                options={[
                                  { id: "₹", name: "₹" },
                                  { id: "%", name: "%" },
                                ]}
                                placeholder="%"
                                name="bill_discount_type"
                                onChange={(e) => handleInputChange(e)}
                                value={summary.bill_discount_type}
                                width="40px"
                                sx={{
                                  height: "35px",
                                  borderRadius: "0px 5px 5px 0px",
                                  borderColor: "var(--primary-border-color)",
                                  boxShadow: "none",
                                  ...styles.selectStyle,
                                }}
                                isRemoveClearIcon
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {row?.isInputField ? (
                        <TextField
                          name="adjustment"
                          value={summary.adjustment}
                          onChange={(e) => handleInputChange(e)}
                          sx={{
                            ...styles.textFieldStyle,
                            width: "inherit",
                            "& .MuiInputBase-input": {
                              fontSize: "12px",
                              fontWeight: "400",
                              textAlign: "left",
                              p: "0px 10px",
                            },
                          }}
                        />
                      ) : (
                        <Typography sx={{ width: "20%", textAlign: "right" }}>
                          {/* {row.name === "netAmount"
                            ? row?.value
                            : row?.value?.toFixed(2)} */}
                          {row?.value}
                        </Typography>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            width: { xs: "90vw", sm: "80vw", md: "90vw" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h3"
            color="initial"
            sx={{
              fontWeight: "600",
              color: "primary.main",
              m: "39px 0 15px 0",
            }}
          >
            Payment Method
          </Typography>
          <Grid
            container
            sx={{
              width: { xs: "90vw", sm: "80vw", md: "90vw" },
              borderRadius: "5px",
              border: "1px solid #D1D1D1",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Grid
              item
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "center", md: "flex-start" },
                justifyContent: { xs: "space-around" },
              }}
            >
              <Grid
                item
                sx={{
                  width: {
                    xs: "50%",
                    sm: "50%",
                    md: "30%",
                    lg: "35%",
                  },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  m: "51px 0px 45px 0px",
                }}
              >
                {summaryThree.map((row, index) => {
                  return (
                    <Grid
                      key={index}
                      sx={{
                        width: {
                          xs: "100%",
                          lg: "100%",
                        },
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        maxHeight: "35px",
                        mb: "23px",
                        color: "textPrimary.main",
                      }}
                      className="row"
                    >
                      <Grid
                        sx={{
                          width: "40%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h4">{row.key}</Typography>
                        <Typography variant="h4">:</Typography>
                      </Grid>
                      <TextField
                        value={row.value}
                        name="quantity"
                        disabled={row?.isDisabled}
                        onChange={(e) => handleInputChange(e)}
                        sx={{
                          ...styles.textFieldStyle,
                          "& .MuiOutlinedInput-root": {
                            height: "34px",
                            borderRadius: "3px",
                            paddingLeft: "10px",
                            textAlign: "center",
                            backgroundColor: row?.isDisabled
                              ? "var(--disabled-background)"
                              : "initial",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                          width: "153px",
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid
                item
                sx={{
                  width: {
                    xs: "50%",
                    sm: "50%",
                    md: "35%",
                    lg: "35%",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  m: "51px 0px 45px 0px",
                }}
              >
                {!isEdit &&
                  summaryFour.map((row, index) => {
                    return (
                      <Grid
                        key={index}
                        sx={{
                          width: {
                            xs: "100%",
                            lg: "100%",
                          },
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          maxHeight: "35px",
                          mb: "23px",
                          color: "textPrimary.main",
                        }}
                        className="row"
                      >
                        <Grid
                          sx={{
                            width: "40%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h4">{row.key}</Typography>
                          <Typography variant="h4">:</Typography>
                        </Grid>
                        {row?.isSelect ? (
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "153px",
                            }}
                          >
                            <Select
                              label=""
                              options={paymentTypeOptions}
                              placeholder="cash"
                              name="payment_method"
                              value={summary.payment_method}
                              onChange={(e) => handleInputChange(e)}
                              width="153px"
                              sx={{
                                ...styles.selectStyle,
                                height: "34px",
                                borderRadius: "5px",
                                boxShadow: "none",
                              }}
                            />
                          </Grid>
                        ) : (
                          <TextField
                            name={row.name}
                            disabled={row?.isDisabled}
                            value={row?.value}
                            onChange={(e) => handleInputChange(e)}
                            sx={{
                              ...styles.textFieldStyle,
                              width: "153px",
                              "& .MuiOutlinedInput-root": {
                                color: "red",
                                height: "34px",
                                borderRadius: "3px",
                                paddingLeft: "10px",
                                textAlign: "center",
                                backgroundColor: row?.isDisabled
                                  ? "var(--disabled-background)"
                                  : "initial",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: row?.isDisabled ? 0 : 1,
                              },
                            }}
                            error={row.isError}
                          />
                        )}
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid sx={{ m: "35px 0 45px 0" }}>
          <SaveButton
            loading={isSaveLoading}
            handleClick={handleSaveData}
            sx={{ width: "100px", height: "40px", mr: "20px" }}
            disabled={isSaveLoading}
          />
          <CancelButton
            sx={{ width: "100px", height: "40px" }}
            isDisabled={isSaveLoading}
          />
        </Grid>
      </Grid>
    );
  }
);

export default Summary;
