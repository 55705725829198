import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  debounce,
} from "@mui/material";
import {
  AutoComplete,
  CancelButton,
  SaveButton,
} from "../../../../../components/basic";
import { DeleteIcon } from "../../../../../assets/icons";
import {
  DataTable,
  ConfirmationDialog,
} from "../../../../../components/shared";
import { GridColDef } from "@mui/x-data-grid";
import { ipAdmissionConst } from "../../../../../constants/displayText";
import {
  getAdmissionIpPrescriptionsById,
  createAdmissionIpPrescriptionById,
  deleteAdmissionIpPrescriptionById,
  getAllRooms,
  getAllPxProductsList,
  getProductsList,
  getConsultantsList,
  getAllUnits,
} from "../../../../../services/admissionService";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../../../redux/slices/snackbar";
import { RootState } from "../../../../../redux/store";
import moment from "moment";
import Row from "./row";
import PermissionUtils from "../../../../../utils/PermissionUtils";
import { useDispatch, useSelector } from "react-redux";
import { setPrescriptionsList } from "../../../../../redux/slices/inpatients";

type PropsType = {
  ipAdmissionDetails?: any;
};

const Prescription = ({ ipAdmissionDetails }: PropsType) => {
  const styles = useMemo(
    () => ({
      textFieldStyle: {
        width: {
          xs: "200px",
          xl: "200px",
        },
        "& .MuiOutlinedInput-root": {
          height: "45px",
          borderRadius: "5px",
          paddingLeft: "10px",
        },
      },
      selectStyle: {
        width: {
          xs: "200px",
          xl: "200px",
        },
        height: "45px",
        boxShadow: "none",
        borderRadius: "5px",
      },
      autocompleteStyle: {
        width: {
          xs: "200px",
          xl: "200px",
        },
        height: "45px",
        "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
          padding: "5px 0px",
          boxShadow: "none",
          borderRadius: "5px",
        },
      },
      datePickerWithTimeStyle: {
        width: {
          xs: "200px",
          xl: "200px",
        },
        mr: "20px",
        "& .MuiOutlinedInput-root": {
          width: {
            xs: "200px",
            xl: "200px",
          },
          borderRadius: "5px",
          paddingLeft: "15px",
        },
      },
      datePickerGridStyle: {
        p: "0px 0px",

        "& .MuiGrid-root": {
          height: "45px",
          width: "100%",
        },
        "& .MuiFormControl-root": {
          height: "45px",
          width: "100%",
          position: "static",
        },
        "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container ": {
          height: "45px",
          width: "100%",
          border: 0,
          p: "0px",
        },

        "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container  >div >div .rmdp-input":
          {
            height: "35px",
            width: "95%",
            p: "0px 10px",
            border: 0,
            m: 0,
            borderWidth: "0px",
            borderRadius: 0,
          },

        "& .rmdp-wrapper": {
          position: "initial",
        },
      },
    }),
    []
  );

  const { prescriptionsList } = useSelector(
    (state: RootState) => state.inPatients
  );

  const { ADDED_SUCCESSFULLY, UPDATED_SUCCESSFULLY, DELETED_SUCCESSFULLY } =
    ipAdmissionConst;
  const { can } = PermissionUtils();

  const dispatch = useDispatch();

  // common variables

  const [admissionId, setAdmissionId] = useState<any>(ipAdmissionDetails?.id);
  const [idToBeDeleted, setIdToBeDeleted] = useState<any>(null);

  const { commonVariables, appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );

  // dialog and loader variables
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [
    isConfirmationDialogButtonLoading,
    setIsConfirmationDialogButtonLoading,
  ] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [isSaveButtonLoading, setIsSaveButtonLoading] =
    useState<boolean>(false);

  //field variables
  const [roomOptions, setRoomOptions] = useState<any>([]);
  const [itemOptions, setItemOptions] = useState<any>([]);
  const [consultantOptions, setConsultantOptions] = useState<any>([]);

  const [unitsOptions, setUnitsOptions] = useState<any>([]);
  const [freqOptions, setFreqOptions] = useState<any>([]);
  const [freqTimeTypeOptions, setFreqTimeTypeOptions] = useState<any>([]);
  const [durationTypeOptions, setDurationTypeOptions] = useState<any>([]);
  const [specificDaysOptions, setSpecificDaysOptions] = useState<any>([]);
  const [intervalOptions, setIntervalOptions] = useState<any>([]);
  const [isAutocompleteOptionsLoading, setIsAutocompleteOptionsLoading] =
    useState<boolean>(false);

  const durationTypes: any = {
    specific_days: "specific_days",
    interval_days: "interval_days",
    custom_date: "custom_date",
  };

  const initialFormData = {
    consultant: null,
    ward: {
      id: ipAdmissionDetails?.bed,
      name: `${ipAdmissionDetails?.floor_name} / ${ipAdmissionDetails?.room_no}`,
    },
    wardInput: `${ipAdmissionDetails?.floor_name} / ${ipAdmissionDetails?.room_no}`,
    wardRef: useRef(null),
    consultantInput: "",
    consultantRef: useRef(null),
    prescriptions: [
      {
        id: Date.now(),
        product_id: null,
        product_id_input: "",
        productRef: useRef(null),
        dosage: null,
        units: null,
        freq: null,
        freq_time: null,
        freq_time_type: null,
        duration_type: "",
        start_date: new Date(),
        duration: {
          specific_days: null, //["mon"] - array of strings
          custom_date: null, //"string"
          interval_days: null, //"3"
          duration_count: null, //"2" //duration time .//time-frontEnd
          interval: null, // "Days"  //duration days or weeks or months, //Days-frontEnd// eat medicines for 2days with the gap of 3 days (3 days once eat tablets for 2 days)
        },
      },
    ],
  };

  const [formData, setFormData] = useState<any>(initialFormData);

  const [validationErrors, setValidationErrors]: any = useState<any>({
    prescriptions: {},
  });

  const TableActions = ({ row }: any) => {
    return (
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        <IconButton
          onClick={() => {
            setIsConfirmationDialogOpen(true);
            setIdToBeDeleted(row?.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "sNo",
      flex: 0.4,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            S.No
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.sNo}
        </Typography>
      ),
      minWidth: 40,
      sortable: false,
    },
    {
      field: "doctor",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Consultant
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.doctor_name}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "particulars",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Particulars
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.product_name}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "date",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {moment(row?.date_created).format("DD/MM/YYYY hh:mm:ss A")}
        </Typography>
      ),
      minWidth: 160,
      sortable: false,
    },
    {
      field: "generatedBy",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Generated By
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.created_user}
        </Typography>
      ),
      minWidth: 160,
      sortable: false,
    },
    ...(can("ip_prescriptions_delete")
      ? [
          {
            field: "actions",
            flex: 0.5,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 50,
            sortable: false,
          },
        ]
      : []),
  ];

  // field onchange functions

  const handleInputChange = useCallback(
    (e: any, prescriptionId: number | null) => {
      const { name, value } = e.target;

      if (name === "ward" || name === "consultant") {
        setFormData((prev: any) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      } else {
        setFormData((prev: any) => {
          return {
            ...prev,
            prescriptions: prev?.prescriptions?.map((pres: any) => {
              if (pres.id === prescriptionId) {
                if (
                  name === "specific_days" ||
                  name === "interval_days" ||
                  name === "custom_date" ||
                  name === "duration_count" ||
                  name === "interval"
                ) {
                  return {
                    ...pres,
                    duration: {
                      ...pres.duration,
                      [name]: value,
                    },
                  };
                } else {
                  return {
                    ...pres,
                    [name]: value,
                  };
                }
              }
              return pres;
            }),
          };
        });
      }
    },
    []
  );

  const handleAutoCompleteChange = useCallback(
    (e: any, newValue: any, prescriptionId: any, name: string) => {
      if (name === "ward" || name === "consultant") {
        setFormData((prev: any) => ({
          ...prev,
          [name]: newValue,
        }));

        if (validationErrors[name]) {
          setValidationErrors((prev: any) => ({
            ...prev,
            [name]: "",
          }));
        }
      } else {
        setFormData((prev: any) => {
          return {
            ...prev,
            prescriptions: prev?.prescriptions?.map((pres: any) => {
              if (pres.id === prescriptionId) {
                return {
                  ...pres,
                  [name]: newValue,
                };
              }
              return pres;
            }),
          };
        });

        if (name === "product_id") {
          if (
            validationErrors?.prescriptions[prescriptionId] &&
            validationErrors?.prescriptions[prescriptionId][name]
          ) {
            setValidationErrors((prev: any) => ({
              ...prev,
              prescriptions: {
                ...prev?.prescriptions,
                [prescriptionId]: {
                  ...prev?.prescriptions[prescriptionId],
                  [name]: "",
                },
              },
            }));
          }
        }
      }
    },
    [validationErrors]
  );

  const handleAutoCompleteInputChange = useCallback(
    (
      e: any,
      newInputValue: any,
      prescriptionId: number | null,
      name: string
    ) => {
      if (name === "wardInput" || name === "consultantInput") {
        setFormData((prev: any) => ({
          ...prev,
          [name]: newInputValue,
        }));
      } else {
        setFormData((prev: any) => {
          return {
            ...prev,
            prescriptions: prev?.prescriptions?.map((pres: any) => {
              if (pres.id === prescriptionId) {
                return {
                  ...pres,
                  [name]: newInputValue,
                };
              }
              return pres;
            }),
          };
        });
      }
      if (name === "product_id_input") {
        getAllBillingItemsList(newInputValue);
      }
    },

    [validationErrors]
  );

  const handleDateChange = useCallback(
    (prescriptionId: number, newValue: any, name: any) => {
      setFormData((prev: any) => {
        return {
          ...prev,
          prescriptions: prev?.prescriptions?.map((prescription: any) => {
            if (prescription.id === prescriptionId) {
              if (name === "custom_date") {
                return {
                  ...prescription,
                  duration: {
                    ...prescription.duration,
                    [name]: newValue.validatedValue[0],
                  },
                };
              } else {
                return {
                  ...prescription,
                  [name]: newValue.validatedValue[0],
                };
              }
            }
            return prescription;
          }),
        };
      });
    },
    []
  );

  const handlePrescriptionAdd = useCallback(() => {
    setFormData((prev: any) => {
      return {
        ...prev,
        prescriptions: [
          ...prev.prescriptions,
          {
            id: Date.now(),
            product_id: null,
            product_id_input: "",
            productRef: React.createRef(),
            dosage: null,
            units: null,
            freq: null,
            freq_time: null,
            freq_time_type: null,
            duration_type: null,
            start_date: new Date(),
            duration: {
              specific_days: null,
              custom_date: null,
              interval_days: null,
              duration_count: null,
              interval: null,
            },
          },
        ],
      };
    });
  }, [validationErrors]);

  const handlePrescriptionDelete = useCallback(
    (id: any) => {
      setFormData((prev: any) => {
        return {
          ...prev,
          prescriptions: prev?.prescriptions?.filter(
            (prescription: any) => prescription.id !== id
          ),
        };
      });
    },
    [validationErrors]
  );

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  // api call functions to get select options

  const getCommonVariablesDetails = async () => {
    if (commonVariables) {
      setFreqOptions(commonVariables?.freq);
      setFreqTimeTypeOptions(commonVariables?.freq_time_type);
      setDurationTypeOptions(commonVariables?.duration_type);
      setSpecificDaysOptions(commonVariables?.specific_days);
      setIntervalOptions(commonVariables?.interval);
    }
  };

  const getAllUnitList = async () => {
    try {
      await getAllUnits().then((result: any) => {
        let data = result?.data?.result;

        setUnitsOptions(data);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAllRoomsList = async () => {
    try {
      await getAllRooms().then((result: any) => {
        let data = result?.data?.result;

        const modifiedData = data?.map((item: any) => {
          return {
            id: item?.id,
            name: `${item?.floor_name} / ${item?.room_no}`,
          };
        });

        setRoomOptions(modifiedData);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAllBillingItemsList = useCallback(
    debounce(async (search?: any) => {
      try {
        let pxProducts = appConfiguration?.px_products;

        if (pxProducts) {
          const data = {
            search: search,
            page: 1,
            size: "20",
          };
          setIsAutocompleteOptionsLoading(true);
          await getAllPxProductsList(data).then((result: any) => {
            let data = result?.data;

            const newArray = [...itemOptions, ...data];

            const updatedArray = newArray.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.id === value.id)
            );

            setItemOptions(updatedArray);
            setIsAutocompleteOptionsLoading(false);
          });
        } else {
          const data = {
            search: search,
            page: 1,
            size: "20",
          };
          setIsAutocompleteOptionsLoading(true);

          await getProductsList(data).then((result: any) => {
            let data = result?.data?.result;
            const newArray = [...itemOptions, ...data];

            const updatedArray = newArray.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.id === value.id)
            );

            setItemOptions(updatedArray);
            setIsAutocompleteOptionsLoading(false);
          });
        }
      } catch (error) {
        setIsAutocompleteOptionsLoading(false);
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  const getAllConsultantsList = async () => {
    try {
      await getConsultantsList().then((result: any) => {
        let data = result?.data;

        setConsultantOptions(data);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // crud operation api calls functions

  const getAdmissionIpPrescriptions = async () => {
    if (!admissionId) {
      return false;
    }
    try {
      setIsPageLoading(true);
      setIsDataTableLoading(true);
      await getAdmissionIpPrescriptionsById(admissionId).then((result: any) => {
        let data = result?.data;

        if (data) {
          const modifiedData = data?.map((item: any, index: number) => ({
            ...item,
            sNo: index + 1,
          }));

          dispatch(setPrescriptionsList(modifiedData));
        }
        setIsPageLoading(false);
        setIsDataTableLoading(false);
      });
    } catch (error) {
      setIsPageLoading(false);
      setIsDataTableLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const createAdmissionIpPrescriptions = async () => {
    const errors: any = { prescriptions: {} };

    const reversedArray = formData?.prescriptions?.slice().reverse();

    reversedArray?.forEach((data: any, index: any) => {
      const error: any = {};

      if (!data.product_id) {
        error.product_id = "*Product is required";
        if (data?.productRef?.current) {
          data?.productRef?.current?.focus();
        }
      }

      if (Object.keys(error).length > 0) {
        errors.prescriptions[data.id] = error;
      }
    });

    if (formData.consultant === null) {
      errors["consultant"] = "*Consultant is required field";
      if (formData?.consultantRef?.current) {
        formData?.consultantRef?.current?.focus();
      }
    }

    if (formData.ward === null) {
      errors["ward"] = "*Ward is required field";
      if (formData?.wardRef?.current) {
        formData?.wardRef?.current?.focus();
      }
    }

    if (Object?.keys(errors)?.length > 1) {
      setValidationErrors(errors);
      return;
    } else if (Object?.keys(errors?.prescriptions)?.length > 0) {
      setValidationErrors(errors);
      return;
    } else {
      try {
        setIsSaveButtonLoading(true);

        const prescriptions = formData?.prescriptions?.map((pres: any) => {
          return {
            product_id: pres?.product_id?.id,
            dosage: pres?.dosage,
            units: pres?.units,
            freq: pres?.freq,
            freq_time: pres?.freq_time,
            freq_time_type: pres?.freq_time_type,
            duration_type: pres?.duration_type,
            start_date: moment(pres?.start_date, ["DD/MM/YYYY"]).format(
              "DD/MM/YYYY"
            ),
            duration: {
              specific_days: pres?.duration?.specific_days,
              custom_date: pres?.duration?.custom_date,
              interval_days: pres?.duration?.interval_days,
              duration_count: pres?.duration?.duration_count,
              interval: String(pres?.duration?.interval),
            },
          };
        });

        const data = {
          ip_admission_id: admissionId,
          consultant_id: formData.consultant.id,
          ward_id: formData.ward.id ? formData.ward.id : formData.ward,
          prescriptions: prescriptions,
        };

        await createAdmissionIpPrescriptionById(data).then((result: any) => {
          resetFormData();
          setIsSaveButtonLoading(false);
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: ADDED_SUCCESSFULLY,
            })
          );
          getAdmissionIpPrescriptions();
        });
      } catch (error: any) {
        setIsSaveButtonLoading(false);
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
      }
    }
  };

  const handleDelete = async (id: any) => {
    try {
      setIsConfirmationDialogButtonLoading(true);
      await deleteAdmissionIpPrescriptionById(id).then((result: any) => {
        getAdmissionIpPrescriptions();

        dispatch(
          setSnackBarSuccess({
            snackBarMessage: DELETED_SUCCESSFULLY,
          })
        );

        setIsConfirmationDialogOpen(false);
        setIsConfirmationDialogButtonLoading(false);
        setIdToBeDeleted(null);
      });
    } catch (error) {
      setIsConfirmationDialogOpen(false);
      setIsConfirmationDialogButtonLoading(false);
      setIdToBeDeleted(null);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "something went wrong",
        })
      );
      console.error("An error occurred:", error);
    }
  };

  // dialog functions

  const onConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (idToBeDeleted === null) {
      return false;
    } else {
      handleDelete(idToBeDeleted);
    }
  };

  useEffect(() => {
    getAllRoomsList();
    getAllBillingItemsList();
    getAllConsultantsList();
    getAllUnitList();
  }, []);

  useEffect(() => {
    if (!prescriptionsList) {
      getAdmissionIpPrescriptions();
    }
  }, [admissionId]);

  useEffect(() => {
    getCommonVariablesDetails();
  }, [commonVariables, appConfiguration]);

  return isPageLoading ? (
    <Grid
      sx={{
        width: "92vw",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        height: "80vh",
        background: "transparent",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid sx={{ width: "auto" }}>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you surely want to delete?"
        handleClick={handleConfirmDelete}
        onClose={onConfirmationDialogClose}
        loading={isConfirmationDialogButtonLoading}
      />

      {can("ip_prescriptions_add") ? (
        <>
          <Grid container spacing={2} sx={{ width: "auto" }}>
            <Grid item xs={12}>
              <Typography variant="h2">Add Prescription</Typography>
            </Grid>
            <Grid
              sx={{
                width: "auto",
                display: "flex",
                flexDirection: "row",
                gap: 2,
                m: "40px 20px",
                overflowX: "scroll",
              }}
            >
              <AutoComplete
                label="Ward"
                placeholder="Select Ward"
                name="ward"
                value={formData.ward}
                inputValue={formData.wardInput}
                inputRef={formData?.wardRef}
                disableClearable={false}
                isValueAsObject={true}
                options={roomOptions}
                disabled={can("ip_prescriptions_ward_change") ? false : true}
                onChange={(e: any, newValue: any) =>
                  handleAutoCompleteChange(e, newValue, null, "ward")
                }
                onInputChange={(e: any, newValue: any) =>
                  handleAutoCompleteInputChange(e, newValue, null, "wardInput")
                }
                helperText={validationErrors["ward"]}
                width="275px"
                sx={{ ...styles.autocompleteStyle }}
              />
              <AutoComplete
                label="Consultant"
                placeholder="Select Consultant"
                name="consultant"
                value={formData.consultant}
                inputValue={formData.consultantInput}
                inputRef={formData?.consultantRef}
                disableClearable={false}
                isValueAsObject={true}
                options={consultantOptions}
                onChange={(e: any, newValue: any) =>
                  handleAutoCompleteChange(e, newValue, null, "consultant")
                }
                onInputChange={(e: any, newValue: any) =>
                  handleAutoCompleteInputChange(
                    e,
                    newValue,
                    null,
                    "consultantInput"
                  )
                }
                helperText={validationErrors["consultant"]}
                width="275px"
                sx={{ ...styles.autocompleteStyle }}
              />
              <Grid
                sx={{
                  width: "auto",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {formData?.prescriptions?.map(
                  (prescription: any, index: number) => {
                    return (
                      <Row
                        styles={styles}
                        index={index}
                        row={prescription}
                        handleInputChange={handleInputChange}
                        handleDateChange={handleDateChange}
                        handleAutoCompleteChange={handleAutoCompleteChange}
                        handleAutoCompleteInputChange={
                          handleAutoCompleteInputChange
                        }
                        handlePrescriptionDelete={handlePrescriptionDelete}
                        itemOptions={itemOptions}
                        unitsOptions={unitsOptions}
                        freqOptions={freqOptions}
                        freqTimeTypeOptions={freqTimeTypeOptions}
                        durationTypeOptions={durationTypeOptions}
                        specificDaysOptions={specificDaysOptions}
                        intervalOptions={intervalOptions}
                        isAutocompleteOptionsLoading={
                          isAutocompleteOptionsLoading
                        }
                        validationErrors={validationErrors}
                      />
                    );
                  }
                )}

                <Button
                  variant="text"
                  sx={{
                    width: "50px",
                    height: "50px",
                    textTransform: "none",
                    m: "10px 0px",
                  }}
                  onClick={handlePrescriptionAdd}
                >
                  <Typography variant="h4">+Add</Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid item sx={{ display: "flex", gap: "2", mt: 2 }}>
              <SaveButton
                buttonText={"Add"}
                loading={isSaveButtonLoading}
                handleClick={createAdmissionIpPrescriptions}
              />
              <CancelButton
                buttonText={"Reset"}
                handleClick={resetFormData}
                sx={{ ml: 3 }}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 3, color: "#D9D9D9" }} />
        </>
      ) : (
        <></>
      )}
      <Grid item sx={{ m: "20px 20px 50px 0px" }}>
        <Typography variant="h2" sx={{ m: "20px 0px" }}>
          Added Prescriptions
        </Typography>

        <Box>
          <DataTable
            loading={isDataTableLoading}
            columns={columns}
            rows={prescriptionsList || []}
            tableOnly={true}
            customizedTable={true}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Prescription;
