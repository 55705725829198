import { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { SaveButton, CancelButton, Button } from "../../../components/basic";
import PaymentsTabs from "./PaymentsTabs";
import PaymentsTable from "./PaymentsTable";
import PaymentsFooter from "./PaymentsFooter";
import {
  createAdvance,
  createPayments,
  getAdvanceAndExcess,
  getCardOptionDetails,
  getPatientsBills,
} from "../../../services/paymentService";
import { formula } from "../../../utils/CalculationUtils";
import { paymentOptions } from "../../../constants/dropdownOptions";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DeleteAlertIcon } from "../../../assets/icons";
import { RouteUrls } from "../../../constants/routes";
import { timeWithTwentyFourHoursConverter } from "../../../utils/DateTimeFormatUtils";
import { RootState } from "../../../redux/store";
import { ConfirmationDialog } from "../../../components/shared";

const { calculateDiscountAmount, calculateAmountDue, calculateConvenienceFee } =
  formula.payments;
const { payments, paymentsListUrl } = RouteUrls;

const Payments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const patient = searchParams.get("patient");
  const patientName = searchParams.get("patient_name");
  const payee = searchParams.get("payee");
  const billNo = searchParams.get("bill_no");
  const type = searchParams.get("type");
  const subType = searchParams.get("sub_type");
  const isAdvance = searchParams.get("is_advance");

  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [tabValue, setTabValue] = useState("1");
  const initialData: any = {
    patient_id: "",
    payee_id: 1,
    payment_type: "Cash",
    urn_number: "",
    cash_received: "",
    used_amount: 0,
    excess_amount: 0,
    refunded_excess: 0,
    net_excess: 0,
    convenience_fee: 0,
    pay_term_id: null,
    date_created: new Date(),
    is_pay_full_amount: false,
    payments: [],
    patient_name: "",
    bill_id: "",
    bill_type: "",
    bill_sub_type: "",
  };

  const [data, setData] = useState(initialData);
  const [advanceData, setAdvanceData] = useState<{
    patient_id: string | number;
    payee_id: string | number;
    cash_received: string | number;
    date_created: any;
    payment_type: "Cash";
    urn_number: string;
    convenience_fee: number;
    pay_term_id: string | number | null;
  }>({
    patient_id: "",
    payee_id: 1,
    cash_received: "",
    date_created: new Date(),
    payment_type: "Cash",
    urn_number: "",
    convenience_fee: 0,
    pay_term_id: null,
  });
  const [totals, setTotals] = useState<any>({
    totalBillAmount: 0,
    totalAmountDue: 0,
    totalPaidAmount: 0,
    totalDiscount: 0,
    totalTds: 0,
  });
  const [errors, setErrors] = useState<any[]>([]);
  const [cashReceivedError, setCashReceivedError] = useState(false);
  const [refundedError, setRefundedError] = useState(false);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState<
    { id: number | string; name: string | number }[]
  >([]);
  const [cardDetails, setCardDetails] = useState([]);
  const [excessAmount, setExcessAmount] = useState({
    excess: 0,
    advance: 0,
  });
  const [loading, setLoading] = useState(false);
  const [isPatientBillsLoading, setIsPatientBillsLoading] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<{
    isOpen: boolean;
    content: string;
  }>({
    isOpen: false,
    content: "",
  });
  const [patientList, setPatientsList] = useState<any>([]);
  const [isOpenReloadDialog, setIsOpenReloadDialog] = useState<boolean>(false);

  const calCulatePreviousTotalAmount = (data: any, index: number) => {
    let cashReceived = Number(data.cash_received) || 0;

    if (data?.payments?.length > 0) {
      const bills = data?.payments?.slice(0, index);

      bills?.forEach((ele: any) => {
        cashReceived -= Number(ele.paid_amt) || 0;
      });
    }

    return cashReceived;
  };

  const tableCalculation = (
    row: any,
    index: number,
    data: any,
    name: string
  ) => {
    row.discount_amount = calculateDiscountAmount(row);
    row.balance = calculateAmountDue(row);

    // if (row.payInFull) {
    //   row.paid_amt = row.balance;
    // }

    if (row?.balance < 0) {
      setErrors((prev) => {
        const newErrors = [...prev];
        newErrors[index].paid_amt = row.paid_amt ? "required field" : "";
        newErrors[index].discount_value = row.discount_amount
          ? "required field"
          : "";
        newErrors[index].tds = row.tds ? "required field" : "";
        return newErrors;
      });
    } else {
      setErrors((prev) => {
        const newErrors = [...prev];
        newErrors[index].paid_amt =
          Number(row?.paid_amt) > Number(row?.balance) ? "required field" : "";
        newErrors[index].discount_value = "";
        newErrors[index].tds = "";

        if (name === "paid_amt") {
          const previousAmount = calCulatePreviousTotalAmount(data, index);

          if (
            row.paid_amt &&
            // !newErrors[index]?.paid_amt &&
            previousAmount < row.paid_amt
          ) {
            newErrors[index].paid_amt = "Required field";
          } else if (Number(row?.paid_amt) > Number(row?.balance)) {
            newErrors[index].paid_amt = "Required field";
          } else {
            newErrors[index].paid_amt = "";
          }
        }
        return newErrors;
      });
    }

    return row;
  };

  const handleInfoChange = (e: any) => {
    const { name, value } = e.target;

    setData((prevFilterData: any) => {
      const prevState = { ...prevFilterData };

      prevState[name] = value;

      if (
        name === "cash_received" &&
        Number(totals.totalPaidAmount) > Number(value)
      ) {
        setCashReceivedError(true);
        const newErrors = [...errors];
        let amount = prevState.cash_received;
        prevState?.payments?.forEach((ele: any, i: number) => {
          if (
            ele.paid_amt &&
            !newErrors[i]?.paid_amt &&
            Number(amount) < Number(ele.paid_amt)
          ) {
            newErrors[i].paid_amt = "Required field";
          } else if (ele.paid_amt && ele.amount_due < Number(ele.paid_amt)) {
            newErrors[i].paid_amt = "Required field";
          } else {
            amount -= ele.paid_amt;
          }
        });
        setErrors(newErrors);
      } else if (
        name === "cash_received" &&
        cardDetails?.findIndex(
          (ele: any) => ele.name === prevState.payment_type
        ) !== -1
      ) {
        const newCardDetails = cardDetails?.find(
          (ele: any) => ele.name === prevState.payment_type
        ) as any;
        prevState.convenience_fee = calculateConvenienceFee(
          prevState,
          newCardDetails?.convenience_fee_percentage
        );
        if (name === "cash_received" && cashReceivedError) {
          setCashReceivedError(false);
        }
        const newErrors = [...errors];
        let amount = prevState.cash_received;
        prevState?.payments?.forEach((ele: any, i: number) => {
          if (ele.paid_amt && Number(amount) < Number(ele.paid_amt)) {
            newErrors[i].paid_amt = "Required field";
          } else if (
            ele.paid_amt &&
            Number(ele.amount_due) < Number(ele.paid_amt)
          ) {
            newErrors[i].paid_amt = "Required field";
          } else {
            newErrors[i].paid_amt = "";
            amount -= ele.paid_amt;
          }
        });
        setErrors(newErrors);
      } else if (name === "cash_received") {
        const newErrors = [...errors];
        let amount = prevState.cash_received;
        prevState?.payments?.forEach((ele: any, i: number) => {
          if (ele.paid_amt && Number(amount) < Number(ele.paid_amt)) {
            newErrors[i].paid_amt = "Required field";
          } else if (
            ele.paid_amt &&
            Number(ele.amount_due) < Number(ele.paid_amt)
          ) {
            newErrors[i].paid_amt = "Required field";
          } else {
            newErrors[i].paid_amt = "";
            amount -= ele.paid_amt;
          }
        });
        setErrors(newErrors);
        if (name === "cash_received" && cashReceivedError) {
          setCashReceivedError(false);
        }
      }

      if (name === "is_pay_full_amount" && value) {
        let payments = prevState?.payments;
        if (prevState.cash_received) {
          let cash = prevState.cash_received;

          const newErrors = [...errors];
          payments = prevState?.payments?.map((bill: any, ind: number) => {
            if (cash > 0 && bill.balance) {
              bill.paid_amt = cash - bill.balance > 0 ? bill.balance : cash;
              cash -= bill.paid_amt;
              newErrors[ind].paid_amt = "";
            } else if (cash === 0 && bill.paid_amt) {
              bill.paid_amt = 0;
              newErrors[ind].paid_amt = "";
            }
            return {
              ...bill,
            };
          });

          setErrors(newErrors);
          setCashReceivedError(false);
        } else {
          let cash = 0;
          payments = prevState?.payments?.map((bill: any, ind: number) => {
            bill.paid_amt = bill.balance;
            bill.payInFull = true;
            cash += Number(bill.paid_amt);
            return {
              ...bill,
            };
          });

          prevState.cash_received = cash;
          setCashReceivedError(false);
          setErrors((prevState: any) => prevState.map(() => ({})));
        }

        const {
          totalBillAmount,
          totalAmountDue,
          totalPaidAmount,
          totalDiscount,
          totalTds,
        } = payments?.reduce(
          (totals: any, bill: any) => {
            totals.totalBillAmount += parseFloat(bill.net_total_amt) || 0;
            totals.totalAmountDue += parseFloat(bill.balance) || 0;
            totals.totalPaidAmount += parseFloat(bill.paid_amt) || 0;
            totals.totalDiscount += parseFloat(bill.discount_amount) || 0;
            totals.totalTds += parseFloat(bill.tds) || 0;
            return totals;
          },
          {
            totalBillAmount: 0,
            totalAmountDue: 0,
            totalPaidAmount: 0,
            totalDiscount: 0,
            totalTds: 0,
          }
        );

        setTotals({
          totalBillAmount: Number(totalBillAmount)?.toFixed(2),
          totalAmountDue: Number(totalAmountDue)?.toFixed(2),
          totalPaidAmount: Number(totalPaidAmount)?.toFixed(2),
          totalDiscount: Number(totalDiscount)?.toFixed(2),
          totalTds: Number(totalTds)?.toFixed(2),
        });
      } else if (name === "is_pay_full_amount" && !value) {
        // const newPatientBills = [...prevState.payments];
        // prevState.payments = newPatientBills?.map((ele) => ({
        //   ...ele,
        //   paid_amt: 0,
        // }));
      }

      if (
        name === "payment_type" &&
        cardDetails?.findIndex((ele: any) => ele.name === value) !== -1 &&
        prevState?.cash_received
      ) {
        const newCardDetails = cardDetails?.find(
          (ele: any) => ele.name === value
        ) as any;
        prevState.convenience_fee = calculateConvenienceFee(
          prevState,
          newCardDetails?.convenience_fee_percentage || 0
        );
      } else if (name === "payment_type" && value !== "Card") {
        prevState.convenience_fee = 0;
      }

      if (
        name === "payment_type" &&
        (value === "Advance" || value === "Excess")
      ) {
        prevState.cash_received =
          excessAmount[value === "Advance" ? "advance" : "excess"];
        prevState.is_pay_full_amount = false;
        const newErrors = [...errors];
        let amount = prevState.cash_received;
        prevState?.payments?.forEach((ele: any, i: number) => {
          if (
            ele.paid_amt &&
            !newErrors[i]?.paid_amt &&
            Number(amount) < Number(ele.paid_amt)
          ) {
            newErrors[i].paid_amt = "Required field";
          } else if (
            ele.paid_amt &&
            Number(ele.amount_due) < Number(ele.paid_amt)
          ) {
            newErrors[i].paid_amt = "Required field";
          } else {
            amount -= ele.paid_amt;
          }
        });

        setErrors(newErrors);
      }

      prevState.used_amount = prevState?.payments?.reduce(
        (total: number, bill: any) => {
          return total + (parseFloat(bill.paid_amt) || 0);
        },
        0
      );
      prevState.excess_amount =
        (prevState.cash_received || 0) - prevState.used_amount;

      if (!appConfiguration?.direct_excess && name !== "refunded_excess") {
        prevState.refunded_excess =
          (prevState.cash_received || 0) - prevState.used_amount;
      }

      if (name === "refunded_excess") {
        if (
          Number(prevState.refunded_excess) > Number(prevState.excess_amount)
        ) {
          setRefundedError(true);
        } else {
          setRefundedError(false);
        }
      }

      if (patient && name === "patient_id") {
        prevState.bill_id = "";
      }

      if (name === "patient") {
        prevState.payments = [];
      }
      const net_excess =
        (prevState.cash_received || 0) -
        prevState.used_amount -
        (prevState.refunded_excess || 0);
      prevState.net_excess = net_excess > 0 ? net_excess : 0;
      return prevState;
    });
  };

  const handleAdvanceInfoChange = (event: any) => {
    const { name, value } = event.target;
    setAdvanceData((prevState: any) => {
      const newState = { ...prevState };
      newState[name] = value;

      if (
        name === "cash_received" &&
        cardDetails?.findIndex(
          (ele: any) => ele.name === newState.payment_type
        ) !== -1
      ) {
        const newCardDetails = cardDetails?.find(
          (ele: any) => ele.name === newState.payment_type
        ) as any;
        newState.convenience_fee = calculateConvenienceFee(
          newState,
          newCardDetails?.convenience_fee_percentage
        );
      }

      if (
        name === "payment_type" &&
        cardDetails?.findIndex((ele: any) => ele.name === value) !== -1 &&
        newState?.cash_received
      ) {
        const newCardDetails = cardDetails?.find(
          (ele: any) => ele.name === value
        ) as any;
        newState.convenience_fee = calculateConvenienceFee(
          newState,
          newCardDetails?.convenience_fee_percentage
        );
      } else if (name === "payment_type" && value !== "Card") {
        newState.convenience_fee = 0;
      }

      return newState;
    });
  };

  const setRowPaidAmount = (data: any, row: any, index: number) => {
    const totalCashPaidAmount = data?.payments?.reduce(
      (total: number, bill: any, i: number) => {
        if (i === index) {
          return total;
        }

        return total + (parseFloat(bill.paid_amt) || 0);
      },
      0
    );

    const balance = data.cash_received
      ? data.cash_received > totalCashPaidAmount
        ? Number(data.cash_received) - Number(row.balance) > 0
          ? row.balance
          : Number(data.cash_received) - totalCashPaidAmount
        : 0
      : row?.balance;
    return balance;
  };

  const handleRowChange = useCallback((e: any, index: number) => {
    const { name, value } = e.target;

    setData((prevState: any) => {
      const newRows = prevState?.payments?.map((row: any, i: number) => {
        if (i === index) {
          let newRow = { ...row, [name]: value };

          if (name === "paid_amt" && newRow.payInFull) {
            newRow.payInFull = false;
          }
          if (
            name === "discount_value" ||
            name === "discount_type" ||
            name === "tds" ||
            name === "paid_amt"
          ) {
            const newData = { ...prevState };
            if (newData?.payments[index]) {
              newData.payments[index][name] = value;
            }
            const newState = tableCalculation(newRow, index, newData, name);

            // if (name === "paid_amt") {
            //   const rows = [...prevState?.payments];
            //   rows[index][name] = value;

            //   const newErrors = [...errors];
            //   let amount = prevState.cash_received;
            //   rows?.forEach((ele: any, i: number) => {
            //     if (ele.paid_amt && amount < Number(ele.paid_amt)) {
            //       newErrors[i].paid_amt = "Required field";
            //       amount -= Number(ele.paid_amt);
            //     } else {
            //       amount -= Number(ele.paid_amt) || 0;
            //       newErrors[i].paid_amt = "";
            //     }
            //   });
            //   setErrors(newErrors);
            // }

            return newState;
          }

          if (name === "payInFull" && value) {
            newRow.paid_amt = setRowPaidAmount(data, newRow, index);
            if (errors[index]?.paid_amt) {
              setErrors((prev) => {
                const newErrors = [...prev];
                if (newErrors[index].paid_amt) {
                  newErrors[index].paid_amt = "";
                }
                return newErrors;
              });
            }
          }

          if (
            (name === "payInFull" || name === "paid_amt") &&
            !data.cash_received
          ) {
            setCashReceivedError(true);
          }

          setErrors((prev) => {
            const newErrors = [...prev];
            if (newErrors[index][name] && value) {
              newErrors[index][name] = "";
            }
            return newErrors;
          });
          return newRow;
        }
        return row;
      });

      if (
        name === "discount_value" ||
        name === "discount_type" ||
        name === "tds" ||
        name === "paid_amt" ||
        (name === "payInFull" && value)
      ) {
        const {
          totalBillAmount,
          totalAmountDue,
          totalPaidAmount,
          totalDiscount,
          totalTds,
        } = newRows?.reduce(
          (totals: any, bill: any) => {
            totals.totalBillAmount += parseFloat(bill.net_total_amt) || 0;
            totals.totalAmountDue += parseFloat(bill.balance) || 0;
            totals.totalPaidAmount += parseFloat(bill.paid_amt) || 0;
            totals.totalDiscount += parseFloat(bill.discount_amount) || 0;
            totals.totalTds += parseFloat(bill.tds) || 0;
            return totals;
          },
          {
            totalBillAmount: 0,
            totalAmountDue: 0,
            totalPaidAmount: 0,
            totalDiscount: 0,
            totalTds: 0,
          }
        );

        prevState.used_amount = totalPaidAmount;
        prevState.excess_amount =
          (prevState.cash_received || 0) - totalPaidAmount;
        if (!appConfiguration?.direct_excess && name !== "refunded_excess") {
          prevState.refunded_excess =
            (prevState.cash_received || 0) - totalPaidAmount;
        }

        if (name === "refunded_excess") {
          if (
            Number(prevState.refunded_excess) > Number(prevState.excess_amount)
          ) {
            setRefundedError(true);
          } else {
            setRefundedError(false);
          }
        }
        prevState.net_excess =
          (prevState.cash_received || 0) -
          totalPaidAmount -
          (prevState.refunded_excess || 0);

        setTotals({
          totalBillAmount: Number(totalBillAmount)?.toFixed(2),
          totalAmountDue: Number(totalAmountDue)?.toFixed(2),
          totalPaidAmount: Number(totalPaidAmount)?.toFixed(2),
          totalDiscount: Number(totalDiscount)?.toFixed(2),
          totalTds: Number(totalTds)?.toFixed(2),
        });
      }
      return { ...prevState, payments: newRows };
    });
  }, []);

  const getPatientBillDetails = () => {
    setIsPatientBillsLoading(true);
    getPatientsBills({
      patient_id: data.patient_id,
      payee_id: data.payee_id,
      bill_id: data?.bill_id,
      type: data?.bill_type,
    })
      .then((res: any) => {
        if (res.data) {
          setData((prevState: any) => ({
            ...prevState,
            payments: res?.data?.map((ele: any) => ({
              ...ele,
              paid_amt: 0,
              remarks: "",
              discount_value: 0,
              discount_type: "%",
              discount_amount: 0,
              tds: 0,
              amount_due: ele.balance,
            })),
          }));
          const {
            totalBillAmount,
            totalAmountDue,
            totalPaidAmount,
            totalDiscount,
            totalTds,
          } = res?.data?.reduce(
            (totals: any, bill: any) => {
              totals.totalBillAmount += parseFloat(bill.net_total_amt) || 0;
              totals.totalAmountDue += parseFloat(bill.balance) || 0;
              totals.totalDiscount += parseFloat(bill.discount_amount) || 0;
              totals.totalPaidAmount += parseFloat(bill.paid_amt) || 0;
              totals.totalTds += parseFloat(bill.tds) || 0;
              return totals;
            },
            {
              totalBillAmount: 0,
              totalAmountDue: 0,
              totalDiscount: 0,
              totalTds: 0,
              totalPaidAmount: 0,
            }
          );

          setTotals((prevState: any) => ({
            ...prevState,
            totalBillAmount: Number(totalBillAmount)?.toFixed(2),
            totalAmountDue: Number(totalAmountDue)?.toFixed(2),
            totalDiscount: Number(totalDiscount)?.toFixed(2),
            totalTds: Number(totalTds)?.toFixed(2),
            totalPaidAmount: Number(totalPaidAmount)?.toFixed(2),
          }));

          setErrors(res?.data?.map(() => ({})));
        }
        setIsPatientBillsLoading(false);
      })
      .catch((err: any) => {
        setIsPatientBillsLoading(false);
      });
  };
  useEffect(() => {
    if (data.patient_id || (data.payee_id && data.payee_id !== 1)) {
      getPatientBillDetails();
    }
  }, [data.patient_id, data.payee_id]);

  useEffect(() => {
    getCardOptionDetails()
      .then((res: any) => {
        if (res.data) {
          const newTypeOptions = paymentOptions.map(
            (option: { id: number | string; name: string | number }) => {
              const newOption = { ...option } as any;

              if (option.id === "Advance" || option.id === "Excess") {
                newOption.disabled = !Boolean(
                  excessAmount[option.id === "Advance" ? "advance" : "excess"]
                );
              }
              return newOption;
            }
          );
          const extraOptions = res?.data?.map(
            (ele: { id: number | string; name: string | number }) => ({
              ...ele,
              id: ele.name,
            })
          );
          setPaymentTypeOptions([...newTypeOptions, ...extraOptions]);
          setCardDetails(res.data);
        }
      })
      .catch((err: any) => console.log(err));

    if (
      patient &&
      patientName &&
      payee &&
      billNo &&
      type &&
      subType &&
      !isAdvance
    ) {
      setData((prevState: any) => ({
        ...prevState,
        patient_id: Number(patient),
        payee_id: Number(payee),
        patient_name: patientName,
        bill_id: Number(billNo),
        bill_type: type,
        bill_sub_type: subType,
      }));
      setPatientsList([{ value: Number(patient), label: patientName }]);
    } else if (isAdvance && patient && patientName) {
      setAdvanceData((prevState: any) => ({
        ...prevState,
        patient_id: Number(patient),
        payee_id: 1,
      }));
      setPatientsList([{ value: Number(patient), label: patientName }]);
      setTabValue("2");
    }
  }, []);

  const getPatientAdvanceAndExcess = () => {
    getAdvanceAndExcess({
      patient_id: data.patient_id,
      payee_id: data.payee_id,
    })
      .then((res: any) => {
        if (res.data) {
          setExcessAmount(res.data);
          setPaymentTypeOptions((prevState: any) => {
            const newTypeOptions = prevState.map(
              (option: { id: number | string; name: string | number }) => {
                const newOption = { ...option } as any;

                if (option.id === "Advance" || option.id === "Excess") {
                  newOption.disabled = !Boolean(
                    res?.data[option.id === "Advance" ? "advance" : "excess"]
                  );
                }
                return newOption;
              }
            );

            return newTypeOptions;
          });
        }
      })
      .catch((err: any) => console.log(err));
  };
  useEffect(() => {
    if (data?.patient_id && data?.payee_id) {
      getPatientAdvanceAndExcess();
    }
  }, [data.patient_id, data?.payee_id]);

  const handleValidate = () => {
    if (cashReceivedError) {
      return false;
    }

    if (data.refunded_excess > data.excess_amount) {
      setIsOpenErrorModal({
        isOpen: true,
        content: "The refunded amount cannot exceed the excess amount",
      });
      return false;
    }
    let productsHasError = true;

    if (errors?.length > 0) {
      errors?.forEach((item: any) => {
        if (Object.values(item)?.filter((ele) => Boolean(ele))?.length > 0) {
          productsHasError = false;
        }
      });
    }

    let hasOnePayment = false;
    data.payments?.forEach((ele: any) => {
      if (Number(ele.paid_amt) > 0) {
        hasOnePayment = true;
      }
    });

    if (!hasOnePayment) {
      setIsOpenErrorModal({
        isOpen: true,
        content: "You must pay at least one bill to proceed",
      });
      return false;
    }

    return productsHasError;
  };

  const handleSave = () => {
    if (handleValidate()) {
      setLoading(true);
      const newCardDetails = cardDetails?.find(
        (ele: any) => ele.name === data.payment_type
      ) as any;
      createPayments({
        ...data,
        refunded_excess: data.refunded_excess || 0,
        date_created: data?.date_created
          ? timeWithTwentyFourHoursConverter(data?.date_created)
          : null,
        pay_term_id: newCardDetails?.id || null,
      })
        .then((res: any) => {
          if (res) {
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          }
          setLoading(false);
          navigate(`${payments}${paymentsListUrl}`);
        })
        .catch((err: any) => {
          console.log(err);
          if (err?.response?.data?.errors) {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.response?.data?.errors,
              })
            );
          }
          if (err?.response?.data?.error_data) {
            if (err?.response?.data?.error_data?.type === "paid") {
              getPatientBillDetails();
            } else if (
              err?.response?.data?.error_data?.type &&
              err?.response?.data?.error_data?.balance
            ) {
              setExcessAmount((prevState: any) => ({
                ...prevState,
                [err?.response?.data?.error_data?.type === "Advance"
                  ? "advance"
                  : "excess"]: err?.response?.data?.error_data?.balance,
              }));
            }
          }
          setLoading(false);
        });
    }
  };

  const handleSaveAdvance = () => {
    setLoading(true);
    const newCardDetails = cardDetails?.find(
      (ele: any) => ele.name === advanceData.payment_type
    ) as any;
    createAdvance({
      ...advanceData,
      pay_term_id: newCardDetails?.id || null,
      date_created: advanceData?.date_created
        ? timeWithTwentyFourHoursConverter(advanceData?.date_created)
        : null,
    })
      .then((res: any) => {
        if (res) {
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        }
        setLoading(false);
        navigate(`${payments}${paymentsListUrl}`);
      })
      .catch((err: any) => {
        console.log(err);
        if (err?.response?.data?.errors) {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: err?.response?.data?.errors,
            })
          );
        }
        setLoading(false);
      });
  };

  return (
    <Grid className="payment">
      <PaymentsTabs
        tabValue={setTabValue}
        data={data}
        advanceData={advanceData}
        onChange={handleInfoChange}
        onAdvanceChange={handleAdvanceInfoChange}
        cashReceivedError={cashReceivedError}
        paymentTypeOptions={paymentTypeOptions}
        excessAmount={excessAmount}
        initialPatientList={patientList}
        isAdvance={isAdvance}
      />
      {((tabValue === "1" && data.patient_id) ||
        (tabValue === "1" && data.payee_id && data.payee_id !== 1)) && (
        <PaymentsTable
          data={data}
          isPatientBillsLoading={isPatientBillsLoading}
          totals={totals}
          onChange={handleRowChange}
          errors={errors}
        />
      )}
      {((tabValue === "1" && data.patient_id) ||
        (tabValue === "1" && data.payee_id && data.payee_id !== 1)) && (
        <PaymentsFooter
          data={data}
          onChange={handleInfoChange}
          refundedError={refundedError}
        />
      )}
      {tabValue === "1" ? (
        <Grid sx={{ m: "35px 0 45px 0" }}>
          <SaveButton
            sx={{ width: "100px", height: "40px", mr: "20px" }}
            loading={loading}
            disabled={
              data?.payments?.length === 0 ||
              !Boolean(totals?.totalPaidAmount) ||
              cashReceivedError
            }
            handleClick={handleSave}
          />
          <CancelButton
            sx={{ width: "100px", height: "40px" }}
            handleClick={() => navigate(`${payments}${paymentsListUrl}`)}
            isDisabled={loading}
          />
        </Grid>
      ) : (
        <Grid sx={{ m: "35px 0 45px 0" }}>
          <SaveButton
            sx={{ width: "100px", height: "40px", mr: "20px" }}
            loading={loading}
            disabled={
              !advanceData?.cash_received ||
              (advanceData?.payee_id === 1 && !advanceData?.patient_id)
            }
            handleClick={handleSaveAdvance}
          />
          <CancelButton
            sx={{ width: "100px", height: "40px" }}
            handleClick={() => navigate(`${payments}${paymentsListUrl}`)}
            isDisabled={loading}
          />
        </Grid>
      )}
      <ConfirmationDialog
        open={isOpenReloadDialog}
        title={"The Payment or Paid amount already used?"}
        confirmText="Okay"
        handleClick={() => {
          // window.location.reload()
          setIsOpenReloadDialog(false);
        }}
        onClose={() => setIsOpenReloadDialog(false)}
        // loading={isConfirmationDialogButtonLoading}
      />
      <ConfirmationDialog
        open={isOpenErrorModal.isOpen}
        title={isOpenErrorModal.content}
        handleClick={() => setIsOpenErrorModal({ isOpen: false, content: "" })}
        onClose={() => setIsOpenErrorModal({ isOpen: false, content: "" })}
        // loading={isConfirmationDialogButtonLoading}
      />
      {/* <Dialog
        open={isOpenErrorModal.isOpen}
        keepMounted
        onClose={() => setIsOpenErrorModal({ isOpen: false, content: "" })}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root": {
            position: "absolute",
            top: "0px",
            height: "145px",
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            p: "10px 20px",
            borderRadius: "10px",
          }}
        >
          <DeleteAlertIcon width="19px" height="19px" />
          <DialogTitle id="alert-dialog-title" sx={{ p: "20px 24px" }}>
            {isOpenErrorModal.content}
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            buttonText="Okay"
            sx={{
              borderRadius: "0px",
              width: "75px",
              height: "35px",
              fontWeight: 400,
              fontSize: "13px",
              border: 0,
              color: "#fff",
              "&:hover": {
                color: "#fff",
              },
              "&.Mui-focusVisible": {
                border: 1,
                borderColor: "primary.main",
                borderRadius: "5px",
              },
            }}
            handleClick={() =>
              setIsOpenErrorModal({ isOpen: false, content: "" })
            }
          />
        </DialogActions>
      </Dialog> */}
    </Grid>
  );
};

export default Payments;
