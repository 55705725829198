import { useCallback, useEffect, useState } from "react";
import { Typography, Box, IconButton, Grid, debounce } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteIcon, EditIcon, PlusIcon, ViewIcon } from "../../../../assets/icons";
import CustomFilter from "../../../../components/shared/customFilter";
import { Sort, TextField, DatePicker } from "../../../../components/basic";
import { DataTable } from "../../../../components/shared";
import { RouteUrls } from "../../../../constants/routes";
import { RootState } from "../../../../redux/store";
import { getAllIndents } from "../../../../services/procurementService";
import { setSnackBarFailed } from "../../../../redux/slices/snackbar";
import { errorMessage } from "../../../../constants/displayText";
import moment from "moment";
import { commonDateFormatter } from "../../../../utils/DateTimeFormatUtils";
import { Can } from "../../../../utils/PermissionUtils";
import {
  setCurrentPage,
  setFilterDataValues,
  setHandleClearSearch,
  setSortField,
} from "../../../../redux/slices/pagination";

type GridRowData = Record<string, unknown>;

const IntentList = () => {
  const { procurementUrl, indentsListUrl, createUrl, editUrl, viewUrl } =
    RouteUrls;

  const style = {
    selectStyle: {
      width: {
        xs: "90vw",
        sm: "45vw",
        md: "30vw",
        lg: "13vw",
        xl: "13vw",
      },
      height: "45px",
      boxShadow: "none",
      borderRadius: "5px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
    DatePickerStyle: {
      "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container >div >div .rmdp-input":
        {
          boxSizing: "border-box",
          width: {
            xs: "90vw",
            sm: "45vw",
            md: "30vw",
            lg: "13vw",
            xl: "13vw",
          },
        },
    },
    textFieldStyle: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "45px",
        borderRadius: "5px",
        paddingLeft: "8px",
        boxShadow: "none",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
  };
  const { pageInfo, filterData, count, sortField } = useSelector(
    (state: RootState) => ({
      pageInfo: state?.pagination?.procurementIndentList,
      filterData: state?.pagination?.procurementIndentList?.filterData,
      count: state?.pagination?.procurementIndentList?.count,
      sortField: state?.pagination?.procurementIndentList?.sortedField,
    })
  );

  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const dispatch = useDispatch();

  const [intentsList, setIntentsList] = useState<[]>([]);

  const initialFilterData = {
    indent_no: "",
    from_date: new Date(),
    to_date: new Date(),
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [ascendingOrderClicked, setAscendingOrderClicked] =
    useState<boolean>(false);
  const [descendingOrderClicked, setDescendingOrderClicked] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {Can("indents_edit") && (
          <IconButton
            onClick={() => {
              navigate(
                `${procurementUrl}${indentsListUrl}${editUrl}/${row.id}`
              );
            }}
          >
            <EditIcon />
          </IconButton>
        )}
        {Can("indents_view") && (
          <IconButton
            aria-label=""
            onClick={() => {
              navigate(
                `${procurementUrl}${indentsListUrl}${viewUrl}/${row.id}`
              );
            }}
          >
            <ViewIcon />
          </IconButton>
        )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "indentNo",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("indent_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Indent No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "indent_no" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "indent_no" && sortField?.order === "DESC"
                ? true
                : false
            }
            ascendingOnClick={() =>
              setAscendingOrderClicked(!descendingOrderClicked)
            }
            descendingOnClick={() =>
              setDescendingOrderClicked(!ascendingOrderClicked)
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.indent_no}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "date",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("created_at")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "created_at" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "created_at" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {moment(row?.created_at).format("DD/MM/YYYY hh:mm:ss A")}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "user",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("created_user")}
        >
          <Typography variant="h5" fontSize={14}>
            User
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "created_user" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "created_user" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.created_user}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    ...(Can("indents_edit") || Can("indents_view")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortField?.field === field) {
      dispatch(
        setSortField({
          key: "procurementIndentList",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
    if (sortField?.field !== field) {
      dispatch(
        setSortField({
          key: "procurementIndentList",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
  };

  const clearFilters = () => {
    if (filterData.indent_no || filterData.from_date || filterData.to_date) {
      dispatch(
        setFilterDataValues({
          key: "procurementIndentList",
          name: "clearFilter",
          value: initialFilterData,
        })
      );
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      const { name, value } = e.target;
      dispatch(
        setFilterDataValues({
          key: "procurementIndentList",
          name: name,
          value: value,
        })
      );
      dispatch(setCurrentPage({ key: "procurementIndentList", value: 0 }));
    }
  };

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      dispatch(
        setFilterDataValues({
          key: "procurementIndentList",
          name: name,
          value: newValue.validatedValue[0],
        })
      );
      dispatch(setCurrentPage({ key: "procurementIndentList", value: 0 }));
    }
  };

  const appointmentFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData.indent_no}
          placeholder={"Enter Indent No"}
          label="Indent No"
          formControlStyle={{ width: "100%" }}
          onChange={handleInputChange}
          name={"indent_no"}
          sx={{
            ...style.textFieldStyle,
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <Grid
          sx={{
            ...style.DatePickerStyle,
          }}
        >
          <DatePicker
            label="From Date"
            name="from_date"
            placeholder="Select Date"
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "from_date")
            }
            format="DD/MM/YYYY"
            value={filterData.from_date}
            style={{
              boxShadow: "none",
              height: "45px",
              borderRadius: "5px",
              width: "100%",
            }}
            borderColor="var(--primary-border-color)"
            formControlStyle={{ width: "100%" }}
          />
        </Grid>
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <Grid
          sx={{
            ...style.DatePickerStyle,
          }}
        >
          <DatePicker
            label="To Date"
            name="to_date"
            placeholder="Select Date"
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "to_date")
            }
            format="DD/MM/YYYY"
            value={filterData.to_date}
            style={{
              boxShadow: "none",
              height: "45px",
              borderRadius: "5px",
              width: "100%",
            }}
            formControlStyle={{ width: "100%" }}
            borderColor="var(--primary-border-color)"
          />{" "}
        </Grid>
      ),
    },
  ];
  const debouncedGetAllIndents = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllIndents({
        page: data.page,
        limit: data.pageSize,
        column: data.sortBy,
        order: data.sortOrder,
        from_date: data.fromDate
          ? commonDateFormatter(data.fromDate, "YYYY-MM-DD")
          : null,
        to_date: data.toDate
          ? commonDateFormatter(data.toDate, "YYYY-MM-DD")
          : null,
        indent_no: data.indent_no,
        search: data.search,
      })
        .then((res: any) => {
          setIntentsList(res.data.result as []);
          setPageCount(res.data.total as any);
          setLoading(false);
        })
        .catch((err) => {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage.ERROR_FETCHING_INDENT}`,
            })
          );
          setLoading(false);
          console.log("error getAppointmentList", err);
        });
    }, 300),
    []
  );
  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo.pageSize,
      sortBy: sortField?.field,
      sortOrder: sortField?.order,
      fromDate: filterData.from_date,
      toDate: filterData.to_date,
      indent_no: filterData.indent_no,
      search: filterData.search,
    };
    debouncedGetAllIndents(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortField?.order,
    sortField?.field,
    filterData.from_date,
    filterData.to_date,
    filterData.from_date,
    filterData.to_date,
    filterData.indent_no,
    filterData.search,
  ]);

  return (
    <Box>
      <CustomFilter
        data={appointmentFilter}
        isFilterEnabled={true}
        isSearchEnabled={true}
        clearFilter={clearFilters}
        searchOnChange={handleInputChange}
        searchName={"search"}
        appliedFilterCount={count}
        searchValue={filterData.search}
        clearSearch={() =>
          dispatch(
            setHandleClearSearch({
              key: "procurementIndentList",
              value: "",
              name: "search",
            })
          )
        }
        {...(Can("indents_add") && {
          editButtonTitle: "Add",
          onUpdateButtonClick: () =>
            navigate(`${procurementUrl}${indentsListUrl}${createUrl}`),
          editStartIcon: (
            <Box
              sx={{
                "& path": {
                  fill: "#fff",
                },
              }}
            >
              <PlusIcon width={"12px"} height={"12px"} />
            </Box>
          ),
        })}
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={intentsList}
        pageCount={pageCount}
        currentPage={"procurementIndentList"}
      />
    </Box>
  );
};

export default IntentList;
