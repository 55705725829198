import React from "react";
import { Button } from "@mui/material";

type ButtonProps = {
  variant?: "text" | "outlined" | "contained";
  buttonText: string;
  handleClick?: () => void;
  sx?: {};
};

const MuiButton = ({
  variant = "outlined",
  buttonText,
  handleClick,
  sx,
}: ButtonProps) => {
  const styles = {
    boxShadow: "none",
    borderRadius: "5px",
    width: "fit-content",
    height: "40px",
    fontWeight: 400,
    fontSize: 14,
    borderColor: "primary.main",
    color: "primary.lighter",
    backgroundColor: "backgroundPrimary",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "initial",
      color: "primary.main",
    },
  };
  return (
    <Button
      variant={variant}
      onClick={handleClick}
      disableRipple
      sx={{
        ...styles,
        ...sx,
      }}
    >
      {buttonText}
    </Button>
  );
};

export default MuiButton;
