import { useState, useEffect, useCallback } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Typography, Grid, Switch, debounce, Box } from "@mui/material";
import {
  Sort,
  CustomButton,
  TextField,
  BrowseButton,
  SaveButton,
  PageLoader,
  Tooltip,
} from "../../../../components/basic";
import {
  DataTable,
  DialogWrapper,
  ConfirmationDialog,
} from "../../../../components/shared";
import { ActiveStatusColor, statusColor } from "../../../../utils/GeneralUtils";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import CustomFilter from "../../../../components/shared/customFilter";
import { displayText } from "../../../../constants/displayText";
import { DeleteIcon, EditIcon, PlusIcon } from "../../../../assets/icons";
import {
  addLabSuppliers,
  deleteLabSupplier,
  getAllLabSupplierList,
  getLabSupplierListById,
  updateLabSupplierById,
} from "../../../../services/procurementMasterService";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import PermissionUtils from "../../../../utils/PermissionUtils";
import { setCurrentPage } from "../../../../redux/slices/pagination";

type GridRowData = Record<string, unknown>;
function Suppliers() {
  const { can } = PermissionUtils();

  const { ADD_BUTTON_TITLE, CONFIRMATION_DELETE_DIALOG_TITLE } = displayText;

  // data table constants and states

  const [isFieldSort, setIsFieldSort] = useState(false);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [loading, setLoading] = useState(false);

  const [supplierList, setSupplierList] = useState([]);
  const [filesToBeUploaded, setFilesToBeUploaded] = useState<any>([]);

  const [isAddAndEditDialogOpen, setIsAddAndEditDialogOpen] =
    useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isAddAndEditDialogLoading, setIsAddAndEditDialogLoading] =
    useState<boolean>(false);
  const [idToBeUpdated, setIdToBeUpdated] = useState<number | null>(null);

  // confirmation dialog states and constants

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [suppliersToBeDeleted, setSuppliersToBeDeleted] = useState<any>(null);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);
  const { laboratoryMasterSuppliers } = useSelector(
    (state: RootState) => state.pagination
  );
  const [filterData, setFilterData] = useState({ search: "" });
  const initialFormError = {
    name: "",
    phone: "",
    email: "",
    gst_no: "",
    address: "",
  };

  const [supplierFormError, setSupplierFormError] =
    useState<any>(initialFormError);
  type InitialForDataTypes = {
    name: string | null | number;
    phone: string | null | number;
    email: string | null | number;
    gst_no: string | null | number;
    address: string | null | number;
    active: boolean;
  };
  const initialFormData: InitialForDataTypes = {
    name: "",
    phone: "",
    email: "",
    gst_no: "",
    address: "",
    active: true,
  };

  const [supplierFormData, setSupplierFormData] =
    useState<any>(initialFormData);
  const [pageCount, setPageCount] = useState(null);

  const fieldData = {
    name: {
      label: "Name",
      name: "name",
      value: supplierFormData.name,
      isError: supplierFormError.name === "" ? false : true,
      helperText: supplierFormError.name,
    },
    phone: {
      label: "Phone Number",
      name: "phone",
      value: supplierFormData.phone,
      isError: supplierFormError.phone === "" ? false : true,
      helperText: supplierFormError.phone,
    },
    email: {
      label: "Email",
      name: "email",
      value: supplierFormData.email,
      isError: supplierFormError.email === "" ? false : true,
      helperText: supplierFormError.email,
    },
    gst_no: {
      label: "GST No",
      name: "gst_no",
      value: supplierFormData.gst_no,
      isError: supplierFormError.gst_no === "" ? false : true,
      helperText: supplierFormError.gst_no,
    },
    address: {
      label: "Address",
      name: "address",
      value: supplierFormData.address,
      isError: supplierFormError.address === "" ? false : true,
      helperText: supplierFormError.address,
    },
    active: {
      label: "Status",
      name: "active",
      value: supplierFormData.active,
      isError: supplierFormError.active === "" ? false : true,
      helperText: supplierFormError.active,
    },
  };

  const [departmentFieldData, setDepartmentFieldData] =
    useState<any>(fieldData);

  // data table columns and actions
  const deleteProductById = async () => {
    if (suppliersToBeDeleted === null) {
      return false;
    }
    setDeleteBtnLoader(true);
    await deleteLabSupplier(suppliersToBeDeleted as number)
      .then((res: any) => {
        setSuppliersToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        debouncedGetAllAppointment({
          page: laboratoryMasterSuppliers.page,
          pageSize: laboratoryMasterSuppliers.pageSize,
        });
        dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
        setDeleteBtnLoader(false);
      })
      .catch((err) => {
        setSuppliersToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        setDeleteBtnLoader(false);
        const errorMessage = err?.response?.data?.errors
          ? err?.response?.data?.errors
          : "Unknown error occurred";
        dispatch(
          setSnackBarFailed({
            snackBarMessage: `${errorMessage}`,
          })
        );
        console.log(err);
      });
  };

  const deleteVacation = (row: any) => {
    setIsConfirmationDialogOpen(true);
    setSuppliersToBeDeleted(row.id);
  };
  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        {can("lab_suppliers_edit") && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIdToBeUpdated(row.id);
              openAddAndEditDialog();
            }}
          >
            <EditIcon />
          </div>
        )}

        {can("lab_suppliers_delete") && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => deleteVacation(row)}
          >
            <DeleteIcon />
          </div>
        )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "phone",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("phone")}
        >
          <Typography variant="h5" fontSize={14}>
            Phone Number
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "phone" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "phone" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.phone || ""}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "Email",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("email")}
        >
          <Typography variant="h5" fontSize={14}>
            Email
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "email" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "email" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.email}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "gst_no",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("gst_no")}
        >
          <Typography variant="h5" fontSize={14}>
            GST No
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "gst_no" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "gst_no" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.gst_no}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "Address",
      flex: 1.2,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("address")}
        >
          <Typography variant="h5" fontSize={14}>
            Address
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "address" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "address" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Tooltip title={row?.address} arrow placement="top">
          <Typography
            variant="h5"
            fontSize={14}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {row?.address}
          </Typography>
        </Tooltip>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "Status",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("active")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "active" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "active" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => {
        const statusValue = row ? row.active : null;
        const { buttonText, backgroundColor, textColor } =
          ActiveStatusColor[statusValue] || {};

        return (
          <Typography variant="h5" fontSize={14}>
            <CustomButton
              buttonText={buttonText}
              backgroundColor={backgroundColor}
              textColor={textColor}
              fontSize={"10px"}
              fontWeight={500}
              textTransform={"capitalize"}
            />
          </Typography>
        );
      },
      minWidth: 120,
      sortable: false,
    },

    ...(can("lab_suppliers_edit") || can("lab_suppliers_delete")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  // data table functions

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  //form validation functions

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "name":
      case "phone":
      case "gst_no": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setSupplierFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setSupplierFormError);
        }
        break;
      }
      default:
        break;
    }
  };
  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };
  const updateProductsFieldData = () => {
    setDepartmentFieldData((prev: any) => {
      return Object?.keys(prev)?.map((field: any) => {
        return {
          ...field,
          value: supplierFormData[field.name],
          isError: supplierFormError[field.name] === "" ? false : true,
          helperText: supplierFormError[field.name],
        };
      });
    });
  };

  // form API call functions
  const dispatch = useDispatch();
  const createProduct = async () => {
    if (
      supplierFormData.name === "" ||
      supplierFormData.phone === "" ||
      supplierFormData.gst_no === ""
    ) {
      validateForm();
    } else {
      const data = {
        name: supplierFormData.name,
        phone: supplierFormData.phone,
        email: supplierFormData.email,
        gst_no: supplierFormData.gst_no,
        address: supplierFormData.address,
        active: supplierFormData.active === false ? 0 : 1,
      };
      setIsButtonLoading(true);
      await addLabSuppliers(data)
        .then((res: any) => {
          closeAddAndEditDialog();
          dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
          debouncedGetAllAppointment({
            page: laboratoryMasterSuppliers.page,
            pageSize: laboratoryMasterSuppliers.pageSize,
          });
          setIsButtonLoading(false);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.errors
            ? err?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          setIsButtonLoading(false);
        });
    }
  };
  const updateProduct = async () => {
    if (
      supplierFormData.name === "" ||
      supplierFormData.phone === "" ||
      supplierFormData.gst_no === ""
    ) {
      validateForm();
    } else {
      const data = {
        name: supplierFormData.name,
        phone: supplierFormData.phone,
        email: supplierFormData.email,
        gst_no: supplierFormData.gst_no,
        address: supplierFormData.address,
        active: supplierFormData.active === false ? 0 : 1,
      };
      setIsButtonLoading(true);
      await updateLabSupplierById(idToBeUpdated as number, data)
        .then((res: any) => {
          setIsButtonLoading(false);
          closeAddAndEditDialog();
          dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
          debouncedGetAllAppointment({
            page: laboratoryMasterSuppliers.page,
            pageSize: laboratoryMasterSuppliers.pageSize,
          });
          setIsButtonLoading(false);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.errors
            ? err?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          setIsButtonLoading(false);
        });
    }
  };

  // form fields onchange functions

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setSupplierFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    handleValidation(e);
  };

  const handleSwitchChange = async (e: any) => {
    const { name, checked } = e.target;
    setSupplierFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: checked,
    }));
  };

  useEffect(() => {
    updateProductsFieldData();
  }, [supplierFormError, supplierFormData]);

  // Add And Edit dialog functions

  const openAddAndEditDialog = async () => {
    setIsAddAndEditDialogOpen(true);
  };

  const closeAddAndEditDialog = async () => {
    setIsButtonLoading(false);
    setIsAddAndEditDialogOpen(false);
    setSupplierFormData(initialFormData);
    setIdToBeUpdated(null);
    setSupplierFormError(initialFormError);
  };

  useEffect(() => {
    if (idToBeUpdated) {
      setIsAddAndEditDialogLoading(true);
      getLabSupplierListById(idToBeUpdated)
        .then((res: any) => {
          const supplierDetail: any = res?.data;
          setSupplierFormData((prevState: InitialForDataTypes) => ({
            ...prevState,
            name: supplierDetail?.name,
            phone: supplierDetail?.phone,
            email: supplierDetail?.email,
            gst_no: supplierDetail?.gst_no,
            address: supplierDetail?.address,
            active: supplierDetail?.active === 0 ? false : true,
          }));
          setIsAddAndEditDialogLoading(false);
        })
        .catch((err) => {
          setIsAddAndEditDialogLoading(false);
          const errorMessage = err?.response?.data?.errors
            ? err?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          console.log(err);
        });
    }
  }, [idToBeUpdated]);
  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };
  const debouncedGetAllAppointment = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllLabSupplierList({
        page: data.page,
        limit: data.pageSize,
        column: data.sortBy,
        order: data.sortOrder,
        search: data.search,
      })
        .then((res: any) => {
          setSupplierList(res?.data?.result);
          setPageCount(res?.data?.total as any);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          const errorMessage = err?.response?.data?.errors
            ? err?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          console.log("error getAppointmentList", err);
        });
    }, 300),
    []
  );

  const handleSearchInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;
      setFilterData((prevState: any) => {
        const newFilter = { ...prevState, [name]: value };
        return newFilter;
      });
    }
    dispatch(setCurrentPage({ key: "laboratoryMasterSuppliers", value: 0 }));
  };
  useEffect(() => {
    const data = {
      page: Number(laboratoryMasterSuppliers.page) + 1,
      pageSize: laboratoryMasterSuppliers.pageSize,
      sortBy: sortedField.field,
      sortOrder: sortedField.order,
      search: filterData.search,
    };
    debouncedGetAllAppointment(data);
  }, [
    laboratoryMasterSuppliers.page,
    laboratoryMasterSuppliers.pageSize,
    sortedField.order,
    sortedField.field,
    filterData.search,
  ]);
  // custom component and it's function
  const CustomComponent = () => {
    return (
      <Grid
        sx={{
          width: "auto",
          minWidth: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BrowseButton
          selectedItems={filesToBeUploaded}
          onChange={handleFileChange}
        />
        <SaveButton
          buttonText="Upload"
          sx={{ width: "100px", height: "40px" }}
        />
      </Grid>
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setFilesToBeUploaded(Array.from(files));
    }
  };

  return (
    <>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={CONFIRMATION_DELETE_DIALOG_TITLE}
        handleClick={deleteProductById}
        onClose={closeConfirmationDialog}
        loading={deleteBtnLoader}
      />
      <CustomFilter
        isSearchEnabled={true}
        searchName="search"
        searchValue={filterData.search}
        clearSearch={() => {
          setFilterData({ search: "" });
          dispatch(
            setCurrentPage({ key: "laboratoryMasterSuppliers", value: 0 })
          );
        }}
        searchOnChange={handleSearchInputChange}
        // customComponent={<CustomComponent />}
        {...(can("lab_suppliers_add") && {
          editButtonTitle: ADD_BUTTON_TITLE,
          onUpdateButtonClick: openAddAndEditDialog,
          editStartIcon: (
            <Box
              sx={{
                "& path": {
                  fill: "#fff",
                },
              }}
            >
              <PlusIcon width={"12px"} height={"12px"} />
            </Box>
          ),
        })}
      />
      <DataTable
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={supplierList}
        pageCount={pageCount}
        loading={loading}
        currentPage={"laboratoryMasterSuppliers"}
      />

      <DialogWrapper
        onClose={closeAddAndEditDialog}
        open={isAddAndEditDialogOpen}
        handleClick={idToBeUpdated === null ? createProduct : updateProduct}
        title={idToBeUpdated === null ? "Add Supplier" : "Edit Supplier"}
        loading={isButtonLoading}
        maxWidth="sm"
      >
        {isAddAndEditDialogLoading ? (
          <PageLoader />
        ) : (
          <>
            <TextField
              value={fieldData?.name?.value}
              name={fieldData?.name?.name}
              onChange={handleInputChange}
              label={fieldData?.name?.label}
              placeholder="Enter Name"
              helperText={fieldData?.name?.helperText}
              onBlur={(e: any) =>
                handleValidation((e = { target: fieldData.name }))
              }
              sx={{
                width: "100%",
              }}
            />
            <TextField
              value={fieldData?.phone?.value}
              name={fieldData?.phone?.name}
              onChange={handleInputChange}
              label={fieldData?.phone?.label}
              placeholder="Enter Phone Number"
              helperText={fieldData?.phone?.helperText}
              onBlur={(e: any) =>
                handleValidation((e = { target: fieldData.phone }))
              }
              sx={{
                width: "100%",
              }}
            />
            <TextField
              value={fieldData?.email?.value}
              name={fieldData?.email?.name}
              onChange={handleInputChange}
              label={fieldData?.email?.label}
              placeholder="Enter Email"
              helperText={fieldData?.email?.helperText}
              onBlur={(e: any) =>
                handleValidation((e = { target: fieldData.email }))
              }
              sx={{
                width: "100%",
              }}
            />
            <TextField
              value={fieldData?.gst_no?.value}
              name={fieldData?.gst_no?.name}
              onChange={handleInputChange}
              label={fieldData?.gst_no?.label}
              placeholder="Enter GST no"
              helperText={fieldData?.gst_no?.helperText}
              onBlur={(e: any) =>
                handleValidation((e = { target: fieldData.gst_no }))
              }
              sx={{
                width: "100%",
              }}
            />
            <Grid
              item
              sm={12}
              sx={{
                marginBottom: "10px",
                marginTop: "10px",
                paddingRight: "12px",
              }}
            >
              <Typography variant="p" sx={{ color: "#8A8A8A" }}>
                Address
              </Typography>
              <textarea
                style={{
                  width: "98%",
                  height: "154px",
                  borderRadius: "8px",
                  padding: "10px",
                  resize: "none",
                  outline: "none",
                  border: "1px solid var(--primary-border-color)",
                  marginTop: "10px",
                  fontFamily: "inter",
                  fontSize: "14px",
                }}
                placeholder="Enter Address"
                name={fieldData?.address?.name}
                value={fieldData?.address?.value}
                onChange={handleInputChange}
                spellCheck="false"
              />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: "10px",
              }}
            >
              <Typography>{fieldData?.active?.label}</Typography>
              <Switch
                name={fieldData?.active?.name}
                checked={fieldData?.active?.value}
                onChange={handleSwitchChange}
                value={supplierFormData.active}
                disabled={Boolean(!idToBeUpdated)}
              />
            </Grid>
          </>
        )}
      </DialogWrapper>
    </>
  );
}

export default Suppliers;
