import React from "react";
import {
  AppointMentsIcon,
  DashBoardIcon,
  PatientsIcon,
  BillingIcon,
  PaymentIcon,
  VaccinesIcon,
  LaboratoryIcon,
  InpatientsIcon,
  AccountsIcon,
  PharmacyIcon,
  SettingsIcon,
  AdminAccountIcon,
  CampaignIcon,
} from "../../../assets/icons";
import { RouteUrls } from "../../../constants/routes";
import { Can } from "../../../utils/PermissionUtils";

const {
  dashboardUrl,
  appointmentUrl,
  appointmentsBooking,
  appointmentsDetails,
  accountsUrl,
  patientUrl,
  billingUrl,
  paymentUrl,
  vaccineUrl,
  laboratoryUrl,
  inPatientsUrl,
  pharmacyUrl,
  patientHistoryUrl,
  masterUrl,
  labMasterUrl,
  procurementUrl,
  purchaseBillUrl,
  purchasePaymentListUrl,
  purchaseReturnUrl,
  goodsIssueReturnUrl,
  indentsListUrl,
  goodsIssueUrl,
  stockAdjustmentUrl,
  purchaseOrdersUrl,
  salesBillListUrl,
  salesReturnUrl,
  billingItemsUrl,
  billingListUrl,
  patientAccountUrl,
  adminUrl,
  listUrl,
  gynecologyUrl,
  labBillsUrl,
  campaignUrl,
  inhouseLeadsUrl,
  coldLeadsUrl,
  labPurchaseReturnUrl,
  labGoodsIssue,
  labIssueStockAdjustments,
  labReturnLabGoodsIssue,
  labPurchasePayments,
  wholeSalesUrl,
  wholeSalesReturn,
  labPurchaseUrl,
  labIndentsListUrl,
  labPurchaseOrderUrl,
  wholeSalesPayments,
  adjustmentUrl,
  paymentAdjustmentUrl,
  searchUrl,
  vaccinesUrl,
  stockTransferUrl,
} = RouteUrls;

const NavConfig = () => {
  const navConfig = [
    {
      title: "Dashboard",
      path: dashboardUrl,
      hasPermission: true,
      icon: <DashBoardIcon />,
    },
    {
      title: "Appointments",
      path: appointmentUrl,
      hasPermission:
        Can("admin_booking") ||
        Can("appointments_view") ||
        Can("review_list") ||
        Can("patient_appointments_view") ||
        Can("workhour_list") ||
        Can("symptoms_view") ||
        Can("print_note_view") ||
        Can("pxproducts_view") ||
        Can("vaccation_list"),
      icon: <AppointMentsIcon />,
      children: [
        {
          title: "Consultations",
          path: appointmentsBooking,
          hasPermission: Can("admin_booking"),
        },
        {
          title: "Appointment List",
          path: listUrl,
          hasPermission: Can("appointments_view"),
        },
        {
          title: "Master",
          path: masterUrl,
          hasPermission:
            Can("workhour_list") ||
            Can("symptoms_view") ||
            Can("print_note_view") ||
            Can("pxproducts_view") ||
            Can("vaccation_list"),
        },
      ],
    },
    {
      title: "Patients",
      path: patientUrl,
      hasPermission: Can("patients_account"),
      icon: <PatientsIcon />,
      children: [
        {
          title: "Patients List",
          path: `${listUrl}`,
          hasPermission: Can("patients_account"),
        },
      ],
    },
    {
      title: "Billing",
      path: billingUrl,
      icon: <BillingIcon />,
      hasPermission: Can("billing_view") || Can("billing_items_list"),
      children: [
        {
          title: "Items List",
          path: billingItemsUrl,
          hasPermission: Can("billing_items_list"),
        },
        {
          title: "Bills List",
          path: billingListUrl,
          hasPermission: Can("billing_view"),
        },
      ],
    },
    {
      title: "Payment",
      path: paymentUrl,
      icon: <PaymentIcon />,
      hasPermission: Can("payment_view") || Can("adjustment_view"),
      children: [
        {
          title: "Payment List",
          path: listUrl,
          hasPermission: Can("payment_view"),
        },
        {
          title: "Adjustment List",
          path: paymentAdjustmentUrl,
          hasPermission: Can("adjustment_view"),
        },
      ],
    },
    {
      title: "Vaccine",
      path: vaccinesUrl,
      hasPermission: Can("vaccine_patients_list") || Can("vaccine_search"),
      icon: <VaccinesIcon />,
      children: [
        {
          title: "Vaccines",
          path: listUrl,
          hasPermission: Can("vaccine_patients_list"),
        },
        {
          title: "Search",
          path: searchUrl,
          hasPermission: Can("vaccine_search"),
        },
      ],
    },
    {
      title: "Laboratory",
      path: laboratoryUrl,
      hasPermission: Can("labreport_view"),
      icon: <LaboratoryIcon />,
      children: [
        {
          title: "Lab Master",
          path: labMasterUrl,
          hasPermission:
            Can("lab_products_list") ||
            Can("lab_suppliers_list") ||
            Can("lab_tax_list"),
        },
        {
          title: "Lab Purchase",
          path: labPurchaseUrl,
          hasPermission: Can("lab_purchasebill_view"),
        },
        {
          title: "Lab Purchase Payments",
          path: labPurchasePayments,
          hasPermission: Can("lab_purchasebill_pay_view"),
        },
        {
          title: "Lab Purchase Return",
          path: labPurchaseReturnUrl,
          hasPermission: Can("lab_purchase_return_add"),
        },
        {
          title: "Lab Indents",
          path: labIndentsListUrl,
          hasPermission: Can("lab_indents_view"),
        },
        {
          title: "Lab Purchase Orders",
          path: labPurchaseOrderUrl,
          hasPermission: Can("lab_indents_view"),
        },
        {
          title: "Lab Goods Issue",
          path: labGoodsIssue,
          hasPermission: Can("lab_goods_issue_view"),
        },
        {
          title: "Lab Goods Issue Return",
          path: labReturnLabGoodsIssue,
          hasPermission: Can("lab_goods_issue_return_add"),
        },
        {
          title: "Lab Stock Adjustment",
          path: labIssueStockAdjustments,
          hasPermission: Can("lab_goods_issue_stock_adj_view"),
        },
        {
          title: "Lab Bills",
          path: labBillsUrl,
          hasPermission: Can("lab_bills_list"),
        },
      ],
    },
    {
      title: "Gynecology",
      path: `${gynecologyUrl}${listUrl}`,
      icon: <LaboratoryIcon />,
      hasPermission: Can("gynecology_view"),
    },
    {
      title: "Inpatients",
      path: inPatientsUrl,
      icon: <InpatientsIcon />,
      hasPermission: Can("ip_view"),
      children: [
        {
          title: "IP List",
          path: listUrl,
          hasPermission: Can("ip_view"),
        },
      ],
    },
    {
      title: "Accounts",
      path: accountsUrl,
      hasPermission: Can("ac_expense_list") || Can("ac_cash_summary_list"),
      icon: <AccountsIcon />,
      children: [
        {
          title: "Expenses",
          path: accountsUrl,
          hasPermission: Can("ac_expense_list"),
        },
        {
          title: "Cash Summary",
          path: accountsUrl,
          hasPermission: Can("ac_cash_summary_list"),
        },
      ],
    },
    {
      title: "Pharmacy",
      path: pharmacyUrl,
      hasPermission: Can("salesbill_view") || Can("sales_return_view"),
      icon: <PharmacyIcon />,
      children: [
        {
          title: "Sales Bills",
          path: salesBillListUrl,
          hasPermission: Can("salesbill_view"),
        },
        {
          title: "Sales Return",
          path: salesReturnUrl,
          hasPermission: Can("sales_return_view"),
        },
      ],
    },
    {
      title: "Procurement",
      path: procurementUrl,
      hasPermission:
        Can("products_list") ||
        Can("suppliers_list") ||
        Can("gst_list") ||
        Can("purchasebill_view") ||
        Can("purchase_return_view") ||
        Can("indents_view") ||
        Can("goods_issue_view") ||
        Can("goods_issue_return_add") ||
        Can("goods_issue_stock_adj_view") ||
        Can("goods_issue_transfer_add") ||
        Can("goods_issue_temp_is_draft") ||
        Can("purchasebill_temp_is_draft") ||
        Can("whole_sales_view") ||
        Can("purchase_order_view") ||
        Can("whole_sales_return_add"),
      icon: <SettingsIcon />,
      children: [
        {
          title: "Master",
          path: masterUrl,
          hasPermission:
            Can("products_list") || Can("suppliers_list") || Can("gst_list"),
        },
        {
          title: "Purchase Bill",
          path: purchaseBillUrl,
          hasPermission:
            Can("purchasebill_view") || Can("purchasebill_temp_is_draft"),
        },
        {
          title: "Purchase Payments",
          path: purchasePaymentListUrl,
          hasPermission: Can("purchasebill_pay_view"),
        },
        {
          title: "Purchase Return",
          path: purchaseReturnUrl,
          hasPermission: Can("purchase_return_add"),
        },
        {
          title: "Indent",
          path: indentsListUrl,
          hasPermission: Can("indents_view"),
        },
        {
          title: "Purchase Orders",
          path: purchaseOrdersUrl,
          hasPermission: Can("purchase_order_view"),
        },
        {
          title: "Goods Issue",
          path: goodsIssueUrl,
          hasPermission:
            Can("goods_issue_view") || Can("goods_issue_temp_is_draft"),
        },
        {
          title: "Goods Issue Return",
          path: goodsIssueReturnUrl,
          hasPermission: Can("goods_issue_return_add"),
        },
        {
          title: "B2B",
          path: wholeSalesUrl,
          hasPermission: Can("whole_sales_view"),
        },
        {
          title: "B2B Return",
          path: wholeSalesReturn,
          hasPermission: Can("whole_sales_return_add"),
        },
        {
          title: "B2B Payments",
          path: wholeSalesPayments,
          hasPermission: Can("whole_sales_pay_view"),
        },
        {
          title: "B2B Adjustment",
          path: adjustmentUrl,
          hasPermission: Can("adjustment_view"),
        },
        {
          title: "Stock Adjustment",
          path: stockAdjustmentUrl,
          hasPermission: Can("goods_issue_stock_adj_view"),
        },
        {
          title: "Stock Transfer",
          path: stockTransferUrl,
          hasPermission: Can("goods_issue_transfer_add"),
        },
      ],
    },
    {
      title: "Campaigns",
      path: campaignUrl,
      hasPermission: true,
      icon: <CampaignIcon />,
      children: [
        {
          title: "Campaigns List",
          path: listUrl,
          hasPermission: true,
        },
        {
          title: "Inhouse Leads",
          path: inhouseLeadsUrl,
          hasPermission: true,
        },
        {
          title: "Cold Leads",
          path: coldLeadsUrl,
          hasPermission: true,
        },
      ],
    },
    {
      title: "Admin",
      path: adminUrl,
      hasPermission: true,
      icon: <AdminAccountIcon />,
      children: [
        {
          title: "Users Account",
          path: "https://hms.techbumbles.com/auth-redirect?type=session&serviceUrl=https://hms.techbumbles.com/users_account",
          hasPermission: true,
          hasExternalURL: true,
        },
        {
          title: "Patients Account",
          path: patientAccountUrl,
          hasPermission: true,
        },
      ],
    },
  ];

  return navConfig;
};

export default NavConfig;
