import {
  Badge,
  Box,
  useTheme,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useState, type ChangeEvent } from "react";
import { FilterIcon, SearchIcon, ClearFilterIcon } from "../../../assets/icons";
import { CustomButton as Button, SearchBar } from "../../basic";

interface Props {
  title?: string;
  subtitle?: string;
  addButtonTitle?: string | null;
  addButtonTitle2?: string | null;
  editButtonTitle?: string | null;
  isShowExport?: boolean;
  searchEnabled?: boolean;
  onAddButtonClick?: () => void;
  onAddButtonClick2?: () => void;
  onUploadButtonClick?: (e: ChangeEvent<HTMLInputElement>) => void;
  onUpdateButtonClick?: () => void;
  onExportClick?: () => void;
  handleSearch?: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  flag?: boolean;
  isFilterEnabled?: boolean;
  handleFilterClick?: () => void;
  handleSearchClick?: () => void;
  isSearchClicked?: boolean;
  FilterElement?: any;
  isFilterOpen?: boolean;
  data?: [] | any;
  isSearchEnabled?: boolean;
  clearFilter?: any;
  searchOnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  searchName?: string;
  searchValue?: any;
  appliedFilterCount?: number | string;
  customComponent?: any;
  filterGridSx?: {};
  clearSearch?: () => void;
  editStartIcon?: any;
  addStartIcon?: any;
}

const CustomFilter = ({
  title,
  addButtonTitle,
  addButtonTitle2,
  editButtonTitle,
  isShowExport = false,
  onAddButtonClick,
  onAddButtonClick2,
  onUpdateButtonClick,
  onUploadButtonClick,
  onExportClick,
  flag = false,
  isFilterEnabled = false,
  data,
  isSearchEnabled = false,
  clearFilter,
  searchOnChange,
  searchName,
  searchValue,
  appliedFilterCount,
  customComponent,
  filterGridSx,
  clearSearch,
  editStartIcon,
  addStartIcon,
}: Props): JSX.Element => {
  const currentTheme = useTheme();
  const colors = currentTheme.palette;
  const [filterClicked, setFilterClicked] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const styles = {
    mainBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    buttonGroupBox: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "100%",
      textAlign: "center",
    },
    editButton: {
      textTransform: "none",
      maxWidth: "max-content",
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: '"Inter", "sans-serif"',
    },
    addButton: {
      textTransform: "none",
      maxWidth: "max-content",
      margin: "0 0 0 16px",
      fontSize: "14px",
      fontFamily: '"Inter", "sans-serif"',
      fontWeight: 400,
    },
    filterContainerStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: 2,
    },
    filterStyles: {
      textAlign: "center",
      alignItems: "center",
      cursor: "pointer !important",
      paddingBottom: "10px",
      marginRight: 2,
    },
    searchStyles: {
      textAlign: "center",
      alignItems: "center",
      cursor: "pointer",
      padding: "0 16px 0 0",
      display: "flex",
    },
    addBtnTwo: {
      height: 40,
      bgcolor: colors.primary.lighter,
      borderRadius: 2,
      textTransform: "none",
      margin: "0 10px 0",
    },
    filterIconBadge: { padding: "2px" },
  };

  const isMediumScreen = useMediaQuery(
    "(min-width:251px) and (max-width:900px)"
  );

  const sumGridProps = (items: any) => {
    const totalColumns = items?.reduce(
      (acc: any, item: any) => {
        const { xs, sm, md, lg, xl } = item?.gridProps;
        return {
          xs: acc.xs + xs,
          sm: acc.sm + sm,
          md: acc.md + md,
          lg: acc.lg + lg,
          xl: acc.xl + xl,
        };
      },
      { xs: 4, sm: 2, md: 2, lg: 1, xl: 1 }
    );

    return {
      xs: 12 - (Math.ceil(totalColumns?.xs) % 12),
      sm: 12 - (Math.ceil(totalColumns?.sm) % 12),
      md:
        12 - (Math.ceil(totalColumns?.md) % 12) >= 2
          ? 12 - (Math.ceil(totalColumns?.md) % 12)
          : 12,
      lg:
        12 - (Math.ceil(totalColumns?.lg) % 12) >= 2
          ? 12 - (Math.ceil(totalColumns?.lg) % 12)
          : 12,
      xl:
        12 - (Math.ceil(totalColumns?.xl) % 12) >= 2
          ? 12 - (Math.ceil(totalColumns?.xl) % 12)
          : 12,
    };
  };

  const summedProps = sumGridProps(data);
  return (
    <>
      <Box sx={styles.mainBox}>
        {title && (
          <Typography
            variant="h1"
            sx={{
              width: "500px",
              pl: 1,
              fontSize: "var(--primary-header-font-size) !important",
              fontWeight: "var(--primary-header-font-weight)",
            }}
          >
            {title}
          </Typography>
        )}
        {customComponent && customComponent}
        <Box sx={styles.buttonGroupBox}>
          {editButtonTitle && (
            <Button
              variant="contained"
              buttonText={editButtonTitle}
              sx={styles.editButton}
              backgroundColor="#207DFF"
              textColor="#FFFFFF"
              border={"none"}
              onClick={onUpdateButtonClick}
              startIcon={editStartIcon}
            />
          )}
          {addButtonTitle && !flag && (
            <Button
              variant="outlined"
              buttonText={addButtonTitle}
              textColor={"#207DFF"}
              onClick={onAddButtonClick}
              sx={styles.addButton}
              border={"1px solid #207DFF"}
              buttonType="secondary"
              startIcon={addStartIcon}
            />
          )}
          {addButtonTitle2 && !flag && (
            <Button
              variant="contained"
              buttonText={addButtonTitle2}
              sx={styles.addBtnTwo}
              onClick={onAddButtonClick2}
            >
              {addButtonTitle2}
            </Button>
          )}
          {addButtonTitle && flag && (
            <Button
              variant="contained"
              buttonText={addButtonTitle}
              component="label"
            >
              Upload File
              <input
                onChange={onUploadButtonClick}
                hidden
                accept=".csv,text/xml"
                type="file"
              />
            </Button>
          )}
          {isShowExport && (
            <Button
              variant="contained"
              sx={{
                height: 40,
                bgcolor: colors.primary.lighter,
                borderRadius: 2,
                textTransform: "none",
                margin: "0 10px 0",
              }}
              onClick={onExportClick}
            >
              Export
            </Button>
          )}
        </Box>
        <Box sx={styles.filterContainerStyle}>
          {isFilterEnabled && (
            <Box
              sx={styles.filterStyles}
              onClick={() => setFilterClicked(!filterClicked)}
            >
              <Badge
                badgeContent={appliedFilterCount}
                color="error"
                sx={styles.filterIconBadge}
              >
                <FilterIcon />
              </Badge>
            </Box>
          )}
          {/* {isSearchEnabled && (
            <Box
              sx={styles.searchStyles}
              onClick={() => setSearchClicked(!searchClicked)}
            >
              <SearchIcon />
            </Box>
          )} */}
        </Box>
      </Box>
      {/* Developer use */}
      {/* {isMediumScreen && searchClicked && (
        <div
          style={{
            margin: "0 -12px",
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <SearchBar
            onChange={searchOnChange}
            searchName={searchName}
            handleSearchClick={() => setSearchClicked(!searchClicked)}
          />
        </div>
      )} */}
      {/* {isMediumScreen && filterClicked && data && data.length > 0 && (
        <div
          style={{
            width: "100%",
            marginBottom: "1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="filter-grid-parent"
        >
          <Grid className="filter-grid" container spacing={1}>
            {data.map((ele: any) => (
              <Grid item {...ele.gridProps}>
                {ele.children}
              </Grid>
            ))}
            <Grid
              item
              xl={1}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <Typography
                  variant="h3"
                  color="primary.main"
                  onClick={clearFilter}
                >
                  Clear Filter
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      )} */}
      {/* {!isMediumScreen && ( */}
      <Grid container display={"flex"} alignItems={"center"}>
        {/* we will use it in the future */}
        {/* {isMediumScreen && (
          <Grid item sm={12} md={3} xl={3} xs={12} alignItems={"flex-end"}>
            {searchClicked && (
              <div
                style={{
                  margin: "21px -7px 0",
                  display: "flex",
                  width: "100% !important",
                  justifyContent: "center",
                }}
              >
                <SearchBar
                  value={searchValue}
                  sx={{ width: "95%" }}
                  onChange={searchOnChange}
                  searchName={searchName}
                  handleSearchClick={clearSearch}
                />
              </div>
            )}
          </Grid>
        )} */}
        <Grid item xl={12} sm={12} md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginRight: "16px",
            }}
            className="filter-grid-parent"
          >
            <Grid
              className="filter-grid"
              container
              spacing={1}
              sx={{ ...filterGridSx }}
              display={"flex"}
              alignItems={"flex-end"}
              pt={"20px"}
            >
              {filterClicked && data && data.length > 0 && (
                <>
                  {data.map((ele: any, index: number) => (
                    <Grid item {...ele.gridProps} key={`data - ${index}`}>
                      {ele.children}
                      {console.log(ele.gridProps, data)}
                    </Grid>
                  ))}
                  <Grid
                    item
                    xs={4}
                    sm={2}
                    md={2}
                    xl={1}
                    lg={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      textAlign: "center",
                      height: "44px",
                      fontSize: "14px",
                      paddingTop: "0px !important",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                      }}
                      color="primary.main"
                      onClick={clearFilter}
                    >
                      Clear Filter
                    </Box>
                  </Grid>
                </>
              )}
              {/* we will use it in the future */}
              {/* {!isMediumScreen && ( */}
              <Grid
                item
                xs={filterClicked ? summedProps.xs || 8 : 8}
                sm={filterClicked ? summedProps.sm || 10 : 10}
                md={filterClicked ? summedProps.md || 10 : 10}
                xl={filterClicked ? summedProps.xl || 12 : 12}
                lg={filterClicked ? summedProps.lg || 12 : 12}
              >
                {/* {searchClicked && ( */}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <SearchBar
                    value={searchValue}
                    sx={{ width: "230px" }}
                    onChange={searchOnChange}
                    searchName={searchName}
                    placeholder={"Search..."}
                    handleSearchClick={clearSearch}
                  />
                </div>
                {/* )} */}
              </Grid>
              {/* )} */}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomFilter;
