import React from "react";
import {
  TableCell,
  TableRow,
  Popper,
  Box,
  List,
  Grid,
  Typography,
} from "@mui/material";
import { TextField, AutoCompleteWithTable } from "../../../../components/basic";
import { DeleteIcon, DisabledDelete } from "../../../../assets/icons";

type ProductRowProps = {
  styles?: any;
  row?: any;
  billId?: any;
  handleDelete: (index: any, batch_no?: string, id?: number) => void;
  allRows?: any;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number | string | any
  ) => void;
  handleAutoCompleteChange: (
    newValue: any,
    idx: number | string | any,
    name: string
  ) => false | void;
  index?: number;
  formError?: any;
  totalLength?: number;
  setIsDeleteDialogOpen?: any;
  handleAutocompleteInputChange?: any;
};

const ProductRow = React.memo(
  ({
    styles,
    row,
    billId,
    allRows,
    handleInputChange,
    handleAutoCompleteChange,
    handleDelete,
    index,
    formError,
    totalLength,
    setIsDeleteDialogOpen,
    handleAutocompleteInputChange,
  }: ProductRowProps) => {
    const PopperMy = function (props: any) {
      const { children, ...rest } = props;
      return (
        <Popper
          {...rest}
          placement="bottom-start"
          className="auto-complete-popper"
          sx={{
            width: "700px",
            p: "10px 0px",
            boxSizing: "border-box",
            "& .MuiPaper-root": {
              width: "700px",
              maxHeight: "250px",
              overflow: "hidden",
              borderRadius: "0px 0px 5px 5px",
              boxSizing: "border-box",
            },
            "& .MuiAutocomplete-listbox": {
              overflowX: "hidden",
              maxHeight: "250px",
              p: 0,
            },
          }}
        >
          <Box
            sx={{
              width: "700px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "var(--table-autocomplete-background)",
              p: "0px 10px 0px 0px",
              height: "44px",
              boxSizing: "border-box",
              borderRadius: "5px 5px 0px 0px",
            }}
          >
            <Typography
              sx={{
                width: "50%",
                fontSize: "14px",
                fontWeight: "500",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Product Name
            </Typography>
            <Typography
              sx={{
                width: "20%",
                fontSize: "14px",
                fontWeight: "500",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Batch
            </Typography>
            <Typography
              sx={{
                width: "20%",
                fontSize: "14px",
                fontWeight: "500",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Exp.Date
            </Typography>
            <Typography
              sx={{
                width: "20%",
                fontSize: "14px",
                fontWeight: "500",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Stock
            </Typography>
          </Box>
          {props.children}
        </Popper>
      );
    };

    return (
      <TableRow
        sx={{
          "& td, & th": { border: 0 },
          width: "90vw",
        }}
      >
        <TableCell
          align="left"
          sx={{
            width: "12%",
            "& .MuiAutocomplete-root": {
              height: "100%",
            },
            "& input": {
              height: "100%",
            },
            "& .MuiInputBase-root": {
              height: "32px",
              p: "0px",
              pr: "0px",
            },
            "& .MuiOutlinedInput-root": {
              p: "0px",
              pr: "0px",
            },
          }}
        >
          {!row.isId ? (
            <AutoCompleteWithTable
              placeholder="Select Product"
              name="product_name"
              value={row?.product_name}
              onChange={(e: any, newValue: any) =>
                handleAutoCompleteChange(newValue, index, "product_name")
              }
              options={allRows}
              renderOption={(props: any, option: any) => {
                return (
                  <List
                    {...props}
                    key={option.ID}
                    className="table-body-lists-div"
                    sx={{
                      width: "700px",
                      borderRadius: 0,
                      boxSizing: "border-box",
                      m: "0px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "primary.lighter",
                      },
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="700px"
                      className="list-row"
                      sx={{
                        boxSizing: "border-box",
                        m: "0px",
                        p: "0px 10px 0px 0px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          width: "50%",
                          textAlign: "left",
                          p: "0px 10px",
                        }}
                      >
                        {option.product_name}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          width: "20%",
                          textAlign: "left",
                          p: "0px 10px",
                        }}
                      >
                        {option.batch_no}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          width: "20%",
                          textAlign: "left",
                          p: "0px 10px",
                        }}
                      >
                        {option.expires_at
                          ? option.expires_at?.split("-").reverse().join("/")
                          : ""}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          width: "20%",
                          textAlign: "left",
                          p: "0px 10px",
                        }}
                      >
                        {Math.ceil(option.stock_qty / option.pack_type) || 0}
                      </Typography>
                    </Box>
                  </List>
                );
              }}
              // inputValue={row?.product_name_input_value}
              // onInputChange={(e: any, newValue: any) => {
              //   handleAutocompleteInputChange(
              //     {
              //       target: {
              //         name: "product_name_input_value",
              //         value: e?.target?.value || "",
              //       },
              //     } as any,
              //     index
              //   );
              // }}
              // noOptionsText="Please enter 3 or more characters"
              getOptionLabel={(option: any) => option?.product_name}
              sx={{
                width: "100%",
                height: "34px",
                "& .MuiFormControl-root": {
                  height: "32px",
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "& .MuiInputBase-root .MuiInputBase-input": {
                  padding: 0,
                  fontSize: "12px",
                  fontWeight: "400",
                },
              }}
              PopperComponent={PopperMy}
              isHideClearIcon
            />
          ) : (
            <TextField
              name="product_name"
              value={row.product_name.product_name}
              disabled={true}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          )}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="batch_no"
            value={row.batch_no}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
              "& .MuiInputBase-input": {
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="expiry_date"
            value={
              row.expiry_date
                ? row?.expiry_date?.split("-").reverse().join("/")
                : ""
            }
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        {/* <TableCell
          align="left"
          sx={{
            width: "6%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="pack"
            value={row.pack}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell> */}
        {billId && (
          <TableCell
            align="center"
            sx={{
              width: "6%",
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          >
            <TextField
              name="used_qty"
              value={row.used_strip_qty}
              disabled={true}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          </TableCell>
        )}
        {billId && (
          <TableCell
            align="center"
            sx={{
              width: "6%",
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          >
            <TextField
              name="used_qty"
              value={row.used_qty}
              disabled={true}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          </TableCell>
        )}
        <TableCell
          align="left"
          sx={{
            width: "6%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="strip_qty"
            value={row.strip_qty}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
            onChange={(e: any) => handleInputChange(e, index)}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            border: Boolean(formError[index as number]?.qty)
              ? "2px solid red !important"
              : "",
          }}
        >
          <TextField
            name="qty"
            value={row.qty}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
            onChange={(e: any) => handleInputChange(e, index)}
          />
        </TableCell>

        <TableCell
          align="left"
          sx={{
            width: "6%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="ptr"
            value={row.ptr}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "7%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="mrp"
            value={(Number(row?.mrp) || 0).toFixed(2)}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "5%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="discount"
            value={row.discount}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            backgroundColor: "var(--table-cell-disabled-background)",
            width: "10%",
          }}
        >
          <TextField
            name="tax"
            value={row.tax}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          className="last-cell"
          align="right"
          sx={{
            backgroundColor: "var(--disabled-background)",
            width: "10%",
            position: "relative",
          }}
        >
          <TextField
            name="amount"
            value={(Number(row?.amount) || 0).toFixed(2)}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
            onChange={(e: any) => handleInputChange(e, index)}
          />

          <Grid className="deleteIconGrid">
            {(index === 0 && totalLength === 1) || row.used_qty > 0 ? (
              <DisabledDelete className="delete-icon" />
            ) : (
              <DeleteIcon
                className="delete-icon"
                onClick={() => {
                  setIsDeleteDialogOpen({
                    isOpen: true,
                    selectedRow: {
                      index: index,
                      batchNo: row.row_batch_id,
                      id: row?.isId,
                    },
                  });
                }}
              />
            )}

            <TextField
              width="0px"
              name="discount_amount"
              value={row.discount_amount}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
              onChange={(e: any) => handleInputChange(e, index)}
            />
            <TextField
              width="0px"
              name="tax_amount"
              value={row.tax_amount}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
              onChange={(e: any) => handleInputChange(e, index)}
            />
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
);

export default ProductRow;
