import React from "react";
import { Grid } from "@mui/material";
import { SettingsIcon } from "../../../assets/icons";
import {
  Select,
  DatePickerWithTime,
  TextField,
  InfiniteScroller,
} from "../../../components/basic";
import { purchaseBillInfoConst } from "../../../constants/displayText";

type InfoType = {
  supplier_name: string | null;
  department: string | null;
  invoice_value: string | null;
  invoice_date: string | null;
  bill_no: string | null;
  disabled: boolean;
  supplier?: string;
  department_name?: string;
  to_dept_id?: number;
  to_dept_name?: string;
};

type InfoProps = {
  info: InfoType;
  setInfo: React.Dispatch<React.SetStateAction<InfoType>>;
  suppliersList: any;
  departmentList: any;
  isGoodsIssue: any;
  setSequenceDialogBox?: any;
  handleInvoiceNoChange?: any;
  getAllSuppliersList?: any;
  defaultOptions?: any;
  customSequence?: boolean;
  errors: any;
  isEdit?: boolean;
  handleDepartmentChange?: any;
  issuerList?: any;
};

const Info = React.memo(
  ({
    info,
    setInfo,
    suppliersList,
    departmentList,
    isGoodsIssue,
    setSequenceDialogBox,
    handleInvoiceNoChange,
    getAllSuppliersList,
    customSequence,
    errors,
    isEdit,
    handleDepartmentChange,
    issuerList,
  }: InfoProps) => {
    const {
      supplierName,
      department,
      invoiceValue,
      invoiceDate,
      billSequenceNo,
    } = purchaseBillInfoConst;

    const handleInputChange = (e: any) => {
      const { name, value } = e.target;
      setInfo((prev: any) => ({
        ...prev,
        [name]: value,
      }));
      if (name === "supplier_name") {
        handleDepartmentChange();
      }
    };

    const handleDateChange = (newValue: any) => {
      setInfo((prev: any) => ({
        ...prev,
        invoice_date: newValue.toString(),
      }));
    };

    const styles = {
      selectGrid: {
        width: "100%",
        height: "44px",
        boxShadow: "none",
        borderRadius: "5px",
        borderColor: "var(--primary-border-color)",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },

      textFieldStyle: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
          height: "44px",
          borderRadius: "5px",
          paddingLeft: "8px",
          boxShadow: "none",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
    };

    return (
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          m: "0px 0px 30px 0px",
          width: "90vw",
          minHeight: "100px",
          height: "auto",
        }}
        spacing={1}
      >
        {isEdit ? (
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <TextField
              value={info.supplier}
              label={supplierName}
              placeholder="Enter supplier name"
              name="supplier"
              // onChange={handleInputChange}
              disabled
              formControlStyle={{ width: "100%" }}
              sx={{
                ...styles.textFieldStyle,
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <InfiniteScroller
              loadOptions={getAllSuppliersList}
              options={suppliersList}
              handleOnChange={handleInputChange}
              label={supplierName}
              name="supplier_name"
              value={info.supplier_name}
              width="100%"
            />
          </Grid>
        )}
        {isEdit ? (
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <TextField
              value={info.department_name}
              label={isGoodsIssue ? "Receiver" : department}
              placeholder="department"
              name="department_name"
              // onChange={handleInputChange}
              disabled
              formControlStyle={{ width: "100%" }}
              sx={{
                ...styles.textFieldStyle,
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <Select
              value={info.department}
              onChange={handleInputChange}
              placeholder={"Select Department"}
              options={departmentList}
              label={department}
              name="department"
              formControlStyle={{ width: "100%" }}
              width=""
              sx={{
                ...styles.selectGrid,
              }}
              disabled={info.disabled}
            />
          </Grid>
        )}
        {isEdit && isGoodsIssue ? (
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <TextField
              value={info.to_dept_name}
              label={"Receiver"}
              placeholder=""
              name="to_dept_name"
              disabled
              sx={{
                ...styles.textFieldStyle,
              }}
            />
          </Grid>
        ) : !isEdit && isGoodsIssue ? (
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <Select
              value={info.to_dept_id}
              onChange={handleInputChange}
              placeholder={"Select Receiver"}
              options={issuerList}
              label={"Receiver"}
              name="to_dept_id"
              width=""
              sx={{
                ...styles.selectGrid,
              }}
              disabled={info.disabled}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
          <TextField
            value={info.invoice_value}
            label={invoiceValue}
            placeholder="Enter Invoice Value"
            name="invoice_value"
            onChange={handleInputChange}
            disabled
            sx={{
              ...styles.textFieldStyle,
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          xl={2}
          sx={{
            "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container  >div >div .rmdp-input":
              {
                boxSizing: "border-box",
                width: "100%",
              },
          }}
        >
          <DatePickerWithTime
            value={info.invoice_date}
            label={invoiceDate}
            onChange={handleDateChange}
            borderColor="var(--primary-border-color)"
            placeholder="Enter Invoice Date"
            formControlStyle={{ width: "100%" }}
            style={{
              boxShadow: "none",
              width: "100%",
              height: "44px",
              borderRadius: "5px",
            }}
          />
        </Grid>
        {customSequence ? (
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <TextField
              value={info.bill_no}
              label={billSequenceNo}
              helperText={errors?.bill_no}
              placeholder="Enter Bill Number"
              onChange={handleInputChange}
              endPlaceholderIcon={
                <SettingsIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSequenceDialogBox(true);
                  }}
                />
              }
              onBlur={handleInvoiceNoChange}
              name="bill_no"
              sx={{
                ...styles.textFieldStyle,
              }}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }
);

export default Info;
