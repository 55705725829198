import React from "react";
import {
  TableCell,
  TableRow,
  Popper,
  Box,
  List,
  Grid,
  Typography,
} from "@mui/material";
import {
  TextField,
  Select,
  DatePicker,
  AutocompleteWithTable,
} from "../../../../components/basic";
import { DeleteIcon, DisabledDelete } from "../../../../assets/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import moment from "moment";

type ProductRowProps = {
  styles?: any;
  row?: any;
  billId?: any;
  handleDelete: (index: number, id: number | string) => void;
  allRows?: any;
  summary?: any;
  handleInputChange: any;
  handleDateChange: any;
  handleAutoCompleteChange: any;
  index: number;
  taxesOptions: any;
  error?: any;
  setIsOpenErrorModal?: any;
  billProducts?: any;
};

const ProductRow = React.memo(
  ({
    styles,
    index,
    row,
    billId,
    allRows,
    handleInputChange,
    handleDateChange,
    handleAutoCompleteChange,
    handleDelete,
    taxesOptions,
    error,
    setIsOpenErrorModal,
    billProducts,
  }: ProductRowProps) => {
    const PopperMy = function (props: any) {
      const { children, ...rest } = props;
      return (
        <Popper
          {...rest}
          placement="bottom-start"
          className="auto-complete-popper"
          sx={{
            height: "40px",
            p: "10px 0px",
            "& .MuiPaper-root": {
              width: "200px",
              maxHeight: "180px",
              overflow: "hidden",
            },
          }}
        >
          <Box
            sx={{
              width: "200px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "var(--table-autocomplete-background)",
              height: "43px",
            }}
          >
            <Typography
              sx={{
                width: "150px",
                height: "40px",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "left",
                pl: "5%",
                display: "flex",
                alignItems: "center",
              }}
            >
              product Name
            </Typography>
          </Box>
          {props.children}
        </Popper>
      );
    };

    const { appConfiguration } = useSelector(
      (state: RootState) => state.appConfiguration
    );

    const calculateMonthDifference = (date: any) => {
      const today = moment();
      const givenDate = moment(date, "DD/MM/YYYY");

      const monthDifference = today.diff(givenDate, "months");
      return Math.abs(monthDifference);
    };

    const handleBlur = () => {
      const monthDifference = calculateMonthDifference(row.expires_at);
      if (monthDifference <= 6) {
        setIsOpenErrorModal({
          isOpen: true,
          content: `This product will expire in ${monthDifference} months.`,
        });
      }
    };

    return (
      <TableRow
        sx={{
          "& td, & th": { border: 0 },
          width: "90vw",
        }}
      >
        <TableCell
          align="left"
          sx={{
            width: "12%",
            "& .MuiAutocomplete-root": {
              height: "31px",
            },
            "& .MuiInputBase-root": {
              height: "32px",
              p: "0px",
              pr: "0px",
            },
            "& .MuiOutlinedInput-root": {
              p: "0px",
              pr: "0px",
            },
            border: !!error?.product_name ? "2px solid #d32f2f !important" : "",
          }}
        >
          <AutocompleteWithTable
            placeholder="Select Product"
            name="product_name"
            value={row?.product_name}
            onChange={(e: any, newValue: any) =>
              handleAutoCompleteChange(newValue, index, "product_name")
            }
            options={allRows}
            renderOption={(props: any, option: any) => {
              return (
                <List
                  {...props}
                  key={option.ID}
                  sx={{
                    width: "450px",
                    borderRadius: 0,
                  }}
                >
                  <Box className="list-box" display="flex" width="450px">
                    <Typography
                      variant="h6"
                      sx={{ width: "150px", textAlign: "left", pl: "5%" }}
                    >
                      {option.name}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ width: "150px", textAlign: "left", pl: "5%" }}
                    >
                      {option.generic_name}
                    </Typography>
                  </Box>
                </List>
              );
            }}
            isDisabled={billId && row.used_qty > 0}
            getOptionLabel={(option: any) => option.name}
            sx={{
              width: "100%",
              height: "34px",
              "& .MuiFormControl-root": {
                height: "32px",
              },

              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "& .MuiInputBase-root .MuiInputBase-input": {
                padding: 0,
                fontSize: "12px",
                fontWeight: "400",
              },
            }}
            inputValue={row?.product_value}
            // onInputChange={(e: any) =>
            // handleInputChange(
            //   { target: { name: "product_value", value: e?.target?.value } },
            //   index
            // )
            // }
            PopperComponent={PopperMy}
            isHideClearIcon
            onBlur={() => console.log("blur")}
          />
        </TableCell>
        {billId && (
          <TableCell
            sx={{
              width: "4%",
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          >
            <TextField
              name="used_qty"
              value={row.used_qty}
              disabled={true}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          </TableCell>
        )}
        {billId && (
          <TableCell
            sx={{
              width: "4%",
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          >
            <TextField
              name="used_free"
              value={row.used_free}
              disabled={true}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          </TableCell>
        )}
        <TableCell
          align="left"
          sx={{
            width: "6%",
            border: !!error?.qty ? "2px solid #d32f2f !important" : "",
          }}
        >
          <TextField
            name="qty"
            value={row.qty}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
          }}
        >
          <TextField
            name="free"
            value={row.free}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        {appConfiguration?.lab_purchase_discount !== "overall" && (
          <TableCell align="center" sx={{ width: "10%", p: "0px 0px" }}>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  width: "60%",
                  "& .MuiFormControl-root": {
                    width: "100%",
                  },
                }}
              >
                <TextField
                  name="discount"
                  value={row.discount}
                  onChange={(e: any) => handleInputChange(e, index)}
                  sx={{
                    ...styles.textFieldStyle,
                    width: "inherit",
                    "& .MuiInputBase-input": {
                      fontSize: "12px",
                      fontWeight: "400",
                      textAlign: "left",
                      p: "0px 10px",
                    },
                  }}
                />
              </Grid>

              <Grid
                className="select-grid"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "40%",
                  "& .MuiFormControl-root": { width: "100%" },

                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    borderWidth: 0,
                    height: "34px",
                    maxHeight: "34px",
                    p: 0,
                  },

                  "& .MuiInputBase-root": {
                    padding: "0px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  },

                  "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "primary.main",
                      borderWidth: "1px",
                    },

                  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderWidth: 0,
                    },

                  "& .MuiGrid-root": {
                    width: "100%",
                  },
                }}
              >
                <Select
                  label=""
                  options={[
                    { id: "₹", name: "₹" },
                    { id: "%", name: "%" },
                  ]}
                  placeholder="%"
                  name="row_discount_type"
                  value={row.row_discount_type}
                  onChange={(e: any) => handleInputChange(e, index)}
                  width="inherit"
                  sx={{
                    height: "34px",
                    borderRadius: "0px",
                    "& input": {
                      height: "100%",
                    },
                  }}
                  formControlStyle={{
                    "& .MuiOutlinedInput-input": {
                      height: "100%",
                      "& .MuiBox-root": {
                        height: "100%",
                      },
                    },
                  }}
                  isRemoveClearIcon
                />
              </Grid>
            </Grid>
          </TableCell>
        )}
        <TableCell
          align="left"
          sx={{
            width: "10%",
            border: !!error?.batch_no ? "2px solid #d32f2f !important" : "",
          }}
        >
          <TextField
            name="batch_no"
            value={row.batch_no}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
              width: "inherit",
            }}
          />
        </TableCell>
        {/* We will add this one in the future */}
        {/* <TableCell
          align="left"
          sx={{
            width: "10%",
          }}
        >
          <TextField
            name="barcode"
            value={row.barcode}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
              width: "inherit",
            }}
          />
        </TableCell> */}
        <TableCell
          align="left"
          sx={{
            height: "34px",
            p: "0px 0px",
            width: "10%",
            "& .MuiGrid-root": {
              height: "34px",
              width: "100%",
            },
            "& .MuiFormControl-root": {
              height: "34px",
              width: "100%",
              position: "static",
            },
            "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container ": {
              height: "34px",
              width: "100%",
              border: 0,
              p: "0px 10px",
            },

            "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container  >div >div .rmdp-input":
              {
                height: "20px",
                width: "95%",
                p: "0px 10px",
                border: 0,
                m: 0,
                borderWidth: "0px",
                borderRadius: 0,
              },

            "& .rmdp-wrapper": {
              position: "initial",
            },
            border: !!error?.expires_at ? "2px solid #d32f2f !important" : "",
          }}
        >
          <DatePicker
            label=""
            name="expires_at"
            value={row.expires_at}
            onChange={(e: any, newValue: any) =>
              handleDateChange(index, newValue, "expires_at")
            }
            height="32px"
            format="DD-MM-YYYY"
            style={{
              boxShadow: "none",
              border: "0px solid rgba(138, 138, 138, 0.32)",
              width: "90%",
              height: "34px",
              borderRadius: "5px",
              padding: "0px",
            }}
            onBlur={handleBlur}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "6%",
            border: !!error?.tax ? "2px solid #d32f2f !important" : "",
          }}
        >
          <Grid
            className="select-grid"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "150px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
                borderWidth: 0,
                height: "34px",
                maxHeight: "34px",
                p: 0,
              },

              "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "primary.main",
                  borderWidth: "1px",
                },

              "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderWidth: 0,
                },
            }}
          >
            <Select
              selectedType="object"
              options={taxesOptions}
              name="tax"
              value={row.tax}
              onChange={(e: any) => handleInputChange(e, index)}
              placeholder="Select"
              width="150px"
              sx={{
                width: "150px",
                height: "34px",
                borderRadius: "0px",
                "& .MuiOutlinedInput-input": {
                  padding: "14px 2px 14px 10px",
                },
                "& input": {
                  height: "100%",
                },
              }}
              formControlStyle={{
                "& .MuiOutlinedInput-input": {
                  height: "100%",
                  "& .MuiBox-root": {
                    height: "100%",
                  },
                },
              }}
            />
          </Grid>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "6%",
            border: !!error?.p_rate ? "2px solid #d32f2f !important" : "",
          }}
        >
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              "&:has(.Mui-focused) .icon-grid": {
                backgroundColor: "var(--table-cell-background)",
              },
            }}
          >
            <TextField
              width="100px"
              name="p_rate"
              value={row.p_rate}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                ...styles.textFieldStyle,
                "& .MuiInputBase-input": {
                  p: "0px 10px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "left",
                },
              }}
            />
          </Grid>
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            border: !!error?.mrp ? "2px solid #d32f2f !important" : "",
          }}
        >
          <TextField
            width="100px"
            name="mrp"
            value={row.mrp}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
            }}
          />
        </TableCell>
        <TableCell
          className="last-cell"
          align="right"
          sx={{
            backgroundColor: "var(--disabled-background)",
            width: "16%",
            position: "relative",
          }}
        >
          <TextField
            name="amount"
            value={row.amount}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{ ...styles.textFieldStyle }}
          />

          <Grid className="deleteIconGrid">
            {billProducts.length === 1 ? (
              <DisabledDelete className="delete-icon" />
            ) : (
              <DeleteIcon
                className="delete-icon"
                onClick={() => handleDelete(index, row.id)}
              />
            )}

            <TextField
              width="0px"
              name="discountAmount"
              value={row.discount_amount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
            <TextField
              width="0px"
              name="taxAmount"
              value={row.tax_amount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
);

export default ProductRow;
