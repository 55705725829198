import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"; // Import the ErrorOutlineIcon
import DeleteButton from "../buttongroup/deleteButton";
import CancelButton from "../buttongroup/cancelButton";

interface DeleteConfirmationDialogProps {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  backdropClick?: boolean;
}

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  open,
  loading,
  onClose,
  onConfirm,
  backdropClick = false,
}) => {
  return (
    <Dialog
      open={open}
      onClose={backdropClick ? onClose : undefined}
      fullWidth
      maxWidth="xs"
      sx={{
        alignItems: "flex-start",
        "& .MuiPaper-root": {
          position: "absolute",
          top: "0px",
          height: "145px",
          borderRadius: "10px",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: "1rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography color="error" component="span">
          <ErrorOutlineIcon
            sx={{
              fontSize: "1.5rem",
              verticalAlign: "middle",
              marginRight: "0.5rem",
              color: "#F7525A",
            }}
          />
        </Typography>
        Are you sure you want to delete?
      </DialogTitle>
      <DialogActions
        sx={{ display: "flex", justifyContent: "flex-start", margin: "15px" }}
      >
        <Box sx={{ display: "flex", gap: "20px" }}>
          <DeleteButton
            handleClick={onConfirm}
            loading={loading}
          />
          <CancelButton
            handleClick={onClose}
            isDisabled={loading}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
