import React, { useCallback, useEffect, useRef, useState } from "react";
import { ConfirmationDialog, DataTable } from "../../../components/shared";
import CustomFilter from "../../../components/shared/customFilter";
import { Box, Typography, debounce } from "@mui/material";
import { DeleteIcon, EditIcon, PlusIcon, ViewIcon } from "../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { Sort, DatePicker, Select } from "../../../components/basic";
import { InfiniteScroller } from "../../../components/basic";
import { useNavigate } from "react-router-dom";
import {
  deletePaymentById,
  getAllPatientsList,
  getAllPayments,
  getCardOptionDetails,
} from "../../../services/paymentService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { paymentOptions } from "../../../constants/dropdownOptions";
import {
  setCurrentPage,
  setFilterDataValues,
  setHandleClearSearch,
  setSortField,
} from "../../../redux/slices/pagination";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import {
  commonDateFormatter,
  formatTwelveHoursTime,
} from "../../../utils/DateTimeFormatUtils";
import PermissionUtils from "../../../utils/PermissionUtils";
import { RouteUrls } from "../../../constants/routes";

const { billingUrl, salesBillUrl, view } = RouteUrls;

type GridRowData = Record<string, unknown>;
export default function PaymentsList() {
  const { can } = PermissionUtils();
  const [loading, setLoading] = useState(false);
  const { paymentList, filterData, count, sortField } = useSelector(
    (state: RootState) => ({
      paymentList: state?.pagination?.paymentList,
      filterData: state?.pagination?.paymentList?.filterData,
      count: state?.pagination?.paymentList?.count,
      sortField: state?.pagination?.paymentList?.sortedField,
    })
  );
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState(paymentOptions);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<{
    isOpen: boolean;
    id: any;
  }>({
    isOpen: false,
    id: null,
  });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const initialFilterData: any = {
    patient_id: "",
    term: "",
    from_date: commonDateFormatter(new Date(), "DD/MM/YYYY"),
    to_date: commonDateFormatter(new Date(), "DD/MM/YYYY"),
    search: "",
  };

  const [patientsList, setPatientsList] = useState<any>([]);
  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortField?.field === field) {
      dispatch(
        setSortField({
          key: "paymentList",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
    if (sortField?.field !== field) {
      dispatch(
        setSortField({
          key: "paymentList",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
  };

  const testingBillData = [
    {
      id: 1,
      ref_id: 1,
      bill_no: 2,
      patient_name: "John",
      date: "12-04-2024",
      cash_received: "100",
      type: "Bank",
    },
    {
      id: 2,
      ref_id: 2,
      bill_no: 3,
      patient_name: "Adam",
      date: "12-04-2024",
      cash_received: "100",
      type: "Excess",
    },
    {
      id: 3,
      ref_id: 3,
      bill_no: 4,
      patient_name: "Smith",
      date: "12-04-2024",
      cash_received: "100",
      type: "Advance",
    },
  ];
  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        {can("payment_edit") && !row.is_cancel && !row.is_return && (
          <div style={{ cursor: "pointer" }}>
            <EditIcon onClick={() => navigate(`/payment/edit/${row.id}`)} />
          </div>
        )}
        {can("payment_view") && (
          <div style={{ cursor: "pointer" }}>
            <ViewIcon onClick={() => navigate(`/payment/view/${row.id}`)} />
          </div>
        )}
        {can("payment_delete") && !row.is_cancel && !row.is_return && (
          <div style={{ cursor: "pointer" }}>
            <DeleteIcon
              onClick={() => setIsOpenDeleteModal({ isOpen: true, id: row.id })}
            />
          </div>
        )}
      </div>
    );
  };
  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      dispatch(
        setFilterDataValues({
          key: "paymentList",
          name: name,
          value: newValue.validatedValue[0],
        })
      );

      dispatch(setCurrentPage({ key: "paymentList", value: 0 }));
    }
  };
  const supplierPagination = useRef(1);
  const searchSupplierPagination = useRef({ search: "", page: 1 });

  const debouncedGetAllSupplierList = debounce(
    async (currentValue, prevOptions, callback) => {
      try {
        return await getAllPatientsList({
          search: currentValue,
          page:
            searchSupplierPagination.current?.search === currentValue
              ? searchSupplierPagination.current?.page
              : 1,
          limit: 10,
        }).then((result: any) => {
          let data = result?.data?.data;
          const formattedSuppliersList = data.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: { value: string | number; label: string | number }) =>
              !prevOptions.some(
                (prevOption: {
                  value: string | number;
                  label: string | number;
                }) => prevOption.value === option.value
              )
          );
          setPatientsList(uniqueOptions);
          searchSupplierPagination.current.page =
            searchSupplierPagination.current?.search === currentValue
              ? searchSupplierPagination.current?.page + 1
              : 1;
          searchSupplierPagination.current.search = currentValue;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
      } catch (error) {
        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
      }
    },
    300
  );

  const getAllPatients = async (currentValue: any, prevOptions: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllSupplierList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page =
          supplierPagination.current === Math.ceil(prevOptions.length / 10)
            ? supplierPagination.current
            : Math.ceil(prevOptions.length / 10) + 1;
        return await getAllPatientsList({
          search: currentValue,
          page: page,
          limit: 10,
        }).then((result: any) => {
          let data = result?.data?.data;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: { value: string | number; label: string | number }) =>
              !prevOptions.some(
                (prevOption: {
                  value: string | number;
                  label: string | number;
                }) => prevOption.value === option.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setPatientsList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }
          const currentValue = supplierPagination.current;
          supplierPagination.current = currentValue + 1;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          console.log("hasMore", supplierPagination.current, hasMore);

          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };
  const columns: GridColDef[] = [
    {
      field: "id",
      flex: 0.5,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("id")}
        >
          <Typography variant="h5" fontSize={14}>
            ID
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "id" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "id" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.id}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "bill_no",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("patient_bill_id")}
        >
          <Typography variant="h5" fontSize={14}>
            Bill No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "patient_bill_id" &&
              sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "patient_bill_id" &&
              sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h5"
          sx={{
            fontSize: 14,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            overflowY: !row?.ref_no ? "unset" : "scroll",
            height: "100%",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {row?.patient_bills?.length > 0 ? (
            <>
              {row.patient_bills?.map((bill: any, index: number) => (
                <a
                  style={{ textDecoration: "unset" }}
                  rel="noreferrer"
                  target="_blank"
                  href={`${billingUrl}${view}/${bill.bill_id}`}
                >
                  {bill?.bill_no}
                  {index + 1 !== row?.patient_bills?.length ||
                  (index + 1 === row?.patient_bills?.length &&
                    row?.sales_bills?.length > 0)
                    ? ","
                    : ""}{" "}
                </a>
              ))}
            </>
          ) : (
            ""
          )}
          {row?.sales_bills?.length > 0 ? (
            <>
              {row.sales_bills?.map((bill: any, index: number) => (
                <a
                  style={{ textDecoration: "unset" }}
                  rel="noreferrer"
                  target="_blank"
                  href={`${salesBillUrl}${view}/${bill.bill_id}`}
                >
                  {bill?.bill_no}
                  {index + 1 !== row?.sales_bills?.length ? "," : ""}{" "}
                </a>
              ))}
            </>
          ) : (
            ""
          )}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "Patient’s",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("patient_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Patient’s Name
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "patient_name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "patient_name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.patient_name}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "date",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("date_created")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "date_created" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "date_created" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h5"
          fontSize={14}
          fontWeight={!row?.ref_no ? 600 : 400}
        >
          {!row?.ref_no
            ? row?.date_created
            : row?.date_created
            ? formatTwelveHoursTime(row?.date_created)
            : ""}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "cash_received",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("cash_received")}
        >
          <Typography variant="h5" fontSize={14}>
            Cash Received
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "cash_received" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "cash_received" &&
              sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h5"
          fontSize={14}
          fontWeight={!row?.ref_no ? 600 : 400}
        >
          {row?.cash_received}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "type",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("type")}
        >
          <Typography variant="h5" fontSize={14}>
            Type
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "type" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "type" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.type}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    ...(can("payment_edit") || can("payment_view") || can("payment_delete")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return !row?.ref_no ? null : <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "patient_id" && value) {
      const finalValue = patientsList.filter((obj: any) => {
        if (obj.value === value) {
          return obj;
        }
      });
      dispatch(
        setFilterDataValues({
          key: "paymentList",
          name: name,
          value: finalValue[0],
        })
      );

      dispatch(setCurrentPage({ key: "paymentList", value: 0 }));
      return;
    }

    dispatch(
      setFilterDataValues({ key: "paymentList", name: name, value: value })
    );

    dispatch(setCurrentPage({ key: "paymentList", value: 0 }));
  };

  const clearFilters = () => {
    dispatch(
      setFilterDataValues({
        key: "paymentList",
        name: "clearFilter",
        value: initialFilterData,
      })
    );
  };
  const [allPaymentList, setAllPaymentList] = useState<any>([]);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(testingBillData.length);
    if (filterData.patient_id) {
      setPatientsList([filterData.patient_id]);
    }
  }, []);

  const debouncedGetAllPayments = useCallback(
    debounce((data: any) => {
      setLoading(true);
      //this is for future use
      getAllPayments(data)
        .then((res: any) => {
          if (res.data) {
            const newPaymentList = [...res.data?.result] as any;

            if (can("payment_list_total") && res.data?.result?.length > 0) {
              newPaymentList.push({
                date_created: "Total :",
                cash_received: res.data.sum_net_total_amt,
              });
            }
            setAllPaymentList(() => {
              const paymentList = newPaymentList.map((bill: any) => ({
                ...bill,
                patient_bills: bill?.patient_bills
                  ? JSON.parse("[" + bill?.patient_bills + "]")?.filter(
                      (ele: any) => Boolean(ele?.bill_no)
                    )
                  : [],
                sales_bills: bill?.sales_bills
                  ? JSON.parse("[" + bill?.sales_bills + "]")?.filter(
                      (ele: any) => Boolean(ele?.bill_no)
                    )
                  : [],
              }));

              return paymentList;
            });
            setPageCount(res.data.total as any);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("error getAllPayment", err);
        });
    }, 300),
    []
  );

  // this is for future use
  useEffect(() => {
    const data = {
      page: Number(paymentList.page) + 1,
      limit: paymentList.pageSize,
      column: sortField?.field,
      order: sortField?.order,
      filter_from_date: filterData?.from_date
        ? commonDateFormatter(filterData?.from_date, "YYYY-MM-DD")
        : null,
      filter_to_date: filterData?.to_date
        ? commonDateFormatter(filterData?.to_date, "YYYY-MM-DD")
        : null,
      filter_patient: filterData?.patient_id?.value,
      term_filter: filterData.term,
      search: filterData.search,
    };

    debouncedGetAllPayments(data);
  }, [paymentList, sortField, filterData]);

  const filterChildren = [
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <InfiniteScroller
          loadOptions={getAllPatients}
          options={patientsList}
          handleOnChange={handleInputChange}
          name={"patient_id"}
          label={"Select Patient"}
          value={filterData.patient_id?.value}
          width="100%"
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <Select
          value={filterData.term}
          placeholder="Select term"
          options={paymentTypeOptions}
          label={"Term"}
          name={"term"}
          formControlStyle={{ width: "100%" }}
          width=""
          onChange={handleInputChange}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <DatePicker
          label="From Date"
          name="fromDate"
          placeholder="Select Date"
          width="95%"
          formControlStyle={{
            width: "90%",
            "& .icon-tabler": {
              left: "95%",
              color: "rgba(0, 0, 0, 0.54)",
            },
          }}
          onChange={(e: any, newValue: any) =>
            handleDateChange(e, newValue, "from_date")
          }
          format="DD/MM/YYYY"
          value={filterData.from_date}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <DatePicker
          label="To Date"
          name="toDate"
          placeholder="Select Date"
          width="95%"
          formControlStyle={{
            width: "90%",
            "& .icon-tabler": {
              left: "95%",
              color: "rgba(0, 0, 0, 0.54)",
            },
          }}
          onChange={(e: any, newValue: any) =>
            handleDateChange(e, newValue, "to_date")
          }
          format="DD/MM/YYYY"
          value={filterData.to_date}
        />
      ),
    },
  ];

  useEffect(() => {
    getCardOptionDetails().then((res: any) => {
      if (res.data) {
        const extraOptions = res?.data?.map((ele: any) => ({
          ...ele,
          id: ele.name,
        }));
        const options = [...paymentTypeOptions, ...extraOptions];
        setPaymentTypeOptions(options);
      }
    });
  }, []);

  const handleConfirmDelete = () => {
    if (isOpenDeleteModal.id) {
      setIsDeleteLoading(true);
      deletePaymentById(isOpenDeleteModal.id)
        .then((res: any) => {
          if (res) {
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          }
          setIsDeleteLoading(false);
          const data = {
            page: Number(paymentList.page) + 1,
            limit: paymentList.pageSize,
            column: sortField?.field,
            order: sortField?.order,
            filter_from_date: filterData?.from_date
              ? commonDateFormatter(filterData?.from_date, "YYYY-MM-DD")
              : null,
            filter_to_date: filterData?.to_date
              ? commonDateFormatter(filterData?.to_date, "YYYY-MM-DD")
              : null,
            filter_patient: filterData.patient_id,
            term_filter: filterData.term,
            search: filterData.search,
          };
          debouncedGetAllPayments(data);
          setIsOpenDeleteModal({ isOpen: false, id: null });
        })
        .catch((err) => {
          console.log(err);
          setIsDeleteLoading(false);
          if (err?.response?.data?.errors) {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.response?.data?.errors,
              })
            );
          }
        });
    }
  };

  return (
    <Box>
      <CustomFilter
        data={filterChildren}
        isFilterEnabled
        isSearchEnabled
        editButtonTitle={can("payment_add") ? "Add" : null}
        clearFilter={clearFilters}
        searchOnChange={handleInputChange}
        searchName={"search"}
        searchValue={filterData.search}
        clearSearch={() =>
          dispatch(
            setHandleClearSearch({
              key: "paymentList",
              value: "",
              name: "search",
            })
          )
        }
        onUpdateButtonClick={() => navigate("/payment/create")}
        appliedFilterCount={count}
        editStartIcon={
          <Box
            sx={{
              "& path": {
                fill: "#fff",
              },
            }}
          >
            <PlusIcon width={"12px"} height={"12px"} />
          </Box>
        }
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={allPaymentList}
        pageCount={pageCount}
        currentPage={"paymentList"}
      />
      <ConfirmationDialog
        open={isOpenDeleteModal.isOpen}
        title="are you surely want to delete"
        handleClick={handleConfirmDelete}
        onClose={() => setIsOpenDeleteModal({ isOpen: false, id: null })}
        loading={isDeleteLoading}
      />
    </Box>
  );
}
