import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { TextField, AutoComplete } from "../../../../../components/basic";
import { DeleteIcon } from "../../../../../assets/icons";

type RowProps = {
  styles?: any;
  index: number;
  row?: any;
  billId?: any;
  handleTreatmentDelete: (id: number) => void;
  handleInputChange: (e: any, id: number | null) => void;
  handleAutoCompleteChange: (
    e: any,
    newValue: any,
    name: string,
    treatmentId: any
  ) => void | false | undefined;
  handleAutoCompleteInputChange: (
    e: any,
    newInputValue: any,
    name: string,
    treatmentId: any
  ) => void;
  itemOptions: any;
  isAutocompleteOptionsLoading?: boolean;
  validationErrors?: any;
};

const Row = React.memo(
  ({
    styles,
    index,
    row,
    itemOptions,
    handleInputChange,
    handleAutoCompleteChange,
    handleAutoCompleteInputChange,
    handleTreatmentDelete,
    isAutocompleteOptionsLoading,
    validationErrors,
  }: RowProps) => {
    return (
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          mt: index === 0 ? "0px" : "10px",
        }}
      >
        <AutoComplete
          key="item"
          label={index === 0 ? "Items" : undefined}
          placeholder="Select Item"
          name="item"
          value={row.item}
          inputValue={row.itemInput}
          inputRef={row?.itemRef}
          disableClearable={false}
          isValueAsObject={true}
          options={itemOptions}
          noOptionsText={
            isAutocompleteOptionsLoading ? "...Loading" : "No Options"
          }
          onChange={(e: any, newValue: any) =>
            handleAutoCompleteChange(e, newValue, "item", row.id)
          }
          onInputChange={(e: any, newValue: any) =>
            handleAutoCompleteInputChange(e, newValue, "itemInput", row.id)
          }
          helperText={
            validationErrors?.treatments[row.id]?.item
              ? validationErrors?.treatments[row.id]?.item
              : true
          }
          width="275px"
          sx={{ ...styles.autocompleteStyle }}
        />
        <TextField
          label={index === 0 ? "Quantity" : undefined}
          placeholder="Enter Quantity"
          name="quantity"
          value={row.quantity}
          inputRef={row?.quantityRef}
          onChange={(e: any) => handleInputChange(e, row.id)}
          helperText={
            validationErrors?.treatments[row.id]?.quantity
              ? validationErrors?.treatments[row.id]?.quantity
              : true
          }
          sx={{ ...styles.textFieldStyle }}
        />
        <TextField
          label={index === 0 ? "Instruction" : undefined}
          placeholder="Enter Instruction"
          name="instruction"
          value={row.instruction}
          onChange={(e: any) => handleInputChange(e, row.id)}
          helperText=""
          sx={{ ...styles.textFieldStyle }}
        />
        <Button
          sx={{ width: "10px", height: "20px", mt: "2px" }}
          onClick={() => handleTreatmentDelete(row.id)}
        >
          <DeleteIcon />
        </Button>
      </Grid>
    );
  }
);

export default Row;
