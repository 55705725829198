import { Box, Typography } from "@mui/material";
import { DialogWrapper } from "../../../../components/shared";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { useEffect, useState } from "react";
import { RouteUrls } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  createPo,
  generateGoodsIssue,
} from "../../../../services/procurementService";
import { SaveButton, TextField } from "../../../../components/basic";

const { procurementUrl, purchaseOrdersUrl } = RouteUrls;
type ConfirmPoModalProps = {
  isOpen: boolean;
  onClose: (isSave?: boolean) => void;
  data: any;
  isGoodsIssue?: boolean;
  handleChangeBatch?: any;
};

const styles = {
  headerContainerStyle: {
    width: "100%",
    borderTop: "1px solid #EBEBEB",
    borderBottom: "1px solid #EBEBEB",
    padding: "10px",
    display: "flex",
    alignItems: "center",
  },
  bodyContainerStyle: {
    width: "100%",
    borderTop: "1px solid #EBEBEB",
    borderBottom: "1px solid #EBEBEB",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    background: "#f9f9f9",
  },
};

const ConfirmPoModal = (props: ConfirmPoModalProps) => {
  const { isOpen, onClose, data, isGoodsIssue, handleChangeBatch } = props;
  const [filterData, setFilterData] = useState<any>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const handleChange = (
    event: any,
    parentIndex: number,
    childIndex: number
  ) => {
    const { name, value } = event.target;
    setFilterData((prevState: any) => {
      const newState = [...prevState];
      if (
        newState[parentIndex] &&
        newState[parentIndex]?.products?.length > 0 &&
        newState[parentIndex]?.products[childIndex]
      ) {
        newState[parentIndex].products[childIndex][name] = value;

        if (
          name === "order_qty" &&
          Number(
            newState[parentIndex]?.products[childIndex]?.batch?.stock_qty
          ) < Number(value)
        ) {
          newState[parentIndex].products[childIndex].error = {
            ...newState[parentIndex]?.products[childIndex]?.error,
            order_qty: "Order quantity cannot exceed the stock qty",
          };
        } else if (
          name === "order_qty" &&
          newState[parentIndex]?.products[childIndex]?.error?.order_qty
        ) {
          newState[parentIndex].products[childIndex].error = {
            ...newState[parentIndex]?.products[childIndex]?.error,
            order_qty: "",
          };
        }
      }

      return newState;
    });
  };

  const handleSave = () => {
    setIsSaveLoading(true);
    createPo({ purchaser_order: filterData })
      .then((res: any) => {
        if (res.data) {
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          navigate(`${procurementUrl}${purchaseOrdersUrl}`);
        }
        setIsSaveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsSaveLoading(false);
      });
  };

  const handleValidation = (index?: number) => {
    let productsHasError = true;
    setFilterData((prevState: any) =>
      prevState?.map((indent: any, idx: number) => {
        if (index === idx) {
          return {
            ...indent,
            products: indent?.products?.map((ele: any) => {
              if (Number(ele?.batch?.stock_qty) < Number(ele?.order_qty)) {
                productsHasError = false;
                return {
                  ...ele,
                  error: {
                    order_qty: "Order quantity cannot exceed the stock qty",
                  },
                };
              }

              return ele;
            }),
          };
        }

        return indent;
      })
    );

    return productsHasError;
  };

  const handleIssue = (index: number) => {
    if (handleValidation(index)) {
      setIsSaveLoading(true);
      const currentGoodsIssue = filterData[index];

      generateGoodsIssue({
        from_dept: currentGoodsIssue?.from_department?.id,
        to_dept: currentGoodsIssue?.department?.id,
        product: currentGoodsIssue?.products?.map((product: any) => ({
          batch_id: product?.batch?.id,
          qty: product?.order_qty,
          product_id: product?.product_id,
          indents: product?.indents,
        })),
      })
        .then((res: any) => {
          if (res.data) {
            setIsSaveLoading(false);
            setFilterData((prevState: any) => {
              const newFilterData = prevState.filter(
                (ele: any, idx: number) => index !== idx
              );

              if (newFilterData?.length === 0) {
                onClose(true);
              }
              return newFilterData;
            });
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          }
        })
        .catch((err: any) => {
          console.log("err", err);
          setIsSaveLoading(false);
          if (err?.response?.data?.errors?.data) {
            const { data, message, error_type } = err.response.data.errors;

            setFilterData((prevState: any) =>
              prevState?.map((indent: any, idx: number) => {
                if (index === idx) {
                  return {
                    ...indent,
                    products: indent?.products?.map((ele: any, i: number) => {

                      if (
                        i === data?.key &&
                        ele.product_id === data?.product_id &&
                        ele.batch?.id === data?.batch_id
                      ) {
                        return {
                          ...ele,
                          batch: {
                            ...ele.batch,
                            stock_qty: data?.qty,
                            name: `${ele?.batch?.batch_no}#${data?.qty}`,
                          },
                          error: {
                            order_qty:
                              "Order quantity cannot exceed the stock qty",
                          },
                        };
                      }

                      return ele;
                    }),
                  };
                }

                return indent;
              })
            );
            dispatch(setSnackBarFailed({ snackBarMessage: message }));
            handleChangeBatch(data);
          } else if (err?.response?.data?.errors) {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.response?.data?.errors,
              })
            );
          } else {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: "Something went wrong",
              })
            );
          }
        });
    }
  };

  useEffect(() => {
    if (data) {
      setFilterData(
        data?.map((ele: any) => {
          return {
            ...ele,
            products: ele?.products?.map((product: any) => {
              if (
                Number(product.order_qty) > Number(product?.batch?.stock_qty)
              ) {
                return {
                  ...product,
                  error: {
                    order_qty: "Order quantity cannot exceed the stock qty",
                  },
                };
              }
              return product;
            }),
          };
        })
      );
    }
  }, [data]);

  return (
    <DialogWrapper
      open={isOpen}
      onClose={() => onClose()}
      handleClick={isGoodsIssue ? null : handleSave}
      loading={isSaveLoading}
      title={isGoodsIssue ? "Confirm Goods Issue" : "Confirm PO"}
      confirmText={"Save"}
      cancelText={"Cancel"}
    >
      <Box>
        {filterData?.map((ele: any, index: number) => (
          <Box mb={2}>
            <Typography variant="h3" mb={2}>
              {isGoodsIssue
                ? `${ele?.from_department?.name} - ${ele?.department_name}`
                : ele.supplier}
            </Typography>
            <Box sx={styles.headerContainerStyle}>
              <Typography sx={{ width: isGoodsIssue ? "25%" : "33%" }}>
                Product
              </Typography>
              <Typography sx={{ width: isGoodsIssue ? "25%" : "33%" }}>
                Required Qty
              </Typography>
              {isGoodsIssue && (
                <Typography sx={{ width: "25%" }}>Batch</Typography>
              )}
              <Typography sx={{ width: isGoodsIssue ? "25%" : "33%" }}>
                {isGoodsIssue ? "Issue" : "Order"} Qty
              </Typography>
            </Box>
            {ele?.products?.map((item: any, idx: number) => (
              <Box sx={styles.bodyContainerStyle}>
                <Typography sx={{ width: isGoodsIssue ? "25%" : "33%" }}>
                  {item?.product_name}
                </Typography>
                <Typography sx={{ width: isGoodsIssue ? "25%" : "33%" }}>
                  {item?.total_requirement}
                </Typography>
                {isGoodsIssue && (
                  <Typography sx={{ width: "25%" }}>
                    {item?.batch?.name}
                  </Typography>
                )}
                {isGoodsIssue ? (
                  <TextField
                    name="order_qty"
                    value={item?.order_qty}
                    sx={{ background: "#fff" }}
                    error={item?.error?.order_qty}
                    onChange={(event: any) => handleChange(event, index, idx)}
                    type="number"
                  />
                ) : (
                  <Typography sx={{ width: isGoodsIssue ? "25%" : "33%" }}>
                    {item?.order_qty}
                  </Typography>
                )}
              </Box>
            ))}
            {isGoodsIssue && (
              <Box
                sx={{
                  width: "100%",
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <SaveButton
                  buttonText={"Issue"}
                  sx={{ width: "100px", height: "40px" }}
                  handleClick={() => handleIssue(index)}
                  loading={isSaveLoading}
                />
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </DialogWrapper>
  );
};

export default ConfirmPoModal;
