import { Box, Typography, debounce } from "@mui/material";
import { LaboratoryIcon, PlusIcon } from "../../../../assets/icons";
import { useCallback, useEffect, useState } from "react";
import { CustomFilter, DataTable } from "../../../../components/shared";
import { DeleteDialog, Sort } from "../../../../components/basic";
import { GridColDef } from "@mui/x-data-grid";
import { DeleteIcon, EditIcon } from "../../../../assets/icons";
import MasterAddAndEditDialog from "./MasterAddAndEditDialog";
import {
  deleteItemGroupMasterById,
  deleteLabResultMasterById,
  deleteMethodologyMasterById,
  getItemsGroupList,
  getLabResultsList,
  getMethodologyList,
} from "../../../../services/billingService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { setSnackBarSuccess } from "../../../../redux/slices/snackbar";
import { setCurrentPage } from "../../../../redux/slices/pagination";
import PermissionUtils from "../../../../utils/PermissionUtils";

type ItemsMasterType = {
  id: number;
  name: string;
};

type GridRowData = Record<string, unknown>;

const dummyData: any = {
  "Items Group": [
    {
      id: 5,
      name: "EMR carge",
      active: "1",
      created_at: "2023-10-12T17:46:47.000000Z",
      updated_at: "2023-10-12T17:46:47.000000Z",
      deleted_at: null,
      created_by: "4",
      updated_by: null,
      deleted_by: null,
      action:
        '<a href="javascript:void(0)" data-toggle="tooltip"  data-id="5" class="btn btn-link btn-info btn-just-icon btn-tble-act editItemGroup"><i class="fa fa-pencil-square-o ico-tble-act" aria-hidden="true"></i><div class="ripple-container"></div></a><a href="javascript:void(0)" data-toggle="tooltip"  data-id="5" class="btn btn-link btn-danger btn-just-icon btn-tble-act deleteItemGroup"><i class="material-icons ico-tble-act">delete_forever</i><div class="ripple-container"></div></a>',
    },
    {
      id: 2,
      name: "HP Item",
      active: "1",
      created_at: "2023-10-12T17:43:44.000000Z",
      updated_at: "2023-10-12T17:43:44.000000Z",
      deleted_at: null,
      created_by: "4",
      updated_by: null,
      deleted_by: null,
      action:
        '<a href="javascript:void(0)" data-toggle="tooltip"  data-id="2" class="btn btn-link btn-info btn-just-icon btn-tble-act editItemGroup"><i class="fa fa-pencil-square-o ico-tble-act" aria-hidden="true"></i><div class="ripple-container"></div></a><a href="javascript:void(0)" data-toggle="tooltip"  data-id="2" class="btn btn-link btn-danger btn-just-icon btn-tble-act deleteItemGroup"><i class="material-icons ico-tble-act">delete_forever</i><div class="ripple-container"></div></a>',
    },
    {
      id: 3,
      name: "IP Bills",
      active: "1",
      created_at: "2023-10-12T17:44:53.000000Z",
      updated_at: "2023-10-12T17:44:53.000000Z",
      deleted_at: null,
      created_by: "4",
      updated_by: null,
      deleted_by: null,
      action:
        '<a href="javascript:void(0)" data-toggle="tooltip"  data-id="3" class="btn btn-link btn-info btn-just-icon btn-tble-act editItemGroup"><i class="fa fa-pencil-square-o ico-tble-act" aria-hidden="true"></i><div class="ripple-container"></div></a><a href="javascript:void(0)" data-toggle="tooltip"  data-id="3" class="btn btn-link btn-danger btn-just-icon btn-tble-act deleteItemGroup"><i class="material-icons ico-tble-act">delete_forever</i><div class="ripple-container"></div></a>',
    },
    {
      id: 1,
      name: "Lab Test",
      active: "1",
      created_at: "2023-10-12T12:30:26.000000Z",
      updated_at: "2023-10-12T12:30:46.000000Z",
      deleted_at: null,
      created_by: "1",
      updated_by: "1",
      deleted_by: null,
      action:
        '<a href="javascript:void(0)" data-toggle="tooltip"  data-id="1" class="btn btn-link btn-info btn-just-icon btn-tble-act editItemGroup"><i class="fa fa-pencil-square-o ico-tble-act" aria-hidden="true"></i><div class="ripple-container"></div></a><a href="javascript:void(0)" data-toggle="tooltip"  data-id="1" class="btn btn-link btn-danger btn-just-icon btn-tble-act deleteItemGroup"><i class="material-icons ico-tble-act">delete_forever</i><div class="ripple-container"></div></a>',
    },
    {
      id: 4,
      name: "OP Bills",
      active: "1",
      created_at: "2023-10-12T17:45:46.000000Z",
      updated_at: "2023-10-12T17:45:46.000000Z",
      deleted_at: null,
      created_by: "4",
      updated_by: null,
      deleted_by: null,
      action:
        '<a href="javascript:void(0)" data-toggle="tooltip"  data-id="4" class="btn btn-link btn-info btn-just-icon btn-tble-act editItemGroup"><i class="fa fa-pencil-square-o ico-tble-act" aria-hidden="true"></i><div class="ripple-container"></div></a><a href="javascript:void(0)" data-toggle="tooltip"  data-id="4" class="btn btn-link btn-danger btn-just-icon btn-tble-act deleteItemGroup"><i class="material-icons ico-tble-act">delete_forever</i><div class="ripple-container"></div></a>',
    },
    {
      id: 6,
      name: "wr",
      active: "1",
      created_at: "2024-02-13T11:04:14.000000Z",
      updated_at: "2024-02-13T11:04:14.000000Z",
      deleted_at: null,
      created_by: "1",
      updated_by: null,
      deleted_by: null,
      action:
        '<a href="javascript:void(0)" data-toggle="tooltip"  data-id="6" class="btn btn-link btn-info btn-just-icon btn-tble-act editItemGroup"><i class="fa fa-pencil-square-o ico-tble-act" aria-hidden="true"></i><div class="ripple-container"></div></a><a href="javascript:void(0)" data-toggle="tooltip"  data-id="6" class="btn btn-link btn-danger btn-just-icon btn-tble-act deleteItemGroup"><i class="material-icons ico-tble-act">delete_forever</i><div class="ripple-container"></div></a>',
    },
  ],
  Methodology: [],
  "Lab Results": [
    {
      id: 1,
      name: "posityive",
      active: "1",
      created_at: "2023-12-04T11:14:58.000000Z",
      updated_at: "2023-12-04T11:14:58.000000Z",
      deleted_at: null,
      created_by: "1",
      updated_by: null,
      deleted_by: null,
      action:
        '<a href="javascript:void(0)" data-toggle="tooltip"  data-id="1" class="btn btn-link btn-info btn-just-icon btn-tble-act editLabResult"><i class="fa fa-pencil-square-o ico-tble-act" aria-hidden="true"></i><div class="ripple-container"></div></a><a href="javascript:void(0)" data-toggle="tooltip"  data-id="1" class="btn btn-link btn-danger btn-just-icon btn-tble-act deleteLabResult"><i class="material-icons ico-tble-act">delete_forever</i><div class="ripple-container"></div></a>',
    },
    {
      id: 2,
      name: "waste",
      active: "1",
      created_at: "2023-12-04T11:15:03.000000Z",
      updated_at: "2023-12-04T11:15:03.000000Z",
      deleted_at: null,
      created_by: "1",
      updated_by: null,
      deleted_by: null,
      action:
        '<a href="javascript:void(0)" data-toggle="tooltip"  data-id="2" class="btn btn-link btn-info btn-just-icon btn-tble-act editLabResult"><i class="fa fa-pencil-square-o ico-tble-act" aria-hidden="true"></i><div class="ripple-container"></div></a><a href="javascript:void(0)" data-toggle="tooltip"  data-id="2" class="btn btn-link btn-danger btn-just-icon btn-tble-act deleteLabResult"><i class="material-icons ico-tble-act">delete_forever</i><div class="ripple-container"></div></a>',
    },
  ],
};

const BillingItemsMaster = () => {
  const dispatch = useDispatch();
  const { can } = PermissionUtils();
  const { billingItemsMaster } = useSelector(
    (state: RootState) => state.pagination
  );
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState<null | ItemsMasterType>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [activeSort, setActiveSort] = useState({ field: "", order: "" });
  const [data, setData] = useState<{
    result: { id: number | string; name: string; active: string }[] | [];
    total: number;
  }>({ result: [], total: 0 });
  const [masterDialogData, setMasterDialogData] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<{
    isOpen: boolean;
    selectedRow: { id: number | string; name: string; active: string } | null;
  }>({
    isOpen: false,
    selectedRow: null,
  });
  const [search, setSearch] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const itemsMasterTypes = [
    ...(can("items_group_list")
      ? [{ id: 1, name: "Items Group", label: "Items Group" }]
      : []),
    ...(can("methodology_list")
      ? [{ id: 2, name: "Methodology", label: "Methodology" }]
      : []),
    ...(can("lab_result_list")
      ? [{ id: 3, name: "Lab Results", label: "Lab Results" }]
      : []),
  ];

  const debouncedGetMasterData = useCallback(
    debounce((type: string, searchValue, sort, billingItemsMaster) => {
      const getFunc =
        type === "Items Group"
          ? getItemsGroupList
          : type === "Methodology"
          ? getMethodologyList
          : getLabResultsList;

      setIsLoading(true);
      getFunc({
        search: searchValue,
        column: sort.field,
        order: sort.order,
        page: billingItemsMaster?.page + 1,
        limit: billingItemsMaster?.pageSize,
      })
        .then((res: any) => {
          if (res.data) {
            setData(res.data);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    }, 300),
    []
  );
  const handleSelect = async (type: any) => {
    setActiveItem(type);
    dispatch(setCurrentPage({ key: "billingItemsMaster", value: 0 }));
  };

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (isFieldSort) {
      setActiveSort({ field: field, order: "ASC" });
    }
    if (!isFieldSort) {
      setActiveSort({ field: field, order: "DESC" });
    }
  };

  const deleteMasterData = (func: any) => {
    setLoading(true);
    func(isDeleteDialogOpen?.selectedRow?.id)
      .then((res: any) => {
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        setIsDeleteDialogOpen({ isOpen: false, selectedRow: null });
        if (activeItem) {
          debouncedGetMasterData(
            activeItem?.name,
            search,
            activeSort,
            billingItemsMaster
          );
        }
        setIsDeleteDialogOpen({ isOpen: false, selectedRow: null });
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("err", err);
        setLoading(false);
        setIsDeleteDialogOpen({ isOpen: false, selectedRow: null });
      });
  };

  const handleDeleteConfirm = () => {
    if (isDeleteDialogOpen?.selectedRow?.id) {
      deleteMasterData(
        activeItem?.name === "Items Group"
          ? deleteItemGroupMasterById
          : activeItem?.name === "Methodology"
          ? deleteMethodologyMasterById
          : deleteLabResultMasterById
      );
    } else {
      setIsDeleteDialogOpen({ isOpen: false, selectedRow: null });
    }
  };

  const TableActions = ({ row }: any) => {
    return (
      <Box>
        {can(
          activeItem?.name === "Items Group"
            ? "items_group_edit"
            : activeItem?.name === "Methodology"
            ? "methodology_edit"
            : activeItem?.name === "Lab Results"
            ? "lab_result_edit"
            : ""
        ) && (
          <EditIcon
            style={{
              cursor: "pointer",
              margin: "0 20px",
            }}
            onClick={() =>
              setMasterDialogData({ isOpen: true, data: row, isEdit: true })
            }
          />
        )}
        {can(
          activeItem?.name === "Items Group"
            ? "items_group_delete"
            : activeItem?.name === "Methodology"
            ? "methodology_delete"
            : activeItem?.name === "Lab Results"
            ? "lab_result_delete"
            : ""
        ) && (
          <DeleteIcon
            style={{
              cursor: "pointer",
              width: "15px",
              height: "15px",
              margin: "0 7px 0 -1px",
            }}
            onClick={() =>
              setIsDeleteDialogOpen({ isOpen: true, selectedRow: row })
            }
          />
        )}
      </Box>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "Name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "name" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "name" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "Status",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("active")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "active" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "active" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Box
          sx={{
            padding: "5px",
            background: row.active ? "#4CAF50" : "#F44336",
            display: "inline-block",
            fontSize: "11px",
            fontWeight: 500,
            textAlign: "center",
            whiteSpace: "nowrap",
            borderRadius: "5px",
            color: "#fff",
          }}
        >
          {Number(row.active) ? "Active" : "Inactive"}
        </Box>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      flex: 1,
      field: "actions",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => <TableActions row={row} />,
      sortable: false,
      minWidth: 100,
    },
  ];

  const handleSearch = (event: any) => {
    const { value } = event.target;
    console.log("handleChange", value);

    setSearch(value?.toString());
    dispatch(setCurrentPage({ key: "billingItemsMaster", value: 0 }));
  };
  useEffect(() => {
    if (activeItem) {
      debouncedGetMasterData(
        activeItem?.name,
        search,
        activeSort,
        billingItemsMaster
      );
    }
  }, [activeItem, activeSort, search, billingItemsMaster, isUpdated]);

  return (
    <Box flexDirection="column">
      <Box sx={{ display: "flex", overflowX: "scroll", width: "100vw" }}>
        {itemsMasterTypes.map((type) => (
          <Box
            sx={{
              padding: "20px",
              border: `1px solid ${
                type.id === activeItem?.id ? "#207DFF" : "#B9B9B9"
              }`,
              background: "rgba(255, 255, 255, 0.43)",
              borderRadius: "5px",
              margin: "0 30px 0 0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              minWidth: "145px",
            }}
            onClick={() => handleSelect(type)}
          >
            <Box
              sx={{
                marginBottom: "10px",
                path: {
                  fill: type.id === activeItem?.id ? "#207DFF" : "#a7a7a7",
                },
              }}
            >
              <LaboratoryIcon
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </Box>
            <Typography
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "14px",
                color: type.id === activeItem?.id ? "#207DFF" : "#232323",
              }}
            >
              {type.name}
            </Typography>
          </Box>
        ))}
      </Box>
      {activeItem && (
        <>
          <CustomFilter
            isSearchEnabled
            editButtonTitle={
              can(
                activeItem.name === "Items Group"
                  ? "items_group_add"
                  : activeItem.name === "Methodology"
                  ? "methodology_add"
                  : activeItem.name === "Lab Results"
                  ? "lab_result_add"
                  : ""
              )
                ? "Add"
                : null
            }
            searchOnChange={handleSearch}
            searchName="search"
            searchValue={search}
            onAddButtonClick={() =>
              setMasterDialogData({ isOpen: true, data: null, isEdit: false })
            }
            clearSearch={() => setSearch("")}
            editStartIcon={
              <Box
                sx={{
                  "& path": {
                    fill: "#fff",
                  },
                }}
              >
                <PlusIcon width={"12px"} height={"12px"} />
              </Box>
            }
          />
          <DataTable
            loading={isLoading}
            columns={columns}
            getRowId={(row: GridRowData) => `${String(row.id)}`}
            rows={data?.result}
            pageCount={data?.total || 0}
            currentPage={"billingItemsMaster"}
          />
        </>
      )}
      <MasterAddAndEditDialog
        isOpen={masterDialogData?.isOpen}
        type={activeItem?.name}
        onClose={() =>
          setMasterDialogData({ isOpen: false, isEdit: false, data: null })
        }
        isEdit={masterDialogData?.isEdit}
        data={masterDialogData.data}
        setIsUpdated={setIsUpdated}
      />
      <DeleteDialog
        open={isDeleteDialogOpen.isOpen}
        loading={loading}
        onClose={() =>
          setIsDeleteDialogOpen({ isOpen: false, selectedRow: null })
        }
        onConfirm={handleDeleteConfirm}
      />
    </Box>
  );
};

export default BillingItemsMaster;
