import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  Select,
  TextField,
  CheckBox,
  SaveButton,
  CancelButton,
  PageLoader,
  DatePickerWithTime,
  Button,
} from "../../../../../components/basic";
import Paper from "@mui/material/Paper";
import PaymentsFooter from "../PaymentsFooter";
import { useNavigate, useParams } from "react-router-dom";
import {
  cancelLabPurchaseBill,
  updateLabPurchaseAdvance,
  getLabPurchaseBillDetails,
  updateLabPurchasePayment,
} from "../../../../../services/laboratoryService";
import { paymentOptions } from "../../../../../constants/dropdownOptions";
import {
  CancelIcon,
  DeleteIcon,
  DeleteAlertIcon,
  RupeeIcon,
} from "../../../../../assets/icons";
import moment from "moment";
import { formula } from "../../../../../utils/CalculationUtils";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { ConfirmationDialog } from "../../../../../components/shared";
import { timeWithTwentyFourHoursConverter } from "../../../../../utils/DateTimeFormatUtils";
import { RouteUrls } from "../../../../../constants/routes";

const { calculateConvenienceFee, calculateDiscountAmount } = formula.payments;

const PaymentsEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { laboratoryUrl, labPurchasePayments } = RouteUrls;
  const [data, setData] = useState<any>({});

  const [hasFocusedChild, setHasFocusedChild] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState(paymentOptions);
  const [cardDetails, setCardDetails] = useState<any>({});
  const [excessAmount, setExcessAmount] = useState({
    excess: 0,
    advance: 0,
  });
  const [totals, setTotals] = useState({
    totalBillAmount: 0,
    totalAmountDue: 0,
    totalPaidAmount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any[]>([]);
  const [cashReceivedError, setCashReceivedError] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<{
    isOpen: boolean;
    content: string;
  }>({
    isOpen: false,
    content: "",
  });
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<{
    isOpen: boolean;
    content: string;
    id: null | number | string;
  }>({
    isOpen: false,
    content: "",
    id: null,
  });
  const [isOpenCancelModal, setIsOpenCancelModal] = useState<{
    isOpen: boolean;
    content: string;
    id: null | number | string;
  }>({
    isOpen: false,
    content: "",
    id: null,
  });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [advancePaymentOptions, setAdvancePaymentOptions] =
    useState(paymentOptions);

  useEffect(() => {
    const children = document.querySelectorAll(
      ".MuiTableCell-body .mui-focused"
    );

    setHasFocusedChild(children.length > 0);
  }, []);

  const styles = {
    selectGrid: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
    textFieldGrid: {
      "& input::placeholder": {
        fontSize: "12px",
        color: "textPrimary.main",
      },
      "& .MuiFormLabel-root": {
        color: "greyScale.main",
      },
    },
    textFieldStyle: {
      height: "44px",
      fontSize: "6px",
      "& .MuiOutlinedInput-root": {
        height: "44px",
        borderRadius: "5px",
        paddingLeft: "14.4px",
        boxShadow: "none",
        borderColor: "var(--primary-border-color)",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
    tableContainerStyle: {
      borderRadius: "5px",
      boxShadow: "none",
      overflow: "hidden",
      width: {
        xs: "400vw",
        sm: "200vw",
        md: "120vw",
        lg: "92vw",
      },
      overflowX: { xs: "scroll", lg: "hidden" },
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },

    tableStyle: {
      borderRadius: "5px",
      border: "1px solid",
      borderColor: "var(--table-border)",
      width: {
        xs: "400vw",
        sm: "200vw",
        md: "120vw",
        lg: "90vw",
      },
      "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
      "& .MuiTableRow-head": {
        backgroundColor: "var(--table-header)",
      },
      " & .MuiTableBody-root": {
        border: 0,
      },
      "& .MuiTableRow-root": {
        height: "40px !important",
        maxHeight: "40px !important",
        minHeight: "0px",
        lineHeight: "0px",
        border: 0,
      },
      "& .MuiTableCell-root": {
        minHeight: "0px",
      },

      "& .MuiTableCell-body": {
        height: "38px !important",
        maxHeight: "38px !important",
        minHeight: "0px",
        lineHeight: "0px",
        p: "0px 0px",
        fontSize: "12px",
        fontWeight: "400",
        border: 1,
        borderColor: "var(--table-border)",
        borderRadius: "5px",
      },

      "& .MuiTableCell-body:has(.Mui-focused)": {
        borderColor: "primary.main",
        borderWidth: "2px",
        p: "0px",
        height: "31px",
      },

      "& .MuiInputBase-root": {
        height: "34px",
      },
    },

    tableCellHead: {
      height: "43px",
      maxHeight: "43px",
      minHeight: "0px",
      lineHeight: "0px",
      p: "0px 10px",
      fontSize: "13px",
      fontWeight: "600",
      borderBottom: 0,
      borderRight: 1,
      borderRadius: "5px",
      borderColor: "var(--table-border)",
    },

    textFieldStyles: {
      height: "37px",
      width: "auto",
      fontSize: "6px",

      "&>*": {
        border: 0,
      },
      "& .MuiOutlinedInput-root": {
        height: "37px",
        borderRadius: "0px",
        paddingLeft: "0px",
      },
      "& .MuiOutlinedInput-root.Mui-focused": {
        borderColor: "primary.main",
      },
      "& .MuiInputBase-input": {
        padding: "0px 10px",
        fontSize: "12px",
        fontWeight: "400",
        textAlign: "right",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px",
        height: "32px",
        maxHeight: "32px",
        top: 0,
      },

      "& input::placeholder": {
        fontSize: "12px",
      },
    },
    textStyle: {
      p: "10px 10px",
      height: "38px",
      width: "100%",
      fontSize: "12px",
      fontWeight: "400",
      display: "flex",
      alignItems: "center",
    },
  };

  const handleInputsChange = (e: any, index?: number) => {
    const { name, value } = e.target;

    setData((prevState: any) => {
      const newState = { ...prevState };
      newState[name] = value;
      if (
        name === "cash_received" &&
        Number(totals.totalPaidAmount) > Number(value)
      ) {
        setCashReceivedError(true);
        const newErrors = [...errors];
        let amount = newState.cash_received;
        newState?.payments?.forEach((ele: any, i: number) => {
          if (
            ele.paid_amt &&
            !newErrors[i]?.paid_amt &&
            amount < Number(ele.paid_amt)
          ) {
            newErrors[i].paid_amt = "Required field";
          } else {
            amount -= ele.paid_amt;
          }
        });
        setErrors(newErrors);
      } else if (name === "cash_received" && newState.payment_type === "Card") {
        newState.convenience_fee = calculateConvenienceFee(
          newState,
          cardDetails?.convenience_fee_percentage
        );
        if (name === "cash_received" && cashReceivedError) {
          setCashReceivedError(false);
        }
        const newErrors = [...errors];
        let amount = newState.cash_received;
        newState?.payments?.forEach((ele: any, i: number) => {
          if (ele.paid_amt && amount < ele.paid_amt) {
            newErrors[i].paid_amt = "Required field";
          } else {
            newErrors[i].paid_amt = "";
            amount -= ele.paid_amt;
          }
        });
        setErrors(newErrors);
      } else if (name === "cash_received") {
        const newErrors = [...errors];
        let amount = newState.cash_received;
        newState?.payments?.forEach((ele: any, i: number) => {
          if (ele.paid_amt && amount < ele.paid_amt) {
            newErrors[i].paid_amt = "Required field";
          } else {
            newErrors[i].paid_amt = "";
            amount -= ele.paid_amt;
          }
        });
        setErrors(newErrors);
        if (name === "cash_received" && cashReceivedError) {
          setCashReceivedError(false);
        }
      }

      if (
        name === "payment_type" &&
        value === "Card" &&
        newState?.cash_received
      ) {
        newState.convenience_fee = calculateConvenienceFee(
          newState,
          cardDetails?.convenience_fee_percentage || 0
        );
      } else if (name === "payment_type" && value !== "Card") {
        newState.convenience_fee = 0;
      }

      if (
        name === "payment_type" &&
        (value === "Advance" || value === "Excess")
      ) {
        newState.cash_received =
          data[value === "Advance" ? "advance" : "excess"];
        const newErrors = [...errors];
        let amount = newState.cash_received;
        newState?.payments?.forEach((ele: any, i: number) => {
          if (
            ele.paid_amt &&
            !newErrors[i]?.paid_amt &&
            amount < ele.paid_amt
          ) {
            newErrors[i].paid_amt = "Required field";
          } else {
            amount -= ele.paid_amt;
          }
        });
        setErrors(newErrors);
      }

      newState.used_amount = newState?.payments?.reduce(
        (total: any, bill: any) => {
          return total + (parseFloat(bill.paid_amt) || 0);
        },
        0
      );
      newState.excess_amount =
        (newState.cash_received || 0) - newState?.used_amount;
      if (!newState.excess_amount) {
        newState.refunded_excess = 0;
      }
      const net_excess =
        (newState.cash_received || 0) -
        newState.used_amount -
        (newState.refunded_excess || 0);
      newState.net_excess = net_excess > 0 ? net_excess : 0;
      return newState;
    });
  };

  const calCulatePreviousTotalAmount = (data: any, index: number) => {
    let cashReceived = Number(data.cash_received) || 0;

    if (data?.payments?.length > 0) {
      const bills = data?.payments?.slice(0, index);

      bills?.forEach((ele: any) => {
        cashReceived -= Number(ele.paid_amt) || 0;
      });
    }

    return cashReceived;
  };

  const tableCalculation = (
    row: any,
    index: number,
    data: any,
    name: string
  ) => {
    row.discount_amount = calculateDiscountAmount(row);
    // row.balance = calculateAmountDue(row);

    // if (row.payInFull) {
    //   row.paid_amt = row.balance;
    // }

    if (row?.balance < 0) {
      setErrors((prev) => {
        const newErrors = [...prev];
        newErrors[index].paid_amt = row.paid_amt ? "required field" : "";
        newErrors[index].discount_value = row.discount_amount
          ? "required field"
          : "";
        newErrors[index].tds = row.tds ? "required field" : "";
        return newErrors;
      });
    } else {
      setErrors((prev) => {
        const newErrors = [...prev];
        newErrors[index].paid_amt =
          Number(row?.paid_amt) > Number(row?.balance) ? "required field" : "";
        newErrors[index].discount_value = "";
        newErrors[index].tds = "";

        if (name === "paid_amt") {
          const previousAmount = calCulatePreviousTotalAmount(data, index);

          if (
            row.paid_amt &&
            // !newErrors[index]?.paid_amt &&
            previousAmount < row.paid_amt
          ) {
            newErrors[index].paid_amt = "Required field";
          } else {
            newErrors[index].paid_amt = "";
          }
        }
        return newErrors;
      });
    }

    return row;
  };
  const setRowPaidAmount = (data: any, row: any, index: number) => {
    const totalCashPaidAmount = data?.payments?.reduce(
      (total: any, bill: any, i: any) => {
        if (i === index) {
          return total;
        }

        return total + (parseFloat(bill.paid_amt) || 0);
      },
      0
    );

    const balance = data.cash_received
      ? data.cash_received > totalCashPaidAmount
        ? Number(data.cash_received) - Number(row.balance) > 0
          ? row.balance
          : Number(data.cash_received) - totalCashPaidAmount
        : 0
      : row?.balance;
    return balance;
  };
  const handleRowChange = (e: any, index: number) => {
    const { name, value } = e.target;

    setData((prevState: any) => {
      const newRows = prevState?.payments?.map((row: any, i: number) => {
        if (i === index) {
          let newRow = { ...row, [name]: value };

          if (name === "paid_amt" && newRow.payInFull) {
            newRow.payInFull = false;
          }
          if (
            name === "discount_value" ||
            name === "discount_type" ||
            name === "tds" ||
            name === "paid_amt"
          ) {
            const newData = { ...prevState };
            if (newData?.payments[index]) {
              newData.payments[index][name] = value;
            }
            const newState = tableCalculation(newRow, index, newData, name);

            // if (name === "paid_amt") {
            //   const rows = [...prevState?.payments];
            //   rows[index][name] = value;

            //   const newErrors = [...errors];
            //   let amount = prevState.cash_received;
            //   rows?.forEach((ele: any, i: number) => {
            //     if (ele.paid_amt && amount < Number(ele.paid_amt)) {
            //       newErrors[i].paid_amt = "Required field";
            //       amount -= Number(ele.paid_amt);
            //     } else {
            //       amount -= Number(ele.paid_amt) || 0;
            //       newErrors[i].paid_amt = "";
            //     }
            //   });
            //   setErrors(newErrors);
            // }

            return newState;
          }

          if (name === "payInFull" && value) {
            newRow.paid_amt = setRowPaidAmount(data, newRow, index);
            if (errors[index]?.paid_amt) {
              setErrors((prev) => {
                const newErrors = [...prev];
                if (newErrors[index].paid_amt) {
                  newErrors[index].paid_amt = "";
                }
                return newErrors;
              });
            }
          }

          if (
            (name === "payInFull" || name === "paid_amt") &&
            !data.cash_received
          ) {
            setCashReceivedError(true);
          }

          setErrors((prev) => {
            const newErrors = [...prev];
            if (newErrors[index][name] && value) {
              newErrors[index][name] = "";
            }
            return newErrors;
          });
          return newRow;
        }
        return row;
      });

      if (
        name === "discount_value" ||
        name === "discount_type" ||
        name === "tds" ||
        name === "paid_amt" ||
        (name === "payInFull" && value)
      ) {
        const { totalBillAmount, totalAmountDue, totalPaidAmount } =
          newRows?.reduce(
            (totals: any, bill: any) => {
              totals.totalBillAmount += parseFloat(bill.net_total_amt) || 0;
              totals.totalAmountDue += parseFloat(bill.balance) || 0;
              totals.totalPaidAmount += parseFloat(bill.paid_amt) || 0;
              return totals;
            },
            { totalBillAmount: 0, totalAmountDue: 0, totalPaidAmount: 0 }
          );

        prevState.used_amount = totalPaidAmount;
        prevState.excess_amount =
          (prevState.cash_received || 0) - totalPaidAmount;
        if (!prevState.excess_amount) {
          prevState.refunded_excess = 0;
        }
        prevState.net_excess =
          (prevState.cash_received || 0) -
          totalPaidAmount -
          (prevState.refunded_excess || 0);

        setTotals({
          totalBillAmount,
          totalAmountDue,
          totalPaidAmount,
        });
      }
      return { ...prevState, payments: newRows };
    });
  };

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      setData((prevState: any) => ({
        ...prevState,
        name: newValue.validatedValue[0],
      }));
    }
  };

  const handleSelectChange = (e: SelectChangeEvent<string>, index: number) => {
    const { name, value } = e.target;

    setData((prevState: any) => ({
      ...prevState,
      payments: prevState.payments.map((row: any, i: number) =>
        i === index ? { ...row, [name]: value } : row
      ),
    }));
  };

  useEffect(() => {
    if (id) {
      setLoading(true);

      getLabPurchaseBillDetails(id)
        .then((res: any) => {
          if (res.data) {
            console.log("data", res.data);
            const { bill, payment } = res.data;
            const purchaseExcess = payment?.exs_bal || 0;
            const purchaseAdvance = payment?.adv_bal || 0;
            setData({
              ...payment,
              date_created: moment(payment?.created_at).format(
                "DD/MM/YYYY hh:mm:ss A"
              ),
              cash_received: payment.cash_paid,
              payment_type: payment?.type,
              excess_amount: payment?.total_excess,
              payments: bill

                // ?.filter((ele: any) => ele.is_cancel === 0)
                ?.map((ele: any) => ({
                  ...ele,
                  tds: Number(ele.payment) - Number(ele.paid_amt),
                  net_total_amt: ele.bill_amt,
                  discount_value: ele?.discount_amt || 0,
                  discount_type: ele?.discount_type || "%",
                  discount_amount: ele?.discount_amt || 0,
                  balance: (
                    Number(ele?.balance) + Number(ele?.paid_amt)
                  ).toFixed(2),
                })),
            });

            setErrors(bill?.map(() => ({})));
            if (payment?.is_advance) {
              setAdvancePaymentOptions(() => {
                const newPaymentOptions = paymentTypeOptions.filter(
                  (ele: any) => ele.id !== "Advance" && ele.id !== "Excess"
                );
                newPaymentOptions.push({ id: "Card", name: "Card" });
                return newPaymentOptions;
              });
            }
            setExcessAmount({
              advance: purchaseAdvance,
              excess: purchaseExcess,
            });
            setPaymentTypeOptions((prevState: any) => {
              const newTypeOptions = prevState.map((option: any) => {
                const newOption = { ...option } as any;

                if (option.id === "Advance" || option.id === "Excess") {
                  newOption.disabled = !Boolean(
                    option.id === "Advance" ? purchaseAdvance : purchaseExcess
                    // res?.data[option.id === "Advance" ? "advance" : "excess"]
                  );
                }
                return newOption;
              });

              return newTypeOptions;
            });
            const { totalBillAmount, totalAmountDue, totalPaidAmount } =
              res?.data?.bill?.reduce(
                (totals: any, bill: any) => {
                  totals.totalBillAmount += Number(bill.net_total_amt) || 0;
                  totals.totalAmountDue +=
                    Number(bill?.balance) + Number(bill?.paid_amt) || 0;
                  totals.totalPaidAmount += Number(bill.paid_amt) || 0;
                  return totals;
                },
                { totalBillAmount: 0, totalAmountDue: 0, totalPaidAmount: 0 }
              );

            setTotals({
              totalBillAmount,
              totalAmountDue,
              totalPaidAmount,
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [id]);

  const handleValidate = () => {
    if (cashReceivedError) {
      return false;
    }

    if (data?.refunded_excess > data?.excess_amount) {
      setIsOpenErrorModal({
        isOpen: true,
        content: "The refunded amount cannot exceed the excess amount",
      });
      return false;
    }

    let productsHasError = true;

    if (errors?.length > 0) {
      errors?.forEach((item: any) => {
        if (Object.values(item)?.filter((ele) => Boolean(ele))?.length > 0) {
          productsHasError = false;
        }
      });
    }

    return productsHasError;
  };

  const handleSave = () => {
    if (id) {
      if (handleValidate()) {
        setLoading(true);
        const formattedPayment = data?.payments?.map(
          (ele: {
            id: number;
            paid_amt: string;
            remarks: string;
            pay_id: number;
          }) => ({
            payment_id: ele.pay_id,
            bill_id: ele.id,
            paid_amount: ele.paid_amt,
            remarks: ele.remarks,
          })
        );
        const paymentData = {
          supplier_id: data.supplier_id,
          cash_paid: data.cash_received,
          created_date: data.date_created
            ? timeWithTwentyFourHoursConverter(data.date_create)
            : "",
          bill_payment_type: data.payment_type,
          urn_number: data.urn_number,
          payments: [...formattedPayment],
          used_amount: data.used_amount,
          excess_amount: data.excess_amount,
          refunded_excess: data.refunded_excess,
          net_excess: data.net_excess,
        };
        updateLabPurchasePayment(id as any, paymentData)
          .then((res: any) => {
            if (res) {
              dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            }
            setLoading(false);
            navigate(`/laboratory/lab_purchase_payments`);
          })
          .catch((err) => {
            console.log(err);
            if (err?.response?.data?.errors) {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
            }
            setLoading(false);
          });
      }
    }
  };

  const handleSaveAdvance = () => {
    if (id) {
      setLoading(true);
      updateLabPurchaseAdvance(
        id as any,
        {
          supplier_id: data?.supplier_id,
          created_date: data.date_created
            ? timeWithTwentyFourHoursConverter(data.date_created)
            : "",
          cash_paid: data?.cash_received,
          bill_payment_type: data?.payment_type,
          payee_id: data?.payee_id,
          urn_number: data?.urn_number,
        } as any
      )
        .then((res: any) => {
          if (res) {
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          }
          setLoading(false);
          navigate(`/laboratory/lab_purchase_payments`);
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data?.errors) {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.response?.data?.errors,
              })
            );
          }
          setLoading(false);
        });
    }
  };

  // const handleConfirmDelete = () => {
  //   if (isOpenDeleteModal?.id) {
  //     setIsDeleteLoading(true);
  //     deletePaymentById(id, {
  //       type: "patient_bill",
  //       sub_payment_id: isOpenDeleteModal?.id,
  //     })
  //       .then((res) => {
  //         if (res) {
  //           dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
  //         }
  //         setData((prevState: any) => {
  //           const newState = { ...prevState };

  //           newState.payments = prevState?.payments?.filter(
  //             (ele: any) => isOpenDeleteModal?.id !== ele.id
  //           );

  //           return newState;
  //         });
  //         setIsOpenDeleteModal({
  //           isOpen: false,
  //           content: "",
  //           id: null,
  //         });
  //         setIsDeleteLoading(false);
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //         setIsDeleteLoading(false);
  //         // dispatch(setSnackBarFailed({ snackBarMessage: err.message }));
  //       });
  //   }
  // };
  const handleConfirmCancel = () => {
    if (isOpenCancelModal?.id) {
      setIsCancelLoading(true);
      cancelLabPurchaseBill(id as string)
        .then((res: any) => {
          if (res) {
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          }
          setData((prevState: any) => {
            const newState = { ...prevState };

            newState.payments = prevState?.payments?.filter(
              (ele: any) => ele.id !== isOpenCancelModal?.id
            );

            return newState;
          });
          setIsOpenCancelModal({ isOpen: false, content: "", id: null });
          setIsCancelLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setIsCancelLoading(false);
          // dispatch(setSnackBarFailed({ snackBarMessage: err.message }));
        });
    }
  };
  console.log("data", data);

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          {!data?.is_advance ? (
            <Box
              sx={{
                width: "90vw",
                borderRadius: "5px",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  sx={{ mt: { xs: "25px" }, fontSize: "13px" }}
                >
                  <p style={{ color: "#999999", margin: "4px" }}>UHID</p>
                  <p
                    style={{
                      border: "1px solid var(--primary-border-color)",
                      borderRadius: "3px",
                      padding: "13px",
                      margin: "0px 75px 0px 0px",
                    }}
                  >
                    {data?.name}
                  </p>
                  <Box
                    sx={{
                      width: "275px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      mt: "6px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "11",
                        fontWeight: "500",
                        color: "primary.main",
                      }}
                    >
                      Excess: {excessAmount?.excess || 0}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "11",
                        fontWeight: "500",
                        color: "#207DFF",
                      }}
                    >
                      Advance: {excessAmount?.advance || 0}
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  sx={{ mt: { xs: "25px" } }}
                >
                  <Box sx={{ ...styles.textFieldGrid, width: "100%" }}>
                    <TextField
                      placeholder="Cash Received"
                      label={"Cash Received"}
                      name="cash_received"
                      value={data?.cash_received}
                      endPlaceholderIcon={<RupeeIcon />}
                      sx={{
                        width: {
                          xs: "275px",
                        },
                        ...styles.textFieldStyle,
                      }}
                      onChange={handleInputsChange}
                      error={cashReceivedError}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: { xs: "275px" },
                    mt: { xs: "25px" },
                  }}
                >
                  <DatePickerWithTime
                    label="Created Date"
                    style={{
                      height: "37px",
                      borderRadius: "none",
                      width: "100%",
                    }}
                    formControlStyle={{ width: "100%" }}
                    value={data?.date_created}
                    name="date_created"
                    format="DD/MM/YYYY hh:mm:ss A"
                    borderColor="var(--primary-border-color)"
                    onChange={(e: any, newValue: any) => {
                      handleDateChange(e, newValue, "date_created");
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  sx={{ ...styles.selectGrid, mt: { xs: "25px" } }}
                >
                  <Select
                    value={data?.payment_type}
                    name="payment_type"
                    placeholder="Select Payment Type"
                    options={paymentTypeOptions}
                    label={"Payment Type"}
                    width="275px"
                    sx={{
                      width: {
                        xs: "275px",
                      },
                      height: "45px",
                      boxShadow: "none",
                      borderRadius: "5px",
                      borderColor: "var(--primary-border-color)",
                      ...styles.selectGrid,
                    }}
                    disabled
                    onChange={handleInputsChange}
                  />
                </Grid>
                {data?.payment_type === "Bank" && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={3}
                    sx={{ ...styles.textFieldGrid, mt: { xs: "25px" } }}
                  >
                    <TextField
                      value={data?.urn_number}
                      name="urn_number"
                      placeholder="URN No"
                      label={"URN No"}
                      sx={{
                        width: {
                          xs: "275px",
                        },
                        ...styles.textFieldStyle,
                      }}
                      onChange={handleInputsChange}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid
                sx={{
                  m: "33px 0px 29px 0px",
                  width: {
                    xs: "92vw",
                  },
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  overflowX: { md: "scroll", lg: "hidden" },
                }}
              >
                <TableContainer
                  className="table-container"
                  component={Paper}
                  sx={{
                    ...styles.tableContainerStyle,
                  }}
                >
                  <Table
                    sx={{ ...styles.tableStyle }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{ ...styles.tableCellHead }}
                        >
                          Department
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ ...styles.tableCellHead }}
                        >
                          Invoice No
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ ...styles.tableCellHead }}
                        >
                          Bill Date
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ ...styles.tableCellHead }}
                        >
                          Bill Amount
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ ...styles.tableCellHead }}
                        >
                          Discount
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ ...styles.tableCellHead }}
                        >
                          TDS
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ ...styles.tableCellHead }}
                        >
                          Amount Due
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ ...styles.tableCellHead }}
                        >
                          Paid Amount
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ ...styles.tableCellHead }}
                        >
                          Remarks
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            ...styles.tableCellHead,
                            border: 0,
                            borderRadius: "0px 5px 0px 0px",
                          }}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.payments?.map((row: any, index: number) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "& td, & th": { border: 0 },
                          }}
                        >
                          <TableCell
                            align="left"
                            size="small"
                            sx={{
                              width: "10%",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...styles.textStyle,
                              }}
                            >
                              {row?.name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            size="small"
                            sx={{
                              width: "10%",
                            }}
                          >
                            <Typography
                              sx={{
                                ...styles.textStyle,
                              }}
                            >
                              {row?.invoice_no}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            size="small"
                            sx={{
                              width: "10%",
                            }}
                          >
                            <Typography
                              sx={{
                                ...styles.textStyle,
                              }}
                            >
                              {row?.ref_no}
                            </Typography>
                          </TableCell>

                          <TableCell
                            align="left"
                            size="small"
                            sx={{
                              width: "10%",
                            }}
                          >
                            <Typography
                              sx={{
                                ...styles.textStyle,
                              }}
                            >
                              {row?.net_total_amt}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "10%", p: "0px 0px" }}
                          >
                            <Grid
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                "&:has(.Mui-focused) .select-grid": {
                                  backgroundColor:
                                    "var(--table-cell-background)",
                                },
                              }}
                            >
                              <Grid
                                sx={{
                                  "& .MuiFormControl-root": {
                                    width: "70px",
                                  },
                                }}
                              >
                                <TextField
                                  value={row?.discount_value}
                                  name="discount_value"
                                  onChange={(e) => handleRowChange(e, index)}
                                  onFocus={() => setIsFocused(true)}
                                  sx={{
                                    ...styles.textFieldStyles,
                                    "& .MuiInputBase-input": {
                                      fontSize: "12px",
                                      fontWeight: "400",
                                      textAlign: "left",
                                      p: "0px 10px",
                                    },
                                    width: "60px",
                                  }}
                                />
                              </Grid>

                              <Grid
                                className="select-grid"
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  width: "50px",
                                  height: "38px",
                                  "& .MuiFormControl-root": { width: "100%" },

                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: 0,
                                    borderWidth: 0,
                                    height: "38px",
                                    maxHeight: "38px",
                                    p: 0,
                                  },

                                  "& .MuiInputBase-root": {
                                    padding: "0px",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  },

                                  "& .MuiOutlinedInput-input": {
                                    p: "8px 14px",
                                  },

                                  "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "primary.main",
                                      borderWidth: "1px",
                                    },

                                  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderWidth: 0,
                                    },
                                }}
                              >
                                <Select
                                  label=""
                                  options={[
                                    { id: "₹", name: "₹" },
                                    { id: "%", name: "%" },
                                  ]}
                                  value={row?.discount_type}
                                  name="discount_type"
                                  onChange={(e) => handleSelectChange(e, index)}
                                  width="50px"
                                  sx={{
                                    height: "38px",
                                    borderRadius: "0px",
                                    boxShadow: "none",
                                  }}
                                  isRemoveClearIcon
                                />
                              </Grid>
                            </Grid>
                          </TableCell>
                          {/* <TableCell
                        align="left"
                        size="small"
                        sx={{
                          width: "8%",
                        }}
                      >
                        <Typography
                          sx={{
                            ...styles.textStyle,
                          }}
                        >
                          {row?.tds}
                        </Typography>
                      </TableCell> */}
                          <TableCell
                            align="left"
                            sx={{
                              width: "10%",
                              border: !!errors[index]?.tds
                                ? "2px solid #d32f2f !important"
                                : "",
                            }}
                          >
                            <TextField
                              value={row?.tds}
                              name="tds"
                              onChange={(e) => handleRowChange(e, index)}
                              sx={{
                                ...styles.textFieldStyles,
                                "& .MuiInputBase-input": {
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  p: "0px 10px",
                                },
                                width: "inherit",
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            size="small"
                            sx={{
                              width: "10%",
                            }}
                          >
                            <Typography
                              sx={{
                                ...styles.textStyle,
                              }}
                            >
                              {row?.balance}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              width: "10%",
                              border: !!errors[index]?.paid_amt
                                ? "2px solid #d32f2f !important"
                                : "",
                            }}
                          >
                            <TextField
                              value={row?.paid_amt}
                              name="paid_amt"
                              onChange={(e) => handleRowChange(e, index)}
                              sx={{
                                ...styles.textFieldStyles,
                                "& .MuiInputBase-input": {
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  p: "0px 10px",
                                },
                                width: "inherit",
                              }}
                            />
                          </TableCell>
                          <TableCell
                            className="last-cell"
                            align="left"
                            sx={{
                              width: "10%",
                            }}
                          >
                            <TextField
                              value={row.remarks}
                              name="remarks"
                              placeholder="Type Your Remarks"
                              onChange={(e) => handleRowChange(e, index)}
                              sx={{
                                ...styles.textFieldStyles,
                                "& .MuiInputBase-input": {
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  p: "0px 10px",
                                },
                                width: "inherit",
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              width: "10%",
                            }}
                          >
                            {data?.payments?.length > 1 && (
                              <Box
                                width={"100%"}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                <CancelIcon
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    setIsOpenCancelModal({
                                      isOpen: true,
                                      // content: `<p>Do you want to cancel the bill?</p> <p>Paid amount ${row?.paid_amt} will be added to patient/payee excess amount</p>`,
                                      content: row?.paid_amt,
                                      id: row?.id,
                                    })
                                  }
                                />
                                <DeleteIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setIsOpenDeleteModal({
                                      isOpen: true,
                                      content: `Do you want to delete the bill (${row?.bill_no})?`,
                                      id: row?.id,
                                    })
                                  }
                                />
                              </Box>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell sx={{ border: "unset !important" }} />
                        <TableCell sx={{ border: "unset !important" }} />
                        <TableCell sx={{ border: "unset !important" }} />
                        <TableCell sx={{ border: "unset !important" }} />
                        <TableCell sx={{ border: "unset !important" }}>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "600",
                              color: "primary.main",
                              textAlign: "left",
                            }}
                          >
                            Total
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ border: "unset !important" }}>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "600",
                              color: "primary.main",
                              textAlign: "left",
                            }}
                          >
                            {totals?.totalBillAmount}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ border: "unset !important" }} />
                        <TableCell sx={{ border: "unset !important" }} />
                        <TableCell sx={{ border: "unset !important" }}>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "600",
                              color: "primary.main",
                              textAlign: "left",
                            }}
                          >
                            {totals?.totalAmountDue}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ border: "unset !important" }}>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "600",
                              color: "primary.main",
                              textAlign: "left",
                            }}
                          >
                            {totals?.totalPaidAmount}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ border: "unset !important" }} />
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <PaymentsFooter data={data} onChange={handleInputsChange} />
              <Grid sx={{ m: "35px 0 45px 0" }}>
                <SaveButton
                  sx={{ width: "100px", height: "40px", mr: "20px" }}
                  disabled={
                    data?.payments?.length === 0 ||
                    !Boolean(totals?.totalPaidAmount) ||
                    cashReceivedError
                  }
                  handleClick={handleSave}
                />
                <CancelButton
                  sx={{ width: "100px", height: "40px" }}
                  handleClick={() =>
                    navigate(`${laboratoryUrl}${labPurchasePayments}`)
                  }
                />
              </Grid>
              <Dialog
                open={isOpenErrorModal.isOpen}
                keepMounted
                onClose={() =>
                  setIsOpenErrorModal({ isOpen: false, content: "" })
                }
                aria-describedby="alert-dialog-slide-description"
                sx={{
                  "& .MuiPaper-root": {
                    position: "absolute",
                    top: "0px",
                    height: "145px",
                    borderRadius: "10px",
                  },
                }}
              >
                <DialogContent
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    p: "10px 20px",
                    borderRadius: "10px",
                  }}
                >
                  <DeleteAlertIcon width="19px" height="19px" />
                  <DialogTitle id="alert-dialog-title" sx={{ p: "20px 24px" }}>
                    {isOpenErrorModal.content}
                  </DialogTitle>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    buttonText="Okay"
                    sx={{
                      borderRadius: "0px",
                      width: "75px",
                      height: "35px",
                      fontWeight: 400,
                      fontSize: "13px",
                      border: 0,
                      color: "#fff",
                      "&:hover": {
                        color: "#fff",
                      },
                      "&.Mui-focusVisible": {
                        border: 1,
                        borderColor: "primary.main",
                        borderRadius: "5px",
                      },
                    }}
                    handleClick={() =>
                      setIsOpenErrorModal({ isOpen: false, content: "" })
                    }
                  />
                </DialogActions>
              </Dialog>
              {/* <ConfirmationDialog
                open={isOpenDeleteModal.isOpen}
                title={isOpenDeleteModal.content}
                handleClick={handleConfirmDelete}
                onClose={() =>
                  setIsOpenDeleteModal({
                    isOpen: false,
                    id: null,
                    content: "",
                  })
                }
                loading={isDeleteLoading}
              /> */}
              <ConfirmationDialog
                open={isOpenCancelModal.isOpen}
                title={
                  <div>
                    <p>Do you want to cancel the bill?</p>{" "}
                    <p>
                      Paid amount {isOpenCancelModal?.content} will be added to
                      patient/payee excess amount
                    </p>
                  </div>
                }
                handleClick={handleConfirmCancel}
                onClose={() =>
                  setIsOpenCancelModal({ isOpen: false, id: null, content: "" })
                }
                loading={isCancelLoading}
              />
            </Box>
          ) : (
            <Box
              sx={{
                width: "90vw",
                borderRadius: "5px",
              }}
            >
              <Typography variant="h1">Advance</Typography>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  sx={{ mt: { xs: "25px" }, fontSize: "13px" }}
                >
                  <p style={{ color: "#999999", margin: "4px" }}>UHID</p>
                  <p
                    style={{
                      border: "1px solid var(--primary-border-color)",
                      borderRadius: "3px",
                      padding: "13px",
                      margin: "0px 75px 0px 0px",
                    }}
                  >
                    {data?.name}
                  </p>
                  <Box
                    sx={{
                      width: "275px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      mt: "6px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "11",
                        fontWeight: "500",
                        color: "primary.main",
                      }}
                    >
                      Excess: {excessAmount?.excess || 0}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "11",
                        fontWeight: "500",
                        color: "#207DFF",
                      }}
                    >
                      Advance: {excessAmount?.advance || 0}
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  sx={{ mt: { xs: "25px" } }}
                >
                  <Box sx={{ ...styles.textFieldGrid, width: "100%" }}>
                    <TextField
                      placeholder="Cash Received"
                      label={"Cash Received"}
                      name="cash_received"
                      value={data?.cash_received}
                      endPlaceholderIcon={<RupeeIcon />}
                      sx={{
                        width: {
                          xs: "275px",
                        },
                        ...styles.textFieldStyle,
                      }}
                      onChange={handleInputsChange}
                      error={cashReceivedError}
                    />
                    {/* <Box
                  sx={{
                    width: "100%",
                    height: "20px",
                    mt: "6px",
                    "& .MuiFormControl-root": { height: "100%" },
                    "& .MuiBox-root": {
                      height: "100%",
                    },
                    "& .MuiFormControlLabel-root": {
                      height: "100%",
                    },
                    "& .MuiSvgIcon-root": {
                      width: "14px",
                      height: "14px",
                    },
                    "& .PrivateSwitchBase-input": {
                      height: "inherit",
                    },
                  }}
                >
                  <CheckBox
                    label={`Pay Full Amount (${data?.cash_received})`}
                    checked={data?.pay_in_full}
                    sx={{
                      fontSize: "12",
                      fontWeight: "400",
                    }}
                  />
                </Box> */}
                  </Box>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: { xs: "275px" },
                    mt: { xs: "25px" },
                  }}
                >
                  <DatePickerWithTime
                    label="Created Date"
                    style={{
                      height: "37px",
                      borderRadius: "none",
                      width: "100%",
                    }}
                    formControlStyle={{ width: "76%" }}
                    value={data?.date_created}
                    name="date_created"
                    format="DD/MM/YYYY hh:mm:ss A"
                    borderColor="var(--primary-border-color)"
                    onChange={(e: any, newValue: any) => {
                      handleDateChange(e, newValue, "date_created");
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  sx={{ ...styles.selectGrid, mt: { xs: "25px" } }}
                >
                  <Select
                    value={data?.payment_type}
                    name="payment_type"
                    placeholder="Select Payment Type"
                    options={advancePaymentOptions}
                    label={"Payment Type"}
                    width="275px"
                    sx={{
                      width: {
                        xs: "275px",
                      },
                      height: "45px",
                      boxShadow: "none",
                      borderRadius: "5px",
                      borderColor: "var(--primary-border-color)",
                      ...styles.selectGrid,
                    }}
                    onChange={handleInputsChange}
                  />
                  <Box
                    sx={{
                      width: "275px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      mt: "6px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "11",
                        fontWeight: "500",
                        color: "#207DFF",
                      }}
                    >
                      Convenience Fee : {data?.convenience_fee}
                    </Typography>
                  </Box>
                </Grid>
                {data?.payment_type === "Bank" && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={3}
                    sx={{ ...styles.textFieldGrid, mt: { xs: "25px" } }}
                  >
                    <TextField
                      value={data?.urn_number}
                      name="urn_number"
                      placeholder="URN No"
                      label={"URN No"}
                      sx={{
                        width: {
                          xs: "275px",
                        },
                        ...styles.textFieldStyle,
                      }}
                      onChange={handleInputsChange}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid sx={{ m: "35px 0 45px 0" }}>
                <SaveButton
                  sx={{ width: "100px", height: "40px", mr: "20px" }}
                  disabled={!data?.name || !Boolean(data?.cash_received)}
                  handleClick={handleSaveAdvance}
                />
                <CancelButton
                  sx={{ width: "100px", height: "40px" }}
                  handleClick={() =>
                    navigate(`${laboratoryUrl}${labPurchasePayments}`)
                  }
                />
              </Grid>
            </Box>
          )}
        </>
      )}
    </>
  );
};
export default PaymentsEdit;
