/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { Typography, Box, IconButton, debounce } from "@mui/material";
import { DataTable } from "../../../../components/shared";
import { ViewIcon, DeleteIcon, PlusIcon } from "../../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Sort, TextField } from "../../../../components/basic";
import { RouteUrls } from "../../../../constants/routes";
import { RootState } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import CustomFilter from "../../../../components/shared/customFilter";
import { setSnackBarFailed } from "../../../../redux/slices/snackbar";
import { errorMessage } from "../../../../constants/displayText";
import { getAllLabStockAdjustment } from "../../../../services/procurementService";
import { Link } from "react-router-dom";
import PermissionUtils from "../../../../utils/PermissionUtils";

type GridRowData = Record<string, unknown>;

const StockAdjustmentList = () => {
  const { labIssueStockAdjustments, laboratoryUrl, createUrl, viewUrl } =
    RouteUrls;
  const style = {
    textFieldStyle: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        height: "45px",
        borderRadius: "5px",
        paddingLeft: "8px",
        boxShadow: "none",
      },
    },
  };
  const dispatch = useDispatch();
  const { can } = PermissionUtils();

  const [stockAdjustmentList, setStockAdjustmentList] = useState<[]>([]);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);

  const [isClearFilter, setIsClearFilter] = useState(false);
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);
  const [filterData, setFilterData] = useState({
    product: "",
    chemical_reagent: "",
  });
  const [loading, setLoading] = useState(false);
  const [ascendingOrderClicked, setAscendingOrderClicked] = useState(false);
  const [descendingOrderClicked, setDescendingOrderClicked] = useState(false);
  const [searchValue, setSearchValue] = useState<any>("");

  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const navigate = useNavigate();
  const pageInfo = useSelector(
    (state: RootState) => state?.pagination?.stockAdjustment
  );
  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        {can("lab_goods_issue_stock_adj_view") && (
          <IconButton>
            <ViewIcon
              onClick={() =>
                navigate(
                  `${laboratoryUrl}${labIssueStockAdjustments}${viewUrl}/${row.dept_id}`,
                  { state: { product_id: row.product_id } }
                )
              }
              style={{ textDecoration: "none", cursor: "pointer" }}
            />
          </IconButton>
        )}
        {can("lab_goods_issue_stock_adj_delete") && (
          <IconButton aria-label="">
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "dept_name",
      flex: 0.8,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("dept_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Department
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "dept_name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "dept_name" && sortedField.order === "DESC"
                ? true
                : false
            }
            ascendingOnClick={() =>
              setAscendingOrderClicked(!descendingOrderClicked)
            }
            descendingOnClick={() =>
              setDescendingOrderClicked(!ascendingOrderClicked)
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.dept_name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "productName",
      flex: 1.2,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Product Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "pack_type",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("pack_type")}
        >
          <Typography variant="h5" fontSize={14}>
            Pack Type
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "pack_type" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "pack_type" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.pack_type}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "increase_qty",
      flex: 0.8,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("increase_qty")}
        >
          <Typography variant="h5" fontSize={14}>
            Increased Qty
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "increase_qty" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "increase_qty" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.increase_qty}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "decrease_qty",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("decrease_qty")}
        >
          <Typography variant="h5" fontSize={14}>
            Decreased Qty
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "decrease_qty" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "decrease_qty" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.decrease_qty}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    ...(can("lab_goods_issue_stock_adj_view") ||
    can("lab_goods_issue_stock_adj_delete")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const clearFilters = () => {
    if (filterData.product || filterData.chemical_reagent) {
      setFilterData({
        ...filterData,
        product: "",
        chemical_reagent: "",
      });
      setAppliedFilterCount(0);
      setIsClearFilter(true);
    }
  };

  const handleInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;

      setFilterData((prevState: any) => {
        const newFilter = { ...prevState, [name]: value };
        if (name !== "search") {
          const appliedFilterCount =
            Object.values(newFilter).filter(Boolean).length;
          setAppliedFilterCount(appliedFilterCount);
        }
        return newFilter;
      });
    }
  };

  const appointmentFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData.product}
          placeholder={"Enter Product Name"}
          label={"Product Name"}
          formControlStyle={{ width: "100%" }}
          onChange={handleInputChange}
          name={"product"}
          sx={{
            ...style.textFieldStyle,
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData.chemical_reagent}
          placeholder={"Enter Chemical Reagent"}
          label={"Chemical Reagent"}
          formControlStyle={{ width: "100%" }}
          onChange={handleInputChange}
          name={"chemical_reagent"}
          sx={{
            ...style.textFieldStyle,
          }}
        />
      ),
    },
  ];

  const debouncedGetAllAdjustmentList = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllLabStockAdjustment({
        product: data.product,
        chemical_reagent: data.chemicalReagent,
        search: data.search,
        column: data.sortBy,
        order: data.sortOrder,
        page: data.page,
        limit: data.pageSize,
      })
        .then((res: any) => {
          setStockAdjustmentList(res.data.result as []);
          setPageCount(res.data.total as any);
          setLoading(false);
        })
        .catch((err) => {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage.ERROR_FETCHING_STOCK_ADJUSTMENT}`,
            })
          );
          setLoading(false);
          console.log("error getAppointmentList", err);
        });
    }, 300),
    []
  );
  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo.pageSize,
      sortBy: sortedField.field,
      sortOrder: sortedField.order,
      product: filterData.product,
      search: searchValue,
      chemicalReagent: filterData.chemical_reagent,
    };
    debouncedGetAllAdjustmentList(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortedField.order,
    sortedField.field,
    filterData.product,
    filterData.chemical_reagent,
    searchValue,
  ]);
  return (
    <Box>
      <CustomFilter
        data={appointmentFilter}
        isFilterEnabled={true}
        isSearchEnabled={true}
        clearFilter={clearFilters}
        searchOnChange={debounce(
          (e: any) => setSearchValue(e.target.value),
          300
        )}
        searchName={"search"}
        appliedFilterCount={appliedFilterCount}
        {...(can("lab_goods_issue_stock_adj_add") && {
          editButtonTitle: "Add",
          onUpdateButtonClick: () =>
            navigate(`${laboratoryUrl}${labIssueStockAdjustments}${createUrl}`),
          editStartIcon: (
            <Box
              sx={{
                "& path": {
                  fill: "#fff",
                },
              }}
            >
              <PlusIcon width={"12px"} height={"12px"} />
            </Box>
          ),
        })}
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={stockAdjustmentList}
        pageCount={pageCount}
        currentPage={"stockAdjustment"}
      />
    </Box>
  );
};

export default StockAdjustmentList;
