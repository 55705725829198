import { Grid, InputLabel } from "@mui/material";
import { DialogWrapper } from "../../../../components/shared";
import { TextField } from "../../../../components/basic";
import { useState } from "react";
import { emailValidator } from "../../../../utils/ValidationUtils";

type AddReferralDoctorModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSave: (Value: any) => void;
};

const AddReferralDoctorModal = (props: AddReferralDoctorModalProps) => {
  const { isOpen, isLoading, onClose, onSave } = props;
  const [data, setData] = useState<any>({
    name: "",
    email: "",
    mobile: "",
    address: "",
  });
  const [error, setError] = useState<any>({
    name: "",
    email: "",
    mobile: "",
    address: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));

    setError((prevState: any) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleValidate = () => {
    const keys = ["name", "mobile"];
    const newError: { [key: string]: string } = {};
    keys.forEach((key) => {
      if (!data[key]) {
        newError[key] = `${key} is required field`;
      }
    });

    if (data.email) {
      if (emailValidator(data.email, "email")) {
        newError.email = `Please check the email format`;
      }
    }

    const hasEmpty =
      Object.values(data)?.length !== Object.values(newError)?.length;
    setError(newError);
    return hasEmpty;
  };

  const handleSave = async () => {
    if (handleValidate()) {
      onSave(data);
    }
  };
  return (
    <DialogWrapper
      open={isOpen}
      onClose={() => {
        setData({
          name: "",
          email: "",
          mobile: "",
          address: "",
        });
        onClose();
      }}
      handleClick={() => {
        handleSave();
      }}
      title="Add Referral Doctor"
      confirmText="Save"
      cancelText="Cancel"
      maxWidth="xs"
      loading={isLoading}
    >
      <Grid container rowSpacing={0.5}>
        <Grid item xl={12}>
          <TextField
            label="Name"
            value={data.name}
            fullWidth
            formControlStyle={{
              width: "100%",
            }}
            sx={{
              "& .MuiInputBase-input": {
                height: "100%",
              },
            }}
            name="name"
            onChange={handleChange}
            error={!!error.name}
            helperText={error.name}
          />
        </Grid>
        <Grid item xl={12}>
          <TextField
            label="Email"
            value={data.email}
            fullWidth
            formControlStyle={{
              width: "100%",
            }}
            sx={{
              "& .MuiInputBase-input": {
                height: "100%",
              },
            }}
            name="email"
            onChange={handleChange}
            error={!!error.email}
            helperText={error.email}
          />
        </Grid>
        <Grid item xl={12}>
          <TextField
            label="mobile"
            value={data.mobile}
            fullWidth
            formControlStyle={{
              width: "100%",
            }}
            sx={{
              "& .MuiInputBase-input": {
                height: "100%",
              },
            }}
            name="mobile"
            onChange={handleChange}
            error={!!error.mobile}
            helperText={error.mobile}
          />
        </Grid>
        <Grid item xl={12}>
          <InputLabel
            sx={{
              color: "greyScale.main",
              fontSize: "12px",
              marginBottom: "3px",
            }}
          >
            address
          </InputLabel>
          <textarea
            style={{
              width: "99%",
              height: "90px",
              borderRadius: "8px",
              resize: "none",
              outline: "none",
              border: "1px solid var(--primary-border-color)",
              fontFamily: "inter",
              fontSize: "14px",
              marginTop: "3px",
            }}
            spellCheck="false"
            value={data.address}
            name="address"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </DialogWrapper>
  );
};

export default AddReferralDoctorModal;
