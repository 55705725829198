import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider/Divider";

import { SaveButton, CancelButton } from "../../basic";
import { displayText } from "../../../constants/displayText";

type DialogWrapperProps = {
  children?: React.ReactNode;
  onClose?: () => void;
  handleClick?:any;
  open: boolean;
  title?: string;
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  confirmText?: string;
  cancelText?: string;
  loading?: boolean;
  backdropClick?: boolean;
  sx?: any;
  buttonHeight?: string | number;
  buttonWidth?: string | number;
};

function DialogWrapper({
  children,
  onClose,
  handleClick,
  open,
  fullWidth = true,
  maxWidth = "md",
  title = "",
  confirmText = displayText.SAVE,
  cancelText = displayText.CANCEL,
  loading,
  backdropClick = false,
  sx,
  buttonWidth,
  buttonHeight,
}: DialogWrapperProps) {
  const styles = {
    dialogTitle: { p: "20px 24px" },
    iconButton: { mr: "24px" },
    dialogAction: {
      display: "flex",
      justifyContent: "flex-start",
      m: "20px 24px",
      p: "0px",
    },
    defaultButton: {
      width: buttonWidth || "100px",
      height: buttonHeight || "40px",
    },
    dialogContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  return (
    <Dialog
      id="dialog-wrapper"
      onClose={backdropClick ? onClose : undefined}
      aria-labelledby={`${title}_dialog_title`}
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      sx={{
        "& .MuiPaper-root": {
          position: "static",
        },
        ...sx,
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DialogTitle sx={{ ...styles.dialogTitle }}>
          <Typography variant="h2">{title}</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ ...styles.iconButton }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <Divider light />
      <DialogContent>{children}</DialogContent>
      {handleClick ? (
        <DialogActions
          sx={{
            ...styles.dialogAction,
          }}
        >
          <SaveButton
            buttonText={confirmText}
            handleClick={handleClick}
            sx={{ ...styles.defaultButton }}
            loading={loading}
          />
          <CancelButton
            handleClick={onClose}
            buttonText={cancelText}
            sx={{ ...styles.defaultButton }}
          />
        </DialogActions>
      ) : null}
    </Dialog>
  );
}

export default DialogWrapper;
