import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ItemRow from "./ItemRow";
import {
  getBillingItemTestDepartments,
  getBillingItemLabResults,
  getBillingMethodology,
} from "../../../../services/billingService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

const styles = {
  tableContainerStyle: {
    borderRadius: "5px",
    boxShadow: "none",
    overflow: "hidden",
    width: "100%",
    overflowX: { xs: "scroll", lg: "hidden" },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tableStyle: {
    border: "1px solid",
    borderColor: "var(--table-border)",
    width: "100%",
    minWidth: "1200px",
    overflowX: "auto",
    "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
    "& .MuiTableRow-head": {
      backgroundColor: "var(--table-header)",
    },
    " & .MuiTableBody-root": {
      border: 0,
    },
    "& .MuiTableRow-root": {
      height: "44px !important",
      maxHeight: "44px !important",
      minHeight: "0px",
      lineHeight: "0px",
      border: 0,
    },

    "& .MuiTableCell-root": {
      minHeight: "0px",
    },

    "& .MuiTableCell-body": {
      height: "44px !important",
      maxHeight: "44px !important",
      minHeight: "0px",
      lineHeight: "0px",
      p: "0px 0px",
      fontSize: "12px",
      fontWeight: "400",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "var(--table-border)",
      overflow: "hidden",
    },

    "& .MuiTableCell-body:has(.Mui-focused)": {
      borderColor: "primary.main",
      borderWidth: "2px",
      p: "0px",
      height: "31px",
    },

    "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
      borderColor: "primary.main",
      borderWidth: "2px",
      p: "0px",
      height: "31px",
    },
    "& .deleteIconGrid": {
      cursor: "pointer",
      position: "absolute",
      right: {
        xs: "0px",
        sm: "0px",
        md: "0px",
        lg: "-2vw",
      },
      bottom: "5px",
      mb: "10px",
    },
  },
  header: {
    backgroundColor: "var(--table-header)",
  },
  textFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
    },
    "& .MuiInputBase-input": {
      padding: "0px 5px",
      fontSize: "12px",
      fontWeight: "400",
      textAlign: "left",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  selectFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
  },
  headerStyle: {
    color: "#000",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "15px",
    borderBottom: "1px solid var(--table-border)",
    borderRight: "1px solid var(--table-border)",
    padding: "0 12px 0 20px",
    marginLeft: "8px",
  },
};
const columns = {
  headers: [
    {
      header: "Sub Heading",
      textAlignment: "left",
      width: 10,
      name: "subHeading",
      headerStyle: styles.headerStyle,
    },
    {
      header: "Test Name& Seq",
      textAlignment: "left",
      width: 12,
      name: "testname&Seq",
      headerStyle: styles.headerStyle,
    },
    {
      header: "Department",
      textAlignment: "left",
      width: 10,
      name: "department",
      headerStyle: styles.headerStyle,
    },
    {
      header: "Lab Result",
      textAlignment: "left",
      width: 11,
      name: "labResult",
      headerStyle: styles.headerStyle,
    },
    {
      header: "Methodology",
      textAlignment: "left",
      width: 11,
      name: "methodology",
      headerStyle: styles.headerStyle,
    },
    {
      header: "Investigation",
      textAlignment: "left",
      width: 10,
      name: "investigation",
      headerStyle: styles.headerStyle,
    },
    {
      header: "Unit",
      textAlignment: "left",
      width: 8,
      name: "unit",
      headerStyle: styles.headerStyle,
    },
    {
      header: "Ref-Range",
      textAlignment: "left",
      width: 7,
      name: "refRange",
      headerStyle: styles.headerStyle,
    },
    {
      header: "Notes",
      textAlignment: "left",
      width: 7,
      name: "notes",
      headerStyle: styles.headerStyle,
    },
    {
      header: "Price",
      textAlignment: "right",
      width: 10,
      name: "price",
      headerStyle: styles.headerStyle,
    },
    {
      header: "",
      textAlignment: "left",
      width: 4,
      headerStyle: { ...styles.headerStyle, borderRight: "none" },
    },
  ],
};

type TableHeaderProps = {
  columns: any;
  labResultRef?: any;
};

type TableBodyProps = {
  rows: any;
  isSubTestAddedOrDeleted?: boolean;
  labResultRef?: any;
  onAddSubTest: (index: number) => void;
  onDelete: (rowIndex: number, index: number) => void;
  onClickRange: (rowIndex: number, index: number) => void;
  onChange: (
    event: any,
    rowIndex: number,
    subTestIndex: number,
    isSubHeading?: boolean
  ) => void;
  billingDepartmentOptions: any;
  labResultOptions: any;
  methodologyOptions: any;
  errors?: any;
};

type RowProps = {
  row: any;
  isSubTestAddedOrDeleted?: boolean;
  columns: any;
  rowIndex: number;
  labResultRef: any;
  onAddSubTest: (index: number) => void;
  onDelete: (rowIndex: number, index: number) => void;
  onClickRange: (rowIndex: number, index: number) => void;
  onChange: (
    event: any,
    rowIndex: number,
    subTestIndex: number,
    isSubHeading?: boolean
  ) => void;
  billingDepartmentOptions: any;
  labResultOptions: any;
  methodologyOptions: any;
  errors?: any;
};

type ItemsListProps = {
  isSubTestAddedOrDeleted?: boolean;
  items: any;
  handleClickRange: (rowIndex: number, index: number) => void;
  handleDeleteItem: (rowIndex: number, index: number, id?: number) => void;
  handleAddSubTest: (index: number) => void;
  handleAddItem: () => void;
  handleChangeRowData: (
    event: any,
    rowIndex: number,
    subTestIndex: number,
    isSubHeading?: boolean
  ) => void;
  errors?: any;
};

const ItemRowMemoized = React.memo(ItemRow);

const TableHeader = React.memo((props: TableHeaderProps) => {
  const { columns, labResultRef } = props;

  return (
    <TableHead sx={{ ...styles.header }}>
      <TableRow>
        {columns?.map((column: any) => (
          <TableCell
            align={column.textAlignment}
            ref={column?.header === "Lab Result" ? labResultRef : null}
            sx={{ ...column.headerStyle, width: `${column.width}%` }}
          >
            {column?.header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

const Row = React.memo((props: RowProps) => {
  const {
    row,
    isSubTestAddedOrDeleted, // Added this prop to render only when row is added or removed
    columns,
    rowIndex,
    labResultRef,
    onAddSubTest,
    onDelete,
    onClickRange,
    onChange,
    billingDepartmentOptions,
    labResultOptions,
    methodologyOptions,
    errors,
  } = props;

  return (
    <>
      {row?.subTests?.map((subTest: any, index: number) => (
        <ItemRowMemoized
          error={
            errors?.subTests?.length > index ? errors?.subTests[index] : null
          }
          index={index}
          isSubTestAddedOrDeleted={isSubTestAddedOrDeleted}
          billingDepartmentOptions={billingDepartmentOptions}
          labResultOptions={labResultOptions}
          methodologyOptions={methodologyOptions}
          rowIndex={rowIndex}
          subTest={subTest}
          row={row}
          labResultRef={labResultRef}
          columns={columns}
          onAddSubTest={onAddSubTest}
          onDelete={onDelete}
          onClickRange={onClickRange}
          onChange={onChange}
        />
      ))}
    </>
  );
});

const TableBodyComponent = React.memo((props: TableBodyProps) => {
  const {
    rows,
    isSubTestAddedOrDeleted,
    labResultRef,
    onAddSubTest,
    onDelete,
    onClickRange,
    onChange,
    billingDepartmentOptions,
    labResultOptions,
    methodologyOptions,
    errors,
  } = props;

  return (
    <TableBody
      sx={{
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderBottomColor: "#207DFF !important",
        },
      }}
    >
      {rows?.map((row: any, rowIndex: number) => (
        <Row
          errors={
            errors?.lab_tests?.length > rowIndex
              ? errors.lab_tests[rowIndex]
              : null
          }
          isSubTestAddedOrDeleted={isSubTestAddedOrDeleted}
          billingDepartmentOptions={billingDepartmentOptions}
          labResultOptions={labResultOptions}
          methodologyOptions={methodologyOptions}
          rowIndex={rowIndex}
          row={row}
          labResultRef={labResultRef}
          columns={columns}
          onAddSubTest={onAddSubTest}
          onDelete={onDelete}
          onClickRange={onClickRange}
          onChange={onChange}
        />
      ))}
    </TableBody>
  );
});

const ItemsList = (props: ItemsListProps) => {
  const {
    isSubTestAddedOrDeleted,
    items,
    handleClickRange,
    handleDeleteItem,
    handleAddSubTest,
    handleAddItem,
    handleChangeRowData,
    errors,
  } = props;
  const labResultRef: any = useRef();
  const { commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [testDepartmentOptions, setTestDepartmentOptions] = useState([]);
  const [labResultOptions, setLabResultOptions] = useState([]);
  const [methodologyOptions, setMethodologyOptions] = useState([]);

  useEffect(() => {
    if (labResultOptions?.length === 0) {
      getBillingItemLabResults({ active: 1, page: 1, limit: 100 })
        .then((res: any) => {
          if (res.data) {
            setLabResultOptions(res.data?.result);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }

    if (methodologyOptions?.length === 0) {
      getBillingMethodology({ active: 1, page: 1, limit: 100 })
        .then((res: any) => {
          if (res.data) {
            setMethodologyOptions(res.data?.result);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, []);

  useEffect(() => {
    if (commonVariables?.billig_item_test_department) {
      setTestDepartmentOptions(commonVariables?.billig_item_test_department);
    }
  }, [commonVariables]);

  return (
    <Box style={{ overflowX: "auto", minWidth: "1000px", width: "100%" }}>
      <TableContainer
        sx={{
          ...styles.tableContainerStyle,
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Table sx={{ ...styles.tableStyle }}>
          <TableHeader columns={columns.headers} labResultRef={labResultRef} />
          <TableBodyComponent
            errors={errors}
            rows={items}
            isSubTestAddedOrDeleted={isSubTestAddedOrDeleted}
            billingDepartmentOptions={testDepartmentOptions}
            labResultOptions={labResultOptions}
            methodologyOptions={methodologyOptions}
            // labResultRef={labResultRef}
            onAddSubTest={handleAddSubTest}
            onDelete={handleDeleteItem}
            onClickRange={handleClickRange}
            onChange={handleChangeRowData}
          />
        </Table>
      </TableContainer>
      <Box mt={3}>
        <Typography
          style={{
            color: "#3A8DFF",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "26px",
            cursor: "pointer",
            width: "fit-content",
          }}
          onClick={handleAddItem}
        >
          + Add items
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(ItemsList);
