import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Box,
  CircularProgress,
  debounce,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DataTable, DialogWrapper } from "../../../../components/shared";
import {
  ColorPlayIcon,
  PrintIcon,
  VideoColorFileIcon,
  VideoColorIcon,
  VideoEyeIcon,
  VideoFileIcon,
  VideoIcon,
  StartRecordIcon,
  StopRecordIcon,
  DeleteIcon,
  VideoDeleteIcon,
  RetakeIcon,
} from "../../../../assets/icons";
import { useParams, Link } from "react-router-dom";
import {
  createPatientConsent,
  getPatientConsentsById,
  getPatientMedicalRecordsById,
} from "../../../../services/patientHistoryService";
import { setPrintPdfUrl } from "../../../../redux/slices/appconfiguration";
import { useDispatch } from "react-redux";
import { RouteUrls } from "../../../../constants/routes";
import moment, { duration } from "moment";
import { printOpenWithHeader } from "../../../../utils/GeneralUtils";
import Webcam from "react-webcam";
import {
  CancelButton,
  SaveButton,
  Select,
  TextArea,
  TextField,
} from "../../../../components/basic";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";

interface MediaDeviceInfo {
  deviceId: string;
  kind: string;
  label?: string;
}

interface Device {
  id: string;
  name: string;
}

const Consents = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const BASE_URL = process.env.REACT_APP_DR_CARROT_BASE_URL;

  const initialData = {
    title: "",
    description: "",
    camera: null,
    video: null,
    type: "video",
    duration: "",
    patient_id: id,
  };

  const [consentFormData, setConsentFormData] = useState<string | Date | any>(
    initialData
  );
  console.log();
  const fieldData: any = {
    title: {
      label: "Title of Consent Form",
      name: "title",
      value: consentFormData.title,
      placeholder: "Enter Title",
    },
    description: {
      label: "Description",
      name: "description",
      value: consentFormData.description,
      placeholder: "Enter Description",
    },
    camera: {
      name: "camera",
      value: consentFormData.camera,
      placeholder: "Select Camera",
    },
  };
  const [openCaptureRecordModal, setOpenCaptureRecordModal] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isCardLoading, setIsCardLoading] = useState(false);

  const [isViewRecord, setIsViewRecord] = useState(false);
  const [videoSrc, setVideoSrc] = useState<any>(null);
  const [limit, setLimit]: any = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [initialCount, setInitialCount] = useState(null);
  const [allData, setAllData] = useState<any>([]);
  const [dataGridData, setDataGridData]: any = useState([]);
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);
  const [isDataGridExpanded, setIsDataGridExpanded] = useState(false);
  const webcamRef: any = React.useRef(null);
  const mediaRecorderRef: any = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [deviceId, setDeviceId] = React.useState<string | null>(null);
  const [devices, setDevices] = React.useState<Device[]>([]);
  const [videoDuration, setVideoDuration] = useState(0);
  const timerRef: any = useRef(null); // To store the timer reference
  const [elapsedTime, setElapsedTime] = useState(0);
  console.log("videoDevices", devices);

  const handleDevices = React.useCallback((mediaDevices: MediaDeviceInfo[]) => {
    // Transform the mediaDevices array
    const videoDevices = mediaDevices
      .filter(({ kind }) => kind === "videoinput")
      .map(({ deviceId, label }) => ({
        id: deviceId,
        name: label || `Device ${deviceId}`,
      }));

    setDevices(videoDevices);
  }, []);

  useEffect(() => {
    if (devices.length > 0) {
      setConsentFormData((prevConsentFormData: any) => ({
        ...prevConsentFormData,
        camera: devices[0].id,
      }));
    }
  }, [openCaptureRecordModal]);

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);
  // Manual duration tracking

  const handleStartCaptureClick = () => {
    const stream = webcamRef?.current?.video?.srcObject as MediaStream;

    if (!stream) {
      handleCaptureClose();
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "No webcam detected. Please connect a webcam.",
        })
      );
      return;
    }

    setCapturing(true);
    setRecordedChunks([]);
    mediaRecorderRef.current = new MediaRecorder(stream, {
      mimeType: "video/webm",
    });

    mediaRecorderRef.current.ondataavailable = (event: any) => {
      if (event?.data?.size > 0) {
        setRecordedChunks((prev) => prev.concat(event.data));
      }
    };

    mediaRecorderRef.current.start();
    setElapsedTime(0);
    // Start the manual timer to track duration
    let startTime = Date.now();
    timerRef.current = setInterval(() => {
      setElapsedTime((Date.now() - startTime) / 1000); // Update elapsed time in seconds
    }, 1000); // Update every second
  };

  const debouncedStartRecording = useCallback(
    debounce(() => {
      handleStartCaptureClick();
    }, 1000), // 10 seconds debounce delay
    []
  );

  const handleStopCaptureClick = () => {
    mediaRecorderRef.current?.stop();
    setCapturing(false);

    // Stop the manual timer
    clearInterval(timerRef.current);
    let formattedDuration: any = moment
      ?.utc(elapsedTime * 1000)
      ?.format("mm:ss");

    setConsentFormData((prevConsentFormData: any) => ({
      ...prevConsentFormData,
      duration: formattedDuration,
    }));
    stopWebcamStream();
  };

  const stopWebcamStream = () => {
    const stream = webcamRef?.current?.srcObject as MediaStream;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop()); // Stop all tracks
      webcamRef.current.srcObject = null; // Clear the webcam stream from the video element
    }
  };

  const handleCaptureClose = () => {
    mediaRecorderRef.current?.stop();
    setCapturing(false);
    setRecordedChunks([]);
    setVideoSrc(null);
    setOpenCaptureRecordModal(false);
    setIsViewRecord(false);
    setConsentFormData(initialData);
    handleStopCaptureClick(); // Ensure recording stops
    stopWebcamStream(); //
  };
  useEffect(() => {
    return () => {
      stopWebcamStream(); // Ensure the webcam is stopped when the component unmounts
    };
  }, []);
  const handleInputChange = (e: any) => {
    const { name, value } = e?.target;
    setConsentFormData((prevConsentFormData: any) => ({
      ...prevConsentFormData,
      [name]: value,
    }));
  };

  const handleConsentFormSubmit = () => {
    try {
      setIsButtonLoading(true);
      const data = new FormData();

      // Append the necessary form fields
      data.append("patient_id", consentFormData.patient_id);
      data.append("title", consentFormData.title);
      data.append("description", consentFormData.description);
      data.append("type", consentFormData.type);
      data.append("duration", consentFormData.duration);

      // Convert video blob to File and append it
      if (recordedChunks.length > 0) {
        const videoBlob = new Blob(recordedChunks, { type: "video/webm" });
        data.append("video", videoBlob, "recorded-video.mp4");
      }
      createPatientConsent(data)
        .then((result: any) => {
          setIsButtonLoading(false);
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: "Added Successfully",
            })
          );
          getPatientConsentsDetails();
          handleCaptureClose();
        })
        .catch((err) => {
          console.error("An error occurred:", err);

          const errorData = err?.response?.data?.error_data
            ? err?.response?.data?.error_data
            : null;

          // const errorMessage = errorData ? errorData.video[0] : "";
          const errorMessage = errorData
            ? Object.keys(errorData)
                .map((key) => errorData[key].join(", ")) // Join each array of error messages
                .join(" ") // Join all error messages into one string
            : "";

          dispatch(
            setSnackBarFailed({
              snackBarMessage: errorData
                ? `${errorMessage}`
                : `${err?.response?.data?.errors}`,
            })
          );
          setIsButtonLoading(false);
        });
    } catch (error: any) {
      console.error("An error occurred:", error);
      const errorData = error?.response?.data?.error_data
        ? error?.response?.data?.error_data
        : null;

      // const errorMessage = errorData ? errorData.video[0] : "";
      const errorMessage = errorData
        ? Object.keys(errorData)
            .map((key) => errorData[key].join(", ")) // Join each array of error messages
            .join(" ") // Join all error messages into one string
        : "";

      dispatch(
        setSnackBarFailed({
          snackBarMessage: errorData
            ? `${errorMessage}`
            : `${error?.response?.data?.errors}`,
        })
      );
      setIsButtonLoading(false);
    }
  };

  const getPatientConsentsDetails = async () => {
    try {
      await getPatientConsentsById(id, limit).then((result: any) => {
        const data = result?.data?.result;
        if (data) {
          setTotalCount(result?.data?.count);
          if (!limit) {
            setInitialCount(data?.length);
          }
          const modifiedData = data?.map((item: any, index: number) => ({
            ...item,
            sNo: index + 1,
          }));

          setAllData(modifiedData);
          setDataGridData(modifiedData);
          setIsCardLoading(false);
        }
      });
    } catch (error: any) {
      console.error("An error occurred:", error);
      const errorMessage = error?.response?.data?.errors
        ? error?.response?.data?.errors
        : "Unknown error occurred";
      dispatch(
        setSnackBarFailed({
          snackBarMessage: `${errorMessage}`,
        })
      );
      setIsCardLoading(false);
    }
  };
  useEffect(() => {
    if (recordedChunks.length && !capturing) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      setVideoSrc(url);

      // const videoElement = document.createElement("video");
      // videoElement.src = url;

      // videoElement.onloadedmetadata = () => {
      //   let duration = videoElement?.duration; // duration in seconds

      //   duration = Math.round(duration);
      //   let formattedDuration = moment?.utc(duration * 1000)?.format("mm:ss");

      // };
      setConsentFormData((prevConsentFormData: any) => ({
        ...prevConsentFormData,
        // duration: formattedDuration,
        video: url,
      }));
    }
  }, [capturing, recordedChunks]);
  // const videoEl: any = useRef(null);
  // // console.log("videoEl.current;", videoEl.current.duration);
  // // const handleLoadedMetadata = () => {
  // //   const video = videoEl.current;
  // //   if (!video) return;

  // //   setTimeout(() => {
  // //     console.log("video", video);
  // //     console.log(`The video is ${video.duration} seconds long.`);
  // //   }, 100); // Small delay to ensure metadata is loaded
  // // };

  // const handleLoadedMetadata = () => {
  //   const video = videoEl.current;
  //   if (!video) return;

  //   console.log("Video readyState:", video.readyState);
  //   console.log("Video networkState:", video.networkState);

  //   if (video.readyState >= 1) {
  //     // If metadata is available
  //     console.log(`The video is ${video.duration} seconds long.`);
  //   } else {
  //     console.log("Video metadata not fully loaded.");
  //   }
  // };
  // useEffect(() => {
  //   handleLoadedMetadata();
  // }, []);
  const openVideoDialog: any = () => {
    setOpenCaptureRecordModal(true);
    setConsentFormData(initialData);
    setRecordedChunks([]);
  };

  const openViewDialog: any = (data: any) => {
    setIsViewRecord(true);
    setOpenCaptureRecordModal(true);

    setConsentFormData((prevConsentFormData: any) => ({
      ...prevConsentFormData,
      title: data.title,
      description: data.description,
      video: `${BASE_URL}${data.path}`,
    }));
  };
  const toggleDataGrid = () => {
    setIsDataGridExpanded((prev) => !prev);
    if (totalCount && initialCount && totalCount > initialCount) {
      setLimit("all");
    }
  };

  useEffect(() => {
    if (isDataGridExpanded) {
      setDataGridData(allData);
    } else {
      const slicedData = allData.slice(0, initialCount);
      setDataGridData(slicedData);
    }
  }, [isDataGridExpanded]);

  useEffect(() => {
    if (id) {
      setIsCardLoading(true);
      if (limit) {
        getPatientConsentsDetails();
      } else {
        setTimeout(getPatientConsentsDetails, 4000);
      }
    }
  }, [limit]);

  return (
    <>
      <Grid
        sx={{
          width: { xs: "90%", md: "49%" },
          minWidth: { xs: "90%", md: "49%" },
          boxShadow: "3px 5px 15px 0px #23232314",
          borderRadius: "5px",
          height: "auto",
          maxHeight: isDataGridExpanded
            ? `${200 + allData?.length * 110}px`
            : `${200 + dataGridData?.length * 100}px`,
          m: "20px 0px",
          border: 1,
          borderColor: "greyScale.lighter",
        }}
      >
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: "12px 16px 0px",
          }}
        >
          <Typography variant="h2" sx={{ color: "primary.main" }}>
            Patient Consents
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <IconButton
              sx={{ width: "40px", height: "40px" }}
              onClick={openVideoDialog}
            >
              <VideoIcon />
            </IconButton>
            <IconButton>
              <VideoFileIcon />
            </IconButton>
            <Button
              sx={{
                color: "textPrimary.main",
                backgroundColor: "#F4F4F4",
                p: "10px",
                fontWeight: "500",
                fontSize: "12px",
                textTransform: "capitalize",
              }}
              onClick={toggleDataGrid}
            >
              View All
            </Button>
          </Box>
        </Grid>

        <Grid
          sx={{
            p: "12px 16px 18px",
            width: "100%",
          }}
        >
          {" "}
          {isCardLoading ? (
            <div
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                height: "100px",
                background: "transparent",
              }}
            >
              <CircularProgress />
            </div>
          ) : dataGridData?.length > 0 ? (
            <>
              {dataGridData.map((data: any, index: number) => (
                <Box
                  key={index} // Important to add a unique key for each item
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "10px",
                    border: "1px solid #F2F2F2",
                    borderRadius: "5px",
                    padding: "20px",
                    paddingBottom: "12px",
                    marginBottom: "12px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        mr: 2,
                      }}
                    >
                      <VideoColorIcon />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          lineHeight: "20px",
                        }}
                      >
                        {data?.title || "Consent for patient"}
                      </Box>
                      <Box
                        sx={{
                          color: "#8A8A8A",
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "20px",
                        }}
                      >
                        {data?.description}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "100px",
                      gap: 3,
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        cursor: "pointer",
                      }}
                    >
                      <IconButton onClick={() => openViewDialog(data)}>
                        <ColorPlayIcon />
                      </IconButton>
                      <IconButton>
                        <VideoDeleteIcon />
                      </IconButton>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        cursor: "pointer",
                        fontSize: "10px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        color: "#A7A7A7",
                      }}
                    >
                      Duration: {data.duration} mins
                    </Box>
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100px",
              }}
            >
              {" "}
              <Typography variant="h4" color="initial">
                No Data
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <DialogWrapper
        open={openCaptureRecordModal}
        title={"Patient Consent Form"}
        onClose={handleCaptureClose}
        handleClick={
          recordedChunks.length > 0 || isViewRecord
            ? handleConsentFormSubmit
            : undefined
        }
        confirmText="Save"
        cancelText="Cancel"
        buttonWidth="100px"
        buttonHeight="36px"
        maxWidth="md"
        backdropClick={true}
        loading={isButtonLoading}
      >
        <Box mb={2} width={"100%"}>
          {(recordedChunks?.length > 0 || isViewRecord) && (
            <>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="h3"
                  color="initial"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "primary.main",
                    marginBottom: "10px",
                    // ml: 1,
                  }}
                >
                  Video Details
                </Typography>
                <Box sx={{ display: "flex", cursor: "pointer" }}>
                  <RetakeIcon />
                  <Typography
                    variant="h3"
                    color="initial"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#FF9500",
                      // marginBottom: "10px",
                      ml: 1,
                    }}
                  >
                    Retake
                  </Typography>{" "}
                </Box>
              </Box>
              <Box mb={3} width={"100%"}>
                <video
                  src={consentFormData.video}
                  controls
                  preload="metadata"
                  width="100%"
                  height="400"
                  poster="https://image.architonic.com/pro1-3/20182636/le-1231-z-pro-sq-arcit18.jpg"
                />
              </Box>
            </>
          )}
          {!capturing && (
            <>
              <Box width={"100%"} mb={2}>
                <TextField
                  label={fieldData.title.label}
                  placeholder={fieldData.title.placeholder}
                  name={fieldData.title.name}
                  value={fieldData.title.value}
                  onChange={handleInputChange}
                />
              </Box>
              <Box width={"100%"}>
                <TextArea
                  label={fieldData.description.label}
                  placeholder={fieldData.description.placeholder}
                  name={fieldData.description.name}
                  value={fieldData.description.value}
                  onChange={handleInputChange}
                  sx={{ width: "97%" }}
                />
              </Box>
              {recordedChunks.length === 0 && !isViewRecord && (
                <Grid container rowSpacing={1} sx={{ marginTop: "15px" }}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sm={6}
                    xl={6}
                    lg={6}
                    display={"flex"}
                  >
                    <IconButton sx={{ marginRight: "16px" }}>
                      <VideoColorIcon />
                    </IconButton>
                    <Select
                      options={devices}
                      width={"300px"}
                      label={fieldData.camera.label}
                      placeholder={fieldData.camera.placeholder}
                      name={fieldData.camera.name}
                      value={fieldData.camera.value}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sm={6}
                    xl={6}
                    lg={6}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <SaveButton
                      startIcon={<StartRecordIcon />}
                      buttonText="Start Recording"
                      handleClick={debouncedStartRecording}
                      sx={{
                        width: "154px",
                        height: "42px",
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
          <Box
            display={capturing ? "flex" : "none"}
            alignItems={"center"}
            width={"100%"}
            justifyContent={"center"}
            position={"relative"}
          >
            <Webcam
              width={640}
              height={480}
              audio={true}
              ref={webcamRef}
              videoConstraints={{ deviceId: fieldData.camera.value }}
            />
           
          </Box>
          {capturing && (
            <Box
              marginTop={"12px"}
              alignItems={"center"}
              width={"100%"}
              justifyContent={"center"}
              display={"flex"}
            >
              <SaveButton
                startIcon={<StopRecordIcon />}
                buttonText="Stop Recording"
                handleClick={handleStopCaptureClick}
                sx={{
                  width: "154px",
                  height: "42px",
                  background: "#EB5757",
                  "&:hover": {
                    backgroundColor: "#EB5757",
                  },
                }}
              />
            </Box>
          )}
        </Box>
      </DialogWrapper>
    </>
  );
};

export default Consents;
