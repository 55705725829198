import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, debounce, CircularProgress } from "@mui/material";
import {
  Select,
  TextField,
  SaveButton,
  DateCalender,
  PageLoader,
  InfiniteScroller,
  AutoComplete,
} from "../../../components/basic";
import {
  DoctorIcon,
  ConsultationIcon,
  PrintIcon,
  EditIcon,
  AddIcon,
  SettingsIcon,
} from "../../../assets/icons";
import "react-multi-date-picker/styles/layouts/prime.css";
import { AppointmentPic } from "../../../assets/images";
import { AddPatientDialog, SequenceWrapper } from "../../../components/shared";
import BookingTokens from "./BookingTokens";
import BookingSlots from "./BookingSlots";
import { appointmentEndPoints } from "../../../constants/apiEndPoints";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { appointmentDetailsConst } from "../../../constants/displayText";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../utils/ValidationUtils";
import { AppDispatch, RootState } from "../../../redux/store";
import { RouteUrls } from "../../../constants/routes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setIsLoading } from "../../../redux/slices/loader";
import {
  AppointmentStatusDetails,
  appointmentInfo,
  consultationDetails,
  createAppointmentDetails,
  doctorDetails,
  doctorsAvailableDaysDetails,
  getAllPatientsList,
  getDepartmentSequence,
  rescheduleAppointmentDetails,
  reviewAppointmentDetails,
  slotsDetails,
  tokenDetails,
  updatedSequence,
  getPatientsLimitInfo,
} from "../../../services/appointmentService";
import { commonDateFormatter } from "../../../utils/DateTimeFormatUtils";
import moment from "moment";
import PermissionUtils from "../../../utils/PermissionUtils";

const BookingForm = () => {
  const styles = {
    boxStyle: {
      width: { xs: "30px", sm: "30px" },
      height: { xs: "30px", sm: "30px" },
      borderRadius: " 5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#ECF4FF ",
      cursor: "pointer",
    },
    widthStyle: {
      xs: "330px",
      sm: "427px",
    },
    textFieldStyle: {
      width: {
        xs: "330px",
        sm: "425px",
      },
      "& .MuiOutlinedInput-root": {
        height: "45px",
        borderRadius: "8px",
        paddingLeft: "8px",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      },
    },
    selectStyle: {
      width: {
        xs: "330px",
        sm: "425px",
      },
      height: "45px",
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      borderRadius: "8px",
    },
    autocompleteStyle: {
      width: {
        xs: "330px",
        sm: "425px",
      },
      height: "45px",
      "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
        padding: "5px 0px",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        borderRadius: "8px",
      },
    },
  };

  const { can } = PermissionUtils();

  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: any) => state.loader.isLoading);
  const navigate = useNavigate();
  const isTokenEnable = useSelector(
    (state: any) => state.appConfiguration.isTokenEnable
  );

  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );

  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);
  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });
  const [isManualAdded, setIsManualAdded] = useState(false);

  const searchPatientsPagination = useRef({ search: "", page: 1 });
  const initialBookingError = {
    doctor_id: "",
    purpose_id: "",
    patient_id: "",
    appoint_date: "",
    slot_label: "",
    slot_id: "",
  };
  const [sequenceId, setSequenceId] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const patientsPagination: any = useRef(1);
  const [doctorsName, setDoctorsName] = useState<any>();
  const [consultationsName, setConsultationsName] = useState<any>([]);
  const [patientsList, setPatientsList] = useState<any>([]);
  const [slotTimes, setSlotTimes] = useState<any>([]);
  const [sequenceDialogBox, setSequenceDialogBox] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [checkStatus, setCheckStatus] = useState<boolean>(false);

  const [availableDays, setAvailableDays] = useState<any>([]);
  const [tokenList, setTokenList] = useState<any>([]);
  const [isPatientSelected, setIsPatientSelected] = useState<boolean | any>(
    false
  );
  const [tokenLoading, setTokenLoading] = useState<boolean>(false);
  const [dateSelected, setDateSelected] = useState<any>(false);
  const [autoCompleteClear, setAutoCompleteClear] = useState<any>(false);
  const [disableSelect, setDisableSelect] = useState<any>(false);
  const [getSlotsFetched, setGetSlotsFetched] = useState<boolean>(false);
  const { appointmentId } = useParams();
  const [processChart, setProcessChart] = useState<boolean>(true);
  const [autoGeneratedSeqNo, setAutoGeneratedSeqNo] = useState("");
  const { reviewAppointmentId } = useParams();
  const [seqIsOnce, setSeqIsOnce] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });
  const [isSlotsLoading, setIsSlotsLoading] = useState<boolean>(false);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const [bookingError, setBookingError] = useState<any>(initialBookingError);

  const initialData = {
    doctor_id: "",
    doctor_id_input: "",
    purpose_id: "",
    patient_id: "",
    patient_name: "",
    appoint_date: new Date().toISOString().split("T")[0],
    slot_label: "",
    slot_id: "",
    status: 1,
    type: 1,
    invoice_no: "",
    review_appointment: null,
  };

  const [bookingFormData, setBookingFormData] = useState<any>(initialData);
  const [customSequence, setCustomSequence] = useState(true);
  // Example: Retrieving a specific query parameter
  const date: any = queryParams.get("date");
  const [defaultOptions, setDefaultOptions] = useState<any>([]);
  const [canCreatePatients, setCanCreatePatients] = useState<any>(true);
  const [bookingMaxDate, setBookingMaxDate] = useState<any>(null);

  // generate array with dates function

  function generateDateArray(days: any) {
    // Create an empty array to store the dates
    const dateArray = [];

    // Get today's date
    const today = new Date();

    for (let i = 0; i < days; i++) {
      // Create a new date object for each day
      const newDate = new Date(today);
      newDate.setDate(today.getDate() + i);

      // Format the date as YYYY-MM-DD
      const formattedDate = newDate.toISOString().split("T")[0];

      // Add the formatted date to the array
      dateArray.push(formattedDate);
    }

    return dateArray;
  }

  const getAllDoctorsName = async () => {
    try {
      await doctorDetails().then((result: any) => {
        let data = result?.data;
        const doctorsList = data?.map((uniqueData: any) => {
          return { id: uniqueData?.id, name: uniqueData?.name };
        });
        setDoctorsName(doctorsList);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAllConsultationsName = async () => {
    try {
      await consultationDetails().then((result: any) => {
        let data = result?.data;

        const consultationsList = data?.map((uniqueData: any) => {
          return { id: uniqueData?.id, name: uniqueData?.name };
        });
        setConsultationsName(consultationsList);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getPatientsLimit = async () => {
    try {
      await getPatientsLimitInfo().then((result: any) => {
        let data = result?.data;

        setCanCreatePatients(data?.access);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };

  const debouncedGetAllPatientsList = debounce(
    async (currentValue, prevOptions, callback) => {
      try {
        return await getAllPatientsList({
          search: currentValue,
          page:
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page
              : 1,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;
          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList?.filter(
            (option: any) =>
              !prevOptions?.some(
                (prevOption: any) => prevOption?.value === option?.value
              )
          );
          setPatientsList(uniqueOptions);
          searchPatientsPagination.current.page =
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page + 1
              : 1;
          searchPatientsPagination.current.search = currentValue;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
      } catch (error) {
        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
      }
    },
    300
  );

  const getAllPatientsData = async (currentValue?: any, prevOptions?: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllPatientsList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page =
          patientsPagination.current === Math.ceil(prevOptions.length / 10)
            ? patientsPagination.current
            : Math.ceil(prevOptions.length / 10) + 1;
        return await getAllPatientsList({
          search: currentValue,
          page: page,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions?.some(
                (prevOption: any) => prevOption?.value === option?.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setPatientsList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
            const currentValue = patientsPagination?.current;
            patientsPagination.current = currentValue + 1;
          }
          const currentValue = patientsPagination?.current;
          patientsPagination.current = currentValue + 1;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const getAppointmentsDetails = async () => {
    try {
      if (appointmentId !== undefined) {
        dispatch(setIsLoading(true));
        await appointmentInfo(appointmentId).then((result: any) => {
          let data = result?.data?.appointment;
          const AppointmentsDetails = {
            appoint_id: data?.id,
            doctor_id: data?.doctor_id,
            doctor_id_input: data?.doctor_name,
            purpose_id: data?.appoint_for,
            appoint_date: data?.appoint_date,
            patient_id: data?.patient_id,
            patient_name: data?.patient_name,
            user_id: data?.user_id,
            status: data?.status,
            type: 1,
            mobile: data?.patient_mobile,
            slot_label: "",
            slot_id: "",
            invoice_no: data?.op_no,
          };
          setBookingFormData(AppointmentsDetails);
          setIsPatientSelected(true);
          dispatch(setIsLoading(false));
        });
      } else {
        setBookingFormData(initialData);
        setSlotTimes({});

        const dates = generateDateArray(appConfiguration?.booking_max_date);
        if (dates?.length > 0) {
          setAvailableDays(dates);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleInvoiceNoChange = () => {
    if (autoGeneratedSeqNo !== bookingFormData.invoice_no) {
      setSeqIsOnce((prevState) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: bookingFormData?.invoice_no,
      }));
      setSequenceDialogBox(true);
      setIsManual((prevState) => ({
        ...prevState,
        is_manual: false,
        is_auto_generate: false,
        is_once: true,
      }));
    }
  };

  const getAppointmentsReviewDetails = async () => {
    try {
      if (reviewAppointmentId !== undefined) {
        dispatch(setIsLoading(true));
        await appointmentInfo(reviewAppointmentId).then((result: any) => {
          let data = result?.data?.appointment;
          if (data?.review_appointment === null) {
            setBookingFormData((prevBookingData: any) => ({
              ...prevBookingData,
              appoint_id: data?.id,
              user_id: data?.user_id,
              doctor_id: data?.doctor_id,
              doctor_id_input: data?.doctor_name,
              purpose_id: data?.appoint_for,
              appoint_date: date ? date : data?.appoint_date,
              status: 1,
              patient_id: data?.patient_id,
              patient_name: data?.patient_name,
              invoice_no: data?.op_no,
              review_appointment: data?.review_appointment,
            }));
          } else {
            setBookingFormData((prevBookingData: any) => ({
              ...prevBookingData,
              appoint_id: data?.id,
              user_id: data?.user_id,
              doctor_id: data?.doctor_id,
              doctor_id_input: data?.doctor_name,
              purpose_id: data?.appoint_for,
              appoint_date: date ? date : data?.appoint_date,
              status: 3,
              patient_id: data?.patient_id,
              patient_name: data?.patient_name,
              invoice_no: data?.op_no,
              review_appointment: data?.review_appointment,
            }));
          }
          setDisableSelect(true);
          dispatch(setIsLoading(false));
        });
      } else {
        setBookingFormData(initialData);
        setSlotTimes({});
        const dates = generateDateArray(appConfiguration?.booking_max_date);
        if (dates?.length > 0) {
          setAvailableDays(dates);
        }
        setDisableSelect(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    getAllDoctorsName();
    getAllConsultationsName();
    getPatientsLimit();
    if (!reviewAppointmentId && !appointmentId) {
      handleSequence();
    }
  }, []);

  useEffect(() => {
    getAppointmentsDetails();
  }, [appointmentId]);

  useEffect(() => {
    getAppointmentsReviewDetails();
  }, [reviewAppointmentId]);

  useEffect(() => {
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }

    const dates = generateDateArray(appConfiguration?.booking_max_date);
    if (dates?.length > 0) {
      setAvailableDays(dates);
    }
  }, [appConfiguration]);

  const fieldData: any = {
    doctor_id: {
      label: "Doctor Name",
      name: "doctor_id",
      value: bookingFormData.doctor_id,
      inputValue: bookingFormData.doctor_id_input,
      inputValueName: "doctor_id_input",
      isError: bookingError.doctor_id === "" ? false : true,
      helperText: bookingError.doctor_id,
    },
    purpose_id: {
      label: "Consultation For",
      name: "purpose_id",
      value: bookingFormData.purpose_id,
      isError: bookingError.purpose_id === "" ? false : true,
      helperText: bookingError.purpose_id,
    },
    patient_id: {
      label: "Patient Name",
      name: "patient_id",
      value: bookingFormData.patient_id,
      isError: bookingError.patient_id === "" ? false : true,
      helperText: bookingError.patient_id,
    },
    sequenceNo: {
      label: "OP Sequence No",
      name: "invoice_no",
      value: bookingFormData.invoice_no,
    },
    appoint_date: {
      name: "appoint_date",
      value: bookingFormData.appoint_date,
      isError: bookingError.appoint_date === "" ? false : true,
      helperText: bookingError.appoint_date,
    },
    slot_label: {
      label: "Slot Time",
      name: "slot_label",
      value: bookingFormData.slot_label,
      isError: bookingError.slot_label === "" ? false : true,
      helperText: bookingError.slot_label,
    },
  };

  const [bookingFieldData, setBookingFieldData] = useState(fieldData);

  // Handle Change events

  const handleValidation = useCallback(
    (e: any) => {
      const { name, value, label } = e.target;

      switch (name) {
        case "doctor_id":
        case "purpose_id":
        case "patient_id":
        case "slot_label": {
          if (requiredValidator(value, label)) {
            updateFormDataWithHelperText(
              name,
              requiredValidator(value, label),
              setBookingError
            );
          } else {
            updateFormDataWithHelperText(name, "", setBookingError);
          }
          break;
        }
        default:
          break;
      }
    },
    [bookingError]
  );

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateBookingFieldData = () => {
    setBookingFieldData((prevBookingFieldData: any) => {
      return Object.keys(prevBookingFieldData).map((field: any) => {
        return {
          ...field,
          helperText: bookingError[field.name],
          value: bookingFormData[field.name],
          isError: bookingError[field.name] === "" ? false : true,
        };
      });
    });
  };

  useEffect(() => {
    updateBookingFieldData();
  }, [bookingError, bookingFormData]);

  const handleSequence = () => {
    getDepartmentSequence("Appointment")
      .then((res: any) => {
        if (res?.data?.length > 0) {
          setBookingFormData((prevState: any) => ({
            ...prevState,
            invoice_no: res?.data[0]?.sequence_no,
          }));
          setAutoGeneratedSeqNo(res?.data[0]?.sequence_no);
          setSequenceId(res?.data[0]?.sequence?.id);
          const newSequenceData =
            res?.data[0]?.sequence?.sequence_preferences?.map(
              (ele: {
                id: number;
                type: string;
                value: string;
                separator: string;
              }) => ({
                id: ele?.id,
                type: ele?.type,
                value: ele?.value,
                separator: ele?.separator,
              })
            );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual: res?.data[0]?.sequence?.is_manual === 1 ? true : false,
            is_auto_generate:
              res?.data[0]?.sequence?.is_manual !== 1 ? true : false,
          }));
          setSeqIsOnce((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(res.data[0]?.sequence?.is_manual === 1));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleInputChange = useCallback((e: any) => {
    const { name, value } = e.target;

    setBookingFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: value,
    }));

    handleValidation(e);
    if (name === "doctor_id") {
      setDateSelected(false);
    }

    if (name === "patient_id" && value) {
      setIsPatientSelected(true);
    }
  }, []);

  const handleAutoCompleteChange = (e: any, newValue: any, name: string) => {
    if (newValue === null) {
      return false;
    }

    setBookingFormData((prev: any) => ({
      ...prev,
      [name]: newValue.id,
    }));

    handleValidation({
      target: {
        name: name,
        value: newValue,
      },
    });
    if (name === "doctor_id") {
      setDateSelected(false);
    }
  };

  const handleAutoCompleteInputChange = (
    e: any,
    newInputValue: any,
    name: string
  ) => {
    if (newInputValue === "" && (reviewAppointmentId || appointmentId)) {
      return false;
    }

    setBookingFormData((prev: any) => ({
      ...prev,
      [name]: newInputValue,
    }));
  };

  const handleDateChange = useCallback((newValue: any) => {
    setBookingFormData((prevBookingData: any) => ({
      ...prevBookingData,
      appoint_date: newValue?.toString(),
    }));
    setDateSelected(true);
  }, []);

  const handleSlotTimeChange = useCallback(
    (id: number, slot_name: string) => {
      setBookingFormData((prev: any) => ({
        ...prev,
        slot_label: slot_name === prev?.slot_label ? "" : slot_name,
        slot_id: id === prev?.slot_id ? "" : id,
      }));

      handleValidation({
        target: { name: "slot_label", value: slot_name, label: "Slot Time" },
      });
    },
    [handleValidation]
  );

  const { doctor_id, appoint_date, purpose_id, patient_id, slot_label } =
    bookingFormData;

  const createAppointment = useCallback(async () => {
    if (
      !Boolean(doctor_id) ||
      !Boolean(patient_id) ||
      !Boolean(appoint_date) ||
      (isTokenEnable ? false : !Boolean(slot_label))
    ) {
      validateForm();
    } else {
      try {
        setLoader(true);
        const bookingData: any = await createAppointmentDetails({
          ...bookingFormData,
          appoint_date: moment(bookingFormData?.appoint_date, [
            "DD/MM/YYYY hh:mm:ss",
            "YYYY-MM-DD HH:mm:ss",
            "YYYY-MM-DD",
            "DD/MM/YYYY",
          ]).format("YYYY-MM-DD"),
          op_no: bookingFormData?.invoice_no,
          is_modified:
            bookingFormData?.invoice_no === autoGeneratedSeqNo ? 0 : 1,
        });
        setBookingFormData((prevBookingData: any) => ({
          ...prevBookingData,
          patient_id: "",
        }));
        setAutoCompleteClear(!autoCompleteClear);
        setTokenList("");
        getSlots();
        if (appConfiguration?.bill_appointment_enable) {
          window.open(`/billing/create`, "_blank");
        }
        if (!appConfiguration.bill_appointment_enable) {
          window.open(
            `/appointments/details/${bookingData?.data?.id}`,
            "_blank"
          );
        }
        dispatch(
          setSnackBarSuccess({
            snackBarMessage:
              appointmentDetailsConst?.APPOINTMENT_CONFIRMED as string,
          })
        );

        setLoader(false);
        setBookingFormData((prevState: any) => ({
          ...initialData,
          patient_id: null,
          appoint_date: null,
        }));
      } catch (error: any) {
        setLoader(false);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error.errors as string,
          })
        );
        // setBookingFormData((prevState: any) => ({ ...initialData }));
        console.error("An error occurred:", error);
      }
    }
  }, [doctor_id, patient_id, appoint_date, slot_label, bookingFormData]);

  const rescheduleAppointment = useCallback(async () => {
    if (
      !Boolean(patient_id) ||
      (isTokenEnable ? false : !Boolean(slot_label))
    ) {
      validateForm();
    } else {
      try {
        setLoader(true);
        await rescheduleAppointmentDetails(bookingFormData);
        setSlotTimes({});
        setTokenList("");
        dispatch(
          setSnackBarSuccess({
            snackBarMessage:
              appointmentDetailsConst.APPOINTMENT_APPOINTMENT as string,
          })
        );
        setBookingFormData([initialData]);
        navigate(
          `${RouteUrls.appointmentUrl}${RouteUrls.appointmentsDetails}/${appointmentId}`
        );

        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.error("An error occurred:", error);
      }
    }
  }, [bookingFormData]);

  const reviewAppointment = useCallback(async () => {
    if (
      !Boolean(doctor_id) ||
      !Boolean(patient_id) ||
      !Boolean(appoint_date) ||
      (isTokenEnable ? false : !Boolean(slot_label))
    ) {
      validateForm();
    } else {
      try {
        setLoader(true);
        await reviewAppointmentDetails({
          ...bookingFormData,
        });
        setSlotTimes({});
        setTokenList("");
        dispatch(
          setSnackBarSuccess({
            snackBarMessage:
              appointmentDetailsConst?.REVIEW_APPOINTMENT_CONFIRMED as string,
          })
        );
        setBookingFormData([initialData]);
        navigate(
          `${RouteUrls?.appointmentUrl}${RouteUrls?.appointmentsDetails}/${reviewAppointmentId}`
        );

        setLoader(false);
      } catch (error) {
        setLoader(false);
        console?.error("An error occurred:", error);
      }
    }
  }, [bookingFormData]);

  const getDoctorsAvailableDays = async () => {
    if (doctor_id === "") {
      return false;
    } else {
      try {
        setIsSlotsLoading(true);

        await doctorsAvailableDaysDetails(doctor_id).then((result: any) => {
          if (result?.data?.available_dates?.length > 0) {
            setAvailableDays(result?.data?.available_dates);
          } else {
            const dates = generateDateArray(appConfiguration?.booking_max_date);
            if (dates?.length > 0) {
              setAvailableDays(dates);
            }
          }

          const setDate =
            result?.data?.available_dates?.length > 0
              ? result?.data?.available_dates[0]
              : new Date();

          if (reviewAppointmentId === undefined) {
            if (!dateSelected) {
              setBookingFormData((prevBookingData: any) => ({
                ...prevBookingData,
                appoint_date: setDate,
              }));
            }
          } else {
            if (reviewAppointmentId !== undefined && date === null) {
              setBookingFormData((prevBookingData: any) => ({
                ...prevBookingData,
                appoint_date: setDate,
              }));
            }
            if (reviewAppointmentId !== undefined && date !== null) {
              setBookingFormData((prevBookingData: any) => ({
                ...prevBookingData,
                appoint_date: commonDateFormatter(date, "YYYY-MM-DD"),
              }));
            }
          }

          // developer comment
          // if (
          //   bookingFormData.purpose_id === "" ||
          //   bookingFormData.patient_id === ""
          // ) {
          setIsSlotsLoading(false);
          // }
        });
      } catch (error) {
        setIsSlotsLoading(false);
        console.error("An error occurred:", error);
      }
    }
  };

  // Slot setup

  const getSlots = async () => {
    if (
      doctor_id === "" ||
      purpose_id === "" ||
      appoint_date === null
      // patient_id === ""
    ) {
      return false;
    }
    setProcessChart(false);
    setCheckStatus(false);
    setGetSlotsFetched(false);
    setIsSlotsLoading(true);

    try {
      const slotsPostData = {
        doctor_id: bookingFormData?.doctor_id,
        appoint_date: moment(bookingFormData?.appoint_date, [
          "DD/MM/YYYY hh:mm:ss",
          "YYYY-MM-DD HH:mm:ss",
          "YYYY-MM-DD",
          "DD/MM/YYYY",
        ]).format("YYYY-MM-DD"),
        appoint_for: purpose_id,
      };
      await slotsDetails(slotsPostData).then((response: any) => {
        setSlotTimes(response?.data);
        setGetSlotsFetched(true);
        // if (bookingFormData.patient_id === "") {
        setIsSlotsLoading(false);
        // }
      });
    } catch (error) {
      console.error("An error occurred:", error);
      setGetSlotsFetched(false);
      setIsSlotsLoading(false);
    }
  };

  const checkAppointmentStatus = async () => {
    if (!Boolean(doctor_id) || !Boolean(patient_id) || !Boolean(appoint_date)) {
      return false;
    } else {
      if (getSlotsFetched) {
        try {
          setIsSlotsLoading(true);
          setCheckStatus(false);

          const endpoint = `${
            appointmentEndPoints?.checkAppointmentStatus
          }?patient_id=${patient_id}&doctor_id=${doctor_id}&appoint_date=${moment(
            bookingFormData?.appoint_date,
            [
              "DD/MM/YYYY hh:mm:ss",
              "YYYY-MM-DD HH:mm:ss",
              "YYYY-MM-DD",
              "DD/MM/YYYY",
            ]
          ).format("YYYY-MM-DD")}`;

          // }
          if (true) {
            let appoint_date = moment(bookingFormData?.appoint_date, [
              "DD/MM/YYYY hh:mm:ss",
              "YYYY-MM-DD HH:mm:ss",
              "YYYY-MM-DD",
              "DD/MM/YYYY",
            ]).format("YYYY-MM-DD");

            await AppointmentStatusDetails(
              doctor_id,
              patient_id,
              appoint_date
            ).then(() => {
              setIsSlotsLoading(false);
            });
          }
        } catch (error: any) {
          if (error?.response?.status === 400) {
            setIsSlotsLoading(false);
            setCheckStatus(true);
          }
        }
      }
    }
  };

  const getTokens = async () => {
    if (!isTokenEnable || doctor_id === "" || appoint_date === null) {
      return false;
    } else {
      try {
        setTokenLoading(true);

        let appoint_date = moment(bookingFormData?.appoint_date, [
          "DD/MM/YYYY hh:mm:ss",
          "YYYY-MM-DD HH:mm:ss",
          "YYYY-MM-DD",
          "DD/MM/YYYY",
        ]).format("YYYY-MM-DD");

        tokenDetails(doctor_id, appoint_date).then((response: any) => {
          setTokenLoading(false);

          setTokenList(response?.data?.Dailytokens);
          setProcessChart(false);
        });

        // if (bookingFormData.patient_id === "") {
        setIsSlotsLoading(false);
        // }
      } catch (error) {
        console.error("An error occurred:", error);
        setTokenLoading(false);
      }
    }
  };

  useEffect(() => {
    if (doctor_id !== undefined) {
      getDoctorsAvailableDays();
    }
  }, [doctor_id]);

  useEffect(() => {
    if (doctor_id !== undefined && !isTokenEnable) {
      getSlots();
    }
  }, [appoint_date, purpose_id]);

  useEffect(() => {
    if (appoint_date) {
      checkAppointmentStatus();
    }
  }, [appoint_date, patient_id, purpose_id]);

  useEffect(() => {
    if (isTokenEnable) {
      getTokens();
    }
  }, [appoint_date, isTokenEnable, appConfiguration]);

  function onClose() {
    setOpen(false);
  }

  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value === "Select" ? "" : value,
      };
      return newState;
    });
  };

  function onCloseSequenceDialogBox() {
    setSequenceDialogBox(false);
  }

  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      const data = {
        seq_preference: [...sequenceData],
        is_manual: isManual.is_manual ? 1 : 0,
      };
      await updatedSequence(Number(sequenceId), data)
        .then((res) => {
          if (res) {
            handleSequence();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(setSnackBarFailed({ snackBarMessage: err.message }));
        });
    }
  };

  useEffect(() => {
    if (bookingFormData.patient_id && bookingFormData.patient_name) {
      setPatientsList([
        {
          value: bookingFormData.patient_id,
          label: bookingFormData.patient_name,
        },
      ]);
      setDefaultOptions([
        {
          value: bookingFormData.patient_id,
          label: bookingFormData.patient_name,
        },
      ]);
    }
  }, [bookingFormData.patient_name]);

  return isLoading || !appConfiguration ? (
    <PageLoader />
  ) : (
    <Grid container spacing={2} className="booking-form" sx={{ mb: "50px" }}>
      <SequenceWrapper
        open={sequenceDialogBox}
        title="Appointments"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
      <AddPatientDialog
        open={open}
        onClose={onClose}
        patientId={fieldData.patient_id.value}
      />

      <Grid
        sx={{
          width: "92vw",
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          flexWrap: "wrap",
          justifyContent: "space-between",
          mt: "20px",
        }}
      >
        <Grid
          item
          sx={{
            width: { xs: "100%", lg: "40%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            pl: "20px",
          }}
        >
          <AutoComplete
            value={fieldData.doctor_id.value}
            inputValue={fieldData.doctor_id.inputValue}
            placeholder="Select Doctor"
            placeholderIcon={<DoctorIcon />}
            onChange={(e: any, newValue: any) =>
              handleAutoCompleteChange(e, newValue, fieldData.doctor_id.name)
            }
            onInputChange={(e: any, newValue: any) =>
              handleAutoCompleteInputChange(
                e,
                newValue,
                fieldData.doctor_id.inputValueName
              )
            }
            options={doctorsName}
            disabled={disableSelect}
            name={fieldData.doctor_id.name}
            label={fieldData.doctor_id.label}
            error={fieldData.doctor_id.isError}
            helperText={fieldData.doctor_id.helperText}
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.doctor_id }))
            }
            sx={{
              ...styles.autocompleteStyle,
            }}
          />
          <Select
            value={fieldData.purpose_id.value}
            placeholder="Select Consultation"
            placeholderIcon={<ConsultationIcon />}
            onChange={handleInputChange}
            options={consultationsName}
            name={fieldData.purpose_id.name}
            label={fieldData.purpose_id.label}
            error={fieldData.purpose_id.isError}
            helperText={fieldData.purpose_id.helperText}
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.purpose_id }))
            }
            sx={{
              ...styles.selectStyle,
            }}
          />
          <Grid
            sx={{
              width: { xs: "400px", sm: "425px" },
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "flex-start", sm: "center" },
              mb: { xs: "10px", sm: "0px" },
            }}
          >
            <InfiniteScroller
              loadOptions={getAllPatientsData}
              options={patientsList}
              name={"patient_id"}
              value={fieldData.patient_id.value}
              defaultOptions={defaultOptions}
              handleOnChange={handleInputChange}
              isDisabled={appointmentId || reviewAppointmentId ? true : false}
              label={fieldData.patient_id.label}
              error={fieldData.patient_id.isError}
              helperText={fieldData.patient_id.helperText}
              width={"330px"}
              // sx={{
              //   backgroundColor: "red",
              //   pointerEvents: appointmentId ? "none" : "",
              //   opacity: appointmentId ? 1 : 1,
              // }}
            />
            <Grid
              sx={{
                width: { xs: "95px", sm: "95px" },
                display: "flex",
                justifyContent: "space-between",
                ml: { xs: "5px", sm: "20px" },
              }}
            >
              <Grid
                sx={{
                  ...styles.boxStyle,
                }}
              >
                <PrintIcon />
              </Grid>

              <Grid
                sx={{
                  ...styles.boxStyle,
                  overflow: "hidden",
                }}
              >
                {isPatientSelected
                  ? can("update_patient") && (
                      <EditIcon
                        onClick={() => setOpen((prev: boolean) => !prev)}
                      />
                    )
                  : can("add_patient") && (
                      <AddIcon
                        onClick={
                          canCreatePatients
                            ? () => setOpen((prev: boolean) => !prev)
                            : () =>
                                dispatch(
                                  setSnackBarFailed({
                                    snackBarMessage: "Patients limit exceed",
                                  })
                                )
                        }
                      />
                    )}
              </Grid>
            </Grid>
          </Grid>
          {customSequence ? (
            <TextField
              value={fieldData.sequenceNo.value}
              onChange={handleInputChange}
              placeholder="sequence no"
              label={fieldData.sequenceNo.label}
              name={fieldData.sequenceNo.name}
              disabled={appointmentId || reviewAppointmentId ? true : false}
              endPlaceholderIcon={
                <SettingsIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSequenceDialogBox(true);
                  }}
                />
              }
              onBlur={handleInvoiceNoChange}
              sx={{
                ...styles.textFieldStyle,
                mb: "25px",
              }}
            />
          ) : null}
          <Grid
            sx={{
              width: {
                xs: "330px",
                sm: "425px",
                md: "425px",
              },
            }}
          >
            <DateCalender
              value={fieldData.appoint_date.value}
              name={fieldData.appoint_date.name}
              enabledDates={availableDays}
              onChange={handleDateChange}
              isMinDate={appConfiguration?.appointment_previous_date}
              minDate={
                appConfiguration?.appointment_previous_date ? "" : new Date()
              }
            />
          </Grid>
        </Grid>
        <Grid
          sx={{
            width: { xs: "100%", lg: "60%" },
            display: "flex",
            flexDirection: "column",
            pl: "20px",
          }}
        >
          <Grid sx={{ display: { xs: "none", lg: "block" } }}>
            <Grid
              sx={{
                width: { xs: "100%", md: "100%", xl: "100%" },
                height: { xs: "205px", md: "205px", xl: "205px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: `url(${AppointmentPic})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "5px",
              }}
            ></Grid>
          </Grid>
          {isLoading || !appConfiguration ? (
            <Grid
              sx={{
                width: "100%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                height: "40vh",
                background: "transparent",
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {isTokenEnable ? (
                <BookingTokens
                  tokenList={tokenList}
                  checkStatus={checkStatus}
                  loading={tokenLoading}
                />
              ) : (
                <BookingSlots
                  selectedBox={fieldData.slot_label.value}
                  name={fieldData.slot_label.name}
                  onChange={handleSlotTimeChange}
                  slotTimes={slotTimes}
                  error={fieldData.slot_label.isError}
                  helperText={fieldData.slot_label.helperText}
                  onBlur={handleValidation}
                  checkStatus={checkStatus}
                  loading={isSlotsLoading}
                  processChart={processChart}
                />
              )}
              {checkStatus || processChart ? (
                <></>
              ) : (
                <SaveButton
                  buttonText={
                    appointmentId === undefined
                      ? "Book Appointment"
                      : "Reschedule Appointment"
                  }
                  handleClick={
                    appointmentId !== undefined
                      ? rescheduleAppointment
                      : reviewAppointmentId !== undefined
                      ? reviewAppointment
                      : createAppointment
                  }
                  loading={loader}
                  sx={{
                    width: {
                      sm: "200px",
                      md: "300px",
                      lg: "427px",
                    },
                  }}
                  buttonSize="lg"
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BookingForm;
