import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  DataTable,
  DialogWrapper,
  ConfirmationDialog,
} from "../../../../../components/shared";
import { DeleteIcon, EditIcon, PrintIcon } from "../../../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { AutoComplete, TextAreaEditor } from "../../../../../components/basic";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../../utils/ValidationUtils";
import {
  getAdmissionIpDischargeSummaryById,
  deleteAdmissionIpDischargeSummaryById,
  createAdmissionIpDischargeSummaryById,
  updateAdmissionIpDischargeSummaryById,
  editAdmissionIpDischargeSummaryById,
  getAllPrintNotesIpTypes,
} from "../../../../../services/admissionService";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../../../redux/slices/snackbar";
import { ipAdmissionConst } from "../../../../../constants/displayText";
import { setPrintPdfUrl } from "../../../../../redux/slices/appconfiguration";
import { RouteUrls } from "../../../../../constants/routes";
import { Link } from "react-router-dom";
import PermissionUtils from "../../../../../utils/PermissionUtils";
import { printOpenWithHeader } from "../../../../../utils/GeneralUtils";
import { useDispatch, useSelector } from "react-redux";
import { setDischargeSummaryList } from "../../../../../redux/slices/inpatients";
import { RootState } from "../../../../../redux/store";

type DischargeSummaryProps = {
  ipAdmissionDetails?: any;
};

const Discharge = ({ ipAdmissionDetails }: DischargeSummaryProps) => {
  const { ADDED_SUCCESSFULLY, UPDATED_SUCCESSFULLY, DELETED_SUCCESSFULLY } =
    ipAdmissionConst;

  const { printUrl } = RouteUrls;
  const { can } = PermissionUtils();

  const dispatch = useDispatch();

  const { dischargeSummaryList } = useSelector(
    (state: RootState) => state.inPatients
  );

  // common variables
  const [admissionId, setAdmissionId] = useState<any>(ipAdmissionDetails?.id);
  const [idToBeUpdated, setIdToBeUpdated] = useState<number | null>(null);
  const [idToBeDeleted, setIdToBeDeleted] = useState<any>(null);

  // dialog and loader variables

  const [isDialogWrapperButtonLoading, setIsDialogWrapperButtonLoading] =
    useState(false);
  const [isDialogWrapperOpen, setIsDialogWrapperOpen] =
    useState<boolean>(false);
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [
    isConfirmationDialogButtonLoading,
    setIsConfirmationDialogButtonLoading,
  ] = useState(false);
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  // field variables

  const [dischargeSummaryData, setDischargeSummaryData] = useState<any>([]);
  const [typeOptions, setTypeOptions] = useState<any>([]);

  const initialFormError = {
    type: "",
    content: "",
  };

  const [formError, setFormError] = useState<any>(initialFormError);

  const initialFormData = {
    type: null,
    typeInput: "",
    content: "",
  };

  const [formData, setFormData] = useState<any>(initialFormData);

  const fieldData: any = {
    type: {
      label: "Type",
      name: "type",
      placeholder: "Select Type",
      inputValue: formData?.typeInput,
      value: formData.type,
      isError: formError.type === "" ? false : true,
      helperText: formError.type,
    },
    content: {
      label: "Content",
      name: "content",
      value: formData.content,
      isError: formError.content === "" ? false : true,
      helperText: formError.content,
    },
  };

  const [formFieldData, setFormFieldData] = useState(fieldData);

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;
    switch (name) {
      case "type": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateFormFieldData = () => {
    setFormFieldData((prev: any) => {
      return Object.keys(prev).map((field: any) => {
        return {
          ...field,
          value: formData[field.name],
          helperText: formError[field.name],
          isError: formError[field.name] === "" ? false : true,
        };
      });
    });
  };

  useEffect(() => {
    updateFormFieldData();
  }, [formError, formData]);

  const dialogClose = async () => {
    setIsDialogWrapperButtonLoading(false);
    setIsDialogWrapperOpen(false);
    setIsDialogLoading(false);
    setIdToBeUpdated(null);
    setFormError(initialFormError);
    setIdToBeDeleted(null);
    setIsConfirmationDialogOpen(false);
    setIsDialogLoading(false);
    setFormData(initialFormData);
  };

  // data table and actions

  const TableActions = ({ row }: any) => {
    return (
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        {can("ip_discharge_edit") ? (
          <IconButton
            onClick={() => {
              editAdmissionIpDischargeSummary(row?.id);
            }}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <></>
        )}
        {can("ip_discharge_delete") ? (
          <IconButton
            onClick={() => {
              setIsConfirmationDialogOpen(true);
              setIdToBeDeleted(row?.id);
            }}
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <></>
        )}
        <Box
          style={{
            textDecoration: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            const url = printUrl;
            const queryParams = new URLSearchParams({
              url: row?.pdf_url,
            }).toString();

            window.open(`${url}?${queryParams}`, "_blank");
          }}
        >
          <PrintIcon />
        </Box>
      </Grid>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "sNo",
      flex: 0.5,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            S.No
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.sNo}
        </Typography>
      ),
      minWidth: 50,
      sortable: false,
    },
    {
      field: "summaryType",
      flex: 2,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Summary Type
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.type}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "48px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  // field onchange functions

  const handleAutoCompleteChange = (e: any, newValue: any, name: string) => {
    const content = newValue.content;

    if (newValue === null) {
      return false;
    }

    setFormData((prev: any) => ({
      ...prev,
      [name]: newValue,
      content: content,
    }));

    handleValidation(e);
  };

  const handleAutoCompleteInputChange = (
    e: any,
    newInputValue: any,
    name: string
  ) => {
    if (name === "typeInput" && newInputValue === "") {
      return false;
    }

    setFormData((prev: any) => ({
      ...prev,
      [name]: newInputValue,
    }));
  };

  const handleTextAreaChange = (content: any) => {
    setFormData((prev: any) => ({
      ...prev,
      content: content,
    }));
  };

  // api call functions to get select options

  const getPrintNotesIpTypes = async () => {
    try {
      await getAllPrintNotesIpTypes().then((result: any) => {
        let data = result?.data?.result;

        setTypeOptions(data);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // crud operation api calls functions

  const getAdmissionIpDischargeSummary = async () => {
    if (!admissionId) {
      return false;
    }
    try {
      setIsPageLoading(true);
      setIsDataTableLoading(true);
      await getAdmissionIpDischargeSummaryById(admissionId).then(
        (result: any) => {
          let data = result?.data;

          if (data) {
            const modifiedData = data?.map((item: any, index: number) => ({
              ...item,
              sNo: index + 1,
            }));

            dispatch(setDischargeSummaryList(modifiedData));
          }
          setIsPageLoading(false);
          setIsDataTableLoading(false);
        }
      );
    } catch (error) {
      setIsPageLoading(false);
      setIsDataTableLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const createAndUpdateAdmissionIpDischargeSummary = async () => {
    if (formData.type === null) {
      validateForm();
    } else {
      try {
        const data = {
          ip_admission_id: admissionId,
          type: !idToBeUpdated ? formData?.type?.type : formData?.type,
          content: formData?.content,
        };

        setIsDialogWrapperButtonLoading(true);

        if (!idToBeUpdated) {
          await createAdmissionIpDischargeSummaryById(data).then((res: any) => {
            dialogClose();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: ADDED_SUCCESSFULLY,
              })
            );
            setFormData({
              type: null,
              typeInput: "",
              content: "",
            });
            getAdmissionIpDischargeSummary();
          });
        } else {
          if (!idToBeUpdated) {
            return false;
          }
          await updateAdmissionIpDischargeSummaryById(idToBeUpdated, data).then(
            (res: any) => {
              dialogClose();
              dispatch(
                setSnackBarSuccess({
                  snackBarMessage: UPDATED_SUCCESSFULLY,
                })
              );
              setFormData({
                type: null,
                typeInput: "",
                content: "",
              });
              getAdmissionIpDischargeSummary();
            }
          );
        }
      } catch (error: any) {
        dialogClose();
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
        console.error("An error occurred:", error);
      }
    }
  };

  const editAdmissionIpDischargeSummary = async (id: number) => {
    try {
      if (id === null) {
        return false;
      }
      setIsDialogWrapperOpen(true);
      setIsDialogLoading(true);
      await editAdmissionIpDischargeSummaryById(id).then((result: any) => {
        const data = result?.data;
        setFormData({
          type: data?.type,
          typeInput: data?.type,
          content: data?.content,
        });
        setIdToBeUpdated(id);
        setIsDialogLoading(false);
      });
    } catch (error: any) {
      setIsDialogWrapperOpen(false);
      setIsDialogLoading(false);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.message,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const handleDelete = async (id: any) => {
    try {
      setIsDataTableLoading(true);
      setIsConfirmationDialogButtonLoading(true);
      await deleteAdmissionIpDischargeSummaryById(id).then((result: any) => {
        setIsDataTableLoading(false);

        getAdmissionIpDischargeSummary();

        dispatch(
          setSnackBarSuccess({
            snackBarMessage: DELETED_SUCCESSFULLY,
          })
        );

        setIsConfirmationDialogOpen(false);
        setIsConfirmationDialogButtonLoading(false);
        setIdToBeDeleted(null);
      });
    } catch (error) {
      setIsConfirmationDialogOpen(false);
      setIsConfirmationDialogButtonLoading(false);
      setIdToBeDeleted(null);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "something went wrong",
        })
      );
      console.error("An error occurred:", error);
    }
  };

  // dialog functions

  const onConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (idToBeDeleted === null) {
      return false;
    } else {
      handleDelete(idToBeDeleted);
    }
  };

  useEffect(() => {
    if (isDialogWrapperOpen && typeOptions?.length < 1) {
      getPrintNotesIpTypes();
    }
  }, [isDialogWrapperOpen]);

  useEffect(() => {
    if (!dischargeSummaryList) {
      getAdmissionIpDischargeSummary();
    }
  }, [admissionId]);

  return isPageLoading ? (
    <Grid
      sx={{
        width: "95vw",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        height: "40vh",
        background: "transparent",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid className="discharge-grid">
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you surely want to delete?"
        handleClick={handleConfirmDelete}
        onClose={onConfirmationDialogClose}
        loading={isConfirmationDialogButtonLoading}
      />
      {can("ip_discharge_add") && (
        <>
          <DialogWrapper
            onClose={dialogClose}
            open={isDialogWrapperOpen}
            handleClick={createAndUpdateAdmissionIpDischargeSummary}
            title={idToBeUpdated === null ? "Add" : "Edit"}
            maxWidth="lg"
            loading={isDialogWrapperButtonLoading}
          >
            {isDialogLoading ? (
              <Grid
                sx={{
                  width: "100%",
                  margin: "auto",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                  height: "50vh",
                  background: "transparent",
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid item xl={11.5} xs={12} sx={{ gap: 5 }}>
                <AutoComplete
                  label={fieldData.type.label}
                  value={fieldData.type.value}
                  inputValue={fieldData.type.inputValue}
                  placeholder={fieldData.type.placeholder}
                  name={fieldData.type.name}
                  disabled={idToBeUpdated ? true : false}
                  onChange={(e: any, newValue: any) =>
                    handleAutoCompleteChange(e, newValue, fieldData.type.name)
                  }
                  onInputChange={(e: any, newValue: any) =>
                    handleAutoCompleteInputChange(e, newValue, "typeInput")
                  }
                  options={typeOptions}
                  optionName="type"
                  error={fieldData.type.isError}
                  helperText={fieldData.type.helperText}
                  width="300px"
                  sx={{
                    width: {
                      xs: "300px",
                    },
                    height: "45px",
                    boxSizing: "border-box",
                    margin: 0,
                    "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                      padding: "5px 0px",
                      boxShadow: "none",
                      borderRadius: "5px",
                    },
                  }}
                />
                {fieldData && (
                  <TextAreaEditor
                    label={fieldData.content.label}
                    placeholder={""}
                    name={fieldData.content.name}
                    value={fieldData.content.value}
                    onChange={(content: any) => handleTextAreaChange(content)}
                  />
                )}
              </Grid>
            )}
          </DialogWrapper>
          <Grid
            item
            xl={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h2" sx={{ m: "20px 0px" }}>
              Add Discharge Summaries
            </Typography>
            <Button
              variant="text"
              sx={{
                width: "88px",
                height: "38px",
                textTransform: "none",
              }}
              onClick={() => {
                setIsDialogWrapperOpen(true);
              }}
            >
              <Typography variant="h4">+Add</Typography>
            </Button>
          </Grid>
        </>
      )}
      <Grid item xl={12} sx={{ mb: "50px" }} className="data-table-grid">
        <DataTable
          loading={isDataTableLoading}
          columns={columns}
          rows={dischargeSummaryList || []}
          tableOnly={true}
          customizedTable={true}
        />
      </Grid>
    </Grid>
  );
};

export default Discharge;
