import React, { useCallback, useEffect, useRef, useState } from "react";
import { Grid, Typography, Box, IconButton, debounce } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DataTable, ConfirmationDialog } from "../../../../components/shared";
import { InfiniteScroller, Sort, Tooltip } from "../../../../components/basic";
import {
  DeleteIcon,
  EditIcon,
  ViewIcon,
  PaymentRupeeIcon,
  PlusIcon,
} from "../../../../assets/icons";
import { Select, TextField, DatePicker } from "../../../../components/basic";
import { RouteUrls } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import CustomFilter from "../../../../components/shared/customFilter";
import {
  deletePurchaseBillById,
  getAllPurchaseBill,
} from "../../../../services/procurementService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { errorMessage } from "../../../../constants/displayText";
import { procurementBillListOptions } from "../../../../constants/dropdownOptions";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import {
  commonDateFormatter,
  formattedDateWithSlash,
} from "../../../../utils/DateTimeFormatUtils";
import { setCurrentPage } from "../../../../redux/slices/pagination";
import { handleFilterCounts } from "../../../../utils/GeneralUtils";
import { getAllSuppliers } from "../../../../services/gynecologyService";
import { Can } from "../../../../utils/PermissionUtils";
import {
  setSortField,
  setHandleClearSearch,
  setFilterDataValues,
} from "../../../../redux/slices/pagination";

type GridRowData = Record<string, unknown>;

const style = {
  selectStyle: {
    height: "45px",
    boxShadow: "none",
    borderRadius: "5px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primary-border-color)",
    },
    "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primary-border-color)",
    },
  },
  DatePickerStyle: {
    "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container >div >div .rmdp-input":
      {
        boxSizing: "border-box",
      },
  },
  textFieldStyle: {
    "& .MuiOutlinedInput-root": {
      height: "45px",
      borderRadius: "5px",
      paddingLeft: "8px",
      boxShadow: "none",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primary-border-color)",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primary-border-color)",
    },
  },
};
const PurchaseBill = () => {
  const {
    procurementUrl,
    purchaseBillUrl,
    createUrl,
    editUrl,
    viewUrl,
    purchaseReturnUrl,
    purchasePaymentListUrl,
  } = RouteUrls;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );

  const [suppliersList, setSuppliersList] = useState<any>([]);

  const [purchaseBillList, setPurchaseBillList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState({
    isOpen: false,
    id: null,
  });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState({
    isOpen: false,
    errors: [],
  });

  const { pageInfo, filterData, count, sortField } = useSelector(
    (state: RootState) => ({
      pageInfo: state?.pagination?.procurementPurchaseBill,
      filterData: state?.pagination?.procurementPurchaseBill?.filterData,
      count: state?.pagination?.procurementPurchaseBill?.count,
      sortField: state?.pagination?.procurementPurchaseBill?.sortedField,
    })
  );
  const [billListOptions, setBillListOptions] = useState(
    procurementBillListOptions
  );

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortField?.field === field) {
      dispatch(
        setSortField({
          key: "procurementPurchaseBill",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
    if (sortField?.field !== field) {
      dispatch(
        setSortField({
          key: "procurementPurchaseBill",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
  };
  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {!row.is_cancel && !row.status && Can("purchasebill_edit") && (
          <IconButton
            aria-label=""
            onClick={() => {
              if (row.is_return) {
                navigate(`${procurementUrl}${purchaseReturnUrl}/${row.id}`);
              } else {
                navigate(
                  `${procurementUrl}${purchaseBillUrl}${editUrl}/${row.id}${
                    row?.bill_type === "approval_list"
                      ? "?isApprovalList=true"
                      : ""
                  }`
                );
              }
            }}
          >
            <EditIcon />
          </IconButton>
        )}
        {Can("purchasebill_view") && (
          <IconButton
            aria-label=""
            onClick={() => {
              if (row.is_return) {
                navigate(
                  `${procurementUrl}${purchaseReturnUrl}${viewUrl}/${row.id}`
                );
              } else {
                navigate(
                  `${procurementUrl}${purchaseBillUrl}${viewUrl}/${row.id}${
                    row?.bill_type === "approval_list"
                      ? "?isApprovalList=true"
                      : ""
                  }`
                );
              }
            }}
          >
            <ViewIcon />
          </IconButton>
        )}
        {!row.is_cancel && Can("purchasebill_delete") && (
          <IconButton
            aria-label=""
            onClick={() => setIsOpenDeleteModal({ isOpen: true, id: row.id })}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {!row.is_cancel &&
          row?.bill_type !== "approval_list" &&
          !row.is_return &&
          Can("purchasebill_pay_add") && (
            <IconButton
              aria-label=""
              onClick={() =>
                navigate(
                  `${procurementUrl}${purchasePaymentListUrl}${createUrl}?bill_id=${row.id}&supplier_id=${row?.supplier_id}&supplier_name=${row?.name}`
                )
              }
            >
              <PaymentRupeeIcon />
            </IconButton>
          )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "bill_ref_no",
      flex: 1.2,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("bill_ref_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Ref-No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "bill_ref_no" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "bill_ref_no" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Tooltip title={row?.bill_ref_no} arrow placement="top">
          <Typography
            variant="h5"
            fontSize={14}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {row.bill_ref_no}
          </Typography>
        </Tooltip>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "name",
      flex: 1.5,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Suppliers
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Tooltip title={row?.name} arrow placement="top">
          <Typography
            variant="h5"
            fontSize={14}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {row.name}
          </Typography>
        </Tooltip>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "invoice_no",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("invoice_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Invoice No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "invoice_no" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "invoice_no" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.invoice_no}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "invoice_date",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("invoice_date")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "invoice_date" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "invoice_date" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h5"
          fontSize={14}
          fontWeight={!row.id ? "600 !important" : "400 !important"}
        >
          {!row.id
            ? row.invoice_date
            : row.invoice_date
            ? formattedDateWithSlash(row?.invoice_date)
            : ""}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "bill_amt",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("bill_amt")}
        >
          <Typography variant="h5" fontSize={14}>
            Bill Amount
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "bill_amt" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "bill_amt" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h5"
          fontSize={14}
          fontWeight={!row.id ? "600 !important" : "400 !important"}
        >
          {row.bill_amt}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "payment",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("payment")}
        >
          <Typography variant="h5" fontSize={14}>
            Paid Amount
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "payment" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "payment" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h5"
          fontSize={14}
          fontWeight={!row.id ? "600 !important" : "400 !important"}
        >
          {row.payment}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "balance",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("balance")}
        >
          <Typography variant="h5" fontSize={14}>
            Balance
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "balance" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "balance" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h5"
          fontSize={14}
          fontWeight={!row.id ? "600 !important" : "400 !important"}
        >
          {row.balance}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "type",
      flex: 0.8,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("type")}
        >
          <Typography variant="h5" fontSize={14}>
            Type
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "type" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "type" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.type}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    ...(Can("purchasebill_view") ||
    Can("purchasebill_edit") ||
    Can("purchasebill_delete") ||
    Can("purchasebill_pay_add")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              if (row.id) {
                return <TableActions row={row} />;
              } else {
                return null;
              }
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const clearFilters = () => {
    if (
      filterData.supplier_id ||
      filterData.product_name ||
      filterData.bill_no ||
      filterData.from_date ||
      filterData.to_date ||
      filterData.type
    ) {
      dispatch(
        setFilterDataValues({
          key: "procurementPurchaseBill",
          name: "clearFilter",
          value: {
            supplier_id: "",
            product_name: "",
            bill_no: "",
            from_date: new Date(),
            to_date: new Date(),
            type: "bill_list",
          },
        })
      );
    }
  };

  const handleInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;
      if (name === "supplier_id" && value) {
        const finalValue = suppliersList.filter((obj: any) => {
          if (obj.value === value) {
            return obj;
          }
        });
        dispatch(
          setFilterDataValues({
            key: "procurementPurchaseBill",
            name: name,
            value: finalValue[0],
          })
        );
        dispatch(setCurrentPage({ key: "procurementPurchaseBill", value: 0 }));

        return;
      }

      dispatch(
        setFilterDataValues({
          key: "procurementPurchaseBill",
          name: name,
          value: value,
        })
      );

      dispatch(setCurrentPage({ key: "procurementPurchaseBill", value: 0 }));
    }
  };

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      dispatch(
        setFilterDataValues({
          key: "procurementPurchaseBill",
          name: name,
          value: newValue.validatedValue[0],
        })
      );

      dispatch(setCurrentPage({ key: "procurementPurchaseBill", value: 0 }));
    }
  };

  const supplierPagination = useRef(1);
  const searchSupplierPagination = useRef({ search: "", page: 1 });

  const debouncedGetAllSupplierList = debounce(
    async (currentValue: string, prevOptions: any[], callback: any) => {
      return await getAllSuppliers({
        search: currentValue,
        page:
          searchSupplierPagination.current?.search === currentValue
            ? searchSupplierPagination.current?.page
            : 1,
        limit: 10,
      })
        .then((result: any) => {
          let data = result?.data?.result;
          const formattedSuppliersList = data.map((uniqueData: any) => {
            return { value: uniqueData.id, label: uniqueData.name };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );
          setSuppliersList(uniqueOptions);
          searchSupplierPagination.current.page =
            searchSupplierPagination.current?.search === currentValue
              ? searchSupplierPagination.current?.page + 1
              : 1;
          searchSupplierPagination.current.search = currentValue;
          const hasMore = result.data.total > uniqueOptions?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        })
        .catch((err) => {
          console.log(err);
          callback({
            options: [],
            hasMore: false,
          });
        });
    },
    300
  );

  const getAllSuppliersList = async (currentValue: any, prevOptions: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllSupplierList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page =
          supplierPagination.current === Math.ceil(prevOptions.length / 10)
            ? supplierPagination.current
            : Math.ceil(prevOptions.length / 10) + 1;
        return await getAllSuppliers({
          search: currentValue,
          page: page,
          limit: 10,
        })
          .then((result: any) => {
            let data = result?.data?.result;

            const formattedSuppliersList = data?.map((uniqueData: any) => {
              return { value: uniqueData.id, label: uniqueData.name };
            });
            const uniqueOptions = formattedSuppliersList.filter(
              (option: any) =>
                !prevOptions.some(
                  (prevOption: any) => prevOption.value === option.value
                )
            );

            if (uniqueOptions?.length > 0) {
              setSuppliersList((prevState: any) => [
                ...prevState,
                ...uniqueOptions,
              ]);
            }
            const currentValue = supplierPagination.current;
            supplierPagination.current = currentValue + 1;
            const hasMore = result.data.total > uniqueOptions?.length;
            return {
              options: uniqueOptions,
              hasMore,
            };
          })
          .catch((err) => {
            console.log(err);
            return {
              options: [],
              hasMore: false,
            };
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const purchaseFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <InfiniteScroller
          loadOptions={getAllSuppliersList}
          options={suppliersList}
          handleOnChange={handleInputChange}
          // defaultOptions={defaultOptions}
          label="Suppliers"
          name="supplier_id"
          value={filterData.supplier_id?.value}
          // isDisabled={fieldData.supplier_id.isDisabled}
          width="100%"
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData.product_name}
          label="Product Name"
          name="product_name"
          placeholder="Enter Product Name"
          onChange={handleInputChange}
          sx={{
            ...style.textFieldStyle,
          }}
          formControlStyle={{ width: "100%" }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData.bill_no}
          label="Bill No"
          name="bill_no"
          placeholder="Enter Bill No"
          onChange={handleInputChange}
          sx={{
            ...style.textFieldStyle,
          }}
          formControlStyle={{ width: "100%" }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <Grid
          sx={{
            ...style.DatePickerStyle,
          }}
        >
          <DatePicker
            label="From Date"
            name="from_date"
            placeholder="Select Date"
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "from_date")
            }
            format="DD/MM/YYYY"
            value={filterData.from_date}
            style={{ boxShadow: "none", height: "45px", borderRadius: "5px" }}
            borderColor="var(--primary-border-color)"
            width="100%"
            formControlStyle={{ width: "100%" }}
          />
        </Grid>
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <Grid
          sx={{
            ...style.DatePickerStyle,
          }}
        >
          <DatePicker
            label="To Date"
            name="to_date"
            placeholder="Select Date"
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "to_date")
            }
            format="DD/MM/YYYY"
            value={filterData.to_date}
            style={{ boxShadow: "none", height: "45px", borderRadius: "5px" }}
            borderColor="var(--primary-border-color)"
            width="100%"
            formControlStyle={{ width: "100%" }}
          />
        </Grid>
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <Select
          placeholder="Select Bill type"
          value={filterData.type}
          label="Bill Type"
          name="type"
          sx={{ ...style.selectStyle }}
          width=""
          formControlStyle={{ width: "100%" }}
          onChange={handleInputChange}
          options={billListOptions}
        />
      ),
    },
  ];

  const getAllSuppliersData = async () => {
    try {
      await getAllSuppliers({}).then((result: any) => {
        let data = result.data;
        const suppliersList = data?.map((uniqueData: any) => {
          return { id: uniqueData.id, name: uniqueData.name };
        });
        setSuppliersList(suppliersList);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const debouncedGetAllPurchaseBill = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllPurchaseBill(data)
        .then((res: any) => {
          setPageCount(res.data.total as any);
          setLoading(false);

          const purchaseBills = [...res?.data?.result];

          if (Can("purchasebill_list_total") && res?.data?.result?.length > 0) {
            const { totalBillAmount, totalPaidAmount, totalBalance } =
              res?.data?.result?.reduce(
                (totals: any, bill: any) => {
                  totals.totalBillAmount +=
                    !bill.is_cancel && !bill.is_return
                      ? parseFloat(bill.bill_amt) || 0
                      : 0;
                  totals.totalPaidAmount +=
                    !bill.is_cancel && !bill.is_return
                      ? parseFloat(bill.payment) || 0
                      : 0;
                  totals.totalBalance +=
                    !bill.is_cancel && !bill.is_return
                      ? parseFloat(bill.balance) || 0
                      : 0;
                  return totals;
                },
                {
                  totalBillAmount: 0,
                  totalPaidAmount: 0,
                  totalBalance: 0,
                }
              );
            purchaseBills.push({
              invoice_date: "Total:",
              bill_amt: Number(totalBillAmount)?.toFixed(2),
              payment: Number(totalPaidAmount)?.toFixed(2),
              balance: Number(totalBalance)?.toFixed(2),
            });
          }
          setPurchaseBillList(purchaseBills);
        })
        .catch((err) => {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage.ERROR_FETCHING_PURCHASE_BILL}`,
            })
          );
          setLoading(false);
          console.log("error getAppointmentList", err);
        });
    }, 300),
    []
  );

  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      limit: pageInfo.pageSize,
      column: sortField?.field,
      order: sortField?.order,
      from_date: filterData.from_date
        ? commonDateFormatter(filterData.from_date, "YYYY-MM-DD")
        : null,
      to_date: filterData.to_date
        ? commonDateFormatter(filterData.to_date, "YYYY-MM-DD")
        : null,
      bill_no: filterData.bill_no,
      type: filterData.type,
      product_name: filterData.product_name,
      supplier_id: filterData.supplier_id?.value,
      search: filterData.search,
    };
    debouncedGetAllPurchaseBill(data);
  }, [pageInfo, sortField, filterData]);

  useEffect(() => {
    if (!Can("purchasebill_temp_is_draft")) {
      setBillListOptions((prevState: any) =>
        prevState?.filter((ele: any) => ele.id !== "approval_list")
      );
    }

    if (filterData?.supplier_id) {
      setSuppliersList([filterData?.supplier_id]);
    }
  }, []);

  const handleConfirmDelete = () => {
    if (isOpenDeleteModal.id) {
      setIsDeleteLoading(true);
      deletePurchaseBillById(
        isOpenDeleteModal.id,
        appConfiguration.purchase_approval
      )
        .then((res: any) => {
          setIsDeleteLoading(false);
          const data = {
            page: Number(pageInfo.page) + 1,
            limit: pageInfo.pageSize,
            column: sortField?.field,
            order: sortField?.order,
            from_date: filterData.from_date
              ? commonDateFormatter(filterData.from_date, "YYYY-MM-DD")
              : null,
            to_date: filterData.to_date
              ? commonDateFormatter(filterData.to_date, "YYYY-MM-DD")
              : null,
            bill_no: filterData.bill_no,
            product_name: filterData.product_name,
            supplier_id: filterData.supplier_id?.value,
            search: filterData.search,
          };
          debouncedGetAllPurchaseBill(data);
          setIsOpenDeleteModal({ isOpen: false, id: null });
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        })
        .catch((err) => {
          console.log("err", err?.response?.data?.errors?.message);
          setIsDeleteLoading(false);
          setIsOpenDeleteModal({ isOpen: false, id: null });
          setIsOpenErrorModal({
            isOpen: true,
            errors: err?.response?.data?.errors?.message,
          });
        });
    }
  };

  return (
    <Box>
      <CustomFilter
        data={purchaseFilter}
        isFilterEnabled={true}
        isSearchEnabled={true}
        editButtonTitle={Can("purchasebill_add") ? "Create Bill" : null}
        clearFilter={clearFilters}
        searchOnChange={handleInputChange}
        searchName={"search"}
        searchValue={filterData.search}
        clearSearch={() =>
          dispatch(
            setHandleClearSearch({
              key: "procurementPurchaseBill",
              value: "",
              name: "search",
            })
          )
        }
        onUpdateButtonClick={() => {
          navigate(`${procurementUrl}${purchaseBillUrl}${createUrl}`);
        }}
        appliedFilterCount={count}
        editStartIcon={
          <Box
            sx={{
              "& path": {
                fill: "#fff",
              },
            }}
          >
            <PlusIcon width={"12px"} height={"12px"} />
          </Box>
        }
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={purchaseBillList}
        pageCount={pageCount}
        currentPage={"procurementPurchaseBill"}
      />
      <ConfirmationDialog
        open={isOpenDeleteModal.isOpen}
        title="are you surely want to delete"
        handleClick={handleConfirmDelete}
        onClose={() => setIsOpenDeleteModal({ isOpen: false, id: null })}
        loading={isDeleteLoading}
      />
      <ConfirmationDialog
        open={isOpenErrorModal.isOpen}
        title={
          <ul>
            {isOpenErrorModal?.errors?.map((ele: { table: string }) => (
              <li>{ele?.table}</li>
            ))}
          </ul>
        }
        handleClick={() => setIsOpenErrorModal({ isOpen: false, errors: [] })}
        type={"info"}
        confirmText="Okay"
      />
    </Box>
  );
};

export default PurchaseBill;
