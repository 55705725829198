import { FormControl, FormHelperText, Grid, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { components } from "react-select";
import ClearIcon from "@mui/icons-material/Clear";

type ScrollAutoCompleteTypes = {
  handleOnChange: any;
  value: any;
  name: string;
  label?: string;
  setIsClearFilter?: any;
  isClearFilter?: any;
  loadOptions?: any;
  options?: any;
  width?: string;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  isDisabled?: boolean;
  defaultOptions?: any;
  onBlur?: any;
  onFocus?: any;
};
const initialPatientDetails = {
  aadhar_number: "",
  age: null,
  area: "",
  blood_group: null,
  district: "",
  dob: "",
  email: "",
  husband_aadhar_number: null,
  husband_mobile: null,
  id: null,
  line_1: "",
  line_2: "",
  mobile: "",
  name: "",
  patient_mobile: "",
  pincode: "",
  sex: null,
  state: "",
  title: "",
  uhid: "",
};
export default function ScrollAutoComplete({
  handleOnChange,
  value,
  name,
  label,
  isClearFilter,
  setIsClearFilter,
  loadOptions,
  options,
  width,
  error,
  helperText,
  placeholder,
  isDisabled,
  defaultOptions,
  onBlur,
  onFocus,
}: ScrollAutoCompleteTypes) {
  type OptionType = {
    value: number;
    label: string;
    patient_details?: any;
  };

  const [scrollValue, onScrollValue] = useState<OptionType | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isClearFilter) {
      onScrollValue(null);
    }
  }, [isClearFilter]);

  useEffect(() => {
    if (value && value !== scrollValue?.value) {
      const newValue: any = options?.find((ele: any) => ele.value === value);
      onScrollValue(newValue);
    } else if (!value) {
      onScrollValue(null);
    }
  }, [value, options]);

  const customStyles = {
    overflowX: "hidden",
    control: (provided: any, state: any) => ({
      ...provided,
      fontSize: "var(--primary-input-text-font-size)",
      borderRadius: "5px",
      width: width || "275px",
      border: error
        ? "1px solid #d32f2f"
        : "1px solid var(--primary-border-color)",
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      padding: "1.8px 8px",
      margin: "7px",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontSize: "var(--primary-input-text-font-size)",
      width: width || "275px",
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      width: width || "275px",
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      display: "none",
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: "var(--primary-placeholder-font-size)",
      opacity: 0.6,
    }),
  };

  const ClearIndicator = (props: any) => {
    return (
      <components.ClearIndicator {...props}>
        <ClearIcon
          onClick={() => {
            onScrollValue(null);
            handleOnChange({
              target: {
                name: name,
                value: null,
                patient_details: initialPatientDetails,
              },
            });
          }}
        />
      </components.ClearIndicator>
    );
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  };

  return (
    <Grid>
      {label && (
        <InputLabel
          htmlFor="merchant-autocomplete"
          focused={isFocused}
          sx={{
            fontSize: "var(--primary-label-font-size)",
            color: "var(--primary-label-color)",
            marginBottom: "4px",
          }}
        >
          {label}
        </InputLabel>
      )}
      <FormControl fullWidth>
        <AsyncPaginate
          onFocus={handleFocus}
          onBlur={(e: any) => {
            setIsFocused(false);
            onBlur && onBlur(e);
          }}
          key={JSON.stringify(value)}
          placeholder={placeholder}
          value={scrollValue}
          loadOptions={loadOptions}
          onChange={(e) => {
            handleOnChange({
              target: {
                name: name,
                value: e?.value,
                patient_details: e?.patient_details
                  ? e?.patient_details
                  : initialPatientDetails,
              },
            });
            onScrollValue(e);
          }}
          name={name}
          styles={customStyles}
          isDisabled={isDisabled}
          isClearable
          components={{ ClearIndicator }}
          defaultOptions={defaultOptions}
        />
      </FormControl>
      {helperText !== undefined && (
        <FormHelperText
          error={error}
          sx={{
            ml: 2,
            fontSize: "11px",
            minHeight: "20px",
            height: "auto",
            color: "#d32f2f",
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </Grid>
  );
}
