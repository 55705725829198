import React, { useCallback, useEffect, useRef, useState } from "react";
import { debounce, Grid } from "@mui/material";
import { SettingsIcon } from "../../../../assets/icons";
import {
  Select,
  DatePickerWithTime,
  TextField,
} from "../../../../components/basic";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import SequenceWrapper from "../../../../components/shared/sequenceWrapper";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import {
  getAllMasterCustomer,
  getAllMasterDepartments,
  getAllSuppliers,
  getBillTermsOptions,
} from "../../../../services/gynecologyService";
import {
  getDepartmentSequence,
  updatedSequence,
} from "../../../../services/mainCoreService";

type InfoProps = {
  infoData?: any;
  setInfoData: any;
  fieldData: any;
  handleValidation: (e: any) => void;
  error?: any;
  setInfoError?: any;
  handleBlurPoNumber?: (e: any) => void;
  isEdit?: boolean;
  customSequence?: boolean;
  handleSequence: () => void;
  setSequenceData: React.Dispatch<any>;
  sequenceData: any;
  autoGeneratedSeqNo: any;
  setIsManual: React.Dispatch<any>;
  isManual: any;
  setSeqIsOne: React.Dispatch<any>;
  sequenceNo: any;
  isSequenceNumberChanged: boolean;
  seqIsOnce: any;
  commonVariables?: any;
};

type optionsListTypes = {
  id: number;
  name: string;
};
const Info = React.memo(
  ({
    infoData,
    setInfoData,
    fieldData,
    handleValidation,
    error,
    setInfoError,
    handleBlurPoNumber,
    isEdit,
    customSequence,
    handleSequence,
    setSequenceData,
    sequenceData,
    autoGeneratedSeqNo,
    setIsManual,
    isManual,
    setSeqIsOne,
    sequenceNo,
    isSequenceNumberChanged,
    seqIsOnce,
    commonVariables,
  }: InfoProps) => {
    const [suppliersList, setSuppliersList] = useState<
      optionsListTypes[] | any
    >([]);
    const [departmentList, setDepartmentList] = useState<
      optionsListTypes[] | any
    >([]);
    const [sequenceDialogBox, setSequenceDialogBox] = useState(false);
    const dispatch = useDispatch();
    const [issuersList, setIssuersList] = useState<optionsListTypes[]>([]);
    const [billPaymentTypeOptions, setBillPaymentTypeOptions] = useState<any>(
      []
    );

    const [customerList, setCustomerList] = useState<any>([]);

    const { appConfiguration } = useSelector(
      (state: RootState) => state.appConfiguration
    );

    // const getPaymentTypeOptions = () => {
    //   getBillTermsOptions()
    //     .then((res: any) => {
    //       if (res) {
    //         setBillPaymentTypeOptions(res);
    //       }
    //     })
    //     .catch((err) => console.log("err", err));
    // };
    useEffect(() => {
      if (commonVariables?.bill_payment_type) {
        setBillPaymentTypeOptions(commonVariables.bill_payment_type);
      }
    }, [commonVariables]);

    const handleChange = (e: any) => {
      const { name, value } = e.target;
      setInfoData((prev: any) => ({
        ...prev,
        [name]: value,
        department:
          name === "dept_id"
            ? departmentList.find((ele: { id: number }) => ele.id === value)
                ?.name
            : prev.department,
      }));
      setInfoError((prevState: any) => ({
        ...prevState,
        [name]: value ? "" : prevState[name],
      }));
      if (name === "customer_id") {
        const findCustomer = customerList.find(
          (ele: { id: number }) => ele.id === value
        );
        setInfoData((prevState: any) => ({
          ...prevState,
          address: findCustomer?.address || "",
          mobile_no: findCustomer?.phone || "",
        }));
      }
    };

    const handleDateChange = (newValue: any, name: any) => {
      setInfoData((prev: any) => ({
        ...prev,
        [name]: newValue.toString(),
      }));
      setInfoError((prevState: any) => ({
        ...prevState,
        [name]: newValue?.toString() ? "" : prevState[name],
      }));
    };

    const supplierPagination = useRef(1);

    const debouncedGetAllSupplierList = useCallback(
      debounce(async (currentValue: any) => {
        return await getAllSuppliers({
          search: currentValue,
          page: supplierPagination.current,
          limit: 10,
        }).then((result: any) => {
          let data = result?.data?.data;
          const formattedSuppliersList = data.map((uniqueData: any) => {
            return { value: uniqueData.id, label: uniqueData.name };
          });
          setSuppliersList(formattedSuppliersList);
          supplierPagination.current = 1;
          const hasMore = result?.data?.total > suppliersList?.length;
          return {
            options: suppliersList,
            hasMore,
          };
        });
      }, 300),
      []
    );

    function onCloseSequenceDialogBox() {
      setSequenceDialogBox(false);
    }
    const handleSequenceOnChange = (e: any, index: number) => {
      const { name, value } = e.target;
      setSequenceData((prevState: any) => {
        const newState = [...prevState];
        newState[index] = {
          ...newState[index],
          [name]: value === "Select" ? "" : value,
        };
        return newState;
      });
    };
    const handleInvoiceNoChange = () => {
      if (
        autoGeneratedSeqNo !== infoData.invoice_no &&
        Boolean(!isManual.is_once)
      ) {
        setSeqIsOne((prevState: any) => ({
          ...prevState,
          isOnce: true,
          isOnceSeqValue: infoData.invoice_no,
        }));
        setSequenceDialogBox(true);
        setIsManual((prevState: any) => ({
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        }));
      }
    };

    const handleSequenceSubmit = async () => {
      if (isManual.is_once) {
        onCloseSequenceDialogBox();
      } else {
        const data = {
          seq_preference: [...sequenceData],
          is_manual: isManual.is_manual ? 1 : 0,
        };
        await updatedSequence(Number(sequenceNo), data)
          .then((res: any) => {
            if (res) {
              isSequenceNumberChanged = true;
              handleSequence();
              onCloseSequenceDialogBox();
              dispatch(
                setSnackBarSuccess({
                  snackBarMessage: "Sequence Updated SuccessFully",
                })
              );
            }
          })
          .catch((err: any) => {
            console.log(err);
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err.response.data.errors.message,
              })
            );
          });
      }
    };

    const getAllMasterCustomerList = async () => {
      try {
        const data = { active: 1 };
        await getAllMasterCustomer(data).then((res: any) => {
          if (res.data) {
            const newCustomerList = res.data?.result.map((ele: any) => ({
              name: ele.name,
              id: ele.id,
              address: ele.address,
              phone: ele.phone,
            }));
            setCustomerList(newCustomerList);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    const getAllDepartmentList = async () => {
      try {
        const data = { active: 1 };
        await getAllMasterDepartments(data).then((res: any) => {
          if (res.data) {
            const newDepartmentList = res.data?.result
              ?.filter((ele: any) => ele.type === "issuer")
              .map((ele: any) => ({ name: ele.dept_name, id: ele.id }));
            const newIssuerList = res.data?.result
              ?.filter((ele: any) => ele.type === "receiver")
              .map((ele: any) => ({ name: ele.dept_name, id: ele.id }));
            setDepartmentList(newDepartmentList);
            setIssuersList(newIssuerList);

            if (newIssuerList?.length === 1 || newDepartmentList.length === 1) {
              setInfoData((prevState: any) => ({
                ...prevState,
                to_dept: newIssuerList?.length === 1 ? newIssuerList[0].id : "",
                dept_id: isEdit
                  ? prevState.dept_id
                  : newDepartmentList?.length === 1
                  ? newDepartmentList[0].id
                  : "",
                department: newDepartmentList[0]?.name,
              }));
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getAllDepartmentList();
      getAllMasterCustomerList();
    }, [appConfiguration?.goods_issue_disable]);

    const handleSequenceCheckBoxChange = (e: any) => {
      const { name, checked } = e.target;
      setIsManual((prevState: any) => {
        if (name === "is_manual") {
          return {
            ...prevState,
            is_manual: true,
            is_auto_generate: false,
            is_once: false,
          };
        }
        if (name === "is_auto_generate") {
          return {
            ...prevState,
            is_manual: false,
            is_auto_generate: true,
            is_once: false,
          };
        }
        if (name === "is_once") {
          return {
            ...prevState,
            is_manual: false,
            is_auto_generate: false,
            is_once: true,
          };
        }
      });
    };

    useEffect(() => {
      if (infoData.supplier_id && infoData.supplier_name) {
        setSuppliersList([
          { value: infoData.supplier_id, label: infoData.supplier_name },
        ]);
      }
    }, []);

    const styles = {
      selectGrid: {
        width: "100%",
        height: "44px",
        boxShadow: "none",
        borderRadius: "5px",
        borderColor: "var(--primary-border-color)",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
      textFieldStyle: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
          height: "44px",
          borderRadius: "5px",
          paddingLeft: "8px",
          boxShadow: "none",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
    };
    return (
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          m: "0px 0px 30px 0px",
          width: "90vw",
          minHeight: "100px",
          height: "auto",
          gap: 1,
        }}
      >
        <Grid item xs={12} sm={5.9} md={3} lg={1.9} xl={1.9}>
          <Select
            value={fieldData.dept_id.value}
            onChange={handleChange}
            placeholder={fieldData.dept_id.placeholder}
            options={departmentList}
            label={fieldData.dept_id.label}
            disabled={fieldData.dept_id?.isDisables}
            name={fieldData.dept_id.name}
            formControlStyle={{ width: "100%" }}
            width=""
            sx={{
              ...styles.selectGrid,
            }}
            error={!!error?.dept_id}
          />
        </Grid>
        <Grid item xs={12} sm={5.9} md={3} lg={1.9} xl={1.9}>
          <Select
            value={fieldData.customer_id.value}
            label={fieldData.customer_id.label}
            placeholder={fieldData.customer_id.placeholder}
            onChange={handleChange}
            name={fieldData.customer_id.name}
            disabled={fieldData.customer_id.isDisabled}
            formControlStyle={{ width: "100%" }}
            sx={{
              ...styles.selectGrid,
              backgroundColor: fieldData.customer_id.isDisabled
                ? "var(--disabled-background)"
                : "initial",
            }}
            options={customerList}
            width="188px"
            error={!!error?.customer_id}
          />
        </Grid>
        <Grid item xs={12} sm={5.9} md={3} lg={1.9} xl={1.9}>
          <TextField
            value={fieldData.mobile_no.value}
            label={fieldData.mobile_no.label}
            placeholder={fieldData.mobile_no.placeholder}
            onChange={handleChange}
            name={fieldData.mobile_no.name}
            formControlStyle={{ width: "100%" }}
            sx={{
              ...styles.textFieldStyle,
              backgroundColor: "var(--disabled-background)",
            }}
            error={!!error?.mobile_no}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={5.9} md={3} lg={1.9} xl={1.9}>
          <TextField
            value={fieldData.address.value}
            label={fieldData.address.label}
            placeholder={fieldData.address.placeholder}
            onChange={handleChange}
            name={fieldData.address.name}
            sx={{
              ...styles.textFieldStyle,
              backgroundColor: "var(--disabled-background)",
            }}
            error={!!error?.address}
            disabled
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5.9}
          md={3}
          lg={1.9}
          xl={1.9}
          sx={{
            "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container  >div >div .rmdp-input":
              {
                boxSizing: "border-box",
                width: "100%",
              },
          }}
        >
          <DatePickerWithTime
            value={fieldData.date_created.value}
            label={fieldData.date_created.label}
            placeholder={fieldData.date_created.placeholder}
            onChange={(newValue: any) =>
              handleDateChange(newValue, "date_created")
            }
            formControlStyle={{ width: "100%" }}
            width="100%"
            borderColor="var(--primary-border-color)"
            style={{
              boxShadow: "none",
              height: "45px",
              borderRadius: "5px",
            }}
            error={!!error?.date_created}
          />
        </Grid>
        {customSequence ? (
          <Grid item xs={12} sm={5.9} md={3} lg={1.9} xl={1.9}>
            <TextField
              value={fieldData.invoice_no.value}
              label={fieldData.invoice_no.label}
              placeholder={fieldData.invoice_no.placeholder}
              onChange={handleChange}
              name={fieldData.invoice_no.name}
              formControlStyle={{ width: "100%" }}
              sx={{
                ...styles.textFieldStyle,
              }}
              error={!!error?.invoice_no}
              onBlur={handleInvoiceNoChange}
              endPlaceholderIcon={
                <SettingsIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSequenceDialogBox(true);
                  }}
                />
              }
            />
          </Grid>
        ) : null}
        <SequenceWrapper
          open={sequenceDialogBox}
          title="B2B"
          onClose={onCloseSequenceDialogBox}
          handleChange={handleSequenceOnChange}
          sequenceValue={sequenceData}
          isOnce={seqIsOnce}
          handleClick={handleSequenceSubmit}
          handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
          isManual={isManual}
        />
        <Grid item xs={12} sm={5.9} md={3} lg={1.9} xl={1.9}>
          <Select
            value={fieldData.payment.value}
            label={fieldData.payment.label}
            placeholder={fieldData.payment.placeholder}
            onChange={handleChange}
            name={fieldData.payment.name}
            disabled={fieldData.payment.isDisabled}
            sx={{
              ...styles.selectGrid,
              backgroundColor: fieldData.payment.isDisabled
                ? "var(--disabled-background)"
                : "initial",
            }}
            options={billPaymentTypeOptions}
            formControlStyle={{ width: "100%" }}
            width=""
            error={!!error?.payment}
          />
        </Grid>
      </Grid>
    );
  }
);

export default Info;
