import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { SettingsIcon } from "../../../../assets/icons";
import { Select, TextField } from "../../../../components/basic";
import { indentInfoConst } from "../../../../constants/displayText";

import { getAllDepartments } from "../../../../services/gynecologyService";

type InfoType = {
  indent_no: number | null;
  department: number | null;
  indent_no_modified: number | null;
  department_name?: string;
  created_at?: string;
};

type InfoProps = {
  info: InfoType;
  setInfoError: any;
  setInfo: React.Dispatch<React.SetStateAction<InfoType>>;
  resetBillProducts?: any;
  errors: { [key: string]: string };
  isEdit?: boolean;
  indentChange?: any;
  setIsManual?: any;
  isManualAdded?: any;
  isManual?: any;
  handleConfirmSequenceNoChange: () => void;
  customSequence?: boolean;
  sequenceDialogBox: any;
  setSequenceDialogBox: React.Dispatch<React.SetStateAction<boolean>>;
};

const Info = React.memo(
  ({
    info,
    setInfo,
    errors,
    isEdit,
    handleConfirmSequenceNoChange,
    setSequenceDialogBox,
    customSequence,
    resetBillProducts,
  }: InfoProps) => {
    const styles = {
      selectGrid: {
        width: "100%",
        height: "45px",
        boxShadow: "none",
        borderRadius: "5px",
        borderColor: "var(--primary-border-color)",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },

      textFieldStyle: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
          height: "45px",
          borderRadius: "5px",
          paddingLeft: "8px",
          boxShadow: "none",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },

      labelStyle: {
        color: "#8A8A8A",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "1.4375em",
        marginBottom: "5px",
      },
    };
    const { indentNo, department } = indentInfoConst;
    const [departmentList, setDepartmentList] = useState([]);

    const handleInputChange = (e: any) => {
      const { name, value } = e.target;
      setInfo((prev: any) => ({
        ...prev,
        [name]: value,
      }));
      if (name === "department") {
        resetBillProducts();
      }
    };

    useEffect(() => {
      if (!isEdit) {
        getAllDepartments()
          .then((res: any) => {
            if (res?.data?.result) {
              setDepartmentList(
                res?.data?.result?.map((ele: any) => ({
                  ...ele,
                  name: ele.dept_name,
                }))
              );
            }
          })
          .catch((err: any) => console.log(err));
      }
    }, []);

    return (
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "wrap",
          m: "0px 0px 30px 0px",
          width: "90vw",
          minHeight: "100px",
          height: "auto",
          gap: 1,
        }}
      >
        {customSequence ? (
          <Grid item xs={12} sm={5.9} md={3} lg={2} xl={2}>
            <TextField
              value={info.indent_no}
              label={indentNo}
              placeholder="Enter Indent No"
              onChange={handleInputChange}
              onBlur={handleConfirmSequenceNoChange}
              endPlaceholderIcon={
                <SettingsIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSequenceDialogBox(true);
                  }}
                />
              }
              name="indent_no"
              sx={{
                ...styles.textFieldStyle,
              }}
              error={!!errors?.indent_no}
            />
          </Grid>
        ) : null}

        {isEdit ? (
          <Box mb={"20px"}>
            <Typography sx={styles.labelStyle}>Department</Typography>
            <Typography>{info.department_name}</Typography>
          </Box>
        ) : (
          <Grid item xs={12} sm={5.9} md={3} lg={2} xl={2}>
            <Select
              value={info.department}
              onChange={handleInputChange}
              placeholder="Select"
              options={departmentList}
              label={department}
              name="department"
              width=""
              formControlStyle={{ width: "100%" }}
              sx={{
                ...styles.selectGrid,
              }}
              error={!!errors?.department}
            />
          </Grid>
        )}
        {isEdit && (
          <Box mb={"20px"}>
            <Typography sx={styles.labelStyle}>Date</Typography>
            <Typography>{info.created_at}</Typography>
          </Box>
        )}
      </Grid>
    );
  }
);

export default Info;
