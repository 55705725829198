import React from "react";
import { Grid, Typography } from "@mui/material";

const Billings = () => {
  return (
    <Grid sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h2" color="initial">
        Billings
      </Typography>
      <Typography variant="h4" color="initial" sx={{ mt: "10px" }}>
        Coming Soon
      </Typography>
    </Grid>
  );
};

export default Billings;
