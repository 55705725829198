import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Vitals from "./Vitals";
import Investigations from "./Investigations";
import Treatments from "./Treatments";
import Prescription from "./Prescription";
import DietPlan from "./Diet Plan";
import Billings from "./Billings";
import Discharge from "./Discharge";
import {
  InvestigationIcon,
  PrescriptionIcon,
  VitalsIcon,
  HighlightedInvestigationIcon,
  HighlightedPrescriptionIcon,
  HighlightedVitalsIcon,
  OverViewIcon,
  HighlightedOverviewIcon,
  TreatmentsIcon,
  HighlightedTreatmentsIcon,
  OperationTheatreIcon,
  HighlightedOperationTheatreIcon,
  DoctorVisitIcon,
  HighlightedDoctorVisitIcon,
  DischargeSummaryIcon,
  HighlightedDischargeSummaryIcon,
  BillingsIcon,
  HighlightedBillingsIcon,
} from "../../../../assets/icons";
import { useParams, useSearchParams } from "react-router-dom";
import { getInPatientAdmissionDetailById } from "../../../../services/admissionService";
import { Link } from "react-router-dom";
import { RouteUrls } from "../../../../constants/routes";
import PermissionUtils from "../../../../utils/PermissionUtils";
import OperationTheatre from "./OperationTheatre";
import DoctorVisits from "./DoctorVisits";
import Overview from "./Overview";
import { Tabs } from "../../../../components/shared";

const PatientHistory = () => {
  const { id } = useParams();
  const { can } = PermissionUtils();

  const [searchParams] = useSearchParams();

  const type = searchParams.get("type");

  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [ipAdmissionDetails, setIpAdmissionDetails] = useState<any>(null);
  const [initialTabValue, setInitialTabValue] = useState<number>(0);

  const initialPatientDetails = {
    name: "",
    relation: "",
    UHID: "",
    mobile: "",
    age: null,
    address: "",
    gender: "",
  };

  const [patientDetails, setPatientDetails] = useState<any>(
    initialPatientDetails
  );

  const initialIpInformation = {
    ipNo: "",
    reason: "",
    doctorName: "",
    admissionDate: "",
    roomNo: "",
    floorName: "",
    status: "",
    attenderName: "",
    attenderMobile: "",
    dischargeDate: "",
    secondAttenderName: "",
    secondAttenderMobile: "",
    dischargeCondition: "",
  };

  const [ipInformation, setIpInformation] = useState<any>(initialIpInformation);

  interface TabsData {
    id: number;
    label: string;
    icon?: any;
    highlightedIcon?: any;
    children?: JSX.Element;
  }

  const tabsData: TabsData[] = useMemo(
    () => [
      ...(can("ip_view") && ipAdmissionDetails
        ? [
            {
              id: 0,
              label: "Overview",
              Icon: <OverViewIcon />,
              highlightedIcon: <HighlightedOverviewIcon />,
              children: (
                <Overview
                  ipAdmissionDetails={ipAdmissionDetails}
                  patientDetails={patientDetails}
                  ipInformation={ipInformation}
                />
              ),
            },
          ]
        : []),
      ...(can("ip_vitals_view") && ipAdmissionDetails
        ? [
            {
              id: 1,
              label: "Vitals",
              Icon: <VitalsIcon />,
              highlightedIcon: <HighlightedVitalsIcon />,
              children: <Vitals ipAdmissionDetails={ipAdmissionDetails} />,
            },
          ]
        : []),
      ...(can("ip_prescriptions_view") && ipAdmissionDetails
        ? [
            {
              id: 2,
              label: "Prescription",
              Icon: <PrescriptionIcon />,
              highlightedIcon: <HighlightedPrescriptionIcon />,
              children: (
                <Prescription ipAdmissionDetails={ipAdmissionDetails} />
              ),
            },
          ]
        : []),
      ...(can("ip_investigations_view") && ipAdmissionDetails
        ? [
            {
              id: 3,
              label: "Investigations",
              Icon: <InvestigationIcon />,
              highlightedIcon: <HighlightedInvestigationIcon />,
              children: (
                <Investigations ipAdmissionDetails={ipAdmissionDetails} />
              ),
            },
          ]
        : []),
      ...(can("ip_treatments_view") && ipAdmissionDetails
        ? [
            {
              id: 4,
              label: "Treatments",
              Icon: <TreatmentsIcon />,
              highlightedIcon: <HighlightedTreatmentsIcon />,
              children: <Treatments ipAdmissionDetails={ipAdmissionDetails} />,
            },
          ]
        : []),
      ...(can("ip_surgery_list") && ipAdmissionDetails
        ? [
            {
              id: 5,
              label: "Operation Theatre",
              Icon: <OperationTheatreIcon />,
              highlightedIcon: <HighlightedOperationTheatreIcon />,
              children: (
                <OperationTheatre ipAdmissionDetails={ipAdmissionDetails} />
              ),
            },
          ]
        : []),
      ...(can("ip_discharge_view") && ipAdmissionDetails
        ? [
            {
              id: 6,
              label: "DietPlan",
              Icon: <VitalsIcon />,
              highlightedIcon: <HighlightedVitalsIcon />,
              children: <DietPlan />,
            },
          ]
        : []),
      ...(can("ip_discharge_view") && ipAdmissionDetails
        ? [
            {
              id: 7,
              label: "Billings",
              Icon: <BillingsIcon />,
              highlightedIcon: <HighlightedBillingsIcon />,
              children: <Billings />,
            },
          ]
        : []),
      ...(can("ip_discharge_view") && ipAdmissionDetails
        ? [
            {
              id: 8,
              label: "Doctor Visits",
              Icon: <DoctorVisitIcon />,
              highlightedIcon: <HighlightedDoctorVisitIcon />,
              children: (
                <DoctorVisits ipAdmissionDetails={ipAdmissionDetails} />
              ),
            },
          ]
        : []),
      ...(can("ip_discharge_view") && ipAdmissionDetails
        ? [
            {
              id: 9,
              label: "Discharge",
              Icon: <DischargeSummaryIcon />,
              highlightedIcon: <HighlightedDischargeSummaryIcon />,
              children: <Discharge ipAdmissionDetails={ipAdmissionDetails} />,
            },
          ]
        : []),
    ],
    [ipAdmissionDetails, patientDetails, ipInformation]
  );

  const getInPatientAdmissionDetail = async () => {
    if (!id) {
      return false;
    }
    try {
      setIsPageLoading(true);
      await getInPatientAdmissionDetailById(id).then((result: any) => {
        let data = result?.data;

        const newPatientDetails = {
          name: data?.patient_details?.name,
          relation: data?.patient_details?.relation_name,
          UHID: data?.patient_details?.uhid,
          mobile: data?.patient_details?.mobile,
          age: data?.patient_details?.age,
          address: data?.patient_details?.area,
          gender: data?.patient_details?.sex === 1 ? "Female" : "Male",
        };

        const newIpInformation = {
          ipNo: data?.mlc == 1 ? `${data?.ip_no} - MLC` : data?.ip_no,
          reason: data?.reason,
          doctorName: data?.doctor_name,
          admissionDate: data?.doa,
          roomNo: data?.room_no,
          floorName: data?.floor_name,
          status:
            data?.status === "Expired"
              ? `${data?.status} - ${data?.death_date}`
              : data?.status,
          attenderName: data?.attender,
          dischargeDate: data?.dod,
          attenderMobile: data?.attender_mobile,
          secondAttenderName: data?.second_attender,
          secondAttenderMobile: data?.second_attender_mobile,
          dischargeCondition: data?.health_condition,
        };

        setIpAdmissionDetails(data);
        setPatientDetails(newPatientDetails);
        setIpInformation(newIpInformation);
        setIsPageLoading(false);
      });
    } catch (error) {
      setIsPageLoading(false);
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    getInPatientAdmissionDetail();
  }, [id]);

  useEffect(() => {
    if (type === "prescriptions") {
      setInitialTabValue(2);
    }
    if (type === "ot") {
      setInitialTabValue(5);
    }
  }, [type]);

  return isPageLoading ? (
    <Grid
      sx={{
        width: "92vw",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        height: "80vh",
        background: "transparent",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid
      sx={{
        width: { xs: "100vw", sm: "92vw" },
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        mt: { xs: "0px", sm: "-20px" },
      }}
      className="tabs-grid"
    >
      <Tabs
        data={tabsData}
        initialValue={initialTabValue}
        scrollButtons={true}
        sx={{ width: "100%", boxSizing: "border-box" }}
      />
    </Grid>
  );
};

export default PatientHistory;
