import { alpha, styled } from "@mui/material/styles";
import {
  FormControl,
  InputBase,
  InputLabel,
  InputProps,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ChangeEvent, ReactNode } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiSelect-select": {
    minWidth: "140px !important",
  },
  "& .MuiInputBase-input": {
    borderRadius: "var(--primary-border-radius)",
    position: "relative",
    border: "1px solid",
    borderColor: "var(--primary-border-color)",
    fontSize: 16,
    width: "auto",
    maxWidth: "max-content",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      border: "2px solid",
      borderColor: theme.palette.primary.main,
    },
  },
}));
interface CustomInputProps extends InputProps {
  labelName?: string;
  type?: string;
  select?: boolean | any;
  name?: string | any;
  value?: string | any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelectChange?: (e: SelectChangeEvent<any>, child: ReactNode) => void;
  menuItems?: object[];
}

export default function CustomInput({
  labelName,
  type,
  select,
  name,
  onChange,
  value,
  onSelectChange,
  menuItems,
}: CustomInputProps) {
  return (
    <FormControl variant="standard">
      {labelName && (
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          sx={{
            fontSize: "var(--primary-label-font-size)",
            color: "var(--primary-label-color)",
          }}
        >
          {labelName}
        </InputLabel>
      )}
      {!type && select ? (
        <Select
          id="demo-customized-textbox"
          value={value}
          onChange={onSelectChange}
          input={<BootstrapInput />}
          name={name}
          IconComponent={ExpandMoreIcon}
        >
          {menuItems?.map((ele: any) => (
            <MenuItem value={ele.value}>{ele?.name}</MenuItem>
          ))}
        </Select>
      ) : (
        <BootstrapInput
          id="bootstrap-input"
          type={type}
          onChange={onChange}
          name={name}
        />
      )}
    </FormControl>
  );
}
