import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { displayText } from "../../../constants/displayText";

type LoadingButtonProps = {
  loading?: boolean;
  handleClick?: any;
  buttonText?: string;
  sx?: {};
  buttonSize?: "sm" | "md" | "lg" | "xl" | undefined;
};

const commonStyle = {
  sm: {
    minWidth: "88px",
    height: "32px",
    fontSize: "12px",
    padding: "6px 12px",
  },
  md: {
    minWidth: "90px",
    height: "40px",
    fontSize: "14px",
    padding: "10px 20px",
  },
  lg: {
    minWidth: "112px",
    height: "48px",
    fontSize: "16px",
    padding: "14px 28px",
  },
  xl: {
    minWidth: "120px",
    height: "56px",
    fontSize: "16px",
    padding: "18px 32px",
  },
};

function DeleteButton({
  loading = false,
  handleClick,
  buttonText = "Delete",
  sx,
  buttonSize = "md",
}: LoadingButtonProps) {
  const styles = {
    boxShadow: "none",
    borderRadius: "5px",
    width: "fit-content",
    height: "40px",
    fontWeight: 400,
    fontSize: 14,
    textTransform: "none",
    backgroundColor: "#F7525A",
    color: "white",
    "&.MuiLoadingButton-loading": {
      backgroundColor: "#F7525A",
    },
    "&:hover": {
      backgroundColor: "#D9434E",
      color: "white",
      boxShadow: "none",
    },
  };

  return (
    <LoadingButton
      size="small"
      onClick={handleClick}
      loading={loading}
      loadingPosition={loading ? "start" : undefined}
      startIcon={null}
      variant="contained"
      type="submit"
      sx={{
        ...commonStyle[buttonSize],
        ...styles,
        ...sx,
      }}
    >
      {buttonText}
    </LoadingButton>
  );
}

export default DeleteButton;
