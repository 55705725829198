import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import { formula } from "../../../utils/CalculationUtils";
import {
  Button,
  SaveButton,
  CancelButton,
  PageLoader,
  DeleteDialog,
} from "../../../components/basic";
import Info from "./Info";
import ProductRow from "./ProductRow";
import Summary from "./Summary";
import { goodsIssueReturnConst } from "../../../constants/displayText";
import {
  getAllMasterDepartments,
  createGoodsIssueReturn,
  getGoodsReturnProducts,
  getGoodsIssueReturnById,
  updateGoodsIssueReturnById,
} from "../../../services/gynecologyService";
import { useDispatch, useSelector } from "react-redux";
import {
  formattedDateWithSlash,
  formatTwelveHoursTime,
} from "../../../utils/DateTimeFormatUtils";
import SequenceWrapper from "../../../components/shared/sequenceWrapper";
import {
  setSnackBarFailed,
  setSnackBarInfo,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDepartmentSequence,
  updatedSequence,
} from "../../../services/mainCoreService";
import { RootState } from "../../../redux/store";

type SummaryType = {
  total_discount_amount: number;
  goods_value: number;
  CGST: number;
  SGST: number;
  tax_amount: number;
  rounded_off: number;
  bill_amount: number;
  net_amount: number;
};

type InfoType = {
  from_dept: string | number;
  to_dept: string | number;
  invoice_no: string | number;
};

const BillProducts = React.memo(() => {
  const { productName, batchNo, expiryDate, retQty, discount, tax, amount } =
    goodsIssueReturnConst;

  const {
    calculateAmount,
    calculateTaxAmount,
    calculateRoundedOff,
    calculateTotalAmount,
    calculateNetAmount,
  } = formula.goodsIssueReturn;
  const { calculateDiscountAmount } = formula.purchaseBill;
  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: {
          xs: "92vw",
        },
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "90vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "44px !important",
          maxHeight: "44px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },
        "& .MuiTableCell-root": {
          minHeight: "0px",
        },
        "& .MuiTableCell-head": {
          height: "44px",
          maxHeight: "44px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "var(--table-header-font-size)",
          fontWeight: "500",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body": {
          height: "44px !important",
          maxHeight: "44px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "var(--table-body-font-size)",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "44px",
        },
        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "44px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },

      textFieldStyle: {
        height: "44px",
        width: "auto",

        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "44px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "44px",
          maxHeight: "44px",
          top: 0,
        },

        "& input": {
          height: "100%",
        },
      },

      selectStyle: {
        "& .MuiFormControl-root": { width: "100%" },

        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
          borderWidth: 0,
          height: "44px",
          maxHeight: "44px",
          p: 0,
        },

        "& .MuiInputBase-root": {
          padding: "0px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },

        "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "primary.main",
            borderWidth: "1px",
          },

        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: 0,
        },
      },
    }),
    []
  );
  const { id } = useParams();
  const TableHeaderCells: any[] = [
    { TableCellName: productName, alignment: "left" },
    { TableCellName: batchNo, alignment: "left" },
    { TableCellName: expiryDate, alignment: "left" },
    { TableCellName: "Pack Type", alignment: "right" },

    ...(id ? [{ TableCellName: "Max.Strip.Qty", alignment: "right" }] : []),
    ...(id ? [{ TableCellName: "Max.Qty", alignment: "right" }] : []),
    ...(!id ? [{ TableCellName: "Stock Qty", alignment: "right" }] : []),

    { TableCellName: "Ret.Strip Qty", alignment: "right" },
    { TableCellName: retQty, alignment: "right" },
    { TableCellName: "PTR", alignment: "right" },

    { TableCellName: "Discount", alignment: "right" },
    { TableCellName: tax, alignment: "right" },
    {
      TableCellName: amount,
      alignment: "right",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [batchIdNo, setBatchIdNo] = useState<any[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmitLoaded, setIsSubmitLoaded] = useState(false);
  const initialBillProduct = {
    pack: null,
    qty: null,
    mrp: 0,
    id: 0,
    product_name: null,
    autoComplete_input_value: null,
    stock: null,
    free: null,
    ret_qty: null,
    f_ret_qty: null,
    discount: "",
    batch_no: "",
    expiry_date: "",
    tax: "",
    ptr: "",
    amount: null,
    discount_amount: null,
    tax_amount: null,
  };
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<{
    isOpen: boolean;
    selectedRow: {
      id: number;
      batchNo?: string;
    } | null;
  }>({
    isOpen: false,
    selectedRow: null,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [customSequence, setCustomSequence] = useState(true);

  const [billProducts, setBillProducts] = useState<any>([initialBillProduct]);
  const [productRow, setProductRow] = useState([]);

  const initialSummary = {
    total_discount_amount: 0,
    goods_value: 0,
    CGST: 0,
    SGST: 0,
    tax_amount: 0,
    rounded_off: 0,
    bill_amount: 0,
    net_amount: 0,
  };

  const [summary, setSummary] = useState<SummaryType>(initialSummary);
  const [issuerList, setIssuerList] = useState([]);
  const [receiverList, setReceiverList] = useState([]);
  const initialInfo = {
    from_dept: "",
    to_dept: "",
    invoice_no: null,
  };
  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);
  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });
  const [isManualAdded, setIsManualAdded] = useState(false);
  const [sequenceNo, setSequenceNo] = useState("");
  const [sequenceDialogBox, setSequenceDialogBox] = useState(false);
  const [autoGeneratedSeqNo, setAutoGeneratedSeqNo] = useState("");
  const [seqIsOnce, setSeqIsOne] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });
  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");
  const [info, setInfo] = useState<InfoType>(initialInfo as any);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [formError, setFormError] = useState<
    {
      ret_qty: boolean;
    }[]
  >([
    {
      ret_qty: false,
    },
  ]);
  const handleValidation = (
    currentValue: any,
    updatedValue: any,
    idx: number | string
  ) => {
    if (Number(currentValue?.ret_qty) > Number(updatedValue?.stock_qty)) {
      setFormError((prevState) => {
        const newState = [...prevState];
        newState[idx as number]["ret_qty"] = true;
        return newState;
      });
    } else {
      setFormError((prevState) => {
        const newState = [...prevState];
        newState[idx as number]["ret_qty"] = false;
        return newState;
      });
    }
  };
  useEffect(() => {
    if (id) {
      setIsPageLoading(true);
      getGoodsIssueReturnById(id)
        .then((res: any) => {
          const purchaseBill = res.data.bill;
          const billProducts = res.data.bill_products;
          if (purchaseBill === null || billProducts === null) {
            navigate("/procurement/goods-issue");
            setSnackBarInfo({
              snackBarMessage: "This Bill was currently unavailable or removed",
            });
          } else {
            setInfo((prevState: any) => ({
              ...prevState,
              invoice_no: purchaseBill.invoice_no,
              from_dept: purchaseBill.from_dept,
              to_dept: purchaseBill.to_dept,
              date: formatTwelveHoursTime(purchaseBill.created_at),
              disabled: true,
            }));
            const data = billProducts.map((res: any) => ({
              id: res.id,
              product_name: res,
              row_id: id,
              mrp: res.mrp,
              ret_qty: res.return_qty,
              ret_strip_qty: Math.ceil(res.return_qty / res.pack_type),
              max_strip_qty: Math.ceil(
                (Number(res.ref_original_qty) -
                  Number(res.ref_return_qty) -
                  Number(res.ref_sale_qty) +
                  Number(res.ref_adj_qty) +
                  Number(res.return_qty)) /
                  res.pack_type
              ),
              max_qty:
                Number(res.ref_original_qty) -
                Number(res.ref_return_qty) -
                Number(res.ref_sale_qty) +
                Number(res.ref_adj_qty) +
                Number(res.return_qty),
              stock_qty:
                Number(res.ref_original_qty) -
                Number(res.ref_return_qty) -
                Number(res.ref_sale_qty) +
                Number(res.ref_adj_qty) +
                Number(res.return_qty),
              expiry_date: formattedDateWithSlash(res.expires_at),
              batch_no: `${res.batch_no}`,
              amount: res.amount,
              discount: `${res.discount || 0}%`,
              tax: `${res.taxname}-${res.tax_id}%`,
              discount_amount: 0,
              pack_type: res?.pack_type,
              ptr: Number(res?.p_rate / res?.pack_type).toFixed(2),
              tax_id: res.tax_id,
              tax_settings: res.tax_settings === 1 ? "exclusive" : "inclusive",
              product_id: res.product_id,
              ref_batch_id: res.ref_batch_id,
              product_mrp: res.mrp,
              ref_bill: res.ref_bill,
            }));
            const newError = data.map((ele: any) => ({
              qty: false,
            }));
            setBillProducts(data);
            calculateBillData(data);
            const newBatchIds = data.map(
              (ele: { ref_batch_id: number }) => ele.ref_batch_id
            );
            setBatchIdNo(newBatchIds);
            setFormError(newError);
            setIsPageLoading(false);
          }
        })
        .catch((err: any) => {
          setIsPageLoading(false);
          console.log(err);
        });
    }
  }, [id]);
  const handleCalculateTaxWithAmount = (product: any, sales_tax?: string) => {
    let num;
    if (sales_tax === "exclusive") {
      const tax_amount = product.amount * (product.tax_id / 100);
      num = Number(product.amount) + Number(tax_amount);
    } else {
      num = product.amount;
    }
    return num;
  };
  const handleCalculateAmount = (product: any, name: string) => {
    let num = 0;

    if (product && !isNaN(product.ret_qty) && !isNaN(product.ptr)) {
      const qty = Number(product.ret_qty);
      const ptr = Number(product.ptr);
      if (qty) {
        num = handleCalculateTaxWithAmount(
          { amount: qty * ptr, tax_id: product.tax_id },
          product.tax_settings
        );
      }
    }
    return num.toFixed(2);
  };
  const handleInputChange = useCallback((e: any, index: number) => {
    const { name, value } = e.target;

    let numericValue = value.replace(/\D/g, "");

    setCurrentIndex(index as any);

    setBillProducts((prevState: any) => {
      const newState = [...prevState];

      newState[index as number] = {
        ...newState[index as number],
        [name]: numericValue,
      };
      if (name === "ret_strip_qty") {
        newState[index as number].ret_qty = Math.ceil(
          Number(numericValue) * Number(newState[index as number].pack_type)
        );
      }
      if (name === "ret_qty") {
        newState[index as number].ret_strip_qty = Math.ceil(
          Number(numericValue) / Number(newState[index as number].pack_type)
        );
      }
      handleValidation(
        newState[index as number],
        newState[index as number],
        index
      );
      newState[index as number] = {
        ...newState[index as number],
        amount: handleCalculateAmount(newState[index as number], "amount"),
      };

      newState[index as number] = {
        ...newState[index as number],
        discount_amount:
          calculateDiscountAmount(
            newState[index as number]?.amount || 0,
            newState[index as number]?.product_name?.discount || 0,
            newState[index as number]?.product_name?.discount_type
          ) || 0,
      };
      calculateBillData(newState);
      return newState;
    });
  }, []);
  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };
  const handleInvoiceNoChange = () => {
    if (autoGeneratedSeqNo !== info.invoice_no && Boolean(!isManual.is_once)) {
      setSeqIsOne((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: info.invoice_no,
      }));
      setSequenceDialogBox(true);
      setIsManual((prevState) => ({
        ...prevState,
        is_manual: false,
        is_auto_generate: false,
        is_once: true,
      }));
    }
  };
  const handleSequence = () => {
    getDepartmentSequence("Goods Issue Return")
      .then((res: any) => {
        if (res?.data?.length > 0) {
          setInitialSequenceNumber(res?.data[0]?.sequence_no);
          if (!id) {
            setInfo((prevState: any) => ({
              ...prevState,
              invoice_no: res?.data[0]?.sequence_no,
            }));
          }
          setAutoGeneratedSeqNo(res?.data[0]?.sequence_no);
          setSequenceNo(res.data[0].sequence.id);
          const newSequenceData = res.data[0].sequence.sequence_preferences.map(
            (ele: {
              id: number;
              type: string;
              value: string;
              separator: string;
            }) => ({
              id: ele.id,
              type: ele.type,
              value: ele.value,
              separator: ele.separator,
            })
          );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual: res.data[0].sequence.is_manual === 1 ? true : false,
            is_auto_generate:
              res.data[0].sequence.is_manual !== 1 ? true : false,
          }));
          setSeqIsOne((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(res.data[0].sequence.is_manual === 1));
        }
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }
  }, [appConfiguration]);
  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value === "Select" ? "" : value,
      };
      return newState;
    });
  };
  function onCloseSequenceDialogBox() {
    setSequenceDialogBox(false);
  }

  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      const data = {
        seq_preference: [...sequenceData],
        is_manual: isManual.is_manual ? 1 : 0,
      };
      await updatedSequence(Number(sequenceNo), data)
        .then((res) => {
          if (res) {
            handleSequence();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        })
        .catch((err: any) => {
          dispatch(setSnackBarFailed({ snackBarMessage: err.message }));
        });
    }
  };
  const handleAutoCompleteChange = useCallback(
    (newValue: any, idx: number | string, name: string) => {
      if (newValue === null) {
        return false;
      }
      setBillProducts((prevState: any) => {
        let newState = [...prevState];
        if (newState.length > 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            mrp: handleCalculateAmount(
              {
                qty: 1,
                product_name: {
                  pack_type: newValue.pack_type,
                  mrp: newValue.mrp,
                },
              },
              "mrp"
            ),
            product_mrp: newValue.mrp,
            stock_qty: newValue.stock_qty,
            expiry_date: formattedDateWithSlash(newValue.expires_at),
            batch_no: `${newValue.batch_no}`,
            amount: 0,
            discount: newValue.discount || 0,
            discount_type: newValue.discount_type || "",
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
            discount_amount:
              calculateDiscountAmount(
                newValue?.amount,
                newValue?.discount || 0,
                newValue?.discount_type
              ) || 0,
            pack_type: newValue?.pack_type,
            ptr: Number(newValue?.p_rate / newValue?.pack_type).toFixed(2),
            tax_id: newValue.tax_id,
            tax_settings:
              newValue.tax_settings === 1 ? "exclusive" : "inclusive",
            bill_batch_id: newValue.id,
            ref_bill: newValue.bill_id,
            ref_batch_id: newValue.id,
            id: null,
          };
          if (id) {
            newState[idx as number].max_strip_qty =
              newValue.stock_qty / newValue.pack_type;
            newState[idx as number].max_qty = newValue.stock_qty;
          }
        }
        if (newState.length === 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            ref_bill: newValue.bill_id,
            expiry_date: formattedDateWithSlash(newValue.expires_at),
            mrp: handleCalculateAmount(
              {
                qty: 1,
                product_name: {
                  pack_type: newValue.pack_type,
                  mrp: newValue.mrp,
                },
              },
              "mrp"
            ),
            ref_batch_id: newValue.id,
            product_mrp: newValue.mrp,
            stock_qty: newValue.stock_qty,
            batch_no: `${newValue.batch_no}`,
            amount: 0,
            discount: newValue.discount || 0,
            discount_type: newValue.discount_type || "",
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
            discount_amount:
              calculateDiscountAmount(
                newValue?.amount,
                newValue?.discount || 0,
                newValue?.discount_type
              ) || 0,
            pack_type: newValue?.pack_type,
            ptr: Number(newValue?.p_rate / newValue?.pack_type).toFixed(2),
            tax_id: newValue.tax_id,
            tax_settings:
              newValue.tax_settings === 1 ? "exclusive" : "inclusive",
            bill_batch_id: newValue.id,
            id: null,
          };
          if (id) {
            newState[idx as number].max_strip_qty =
              newValue.stock_qty / newValue.pack_type;
            newState[idx as number].max_qty = newValue.stock_qty;
          }
        }
        let newBatchIds = [...batchIdNo];

        if (Boolean(newBatchIds[idx as number])) {
          newBatchIds[idx as number] = newValue?.id;
        } else {
          newBatchIds.push(newValue?.id);
        }
        setBatchIdNo(newBatchIds);
        return newState;
      });
    },
    [batchIdNo]
  );
  const handleAutoCompleteInputChange = useCallback(
    (newValue: any, idx: number, name: string) => {

      setBillProducts((prevState: any) => {
        const newState = [...prevState];
        if (newState.length > 0 && newState[idx]) {
          newState[idx][name] = newValue;
        }
        return newState;
      });
    },
    []
  );

  const getAllIssuerDepartmentList = async () => {
    const data = { active: 1, type: "issuer" };
    await getAllMasterDepartments(data)
      .then((res: any) => {
        const formattedValue = res.data?.result?.map(
          (ele: { dept_name: string; id: number; type: string }) => ({
            name: ele.dept_name,
            id: ele.id,
            type: ele.type,
          })
        );
        setIssuerList(formattedValue);
      })
      .catch((err: any) => console.log(err));
  };
  const getAllReceiverDepartmentList = async () => {
    const data = { active: 1, type: "receiver" };
    await getAllMasterDepartments(data)
      .then((res: any) => {
        const formattedValue = res.data?.result?.map(
          (ele: { dept_name: string; id: number; type: string }) => ({
            name: ele.dept_name,
            id: ele.id,
            type: ele.type,
          })
        );
        setReceiverList(formattedValue);
      })
      .catch((err: any) => console.log(err));
  };
  useEffect(() => {
    getAllReceiverDepartmentList();
    getAllIssuerDepartmentList();
    handleSequence();
  }, []);

  const handleClick = useCallback(() => {
    setBillProducts((prev: any) => [
      ...prev,
      {
        ...initialBillProduct,
        id: prev.length,
      },
    ]);
    setFormError((prev) => [...prev, { ret_qty: false }]);
  }, []);

  const handleDelete = () => {
    const { id, batchNo }: any = isDeleteDialogOpen?.selectedRow;
    setLoading(true);
    const newBillProducts = [...billProducts];
    newBillProducts.splice(id, 1);
    setBillProducts(newBillProducts);

    const newFormError = [...formError];
    newFormError.splice(id, 1);
    setFormError(newFormError);
    if (batchNo) {
      const newBatchIds = [...batchIdNo];
      const findBatchIndex = batchIdNo.findIndex(
        (ele) => ele === Number(batchNo)
      );
      newBatchIds.splice(findBatchIndex, 1);
      setBatchIdNo(newBatchIds);
    }
    calculateBillData(newBillProducts);
    setLoading(false);
    setIsDeleteDialogOpen({ isOpen: false, selectedRow: null });
  };

  const calculateBillData = (allProducts: any) => {
    const newBillAmount = calculateTotalAmount(allProducts);
    const newRoundedOff = calculateRoundedOff({ bill_amount: newBillAmount });
    const newNetAmount = calculateNetAmount({
      bill_amount: newBillAmount,
      rounded_off: newRoundedOff,
    });
    setSummary((prevState: any) => ({
      ...prevState,
      bill_amount: newBillAmount,
      rounded_off: newRoundedOff,
      net_amount: Number(newNetAmount).toFixed(2),
    }));
  };

  const updateRows = (currentIndex: number) => {
    const amount = calculateAmount(billProducts, currentIndex);
    const discountAmount = calculateDiscountAmount(billProducts, currentIndex);
    const taxAmount = calculateTaxAmount(billProducts, currentIndex);

    if (
      amount !== +billProducts[currentIndex]?.amount ||
      taxAmount !== billProducts[currentIndex]?.tax_amount ||
      discountAmount !== billProducts[currentIndex]?.discount_amount
    ) {
      setBillProducts((prev: any) =>
        prev.map((billProduct: any, i: number) => {
          return i === currentIndex
            ? {
                ...billProduct,
                amount: amount,
                discount_amount: discountAmount,
                tax_amount: taxAmount,
              }
            : billProduct;
        })
      );
    }
  };

  useEffect(() => {
    updateRows(currentIndex);
  }, []);
  // useEffect(() => {
  //   if (info.from_dept && info.to_dept && batchIdNo) {
  //     const data: any = {
  //       from_dept: info.from_dept,
  //       to_dept: info.to_dept,
  //       selected_batches: batchIdNo,
  //     };
  //     getGoodsReturnProducts(data)
  //       .then((res: any) => setProductRow(res.data))
  //       .catch((err: any) => console.log(err));
  //   }
  // }, [info.from_dept, info.to_dept, batchIdNo]);

  const validateForm = (details: any) => {
    let validate = true;
    if (!details.from_dept) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Issuer not to be empty!" })
      );
      validate = false;
    }

    if (!details.to_dept) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Receiver not to be empty!" })
      );
      validate = false;
    }
    if (Boolean(billProducts.find((ele: any) => !ele.product_name))) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please select product!" })
      );
      validate = false;
    }
    if (Boolean(billProducts.find((ele: any) => !ele.ret_qty))) {
      dispatch(setSnackBarFailed({ snackBarMessage: "Please Enter Ret Qty!" }));
      validate = false;
    }
    if (Boolean(formError.find((ele) => ele.ret_qty))) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please check Table Values!" })
      );
      validate = false;
    }
    return validate;
  };
  const handleSubmit = async () => {
    if (validateForm(info)) {
      setIsSubmitLoaded(true);
      const productDetails: any =
        billProducts.length > 0
          ? billProducts.map((obj: any) => {
              let newObj: any = {};
              const {
                product_id,
                pack_type,
                discount,
                expires_at,
                tax_id,
                tax_type,
                supplier_id,
                p_rate,
                issue_batch_id,
                batch_no,
                discount_type,
              } = obj.product_name;
              newObj = {
                product_id,
                ref_bill: obj.ref_bill,
                pack_type,
                ref_batch_id: obj.ref_batch_id,
                p_rate,
                supplier_id,
                issue_batch_id,
                batch_no,
                expires_at,
                qty: obj.ret_qty,
                mrp: obj.product_mrp,
                discount,
                discount_type,
                discount_amount: obj?.discount_amount,
                tax_id,
                tax_type,
                amount: obj.amount,
                id: obj.id,
              };

              return newObj;
            })
          : 0;
      const data = {
        from_dept: info.from_dept,
        to_dept: info.to_dept,
        invoice_no: info.invoice_no,
        invoice_no_modified: info.invoice_no === initialSequenceNumber ? 0 : 1,
        product: [...productDetails],
        bill_amt: summary.net_amount,
        disc_goods_val: summary.goods_value.toFixed(2),
        orig_goods_val: summary.goods_value.toFixed(2),
      };
      if (id) {
        await updateGoodsIssueReturnById(id, data as any)
          .then((res: any) => {
            navigate("/procurement/goods-issue");
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            setIsSubmitLoaded(false);
          })
          .catch((err: any) => {
            if (err?.response?.data?.errors?.data) {
              const { data, message } = err.response.data.errors;

              const newBillProducts = [...billProducts];
              newBillProducts[
                data.key
              ].batch_no = `${data.batch_no}#${data.current_stock}`;
              newBillProducts[data.key].stock_qty = data.current_stock;
              setBillProducts(newBillProducts);
              const newFormError = [...formError];
              newFormError[data.key]["ret_qty"] = true;
              setFormError(newFormError);

              dispatch(setSnackBarFailed({ snackBarMessage: message }));
              console.log(err);
            } else if (typeof err?.response?.data?.errors === "string") {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
            }
            setIsSubmitLoaded(false);
            console.log(err);
          });
      } else {
        await createGoodsIssueReturn(data as any)
          .then((res: any) => {
            navigate("/procurement/goods-issue");
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            setIsSubmitLoaded(false);
          })
          .catch((err: any) => {
            if (err?.response?.data?.errors?.data) {
              const { data, message } = err.response.data.errors;

              const newBillProducts = [...billProducts];
              newBillProducts[
                data.key
              ].batch_no = `${data.batch_no}#${data.current_stock}`;
              newBillProducts[data.key].stock_qty = data.current_stock;
              setBillProducts(newBillProducts);
              const newFormError = [...formError];
              newFormError[data.key]["ret_qty"] = true;
              setFormError(newFormError);

              dispatch(setSnackBarFailed({ snackBarMessage: message }));
              console.log(err);
            } else if (typeof err?.response?.data?.errors === "string") {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
            }
            setIsSubmitLoaded(false);
            console.log(err);
          });
      }
    }
  };
  return (
    <>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <Grid sx={{ width: "92vw" }}>
          <Info
            info={info}
            setInfo={setInfo}
            issuerList={issuerList}
            receiverList={receiverList}
            isManual={isManual}
            isManualAdded={isManualAdded}
            setIsManual={setIsManual}
            setSequenceDialogBox={setSequenceDialogBox}
            handleInvoiceNoChange={handleInvoiceNoChange}
            isEdit={!!id}
            customSequence={appConfiguration?.custom_sequence}
          />
          <Grid
            sx={{
              width: {
                xs: "92vw",
              },
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              overflowX: { md: "scroll", lg: "hidden" },
            }}
          >
            <TableContainer
              className="table-container"
              component={Paper}
              sx={{
                ...styles.tableContainerStyle,
              }}
            >
              <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {TableHeaderCells.map((cell, index) => {
                      return (
                        <TableCell
                          align={cell.alignment}
                          sx={cell.sx}
                          key={index}
                        >
                          {cell.TableCellName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    height: "auto",
                    width: {
                      xs: "400vw",
                      sm: "200vw",
                      md: "120vw",
                      lg: "90vw",
                    },
                  }}
                >
                  {billProducts?.map((row: any, index: number) => {
                    return (
                      <ProductRow
                        key={row.id}
                        index={index}
                        row={row}
                        styles={styles}
                        allRows={productRow}
                        formError={formError}
                        handleInputChange={handleInputChange}
                        handleDelete={handleDelete}
                        totalLength={billProducts.length}
                        handleAutoCompleteChange={handleAutoCompleteChange}
                        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                        isEdit={!!id}
                        info={info}
                        batchIdNo={batchIdNo}
                        handleAutoCompleteInputChange={
                          handleAutoCompleteInputChange
                        }
                      />
                    );
                  })}
                </TableBody>
              </Table>
              <Grid
                sx={{
                  width: {
                    xs: "400vw",
                    sm: "200vw",
                    md: "120vw",
                    lg: "90vw",
                  },
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "0px 0px 5px 5px",
                  border: 1,
                  borderColor: "var(--table-border)",
                  borderTop: 0,
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: {
                      xs: "78%",
                    },
                  }}
                >
                  <Button
                    variant="text"
                    buttonText="+ Add Product"
                    handleClick={handleClick}
                    sx={{
                      borderRadius: "0px",
                      width: "auto",
                      height: "auto",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: 0,
                      color: "primary.main",
                      "&:hover": {
                        backgroundColor: "initial",
                        color: "primary.main",
                        borderColor: "initial",
                      },
                      "&.Mui-focusVisible": {
                        border: 1,
                        borderColor: "primary.main",
                        borderRadius: "5px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </TableContainer>
          </Grid>
          <Summary
            summary={summary}
            setSummary={setSummary}
            handleSaveData={() => {}}
          />
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              m: "35px 0 45px 0",
            }}
          >
            <SaveButton
              handleClick={() => handleSubmit()}
              loading={isSubmitLoaded}
              sx={{ width: "100px", height: "40px", mr: "20px" }}
            />
            <CancelButton sx={{ width: "100px", height: "40px" }} />
          </Grid>
        </Grid>
      )}

      <SequenceWrapper
        open={sequenceDialogBox}
        title="Procurement Return"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
      <DeleteDialog
        open={isDeleteDialogOpen.isOpen}
        loading={loading}
        onClose={() =>
          setIsDeleteDialogOpen({ isOpen: false, selectedRow: null })
        }
        onConfirm={handleDelete}
      />
    </>
  );
});

export default BillProducts;
