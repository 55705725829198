import React from "react";
import { Grid } from "@mui/material";
import { SettingsIcon } from "../../../assets/icons";
import {
  Select,
  DatePickerWithTime,
  TextField,
} from "../../../components/basic";
import { purchaseBillInfoConst } from "../../../constants/displayText";

type InfoType = {
  dept_id: string | null | number;
  customer_id: string | null | number;
  invoice_no: string | null | number;
  address: string | null | number;
  mobile_no: string | null | number;
  date_created: string | null | number;
  invoice_no_modified: string | null | number;
  disabled: boolean;
};

type InfoProps = {
  info: InfoType;
  setInfo: any;
  departmentList: any;
  customerList: any;
  isGoodsIssue: any;
  setSequenceDialogBox: any;
  setIsManual: any;
  isManualAdded: any;
  isManual: any;
  customSequence?: boolean;
  handleConfirmSequenceNoChange: () => void;
  errors?: any;
};

const Info = React.memo(
  ({
    info,
    setInfo,
    departmentList,
    customerList,
    isGoodsIssue,
    setSequenceDialogBox,
    setIsManual,
    isManualAdded,
    isManual,
    customSequence,
    handleConfirmSequenceNoChange,
    errors,
  }: InfoProps) => {
    const { invoiceDate, billSequenceNo } = purchaseBillInfoConst;

    const handleInputChange = (e: any) => {
      const { name, value } = e.target;
      setInfo((prev: any) => ({
        ...prev,
        [name]: value,
      }));
      if (name === "customer_id") {
        const findCustomer = customerList.find(
          (ele: { id: number }) => ele.id === value
        );
        setInfo((prevState: any) => ({
          ...prevState,
          address: findCustomer?.address || "",
          mobile_no: findCustomer?.phone || "",
        }));
      }
    };

    const handleDateChange = (newValue: any) => {
      setInfo((prev: any) => ({
        ...prev,
        invoice_date: newValue.toString(),
      }));
    };

    const styles = {
      selectGrid: {
        width: "100%",
        height: "44px",
        boxShadow: "none",
        borderRadius: "5px",
        borderColor: "var(--primary-border-color)",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },

      textFieldStyle: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
          height: "44px",
          borderRadius: "5px",
          paddingLeft: "8px",
          boxShadow: "none",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
    };

    return (
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          m: "0px 0px 30px 0px",
          width: "90vw",
          minHeight: "100px",
          height: "auto",
          gap: 1,
        }}
      >
        <Grid item xs={12} sm={5.9} md={2.9} lg={1.9} xl={1.9}>
          <Select
            value={info.dept_id}
            onChange={(e) => handleInputChange(e)}
            placeholder={"Issuer"}
            options={departmentList}
            label={"Issuer"}
            name="dept_id"
            width=""
            formControlStyle={{ width: "100%" }}
            sx={{
              ...styles.selectGrid,
            }}
            disabled={info.disabled}
          />
        </Grid>
        <Grid item xs={12} sm={5.9} md={2.9} lg={1.9} xl={1.9}>
          <Select
            value={info.customer_id}
            onChange={handleInputChange}
            placeholder={"Select Customer"}
            options={customerList}
            label={"Customer"}
            name="customer_id"
            width=""
            formControlStyle={{ width: "100%" }}
            sx={{
              ...styles.selectGrid,
            }}
            disabled={info.disabled}
          />
        </Grid>
        <Grid item xs={12} sm={5.9} md={2.9} lg={1.9} xl={1.9}>
          <TextField
            value={info.mobile_no}
            label={"Mobile No"}
            name="mobile_no"
            disabled
            formControlStyle={{ width: "100%" }}
            sx={{
              ...styles.textFieldStyle,
              backgroundColor: "var(--disabled-background)",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5.9} md={2.9} lg={1.9} xl={1.9}>
          <TextField
            value={info.address}
            label={"Address"}
            name="address"
            disabled
            formControlStyle={{ width: "100%" }}
            sx={{
              ...styles.textFieldStyle,
              backgroundColor: "var(--disabled-background)",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5.9}
          md={2.9}
          lg={1.9}
          xl={1.9}
          sx={{
            "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container  >div >div .rmdp-input":
              {
                boxSizing: "border-box",
                width: "100%",
              },
          }}
        >
          <DatePickerWithTime
            value={info.date_created}
            label={invoiceDate}
            onChange={handleDateChange}
            borderColor="var(--primary-border-color)"
            style={{
              boxShadow: "none",
              height: "45px",
              borderRadius: "5px",
            }}
            formControlStyle={{ width: "100%" }}
            name="date_created"
          />
        </Grid>
        {customSequence ? (
          <Grid item xs={12} sm={5.9} md={2.9} lg={1.9} xl={1.9}>
            <TextField
              value={info.invoice_no}
              // helperText={errors?.invoice_no}
              label={billSequenceNo}
              onBlur={handleConfirmSequenceNoChange}
              onChange={handleInputChange}
              endPlaceholderIcon={
                <SettingsIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSequenceDialogBox(true);
                  }}
                />
              }
              name="invoice_no"
              formControlStyle={{ width: "100%" }}
              sx={{
                ...styles.textFieldStyle,
              }}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }
);

export default Info;
