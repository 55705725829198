import { Box, CircularProgress, Typography, debounce } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { DeleteIcon, ViewIcon, EditIcon, PlusIcon } from "../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import {
  DatePicker,
  Sort,
  TextField,
  InfiniteScroller,
} from "../../../components/basic";
import {
  ConfirmationDialog,
  DataTable,
  DialogWrapper,
} from "../../../components/shared";
import CustomFilter from "../../../components/shared/customFilter";
import AddAntenatal from "./Add";
import {
  addAntenatal,
  deleteAntenatalById,
  editAntenatal,
  getAllAntenatal,
  getAllCommons,
  getAllPatientsList,
  getAllSymptoms,
  getAntenatalById,
  getPatientById,
} from "../../../services/gynecologyService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setCommonVariable } from "../../../redux/slices/appconfiguration";
import MaternalHistory from "./Add/MaternalHistory";
import HistoryOfDisease from "./Add/HistoryOfDisease";
import { useNavigate } from "react-router-dom";
import {
  setGynecologyId,
  setLastFollowUpId,
  setLatestTrimesterWeek,
  setPatientId,
} from "../../../redux/slices/gynecology";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../utils/ValidationUtils";
import { genderValue, trueFalseValue } from "../../../constants/displayText";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import {
  calculateEddFormat,
  commonDateFormatter,
} from "../../../utils/DateTimeFormatUtils";
import { handleFilterCounts } from "../../../utils/GeneralUtils";
import PermissionUtils from "../../../utils/PermissionUtils";
import {
  setCurrentPage,
  setFilterDataValues,
  setHandleClearSearch,
  setSortField,
} from "../../../redux/slices/pagination";
type GridRowData = Record<string, unknown>;
type AntenatalInfoType = {
  patient_id: string | number | null;
  dofv: string | number | null | any;
  aadhar_no: string | number | null;
  patient_name?: string;
  hus_aadhar_no: string | number | null;
  hus_mobile_no: string | number | null;
  menstruation_cycle: string;
  cycle_duration: string;
  liquid_flow: string;
  obstetric: string | number | null;
  lmp: string | number | null | any;
  edd: string | number | null | any;
  first_pregnancy: string | number | null;
  rch_id: string | number | null;
  address: string;
  age: string | number | null;
  gender: string;
  pincode: string | number | null;
  area: string;
  district: string;
  state: string;
  blood_group: string;
  height: string | number | null;
  weight: string | number | null;
  g: string;
  p: string;
  l: string;
  a: string;
  surgical_history: string;
};
type MaternalHistoryTypes = {
  mode_of_delivery: string | number;
  complications: string | number;
  place_of_birth: string | number;
  city_name: string | number;
  age: string | number;
  gender: string | number;
  born_weight: string | number;
};
function GynecologyList() {
  const { can } = PermissionUtils();

  const [isFieldSort, setIsFieldSort] = useState(false);
  const [ascendingOrderClicked, setAscendingOrderClicked] = useState(false);
  const [descendingOrderClicked, setDescendingOrderClicked] = useState(false);
  const [isHistoryOfDiseaseOpen, setIsHistoryOfDiseaseOpen] = useState(false);
  const [patientsList, setPatientsList] = useState<any>([]);
  const [symptomsList, SetSymptomsList] = useState([]);
  const [antenatalList, setAntenatalList] = useState([]);
  const [isMaternalOpen, setIsMaternalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditPageLoading, setIsEditPageLoading] = useState(false);
  const [isAntenatalLoading, setIsAntenatalLoading] = useState(false);
  const [formError, setFormError] = useState({
    cycle_duration: "",
    dofv: "",
    edd: "",
    liquid_flow: "",
    lmp: "",
    menstruation_cycle: "",
    patient_id: "",
    rch_id: "",
  });
  const initialFilterData = {
    patient_id: "",
    trimester_week: "",
    last_visit_date: new Date(),
    search: "",
  };

  const [pageCount, setPageCount] = useState(0);
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);
  const [isClearFilter, setIsClearFilter] = useState(false);
  const patientsPagination: any = useRef(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [maternalHistory, setMaternalHistory] = useState<
    MaternalHistoryTypes[]
  >([]);

  const initialValue: AntenatalInfoType = {
    patient_id: "",
    dofv: "",
    aadhar_no: "",
    hus_aadhar_no: "",
    hus_mobile_no: "",
    menstruation_cycle: "",
    cycle_duration: "",
    liquid_flow: "",
    obstetric: "",
    lmp: "",
    edd: "",
    first_pregnancy: "",
    rch_id: "",
    address: "",
    age: "",
    gender: "",
    pincode: "",
    area: "",
    district: "",
    state: "",
    blood_group: "",
    height: "",
    weight: "",
    g: "",
    p: "",
    l: "",
    a: "",
    surgical_history: "",
  };
  const [antenatalInfo, setAntenatalInfo] =
    useState<AntenatalInfoType>(initialValue);
  const [historyOfDiseaseData, setHistoryOfDiseaseData] = useState<[] | any>(
    []
  );
  const [isAntenatalOpen, setIsAddAntenatalOpen] = useState(false);
  const [currentDisease, setCurrentDisease] = useState<any>(null);
  const [isEditPatient, setIsEditPatient] = useState(false);

  const { pageInfo, filterData, count, sortField } = useSelector(
    (state: RootState) => ({
      pageInfo: state?.pagination?.gynecologyList,
      filterData: state?.pagination?.gynecologyList?.filterData,
      count: state?.pagination?.gynecologyList?.count,
      sortField: state?.pagination?.gynecologyList?.sortedField,
    })
  );
  const { gynecologyId, patientId } = useSelector(
    (state: RootState) => state?.gynecology
  );

  const { commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [vacationToBeDeleted, setVacationToBeDeleted] = useState<any>(null);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);

  const searchPatientsPagination = useRef({ search: "", page: 1 });

  const personalInfoList = [
    {
      name: "patient_id",
      label: "Patient Name/UHID No",
      placeholder: "",
      value: isEditPatient
        ? antenatalInfo.patient_name
        : antenatalInfo.patient_id,
      type: isEditPatient ? "textField" : "select",
      disabled: Boolean(isEditPatient),
      isFullWidth: true,
      flex: "flex-start",
      helperText: formError.patient_id,
      error: Boolean(formError.patient_id),
    },
    {
      name: "dofv",
      label: "Date of First Visit",
      placeholder: "",
      value: antenatalInfo.dofv,
      type: "date",
      isFullWidth: true,
      flex: "flex-end",
      helperText: formError.dofv,
      error: Boolean(formError.dofv),
    },
    {
      name: "aadhar_no",
      label: "Patient Aadhar No",
      placeholder: "",
      value: antenatalInfo.aadhar_no,
      type: "textField",
      isFullWidth: false,
      flex: "flex-start",
    },
    {
      name: "hus_aadhar_no",
      label: "Husband Aadhar No",
      placeholder: "",
      value: antenatalInfo.hus_aadhar_no,
      type: "textField",
      isFullWidth: false,
      flex: "flex-start",
    },
    {
      name: "hus_mobile_no",
      label: "Husband Mobile No",
      placeholder: "",
      value: antenatalInfo.hus_mobile_no,
      type: "textField",
      isFullWidth: true,
      flex: "flex-end",
    },
    {
      name: "menstruation_cycle",
      label: "Menstruation Cycle",
      placeholder: "",
      value: antenatalInfo.menstruation_cycle,
      type: "select",
      isFullWidth: false,
      options: commonVariables?.menstruation_cycles,
      flex: "flex-start",
      helperText: formError.menstruation_cycle,
      error: Boolean(formError.menstruation_cycle),
    },
    {
      name: "cycle_duration",
      label: "Cycle Duration",
      placeholder: "",
      value: antenatalInfo.cycle_duration,
      type: "textField",
      isFullWidth: false,
      flex: "flex-start",
      helperText: formError.cycle_duration,
      error: Boolean(formError.cycle_duration),
    },
    {
      name: "obstetric",
      label: "Cycle Duration",
      isFullWidth: true,
      flex: "flex-end",
    },
    {
      name: "liquid_flow",
      label: "Liquid Flow",
      placeholder: "",
      value: antenatalInfo.liquid_flow,
      type: "select",
      isFullWidth: true,
      options: commonVariables?.liquid_flow,
      flex: "flex-start",
      helperText: formError.liquid_flow,
      error: Boolean(formError.liquid_flow),
    },

    {
      name: "lmp",
      label: "LMP",
      placeholder: "",
      value: antenatalInfo.lmp,
      type: "date",
      isFullWidth: false,
      flex: "flex-end",
      helperText: formError.lmp,
      error: Boolean(formError.lmp),
    },
    {
      name: "edd",
      label: "EDD",
      placeholder: "",
      value: antenatalInfo.edd,
      type: "date",
      isFullWidth: false,
      flex: "flex-end",
      helperText: formError.edd,
      error: Boolean(formError.edd),
    },
    {
      name: "first_pregnancy",
      label: "First Pregnancy ?",
      placeholder: "",
      value: antenatalInfo.first_pregnancy,
      type: "select",
      isFullWidth: true,
      options: trueFalseValue,
      flex: "flex-start",
    },
    {
      name: "rch_id",
      label: "RCH ID",
      placeholder: "",
      value: antenatalInfo.rch_id,
      type: "textField",
      isFullWidth: true,
      flex: "flex-end",
      helperText: formError.rch_id,
      error: Boolean(formError.rch_id),
    },
  ];
  const addressInfoList = [
    {
      name: "address",
      label: "Address",
      placeholder: "",
      value: antenatalInfo.address,
      type: "textField",
      isFullWidth: true,
      flex: "flex-start",
    },
    {
      name: "age",
      label: "Age",
      placeholder: "",
      value: antenatalInfo.age,
      type: "textField",
      isFullWidth: false,
      flex: "flex-end",
    },
    {
      name: "gender",
      label: "Gender",
      placeholder: "",
      value: antenatalInfo.gender,
      type: "select",
      isFullWidth: false,
      options: genderValue,
      flex: "flex-end",
    },
    {
      name: "pincode",
      label: "Pincode",
      placeholder: "",
      value: antenatalInfo.pincode,
      type: "textField",
      isFullWidth: false,
      flex: "flex-start",
    },
    {
      name: "area",
      label: "Area",
      placeholder: "",
      value: antenatalInfo.area,
      type: "textField",
      isFullWidth: false,
      flex: "flex-start",
    },
    {
      name: "district",
      label: "District",
      placeholder: "",
      value: antenatalInfo.district,
      type: "textField",
      isFullWidth: false,
      flex: "flex-end",
    },
    {
      name: "state",
      label: "State",
      placeholder: "",
      value: antenatalInfo.state,
      type: "textField",
      isFullWidth: false,
      flex: "flex-end",
    },
    {
      name: "blood_group",
      label: "Blood Group",
      placeholder: "",
      value: antenatalInfo.blood_group,
      type: "textField",
      isFullWidth: true,
      flex: "flex-start",
    },
    {
      name: "height",
      label: "Height",
      placeholder: "",
      value: antenatalInfo.height,
      type: "textField",
      isFullWidth: false,
      flex: "flex-end",
    },
    {
      name: "weight",
      label: "Weight",
      placeholder: "",
      value: antenatalInfo.weight,
      type: "textField",
      isFullWidth: false,
      flex: "flex-end",
    },
  ];
  const obstetricViewList = [
    {
      name: "g",
      placeholder: "",
      value: antenatalInfo.g,
      frontLetter: "G",
    },
    {
      name: "p",
      placeholder: "",
      value: antenatalInfo.p,
      frontLetter: "P",
    },
    {
      name: "l",
      placeholder: "",
      value: antenatalInfo.l,
      frontLetter: "L",
    },
    {
      name: "a",
      placeholder: "",
      value: antenatalInfo.a,
      frontLetter: "A",
    },
  ];

  const getPatientDetailsById = async (id?: number | string) => {
    await getPatientById(id || antenatalInfo.patient_id)
      .then((res: any) => {
        const patientDetail = res.data;
        setAntenatalInfo((prevState: any) => ({
          ...prevState,
          address: patientDetail.line_1 + patientDetail.line_2,
          age: patientDetail.age,
          gender: patientDetail.sex,
          area: patientDetail.area,
          district: patientDetail.district,
          state: patientDetail.state,
          pincode: patientDetail.pincode,
          blood_group: patientDetail.blood_group,
          patient_name: patientDetail.name,
          aadhar_no: patientDetail.aadhar_no,
        }));
      })
      .catch((err) => console.log(err));
  };
  const handleDateChange = useCallback((name: string, newValue: any) => {
    setAntenatalInfo((prevState: any) => {
      const newState = { ...prevState };
      newState[name] = newValue?.validatedValue[0];
      if (name === "lmp") {
        newState["edd"] = calculateEddFormat(newValue?.validatedValue[0]);
      }
      return newState;
    });
  }, []);

  const debouncedGetAllPatientsList = debounce(
    async (currentValue, prevOptions, callback) => {
      try {
        return await getAllPatientsList({
          search: currentValue,
          page:
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page
              : 1,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;
          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );
          setPatientsList(uniqueOptions);
          searchPatientsPagination.current.page =
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page + 1
              : 1;
          searchPatientsPagination.current.search = currentValue;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
      } catch (error) {
        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
      }
    },
    300
  );

  const getAllPatientsData = async (currentValue?: any, prevOptions?: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllPatientsList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page =
          patientsPagination.current === Math.ceil(prevOptions.length / 10)
            ? patientsPagination.current
            : Math.ceil(prevOptions.length / 10) + 1;
        return await getAllPatientsList({
          search: currentValue,
          page: page,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setPatientsList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }
          const currentValue = patientsPagination.current;
          patientsPagination.current = currentValue + 1;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;

          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };
  const getAllListOfVariables = async () => {
    await getAllCommons()
      .then((ele: any) => dispatch(setCommonVariable(ele.data)))
      .catch((error: any) => {
        console.error("An error occurred:", error);
      });
  };

  const getAllSymptomsList = async () => {
    await getAllSymptoms()
      .then((ele: any) => SetSymptomsList(ele.data))
      .catch((error: any) => {
        console.error("An error occurred:", error);
      });
  };
  const handleClearSearch = () => {
    dispatch(
      setHandleClearSearch({ key: "gynecologyList", value: "", name: "search" })
    );
  };
  const debouncedGetAllAntenatal = useCallback(
    debounce((data: any) => {
      setIsLoading(true);
      getAllAntenatal({
        page: data.page,
        limit: data.pageSize,
        column: data.field,
        order: data.order,
        patient_id: data.patient_id,
        trimester_week: data.trimester_week,
        last_visit_date: data.last_visit_date,
        search: data.search,
      })
        .then((res: any) => {
          setAntenatalList(res.data.result);
          setPageCount(res.data.total as any);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("error getAntenatalList", err);
        });
    }, 300),
    []
  );
  const handleRemoveDisease = (relationship: number | string) => {
    const diseaseIndex = historyOfDiseaseData.findIndex(
      (ele: { relationship: string }) => ele.relationship === relationship
    );
    const prevDisease = [...historyOfDiseaseData];
    prevDisease.splice(diseaseIndex, 1);
    setHistoryOfDiseaseData(prevDisease);
  };
  const handleSubmitAntenatal = async () => {
    if (
      antenatalInfo?.dofv === "" ||
      antenatalInfo.menstruation_cycle === "" ||
      antenatalInfo.cycle_duration === "" ||
      antenatalInfo.liquid_flow === "" ||
      antenatalInfo.lmp === "" ||
      antenatalInfo.edd === "" ||
      antenatalInfo.rch_id === "" ||
      antenatalInfo.patient_id === ""
    ) {
      validateForm();
    } else {
      const data = {
        patient_id: antenatalInfo?.patient_id,
        date_of_first_visit: antenatalInfo?.dofv
          ?.split("/")
          .reverse()
          .join("-"),
        menstruation_cycle: antenatalInfo?.menstruation_cycle,
        cycle_duration: antenatalInfo?.cycle_duration,
        liquid_flow: antenatalInfo?.liquid_flow,
        obstetric: [
          {
            g: antenatalInfo?.g,
            l: antenatalInfo?.l,
            p: antenatalInfo?.p,
            a: antenatalInfo?.a,
          },
        ],
        lmp: antenatalInfo?.lmp.split("/").reverse().join("-"),
        edd: antenatalInfo?.edd.split("/").reverse().join("-"),
        first_pregnancy: Number(antenatalInfo?.first_pregnancy) === 2 ? 0 : 1,
        rch_id: antenatalInfo?.rch_id,
        surgical_history: antenatalInfo?.surgical_history,
        maternal_history: maternalHistory ? [...maternalHistory] : [],
        history_of_disease: historyOfDiseaseData
          ? [...historyOfDiseaseData]
          : [],
      };
      if (gynecologyId) {
        setIsAntenatalLoading(true);
        await editAntenatal(gynecologyId as number, data)
          .then(async (res) => {
            if (res) {
              onCloseAntenatal();
              setIsAntenatalLoading(false);
              dispatch(
                setSnackBarSuccess({ snackBarMessage: "Updated SuccessFully!" })
              );
              await debouncedGetAllAntenatal({
                page: pageInfo.page,
                limit: pageInfo.pageSize,
              });
            }
          })
          .catch((error) => {
            setIsAntenatalLoading(false);
            dispatch(
              setSnackBarFailed({
                snackBarMessage:
                  error?.response?.data?.error_data?.patient_id?.length > 0
                    ? error?.response?.data?.error_data?.patient_id[0]
                    : error?.response?.data?.errors,
              })
            );
          });
      } else {
        setIsAntenatalLoading(true);
        await addAntenatal(data)
          .then(async (res) => {
            if (res) {
              onCloseAntenatal();
              setIsAntenatalLoading(false);
              dispatch(
                setSnackBarSuccess({ snackBarMessage: "Added SuccessFully!" })
              );
              await debouncedGetAllAntenatal({
                page: pageInfo.page,
                limit: pageInfo.pageSize,
              });
            }
          })
          .catch((error) => {
            setIsAntenatalLoading(false);
            dispatch(
              setSnackBarFailed({
                snackBarMessage:
                  error?.response?.data?.error_data?.patient_id?.length > 0
                    ? error?.response?.data?.error_data?.patient_id[0]
                    : error?.response?.data?.errors,
              })
            );
          });
      }
      setIsAntenatalLoading(false);
    }
  };

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;
    switch (name) {
      case "cycle_duration": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      case "dofv": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      case "edd": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      case "liquid_flow": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      case "lmp": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      case "menstruation_cycle": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      case "patient_id": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      case "rch_id": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      default:
        break;
    }
  };
  const validateForm = () => {
    for (const fieldName in personalInfoList) {
      if (fieldName) {
        handleValidation({
          target: (personalInfoList as any)[Number(fieldName)],
        });
      }
    }
  };
  const onCloseAntenatal = () => {
    setIsAddAntenatalOpen(!isAntenatalOpen);
    setFormError({
      cycle_duration: "",
      dofv: "",
      edd: "",
      liquid_flow: "",
      lmp: "",
      menstruation_cycle: "",
      patient_id: "",
      rch_id: "",
    });
    patientsPagination.current = 1;
    setAntenatalInfo(initialValue);
    setHistoryOfDiseaseData([]);
    dispatch(setGynecologyId(null));
    setCurrentDisease(null);
    setIsEditPatient(false);
  };
  const handlePregnancyValue = () => {
    setIsMaternalOpen(true);
  };

  const handleAddAntenatalChange = useCallback((e: any, index?: any) => {
    const { name, value } = e.target;
    setAntenatalInfo((prevState: any) => {
      const newState = { ...prevState };
      newState[name] = value;
      return newState;
    });

    if (name === "first_pregnancy" && value === 2) {
      handlePregnancyValue();
    }
  }, []);
  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortField?.field === field) {
      dispatch(
        setSortField({
          key: "gynecologyList",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
    if (sortField?.field !== field) {
      dispatch(
        setSortField({
          key: "gynecologyList",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
  };

  const handleAddNewItem = () => {
    const newMaternalHistory = [
      ...maternalHistory,
      {
        mode_of_delivery: "",
        complications: "",
        place_of_birth: "",
        city_name: "",
        age: "",
        gender: "",
        born_weight: "",
      },
    ];
    setMaternalHistory(newMaternalHistory);
  };
  const handleMaternalHistoryInputChange = (e: any, idx: any) => {
    const { name, value } = e.target;
    const newState: any = [...maternalHistory];

    newState[idx][name] = value;
    setMaternalHistory(newState);
  };
  const handleDeleteRow = (idx: number) => {
    setMaternalHistory((prev: any) =>
      prev.filter((billProduct: any, index: any) => index !== idx)
    );
  };

  const deleteVacation = (row: any) => {
    setIsConfirmationDialogOpen(true);
    setVacationToBeDeleted(row.id);
  };

  const handleDeleteAntenatal = async () => {
    if (vacationToBeDeleted === null) {
      return false;
    }
    setDeleteBtnLoader(true);
    await deleteAntenatalById(vacationToBeDeleted)
      .then(async (res) => {
        if (res) {
          setVacationToBeDeleted(null);
          setIsConfirmationDialogOpen(false);
          dispatch(
            setSnackBarSuccess({ snackBarMessage: "Delete SuccessFully!" })
          );
          setDeleteBtnLoader(false);

          await debouncedGetAllAntenatal({
            page: pageInfo.page,
            limit: pageInfo.pageSize,
          });
        }
      })
      .catch((err) => {
        setVacationToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        dispatch(
          setSnackBarFailed({ snackBarMessage: err.data.response.message })
        );
        setDeleteBtnLoader(false);

        console.log(err);
      });
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        {can("gynecology_edit") && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(setGynecologyId(row.id));
              dispatch(setPatientId(row.patient_id));
              setIsAddAntenatalOpen(true);
            }}
          >
            <EditIcon />
          </div>
        )}
        {can("gynecology_view") && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(setGynecologyId(row.id));
              dispatch(setPatientId(row.patient_id));
              dispatch(setLatestTrimesterWeek(row.trimester_week));
              dispatch(setLastFollowUpId(row.last_followup_id));
              navigate(`/gynecology/view/${row.id}`);
            }}
          >
            <ViewIcon />
          </div>
        )}
        {can("gynecology_delete") && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => deleteVacation(row)}
          >
            <DeleteIcon />
          </div>
        )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("id")}
        >
          <Typography variant="h5" fontSize={14}>
            Patient’s UHID
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "id" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "id" && sortField?.order === "DESC"
                ? true
                : false
            }
            ascendingOnClick={() =>
              setAscendingOrderClicked(!descendingOrderClicked)
            }
            descendingOnClick={() =>
              setDescendingOrderClicked(!ascendingOrderClicked)
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.uhid}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Patient’s Name
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "mobile",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("mobile")}
        >
          <Typography variant="h5" fontSize={14}>
            Mobile No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "mobile" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "mobile" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.mobile}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "date_of_visit",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("date_of_visit")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "date_of_visit" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "date_of_visit" &&
              sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.date_of_visit}
        </Typography>
      ),
      minWidth: 160,
      sortable: false,
    },
    {
      field: "trimester_week",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("trimester_week")}
        >
          <Typography variant="h5" fontSize={14}>
            Trimester Week
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "trimester_week" &&
              sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "trimester_week" &&
              sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.trimester_week}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    ...(can("gynecology_edit") ||
    can("gynecology_view") ||
    can("gynecology_delete")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const clearFilters = () => {
    if (
      filterData.patient_id ||
      filterData.last_visit_date ||
      filterData.trimester_week
    ) {
      dispatch(
        setFilterDataValues({
          key: "gynecologyList",
          name: "clearFilter",
          value: initialFilterData,
        })
      );
      setIsClearFilter(true);
    }
  };
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "patient_id" && value) {
      const finalValue = patientsList.filter((obj: any) => {
        if (obj.value === value) {
          return obj;
        }
      });
      dispatch(
        setFilterDataValues({
          key: "gynecologyList",
          name: name,
          value: finalValue[0],
        })
      );
      dispatch(setCurrentPage({ key: "gynecologyList", value: 0 }));

      return false;
    }

    dispatch(
      setFilterDataValues({ key: "gynecologyList", name: name, value: value })
    );
    dispatch(setCurrentPage({ key: "gynecologyList", value: 0 }));
  };
  const handleFilterDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      dispatch(
        setFilterDataValues({
          key: "gynecologyList",
          name: name,
          value: newValue.validatedValue[0],
        })
      );
      dispatch(setCurrentPage({ key: "gynecologyList", value: 0 }));
    }
  };
  const gynecologyFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <InfiniteScroller
          label="Select UHID"
          handleOnChange={handleInputChange}
          name="patient_id"
          value={filterData.patient_id?.value}
          loadOptions={getAllPatientsData}
          options={patientsList}
          isClearFilter={isClearFilter}
          setIsClearFilter={setIsClearFilter}
          width={"100%"}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData.trimester_week}
          placeholder={"Trimester week"}
          label={"Trimester week"}
          formControlStyle={{ width: "100%" }}
          onChange={handleInputChange}
          name={"trimester_week"}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <DatePicker
          label="From Date"
          name="last_visit_date"
          placeholder="Select Date"
          width="95%"
          onChange={(e: any, newValue: any) =>
            handleFilterDateChange(e, newValue, "last_visit_date")
          }
          formControlStyle={{
            width: "90%",
            "& .icon-tabler": {
              left: "95%",
              color: "rgba(0, 0, 0, 0.54)",
            },
          }}
          format="DD/MM/YYYY"
          value={filterData.last_visit_date}
        />
      ),
    },
  ];
  useEffect(() => {
    if (antenatalInfo.patient_id) {
      getPatientDetailsById();
    }
    if (patientId && gynecologyId !== null) {
      setIsEditPatient(true);
      setIsEditPageLoading(true);
      getAntenatalById(gynecologyId as string)
        .then((res: any) => {
          getPatientDetailsById(patientId as string);
          const gynecologyDetails: any = res.data;
          setAntenatalInfo((prevState: AntenatalInfoType) => ({
            ...prevState,
            patient_id: gynecologyDetails?.patient_id,
            dofv: gynecologyDetails?.date_of_first_visit
              .split("-")
              .reverse()
              .join("/"),
            aadhar_no: gynecologyDetails?.aadhar_no,
            hus_aadhar_no: gynecologyDetails?.hus_aadhar_no,
            hus_mobile_no: gynecologyDetails?.hus_mobile_no,
            menstruation_cycle: gynecologyDetails?.menstruation_cycle,
            cycle_duration: gynecologyDetails?.cycle_duration,
            liquid_flow: gynecologyDetails?.liquid_flow,
            lmp: gynecologyDetails?.lmp.split("-").reverse().join("/"),
            edd: gynecologyDetails?.edd.split("-").reverse().join("/"),
            address: gynecologyDetails?.address,
            gender: gynecologyDetails?.gender,
            first_pregnancy: gynecologyDetails?.first_pregnancy,
            rch_id: gynecologyDetails?.rch_id,
            area: gynecologyDetails?.area,
            district: gynecologyDetails?.district,
            state: gynecologyDetails?.state,
            age: gynecologyDetails?.age,
            blood_group: gynecologyDetails?.blood_group,
            pincode: gynecologyDetails?.pincode,
            height: gynecologyDetails?.height,
            weight: gynecologyDetails?.weight,
            g: gynecologyDetails?.obstetric[0]?.g,
            p: gynecologyDetails?.obstetric[0]?.p,
            l: gynecologyDetails?.obstetric[0]?.l,
            a: gynecologyDetails?.obstetric[0]?.e,
            surgical_history: gynecologyDetails?.surgical_history,
          }));
          setHistoryOfDiseaseData(gynecologyDetails?.history_of_disease);
          setMaternalHistory(gynecologyDetails?.maternal_history);
          setIsEditPageLoading(false);
        })
        .catch((err) => {
          setIsEditPageLoading(false);
          console.log(err);
        });
      setIsEditPageLoading(false);
    }
  }, [antenatalInfo.patient_id, patientId, gynecologyId]);
  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo.pageSize,
      field: sortField?.field,
      order: sortField?.order,
      patient_id: filterData.patient_id?.value,
      trimester_week: filterData.trimester_week,
      last_visit_date: commonDateFormatter(
        filterData.last_visit_date,
        "YYYY-MM-DD"
      ),
      search: filterData.search,
    };
    debouncedGetAllAntenatal(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortField?.order,
    sortField?.field,
    filterData.last_visit_date,
    filterData.trimester_week,
    filterData.patient_id,
    filterData.search,
  ]);
  useEffect(() => {
    getAllListOfVariables();
    getAllSymptomsList();
    if (filterData.patient_id) {
      setPatientsList([filterData.patient_id]);
    }
  }, []);
  const handleDiseaseSubmit = (data: [] | any) => {
    setHistoryOfDiseaseData(data);
    setIsHistoryOfDiseaseOpen(!isHistoryOfDiseaseOpen);
  };
  const onClose = () => {
    setIsConfirmationDialogOpen(false);
  };
  return (
    <Box>
      <CustomFilter
        data={gynecologyFilter}
        isFilterEnabled={true}
        isSearchEnabled={true}
        clearFilter={clearFilters}
        searchOnChange={handleInputChange}
        searchName={"search"}
        appliedFilterCount={count}
        clearSearch={handleClearSearch}
        searchValue={filterData.search}
        {...(can("gynecology_add") && {
          editButtonTitle: "Add",
          onUpdateButtonClick: () => setIsAddAntenatalOpen(true),
          editStartIcon: (
            <Box
              sx={{
                "& path": {
                  fill: "#fff",
                },
              }}
            >
              <PlusIcon width={"12px"} height={"12px"} />
            </Box>
          ),
        })}
      />
      <DataTable
        loading={isLoading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={antenatalList}
        pageCount={pageCount}
        currentPage={"appointmentList"}
      />

      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="are you surely want to delete?"
        handleClick={handleDeleteAntenatal}
        onClose={onClose}
        loading={deleteBtnLoader}
      />
      <DialogWrapper
        open={isAntenatalOpen}
        title={"Antenatal Card"}
        confirmText="Save"
        cancelText="Cancel"
        loading={isAntenatalLoading}
        handleClick={() => handleSubmitAntenatal()}
        onClose={() => onCloseAntenatal()}
      >
        {isEditPageLoading ? (
          <div
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              height: "50vh",
              background: "transparent",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <AddAntenatal
            personalInfoList={personalInfoList}
            addressInfoList={addressInfoList}
            obstetricViewList={obstetricViewList}
            handleInputChange={handleAddAntenatalChange}
            getAllPatients={getAllPatientsData}
            setIsHistoryOfDiseaseOpen={setIsHistoryOfDiseaseOpen}
            historyOfDiseaseData={historyOfDiseaseData}
            symptomsList={symptomsList}
            handleDateChange={handleDateChange}
            handleRemoveDisease={handleRemoveDisease}
            isEditPatient={isEditPatient}
            antenatalInfo={antenatalInfo}
          />
        )}
      </DialogWrapper>
      <DialogWrapper
        open={isMaternalOpen}
        title={"Maternal History"}
        confirmText="Save"
        cancelText="Cancel"
        handleClick={() => {
          setIsMaternalOpen(!isMaternalOpen);
        }}
        onClose={() => setIsMaternalOpen(!isMaternalOpen)}
      >
        <MaternalHistory
          maternalHistory={maternalHistory}
          handleAddNewItem={handleAddNewItem}
          handleMaternalHistoryInputChange={handleMaternalHistoryInputChange}
          handleDeleteRow={handleDeleteRow}
          modeOfDelivery={commonVariables?.mode_of_delivery}
        />
      </DialogWrapper>

      <HistoryOfDisease
        symptomsList={symptomsList}
        handleSubmit={handleDiseaseSubmit}
        currentHistoryOfDisease={historyOfDiseaseData}
        isHistoryOfDiseaseOpen={isHistoryOfDiseaseOpen}
        setIsHistoryOfDiseaseOpen={setIsHistoryOfDiseaseOpen}
      />
    </Box>
  );
}
export default GynecologyList;
