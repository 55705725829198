import React, { useState, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { AvatarPic } from "../../../assets/images";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import { logout } from "../../../services/authService";
import { setSnackBarSuccess } from "../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { loginPageConst } from "../../../constants/displayText";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "../../../constants/routes";

export default function BadgeAvatars() {
  const { loginUrl, dashboardUrl } = RouteUrls;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {

    logout();
    navigate(loginUrl);

    dispatch(
      setSnackBarSuccess({
        snackBarMessage: loginPageConst?.LOGGED_OUT_SUCCESSFULLY,
      })
    );
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Tooltip title="">
          <IconButton
            ref={anchorRef}
            id="composition-button"
            disableRipple={true}
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <Avatar sx={{ width: 32, height: 32 }} src={AvatarPic}>
              {/* <AvatarIcon /> */}
            </Avatar>
          </IconButton>
        </Tooltip>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleToggle}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem>
                      <Typography>Profile</Typography>
                    </MenuItem>
                    <MenuItem>
                      <Typography>Reset Password</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <Typography>Logout</Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
