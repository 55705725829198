import React from "react";
import { Grid } from "@mui/material";
import { SettingsIcon } from "../../../../assets/icons";
import { Select, TextField } from "../../../../components/basic";

import { goodsIssueInfoConst } from "../../../../constants/displayText";

type InfoProps = {
  info: {
    issuer: string;
    receiver: string;
    bill_no: number;
    barcode: string | number | null;
    disabled: boolean;
  };
  setInfo: React.Dispatch<any>;
  receiverList: [] | any;
  issuerList: [] | any;
  handleBarcodeOnChange?: any;
  setSequenceDialogBox?: any;
  setIsManual?: any;
  isManualAdded?: any;
  isManual?: any;
  handleInvoiceNoChange?: () => void;
  customSequence?: boolean;
  selectLoading?: boolean;
};

const Info = React.memo(
  ({
    info,
    setInfo,
    receiverList,
    issuerList,
    handleBarcodeOnChange,
    setSequenceDialogBox,
    setIsManual,
    isManualAdded,
    isManual,
    handleInvoiceNoChange,
    customSequence,
    selectLoading,
  }: InfoProps) => {
    const { issuer, receiver, invoiceNo, barcode } = goodsIssueInfoConst;

    const handleInputChange = (e: any) => {
      const { name, value } = e.target;
      if (name === "barcode") {
        handleBarcodeOnChange(value);
      }
      setInfo((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    };

    const styles = {
      selectGrid: {
        width: "100%",
        height: "44px",
        boxShadow: "none",
        borderRadius: "5px",
        borderColor: "var(--primary-border-color)",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },

      textFieldStyle: {
        width: "100%",

        "& .MuiOutlinedInput-root": {
          height: "44px",
          borderRadius: "5px",
          paddingLeft: "8px",
          boxShadow: "none",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
    };

    return (
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          m: "0px 0px 30px 0px",
          width: "90vw",
          minHeight: "100px",
          height: "auto",
          gap: 1,
        }}
      >
        <Grid item xs={12} sm={5.9} md={3} lg={2} xl={2}>
          <Select
            value={info.issuer}
            onChange={(e) => handleInputChange(e)}
            placeholder="Select issuer"
            options={issuerList}
            label={issuer}
            name="issuer"
            width={""}
            formControlStyle={{ width: "100%" }}
            sx={{
              ...styles.selectGrid,
            }}
            disabled={info.disabled}
            isLoading={selectLoading}
          />
        </Grid>
        <Grid item xs={12} sm={5.9} md={3} lg={2} xl={2}>
          <Select
            value={info.receiver}
            onChange={handleInputChange}
            placeholder="Select Receiver"
            options={receiverList}
            label={receiver}
            name="receiver"
            formControlStyle={{ width: "100%" }}
            width={""}
            sx={{
              ...styles.selectGrid,
            }}
            disabled={info.disabled}
            isLoading={selectLoading}
          />
        </Grid>
        {customSequence ? (
          <Grid item xs={12} sm={5.9} md={3} lg={2} xl={2}>
            <TextField
              value={info.bill_no}
              label={invoiceNo}
              onChange={handleInputChange}
              onBlur={handleInvoiceNoChange}
              endPlaceholderIcon={
                <SettingsIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSequenceDialogBox(true);
                  }}
                />
              }
              name="bill_no"
              sx={{
                ...styles.textFieldStyle,
              }}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={5.9} md={3} lg={2} xl={2}>
          <TextField
            value={info.barcode}
            label={barcode}
            onChange={handleInputChange}
            name="barcode"
            sx={{
              ...styles.textFieldStyle,
            }}
          />
        </Grid>
      </Grid>
    );
  }
);

export default Info;
