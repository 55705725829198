import { Outlet, Navigate } from "react-router-dom";

type PublicRoutesTypes = {
  isLoggedIn?: boolean;
};

const PublicRoutes = ({ isLoggedIn }: PublicRoutesTypes) => {

  const lastVisitedRoute = JSON.parse(
    localStorage.getItem("lastVisitedRoute") || "null"
  );

  let initialRoute = "/dashboard";

  if (lastVisitedRoute) {
    initialRoute = lastVisitedRoute;
  }
  return !isLoggedIn ? <Outlet /> : <Navigate to={initialRoute} />;
};

export default PublicRoutes;
