import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import {
  DatePickerLeftArrow,
  DatePickerRightArrow,
} from "../../../assets/icons";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import "./TimePicker.css";
import InputIcon from "react-multi-date-picker/components/input_icon";

type DatePickerWithTimeTypes = {
  width?: string;
  height?: string;
  name?: string; // New prop
  placeholder?: string;
  value?: any; // New prop
  onChange?: any;
  label?: string; // New prop
  style?: {};
  error?: boolean;
  format?: string;
  borderColor?: string;
  hideSeconds?: boolean;
  ref?: any;
  helperText?: string;
  onBlur?: any;
  borderBottom?: boolean;
};
function CustomArrowButton(
  direction: string,
  handleClick: any,
  disabled: boolean
) {
  return (
    <i
      onClick={handleClick}
      style={{
        width: "",
        padding: "0 10px",
        fontWeight: "bold",
        color: disabled ? "gray" : "blue",
      }}
      className={disabled ? "cursor-default" : "cursor-pointer"}
    >
      {direction === "right" ? (
        <DatePickerRightArrow style={{ cursor: "pointer" }} />
      ) : (
        <DatePickerLeftArrow style={{ cursor: "pointer" }} />
      )}
    </i>
  );
}
const ReactTimePicker = ({
  width,
  height = "40px",
  name,
  placeholder,
  label,
  value,
  onChange,
  style,
  error,
  format = "HH:mm:ss A",
  hideSeconds = false,
  borderColor = "var(--primary-border-color)",
  ref,
  helperText,
  onBlur,
  borderBottom,
}: DatePickerWithTimeTypes) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleClose = () => {
    setIsFocused(false);
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Tab") {
      handleClose();
    }
  };

  return (
    <Grid
      sx={{
        "& .timepicker .rmdp-wrapper": {
          border: "1px solid var( --grey-lighter)",
          display: isFocused ? "flex" : "none",
        },
      }}
    >
      <InputLabel
        sx={{
          fontSize: "var(--primary-label-font-size)",
          color: isFocused ? "primary.main" : "var(--primary-label-color)",
          marginBottom: "4px",
        }}
      >
        {label}
      </InputLabel>
      <FormControl className="timepicker">
        <DatePicker
          ref={ref}
          disableDayPicker
          format={format}
          placeholder={placeholder}
          plugins={[<TimePicker position="bottom" hideSeconds />]}
          monthYearSeparator=" "
          showOtherDays
          onOpenPickNewDate
          arrow={false}
          renderButton={CustomArrowButton}
          style={{
            padding: "3px 10px",
          }}
          render={
            <InputIcon
              onKeyDown={handleKeyDown}
              // placeholder={placeholder}
              style={{
                // boxShadow: "var(--input-boxShadow)",
                border: borderBottom
                  ? undefined
                  : isFocused
                  ? "2px solid var(--primary-main)"
                  : `1px solid ${borderColor}`,
                width: width,
                height: height,
                borderRadius: "var(--primary-border-radius)",
                padding: borderBottom
                  ? "2px 9px"
                  : isFocused
                  ? "2px 9px"
                  : "3px 10px",
                fontFamily: "Inter, sans-serif",
                fontSize: "var(--primary-input-text-font-size)",
                ...style,
              }}
            />
          }
          value={value}
          onChange={onChange}
          onOpen={() => {
            handleFocus();
          }}
          className="timepicker"
          onClose={handleClose}
        />
      </FormControl>
      {helperText && (
        <FormHelperText
          error={error}
          sx={{ ml: 2, minHeight: "20px", color: "#d32f2f" }}
        >
          {helperText}
        </FormHelperText>
      )}
    </Grid>
  );
};

export default ReactTimePicker;
