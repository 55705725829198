import React, { useRef, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box, InputAdornment, Grid, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styleText } from "../../../constants/displayText";
import { InputLabel } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "..";
type SelectProps = {
  value?: string | undefined | string[] | any;
  name?: string;
  error?: boolean;
  onBlur?: any;
  helperText?: string;
  onChange?: (event: SelectChangeEvent<string>) => void;
  placeholder?: string | any;
  placeholderIcon?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  endAdornmentIcon?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  options?: string[] | any | undefined;
  label?: string | undefined | React.ReactNode;
  width?: string | any;
  inputRef?: any;
  focused?: boolean;
  sx?: {};
  formControlStyle?: {};
  menuStyle?: {};
  isMultiSelect?: boolean;
  onFocus?: () => void;
  defaultValue?: string | undefined | string[] | any;
  disabled?: boolean;
  setScrollCount?: any;
  selectedType?: any;
  multiSelectSelectedType?: string;
  optionName?: string;
  handleAddClick?: () => void;
  addButtonText?: string;
  autoFocus?: boolean;
  isLoading?: boolean;
  isRemoveClearIcon?: boolean;
};

const MuiSelect = ({
  value,
  onChange,
  error,
  helperText,
  autoFocus,
  onBlur,
  placeholder,
  name,
  placeholderIcon,
  endAdornmentIcon,
  options,
  label,
  width,
  inputRef,
  focused,
  sx,
  formControlStyle,
  isMultiSelect = false,
  menuStyle,
  onFocus,
  defaultValue,
  disabled,
  setScrollCount,
  selectedType = "number",
  multiSelectSelectedType = "number",
  optionName = "name",
  addButtonText,
  handleAddClick,
  isLoading,
  isRemoveClearIcon,
}: SelectProps) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const selectRef: any = useRef();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: width
          ? (width as string | number)
          : selectRef?.current?.offsetWidth,
        ...menuStyle,
      },
    },
  };

  const styles = {
    formControl: {
      ...formControlStyle,
      mt: label ? "5px" : "0px",
      "& .Mui-error .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #d32f2f !important",
      },
    },
    select: {
      width: { width },
      pl: 1.8,
      // boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      borderRadius: "var(--primary-border-radius)",
      "& .MuiOutlinedInput-input": {
        padding: "12.15px 2px",
      },
      color: "textPrimary.main",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary.main",
      },
      "& .Mui-focused": {
        visibility: "hidden",
      },
      "& .MuiInputBase-root:has(.MuiSelect-iconOpen) .MuiInputBase-root.Mui-focused":
        {
          visibility: "visible",
        },
      "& .MuiTableCell-body:has(.Mui-focused)": {
        borderColor: "primary.main",
        borderWidth: "2px",
        p: "0px",
        height: "31px",
      },

      "&.Mui-disabled.MuiOutlinedInput-root": {
        borderColor: "greyScale.lighter",
        backgroundColor: "#F4F4F5",
        pl: "10px",
        borderRadius: "var(--primary-border-radius)",
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: "greyScale.lighter !important",
      },
    },
  };

  const [isFocused, setIsFocused] = useState(false);
  const [isHover, setIsHover] = useState(false); // Add hover state

  const [open, setOpen] = useState(false);
  const handleClear = () => {
    if (onChange) {
      onChange({ target: { name, value: null } } as any); // Simulate a change event with an empty value
    }
  };
  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {label && (
        <InputLabel
          focused={isFocused || focused}
          sx={{
            color: "var(--primary-label-color)",
            fontSize: "var(--primary-label-font-size)",
          }}
        >
          {label}
        </InputLabel>
      )}
      <FormControl
        onMouseEnter={() => setIsHover(true)} // Handle hover enter
        onMouseLeave={() => setIsHover(false)} // Handle hover leave
        sx={{ ...styles.formControl }}
      >
        <Select
          ref={selectRef}
          defaultValue={defaultValue}
          displayEmpty
          autoWidth
          autoFocus={autoFocus}
          open={isFocused}
          onClose={() => {
            setIsHover(false);
            setIsFocused(false);
            setOpen(false);
          }}
          onOpen={() => {
            setIsFocused(true);
            setOpen(true);
          }}
          onFocus={onFocus}
          value={value}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          IconComponent={ExpandMoreIcon}
          inputRef={inputRef}
          multiple={isMultiSelect}
          disabled={disabled}
          error={error}
          input={
            <OutlinedInput
              startAdornment={
                placeholderIcon && (
                  <InputAdornment position="start">
                    {placeholderIcon}
                  </InputAdornment>
                )
              }
              endAdornment={
                <InputAdornment position="start">
                  {endAdornmentIcon}
                  {/* Clear Icon */}
                  {value && !isRemoveClearIcon ? (
                    <IconButton
                      onClick={handleClear}
                      size="small"
                      sx={{
                        ml: 1,
                        color: "primary.textSecondary",
                        visibility: isHover ? "visible" : "hidden",
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ) : null}
                </InputAdornment>
              }
            />
          }
          renderValue={(selected: any) => {
            if (!selected && selected !== 0) {
              return (
                <Box
                  sx={{
                    color: "#8a8a8aba",
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "var(--primary-placeholder-font-size)",
                  }}
                >
                  {placeholder}
                </Box>
              );
            }

            const selectedOption = options?.find((option: any) => {
              return selectedType === "object"
                ? option?.name === selected.name
                : selectedType === "number"
                ? option?.id === selected
                : option[optionName] === selected;
            });

            if (isMultiSelect) {
              const selectOptions = options
                .filter((option: any) =>
                  selected?.includes(
                    multiSelectSelectedType === "number"
                      ? option.id
                      : option[optionName]
                  )
                )
                .map((option: any) => option[optionName])
                .join(", ");

              return (
                <Box
                  sx={{
                    color: "textPrimary.main",
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "var(--primary-input-text-font-size)",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {selectOptions}
                </Box>
              );
            }

            if (!selectedOption) {
              return (
                <Box
                  sx={{
                    color: "textPrimary.main",
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "var(--primary-placeholder-font-size)",
                  }}
                >
                  {placeholder}
                </Box>
              );
            }

            return (
              <Box
                sx={{
                  color: "textPrimary.main",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "var(--primary-input-text-font-size)",
                }}
              >
                {selectedOption[optionName]
                  ? selectedOption[optionName]
                  : selectedOption.value
                  ? selectedOption.value
                  : selectedOption.id}
              </Box>
            );
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            ...styles.select,
            ...sx,
          }}
        >
          {isLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginLeft: "12px",
                color: "#b3adad",
                fontSize: "14px",
              }}
            >
              Loading...
            </div>
          ) : (
            options?.map((option: any, index: number) => (
              <MenuItem
                key={index}
                value={
                  selectedType === "object"
                    ? option
                    : selectedType === "number"
                    ? option.id
                    : option[optionName]
                }
                disabled={option.disabled}
                sx={{
                  "&:hover:not(.Mui-focused)": {
                    backgroundColor: "#ededed", // Change to the desired color on hover
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#207DFF !important", // Change to the desired color on hover
                    color: "#fff !important",

                    "&:hover": {
                      color: "textPrimary.main",
                    },
                  },
                  "&:first-of-type": {
                    backgroundColor: "white",
                    color: "textPrimary.main",
                  },
                  borderRadius: "var(--primary-border-radius)",
                  p: "px 10px",
                  m: "0 5px",
                }}
              >
                {option[optionName] || option.value}
              </MenuItem>
            ))
          )}

          {addButtonText && (
            <Button
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                p: 1,
                borderTop: "1px solid #e0e0e0",
                color: "primary.main",
                cursor: "pointer",
              }}
              variant="text"
              buttonText={addButtonText}
              handleClick={() => {
                setIsFocused(false);
                if (handleAddClick) {
                  handleAddClick();
                }
              }}
            />
          )}
        </Select>
      </FormControl>
      {helperText !== undefined && (
        <FormHelperText
          error={error}
          sx={{ ml: 2, minHeight: "20px", fontSize: "11px", color: "#d32f2f" }}
        >
          {helperText}
        </FormHelperText>
      )}
    </Grid>
  );
};
export default MuiSelect;
