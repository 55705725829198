import { TextField, IconButton, Box, useMediaQuery } from "@mui/material";
import { SearchOutlinedIcon, CancelTextIcon } from "../../../assets/icons";
import { useRef } from "react";
type SearchBarTypes = {
  handleSearchClick?: () => void;
  searchName?: string;
  onChange?: any;
  sx?: any;
  value?: any;
  placeholder?: any;
};

const CustomSearchBar = ({
  handleSearchClick,
  onChange,
  searchName,
  value,
  placeholder,
  sx,
}: SearchBarTypes) => {
  const windowWidth = useRef(window.innerWidth);
  const isMediumScreen = useMediaQuery(
    "(min-width:251px) and (max-width:550px)"
  );
  const styles = {
    inputStyles: {
      // width: isMediumScreen ? `${windowWidth.current - 20}px` : "100%",
    },
  };
  return (
    <Box sx={{ ...sx }}>
      <TextField
        fullWidth
        id="standard-bare"
        variant="outlined"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        sx={{
          "& input": {
            height: "10px",
            padding: "17px 14px",
            transition: "height 3s ease-in-out !important",
            fontSize: "var(--primary-input-text-font-size)",
          },
          "& .cancel-icon": {
            "& path": {
              fill: "#a7a7a7",
            },
          },
          "& input::placeholder": {
            fontSize: "var(--primary-placeholder-font-size)", // Change the font size as needed
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: "var(--primary-border-radius)",
            borderColor: "var(--primary-border-color)",
          },
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: "var(--primary-border-radius)",
            borderColor: "var(--primary-border-color)",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "primary.main",
            },
          "& .Mui-focused": {
            "& .cancel-icon": {
              "& path": {
                fill: "var(--primary-main)",
              },
            },
          },
          ...styles.inputStyles,
        }}
        name={searchName}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <IconButton sx={{ padding: "0" }}>
              <SearchOutlinedIcon />
            </IconButton>
          ),
          ...(value && {
            endAdornment: (
              <IconButton
                onClick={handleSearchClick}
                className="cancel-icon"
                sx={{ padding: "0" }}
              >
                <CancelTextIcon
                  style={{
                    width: "13px",
                    height: "13px",
                  }}
                />
              </IconButton>
            ),
          }),
        }}
      />
    </Box>
  );
};

export default CustomSearchBar;
