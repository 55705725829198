import { useEffect, useState } from "react";
import { DatePicker } from "../../../../components/basic";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getBillCollection } from "../../../../services/billingService";
import { commonDateFormatter } from "../../../../utils/DateTimeFormatUtils";

const styles = {
  containerStyle: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    fontSize: "14px",
  },
  billCollectedStyle: {
    color: "#232323",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "normal",
    marginRight: "16px",
  },
  headerStyle: {
    border: "1px solid var(--primary-border-color) !important",
    background: "#F4F4F4",
  },
  headerTextStyle: {
    color: "#232323",
    fontFamily: "Inter",
    fontSize: "14px !important",
    fontWeight: 500,
    lineHeight: "normal",
  },
  bodyDataStyle: {
    color: "#232323",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "400 !important",
    lineHeight: "normal",
  },
  tableContainerStyle: {
    boxShadow: "none",
  },
  totalTextStyle: {
    color: "#232323",
    fontFamily: "Inter",
    fontSize: "14px !important",
    fontWeight: 600,
    lineHeight: "normal",
  },
};

export default function ListFooter() {
  const [billDate, setBillDate] = useState(new Date());
  const [data, setData] = useState({
    bill_collection_data: [],
    first_shift_amt_total: 0,
    second_shift_amt_total: 0,
    third_shift_amt_total: 0,
  });

  useEffect(() => {
    if (billDate) {
      getBillCollection(commonDateFormatter(billDate, "YYYY-MM-DD"))
        .then((res: any) => {
          if (res.data) {
            setData(res.data.result);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [billDate]);

  return (
    <div style={{ margin: "60px 10px 0px 1px" }}>
      <Box sx={{ ...styles.containerStyle }}>
        <p style={{ ...styles.billCollectedStyle }}>Bill Collected On:</p>
        <DatePicker
          name="billDate"
          width="100%"
          onChange={(e: any, newValue: any) =>
            setBillDate(newValue.validatedValue[0])
          }
          formControlStyle={{
            width: "90%",
            "& .icon-tabler": {
              left: "98%",
              color: "rgba(0, 0, 0, 0.54)",
            },
          }}
          format="DD/MM/YYYY"
          value={billDate}
          maxDate={commonDateFormatter(new Date(), "DD/MM/YYYY")}
        />
      </Box>

      <Box sx={{ marginTop: "12px" }}>
        <TableContainer
          component={Paper}
          sx={{ ...styles.tableContainerStyle }}
        >
          <Table
            sx={{
              minWidth: 650,
              "& th": { fontSize: "14px", fontWeight: "500" },
              border: "1px solid var(--primary-border-color)",
            }}
            aria-label="simple table"
          >
            <TableHead sx={styles.headerStyle}>
              <TableRow sx={styles.headerStyle}>
                <TableCell sx={styles.headerTextStyle}>User</TableCell>
                <TableCell align="center" sx={styles.headerTextStyle}>
                  12.00 midnight - 9.00 am
                </TableCell>
                <TableCell align="center" sx={styles.headerTextStyle}>
                  9.00 am - 9.00 pm
                </TableCell>
                <TableCell align="center" sx={styles.headerTextStyle}>
                  9.00 pm - 12.00 midnight
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& th": { fontSize: "13px !important", fontWeight: 500 },
              }}
            >
              {data.bill_collection_data &&
              data.bill_collection_data?.length > 0 ? (
                <>
                  {data.bill_collection_data.map((row: any) => (
                    <TableRow
                      key={row.user_name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell scope="row" sx={{ ...styles.bodyDataStyle }}>
                        {row.user_name}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ ...styles.bodyDataStyle }}
                      >
                        {row.first_shift_amt}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ ...styles.bodyDataStyle }}
                      >
                        {row.second_shift_amt}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ ...styles.bodyDataStyle }}
                      >
                        {row.third_shift_amt}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="left" sx={{ ...styles.bodyDataStyle }}>
                    No Transaction for this Date
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell align="left" sx={{ ...styles.totalTextStyle }}>
                  Total
                </TableCell>
                <TableCell align="center" sx={{ ...styles.totalTextStyle }}>
                  {data.first_shift_amt_total}
                </TableCell>
                <TableCell align="center" sx={{ ...styles.totalTextStyle }}>
                  {data.second_shift_amt_total}
                </TableCell>
                <TableCell align="center" sx={{ ...styles.totalTextStyle }}>
                  {data.third_shift_amt_total}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
