import React from "react";
import { Button, ButtonProps } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

interface CustomButtonProps extends ButtonProps {
  backgroundColor?: string;
  buttonText?: string;
  textColor?: string;
  fontSize?: string;
  fontWeight?: string | number;
  textTransform?: string | any;
  border?: string | any;
  handleClick?: () => void | any;
  sx?: {};
  width?: number | string;
  buttonSize?: "sm" | "md" | "lg" | "xl" | undefined;
  height?: number | string;
  loading?: any;
  startIcon?: any;
  endIcon?: any;
  disabled?: any;
  variant?: "text" | "outlined" | "contained";
  buttonType?: "primary" | "secondary";
  minWidth?: number | string;
}

const commonStyle = {
  sm: {
    minWidth: "88px",
    height: "32px",
    fontSize: "12px",
    padding: "6px 12px",
  },
  md: {
    minWidth: "90px",
    height: "40px",
    fontSize: "14px",
    padding: "10px 20px",
  },
  lg: {
    minWidth: "112px",
    height: "48px",
    fontSize: "16px",
    padding: "14px 28px",
  },
  xl: {
    minWidth: "120px",
    height: "56px",
    fontSize: "16px",
    padding: "18px 32px",
  },
};

const buttonStyle = {
  primary: {
    border: "none",
    background: "var(--primary-main)",
    color: "#fff",
  },
  secondary: {
    border: "1px solid var(--primary-main)",
    background: "transparent",
    color: "var(--primary-main)",
  },
};

const CustomButton: React.FC<CustomButtonProps> = ({
  backgroundColor,
  buttonText,
  textColor,
  fontSize,
  fontWeight,
  textTransform,
  border,
  sx,
  buttonSize = "md",
  height,
  onClick,
  loading,
  startIcon,
  endIcon,
  disabled,
  variant,
  width,
  buttonType = "primary",
  minWidth,
  ...rest
}) => {
  return (
    <LoadingButton
      onClick={onClick}
      loading={loading}
      loadingPosition={loading ? "start" : undefined}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant}
      disabled={disabled}
      sx={{
        fontSize: fontSize || commonStyle[buttonSize]?.fontSize,
        height: height || commonStyle[buttonSize]?.height,
        minWidth: minWidth || commonStyle[buttonSize]?.minWidth,
        padding: commonStyle[buttonSize]?.padding,
        textTransform: textTransform || "capitalize",
        backgroundColor: backgroundColor || buttonStyle[buttonType]?.background,
        border: border || buttonStyle[buttonType]?.border,
        color: textColor || buttonStyle[buttonType]?.color,
        fontWeight: fontWeight,
        width: width || "fit-content",
        borderRadius: "6px",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        ...sx,
      }}
    >
      {buttonText}
    </LoadingButton>
  );
};

export default CustomButton;
