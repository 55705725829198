import React, { useState, useEffect, useCallback } from "react";
import { Grid, Typography, Avatar, Box } from "@mui/material";
import { AppointmentsDetailsPng, ProfilePng } from "../../../../assets/images";
import DoctorsAdvice from "./DoctorsAdvice";
import PatientTracking from "./PatientTracking";
import AppointmentHistory from "./History";
import { useSelector } from "react-redux";
import {
  CancelIcon,
  RescheduleIcon,
  PlusCircleIcon,
  CheckMarkIcon,
  StarsIcon,
  ShapesOneIcon,
  ShapesTwoIcon,
  EditIcon,
  CheckMarkYellow,
  ShapesThreeIcon,
} from "../../../../assets/icons";
import {
  SaveButton,
  CancelButton,
  DatePicker,
  Select,
  PageLoader,
} from "../../../../components/basic";
import { Link } from "react-router-dom";
import {
  dateFormat,
  formattedDateWithSlash,
  timeFormat,
} from "../../../../utils/DateTimeFormatUtils";
import { cancelAppointmentDetails } from "../../../../services/appointmentService";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "../../../../constants/routes";
import { DialogWrapper } from "../../../../components/shared";
import { useDispatch } from "react-redux";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../../redux/slices/snackbar";
import { appointmentDetailsConst } from "../../../../constants/displayText";
import {
  setIsLoading,
  setIsPageLoading,
} from "../../../../redux/slices/loader";
import { appointmentInfo } from "../../../../services/appointmentService";
import { doctorsAvailableDaysDetails } from "../../../../services/appointmentService";
import { RootState } from "../../../../redux/store";
import PermissionUtils from "../../../../utils/PermissionUtils";

type PropsType = {
  appointmentsDetails: any;
  appointmentId: any;
  setAppointmentsDetails: any;
};

const Overview = ({
  appointmentsDetails,
  appointmentId,
  setAppointmentsDetails,
}: PropsType) => {
  const {
    appointmentUrl,
    reviewAppointment,
    rescheduleAppointment,
    billingUrl,
    createUrl,
  } = RouteUrls;

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { can } = PermissionUtils();

  const isTokenEnable = useSelector(
    (state: any) => state.appConfiguration.isTokenEnable
  );

  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );

  const [appointmentCancel, setAppointmentCancel] = useState(false);
  const [cancelReason, setCancelReason] = useState<any>("");
  const [appointmentCancelLoading, setAppointmentCancelLoading] =
    useState(false);

  const [availableDays, setAvailableDays] = useState<any>([]);
  const [appointId, setAppointId] = useState(appointmentId);
  const [arrivedDetails, setArrivedDetails] = useState(null);
  const [consultDetails, setConsultDetails] = useState(null);
  const [outDetails, setOutDetails] = useState(null);
  const reviewAppointmentId = appointmentId;

  const [editReview, setEditReview] = useState(false);

  const [initialTabValue, setInitialTabValue] = useState<number>(0);

  const [reviewDate, setReviewDate] = useState<any>(
    appointmentsDetails?.review_date
      ? dateFormat(appointmentsDetails?.review_date, "DD/MM/YYYY")
      : null
  );
  const [reviewSlot, setReviewSlot] = useState<any>(
    appointmentsDetails?.review_slot
  );

  const [doctorsName, setDoctorsName] = useState<any>([]);
  const [dateHelperText, setDateHelperText] = useState<any>("");

  const getAppointmentsDetails = async () => {
    if (appointmentId === undefined) {
      return false;
    }
    try {
      dispatch(setIsLoading(true));
      dispatch(setIsPageLoading(true));
      await appointmentInfo(appointmentId).then((result: any) => {
        let data = result?.data?.appointment;

        setAppointmentsDetails(data);
        if (result?.data?.review_date !== null) {
          setReviewDate(dateFormat(result?.data?.review_date, "DD/MM/YYYY"));
        }
        setReviewSlot(timeFormat(result?.data?.review_slot));
        setAppointId(data?.id);
        setArrivedDetails(data?.arrived);
        setConsultDetails(data?.consulting);
        setOutDetails(data?.out);
        dispatch(setIsLoading(false));
        dispatch(setIsPageLoading(false));
      });
    } catch (error) {
      console.error("An error occurred:", error);
      dispatch(setIsLoading(false));
      dispatch(setIsPageLoading(false));
    }
  };

  const cancelAppointment = async () => {
    if (cancelReason === "") {
      return false;
    }
    const data = {
      appoint_id: appointId,
      status: "2",
      reason: cancelReason,
    };
    try {
      setAppointmentCancelLoading(true);
      cancelAppointmentDetails(data).then((result: any) => {
        setCancelReason("");
        setAppointmentCancel(!appointmentCancel);
        getAppointmentsDetails();
        dispatch(
          setSnackBarSuccess({
            snackBarMessage:
              appointmentDetailsConst.APPOINTMENT_CANCELLED as string,
          })
        );
        setAppointmentCancelLoading(false);
      });
    } catch (error) {
      console.error("An error occurred:", error);
      dispatch(
        setSnackBarSuccess({
          snackBarMessage: "something went wrong",
        })
      );
      setAppointmentCancelLoading(false);
    }
  };

  function generateDateArray(days: any) {
    // Create an empty array to store the dates
    const dateArray = [];

    // Get today's date
    const today = new Date();

    for (let i = 0; i < days; i++) {
      // Create a new date object for each day
      const newDate = new Date(today);
      newDate.setDate(today.getDate() + i);

      // Format the date as YYYY-MM-DD
      const formattedDate = newDate.toISOString().split("T")[0];

      // Add the formatted date to the array
      dateArray.push(formattedDate);
    }

    return dateArray;
  }

  const getDoctorsAvailableDays = async () => {
    try {
      doctorsAvailableDaysDetails(appointmentsDetails?.doctor_id).then(
        (result: any) => {
          if (result?.data?.available_dates?.length > 0) {
            setAvailableDays(result?.data?.available_dates);
          } else {
            const dates = generateDateArray(appConfiguration?.booking_max_date);
            if (dates?.length > 0) {
              setAvailableDays(dates);
            }
          }
        }
      );
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    if (appointmentsDetails?.doctor_id) {
      getDoctorsAvailableDays();
    }
  }, [appointmentsDetails?.doctor_id]);

  const handleDateChange = useCallback((newValue: any) => {
    setReviewDate(newValue.toString());

    setDateHelperText("");
  }, []);

  const Reasons = [
    { id: "I am busy", name: "I am busy" },
    {
      id: "Forgot about the appointment",
      name: "Forgot about the appointment",
    },
    { id: "Visited another doctor", name: "Visited another doctor" },
    { id: "Change my mind", name: "Change my mind" },
    { id: "Clinic/Hospital is far", name: "Clinic/Hospital is far" },
    { id: "Doctor asked me to cancel", name: "Doctor asked me to cancel" },
    { id: "Others", name: "Others" },
  ];

  const appointmentDetails = [
    {
      title: "Doctor",
      value: appointmentsDetails?.doctor_name
        ? appointmentsDetails?.doctor_name
        : "-",
    },
    {
      title: "Purpose",
      value: appointmentsDetails?.purpose_name
        ? appointmentsDetails?.purpose_name
        : "-",
    },
    ...(isTokenEnable
      ? [
          {
            title: "Token No",
            value: appointmentsDetails?.token_no
              ? appointmentsDetails?.token_no
              : "-",
          },
        ]
      : []),
    ...(!isTokenEnable
      ? [
          {
            title: "Slot Time",
            value: appointmentsDetails?.slot_label
              ? appointmentsDetails?.slot_label
              : "-",
          },
        ]
      : []),
    {
      title: "Date",
      value: appointmentsDetails?.appoint_date
        ? formattedDateWithSlash(appointmentsDetails?.appoint_date)
        : "-",
    },
    {
      title: "OP No",
      value: appointmentsDetails?.op_no ? appointmentsDetails?.op_no : "-",
    },
  ];

  const patientDetails = [
    {
      title: "Name",
      value: appointmentsDetails?.patient_name
        ? appointmentsDetails?.patient_name
        : "-",
    },
    {
      title: "UHID",
      value: appointmentsDetails?.uhid ? appointmentsDetails?.uhid : "-",
    },
    {
      title: "Age / Gender",
      value: `${
        appointmentsDetails?.patient_age
          ? appointmentsDetails?.patient_age
          : "-"
      } / ${
        appointmentsDetails?.patient_sex
          ? appointmentsDetails?.patient_sex === 1
            ? "Male"
            : "Female"
          : "-"
      }`,
    },
    {
      title: "Mobile No",
      value: appointmentsDetails?.patient_mobile
        ? appointmentsDetails?.patient_mobile
        : "-",
    },
    {
      title: "Address",
      value: appointmentsDetails?.address ? appointmentsDetails?.address : "-",
    },
  ];

  const handleRescheduleAppointment = () => {
    if (reviewDate) {
      const queryParams = reviewDate ? `date=${reviewDate}` : "";
      navigate(
        `${appointmentUrl}${reviewAppointment}/${reviewAppointmentId}?${queryParams}`
      );
    } else {
      setDateHelperText("Date Is Required");
    }
  };

  const handleNavigatePatientBill = () => {
    const addressParts = [
      appointmentsDetails?.line_1 && appointmentsDetails?.line_1 !== "-"
        ? appointmentsDetails.line_1
        : "",
      appointmentsDetails?.line_2 && appointmentsDetails?.line_2 !== "-"
        ? appointmentsDetails.line_2
        : "",
      appointmentsDetails?.area && appointmentsDetails?.area !== "-"
        ? appointmentsDetails.area
        : "",
      appointmentsDetails?.district ? appointmentsDetails.district : "",
      appointmentsDetails?.state ? appointmentsDetails.state : "",
      appointmentsDetails?.pincode ? appointmentsDetails.pincode : "",
    ];

    const address = addressParts.filter((part) => part).join("\n");

    navigate(
      `${billingUrl}${createUrl}?patient_id=${appointmentsDetails?.patient_id}&patient_name=${appointmentsDetails?.uhid} - ${appointmentsDetails?.patient_name}&mobile=${appointmentsDetails.patient_mobile}&address=${address}&doctor_id=${appointmentsDetails?.doctor_id}&type=OP&op_id=${appointmentsDetails?.id}&op_no=${appointmentsDetails?.op_no}`
    );
  };

  return (
    <Grid
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: { xs: "column", md: "column", lg: "row" },
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <DialogWrapper
        open={appointmentCancel}
        title={"Cancel Appointment"}
        onClose={() => setAppointmentCancel(!appointmentCancel)}
        handleClick={cancelAppointment}
        confirmText="Yes"
        cancelText="No"
        buttonWidth="80px"
        buttonHeight="36px"
        maxWidth="sm"
        loading={appointmentCancelLoading}
      >
        <Grid>
          <Grid sx={{ display: "flex", gap: 1, mb: 3 }}>
            Are you sure you want to cancel this appointment ?
          </Grid>
          <Select
            label="Cancel Reason"
            placeholder="Select Reason"
            options={Reasons}
            value={cancelReason}
            onChange={(e: any) => {
              setCancelReason(e.target.value);
            }}
            sx={{ width: "300px" }}
          />
        </Grid>
      </DialogWrapper>
      <Grid
        container
        sx={{
          width: { xs: "98%", md: "98%", lg: "35%" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          borderRadius: "5px",
          boxShadow: "3px 5px 15px 0px rgba(35, 35, 35, 0.08)",
          border: 1,
          borderColor: "greyScale.lighter",
        }}
      >
        <Grid
          sx={{
            height: "50px",
            maxHeight: "50px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            p: "0px 20px",
            borderBottom: 1,
            borderColor: "greyScale.lighter",
            background:
              "linear-gradient(174.41deg, rgba(50, 202, 226, 0.05) 4.46%, rgba(50, 202, 226, 0.01) 67.95%)",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: "var(--primary-header-font-size)",
              fontWeight: "var(--primary-header-font-weight)",
              color: "primary.main",
              mr: "5px",
            }}
          >
            Patient Details
          </Typography>
          <StarsIcon />
        </Grid>
        <Grid
          item
          sx={{
            position: "relative",
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "center", md: "flex-start" },
            justifyContent: { xs: "space-around" },
          }}
        >
          <Grid
            sx={{
              position: "absolute",
              bottom: "10px",
              right: 0,
              display: {
                xs: "flex",
              },
              zIndex: "-10",
            }}
          >
            <ShapesOneIcon />
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
              },
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: { xs: "flex-start", md: "space-between" },
              alignItems: { xs: "flex-start", md: "center" },
              flexWrap: "wrap",
              p: "20px 20px 0px 20px",
            }}
          >
            {patientDetails.map((row, index) => {
              return (
                <Grid
                  key={index}
                  sx={{
                    width: {
                      xs: "100%",
                    },
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    maxHeight: "35px",
                    mb: "14px",
                    color: "textPrimary.main",
                  }}
                  className="row"
                >
                  <Grid
                    sx={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                      {row.title}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                      :
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      pl: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        // color: "greyScale.main",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {row.value}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          position: "relative",
          width: { xs: "98%", md: "98%", lg: "63%" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          boxShadow: "3px 5px 15px 0px rgba(35, 35, 35, 0.08)",
          borderRadius: "5px",
          border: 1,
          borderColor: "greyScale.lighter",
          mt: { xs: "20px", lg: "0px" },
        }}
      >
        <Grid
          sx={{
            position: "absolute",
            bottom: { xs: "20px", md: "20px" },
            right: "25%",
            display: {
              xs: "flex",
            },
            zIndex: "-10",
          }}
        >
          <ShapesThreeIcon />
        </Grid>
        <Grid
          sx={{
            position: "absolute",
            bottom: { xs: "40%", md: "20px" },
            left: { xs: "60%", md: "20%" },
            display: {
              xs: "flex",
            },
            zIndex: "-10",
          }}
        >
          <ShapesTwoIcon />
        </Grid>
        <Grid
          sx={{
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: "0px 20px",
            borderBottom: 1,
            borderColor: "greyScale.lighter",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h3"
              color="initial"
              sx={{
                fontSize: "var(--primary-header-font-size)",
                fontWeight: "var(--primary-header-font-weight)",
                color: "primary.main",
                mr: "5px",
              }}
            >
              Appointment Details
            </Typography>

            {appointmentsDetails?.status !== 2 && <CheckMarkIcon />}
            {appointmentsDetails?.status === 2 && <CancelIcon />}
          </Grid>
          {can("ip_edit") && appointmentsDetails?.status !== 2 && (
            <Box
              // to={`${billingUrl}${createUrl}`}
              style={{
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={handleNavigatePatientBill}
            >
              <SaveButton
                startIcon={<PlusCircleIcon />}
                buttonText="Add Bill"
                handleClick={() => {}}
                sx={{
                  width: "100%",
                  height: "40px",
                  backgroundColor: "rgba(40, 176, 139, 0.06)",
                  color: "secondary.main",
                  border: 0,
                  "&:hover": {
                    backgroundColor: "rgba(40, 176, 139, 0.06)",
                    color: "secondary.main",
                    border: 0,
                    boxShadow: "none",
                  },
                }}
              />
            </Box>
          )}
        </Grid>
        <Grid
          sx={{
            width: {
              xs: "100%",
            },
            display: "flex",
            flexDirection: { xs: "column", md: "row", lg: "row" },
            justifyContent: {
              xs: "flex-start",
              md: "space-between",
              lg: "space-between",
            },
            alignItems: {
              xs: "flex-start",
              md: "flex-end",
              lg: "flex-end",
            },
          }}
        >
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                md: "40%",
                lg: "40%",
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              p: "20px 10px 0px 20px",
            }}
          >
            {appointmentDetails.map((row: any, index: number) => {
              return (
                <Grid
                  key={index}
                  sx={{
                    width: {
                      xs: "100%",
                    },
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    color: "textPrimary.main",
                    mb: "14px",
                  }}
                  className="row"
                >
                  <Grid
                    sx={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                      {row.title}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                      :
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      pl: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "greyScale.main",
                      }}
                    >
                      {row.value ? row.value : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          {appointmentsDetails?.status !== 2 && (
            <>
              <Grid
                sx={{
                  width: {
                    xs: "100%",
                    md: "30%",
                    lg: "20%",
                    xl: "30%",
                  },
                  display: "flex",
                  flexDirection: {
                    xs: "row",
                    md: "row",
                    lg: "column",
                    xl: "row",
                  },
                  justifyContent: {
                    xs: "flex-start",
                    md: !isTokenEnable ? "space-around" : "flex-end",
                    lg: !isTokenEnable ? "center" : "flex-end",
                    xl: !isTokenEnable ? "space-around" : "flex-end",
                  },
                  alignItems: {
                    xs: "flex-start",
                    md: "flex-start",
                    lg: !isTokenEnable ? "space-around" : "flex-end",
                    xl: "flex-start",
                  },
                  p: "20px 10px",
                }}
              >
                <CancelButton
                  startIcon={<CancelIcon />}
                  handleClick={() => {
                    setAppointmentCancel(true);
                    setCancelReason("");
                  }}
                  sx={{
                    backgroundColor: "#FFF7F7",
                    color: "red",
                    border: 0,
                    mr: { xs: "10px", md: "0px", xl: 1 },
                    "&:hover": {
                      backgroundColor: "#FFF7F7",
                      color: "red",
                      border: 0,
                    },
                  }}
                />
                {!isTokenEnable && (
                  <SaveButton
                    startIcon={<RescheduleIcon />}
                    buttonText="Reschedule"
                    handleClick={() => {
                      navigate(
                        `${appointmentUrl}${rescheduleAppointment}/${appointmentId}`
                      );
                    }}
                    sx={{
                      width: "auto",
                      height: "40px",
                      backgroundColor: "rgba(32, 125, 255, 0.09)",
                      color: "primary.main",
                      border: 0,
                      mt: { xs: "0px", md: "0px", lg: "10px", xl: "0px" },
                      "&:hover": {
                        backgroundColor: "rgba(32, 125, 255, 0.09)",
                        color: "primary.main",
                        border: 0,
                        boxShadow: "none",
                      },
                    }}
                    buttonSize="sm"
                  />
                )}
              </Grid>
              {can("review_add") && (
                <Grid
                  sx={{
                    width: {
                      xs: "100%",
                      md: "30%",
                      lg: "40%",
                      xl: "30%",
                    },
                    height: "80%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: { xs: "flex-start", md: "flex-end" },
                    m: "20px 20px 20px 10px",
                    border: 0,
                    borderLeft: { xs: 0, md: 1 },
                    borderColor: "rgba(208, 208, 208, 1) !important",
                    "& .MuiFormControl-root": {
                      position: "static",
                    },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ color: "primary.main", mr: "5px" }}
                      >
                        Next Review Date
                      </Typography>
                      {appointmentsDetails?.review_date && <CheckMarkYellow />}
                    </Grid>
                    <DatePicker
                      label=""
                      placeholder="Select Date And Time"
                      width="168px"
                      focused={true}
                      enabledDates={availableDays}
                      onChange={handleDateChange}
                      value={reviewDate}
                      helperText={dateHelperText}
                      style={{ width: "200px" }}
                    />
                  </Grid>
                  <SaveButton
                    buttonText="Book Now"
                    disabled={appointmentsDetails?.review_date ? true : false}
                    handleClick={handleRescheduleAppointment}
                    sx={{
                      width: "auto",
                      height: "40px",
                      mt: "10px",
                    }}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        sx={{
          width: "100%",
          maxWidth: "100%",
          height: "auto",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
        }}
      >
        {/* developer comment */}
        {/* <DoctorsAdvice />
        <PatientTracking /> */}
        {appointmentsDetails?.status !== 2 && <AppointmentHistory />}
      </Grid>
    </Grid>
  );
};

export default Overview;
