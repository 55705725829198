import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  DeleteLightIcon,
  SettingsLightIcon,
  PrintingLightIcon,
  HistoryIcon,
  DeleteIcon,
  SettingsIcon,
} from "../../../../assets/icons";
import {
  CancelButton,
  Select,
  TextField,
  Button,
  SaveButton,
} from "../../../../components/basic";
import { ConfirmationDialog } from "../../../../components/shared";
import { appointmentDetailsConst } from "../../../../constants/displayText";
import InvestigationRow from "./InvestigationRow";
import "./index.css";
import InvestigationTemplateDialog from "./InvestigationTemplateDialog";
import { appointmentDetailsInvestigationsConst } from "../../../../constants/displayText";
import {
  getInvestigationList,
  getTestList,
  createInvestigationTest,
  updateInvestigationTest,
  deleteInvestigation,
  deleteInvestigationTest,
  getInvoiceNumber,
  updatedSequence,
} from "../../../../services/appointmentService";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

import { SequenceWrapper } from "../../../../components/shared";
import PermissionUtils from "../../../../utils/PermissionUtils";

type TableHeaderCell = {
  TableCellName: string;
  alignment: "left" | "right" | "center";
  sx?: {};
};

type PrescriptionsProps = {
  appointmentsDetails?: any;
};

const Investigations = ({ appointmentsDetails }: PrescriptionsProps) => {
  const {
    testName,
    subName,
    investigation,
    units,
    referenceRange,
    criticalValue,
    ADDED_SUCCESSFULLY,
    UPDATED_SUCCESSFULLY,
    DELETED_SUCCESSFULLY,
  } = appointmentDetailsInvestigationsConst;

  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        boxShadow: "none",
        overflow: "hidden",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "92vw",
        },
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "88vw",
        },
      },
    }),
    []
  );

  const TableHeaderCells: TableHeaderCell[] = [
    { TableCellName: testName, alignment: "left", sx: { width: "30vw" } },
    { TableCellName: subName, alignment: "left", sx: { width: "28vw" } },
    { TableCellName: units, alignment: "left", sx: { width: "28vw" } },
  ];

  const initialInvestigation = [
    {
      id: Date.now(), //investigationId
      billing_item: {
        id: null, //testId
        name: "",
        isTestNameTooltipOpen: false,
        lab_tests: [],
      },
    },
  ];

  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );

  const { can } = PermissionUtils();

  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [isDeleteButtonLoading, setIsDeleteButtonLoading] =
    useState<boolean>(false);
  const [isSaveButtonLoading, setIsSaveButtonLoading] =
    useState<boolean>(false);

  const dispatch = useDispatch();

  const [investigations, setInvestigations] =
    useState<any>(initialInvestigation);

  const [investigationId, setInvestigationId] = useState<any>(null);

  const [testOptions, setTestOptions] = useState<any>([]);

  const [isOpenInvestigationModal, setIsOpenInvestigationModal] =
    useState(false);

  const [initialInvoiceNumber, setInitialInvoiceNumber] = useState<
    string | number | undefined
  >("");
  const [invoiceNumber, setInvoiceNumber] = useState<
    string | number | undefined
  >("");

  const [open, setOpen] = useState<boolean>(false);

  const [isCreate, setIsCreate] = useState<boolean>(true);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] =
    useState<boolean>(false);

  const [isInvestigationTestDelete, setIsInvestigationTestDelete] =
    useState<boolean>(true);

  const [testToBeDeleted, setTestToBeDeleted] = useState<any>(null);

  const [callUseEffect, setCallUseEffect] = useState<boolean>(false);

  const [customSequence, setCustomSequence] = useState(true);

  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");

  const [isSequenceWrapperOpen, setIsSequenceWrapperOpen] = useState(false);

  const [sequenceId, setSequenceId] = useState(null);

  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);

  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });

  const [isManualAdded, setIsManualAdded] = useState(false);

  const [seqIsOnce, setSeqIsOnce] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });

  let isCreatePrescription = true;
  let isSequenceNumberChanged = false;

  // sequence number generator functions

  const getSequenceDetails = async () => {
    try {
      await getInvoiceNumber("Investigation").then((result: any) => {
        let data = result?.data[0]?.sequence_no;

        if (isCreatePrescription && !isSequenceNumberChanged) {
          setInitialSequenceNumber(data);
          setInvoiceNumber(data);
        }

        if (isSequenceNumberChanged) {
          setInvoiceNumber(data);
        }

        if (result?.data?.length > 0) {
          setSequenceId(result.data[0].sequence.id);
          const newSequenceData =
            result?.data[0]?.sequence?.sequence_preferences.map(
              (ele: {
                id: number;
                type: string;
                value: string;
                separator: string;
              }) => ({
                id: ele.id,
                type: ele.type,
                value: ele.value,
                separator: ele.separator,
              })
            );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual:
              result?.data[0]?.sequence?.is_manual === 1 ? true : false,
            is_auto_generate:
              result?.data[0]?.sequence?.is_manual !== 1 ? true : false,
          }));
          setSeqIsOnce((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(result?.data[0]?.sequence?.is_manual === 1));
        }
      });
    } catch (error: any) {
      console.error("An error occurred:", error);
    }
  };

  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value !== "Select" ? value : null,
      };
      return newState;
    });
  };

  function onCloseSequenceDialogBox() {
    setIsSequenceWrapperOpen(false);
  }

  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      try {
        const data = {
          seq_preference: [...sequenceData],
          is_manual: isManual.is_manual ? 1 : 0,
        };
        await updatedSequence(Number(sequenceId), data).then((res: any) => {
          if (res) {
            isSequenceNumberChanged = true;
            getSequenceDetails();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        });
      } catch (error: any) {
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.errors,
          })
        );
      }
    }
  };

  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };

  const handleConfirmSequenceNoChange = () => {
    if (initialSequenceNumber !== invoiceNumber) {
      setSeqIsOnce((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: invoiceNumber,
      }));
      setIsSequenceWrapperOpen(true);
    }
  };

  const getInvestigationDetails = async () => {
    if (appointmentsDetails?.id === undefined) {
      return false;
    }
    try {
      setIsPageLoading(true);
      await getInvestigationList(appointmentsDetails?.id).then(
        (result: any) => {
          setIsPageLoading(false);

          const investigationId = result?.data?.result[0]?.id;

          let data = investigationId
            ? result?.data?.result[0]?.investigation_products
            : [];

          const transformedData = data?.map((item: any) => ({
            ...item,
            investigation_id: investigationId,
            billing_item: {
              ...item.billing_item,
              isTestNameTooltipOpen: false,
            },
          }));

          if (investigationId === undefined) {
            setInvestigations(initialInvestigation);
            getInvoiceNo();
            setIsCreate(true);
            setIsDeleteButtonDisabled(true);
            isCreatePrescription = true;
            isSequenceNumberChanged = false;
            getSequenceDetails();
          } else {
            setInvestigationId(investigationId);
            setInvestigations(transformedData);
            setInitialInvoiceNumber(result?.data?.result[0]?.invoice_no);
            setInvoiceNumber(result?.data?.result[0]?.invoice_no);
            setIsCreate(false);
            setIsDeleteButtonDisabled(false);
            isCreatePrescription = false;
            isSequenceNumberChanged = false;
            getSequenceDetails();
          }
        }
      );
    } catch (error: any) {
      setIsPageLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const getTestDetails = async () => {
    try {
      await getTestList().then((result: any) => {
        let data = result?.data;
        setTestOptions(data);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getInvoiceNo = async () => {
    try {
      await getInvoiceNumber("Investigation").then((result: any) => {
        let data = result?.data[0]?.sequence_no;

        if (data?.length > 0) {
          isCreatePrescription = false;
          isSequenceNumberChanged = false;
          getSequenceDetails();
        }
        setInitialInvoiceNumber(data);
        setInvoiceNumber(data);
      });
    } catch (error: any) {
      console.error("An error occurred:", error);
    }
  };

  const handleInvestigationAdd = useCallback(() => {
    setInvestigations((prevInvestigations: any) => [
      ...prevInvestigations,
      {
        id: Date.now(),
        billing_item: {
          id: null,
          name: "",
          isTestNameTooltipOpen: false,
          lab_tests: [],
        },
      },
    ]);
  }, []);

  const handleInvestigationDelete = () => {
    if (appointmentsDetails?.id === undefined) {
      return false;
    }
    setIsInvestigationTestDelete(false);
    setOpen(true);
    setTestToBeDeleted(appointmentsDetails?.id);
  };

  const handleInvestigationTestDelete = useCallback(
    (investigationId: number) => {
      if (investigationId.toString().length > 10) {
        setInvestigations((prev: any) => {
          return prev.filter(
            (investigation: any, i: number) =>
              investigation?.id !== investigationId
          );
        });

        setCallUseEffect((prev) => !prev);
      } else {
        setIsInvestigationTestDelete(true);
        setOpen(true);
        setTestToBeDeleted(investigationId);
      }
    },
    []
  );

  const handleAutoCompleteChange = useCallback(
    (e: any, newValue: any, id: number, name: string) => {
      if (newValue === null) {
        return false;
      }

      setInvestigations((prev: any) => {
        return prev.map((investigation: any, i: number) => {
          if (investigation.id === id) {
            return {
              ...investigation,
              billing_item: {
                ...investigation.billing_item,
                [name]: newValue,
                testId: newValue.id,
                lab_tests: newValue.lab_tests,
              },
            };
          }
          return investigation;
        });
      });
    },
    []
  );

  const handleAutoCompleteInputChange = useCallback(
    (e: any, newInputValue: any, id: number, name: string) => {
      if (newInputValue === "") {
        return false;
      }

      setInvestigations((prev: any) => {
        return prev.map((investigation: any, i: number) => {
          if (investigation?.id === id) {
            return {
              ...investigation,
              billing_item: {
                ...investigation.billing_item,
                [name]: newInputValue,
              },
            };
          }
          return investigation;
        });
      });
    },
    []
  );

  const investigationTestCreate = async () => {
    try {
      if (appointmentsDetails?.id === undefined) {
        return false;
      }
      const transformedData = investigations.map((item: any) => ({
        id: item.id,
        test: item?.billing_item?.id,
        test_id: item?.billing_item?.testId,
      }));

      const data = {
        appoint_id: appointmentsDetails?.id,
        inv_invoice_no: invoiceNumber,
        inv_invoice_no_modified: invoiceNumber === initialInvoiceNumber ? 0 : 1,
        investigation_product: transformedData,
      };
      setIsSaveButtonLoading(true);
      await createInvestigationTest(data).then(() => {
        setIsSaveButtonLoading(false);

        dispatch(
          setSnackBarSuccess({
            snackBarMessage: ADDED_SUCCESSFULLY,
          })
        );
        getInvestigationDetails();
      });
    } catch (error: any) {
      setIsSaveButtonLoading(false);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.message,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const investigationTestUpdate = async () => {
    try {
      if (appointmentsDetails?.id === undefined) {
        return false;
      }
      const transformedData = investigations?.map((item: any) => ({
        id: item.id ? item.id : null,
        test: item?.billing_item?.id,
        test_id: item?.billing_item?.testId
          ? item?.billing_item?.testId
          : item?.billing_item?.id,
      }));

      const data = {
        appoint_id: appointmentsDetails?.id,
        inv_invoice_no: invoiceNumber,
        inv_invoice_no_modified: invoiceNumber === initialInvoiceNumber ? 0 : 1,
        investigation_product: transformedData,
      };
      setIsSaveButtonLoading(true);

      await updateInvestigationTest(investigationId, data).then(() => {
        setIsSaveButtonLoading(false);

        dispatch(
          setSnackBarSuccess({
            snackBarMessage: UPDATED_SUCCESSFULLY,
          })
        );
        getInvestigationDetails();
      });
    } catch (error: any) {
      setIsSaveButtonLoading(false);

      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.message,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    getInvestigationDetails();
    getTestDetails();
  }, []);

  useEffect(() => {
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }
  }, [appConfiguration]);

  const handleConfirmDelete = async () => {
    if (appointmentsDetails?.id === undefined) {
      return false;
    }

    try {
      if (isInvestigationTestDelete) {
        setIsDeleteButtonLoading(true);

        await deleteInvestigationTest(testToBeDeleted).then((result: any) => {
          setIsDeleteButtonLoading(false);

          setTestToBeDeleted(null);
          setOpen(false);

          setInvestigations((prev: any) => {
            return prev.filter(
              (investigation: any, i: number) =>
                investigation?.id !== testToBeDeleted
            );
          });
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: DELETED_SUCCESSFULLY,
            })
          );
        });
      } else {
        setIsDeleteButtonLoading(true);
        await deleteInvestigation(appointmentsDetails?.id).then(() => {
          setIsDeleteButtonLoading(false);

          getInvestigationDetails();
          setTestToBeDeleted(null);
          setOpen(false);
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: DELETED_SUCCESSFULLY,
            })
          );
        });
      }
    } catch (error: any) {
      setIsDeleteButtonLoading(false);
      setTestToBeDeleted(null);
      setOpen(false);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.message,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleSaveData = () => {
    const isTestNotFilled = investigations
      ?.map((row: any) => {
        return row?.billing_item?.id;
      })
      .includes(null);

    if (isTestNotFilled) {
      return setInvestigations((prev: any) =>
        prev.map((row: any) => {
          return {
            ...row,
            billing_item: {
              ...row.billing_item,
              isTestNameTooltipOpen:
                row.billing_item.id === null ? true : false,
            },
          };
        })
      );
    }
    if (invoiceNumber === initialInvoiceNumber) {
      if (isCreate) {
        investigationTestCreate();
      } else {
        investigationTestUpdate();
      }
    } else {
      if (isCreate) {
        investigationTestCreate();
      } else {
        investigationTestUpdate();
      }
    }
  };

  const handleToolTipClose = useCallback((name: string, index: number) => {
    setInvestigations((prev: any) =>
      prev.map((row: any, i: number) => {
        return i === index
          ? { ...row, billing_item: { ...row.billing_item, [name]: false } }
          : row;
      })
    );
  }, []);

  const updatePrescriptionAfterProductDelete = () => {
    setInvestigations((prev: any) => {
      return prev.filter((item: any) => item.id > 0);
    });
  };

  useEffect(() => {
    updatePrescriptionAfterProductDelete();
  }, [callUseEffect]);

  return isPageLoading ? (
    <Grid
      sx={{
        width: "100%",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        height: "50vh",
        background: "transparent",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid
      item
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ConfirmationDialog
        open={open}
        title="are you surely want to delete"
        handleClick={handleConfirmDelete}
        onClose={onClose}
        loading={isDeleteButtonLoading}
      />
      <SequenceWrapper
        open={isSequenceWrapperOpen}
        title="Appointments"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />

      <Grid
        item
        sx={{
          width: "98%",
          display: "flex",
          flexDirection: {
            md: "row",
            xs: "column",
          },
          justifyContent: "space-between",
        }}
      >
        <Grid sx={{ display: "flex", alignItems: "center", pb: 1 }}>
          <Typography
            variant="h2"
            style={{
              fontSize: "var(--primary-header-font-size) !important",
              fontWeight: "var(--primary-header-font-weight)",
            }}
          >
            {appointmentDetailsConst.INVESTIGATIONS}
          </Typography>
          {customSequence && (
            <TextField
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              onBlur={handleConfirmSequenceNoChange}
              endPlaceholderIcon={
                <SettingsIcon
                  width="20px"
                  height="20px"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsSequenceWrapperOpen(true);
                  }}
                />
              }
              sx={{
                maxWidth: "200px",
                minWidth: "150px",
                width: "100%",
                m: "0 0px 0 10px",
              }}
            />
          )}
          <IconButton
            disabled={isDeleteButtonDisabled}
            onClick={handleInvestigationDelete}
          >
            {isDeleteButtonDisabled ? <DeleteLightIcon /> : <DeleteIcon />}
          </IconButton>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            pb: 1,
          }}
        >
          <SaveButton
            buttonText="Investigation Template"
            buttonSize="md"
            handleClick={() => setIsOpenInvestigationModal(true)}
          />
          <Box sx={{ m: "0px 10px" }}>
            <HistoryIcon />
          </Box>
          <Select
            placeholder={<PrintingLightIcon />}
            sx={{ width: "100%" }}
            width=""
          />
        </Grid>
      </Grid>
      <Grid
        className="investigations"
        sx={{
          width: {
            xs: "92vw",
          },
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          overflowX: { md: "scroll", lg: "hidden" },
        }}
      >
        <TableContainer
          className="table-container"
          component={Paper}
          sx={{
            ...styles.tableContainerStyle,
            position: "relative",
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                height: "var(--table-header-height)",
                backgroundColor: "rgba(236, 244, 255, 0.8)",
                width: {
                  xs: "400vw",
                  sm: "200vw",
                  md: "120vw",
                  lg: "90vw",
                },
                display: "flex",
                "& >*": {
                  boxSizing: "border-box",
                  margin: 0,
                },
              }}
            >
              {TableHeaderCells.map((cell) => {
                return (
                  <TableCell
                    align={cell.alignment}
                    sx={{
                      height: "var(--table-header-height)",
                      p: "0px 10px",
                      fontSize: "var(--table-header-font-size)",
                      fontWeight: "var(--table-header-font-weight)",
                      color: "primary.main",
                      border: 0,
                      borderBottom: 0,
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                      margin: 0,
                      ...cell.sx,
                    }}
                  >
                    {cell.TableCellName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <Table
            sx={{
              ...styles.tableStyle,
              "& >*": {
                boxSizing: "border-box",
                margin: 0,
              },
            }}
            aria-label="simple table"
          >
            <TableBody
              sx={{
                height: "auto",
                width: "90vw",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {investigations.map((row: any, index: number) => {
                return (
                  <InvestigationRow
                    key={index}
                    index={index}
                    row={row}
                    testOptions={testOptions}
                    handleAutoCompleteChange={handleAutoCompleteChange}
                    handleAutoCompleteInputChange={
                      handleAutoCompleteInputChange
                    }
                    handleDelete={handleInvestigationTestDelete}
                    handleToolTipClose={handleToolTipClose}
                  />
                );
              })}
            </TableBody>
            <Grid
              sx={{
                width: {
                  xs: "200vw",
                  sm: "150vw",
                  md: "120vw",
                  lg: "90vw",
                },
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: {
                    xs: "136.5vw",
                    sm: "86.5vw",
                    md: "56.5vw",
                    lg: "27vw",
                  },
                }}
              >
                <Button
                  variant="text"
                  buttonText="+ Add Product"
                  handleClick={handleInvestigationAdd}
                  sx={{
                    borderRadius: "0px",
                    width: "auto",
                    height: "auto",
                    fontWeight: 400,
                    fontSize: "13px",
                    border: 0,
                    color: "primary.main",
                    "&:hover": {
                      backgroundColor: "initial",
                      color: "primary.main",
                      borderColor: "initial",
                    },
                    "&.Mui-focusVisible": {
                      border: 1,
                      borderColor: "primary.main",
                      borderRadius: "5px",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Table>
        </TableContainer>

        {/* <InvestigationTemplateDialog
          isOpen={isOpenInvestigationModal}
          onClose={() => setIsOpenInvestigationModal(false)}
        /> */}
      </Grid>
      {can("op_investigation_add") && (
        <Grid item sx={{ display: "flex", gap: "2", mt: "20px" }}>
          <SaveButton
            sx={{ width: "100px", height: "40px" }}
            handleClick={handleSaveData}
            loading={isSaveButtonLoading}
          />
          <CancelButton sx={{ width: "100px", height: "40px", ml: 3 }} />
        </Grid>
      )}
    </Grid>
  );
};

export default Investigations;
