import { useCallback, useEffect, useState, useRef } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  debounce,
} from "@mui/material";
import {
  Label,
  Select,
  Sort,
  TextAreaEditor,
  TextField,
} from "../../../../components/basic";
import {
  ConfirmationDialog,
  DataTable,
  DialogWrapper,
} from "../../../../components/shared";
import { DeleteIcon, EditIcon, PlusIcon } from "../../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  createPrintNoteMaster,
  deletePrintNoteMaster,
  getAllPrintNoteMaster,
  PrintNoteMasterTypes,
} from "../../../../services/appointmentsMasterService";
import {
  appointmentsMasterConst,
  commonText,
  errorMessage,
} from "../../../../constants/displayText";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import {
  editPrintNoteMaster,
  updatePrintNoteMaster,
} from "../../../../services/appointmentsMasterService";
import CustomFilter from "../../../../components/shared/customFilter";
import PermissionUtils from "../../../../utils/PermissionUtils";
import { setCurrentPage } from "../../../../redux/slices/pagination";

type GridRowData = Record<string, unknown>;

const PrintNoteMasterList = () => {
  const { can } = PermissionUtils();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [printNoteMasterList, setPrintNoteMasterList] = useState<
    PrintNoteMasterTypes[]
  >([]);
  const pageInfo = useSelector(
    (state: RootState) => state?.pagination?.printNoteList
  );

  const [pageCount, setPageCount] = useState<number | null>(null);
  const handleFilterClick = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  const sectionOptions: any = [
    { id: "OP", name: "OP" },
    { id: "IP", name: "IP" },
  ];
  const [AddPrintNotes, setAddPrintNotes] = useState(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [printNoteId, setPrintNoteId] = useState<any>(null);
  const [saveBtnLoader, setSaveBtnLoader] = useState<boolean>(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | number | null>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [dialogLoading, setDialogLoading] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [printNoteToBeDeleted, setPrintNoteToBeDeleted] = useState<any>(null);
  const [typeList, setTypeList] = useState<string[]>([]);

  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  const handleSearchOnChange = (e: any) => {
    setSearchValue(e.target.value);
    dispatch(setCurrentPage({ key: "printNoteList", value: 0 }));
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {can("print_note_edit") && (
          <div style={{ cursor: "pointer" }}>
            <IconButton onClick={() => editPrintNote(row)}>
              <EditIcon />
            </IconButton>
          </div>
        )}
        {can("print_note_delete") && (
          <div style={{ cursor: "pointer" }}>
            <IconButton onClick={() => deletePrintNote(row)}>
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  const initialPrintNoteFormError = {
    type: "",
    section: "",
    content: "",
  };

  const [printNoteFormError, setPrintNoteFormError] = useState<any>(
    initialPrintNoteFormError
  );

  const initialData = {
    type: "",
    section: "",
    content: "",
  };

  const [printNoteFormData, setPrintNoteFormData] = useState<
    string | Date | any
  >(initialData);

  const fieldData: any = {
    type: {
      label: "Type",
      name: "type",
      value: printNoteFormData.type,
      ref: useRef(null),
      isError: Boolean(printNoteFormError.type),
      helperText: printNoteFormError.type,
    },
    section: {
      label: "Section",
      name: "section",
      value: printNoteFormData.section,
      ref: useRef(null),
      isError: Boolean(printNoteFormError.section),
      helperText: printNoteFormError.section,
    },
    content: {
      label: "Content",
      name: "content",
      value: printNoteFormData.content,
      ref: useRef(null),
      isError: Boolean(printNoteFormError.content),
      helperText: printNoteFormError.content,
    },
  };

  const [printNoteFieldData, setPrintNoteFieldData] = useState(fieldData);

  const columns: GridColDef[] = [
    {
      field: "section",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <Grid
          onClick={() => handleSorting("section")}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Label
            variant="h5"
            label={appointmentsMasterConst.SECTION}
            sx={{ color: "primary.main" }}
          />
          <Sort
            ascendingActive={
              sortedField.field === "section" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "section" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: { row: PrintNoteMasterTypes }) => (
        <Label
          variant="h5"
          label={row?.section}
          sx={{ color: "Primary.dark" }}
        />
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "type",
      flex: 1,
      renderHeader: () => (
        <Grid
          onClick={() => handleSorting("type")}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Label
            variant="h5"
            label={appointmentsMasterConst.TYPE}
            sx={{ color: "primary.main" }}
          />
          <Sort
            ascendingActive={
              sortedField.field === "type" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "type" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: { row: PrintNoteMasterTypes }) => (
        <Label variant="h5" label={row?.type} sx={{ color: "Primary.dark" }} />
      ),
      minWidth: 140,
      sortable: false,
    },
    ...(can("print_note_edit") || can("print_note_delete")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const handleDialogOpen = () => {
    setPrintNoteFormData(initialData);
    setAddPrintNotes(true);
    setIsUpdate(false);
  };

  const handleDialogClose = () => {
    setAddPrintNotes(false);
    setPrintNoteFormError(initialPrintNoteFormError);
  };
  const dispatch = useDispatch();

  let isFocused = false;

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "type":
      case "content":
      case "section": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setPrintNoteFormError
          );
          if (!isFocused) {
            if (fieldData[name]?.ref?.current) {
              fieldData[name]?.ref?.current?.focus();
              isFocused = true;
            }
          }
        } else {
          updateFormDataWithHelperText(name, "", setPrintNoteFormError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updatePrintNoteFormData = () => {
    setPrintNoteFieldData((prevFieldData: any) => {
      return Object.keys(prevFieldData).map((field: any) => {
        return {
          ...field,
          value: printNoteFormData[field.name],
          helperText: printNoteFormError[field.name],
          isError: Boolean(printNoteFormError[field.name]),
        };
      });
    });
  };

  useEffect(() => {
    updatePrintNoteFormData();
  }, [printNoteFormError, printNoteFormData]);

  const getPrintNoteMasterList = useCallback(
    debounce(async (data) => {
      setLoading(true);
      await getAllPrintNoteMaster(data)
        .then((res: any) => {
          setPrintNoteMasterList(res?.data?.result as PrintNoteMasterTypes[]);
          const types = res?.data?.result?.map((item: any) => item?.type);
          setTypeList(types);
          setPageCount(res?.data?.total as any);
          setLoading(false);
        })
        .catch((err) => {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage.ERROR_FETCHING_PRINTNOTE}`,
            })
          );
          setLoading(false);
          console.log("error", err);
        });
    }, 300),
    []
  );

  useEffect(() => {
    const data = {
      page: Number(pageInfo?.page) + 1,
      pageSize: pageInfo?.pageSize,
      sortBy: sortedField.field,
      sortOrder: sortedField.order,
      searchValue: searchValue,
    };
    getPrintNoteMasterList(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortedField.order,
    sortedField.field,
    searchValue,
  ]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setPrintNoteFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    handleValidation(e);
  };

  const handleTextAreaChange = (value: any, name: string) => {
    setPrintNoteFormData((prevData: any) => ({
      ...prevData,
      content: value,
    }));
    handleValidation({
      target: {
        label: "Content",
        name: name,
        value: value,
      },
    });
  };

  const { type, section, content } = printNoteFormData;

  const createPrintNote = async () => {
    if (!Boolean(content) || !Boolean(section) || !Boolean(type)) {
      validateForm();
    } else {
      setSaveBtnLoader(true);
      try {
        await createPrintNoteMaster(printNoteFormData).then((result: any) => {
          dispatch(
            setSnackBarSuccess({
              snackBarMessage:
                appointmentsMasterConst.ADDED_SUCCESSFULLY as string,
            })
          );
          setAddPrintNotes(!AddPrintNotes);
          getPrintNoteMasterList({
            page: Number(pageInfo.page) + 1,
            pageSize: pageInfo?.pageSize,
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            searchValue: searchValue,
          });
        });
        setSaveBtnLoader(false);
      } catch (error: any) {
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.error_data?.type[0]
              ? error?.response?.data?.error_data?.type[0]
              : error?.response?.data?.errors,
          })
        );
        setSaveBtnLoader(false);
      }
    }
  };

  const editPrintNote = async (row: any) => {
    try {
      setIsUpdate(true);
      setAddPrintNotes(true);
      setDialogLoading(true);
      await editPrintNoteMaster(row.id).then((result: any) => {
        setPrintNoteFormData((prevData: any) => ({
          ...prevData,
          type: result?.data?.type,
          section: result?.data?.section,
          content: result?.data?.content,
        }));
        setPrintNoteId(result?.data?.id);
      });
      setDialogLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setDialogLoading(false);
    }
  };

  const updatePrintNote = () => {
    if (!Boolean(content) || !Boolean(section) || !Boolean(type)) {
      validateForm();
    } else {
      setSaveBtnLoader(true);
      try {
        updatePrintNoteMaster(printNoteFormData, printNoteId).then(
          (result: any) => {
            getPrintNoteMasterList({
              page: Number(pageInfo.page) + 1,
              pageSize: pageInfo?.pageSize,
              sortBy: sortedField.field,
              sortOrder: sortedField.order,
              searchValue: searchValue,
            });
            dispatch(
              setSnackBarSuccess({
                snackBarMessage:
                  appointmentsMasterConst.UPDATED_SUCCESSFULLY as string,
              })
            );
            setSaveBtnLoader(false);
            setAddPrintNotes(false);
            setPrintNoteId(null);
          }
        );
      } catch (error) {
        console.error("An error occurred:", error);
        setSaveBtnLoader(false);
      }
    }
  };

  const deletePrintNote = (row: any) => {
    setIsConfirmationDialogOpen(true);
    setPrintNoteToBeDeleted(row.id);
  };

  const handleConfirmDelete = async () => {
    if (!Boolean(printNoteToBeDeleted)) {
      return false;
    }
    try {
      setDeleteBtnLoader(true);
      await deletePrintNoteMaster(printNoteToBeDeleted)
        .then((result: any) => {
          setPrintNoteToBeDeleted(null);
          setIsConfirmationDialogOpen(false);
          getPrintNoteMasterList({
            page: Number(pageInfo.page) + 1,
            pageSize: pageInfo?.pageSize,
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            searchValue: searchValue,
          });
        })
        .then((result) => {
          dispatch(
            setSnackBarSuccess({
              snackBarMessage:
                appointmentsMasterConst.DELETED_SUCCESSFULLY as string,
            })
          );
        });
      setDeleteBtnLoader(false);
    } catch (error) {
      setPrintNoteToBeDeleted(null);
      setIsConfirmationDialogOpen(false);
      setDeleteBtnLoader(false);

      console.error("An error occurred:", error);
    }
  };

  const onClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          title="are you surely want to delete?"
          handleClick={handleConfirmDelete}
          onClose={onClose}
          loading={deleteBtnLoader}
        />

        <CustomFilter
          isSearchEnabled={true}
          searchValue={searchValue}
          searchOnChange={handleSearchOnChange}
          searchName={"search"}
          clearSearch={() => setSearchValue("")}
          {...(can("print_note_add") && {
            editButtonTitle: commonText.EDIT_BUTTON_TITLE,
            onUpdateButtonClick: () => {
              handleDialogOpen();
            },
            editStartIcon: (
              <Box
                sx={{
                  "& path": {
                    fill: "#fff",
                  },
                }}
              >
                <PlusIcon width={"12px"} height={"12px"} />
              </Box>
            ),
          })}
        />
        <DataTable
          title={appointmentsMasterConst.PRINT_NOTE_MASTER}
          columns={columns}
          getRowId={(row: GridRowData) => `${String(row.id)}`}
          rows={printNoteMasterList}
          pageCount={pageCount}
          currentPage={"printNoteList"}
          handleFilterClick={handleFilterClick}
          loading={loading}
        />
      </Box>
      <DialogWrapper
        onClose={() => {
          handleDialogClose();
        }}
        open={AddPrintNotes}
        handleClick={() => {
          isUpdate ? updatePrintNote() : createPrintNote();
        }}
        title={isUpdate ? "Edit Print Note" : "Add Print Note"}
        maxWidth="lg"
        loading={saveBtnLoader}
      >
        {dialogLoading ? (
          <div
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              height: "50vh",
              background: "transparent",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 3,
                mb: 2,
              }}
            >
              <Box>
                <TextField
                  placeholder="Enter Type"
                  label="Type"
                  sx={{ width: { xs: "250px", md: "300px" } }}
                  onChange={handleInputChange}
                  value={fieldData.type.value}
                  inputRef={fieldData?.type?.ref}
                  name={fieldData.type.name}
                  helperText={fieldData.type.helperText}
                  onBlur={(e: any) =>
                    handleValidation((e = { target: fieldData.type }))
                  }
                />
              </Box>
              <Box>
                <Select
                  value={fieldData.section.value}
                  inputRef={fieldData?.section?.ref}
                  placeholder="Select Section"
                  onChange={handleInputChange}
                  options={sectionOptions}
                  name={fieldData.section.name}
                  label={"Section"}
                  helperText={fieldData.section.helperText}
                  onBlur={(e: any) =>
                    handleValidation((e = { target: fieldData.section }))
                  }
                  sx={{ width: { xs: "250px", sm: "300px" } }}
                />
              </Box>
            </Box>
            <TextAreaEditor
              value={fieldData.content.value}
              onChange={(content: any) =>
                handleTextAreaChange(content, "content")
              }
              placeholder={""}
              name={fieldData.content.name}
              label={fieldData.content.label}
              error={fieldData.content.isError}
              helperText={fieldData.content.helperText}
            />
          </>
        )}
      </DialogWrapper>
    </div>
  );
};

export default PrintNoteMasterList;
