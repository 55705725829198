import React, { useCallback, useEffect, useMemo, useState } from "react";
import Info from "./info";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import {
  Button,
  CancelButton,
  DeleteDialog,
  SaveButton,
} from "../../../../components/basic";
import { stockAdjustmentConst } from "../../../../constants/displayText";
import ProductRow from "./productRow";
import {
  createStockAdjustment,
  getAllMasterDepartments,
  getStockAdjustProduct,
} from "../../../../services/gynecologyService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { useNavigate } from "react-router-dom";
import SequenceWrapper from "../../../../components/shared/sequenceWrapper";
import { updatedSequence } from "../../../../services/procurementMasterService";
import { getDepartmentSequence } from "../../../../services/mainCoreService";
type TableHeaderCell = {
  TableCellName: string;
  alignment: "left" | "right";
  sx?: {};
};
type BillProductType = {
  id: number | string | null;
  name: number | string | null;
};
const BillProducts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmitLoaded, setIsSubmitLoaded] = useState(false);
  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<{
    isOpen: boolean;
    selectedRow: {
      id: number;
      batchNo?: string;
    } | null;
  }>({
    isOpen: false,
    selectedRow: null,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: {
          xs: "92vw",
        },
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "90vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "44px !important",
          maxHeight: "44px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },
        "& .MuiTableCell-root": {
          minHeight: "0px",
        },
        "& .MuiTableCell-head": {
          height: "44px",
          maxHeight: "44px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "var(--table-header-font-size)",
          fontWeight: "var(--table-header-font-weight)",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body": {
          height: "44px !important",
          maxHeight: "44px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "var(--table-body-font-size)",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "44px",
        },
        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "44px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },

      textFieldStyle: {
        height: "44px",
        width: "auto",

        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "44px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "44px",
          maxHeight: "44px",
          top: 0,
        },

        "& input": {
          height: "100%",
        },
      },

      selectStyle: {
        "& .MuiFormControl-root": { width: "100%" },

        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
          borderWidth: 0,
          height: "44px",
          maxHeight: "44px",
          p: 0,
        },

        "& .MuiInputBase-root": {
          padding: "0px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },

        "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "primary.main",
            borderWidth: "1px",
          },

        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: 0,
        },
      },
    }),
    []
  );
  const {
    productName,
    batchNo,
    stock,
    decQty,
    incQty,
    price,
    discount,
    tax,
    incAmount,
    decAmount,
    reason,
  } = stockAdjustmentConst;
  const TableHeaderCells: TableHeaderCell[] = [
    { TableCellName: productName, alignment: "left" },
    { TableCellName: batchNo, alignment: "left" },
    { TableCellName: stock, alignment: "right" },
    { TableCellName: incQty, alignment: "right" },
    { TableCellName: decQty, alignment: "right" },
    { TableCellName: price, alignment: "right" },
    { TableCellName: discount, alignment: "right" },
    { TableCellName: tax, alignment: "right" },
    { TableCellName: incAmount, alignment: "right" },
    { TableCellName: decAmount, alignment: "right" },
    {
      TableCellName: reason,
      alignment: "left",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];
  const [formErrors, setFormErrors] = useState<
    { dec_qty: boolean; reason: boolean }[]
  >([{ dec_qty: false, reason: false }]);
  const { commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [departmentList, setDepartmentList] = useState<BillProductType[]>([]);
  const [isSelectLoading, setIsSelectLoading] = useState(false);
  const [productRow, setProductRow] = useState([]);
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);
  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });
  const [isManualAdded, setIsManualAdded] = useState(false);
  const [sequenceNo, setSequenceNo] = useState("");
  const [sequenceDialogBox, setSequenceDialogBox] = useState(false);
  const [autoGeneratedSeqNo, setAutoGeneratedSeqNo] = useState("");
  const [seqIsOnce, setSeqIsOne] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });
  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };
  const handleInvoiceNoChange = () => {
    if (autoGeneratedSeqNo !== info.bill_no && Boolean(!isManual.is_once)) {
      setSeqIsOne((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: info.bill_no,
      }));
      setSequenceDialogBox(true);
      setIsManual((prevState) => ({
        ...prevState,
        is_manual: false,
        is_auto_generate: false,
        is_once: true,
      }));
    }
  };
  let isCreatePrescription = true;
  let isSequenceNumberChanged = false;

  const handleSequence = () => {
    getDepartmentSequence("Stock Adjustment")
      .then((res: any) => {
        let data = res?.data[0]?.sequence_no;

        if (isCreatePrescription && !isSequenceNumberChanged) {
          setInitialSequenceNumber(data);
          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: res?.data[0]?.sequence_no,
          }));
        }

        if (isSequenceNumberChanged) {
          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: res?.data[0]?.sequence_no,
          }));
        }
        if (res?.data?.length > 0) {
          setInitialSequenceNumber(res?.data[0]?.sequence_no);

          setAutoGeneratedSeqNo(res?.data[0]?.sequence_no);
          setSequenceNo(res.data[0].sequence.id);
          const newSequenceData = res.data[0].sequence.sequence_preferences.map(
            (ele: {
              id: number;
              type: string;
              value: string;
              separator: string;
            }) => ({
              id: ele.id,
              type: ele.type,
              value: ele.value,
              separator: ele.separator,
            })
          );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual: res.data[0].sequence.is_manual === 1 ? true : false,
            is_auto_generate:
              res.data[0].sequence.is_manual !== 1 ? true : false,
          }));
          setSeqIsOne((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(res.data[0].sequence.is_manual === 1));
        }
      })
      .catch((err: any) => console.log(err));
  };
  useEffect(() => {
    handleSequence();
  }, []);
  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value === "Select" ? "" : value,
      };
      return newState;
    });
  };
  function onCloseSequenceDialogBox() {
    setSequenceDialogBox(false);
  }

  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      const data = {
        seq_preference: [...sequenceData],
        is_manual: isManual.is_manual ? 1 : 0,
      };
      await updatedSequence(Number(sequenceNo), data)
        .then((res: any) => {
          if (res) {
            isSequenceNumberChanged = true;
            handleSequence();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        })
        .catch((err: any) => {
          dispatch(setSnackBarFailed({ snackBarMessage: err.message }));
        });
    }
  };
  const getAllDepartmentList = async () => {
    const data = { active: 1 };
    setIsSelectLoading(true);
    await getAllMasterDepartments(data)
      .then((res: any) => {
        const formattedValue = res.data.result
          ?.filter((elem: { type: string }) => elem.type !== "B2B")
          .map((ele: { dept_name: string; id: number }) => ({
            id: ele.id,
            name: ele.dept_name,
          }));
        setDepartmentList(formattedValue);
        setIsSelectLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsSelectLoading(false);
      });
  };

  const initialBillProduct = {
    id: null,
    product_name: null,
    batch_no: "",
    stock: null,
    dec_qty: null,
    inc_qty: null,
    price: null,
    discount: null,
    tax: null,
    inc_amount: null,
    dec_amount: null,
    reason: null,
  };

  const [billProducts, setBillProducts] = useState<any>([initialBillProduct]);

  const initialInfo: any = {
    department: null,
    bill_no: "",
    bill_no_modified: "",
  };
  const [batchIdNo, setBatchIdNo] = useState<number[] | []>([]);

  const [info, setInfo] = useState<{} | any>(initialInfo);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [rowDisables, setRowDisables] = useState<
    { inc_qty: boolean; dec_qty: boolean }[]
  >([
    {
      inc_qty: false,
      dec_qty: false,
    },
  ]);
  const [customSequence, setCustomSequence] = useState(true);
  const handleCalculation = useCallback((product: any, index: any) => {
    let rate = product?.product_name?.p_rate / product?.product_name?.pack_type;
    let inc_disc_value: any = 0;
    let inc_tax_value: any = 0;
    let dec_tax_value: any = 0;
    let dec_disc_value: any = 0;
    let inc_amount_value = product?.inc_qty
      ? (product?.inc_qty * rate || 0).toFixed(2)
      : 0;
    let dec_amount_value = product?.dec_qty
      ? (product?.dec_qty * rate || 0).toFixed(2)
      : 0;
    let tax_per = product?.product_name?.tax_id;
    const isInclusive = product?.tax_settings === 0 ? "inclusive" : "exclusive";
    if (isNaN(product?.discount) || isNaN(inc_amount_value as number)) {
      inc_disc_value = "";
    } else {
      inc_disc_value = (
        (product?.discount / 100) *
        Number(inc_amount_value)
      ).toFixed(2);
    }

    if (isNaN(tax_per)) {
      inc_tax_value = "";
    } else {
      if (isInclusive === "exclusive") {
        inc_tax_value = Math.abs(
          (Number(inc_amount_value) - inc_disc_value) *
            (1 - (1 / 1 + tax_per / 100))
        );
      } else {
        inc_tax_value = 0;
      }
    }

    if (isNaN(product?.discount) || isNaN(Number(dec_amount_value))) {
      dec_disc_value = "";
    } else {
      dec_disc_value = (
        (product?.discount / 100) *
        Number(dec_amount_value)
      ).toFixed(2);
    }
    if (isNaN(tax_per)) {
      dec_tax_value = "";
    } else {
      if (isInclusive === "exclusive") {
        dec_tax_value = Math.abs(
          (Number(dec_amount_value) - dec_disc_value) *
            (1 - (1 / 1 + tax_per / 100))
        );
      } else {
        dec_tax_value = 0;
      }
    }

    return {
      increase_amount:
        parseFloat(
          Number(inc_amount_value) - inc_disc_value + inc_tax_value
        ).toFixed(2) || 0,
      decrease_amount:
        parseFloat(
          Number(dec_amount_value) - dec_disc_value + dec_tax_value
        ).toFixed(2) || 0,
    };
  }, []);
  const handleDisabledRows = useCallback(
    (name: string, product: any, index: number) => {
      if (name === "inc_qty" && product.inc_qty.length > 0) {
        setRowDisables((prevState) => {
          const newState = [...prevState];
          newState[index as number]["dec_qty"] = true;
          return newState;
        });
      } else if (name === "dec_qty" && product.dec_qty.length > 0) {
        setRowDisables((prevState) => {
          const newState = [...prevState];
          newState[index as number]["inc_qty"] = true;
          return newState;
        });
      } else {
        setRowDisables((prevState) => {
          const newState = [...prevState];
          newState[index as number]["dec_qty"] = false;
          newState[index as number]["inc_qty"] = false;
          return newState;
        });
      }
    },
    []
  );
  const handleFormValidation = useCallback(
    (e: any, updatedValue: any, idx: number | string) => {
      const { name, value } = e.target;
      switch (name) {
        case "dec_qty": {
          if (value > Number(updatedValue.stock)) {
            setFormErrors((prevState) => {
              const newState = [...prevState];
              newState[idx as number]["dec_qty"] = true;
              return newState;
            });
          } else {
            setFormErrors((prevState) => {
              const newState = [...prevState];
              newState[idx as number]["dec_qty"] = false;
              return newState;
            });
          }
          break;
        }
        case "reason": {
          if (Boolean(!updatedValue.reason)) {
            setFormErrors((prevState) => {
              const newState = [...prevState];
              newState[idx as number]["reason"] = true;
              return newState;
            });
          } else {
            setFormErrors((prevState) => {
              const newState = [...prevState];
              newState[idx as number]["reason"] = false;
              return newState;
            });
          }
          break;
        }
        default:
          break;
      }
    },
    []
  );
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const { name, value } = e.target;
      let numericValue = value;

      if (name !== "reason") {
        numericValue = value.replace(/\D/g, "");
      }

      setCurrentIndex(index);
      setBillProducts((prevState: any) => {
        const newState = [...prevState];

        newState[index as number] = {
          ...newState[index as number],
          [name]: numericValue,
        };
        const { increase_amount, decrease_amount } = handleCalculation(
          newState[index as number],
          index
        );
        newState[index as number] = {
          ...newState[index as number],
          inc_amount: increase_amount,
          dec_amount: decrease_amount,
        };
        if (increase_amount || decrease_amount) {
          handleDisabledRows(name, newState[index as number], index as number);
        }
        handleFormValidation(
          { target: { name: name, value: newState[index as number][name] } },
          newState[index as number],
          index
        );
        return newState;
      });
    },
    []
  );
  const validateForm = (details: any) => {
    let validate = true;

    if (!details.department) {
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "department or supplier not to be empty!",
        })
      );
      validate = false;
    }

    if (Boolean(formErrors.find((ele) => ele.dec_qty))) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please check Table Values!" })
      );
      validate = false;
    }
    if (Boolean(billProducts.find((ele: any) => !ele.reason))) {
      const findReasonIndex = billProducts.findIndex(
        (ele: { reason: boolean }) => !ele.reason
      );
      const newErrors = [...formErrors];
      newErrors[findReasonIndex].reason = true;
      setFormErrors(newErrors);
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Reason need for every data!" })
      );
      validate = false;
    }
    if (Boolean(billProducts.find((ele: any) => !ele.product_name))) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please choose product first!" })
      );
      validate = false;
    }

    return validate;
  };
  const handleSubmit = async () => {
    if (validateForm(info)) {
      setIsSubmitLoaded(true);
      // const departmentType = appConfiguration?.goods_issue_disable
      //   ? "receiver"
      //   : "issuer";
      const departmentType = departmentList?.find(
        (ele: { id: any }) => ele.id === info?.department
      );
      const productDetails: any =
        billProducts.length > 0
          ? billProducts.map((obj: any) => {
              let newObj = {};
              const {
                product_id,
                supplier_id,
                pack_type,
                discount,
                tax_id,
                tax_type,
                id,
                batch_no,
              } = obj.product_name;
              newObj = {
                product_id,
                supplier_id,
                pack_type,
                discount,
                tax_id,
                tax_type,
                raw_batch_no: batch_no,
                batch_id: id,
                price: obj.price,
                reason: obj.reason,
                increase_qty: obj.inc_qty,
                decrease_qty: obj.dec_qty,
                increase_amount: obj.inc_amount,
                decrease_amount: obj.dec_amount,
              };
              return newObj;
            })
          : 0;
      const data = {
        dept_id: info.department,
        dept_type: departmentType,
        bill_no: info.bill_no,
        bill_no_modified: info.bill_no === initialSequenceNumber ? 0 : 1,
        product: [...productDetails],
      };

      await createStockAdjustment(data)
        .then((res: any) => {
          navigate("/procurement/stock-adjustment");
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          setIsSubmitLoaded(false);
        })
        .catch((err: any) => {
          if (err?.response?.data?.errors?.data) {
            const { data, message } = err.response.data.errors;

            const newBillProducts = [...billProducts];

            newBillProducts[data.key].stock = data.current_stock;
            setBillProducts(newBillProducts);
            const newFormError = [...formErrors];
            newFormError[data.key]["dec_qty"] = true;
            setFormErrors(newFormError);

            dispatch(setSnackBarFailed({ snackBarMessage: message }));
            console.log(err);
            setIsSubmitLoaded(false);
          } else if (typeof err?.response?.data?.errors === "string") {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.response?.data?.errors,
              })
            );
          }
          setIsSubmitLoaded(false);
          console.log(err);
        });
    }
  };
  useEffect(() => {
    getAllDepartmentList();
  }, [appConfiguration]);

  useEffect(() => {
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }
  }, [appConfiguration]);
  const handleAutoCompleteChange = useCallback(
    (newValue: any, index: Number, name: string) => {
      if (newValue === null) {
        return false;
      }

      setBillProducts((prev: any) => {
        let newState = [...prev];
        if (newState.length > 1) {
          newState[index as number] = {
            ...newState[index as number],
            [name]: newValue,
            batch_no: `${newValue.batch_no}#${newValue.stock_qty}`,
            stock: newValue.stock_qty,
            bill_batch_id: newValue.id,
            price: (newValue.p_rate / newValue.pack_type).toFixed(2),
            discount: newValue.product_discount,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
            inc_qty: "",
            dec_qty: "",
          };
        }
        if (newState.length === 1) {
          newState[index as number] = {
            ...newState[index as number],
            [name]: newValue,
            batch_no: `${newValue.batch_no}#${newValue.stock_qty}`,
            stock: newValue.stock_qty,
            discount: newValue.product_discount,
            bill_batch_id: newValue.id,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
            price: (newValue.p_rate / newValue.pack_type).toFixed(2),
            inc_qty: "",
            dec_qty: "",
          };
        }
        setBatchIdNo((prev) => [...prev, newValue?.id]);
        return newState;
      });
    },
    []
  );

  const handleAutoCompleteInputChange = useCallback(
    (newValue: any, idx: number, name: string) => {
      setBillProducts((prevState: any) => {
        const newState = [...prevState];
        if (newState.length > 0 && newState[idx]) {
          newState[idx][name] = newValue;
        }
        return newState;
      });
    },
    []
  );

  const handleClick = useCallback(() => {
    setBillProducts((prev: any) => [
      ...prev,
      {
        ...initialBillProduct,
        id: prev.length,
      },
    ]);
    setRowDisables((prev) => [
      ...prev,
      {
        inc_qty: false,
        dec_qty: false,
      },
    ]);
    setFormErrors((prevState) => [
      ...prevState,
      { dec_qty: false, reason: false },
    ]);
  }, []);

  const handleDelete = () => {
    const { id, batchNo }: any = isDeleteDialogOpen?.selectedRow;
    setLoading(true);
    const newBillProducts: any = [...billProducts];
    newBillProducts.splice(id, 1);
    setBillProducts(newBillProducts);

    const newErrorValue: any = [...rowDisables];
    newErrorValue.splice(id, 1);
    setRowDisables(newErrorValue);

    const newFormErrors: any = [...formErrors];
    newFormErrors.splice(id, 1);
    setFormErrors(newFormErrors);
    if (batchNo) {
      const newBatchIds = [...batchIdNo];
      const findBatchIndex = batchIdNo.findIndex(
        (ele) => ele === Number(batchNo)
      );
      newBatchIds.splice(findBatchIndex, 1);
      setBatchIdNo(newBatchIds);
    }
    setLoading(false);
    setIsDeleteDialogOpen({ isOpen: false, selectedRow: null });
  };

  useEffect(() => {
    if (info.department && batchIdNo) {
      const data: any = {
        department: info.department,
        selected_batches: batchIdNo,
      };
      getStockAdjustProduct(data)
        .then((res: any) => setProductRow(res.data))
        .catch((err: any) => console.log(err));
    }
  }, [info.department, batchIdNo]);
  return (
    <>
      <Grid sx={{ width: "92vw" }}>
        <Info
          info={info}
          setInfo={setInfo}
          departmentList={departmentList}
          isManual={isManual}
          isManualAdded={isManualAdded}
          setIsManual={setIsManual}
          setSequenceDialogBox={setSequenceDialogBox}
          handleInvoiceNoChange={handleInvoiceNoChange}
          customSequence={customSequence}
          isSelectLoading={isSelectLoading}
        />
        <Grid
          sx={{
            width: {
              xs: "92vw",
            },
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            overflowX: { md: "scroll", lg: "hidden" },
          }}
        >
          <TableContainer
            className="table-container"
            component={Paper}
            sx={{
              ...styles.tableContainerStyle,
            }}
          >
            <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {TableHeaderCells.map((cell, index) => {
                    return (
                      <TableCell
                        align={cell.alignment}
                        sx={cell.sx}
                        key={index}
                      >
                        {cell.TableCellName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  height: "auto",
                  width: {
                    xs: "400vw",
                    sm: "200vw",
                    md: "120vw",
                    lg: "90vw",
                  },
                }}
              >
                {billProducts.map((row: any, index: number) => {
                  return (
                    <ProductRow
                      key={row.id}
                      index={index}
                      row={row}
                      styles={styles}
                      allRows={productRow}
                      rowDisables={rowDisables}
                      formErrors={formErrors}
                      info={info}
                      batchIdNo={batchIdNo}
                      totalLength={billProducts.length}
                      handleInputChange={handleInputChange}
                      handleDelete={handleDelete}
                      handleAutoCompleteChange={handleAutoCompleteChange}
                      reasonList={commonVariables?.adjustment_reason}
                      setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                      handleAutoCompleteInputChange={
                        handleAutoCompleteInputChange
                      }
                    />
                  );
                })}
              </TableBody>
            </Table>
            <Grid
              sx={{
                width: {
                  xs: "400vw",
                  sm: "200vw",
                  md: "120vw",
                  lg: "90vw",
                },
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "0px 0px 5px 5px",
                border: "1px solid",
                borderColor: "var(--table-border)",
                borderTop: 0,
                height: "44px",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: {
                    xs: "39%",
                    lg: "39%",
                  },
                }}
              >
                <Button
                  variant="text"
                  buttonText="+ Add Product"
                  handleClick={handleClick}
                  sx={{
                    borderRadius: "0px",
                    width: "auto",
                    height: "auto",
                    fontWeight: 400,
                    fontSize: "14px",
                    border: 0,
                    color: "primary.main",
                    "&:hover": {
                      backgroundColor: "initial",
                      color: "primary.main",
                      borderColor: "initial",
                    },
                    "&.Mui-focusVisible": {
                      border: 1,
                      borderColor: "primary.main",
                      borderRadius: "5px",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </TableContainer>
        </Grid>
        <Grid
          sx={{ display: "flex", flexDirection: "row", m: "35px 0 45px 0" }}
        >
          <SaveButton
            handleClick={() => handleSubmit()}
            loading={isSubmitLoaded}
            sx={{ width: "100px", height: "40px", mr: "20px" }}
          />
          <CancelButton sx={{ width: "100px", height: "40px" }} />
        </Grid>
      </Grid>
      <SequenceWrapper
        open={sequenceDialogBox}
        title="Stock Adjustment"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
      <DeleteDialog
        open={isDeleteDialogOpen.isOpen}
        loading={loading}
        onClose={() =>
          setIsDeleteDialogOpen({ isOpen: false, selectedRow: null })
        }
        onConfirm={handleDelete}
      />
    </>
  );
};

export default BillProducts;
