import React from "react";
import {
  TableCell,
  TableRow,
  Grid,
  Typography,
  List,
  Box,
  Popper,
  Tooltip,
} from "@mui/material";
import { DeleteIcon, DisabledDelete } from "../../../../assets/icons";
import { TextField, AutoCompleteWithTable } from "../../../../components/basic";
import { formatDateType } from "../../../../utils/DateTimeFormatUtils";

type ProductRowProps = {
  styles?: any;
  row: any;
  handleDelete: (
    id: number,
    batchId?: any,
    bill_id?: any,
    row_id?: number
  ) => void;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleAutoCompleteChange: (
    e: any,
    newValue: any,
    id: Number,
    name: string
  ) => false | void;
  index: number;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  allRows?: any;
  formError?: any;
  isEdit?: boolean;
  totalLength?: any;
  setIsDeleteDialogOpen?: any;
  handleAutoCompleteInputChange?: any;
};

const ProductRow = React.memo(
  ({
    styles,
    row,
    handleInputChange,
    handleAutoCompleteChange,
    handleDelete,
    open,
    onOpen,
    onClose,
    allRows,
    index,
    formError,
    isEdit,
    totalLength,
    setIsDeleteDialogOpen,
    handleAutoCompleteInputChange,
  }: ProductRowProps) => {
    const productRowStyles = {
      autoCompleteWIthTableTitle: {
        width: "50%",
        fontSize: "13px",
        fontWeight: "600",
        color: "textPrimary.main",
        textAlign: "left",
        p: "0px 10px",
      },
    };
    const PopperMy = function (props: any) {
      const { children, ...rest } = props;
      return (
        <Popper
          {...rest}
          placement="bottom-start"
          className="auto-complete-popper"
          sx={{
            width: "700px",
            p: "10px 0px",
            boxSizing: "border-box",
            "& .MuiPaper-root": {
              width: "700px",
              maxHeight: "250px",
              overflow: "hidden",
              borderRadius: "0px 0px 5px 5px",
              boxSizing: "border-box",
            },
            "& .MuiAutocomplete-listbox": {
              overflowX: "hidden",
              maxHeight: "250px",
              p: 0,
            },
          }}
        >
          <Box
            sx={{
              width: "700px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "var(--table-autocomplete-background)",
              p: "0px 10px 0px 0px",
              height: "45px",
              boxSizing: "border-box",
              borderRadius: "5px 5px 0px 0px",
            }}
          >
            <Typography
              sx={{
                ...productRowStyles.autoCompleteWIthTableTitle,
              }}
            >
              Product Name
            </Typography>
            <Typography
              sx={{
                ...productRowStyles.autoCompleteWIthTableTitle,
                width: "20%",
              }}
            >
              Bill No
            </Typography>
            <Typography
              sx={{
                ...productRowStyles.autoCompleteWIthTableTitle,
                width: "20%",
              }}
            >
              Batch No
            </Typography>
            <Typography
              sx={{
                ...productRowStyles.autoCompleteWIthTableTitle,
                width: "20%",
              }}
            >
              Quantity
            </Typography>
          </Box>
          {props.children}
        </Popper>
      );
    };

    return (
      <TableRow
        sx={{
          "& td, & th": { border: 0 },
          width: "90vw",
        }}
      >
        <Tooltip
          open={row?.product_name !== null ? false : open}
          onClose={onClose}
          onOpen={onOpen}
          title={
            <Typography
              sx={{
                color: "white",
                fontSize: "14px",
              }}
            >
              This field is required
            </Typography>
          }
          arrow
        >
          <TableCell
            sx={{
              width: "12%",
              borderColor: "primary.main",
              "& .MuiAutocomplete-root": {
                height: "100%",
              },
              "& .MuiInputBase-root": {
                height: "100%",
                p: "0px",
                pr: "0px",
              },
              "& .MuiOutlinedInput-root": {
                p: "0px",
                pr: "0px",
              },
            }}
          >
            {isEdit ? (
              <TextField
                name="product_name"
                value={row.product_name}
                disabled={true}
                formControlStyle={{ height: "100%" }}
                sx={{
                  ...styles.textFieldStyle,
                  width: "inherit",
                }}
              />
            ) : (
              <AutoCompleteWithTable
                placeholder="Select Product"
                name="product_name"
                value={row?.product_name || null}
                onChange={(e: any, newValue: any) =>
                  handleAutoCompleteChange(e, newValue, index, "product_name")
                }
                options={allRows}
                renderOption={(props: any, option: any) => {
                  return (
                    <List
                      {...props}
                      key={option.ID}
                      className="table-body-lists-div"
                      sx={{
                        width: "700px",
                        borderRadius: 0,
                        boxSizing: "border-box",
                        m: "0px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "primary.lighter",
                        },
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="700px"
                        className="list-row"
                        sx={{
                          boxSizing: "border-box",
                          m: "0px",
                          p: "0px 10px 0px 0px",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            width: "50%",
                            textAlign: "left",
                            p: "0px 10px",
                          }}
                        >
                          {option.product_name}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "20%",
                            textAlign: "left",
                            p: "0px 10px",
                          }}
                        >
                          {option.invoice_no}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "20%",
                            textAlign: "left",
                            p: "0px 10px",
                          }}
                        >
                          {option.batch_no}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            width: "20%",
                            textAlign: "left",
                            p: "0px 10px",
                          }}
                        >
                          {option.qty}
                        </Typography>
                      </Box>
                    </List>
                  );
                }}
                getOptionLabel={(option: any) => option.product_name}
                sx={{
                  width: "100%",
                  height: "100% !important",
                  "& .MuiFormControl-root": {
                    height: "100%",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "& .MuiInputBase-root .MuiInputBase-input": {
                    padding: 0,
                    fontSize: "var(--primary-input-text-font-size)",
                    fontWeight: "400",
                  },

                  "& input": {
                    height: "100%",
                  },
                }}
                PopperComponent={PopperMy}
                inputValue={row?.product_input_value || ""}
                onInputChange={(e: any, newValue: any) =>
                  handleAutoCompleteInputChange(
                    e,
                    newValue,
                    "product_input_value",
                    index
                  )
                }
              />
            )}
          </TableCell>
        </Tooltip>
        <TableCell
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="invoice_no"
            value={row.invoice_no}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          sx={{
            width: "12%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="bill_disc"
            value={row.bill_disc}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
            }}
          />
        </TableCell>
        <TableCell
          sx={{
            width: "12%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="sale_date"
            value={row.sale_date}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
            }}
          />
        </TableCell>
        <TableCell
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="exp_date"
            value={
              row.exp_date
                ? `${formatDateType(row.exp_date, "MM")}/${formatDateType(
                    row.exp_date,
                    "YYYY"
                  )}`
                : ""
            }
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
            }}
          />
        </TableCell>
        <TableCell
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="sale_qty"
            value={row.sale_qty}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="sale_price"
            value={row.sale_price}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <Grid
            className="select-grid"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
                borderWidth: "0 !important",
                height: "44px",
                maxHeight: "44px",
              },

              "& .MuiInputBase-root": {
                padding: "0px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                p: "0px 0px 0px 5px",
              },

              "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "primary.main",
                  borderWidth: "1px",
                },

              "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderWidth: 0,
                },
            }}
          >
            <TextField
              name="sale_disc"
              disabled={true}
              value={row.sale_disc}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e, index)
              }
            />
          </Grid>
        </TableCell>
        <TableCell
          sx={{
            width: "10%",
            border: Boolean(formError[index as number]?.ret_qty)
              ? "2px solid red !important"
              : "",
          }}
        >
          <Grid
            className="select-grid"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
                borderWidth: "0 !important",
                height: "44px",
                maxHeight: "44px",
              },

              "& .MuiInputBase-root": {
                padding: "0px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                p: "0px 0px 0px 5px",
              },

              "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "primary.main",
                  borderWidth: "1px",
                },

              "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderWidth: 0,
                },
            }}
          >
            <TextField
              name="ret_qty"
              value={row.ret_qty}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e, index)
              }
            />
          </Grid>
        </TableCell>

        <TableCell
          className="last-cell"
          sx={{
            backgroundColor: "var(--disabled-background)",
            width: "10%",
            position: "relative",
          }}
        >
          <TextField
            name="ret_price"
            value={row.ret_price}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          />

          <Grid className="deleteIconGrid">
            {index === 0 && totalLength === 1 ? (
              <DisabledDelete className="delete-icon" />
            ) : (
              <DeleteIcon
                className="delete-icon"
                onClick={() => {
                  setIsDeleteDialogOpen({
                    isOpen: true,
                    selectedRow: {
                      id: index,
                      batchNo: row.bill_batch_id,
                      billId: row.bill_id,
                      rowId: row.row_id,
                    },
                  });
                }}
              />
            )}
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
);

export default ProductRow;
