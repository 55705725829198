import { useState, useEffect, useCallback, useRef } from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  Typography,
  Grid,
  CircularProgress,
  Switch,
  debounce,
  Box,
  IconButton,
} from "@mui/material";
import {
  TextField,
  Select,
  Sort,
  CustomButton,
  PageLoader,
} from "../../../../components/basic";
import {
  DataTable,
  DialogWrapper,
  ConfirmationDialog,
} from "../../../../components/shared";
import { statusColor } from "../../../../utils/GeneralUtils";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import CustomFilter from "../../../../components/shared/customFilter";
import { DeleteIcon, EditIcon, PlusIcon } from "../../../../assets/icons";
import {
  displayText,
  procurementConst,
} from "../../../../constants/displayText";
import { ActiveStatusColor } from "../../../../utils/GeneralUtils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  createDepartments,
  deleteDepartments,
  editDepartments,
  getAllDepartments,
  updateDepartments,
} from "../../../../services/procurementMasterService";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { setCurrentPage } from "../../../../redux/slices/pagination";
type GridRowData = Record<string, unknown>;

function Departments() {
  const { ADD, EDIT, ADD_BUTTON_TITLE, CONFIRMATION_DELETE_DIALOG_TITLE } =
    displayText;

  // data table constants and states
  const dispatch = useDispatch();
  const pageInfo = useSelector(
    (state: RootState) => state?.pagination?.procurementMasterDepartments
  );

  const [isFieldSort, setIsFieldSort] = useState(false);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState<number | string>("");
  const [loading, setLoading] = useState<boolean>();
  const [isSaveBtnLoading, setIsSaveBtnLoading] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);

  // Add And Edit dialog states and constants

  const [isAddAndEditDialogOpen, setIsAddAndEditDialogOpen] =
    useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isAddAndEditDialogLoading, setIsAddAndEditDialogLoading] =
    useState<boolean>(false);
  const [idToBeUpdated, setIdToBeUpdated] = useState<number | null>(null);

  // confirmation dialog states and constants

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [departmentToBeDeleted, setDepartmentToBeDeleted] = useState<any>(null);

  // form states and constants

  const initialFormError = {
    type: "",
    name: "",
    gst: "",
    active: "",
  };
  const [departmentFormError, setDepartmentFormError] =
    useState<any>(initialFormError);

  const initialFormData = {
    type: null,
    name: "",
    gst: "",
    active: true,
  };

  const [departmentFormData, setDepartmentFormData] =
    useState<any>(initialFormData);
  const fieldData: any = {
    type: {
      label: "Type",
      name: "type",
      value: departmentFormData.type,
      ref: useRef(null),
      isError: Boolean(departmentFormError.type),
      helperText: departmentFormError.type,
    },
    name: {
      label: "Name",
      name: "name",
      value: departmentFormData.name,
      ref: useRef(null),
      isError: Boolean(departmentFormError.name),
      helperText: departmentFormError.name,
    },
    gst: {
      label: "Gst",
      name: "gst",
      value: departmentFormData.gst,
      ref: useRef(null),
      isError: Boolean(departmentFormError.gst),
      helperText: departmentFormError.gst,
    },

    active: {
      label: "Status",
      name: "active",
      value: departmentFormData.active,
      isError: Boolean(departmentFormError.active),
      helperText: departmentFormError.active,
    },
  };

  const [departmentFieldData, setDepartmentFieldData] =
    useState<any>(fieldData);

  const typeOptions = [
    { id: "Issuer", name: "Issuer" },
    { id: "Receiver", name: "Receiver" },
    { id: "B2B Sales", name: "B2B Sales" },
  ];

  const getDepartmentsList = useCallback(
    debounce(async (data: any) => {
      setLoading(true);

      getAllDepartments(data)
        .then((result: any) => {
          let data = result?.data?.result;
          setDepartmentList(data);
          setPageCount(result?.data?.total as any);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          console.log("error", err);
        });
    }, 300),
    []
  );
  useEffect(() => {
    const data: any = {
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo.pageSize,
      sortBy: sortedField.field,
      sortOrder: sortedField.order,
      searchValue: searchValue,
    };
    getDepartmentsList(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortedField.field,
    sortedField.order,
    searchValue,
  ]);

  // data table columns and actions

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          onClick={() => {
            setIdToBeUpdated(row.id);
            editDepartment(row.id);
          }}
        >
          <EditIcon />
        </IconButton>

        <IconButton onClick={() => deleteDepartment(row.id)}>
          <DeleteIcon />
        </IconButton>
      </div>
    );
  };
  const columns: GridColDef[] = [
    {
      field: "Type",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("type")}
        >
          <Typography variant="h5" fontSize={14}>
            Type
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "type" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "type" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.type}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "dept_name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("dept_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "dept_name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "dept_name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.dept_name || ""}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "active",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("active")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "active" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "active" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => {
        const statusValue = row ? row.active : null;
        const { buttonText, backgroundColor, textColor } =
          ActiveStatusColor[statusValue] || {};

        return (
          <Typography variant="h5" fontSize={14}>
            <CustomButton
              buttonText={buttonText}
              backgroundColor={backgroundColor}
              textColor={textColor}
              fontSize={"10px"}
              fontWeight={500}
              textTransform={"capitalize"}
            />
          </Typography>
        );
      },
      minWidth: 120,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  // data table functions
  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  //form validation functions
  let isFocused = false;
  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "type":
      case "gst":
      case "name": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setDepartmentFormError
          );
          if (!isFocused) {
            if (fieldData[name]?.ref?.current) {
              fieldData[name]?.ref?.current?.focus();
              isFocused = true;
            }
          }
        } else {
          updateFormDataWithHelperText(name, "", setDepartmentFormError);
        }
        break;
      }
      default:
        break;
    }
  };
  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };
  const updateProductsFieldData = () => {
    setDepartmentFieldData((prev: any) => {
      return Object?.keys(prev)?.map((field: any) => {
        return {
          ...field,
          value: departmentFormData[field.name],
          isError: Boolean(departmentFormError[field.name]),
          helperText: departmentFormError[field.name],
        };
      });
    });
  };

  // form API call functions
  const createDepartment = async () => {
    if (
      !Boolean(departmentFormData.type) ||
      !Boolean(departmentFormData.name) ||
      !Boolean(departmentFormData.gst)
    ) {
      validateForm();
    } else {
      try {
        setIsSaveBtnLoading(true);
        const data = {
          dept_name: departmentFormData.name,
          type: departmentFormData.type,
          gst_no: departmentFormData.gst,
          active: 1,
        };

        await createDepartments(data).then((result: any) => {
          closeAddAndEditDialog();
          dispatch(setSnackBarSuccess({ snackBarMessage: result.message }));
          getDepartmentsList({
            page: Number(pageInfo.page) + 1,
            pageSize: pageInfo.pageSize,
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            searchValue: searchValue,
          });
          setIsSaveBtnLoading(false);
        });
      } catch (error: any) {
        closeAddAndEditDialog();
        const errorMessage = error?.response?.data?.errors
          ? error?.response?.data?.errors
          : "Unknown error occurred";
        dispatch(
          setSnackBarFailed({
            snackBarMessage: `${errorMessage}`,
          })
        );
        setIsSaveBtnLoading(false);
        console.error("An error occurred:", error);
      }
    }
  };
  const editDepartment = async (id: number) => {
    try {
      if (id === null) {
        return false;
      }
      setIsAddAndEditDialogLoading(true);
      openAddAndEditDialog();
      await editDepartments(id).then((result: any) => {
        setDepartmentFormData({
          name: result?.data?.dept_name,
          type: result?.data?.type,
          active: result?.data?.active,
          gst: result?.data?.gst_no,
        });

        setIsAddAndEditDialogLoading(false);
      });
    } catch (error: any) {
      setIsAddAndEditDialogLoading(false);
      const errorMessage = error?.response?.data?.errors
        ? error?.response?.data?.errors
        : "Unknown error occurred";
      dispatch(
        setSnackBarFailed({
          snackBarMessage: `${errorMessage}`,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const updateDepartment = async () => {
    if (
      !Boolean(departmentFormData.type) ||
      !Boolean(departmentFormData.name) ||
      !Boolean(departmentFormData.gst)
    ) {
      validateForm();
    } else {
      try {
        if (idToBeUpdated === null) {
          return false;
        }
        setIsSaveBtnLoading(true);
        const data = {
          dept_name: departmentFormData.name,
          type: departmentFormData.type,
          gst_no: departmentFormData.gst,
          active: departmentFormData.active,
        };
        await updateDepartments(idToBeUpdated, data).then((result: any) => {
          closeAddAndEditDialog();
          dispatch(setSnackBarSuccess({ snackBarMessage: result.message }));
          setIsSaveBtnLoading(false);
          getDepartmentsList({
            page: Number(pageInfo.page) + 1,
            pageSize: pageInfo.pageSize,
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            searchValue: searchValue,
          });
        });
      } catch (error: any) {
        closeAddAndEditDialog();
        const errorMessage = error?.response?.data?.errors
          ? error?.response?.data?.errors
          : "Unknown error occurred";
        dispatch(
          setSnackBarFailed({
            snackBarMessage: `${errorMessage}`,
          })
        );
        setIsSaveBtnLoading(false);
        console.error("An error occurred:", error);
      }
    }
  };
  const handleConfirmationDelete = async () => {
    if (departmentToBeDeleted === null) {
      return false;
    }
    try {
      setIsButtonLoading(true);
      await deleteDepartments(departmentToBeDeleted).then((result: any) => {
        setIsButtonLoading(false);
        dispatch(setSnackBarSuccess({ snackBarMessage: result.message }));
        closeConfirmationDialog();
        getDepartmentsList({
          page: Number(pageInfo.page) + 1,
          pageSize: pageInfo.pageSize,
          sortBy: sortedField.field,
          sortOrder: sortedField.order,
          searchValue: searchValue,
        });
      });
    } catch (error: any) {
      setIsButtonLoading(false);
      const errorMessage = error?.response?.data?.errors
        ? error?.response?.data?.errors
        : "Unknown error occurred";
      dispatch(
        setSnackBarFailed({
          snackBarMessage: `${errorMessage}`,
        })
      );
      closeConfirmationDialog();
      console.error("An error occurred:", error);
    }
  };

  // form fields onchange functions

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setDepartmentFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    handleValidation(e);
  };

  const handleSwitchChange = async (e: any) => {
    const { name, checked } = e.target;
    setDepartmentFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: checked,
    }));
  };

  useEffect(() => {
    updateProductsFieldData();
  }, [departmentFormError, departmentFormData]);

  const deleteDepartment = (departmentId: number) => {
    setIsConfirmationDialogOpen(true);
    setDepartmentToBeDeleted(departmentId);
  };

  // Add And Edit dialog functions

  const openAddAndEditDialog = async () => {
    setIsAddAndEditDialogOpen(true);
  };

  const closeAddAndEditDialog = async () => {
    setIsButtonLoading(false);
    setIsAddAndEditDialogOpen(false);
    setDepartmentFormData(initialFormData);
    setIdToBeUpdated(null);
    setDepartmentFormError(initialFormError);
  };

  // confirmation dialog functions

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };
  const handleSearchOnChange = (e: any) => {
    setSearchValue(e.target.value);
    dispatch(setCurrentPage({ key: "procurementMasterDepartments", value: 0 }));
  };
  return (
    <>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={CONFIRMATION_DELETE_DIALOG_TITLE}
        handleClick={handleConfirmationDelete}
        onClose={closeConfirmationDialog}
        loading={isButtonLoading}
      />
      <CustomFilter
        isSearchEnabled={true}
        editButtonTitle={ADD_BUTTON_TITLE}
        onUpdateButtonClick={openAddAndEditDialog}
        searchValue={searchValue}
        clearSearch={() => {
          setSearchValue("");
          dispatch(
            setCurrentPage({ key: "procurementMasterDepartments", value: 0 })
          );
        }}
        searchOnChange={handleSearchOnChange}
        searchName={"search"}
        editStartIcon={
          <Box
            sx={{
              "& path": {
                fill: "#fff",
              },
            }}
          >
            <PlusIcon width={"12px"} height={"12px"} />
          </Box>
        }
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={departmentList}
        pageCount={pageCount}
        currentPage={"procurementMasterDepartments"}
      />

      <DialogWrapper
        onClose={closeAddAndEditDialog}
        open={isAddAndEditDialogOpen}
        handleClick={
          idToBeUpdated === null ? createDepartment : updateDepartment
        }
        title={idToBeUpdated === null ? ADD : EDIT}
        loading={isSaveBtnLoading}
        maxWidth="sm"
      >
        {isAddAndEditDialogLoading ? (
          <PageLoader />
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Select
              value={fieldData.type.value}
              onChange={handleInputChange}
              onBlur={(e: any) =>
                handleValidation((e = { target: fieldData.type }))
              }
              name="type"
              placeholder={"select Type"}
              options={typeOptions}
              label={fieldData.type.label}
              inputRef={fieldData.type.ref}
              helperText={fieldData.type.helperText}
              selectedType="number"
              sx={{
                width: "552px",
              }}
              width="552px"
            />

            <TextField
              value={fieldData.name.value}
              name={fieldData.name.name}
              onChange={handleInputChange}
              inputRef={fieldData.name.ref}
              label={fieldData.name.label}
              placeholder="Enter Name"
              helperText={fieldData.name.helperText}
              onBlur={(e: any) =>
                handleValidation((e = { target: fieldData.name }))
              }
              sx={{
                width: "100%",
              }}
            />
            <TextField
              value={fieldData.gst.value}
              name={fieldData.gst.name}
              onChange={handleInputChange}
              label={fieldData.gst.label}
              inputRef={fieldData.gst.ref}
              helperText={fieldData.gst.helperText}
              placeholder="Enter Gst"
              onBlur={(e: any) =>
                handleValidation((e = { target: fieldData.gst }))
              }
              sx={{
                width: "100%",
              }}
            />
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: "5px",
              }}
            >
              <Typography>{fieldData.active.label}</Typography>
              <Switch
                name={fieldData.active.name}
                checked={fieldData.active.value}
                onChange={handleSwitchChange}
                disabled={idToBeUpdated == null ? true : false}
              />
            </Grid>
          </Box>
        )}
      </DialogWrapper>
    </>
  );
}

export default Departments;
